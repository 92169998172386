import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ArrowBack } from "../../../components/commons/icons";
import styles from "./step.module.css";

const StepTitle = styled.h3`
  text-align: center;
  margin: 3rem 0 0 0;
  font-size: 1.375rem;
  color: #4d4d4d;
  line-height: 1.2;
  font-weight: 500;
`;
const StepText = styled.p`
  text-align: center;
  margin: 0 0 10px;
  font-weight: 300;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
  @media (max-width: 991px) {
    font-size: 1.25rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
    padding: 3rem 0 0 0;
  }

  @media (min-width: 992px) {
    margin-bottom: 0px;
  }

  @media (min-width: 320px) {
    padding: 1rem;
  }
`;
const StepForm = styled.form`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};

  @media (min-width: 480px) {
    padding: 0 3.6em;
  }

  @media (min-width: 768px) {
    padding: 0;
  }

  @media (min-width: 992px) {
    padding: 0 1rem;
  }
`;

const StepFormError = ({ error }) =>
  error ? <p style={{ color: "#FD6773" }}>{error}</p> : null;
StepFormError.propTypes = { error: PropTypes.any };

const StepBox = ({ children, style }) => (
  <div className={styles.stepBox} style={style}>
    {children}
  </div>
);
StepBox.propTypes = { children: PropTypes.node, style: PropTypes.object };

const StepBody = ({ children }) => <div className={styles.stepBody}>{children}</div>;
StepBody.propTypes = { children: PropTypes.node };

const CurrentStep = ({ step }) => <span className={styles.currentStep}>{step}</span>;
CurrentStep.propTypes = { step: PropTypes.string };

const StepControls = ({ children }) => <div className={styles.controls}>{children}</div>;
StepControls.propTypes = { children: PropTypes.node };

const StepLogo = ({ src }) => (
  <div className={styles.stepLogo}>
    <img className={styles.logoImage} src={src} alt="fi-logo" />
  </div>
);
StepLogo.propTypes = { src: PropTypes.string };

const StepInputContainer = ({ children, label, leftIcon, rightIcon }) => (
  <div style={{ display: "flex" }}>
    {label && <label htmlFor={label}>{label}</label>}
    <br />
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        position: "relative",
      }}
    >
      {leftIcon && <div style={{ position: "absolute" }}>{leftIcon}</div>}
      <div style={{ flex: 1 }}>{children && children}</div>
      {rightIcon && <div style={{ position: "absolute", right: 0 }}>{rightIcon}</div>}
    </div>
  </div>
);
StepInputContainer.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
};

const StepBackButton = ({ onClick }) => (
  <button onClick={onClick} className={styles.backButton}>
    <ArrowBack />
    <span>&nbsp;&nbsp;</span>
    <p style={{ color: "#05CE86", fontWeight: "500" }}>BACK</p>
  </button>
);
StepBackButton.propTypes = { onClick: PropTypes.func };

export {
  StepBox,
  StepFormError,
  StepBackButton,
  StepInputContainer,
  StepControls,
  StepForm,
  CurrentStep,
  StepLogo,
  StepTitle,
  StepText,
  StepBody,
};
