// text banking

export const GET_TEXT_BANKING_USAGE_REQUEST = "get_text_banking_usage_request";
export const GET_TEXT_BANKING_USAGE_SUCCESS = "get_text_banking_usage_success";
export const GET_TEXT_BANKING_USAGE_FAILURE = "get_text_banking_usage_failure";

export const GET_TEXT_BANKING_COMMANDS_REQUEST = "get_text_banking_commands_request";
export const GET_TEXT_BANKING_COMMANDS_SUCCESS = "get_text_banking_commands_success";
export const GET_TEXT_BANKING_COMMANDS_FAILURE = "get_text_banking_commands_failure";

export const GET_TEXT_BANKING_DISCLOSURE_REQUEST = "get_text_banking_disclosure_request";
export const GET_TEXT_BANKING_DISCLOSURE_SUCCESS = "get_text_banking_disclosure_success";
export const GET_TEXT_BANKING_DISCLOSURE_FAILURE = "get_text_banking_disclosure_failure";

export const GET_PHONE_LINKED_ACCOUNTS_REQUEST = "get_phone_linked_accounts_request";
export const GET_PHONE_LINKED_ACCOUNTS_SUCCESS = "get_phone_linked_accounts_success";
export const GET_PHONE_LINKED_ACCOUNTS_FAILURE = "get_phone_linked_accounts_failure";

export const UPDATE_LINKED_ACCOUNT_REQUEST = "update_linked_account_request";
export const UPDATE_LINKED_ACCOUNT_SUCCESS = "update_linked_account_success";
export const UPDATE_LINKED_ACCOUNT_FAILURE = "update_linked_account_failure";

export const INITIALIZE_LINKED_ACCOUNTS_REQUEST = "initialize_linked_accounts_request";
export const INITIALIZE_LINKED_ACCOUNTS_SUCCESS = "initialize_linked_accounts_success";
export const INITIALIZE_LINKED_ACCOUNTS_FAILURE = "initialize_linked_accounts_failure";

export const GET_CUSTOMER_PHONES_REQUEST = "get_customer_phones_request";
export const GET_CUSTOMER_PHONES_SUCCESS = "get_customer_phones_success";
export const GET_CUSTOMER_PHONES_FAILURE = "get_customer_phones_failure";

export const STEP_1_INSERT_CUSTOMER_PHONE_REQUEST =
  "step_1_insert_customer_phone_request";
export const STEP_1_INSERT_CUSTOMER_PHONE_SUCCESS =
  "step_1_insert_customer_phone_success";
export const STEP_1_INSERT_CUSTOMER_PHONE_FAILURE =
  "step_1_insert_customer_phone_failure";

export const STEP_2_INSERT_CUSTOMER_PHONE_REQUEST =
  "step_2_insert_customer_phone_request";
export const STEP_2_INSERT_CUSTOMER_PHONE_SUCCESS =
  "step_2_insert_customer_phone_success";
export const STEP_2_INSERT_CUSTOMER_PHONE_FAILURE =
  "step_2_insert_customer_phone_failure";

export const REMOVE_CUSTOMER_PHONE_REQUEST = "remove_customer_phone_request";
export const REMOVE_CUSTOMER_PHONE_SUCCESS = "remove_customer_phone_success";
export const REMOVE_CUSTOMER_PHONE_FAILURE = "remove_customer_phone_failure";

export const OPEN_CONFIRM_DELETE_MODAL = "open_confirm_delete_modal";
export const CLOSE_CONFIRM_DELETE_MODAL = "close_confirm_delete_modal";

export const UPDATE_LINKED_ACCOUNTS_FORM_VALUE = "update_linked_accounts_form_value";
