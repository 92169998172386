import styled from "styled-components";

const RowTitle = styled.h6`
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #141f2d;
  font-family: "Kumbh Sans";
`;

export default RowTitle;
