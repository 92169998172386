import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import RequireAuth from "../../../components/RequireAuth";
import NotFoundPage from "../../../containers/NotFoundPage/Loadable";
import QuickLinksPage from "../QuickLinks";
import FormsPage from "../Forms";
import GoalsPage from "../Goals";
// account
import {
  AccountSummaryPage,
  SearchAccountsPage,
  SearchResultsPage,
  TransactionsHistoryPage,
  AccountPersonalInformationPage,
  MessagesPage,
  NotificationsPage,
} from "../Accounts";
// transfers
import FundsTransfer from "../FundsTransfer";
// e-pay
import {
  NewPayee,
  SchedulePayments,
  ScheduleAutopay,
  PayeeInformation,
  PaymentDisclosure,
  EditAutopay,
} from "../EPayments";
import EPaySummary from "../EPayments/EPaySummary/index";
// text banking
import { TextBanking, TextBankingAccountSetup, TextBankingUsage } from "../TextBanking";
// loans
import { LoanRequestInformation, LoanAppPage } from "../Loans";
import HelpPage from "../Help";
import SendMoney from "../SendMoney";
import Profile from "../user/Profile";
import Security from "../user/Security/index";
import BankAccounts from "../user/BankAccounts";

const Routes = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/accounts`}
      component={RequireAuth(AccountSummaryPage)}
    />
    <Route
      exact
      path={`${match.url}/accounts/search`}
      component={RequireAuth(SearchAccountsPage)}
    />
    <Route
      exact
      path={`${match.url}/accounts/search-results`}
      component={RequireAuth(SearchResultsPage)}
    />
    <Route exact path={`${match.url}/links`} component={RequireAuth(QuickLinksPage)} />
    <Route exact path={`${match.url}/forms`} component={RequireAuth(FormsPage)} />
    <Route
      exact
      path={`${match.url}/accounts/history`}
      component={RequireAuth(TransactionsHistoryPage)}
    />
    <Route
      exact
      path={`${match.url}/accounts/:accountId?/:accountType?/history`}
      component={RequireAuth(TransactionsHistoryPage)}
    />
    <Route
      exact
      path={`${match.url}/personal-information`}
      component={RequireAuth(AccountPersonalInformationPage)}
    />
    <Route
      exact
      path={`${match.url}/notifications`}
      component={RequireAuth(NotificationsPage)}
    />
    <Route exact path={`${match.url}/messages`} component={RequireAuth(MessagesPage)} />
    <Route exact path={`${match.url}/transfers`} component={RequireAuth(FundsTransfer)} />
    <Route exact path={`${match.url}/goals`} component={RequireAuth(GoalsPage)} />
    <Route exact path={`${match.url}/payments`} component={RequireAuth(EPaySummary)} />
    <Route
      exact
      path={`${match.url}/payments/new-payee`}
      component={RequireAuth(NewPayee)}
    />
    <Route
      exact
      path={`${match.url}/payments/activity`}
      component={RequireAuth(SchedulePayments)}
    />
    <Route
      exact
      path={`${match.url}/payments/schedule-autopay`}
      component={RequireAuth(ScheduleAutopay)}
    />
    <Route
      exact
      path={`${match.url}/payments/edit-autopay`}
      component={RequireAuth(EditAutopay)}
    />
    <Route
      exact
      path={`${match.url}/payments/payment-disclosure`}
      component={RequireAuth(PaymentDisclosure)}
    />
    <Route
      exact
      path={`${match.url}/payments/payee-information/:uniqueId`}
      component={RequireAuth(PayeeInformation)}
    />
    <Route
      exact
      path={`${match.url}/text-banking`}
      component={RequireAuth(TextBanking)}
    />
    <Route
      exact
      path={`${match.url}/text-banking/linked-accounts/:phoneId?`}
      component={RequireAuth(TextBankingAccountSetup)}
    />
    <Route
      exact
      path={`${match.url}/text-banking/phone-usage/:phoneNumber?`}
      component={RequireAuth(TextBankingUsage)}
    />
    <Route
      exact
      path={`${match.url}/text-banking/usage`}
      component={RequireAuth(TextBankingUsage)}
    />
    <Route
      exact
      path={`${match.url}/loans/request-information`}
      component={RequireAuth(LoanRequestInformation)}
    />
    <Route exact path={`${match.url}/sendMoney`} component={RequireAuth(SendMoney)} />
    <Route exact path={`${match.url}/profile`} component={RequireAuth(Profile)} />
    <Route exact path={`${match.url}/security`} component={RequireAuth(Security)} />
    <Route
      exact
      path={`${match.url}/bankAccounts`}
      component={RequireAuth(BankAccounts)}
    />
    <Route path={`${match.url}/loans/app`} component={RequireAuth(LoanAppPage)} />
    <Route exact path={`${match.url}/help`} component={RequireAuth(HelpPage)} />
    <Route exact path="*" component={NotFoundPage} />
  </Switch>
);

Routes.propTypes = { match: PropTypes.object };

export default Routes;
