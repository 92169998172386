import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import TextField from "../../../../components/commons/TextField";
import { CustomModal, SuccessModal } from "../../../../components/commons/Modals";
import { withStyles } from "@material-ui/core/styles";
import { Mobile, Desktop } from "../../../../components/commons/Responsive";
import {
  Button,
  Loader,
  Space,
  PageTitle,
  TextError,
  HeadingModal,
} from "../../../../components/commons";
import {
  Cancel,
  Check,
  CheckRoundGreen,
  Edit,
  Refresh,
  CheckRoundGray,
  InfoGreen,
} from "../../../../components/commons/icons";
import {
  getCustomerProfile,
  saveCustomerProfile,
  updateProfileForm,
  saveAccountDescription,
  updateAccountDescription,
  showAccountDescriptionModal,
} from "../../Accounts/actions";
import { selectAccounts } from "../../Accounts/selectors";
import { DashboardScreens } from "../../DashboardInstance/Dashboard";

import {
  changeUserPassword,
  changeUserPhrase,
  openChangePasswordModal,
  closeChangePasswordModal,
  openPhraseModal,
  closePhraseModal,
} from "../actions";

import styles from "../../Accounts/styles.module.css";

const Container = styled.div`
  flex: 1;
`;

const ModalContainerChangePassword = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  form {
    display: flex;
    flex-direction: column;
  }
  .passwordRule {
    display: flex;
    align-items: center;
    .description {
      margin-left: 5px;
      color: #b9c3c7;
      font-family: "Kumbh Sans";
      font-size: 15px;
      &.active {
        color: #3fc78e;
        font-family: "Kumbh Sans";
        font-size: 15px;
      }
    }
  }
  .descriptionRulesPassword {
    color: #141f2d;
    font-family: "Kumbh Sans";
    font-size: 12px;
  }
  .containerRules {
    margin-left: 16px;
    margin-top: 10px;
  }
`;

const ModalContainerChangePhrase = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  form {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
  }
  .description {
    max-width: 303px;
    margin-bottom: 10px;
    margin-top: 60%;
    .text {
      color: #18243c;
      font-family: "Kumbh Sans";
      font-size: 15px;
    }
  }
`;

const ProfileForm = styled.form`
  display: flex;
  .title {
    color: #141f2d;
    font-family: "Kumbh Sans";
    font-size: 36px;
    font-weight: bold;
  }
  .subTitle {
    color: #3fc78e;
    font-family: "Kumbh Sans";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .card {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #ebeff3;
    border-radius: 10px;
    background-color: #fcfcfc;
    padding: 15px;
    margin-top: 10px;
    &.paddingAnswer {
      padding: 10px 15px 10px 15px;
    }
    .textLeft {
      color: #000000;
      font-family: "Kumbh Sans";
      font-size: 14px;
      flex: auto;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .textRight {
        display: contents;
        height: 18px;
        width: 117px;
        color: #000000;
        font-family: "Kumbh Sans";
        font-size: 14px;
      }
    }

    .leftAnswer {
      flex: 1;
      .numberQuestion {
        display: block;
        color: #acabb2;
        font-family: "Kumbh Sans";
        font-size: 12px;
      }
      .Question {
        display: block;
        color: #000000;
        font-family: "Kumbh Sans";
        font-size: 14px;
      }
    }
    .rightAnswer {
      flex: 1;
      .titleAnswer {
        display: block;
        color: #acabb2;
        font-family: "Kumbh Sans";
        font-size: 12px;
      }
      .answer {
        display: block;
        color: #000000;
        font-family: "Kumbh Sans";
        font-size: 14px;
      }
    }
  }
  .titleQuestions {
    display: flex;
    align-items: center;
    .subTitle {
      width: 100%;
    }
  }
`;

const ModalControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #f4f6f8;
`;

function Security({
  getCustomerProfile,
  openChangePasswordModal: openChangePasswordModalAction,
  closeChangePasswordModal: closeChangePasswordModalAction,
  openPhraseModal,
  closePhraseModal,
  phrase,
  changeUserPhrase,
  changeUserPassword,
  changePasswordModal,
  error,
  phraseModal,
  isFetching,
}) {
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    newPhrase: phrase,
    confirmPhrase: "",
    modalChallengeQuestionSuccessIsOpen: false,
    inputError: null,
    inputsWithError: [],
  });
  useEffect(() => {
    getCustomerProfile();
  }, [getCustomerProfile]);

  const openChangePasswordModal = e => {
    e.preventDefault();
    setState({
      ...state,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    openChangePasswordModalAction();
  };
  const closeChangePasswordModal = () => {
    setState({ ...state, error: null });
    closeChangePasswordModalAction();
  };
  const openChangePhraseModal = e => {
    e.preventDefault();
    openPhraseModal();
  };
  const closeChangePhraseModal = () => {
    setState({ ...state, error: null });
    closePhraseModal();
  };

  const handleChangeUserPhrase = () => {
    const { newPhrase, confirmPhrase } = state;
    if (newPhrase !== "") {
      setState({ ...state, error: null });
      changeUserPhrase({ newPhrase, confirmPhrase });
    } else {
      setState({ ...state, error: "please complete the form" });
    }
  };
  const handleChangeUserPassword = () => {
    const { newPassword, confirmPassword, currentPassword } = state;
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        setState({ ...state, error: null });
        changeUserPassword({
          newPassword,
          confirmPassword,
          currentPassword,
        });
      } else {
        setState({ ...state, error: "passwords don't match, try again" });
      }
    } else {
      setState({ ...state, error: "please complete the form" });
    }
  };

  const validateEightCharacter = value => {
    const eightCharacters = /^(\w){8,}$/;
    if (eightCharacters.test(value)) {
      return true;
    }
    return false;
  };
  const validateUpperLoawerCase = value => {
    const oneUpper = /[a-z]+/;
    const oneLowercase = /[A-Z]+/;
    if (oneUpper.test(value) && oneLowercase.test(value)) {
      return true;
    }
    return false;
  };
  const validateOneNumber = value => {
    const oneNumber = /[0-9]+/;
    if (oneNumber.test(value)) {
      return true;
    }
    return false;
  };
  const renderChangePasswordModal = () => {
    return (
      <CustomModal
        isOpen={changePasswordModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: 400,
            width: "100%",
            padding: "1rem 1.5rem 3rem 1.5rem",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 0.3,
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            zIndex: 30,
            backgroundColor: "rgba(230, 230, 230, 0.8)",
          },
        }}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <HeadingModal>Change Password</HeadingModal>
          <Cancel onClick={closeChangePasswordModal} />
        </div>
        <ModalContainerChangePassword>
          <form>
            <TextField
              onChange={e => setState({ ...state, currentPassword: e.target.value })}
              value={state.currentPassword}
              name="currentPassword"
              id="currentPassword"
              type="password"
              error={state.error}
              // helperText={state.error}
              label={"Current Password"}
            />
            <TextField
              onChange={e => {
                let validation = {
                  eightCharacters: false,
                  upperLoawerCase: false,
                  oneNumber: false,
                };
                const eightCharacters = /^(\w){7,}$/;
                const oneUpper = /[a-z]+/;
                const oneLowercase = /[A-Z]+/;
                const oneNumber = /[0-9]+/;
                if (state.newPassword !== "") {
                  if (eightCharacters.test(state.newPassword)) {
                    validation.eightCharacters = true;
                  } else if (
                    oneUpper.test(state.newPassword) &&
                    oneLowercase.test(state.newPassword)
                  ) {
                    validation.upperLoawerCase = true;
                  } else if (oneNumber.test(state.newPassword)) {
                    validation.oneNumber = true;
                  }
                }
                setState({
                  ...state,
                  newPassword: e.target.value,
                  validation,
                });
              }}
              value={state.newPassword}
              name="newPassword"
              id="newPassword"
              type="password"
              error={state.error}
              // helperText={state.error}
              label={"Password"}
            />
            <TextField
              onChange={e => setState({ ...state, confirmPassword: e.target.value })}
              value={state.confirmPassword}
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              error={state.error}
              // helperText={state.error}
              label={"Re-type Password"}
            />
          </form>
          {(state.error || error) && <TextError>{error ? error : state.error}</TextError>}
          <div className="containerRules">
            <span className="descriptionRulesPassword">Your password must have:</span>
            <div className="passwordRule active">
              {validateEightCharacter(state.newPassword) ? (
                <CheckRoundGreen
                  stylesInput={{
                    height: "16.17px",
                    width: "16.17px",
                    opacity: 1,
                  }}
                />
              ) : (
                <CheckRoundGray
                  stylesInput={{
                    height: "16.17px",
                    width: "16.17px",
                    opacity: 1,
                  }}
                />
              )}

              <span
                className={`description ${
                  validateEightCharacter(state.newPassword) ? "active" : ""
                }`}
              >
                8 or more characters
              </span>
            </div>
            <div className="passwordRule">
              {validateUpperLoawerCase(state.newPassword) ? (
                <CheckRoundGreen
                  stylesInput={{
                    height: "16.17px",
                    width: "16.17px",
                    opacity: 1,
                  }}
                />
              ) : (
                <CheckRoundGray
                  stylesInput={{
                    height: "16.17px",
                    width: "16.17px",
                    opacity: 1,
                  }}
                />
              )}
              <span
                className={`description ${
                  validateUpperLoawerCase(state.newPassword) ? "active" : ""
                }`}
              >
                Upper and lowercase letters
              </span>
            </div>
            <div className="passwordRule">
              {validateOneNumber(state.newPassword) ? (
                <CheckRoundGreen
                  stylesInput={{
                    height: "16.17px",
                    width: "16.17px",
                    opacity: 1,
                  }}
                />
              ) : (
                <CheckRoundGray
                  stylesInput={{
                    height: "16.17px",
                    width: "16.17px",
                    opacity: 1,
                  }}
                />
              )}
              <span
                className={`description ${
                  validateOneNumber(state.newPassword) ? "active" : ""
                }`}
              >
                At least one number
              </span>
            </div>
          </div>
        </ModalContainerChangePassword>
        <ModalControls>
          <Button onClick={closeChangePasswordModal}>Cancel</Button>
          <Space />
          <Button
            disabled={
              !validateEightCharacter(state.newPassword) ||
              !validateUpperLoawerCase(state.newPassword) ||
              !validateOneNumber(state.newPassword)
            }
            primary
            onClick={handleChangeUserPassword}
          >
            Save
          </Button>
        </ModalControls>
      </CustomModal>
    );
  };
  const renderChangePhraseModal = () => {
    return (
      <CustomModal
        isOpen={phraseModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: 400,
            width: "100%",
            margin: "auto",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 0.3,
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            zIndex: 30,
            backgroundColor: "rgba(230, 230, 230, 0.8)",
          },
        }}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <HeadingModal>Change Personal Phrase</HeadingModal>
          <Cancel onClick={closeChangePhraseModal} />
        </div>
        <ModalContainerChangePhrase>
          <form>
            <TextField
              onChange={e => setState({ newPhrase: e.target.value })}
              value={state.newPhrase}
              name="newPhrase"
              id="newPhrase"
              type="text"
              error={state.error}
              // helperText={state.error}
              label={"Personal Phrase"}
              margin
            />
          </form>
          {state.error && <TextError>{state.error}</TextError>}
          <div className="description">
            <InfoGreen stylesInput={{ height: "17px", width: "17px" }} />
            <span className="text">
              A personal phrase is something you should be able to identify as yours right
              before you proceed to login.
            </span>
          </div>
        </ModalContainerChangePhrase>
        <ModalControls>
          <Button onClick={closeChangePhraseModal}>Cancel</Button>
          <Space />
          <Button primary onClick={handleChangeUserPhrase}>
            Save
          </Button>
        </ModalControls>
      </CustomModal>
    );
  };

  return (
    <DashboardScreens>
      <Container>
        {renderChangePasswordModal()}
        {renderChangePhraseModal()}

        <Loader isLoading={isFetching} />
        <ProfileForm>
          <Desktop>
            <div style={{ width: "70%", maxWidth: "570px" }}>
              <PageTitle>Security</PageTitle>
              <h2 className="subTitle" style={{ marginTop: "10%" }}>
                PASSWORD
              </h2>
              <div className="card" style={{ marginTop: "5%" }}>
                <span className="textLeft">••••••••••</span>
                <div className="right">
                  <span className="textRight">Change Password</span>
                  <div onClick={openChangePasswordModal}>
                    <Refresh
                      stylesInput={{
                        width: "18px",
                        height: "14px",
                        marginLeft: "10px",
                        opacity: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
              <h2 className="subTitle" style={{ marginTop: "10%" }}>
                PERSONAL PHRASE
              </h2>
              <div className="card" style={{ marginTop: "5%" }}>
                <span className="textLeft">••••••••••</span>
                <div className="right">
                  <span className="textRight">Change Phrase</span>
                  <div onClick={openChangePhraseModal}>
                    <Refresh
                      stylesInput={{
                        width: "18px",
                        height: "14px",
                        marginLeft: "10px",
                        opacity: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Desktop>

          <Mobile>
            <div style={{ width: "100%" }}>
              <h1 className="title">Security</h1>
              <h2 className="subTitle" style={{ marginTop: "10%" }}>
                PASSWORD
              </h2>
              <div className="card" style={{ marginTop: "5%" }}>
                <span className="textLeft">••••••••••</span>
                <div className="right">
                  <span className="textRight">Change Password</span>
                  <div onClick={openChangePasswordModal}>
                    <Refresh
                      stylesInput={{
                        width: "18px",
                        height: "14px",
                        marginLeft: "10px",
                        opacity: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
              <h2 className="subTitle" style={{ marginTop: "10%" }}>
                PERSONAL PHRASE
              </h2>
              <div className="card" style={{ marginTop: "5%" }}>
                <span className="textLeft">Des•• ••••• ••••••• ••er</span>
                <div className="right">
                  <span className="textRight">Change Phrase</span>
                  <div onClick={openChangePhraseModal}>
                    <Refresh
                      stylesInput={{
                        width: "18px",
                        height: "14px",
                        marginLeft: "10px",
                        opacity: 1,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Mobile>
        </ProfileForm>
      </Container>
    </DashboardScreens>
  );
}

Security.prototype = {
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  phrase: PropTypes.string,
  getCustomerProfile: PropTypes.func,
  saveCustomerProfile: PropTypes.func,
  accounts: PropTypes.object,
  saveAccountDescription: PropTypes.func,
  showAccountDescriptionModal: PropTypes.func,
  updateProfileForm: PropTypes.func,
  updateAccountDescription: PropTypes.func,
  customerProfile: PropTypes.object,
  changeUserPassword: PropTypes.func,
  changeUserPhrase: PropTypes.func,
  changePasswordModal: PropTypes.bool,
  isAccountDescriptionModalOpen: PropTypes.bool,
  phraseModal: PropTypes.bool,
  SecurityQuestionsModal: PropTypes.bool,
  openChangePasswordModal: PropTypes.func,
  closeChangePasswordModal: PropTypes.func,
  openPhraseModal: PropTypes.func,
  closePhraseModal: PropTypes.func,
  challengeQuestions: PropTypes.array,
  classes: PropTypes.object,
};

const ChallengeQuestionRow = ({ secret, answer, onChange, index }) => (
  <div className={styles.profileInformationToggleContent}>
    <TextField
      onChange={onChange}
      value={secret}
      type="text"
      name="secret"
      id={secret}
      // error={this.state.formError || this.state.amountError}
      // helperText={this.state.formError || this.state.amountError}
      label={`Question #${index + 1}`}
    />
    <TextField
      onChange={onChange}
      value={answer}
      type="text"
      name="answer"
      id={answer}
      // error={this.state.formError || this.state.amountError}
      // helperText={this.state.formError || this.state.amountError}
      label={`Answer Question #${index + 1}`}
    />
    <br />
  </div>
);
ChallengeQuestionRow.propTypes = {
  secret: PropTypes.string,
  answer: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  isFetching: state.account.isFetching,
  accounts: selectAccounts(state),
  customerProfile: state.account.customerProfile,
  challengeQuestions: state.user.challengeQuestions,
  changePasswordModal: state.user.changePasswordModal,
  phraseModal: state.user.phraseModal,
  phrase: state.account.customerProfile.phrase,
  securityQuestionsModal: state.user.securityQuestionsModal,
  isAccountDescriptionModalOpen: state.account.isAccountDescriptionModalOpen,
  error: state.user.error,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCustomerProfile,
    updateAccountDescription,
    saveCustomerProfile,
    saveAccountDescription,
    updateProfileForm,
    changeUserPassword,
    changeUserPhrase,
    openChangePasswordModal,
    closeChangePasswordModal,
    openPhraseModal,
    closePhraseModal,
    showAccountDescriptionModal,
  })(Security),
);
