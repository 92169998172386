/*
 *
 * Account Summary Page Actions
 *
 */
import { push } from "react-router-redux";
import * as types from "./constants";
import { handleHttpError } from "../../../utils/helper";
import { post } from "../../../utils/server";
const ROOT_URL = `/customer`;

export const getAccountSummary = (forFundsTransfer = false) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.REQUEST_GET_ACCOUNT_SUMMARY });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getsummary`, {
      idOLBUser,
      forFundsTransfer,
    });
    if (data.success) {
      dispatch({
        type: types.GET_ACCOUNT_SUMMARY_SUCCESS,
        payload: { summary: data.data, forFundsTransfer },
      });
    } else {
      dispatch({
        type: types.GET_ACCOUNT_SUMMARY_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (
      err?.response &&
      err?.response?.status !== 400 &&
      err?.response !== undefined &&
      err?.response?.data?.message
    ) {
      dispatch({
        type: types.GET_ACCOUNT_SUMMARY_FAILURE,
        payload: err.response.data.message,
      });
    } else {
      dispatch({
        type: types.GET_ACCOUNT_SUMMARY_FAILURE,
        payload: "error trying to get customer's accounts",
      });
    }
  }
};

export const getSavedSearches = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_SAVED_SEARCHES_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getsavedsearches`, { userId });
    if (data.success) {
      dispatch({ type: types.GET_SAVED_SEARCHES_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_SAVED_SEARCHES_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_SAVED_SEARCHES_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_SAVED_SEARCHES_FAILURE,
        payload: "error trying to get customer's saved searches",
      });
    }
  }
};

export const saveAndSearch = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SAVE_AND_PERFORM_SEARCH_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/saveandperformsearch`, {
      userId,
      ...payload,
    });
    if (data.success) {
      dispatch({ type: types.SAVE_AND_PERFORM_SEARCH_SUCCESS, payload: data.data });
      dispatch(push("/dashboard/accounts/search-results"));
    } else {
      dispatch({
        type: types.SAVE_AND_PERFORM_SEARCH_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.SAVE_AND_PERFORM_SEARCH_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.SAVE_AND_PERFORM_SEARCH_FAILURE,
        payload: "error trying to perform customer's saved searches",
      });
    }
  }
};

export const removeSearch = searchId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REMOVE_SAVED_SEARCH_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/removesavedsearch`, { userId, searchId });
    if (data.success) {
      dispatch({ type: types.REMOVE_SAVED_SEARCH_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.REMOVE_SAVED_SEARCH_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_SAVED_SEARCH_FAILURE,
        payload: err.response.data.message | "",
      });
    } else {
      dispatch({
        type: types.REMOVE_SAVED_SEARCH_FAILURE,
        payload: "error trying to remove customer's saved searches",
      });
    }
  }
};

export const performSearch = search => async (dispatch, getState) => {
  try {
    dispatch({ type: types.PERFORM_SEARCH_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/performsearch`, {
      userId,
      searchId: search.id,
    });
    if (data.success) {
      dispatch({
        type: types.PERFORM_SEARCH_SUCCESS,
        payload: { accountsSummary: data.data, lastSearch: search },
      });
      // set last search
      dispatch(push("/dashboard/accounts/search-results"));
    } else {
      dispatch({ type: types.PERFORM_SEARCH_FAILURE, payload: data.message });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.PERFORM_SEARCH_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.PERFORM_SEARCH_FAILURE,
        payload: "error trying to make customer's request",
      });
    }
  }
};

// messages
export const getCustomerMessages = params => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_GET_CUSTOMER_MESSAGES });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/messages`, { idOLBUser, ...params });
    if (data.success) {
      const { data: messages } = data;
      dispatch({ type: types.GET_CUSTOMER_MESSAGES_SUCCESS, payload: messages });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_MESSAGES_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_CUSTOMER_MESSAGES_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_MESSAGES_FAILURE,
        payload: "error trying to get customer's messages",
      });
    }
  }
};

export const markMessagesAsViewed = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "MARK_ALL_MESSAGES_AS_VIEWED_REQUEST" });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/allmsgviewed`, { idOLBUser });
    if (data.success) {
      dispatch({ type: "MARK_ALL_MESSAGES_AS_VIEWED_SUCCESS", payload: data.data });
    } else {
      dispatch({
        type: "MARK_ALL_MESSAGES_AS_VIEWED_FAILURE",
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: "MARK_ALL_MESSAGES_AS_VIEWED_FAILURE",
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: "MARK_ALL_MESSAGES_AS_VIEWED_FAILURE",
        payload: "error trying to get customer's messages",
      });
    }
  }
};

export const markNotificationsAsViewed = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "MARK_ALL_NOTIFICATIONS_AS_VIEWED_REQUEST" });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/allnotifviewed`, { idOLBUser });
    if (data.success) {
      dispatch({ type: "MARK_ALL_NOTIFICATIONS_AS_VIEWED_SUCCESS", payload: data.data });
    } else {
      dispatch({
        type: "MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE",
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: "MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE",
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: "MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE",
        payload: "error trying to get customer's messages",
      });
    }
  }
};

// remove message
export const removeMessage = messageId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_REMOVE_CUSTOMER_MESSAGE });
    const { data } = await post(`${ROOT_URL}/removemessage`, { messageId });
    if (data.success) {
      dispatch({ type: types.REMOVE_CUSTOMER_MESSAGE_SUCCESS, payload: data.data });
      dispatch(showRemoveMessageConfirmationModal(false));
      dispatch(showRemoveMessageSuccessModal(true));
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_MESSAGE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_CUSTOMER_MESSAGE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_MESSAGE_FAILURE,
        payload: "error trying to remove customer's messages",
      });
    }
  }
};
export const showRemoveMessageConfirmationModal = show => dispatch =>
  dispatch({ type: types.SHOW_REMOVE_MESSAGE_CONFIRMATION_MODAL, payload: show });
export const showRemoveMessageSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_REMOVE_MESSAGE_SUCCESS_MODAL, payload: show });
// notifications
export const getCustomerNotifications = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_GET_CUSTOMER_NOTIFICATIONS });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/notifications`, {
      idOLBUser,
      dismissed: false,
    });
    if (data.success) {
      const { data: notifications } = data;
      dispatch({
        type: types.GET_CUSTOMER_NOTIFICATIONS_SUCCESS,
        payload: notifications,
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_NOTIFICATIONS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_CUSTOMER_NOTIFICATIONS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_NOTIFICATIONS_FAILURE,
        payload: "error trying to get customer's notifications",
      });
    }
  }
};

// remove noti
export const removeNotification = notificationId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_REMOVE_CUSTOMER_NOTIFICATION });
    const { data } = await post(`${ROOT_URL}/removenotification`, {
      notificationId,
    });
    if (data.success) {
      dispatch({ type: types.REMOVE_CUSTOMER_NOTIFICATION_SUCCESS, payload: data.data });
      dispatch(showRemoveMessageConfirmationModal(false));
      dispatch(showRemoveMessageSuccessModal(true));
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_NOTIFICATION_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_CUSTOMER_NOTIFICATION_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_NOTIFICATION_FAILURE,
        payload: "error trying to remove customer's notifications",
      });
    }
  }
};

// transactions
export const getCustomerTransactions = ({
  uniqueId = null,
  accountType = null,
  type = null,
  from = "",
  to = "",
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_GET_CUSTOMER_TRANSACTIONS });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const {
      data: { data: transactions, success, error },
    } = await post(`${ROOT_URL}/transactions`, {
      idOLBUser,
      uniqueId,
      accountType,
      type,
      from,
      to,
    });
    if (success) {
      // console.log(transactions)
      dispatch({ type: types.GET_CUSTOMER_TRANSACTIONS_SUCCESS, payload: transactions });
    } else {
      dispatch({ type: types.GET_CUSTOMER_TRANSACTIONS_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_CUSTOMER_TRANSACTIONS_FAILURE,
        payload: err.response.data.message,
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_TRANSACTIONS_FAILURE,
        payload: "error trying to get customer's transactions",
      });
    }
  }
};

// get customer profile
export const getCustomerProfile = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CUSTOMER_PROFILE_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const {
      data: { data: customerProfile, success, error },
    } = await post(`${ROOT_URL}/getprofile`, { idOLBUser });

    if (success) {
      if (!customerProfile.userOLBAddress) {
        customerProfile.userOLBAddress = {
          city: "",
          address: "",
          address2: "",
          zip: "",
          state: "",
        };
      }

      if (!customerProfile.userOLBPhone) {
        customerProfile.userOLPhone = {
          phone: "",
          homePhone: "",
        };
      }

      dispatch({ type: types.GET_CUSTOMER_PROFILE_SUCCESS, payload: customerProfile });
    } else {
      dispatch({ type: types.GET_CUSTOMER_PROFILE_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_CUSTOMER_PROFILE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_PROFILE_FAILURE,
        payload: "error trying to get customer's profile",
      });
    }
  }
};

// save customer profile
export const saveCustomerProfile = profile => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SAVE_CUSTOMER_PROFILE_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const {
      data: { data: updatedProfile, success, error },
    } = await post(`${ROOT_URL}/saveprofile`, { idOLBUser, ...profile });
    if (success) {
      dispatch({ type: types.SAVE_CUSTOMER_PROFILE_SUCCESS, payload: updatedProfile });
    } else {
      dispatch({ type: types.SAVE_CUSTOMER_PROFILE_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.SAVE_CUSTOMER_PROFILE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.SAVE_CUSTOMER_PROFILE_FAILURE,
        payload: "error trying to save customer's profile",
      });
    }
  }
};

export const updateProfileForm = (name, value) => dispatch => {
  dispatch({ type: types.FORM_CUSTOMER_PROFILE_UPDATE_VALUE, payload: { name, value } });
};

export const updateAccountDescription = (uniqueId, name, value) => dispatch => {
  dispatch({
    type: types.ACCOUNT_DESCRIPTION_UPDATE_VALUE,
    payload: { uniqueId, name, value },
  });
};

export const saveAccountDescription = account => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SAVE_ACCOUNT_DESCRIPTION_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/saveaccountdesc`, { idOLBUser, ...account });
    if (data.success) {
      dispatch({ type: types.SAVE_ACCOUNT_DESCRIPTION_SUCCESS });
      dispatch(showAccountDescriptionModal(true));
    } else {
      dispatch({
        type: types.SAVE_ACCOUNT_DESCRIPTION_FAILURE,
        payload: data.message || "error trying to save customer's description",
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.SAVE_ACCOUNT_DESCRIPTION_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.SAVE_ACCOUNT_DESCRIPTION_FAILURE,
        payload: "error trying to save customer's description",
      });
    }
  }
};

export const getQuickenFile = ({ accountUniqueId, fromDate, toDate }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.REQUEST_GET_QUICKEN_FILE });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getquickenfile`, {
      userId,
      accountUniqueId,
      fromDate,
      toDate,
    });
    if (data) {
      dispatch({ type: types.GET_QUICKEN_FILE_SUCCESS, payload: data });
    } else if (data.error) {
      dispatch({ type: types.GET_QUICKEN_FILE_FAILURE, payload: data.message });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_QUICKEN_FILE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_QUICKEN_FILE_FAILURE,
        payload: "error trying to get customer's quicken file",
      });
    }
  }
};

export const showAccountDescriptionModal = show => dispatch =>
  dispatch({ type: "SHOW_ACCOUNT_DESCRIPTION_MODAL", payload: show });
export const showExcelDownloadModal = show => dispatch =>
  dispatch({ type: types.SHOW_EXCEL_DOWNLOAD_MODAL, payload: show });
export const openQuickenDownloadModal = () => dispatch =>
  dispatch({ type: types.OPEN_QUICKEN_DOWNLOAD_MODAL });
export const closeQuickenDownloadModal = () => dispatch =>
  dispatch({ type: types.CLOSE_QUICKEN_DOWNLOAD_MODAL });
