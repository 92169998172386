import React from "react";

import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { FinancialInstitutionSecuredFormsCustomerStatus } from "../../../../utils/enums";
import Checkbox from "../../../../components/commons/Checkbox";
import { isArchived } from "../../../../utils/containers/admin/forms";

const FormNormalContainer = styled.div`
  width: 100%;
  display: flex;

  .container-left {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4%;

    .star {
      stroke: #3fc78e;
      fill: #3fc78e;
    }

    .archive-selected-unread {
      stroke: #3fc78e;
    }

    .unread {
      stroke: #3fc78e;
    }
  }

  .container-right {
    flex: 1;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .accountName {
      font-size: 14px;
    }

    .title,
    .accountName,
    .date,
    .type {
      color: #141f2d;
      margin: 0px;
    }

    .header {
      display: flex;
      flex: 1;
    }

    .header {
      .source,
      .date {
        flex: 1;
      }

      .source {
        color: #8e949c;
        margin: 0px;
      }

      .date {
        text-align: right;
      }

      .date,
      .source {
        font-size: 12px;
      }
    }
  }
`;

const FormNormal = ({ source, date, title, accountName, status, checked, selected }) => {
  const getIcon = () => {
    const {
      FAVORITE,
      UNREAD,
      ARCHIVED_UNREAD,
      ARCHIVED_FAVORITE,
    } = FinancialInstitutionSecuredFormsCustomerStatus;

    if (isArchived(status)) {
      return status === ARCHIVED_FAVORITE ? (
        <FeatherIcon icon="star" className="star" size="20" />
      ) : status === ARCHIVED_UNREAD || selected ? (
        <FeatherIcon icon="archive" className="archive-selected-unread" size="20" />
      ) : (
        <FeatherIcon icon="archive" className="archive" size="20" />
      );
    }

    return status === FAVORITE ? (
      <FeatherIcon icon="star" className="star" size="20" />
    ) : status === UNREAD || selected ? (
      <FeatherIcon icon="file" className="unread" size="20" />
    ) : (
      <FeatherIcon icon="file" size="20" />
    );
  };

  return (
    <FormNormalContainer className="form-normal">
      <div className="container-left">
        {checked ? (
          <Checkbox
            checked={true}
            label={""}
            formControlLabelStyle={{ margin: 0 }}
            checkBoxStyle={{ padding: 0, color: checked ? "#3fc78e" : "currentColor" }}
          />
        ) : (
          getIcon(status)
        )}
      </div>
      <div className="container-right">
        <div className="header">
          <p className="source">
            Source: <span className="type">{source}</span>
          </p>
          <p className="date">{date}</p>
        </div>

        <p className="title">{title}</p>
        <p className="accountName">{accountName}</p>
      </div>
    </FormNormalContainer>
  );
};

FormNormal.propTypes = {
  source: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  accountName: PropTypes.number,
  status: PropTypes.string,
  selected: PropTypes.bool,
  checked: PropTypes.bool,
};

export default FormNormal;
