import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";

import { useReports } from "./hooks";
import Members from "./Members";
import Admins from "./Admins";
import { DashboardScreens } from "../../Dashboard/DashboardInstance/Dashboard";
import ControlPanel from "../../../components/commons/controlPanel";

const Container = styled.div`
  .MuiOutlinedInput-adornedStart {
    padding-left: 0px;
  }
`;

const Content = ({
  index = 0,
  data = {},
  setSearchQuery,
  searchQuery = "",
  filters = [],
}) => {
  const { members = [], admins = [] } = data;

  switch (index) {
    case 0:
      return (
        <Members
          data={members}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filters={filters}
        />
      );

    case 1:
      return (
        <Admins
          data={admins}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filters={filters}
        />
      );

    default:
      return (
        <Members
          data={members}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filters={filters}
        />
      );
  }
};

const ActivityReports = ({ isFetching, userId, fiId }) => {
  const adminFilterItems = [
    {
      id: 0,
      title: "",
      sub: [
        { id: 0, title: "Action" },
        { id: 1, title: "Username" },
        { id: 2, title: "Date" },
      ],
    },
  ];

  const membersFilterItems = [
    {
      id: 0,
      title: "",
      sub: [
        { id: 0, title: "Username" },
        { id: 1, title: "Date" },
      ],
    },
  ];

  const tabs = [
    {
      id: 1,
      iconName: "users",
      path: "/admin/panel/activity-reports",
      displayName: "Members",
    },
    {
      id: 2,
      iconName: "roles",
      path: "/admin/panel/activity-reports/admins",
      displayName: "Admins",
    },
  ];

  const [tabIndex, setTabIndex] = useState(0);
  const filterItems = tabIndex === 0 ? membersFilterItems : adminFilterItems;

  const [search, setSearch] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [activeFilters, setActiveFilters] = useState([filterItems[0].sub[0]]);

  const { members = [], admins = [] } = useReports({ userId, fiId });

  const handlerOnKeyPressSearch = e => {
    if (e.key === "Enter") {
      if (search.length > 0) {
        setIsSearch(true);
        // Get result search
      } else {
        // Get result search
      }
      e.preventDefault();
    }
  };

  const doClickTab = ({ displayName, path, iconName }, _tabIndex) => {
    setTabIndex(_tabIndex);
  };

  return (
    <DashboardScreens>
      <Container>
        <ControlPanel
          tabIndex={tabIndex}
          tabs={tabs}
          title="Activity Reports"
          filterItems={filterItems}
          valueSearch={search}
          onChangeSearch={e => setSearch(e.target.value)}
          doClickTab={doClickTab}
          onKeyPressSearch={handlerOnKeyPressSearch}
          deleteFilter={id => {
            const filter = activeFilters.filter(item => item.id !== id);
            setActiveFilters(filter);
            // Get result search
          }}
          activeFilters={activeFilters}
          setActiveFilters={filters => {
            setActiveFilters(filters);
            // Get result search
          }}
          loading={isFetching}
          formStyleSearch={{ marginLeft: "0px" }}
          endAdornmentSearch={
            search ? (
              <FeatherIcon
                icon="x"
                size="6"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                  if (isSearch) {
                    // Get result search
                    setIsSearch(false);
                  }
                }}
              />
            ) : null
          }
        />

        <MediaQuery query="(min-width: 768px)">
          <Content
            index={tabIndex}
            data={{ members, admins }}
            setSearchQuery={setSearch}
            searchQuery={search}
            filters={activeFilters}
          />
        </MediaQuery>
      </Container>
    </DashboardScreens>
  );
};

const mapStateToProps = ({ auth, admin, config }) => ({
  userId: admin.userId || auth.userId,
  fiId: config.financialInstitutionId,
});

const mapDispatchToProps = dispatch => ({});

export const tableTypes = {
  MEMBERS: "MEMBERS",
  ADMINS: "ADMINS",
};

export const REPORT_STATUS = {
  MEMBERS: {
    SIGN_IN_SUCCESSFUL: "SIGN_IN_SUCCESSFUL",
    SIGN_IN_FAILED: "SIGN_IN_FAILED",
    NONE: "NONE",
  },
  ADMINS: {
    READ: "READ",
    SECURED_FORM_READ: "SECURED_FORM_READ",
    DELETED: "DELETED",
    SUSPENDED: "SUSPENDED",
    RESET_PASSWORD: "RESET_PASSWORD",
    ADD_ADMIN: "ADD_ADMIN",
    LOCK: "LOCK",
    UNLOCK: "UNLOCK",
    NONE: "NONE",
  },
};

ActivityReports.protoType = {
  userId: PropTypes.number.isRequired,
  fiId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReports);
