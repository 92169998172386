import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Grid } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import queryString from "querystring";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { toast } from "react-toastify";
import { Button, TextField } from "../../../components/commons";
import { StepText, StepTitle } from "../steps/Steps";
import styles from "../steps/step.module.css";
import validatePassword from "../../../utils/isValidPassword";
import { doRecoveryPassword } from "../actions";

const forgotPasswordInputTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#3fc78e",
    },
    secondary: {
      main: "#3fc78e",
    },
  },
});

const svgGetSize = ({ size = 25 }) => `${size}px`;

const svgGetFilter = ({ isOk = false }) => `grayscale(${isOk ? "0" : "100"}%)`;

const Svg = styled.img`
  display: block;
  width: ${svgGetSize};
  min-width: ${svgGetSize};
  max-width: ${svgGetSize};
  height: ${svgGetSize};
  min-height: ${svgGetSize};
  max-height: ${svgGetSize};
  filter: ${svgGetFilter};
`;

function ChangePassword({ doRecoveryPassword, history }) {
  const queries = queryString.parse(window.location.search);

  const hash = queries["hash"] || queries["?hash"] || queries["&hash"] || queries.$hash;

  const idOLBUser =
    queries["idOLBUser"] ||
    queries["?idOLBUser"] ||
    queries["&idOLBUser"] ||
    queries.$idOLBUser;

  const [password, setPassword] = useState("");

  const [reTypePassword, setRreTypePassword] = useState("");

  const [isValidReTypePassword, setIsValidReTypePassword] = useState(false);

  const [rules, setRules] = useState([
    {
      id: 0,
      rule: /\w.{8,}/,
      label: "The string must be eight characters or longer",
    },
    {
      id: 1,
      rule: /\d/,
      label: "The string must contain at least 1 numeric character",
    },
    {
      id: 2,
      rule: /[a-z]/,
      label: "The string must contain at least 1 lowercase alphabetical character",
    },
    {
      id: 3,
      rule: /[A-Z]/,
      label: "The string must contain at least 1 uppercase alphabetical character",
    },
  ]);

  const isValidPassword = !!password && password.length > 5 && validatePassword(password);

  const isValid = isValidPassword && isValidReTypePassword;

  const handleOnSubmit = ({ target }) => {
    const payload = {
      idOLBUser,
      hash,
      password,
    };

    doRecoveryPassword(payload, async (success = false, user = {}) => {
      if (!!success) {
        toast.info("A confirmation email has been sent to your email address.", {
          onClose() {
            history.replace("/change-password-success", { user });
          },
        });
      } else {
        toast.error(
          "The confirmation message expired, please generate a new one and try again.",
        );
      }
    });
  };

  const handleChangePassword = ({ target }) => {
    setPassword(target.value);
  };

  const handleChangeReTypePassword = ({ target }) => {
    setRreTypePassword(target.value);
  };

  useEffect(() => {
    if (password === reTypePassword && password !== "" && reTypePassword !== "")
      setIsValidReTypePassword(true);
    else setIsValidReTypePassword(false);
  }, [password, reTypePassword]);

  const handleKeyPress = event => {
    const { key, preventDefault, stopPropagation } = event;

    if (key === "Enter") {
      preventDefault();

      stopPropagation();

      handleOnSubmit(event);
    }
  };

  const goToForgotYourPassword = () => {
    history.replace("/forgot-password");
  };

  return (
    <Container
      maxWidth="xs"
      style={{ minHeight: "100vh", display: "grid", paddingTop: 20 }}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        className={styles.newSecondStepAuthMainContainer}
      >
        <Grid item style={{ margin: "25px 0 50px 0" }}>
          <StepTitle className={styles.newSecondStepAuthMainContainerTitle}>
            Create Password
          </StepTitle>

          <StepTitle
            className={styles.newSecondStepAuthMainContainerChangePasswordSubTitle}
          >
            Define your access password
          </StepTitle>
        </Grid>

        <ThemeProvider theme={forgotPasswordInputTheme}>
          <Grid item style={{ marginBottom: 50 }}>
            <TextField
              type="password"
              label="Password"
              notMargin
              fullWidth
              onChange={handleChangePassword}
              value={password || ""}
              autoFocus
              onKeyPress={handleKeyPress}
              name="password"
              id="password"
              className={styles.newSecondStepAuthMainContainerInput}
              style={{ fontSize: 18, color: "#1b2846" }}
            />
          </Grid>

          <Grid item style={{ marginBottom: 50 }}>
            <TextField
              type="password"
              label="Re-type Password"
              notMargin
              fullWidth
              onChange={handleChangeReTypePassword}
              value={reTypePassword || ""}
              autoFocus
              onKeyPress={handleKeyPress}
              name="re-type-password"
              id="re-type-password"
              className={styles.newSecondStepAuthMainContainerInput}
              style={{ fontSize: 18, color: "#1b2846" }}
            />
          </Grid>
        </ThemeProvider>

        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          justify="flex-start"
          alignItems="stretch"
          style={{ marginBottom: 35 }}
        >
          {rules.map(({ id = undefined, label = "", rule = new RegExp("") }) => {
            const isOk = rule.test(reTypePassword) && rule.test(password);

            return (
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                justify="flex-start"
                alignItems="center"
                key={id}
                spacing={1}
                style={{ marginBottom: 5 }}
              >
                <Grid item style={{ width: 50 }}>
                  <Svg
                    isOk={isOk}
                    src="https://olb-assets.s3.us-east-2.amazonaws.com/images/tick.svg"
                    alt="Tick"
                  />
                </Grid>

                <Grid item>
                  <StepText
                    className={
                      isOk
                        ? styles.newSecondStepAuthMainContainerDetailsActive
                        : styles.newSecondStepAuthMainContainerDetails
                    }
                  >
                    {label}
                  </StepText>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justify="flex-start"
            alignItems="center"
            spacing={1}
            style={{ marginBottom: 5 }}
          >
            <Grid item style={{ width: 50 }}>
              <Svg
                isOk={isValidReTypePassword}
                src="https://olb-assets.s3.us-east-2.amazonaws.com/images/tick.svg"
                alt="Tick"
              />
            </Grid>

            <Grid item>
              <StepText
                className={
                  isValidReTypePassword
                    ? styles.newSecondStepAuthMainContainerDetailsActive
                    : styles.newSecondStepAuthMainContainerDetails
                }
              >
                The passwords must be the same
              </StepText>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} />

        <Grid container direction="column" item style={{ marginBottom: 50 }}>
          <Button
            primary={isValid}
            disabled={!isValid}
            onClick={handleOnSubmit}
            style={{
              width: "100%",
              fontWeight: "bold",
              fontSize: "1rem",
              marginBottom: 20,
              color:
                "-internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3))",
            }}
          >
            Reset Password
          </Button>

          <Button
            onClick={goToForgotYourPassword}
            style={{
              width: "100%",
              fontWeight: "bold",
              color:
                "-internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3))",
            }}
          >
            Back To Login
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

ChangePassword.propTypes = {
  handleChangeStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  // stepError: PropTypes.string,
  isFetching: PropTypes.bool,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

ChangePassword.defaultProps = {
  handleChangeStep: () => {},
  onSubmit: () => {},
};

export default connect(null, { goBack, doRecoveryPassword })(ChangePassword);
