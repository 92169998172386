import styled from "styled-components";

export default styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    & img {
      padding-bottom: 2em;
    }
  }
`;
