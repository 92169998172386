import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../components/commons";
import TextField from "../../../components/commons/TextField";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, CircularProgress } from "@material-ui/core";
import { Unlock, Eye, EyeOff } from "../../../components/commons/icons";
import {
  StepBox,
  StepBody,
  StepBackButton,
  StepTitle,
  StepControls,
  StepForm,
  CurrentStep,
  StepLogo,
  StepText,
} from "../steps/Steps";

class EnterTemporaryPassword extends React.Component {
  static defaultProps = {
    onSubmit: () => {},
    handleChangeStep: () => {},
  };
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleChangeStep: PropTypes.func,
    stepError: PropTypes.string,
    isFetching: PropTypes.bool,
    classes: PropTypes.object,
  };
  state = {
    temporaryPassword: "",
    showPassword: false,
    error: "",
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.handleOnSubmit();
    }
  };

  handleOnSubmit = () => {
    const { temporaryPassword } = this.state;
    if (temporaryPassword !== "" && temporaryPassword !== null) {
      this.setState({ error: null });
      this.props.onSubmit(this.state.temporaryPassword);
    } else {
      this.setState({ error: "please fill the form" });
    }
  };
  render() {
    const { config } = this.props;
    const renderEyes = this.state.showPassword ? (
      <EyeOff
        alt="show password"
        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
        style={{ position: "relative", marginRight: 10 }}
      />
    ) : (
      <Eye
        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
        style={{ position: "relative", marginRight: 10 }}
      />
    );
    return (
      <StepBox>
        <StepLogo src={config && config.fiLogo} />
        <StepBody>
          <CurrentStep step={"Step 2 of 3"} />
          <StepTitle>Please enter your password</StepTitle>
          <StepText>A temporary password was sent to your email.</StepText>
          <StepForm>
            <TextField
              onChange={event => this.setState({ temporaryPassword: event.target.value })}
              value={this.state.temporaryPassword}
              onKeyPress={this.handleKeyPress}
              type={this.state.showPassword ? "text" : "password"}
              name="temporary password"
              id="temporaryPassword"
              error={this.props.stepError || this.state.error}
              helperText={this.props.stepError || this.state.error}
              label={"Temporary password"}
              startAdornment={
                <InputAdornment position="start">
                  <Unlock />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="start">
                  {this.props.isFetching ? (
                    <CircularProgress
                      className={this.props.classes.progress}
                      style={{ color: "#05CE86", width: 30, height: 30 }}
                    />
                  ) : (
                    renderEyes
                  )}
                </InputAdornment>
              }
            />
          </StepForm>
          <StepControls>
            <Button primary onClick={this.handleOnSubmit}>
              Confirm
            </Button>
          </StepControls>
        </StepBody>
        <StepBackButton onClick={() => this.props.handleChangeStep("forgot_password")} />
      </StepBox>
    );
  }
}
const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});
export default withStyles(loaderStyles)(EnterTemporaryPassword);
