export const RECOVERY_PASSWORD = "recovery_password";
export const RECOVERY_PASSWORD_SUCCESS = "recovery_password_success";
export const RECOVERY_PASSWORD_FAILURE = "recovery_password_failure";
export const RECOVERY_PASSWORD_CRASH = "recovery_password_crash";

export const SEND_RECOVERY_PASSWORD_EMAIL = "send_recovery_password_email";
export const SEND_RECOVERY_PASSWORD_EMAIL_SUCCESS =
  "send_recovery_password_email_success";
export const SEND_RECOVERY_PASSWORD_EMAIL_FAILURE =
  "send_recovery_password_email_failure";
export const SEND_RECOVERY_PASSWORD_EMAIL_CRASH = "send_recovery_password_email_crash";

export const SEND_CONFIRMATION_CODE = "send_confirmation_code";
export const SEND_CONFIRMATION_CODE_SUCCESS = "send_confirmation_code_success";
export const SEND_CONFIRMATION_CODE_FAILURE = "send_confirmation_code_failure";

export const VERIFY_CONFIRMATION_CODE = "verify_confirmation_code";
export const VERIFY_CONFIRMATION_CODE_SUCCESS = "verify_confirmation_code_success";
export const VERIFY_CONFIRMATION_CODE_FAILURE = "verify_confirmation_code_failure";

export const FIRST_STEP_AUTH_USER_REQUEST = "first_step_auth_user_request";
export const FIRST_STEP_AUTH_USER_SUCCESS = "first_step_auth_user_success";
export const FIRST_STEP_AUTH_USER_FAILURE = "first_step_auth_user_failure";

export const SECOND_STEP_AUTH_USER_REQUEST = "second_step_auth_user_request";
export const SECOND_STEP_AUTH_USER_SUCCESS = "second_step_auth_user_success";
export const SECOND_STEP_AUTH_USER_FAILURE = "second_step_auth_user_failure";

export const THIRD_STEP_AUTH_USER_REQUEST = "third_step_auth_user_request";
export const THIRD_STEP_AUTH_USER_SUCCESS = "third_step_auth_user_success";
export const THIRD_STEP_AUTH_USER_FAILURE = "third_step_auth_user_failure";

export const UNBLOCK_USER_REQUEST = "unblock_user_request";
export const UNBLOCK_USER_SUCCESS = "unblock_user_success";
export const UNBLOCK_USER_FAILURE = "unblock_user_failure";

export const CHANGE_PASSWORD_REQUEST = "change_password_request";
export const CHANGE_PASSWORD_SUCCESS = "change_password_success";
export const CHANGE_PASSWORD_FAILURE = "change_password_failure";

export const CHANGE_NON_SECURE_PASSWORD_REQUEST = "change_non_secure_password_request";
export const CHANGE_NON_SECURE_PASSWORD_SUCCESS = "change_non_secure_password_success";
export const CHANGE_NON_SECURE_PASSWORD_FAILURE = "change_non_secure_password_failure";

export const FIRST_STEP_RECOVER_PASSWORD_REQUEST = "first_step_recover_password_request";
export const FIRST_STEP_RECOVER_PASSWORD_SUCCESS = "first_step_recover_password_success";
export const FIRST_STEP_RECOVER_PASSWORD_FAILURE = "first_step_recover_password_failure";

export const SECOND_STEP_RECOVER_PASSWORD_REQUEST =
  "second_step_recover_password_request";
export const SECOND_STEP_RECOVER_PASSWORD_SUCCESS =
  "second_step_recover_password_success";
export const SECOND_STEP_RECOVER_PASSWORD_FAILURE =
  "second_step_recover_password_failure";

export const THIRD_STEP_RECOVER_PASSWORD_REQUEST = "third_step_recover_password_request";
export const THIRD_STEP_RECOVER_PASSWORD_SUCCESS = "third_step_recover_password_success";
export const THIRD_STEP_RECOVER_PASSWORD_FAILURE = "third_step_recover_password_failure";

export const REQUEST_SMS_CODE = "request_sms_code";
export const REQUEST_SMS_CODE_SUCCESS = "request_sms_code_success";
export const REQUEST_SMS_CODE_FAILURE = "request_sms_code_failure";

export const VALIDATE_SMS_CODE_REQUEST = "validate_sms_code_request";
export const VALIDATE_SMS_CODE_SUCCESS = "validate_sms_code_success";
export const VALIDATE_SMS_CODE_FAILURE = "validate_sms_code_failure";

export const AUTH_USER = "auth_user";
export const UNAUTH_USER = "unauth_user";
export const AUTH_ERROR = "auth_error";

export const SHOW_TROUBLESHOOTING_INFO = "show_troubleshooting_info";

export const CHANGE_STEP_TO = "change_step_to";
