/*
 *
 * Account Summary Reducer
 *
 */

import _ from "lodash";
import * as types from "./constants";

const initialState = {
  isFetching: false,
  shares: null,
  creditCards: null,
  loans: null,
  messages: null,
  notifications: null,
  customerProfile: {},
  transactions: null,
  error: null,
  quickenDownloadError: null,
  fileData: null,
  isAccountDescriptionModalOpen: false,
  isRemoveMessageModalOpen: false,
  isRemoveMessageSuccessModalOpen: false,
  showExcelDoanloadModal: false,
  savedSearches: null,
  accountsSummary: null,
  lastSearch: null,
};

function accountReducer(state = initialState, action) {
  switch (action.type) {
    // accounts
    case types.REQUEST_GET_ACCOUNT_SUMMARY:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_ACCOUNT_SUMMARY_SUCCESS:
      const { forFundsTransfer, summary } = action.payload;
      if (forFundsTransfer) {
        return {
          ...state,
          isFetching: false,
          fundsTransferSummary: {
            loans: summary.loans,
            shares: summary.shares,
            creditCards: summary.creditCards,
          },
        };
      }
      return {
        ...state,
        isFetching: false,
        loans: summary.loans,
        shares: summary.shares,
        creditCards: summary.creditCards,
      };
    case types.GET_ACCOUNT_SUMMARY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    // messages
    case types.REQUEST_GET_CUSTOMER_MESSAGES:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CUSTOMER_MESSAGES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messages: action.payload,
      };
    case types.GET_CUSTOMER_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.REQUEST_REMOVE_CUSTOMER_MESSAGE:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.REMOVE_CUSTOMER_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        messages: _.filter(state.messages, msg => msg.id !== action.payload.idRemoved),
      };
    case types.REMOVE_CUSTOMER_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    // notifications
    case types.REQUEST_GET_CUSTOMER_NOTIFICATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CUSTOMER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notifications: action.payload,
        error: null,
      };
    case types.GET_CUSTOMER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.REQUEST_REMOVE_CUSTOMER_NOTIFICATION:
      return {
        ...state,
        isFetching: true,
      };
    case types.REMOVE_CUSTOMER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        notifications: _.filter(
          state.notifications,
          noti => noti.id !== action.payload.idRemoved,
        ),
      };
    case types.REMOVE_CUSTOMER_NOTIFICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    // transactions
    case types.REQUEST_GET_CUSTOMER_TRANSACTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CUSTOMER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transactions: action.payload,
      };
    case types.GET_CUSTOMER_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.GET_CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customerProfile: action.payload,
      };
    case types.GET_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.SAVE_CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SAVE_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        customerProfile: action.payload,
        isFetching: false,
      };
    case types.SAVE_CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.SAVE_ACCOUNT_DESCRIPTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SAVE_ACCOUNT_DESCRIPTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.SAVE_ACCOUNT_DESCRIPTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case "SHOW_ACCOUNT_DESCRIPTION_MODAL":
      return {
        ...state,
        isAccountDescriptionModalOpen: action.payload,
      };
    case types.SHOW_EXCEL_DOWNLOAD_MODAL:
      return {
        ...state,
        showExcelDoanloadModal: action.payload,
      };
    case types.FORM_CUSTOMER_PROFILE_UPDATE_VALUE:
      if (
        action.payload.name === "address" ||
        action.payload.name === "address2" ||
        action.payload.name === "zip" ||
        action.payload.name === "state" ||
        action.payload.name === "city"
      ) {
        state.customerProfile.userOLBAddress = {
          ...state.customerProfile.userOLBAddress,
          [action.payload.name]: action.payload.value,
        };
        return {
          ...state,
          customerProfile: {
            ...state.customerProfile,
          },
        };
      }

      if (action.payload.name === "phone" || action.payload.name === "homePhone") {
        state.customerProfile.userOLBPhone = {
          ...state.customerProfile.userOLBPhone,
          [action.payload.name]: action.payload.value,
        };
        return {
          ...state,
          customerProfile: {
            ...state.customerProfile,
          },
        };
      }

      return {
        ...state,
        customerProfile: {
          ...state.customerProfile,
          [action.payload.name]: action.payload.value,
        },
      };
    case types.ACCOUNT_DESCRIPTION_UPDATE_VALUE:
      const allAccounts = state.shares.concat(state.loans);
      const rowToUpdate =
        allAccounts &&
        _.find(allAccounts, row => row.uniqueId === action.payload.uniqueId);
      return {
        ...state,
        shares:
          state.shares &&
          rowToUpdate &&
          _.map(state.shares, row => {
            if (row.uniqueId === action.payload.uniqueId) {
              return {
                ...row,
                [action.payload.name]: action.payload.value,
                updated: true,
              };
            }
            return row;
          }),
        loans:
          state.loans &&
          rowToUpdate &&
          _.map(state.loans, row => {
            if (row.uniqueId === action.payload.uniqueId) {
              return {
                ...row,
                [action.payload.name]: action.payload.value,
                updated: true,
              };
            }
            return row;
          }),
      };
    case types.SHOW_REMOVE_MESSAGE_CONFIRMATION_MODAL:
      return {
        ...state,
        isRemoveMessageModalOpen: action.payload,
      };
    case types.SHOW_REMOVE_MESSAGE_SUCCESS_MODAL:
      return {
        ...state,
        isRemoveMessageSuccessModalOpen: action.payload,
      };
    case "MARK_ALL_MESSAGES_AS_VIEWED_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MARK_ALL_MESSAGES_AS_VIEWED_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case "MARK_ALL_MESSAGES_AS_VIEWED_SUCCESS":
      return {
        ...state,
        isFetching: false,
        messages: state.messages && _.map(state.messages, m => ({ ...m, viewed: true })),
      };
    case "MARK_ALL_NOTIFICATIONS_AS_VIEWED_REQUEST":
      return {
        ...state,
        isFetching: true,
      };
    case "MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE":
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case "MARK_ALL_NOTIFICATIONS_AS_VIEWED_SUCCESS":
      return {
        ...state,
        isFetching: false,
        notifications:
          state.notifications &&
          _.map(state.notifications, n => ({ ...n, viewed: true })),
      };
    case types.OPEN_QUICKEN_DOWNLOAD_MODAL:
      return {
        ...state,
        quickenDownloadModal: true,
      };
    case types.CLOSE_QUICKEN_DOWNLOAD_MODAL:
      return {
        ...state,
        quickenDownloadModal: false,
        quickenDownloadError: null,
      };
    case types.REQUEST_GET_QUICKEN_FILE:
      return state;
    case types.GET_QUICKEN_FILE_SUCCESS:
      return {
        ...state,
        fileData: action.payload,
        quickenDownloadModal: false,
      };
    case types.GET_QUICKEN_FILE_FAILURE:
      return {
        ...state,
        quickenDownloadError: action.payload,
      };
    case types.GET_SAVED_SEARCHES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_SAVED_SEARCHES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        savedSearches: [{ id: "0", name: "Select an Option" }, ...action.payload],
      };
    case types.GET_SAVED_SEARCHES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.SAVE_AND_PERFORM_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.SAVE_AND_PERFORM_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountsSummary: action.payload,
      };
    case types.SAVE_AND_PERFORM_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.PERFORM_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.PERFORM_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        accountsSummary: action.payload.accountsSummary,
        lastSearch: action.payload.lastSearch,
      };
    case types.PERFORM_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.REMOVE_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.REMOVE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        savedSearches:
          state.savedSearches &&
          _.filter(state.savedSearches, s => s.id !== action.payload.idRemoved),
      };
    case types.REMOVE_SAVED_SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default accountReducer;
