import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import CurrencyFormat from "react-currency-format";
import { withStyles } from "@material-ui/core/styles";
import { handleSubmitSSOForm, getCurrentTime } from "../../../utils/helper";
import { ConfirmationModal } from "../../../components/commons/Modals";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import {
  MobileTable,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import {
  Loader,
  Box,
  Space,
  Heading,
  PageTitle,
  P,
  OverflowContent,
  TextError,
  HeadingModal,
} from "../../../components/commons";
import {
  Plus,
  PieChart,
  Cancel,
  Link as ExternalLink,
} from "../../../components/commons/icons";
import { AddButton } from "../Goals/GoalsInstance";
import { CustomModal } from "../../../components/commons/Modals";
import { LoanRequestInformation } from "../Loans";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import TextField from "../../../components/commons/TextField";
import { selectAccounts } from "./selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";
// actions
import * as actions from "./actions";
import * as ssoActions from "../SSO/actions";
import enums from "../../../utils/enums";

// donload file
const fileDownload = require("js-file-download");
const Json2csvParser = require("json2csv").Parser;

const Container = styled.div`
  flex: 1;
  height: 100%;
`;

const QuickenDateForm = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 967px) {
    display: flex;
    flex-direction: column;
  }
`;

const AccountPageBody = styled.div``;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
    borderSpacing: 0,
  },
  table: {
    minWidth: 700,
    border: "1px solid #EBEFF3",
    borderRadius: 5,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
    height: "auto",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FCFCFC",
    color: "#8E949C",
    fontSize: "14px",
    padding: "1rem",
    fontWeight: "normal",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    borderBottom: "1px solid rgba(18,55,77,0.08)",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "1rem 1rem",
    fontSize: "15px",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#141F2D",
    lineHeight: "1rem",
    borderBottom: "1px solid rgba(18,55,77,0.08)",
    fontFamily: "Kumbh Sans",
    width: "15%",
  },
}))(TableCell);

class AccountSummaryPage extends Component {
  static propTypes = {
    push: PropTypes.func,
    creditCardRowSsoList: PropTypes.array,
    transactions: PropTypes.array,
    accountsButtonsSsoList: PropTypes.array,
    getCustomerMessages: PropTypes.func,
    getCustomerNotifications: PropTypes.func,
    getCustomerTransactions: PropTypes.func,
    getAccountSummary: PropTypes.func,
    getSsoList: PropTypes.func,
    account: PropTypes.object,
    isFetching: PropTypes.bool,
    classes: PropTypes.object,
    redirectUrl: PropTypes.string,
    isPost: PropTypes.bool,
    formParams: PropTypes.array,
    quickenDownloadModal: PropTypes.bool,
    isExcelDoanloadModalOpen: PropTypes.bool,
    quickenDownloadError: PropTypes.string,
    closeQuickenDownloadModal: PropTypes.func,
    openQuickenDownloadModal: PropTypes.func,
    showExcelDownloadModal: PropTypes.func,
    getQuickenFile: PropTypes.func,
    fileData: PropTypes.string,
    showCreateLoanModal: PropTypes.func,
  };
  state = {
    quickenDownloadAccountDescription: "",
    quickenDownloadAccountUniqueId: "",
    quickenFromDate: getCurrentTime(),
    quickenToDate: getCurrentTime(),
    excelFromDate: getCurrentTime(),
    excelToDate: getCurrentTime(),
    filterTransactionError: null,
    isOpenCustomModalLoan: false,
  };

  uppercase(str) {
    if (str) {
      str = str.toLowerCase();
      var array1 = str.split(" ");
      var newarray1 = [];
      for (var x = 0; x < array1.length; x++) {
        newarray1.push(array1[x].charAt(0).toUpperCase() + array1[x].slice(1));
      }
      return newarray1.join(" ");
    }
  }

  async componentDidMount() {
    try {
      const {
        getAccountSummary,
        getCustomerMessages,
        getCustomerNotifications,
        getCustomerTransactions,
        getSsoList,
      } = this.props;
      await Promise.all([
        getAccountSummary(),
        getCustomerMessages({}),
        getCustomerTransactions({}),
        getCustomerNotifications(),
        //getSsoList(),
      ]);
    } catch (err) {
      throw err;
    }
  }

  closeQuickenDownloadModal = () => {
    this.setState({ quickenFromDate: getCurrentTime(), quickenToDate: getCurrentTime() });
    this.props.closeQuickenDownloadModal();
  };
  quickenDownloadButtonClicked = async account => {
    await this.setState({
      quickenDownloadAccountDescription:
        !account.description || account.description === ""
          ? account.defaultDescription
          : account.description,
      quickenDownloadAccountUniqueId: account.uniqueId,
      quickenFromDate: getCurrentTime(),
      quickenToDate: getCurrentTime(),
    });
    this.props.openQuickenDownloadModal();
  };

  getResponse = response => {
    this.setState(response);
  };

  handleOnClickDonloadExcel(account) {
    this.setState({
      quickenDownloadAccountDescription:
        !account.description || account.description === ""
          ? account.defaultDescription
          : account.description,
      quickenDownloadAccountUniqueId: account.uniqueId,
      selectedAccountType: account.accountType,
      excelFromDate: getCurrentTime(),
      excelToDate: getCurrentTime(),
      filterTransactionError: null,
    });
    this.props.showExcelDownloadModal(true);
  }

  // description id created pendingtype postingtype amount
  async handleFilterExcelData() {
    try {
      const { transactions } = this.props;
      const {
        excelFromDate,
        excelToDate,
        quickenDownloadAccountUniqueId: uniqueId,
        selectedAccountType: accountType,
      } = this.state;
      if (transactions && excelFromDate && excelToDate) {
        await this.props.getCustomerTransactions({
          uniqueId,
          accountType,
          from: moment(excelFromDate).utc().format("MM/DD/YYYY"),
          to: moment(excelToDate).utc().format("MM/DD/YYYY"),
        });
        let customerTransactions = { ...transactions };
        if (customerTransactions) {
          customerTransactions = _.map(
            customerTransactions,
            ({ description, accountId, type, amount, pendingType }) => ({
              description,
              accountId,
              type,
              amount,
              pendingType,
            }),
          );
          const fields = ["accountId", "description", "pendingType", "type", "amount"];
          const json2csvParser = new Json2csvParser({ fields });
          const csv = json2csvParser.parse(customerTransactions);
          fileDownload(csv, "attachment.csv");
          this.props.showExcelDownloadModal(false);
        } else {
          this.setState({
            filterTransactionError: "No records found for those dates, try again.",
          });
        }
      }
    } catch (err) {
      this.setState({
        filterTransactionError: "No records found for those dates, try again.",
      });
    }
  }

  handleGetQuickenFile = async () => {
    const { quickenFromDate, quickenToDate, quickenDownloadAccountUniqueId } = this.state;
    if (quickenDownloadAccountUniqueId && quickenFromDate && quickenToDate) {
      await this.props.getQuickenFile({
        accountUniqueId: quickenDownloadAccountUniqueId,
        fromDate: moment(quickenFromDate).utc().format("MM/DD/YYYY"),
        toDate: moment(quickenToDate).utc().format("MM/DD/YYYY"),
      });
      if (this.props.fileData && !this.props.quickenDownloadError) {
        fileDownload(this.props.fileData, "attachment.qfx");
      }
    }
  };

  async ssoClicked(e, sso) {
    e.preventDefault();
    await this.props.getSsoRedirectUrl(sso.id);
    if (this.props.isPost && this.props.formParams) {
      handleSubmitSSOForm(this.props.redirectUrl, this.props.formParams, "post");
    } else {
      handleSubmitSSOForm(this.props.redirectUrl, this.props.formParams, "get");
    }
  }
  handleSubmitSSOForm(formInstance, url, type) {
    if (type === "get") {
      return window.open(url);
    }
    formInstance.setAttribute("method", type);
    formInstance.setAttribute("action", url);
    formInstance.setAttribute("target", "ssoForm");
    window.open(url, "ssoForm");
    return formInstance.submit();
  }
  // MODALS HTML
  renderQuickenDownloadModal() {
    return (
      <ConfirmationModal
        isOpen={this.props.quickenDownloadModal}
        onClose={this.closeQuickenDownloadModal}
        onSuccess={this.handleGetQuickenFile}
        headingText="Quicken Download"
        confirmationText={
          <div>
            <P>
              Confirm Quicken download for account{" "}
              <strong>{this.state.quickenDownloadAccountDescription}</strong>
            </P>
            {this.props.quickenDownloadError && (
              <TextError>{this.props.quickenDownloadError}</TextError>
            )}
            <QuickenDateForm>
              <TextField
                id="quickenFromDate"
                label="From Date"
                type="date"
                shrink
                style={{ maxWidth: 140, minWidth: "100%" }}
                value={this.state.quickenFromDate}
                onChange={e => this.setState({ quickenFromDate: e.target.value })}
              />
              <Space />
              <TextField
                style={{ maxWidth: 140, minWidth: "100%" }}
                id="quickenToDate"
                label="To Date"
                type="date"
                shrink
                value={this.state.quickenToDate}
                onChange={e => this.setState({ quickenToDate: e.target.value })}
              />
            </QuickenDateForm>
          </div>
        }
        cancelButtonText="Cancel"
        successButtonText={"Download"}
        aria-hidden={this.props.quickenDownloadModal}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  renderExcelDownloadModal() {
    return (
      <ConfirmationModal
        isOpen={this.props.isExcelDoanloadModalOpen}
        onClose={() => this.props.showExcelDownloadModal(false)}
        onSuccess={() => this.handleFilterExcelData()}
        headingText="Excel CSV Download"
        confirmationText={
          <div>
            <P>
              Confirm Excel download for account{" "}
              <strong>{this.state.quickenDownloadAccountDescription}</strong>
            </P>
            {this.state.filterTransactionError && (
              <TextError>{this.state.filterTransactionError}</TextError>
            )}
            <QuickenDateForm>
              <TextField
                id="excelFromDate"
                label="From Date"
                type="date"
                style={{ maxWidth: 140, minWidth: "100%" }}
                value={this.state.excelFromDate}
                onChange={e => this.setState({ excelFromDate: e.target.value })}
              />
              <Space />
              <TextField
                style={{ maxWidth: 140, minWidth: "100%" }}
                id="excelToDate"
                label="To Date"
                type="date"
                value={this.state.excelToDate}
                onChange={e => this.setState({ excelToDate: e.target.value })}
              />
            </QuickenDateForm>
          </div>
        }
        cancelButtonText="Cancel"
        successButtonText={"Download"}
        aria-hidden={this.props.quickenDownloadModal}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <DashboardScreens>
        <Container>
          {this.renderQuickenDownloadModal()}
          {this.renderExcelDownloadModal()}
          <Loader isLoading={this.props.isFetching} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageTitle style={{ width: "50%" }}>Home</PageTitle>
            <div className="dropdown">
              <AddButton primary>
                <Plus />
              </AddButton>
              <div className="dropdown-content">
                <a
                  href="#any"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ isOpenCustomModalLoan: true });
                  }}
                >
                  New Loan Application
                </a>
                <a href="#any">New Savings Account</a>
                <a href="#any">New Credit Card</a>
                <a href="#any">New Share Account</a>
              </div>
            </div>
          </div>
          <AccountPageBody>
            <Desktop>
              <div>
                {/* <Box>
                <Button
                  primary
                  onClick={() =>
                    this.props.push('/dashboard/accounts/search')
                  }
                >
                  Advanced Search
                </Button>
                <Space />
                <Button
                  primary
                  onClick={() => this.props.push('/dashboard/accounts/history')}
                >
                  transaction History
                </Button>
                <Space />
                {this.props.accountsButtonsSsoList &&
                  _.map(this.props.accountsButtonsSsoList, (sso, i) => (
                    <Button
                      key={sso.id + i}
                      outline
                      onClick={(e) => this.ssoClicked(e, sso)}
                    >
                      {sso.buttonText || sso.name || 'External site'}
                    </Button>
                  ))}
              </Box> */}
                {/* <Separator style={{ margin: '2rem 0' }} /> */}
                <OverflowContent>
                  <Heading>SHARES</Heading>
                  <Table className={classes.table}>
                    <TableHead className={classes.thead}>
                      <TableRow className={classes.row}>
                        <CustomTableCell>Description</CustomTableCell>
                        <CustomTableCell>Goals</CustomTableCell>
                        <CustomTableCell>Account</CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                        {/*
                        TODO: It is commented because the core no longer has the rate in share accounts
                        <CustomTableCell>Rate</CustomTableCell>
                      */}
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Balance
                        </CustomTableCell>
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Available
                        </CustomTableCell>
                        {/*
                        TODO: It is commented because the core no longer has the matury date
                        <CustomTableCell>Maturity Date</CustomTableCell>
                      */}
                        {/* <CustomTableCell>Download</CustomTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.account.shares &&
                      this.props.account.shares.length > 0 ? (
                        _.map(
                          this.props.account.shares.filter(
                            item => item.type !== enums.AccountType.SHARE_DRAFT,
                          ),
                          (item, index) => (
                            <TableRow className={classes.row} key={item.uniqueId + index}>
                              <CustomTableCell>
                                {item.allowTransactionsInquiry ? (
                                  <Link
                                    style={{ fontWeight: "700" }}
                                    to={`/dashboard/accounts/${item.uniqueId}/${item.accountType}/history`}
                                  >
                                    {item.isTrusted ? (
                                      <span>
                                        {item.description || item.defaultDescription}
                                        <span
                                          style={{
                                            color: "rgb(102, 203, 255)",
                                            fontStyle: "italic",
                                            fontSize: 12,
                                          }}
                                        >
                                          &nbsp;({item.fullName})
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        {item.description || item.defaultDescription}
                                      </span>
                                    )}
                                  </Link>
                                ) : item.isTrusted ? (
                                  <span>
                                    {item.description || item.defaultDescription}
                                    <span
                                      style={{
                                        color: "rgb(102, 203, 255)",
                                        fontStyle: "italic",
                                        fontSize: 12,
                                      }}
                                    >
                                      &nbsp;({item.fullName})
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {item.description || item.defaultDescription}
                                  </span>
                                )}
                              </CustomTableCell>
                              <CustomTableCell>
                                {item.hasGoal && (
                                  <PieChart
                                    onClick={() => this.props.push("/dashboard/goals")}
                                  />
                                )}
                              </CustomTableCell>
                              <CustomTableCell>{item.id}</CustomTableCell>
                              {/*
                            TODO: It is commented because the core no longer has the rate in share accounts
                            <CustomTableCell>{item.rate}</CustomTableCell>
                          */}
                              <CustomTableCell></CustomTableCell>
                              <CustomTableCell></CustomTableCell>
                              <CustomTableCell style={{ textAlign: "right" }}>
                                <CurrencyFormat
                                  value={item.balance}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              </CustomTableCell>
                              <CustomTableCell style={{ textAlign: "right" }}>
                                <CurrencyFormat
                                  value={item.available}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              </CustomTableCell>
                              {/*
                              TODO: It is commented because the core no longer has the matury date
                              <CustomTableCell><CurrencyFormat value={item.maturityDate} displayType={'text'} thousandSeparator prefix={'$'} /></CustomTableCell>
                          */}
                              {/* <CustomTableCell>
                          It is commented because it is not using now
                            <div style={{ display: 'flex', marginLeft: item.isTrusted && '9.7rem' }} >

                              {!item.isTrusted && <Button style={{ marginRight: 10 }} md primary onClick={() => this.quickenDownloadButtonClicked(item)}>Quicken (QDF)</Button>}
                              <Button md primary onClick={() => this.handleOnClickDonloadExcel(item)}>Excel (CSV)</Button>
                            </div>
                          </CustomTableCell> */}
                            </TableRow>
                          ),
                        )
                      ) : (
                        <TableRow>
                          <CustomTableCell>{"You don't have shares"}</CustomTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <br />
                  <Heading>DRAFTS</Heading>
                  <Table className={classes.table}>
                    <TableHead className={classes.thead}>
                      <TableRow className={classes.row}>
                        <CustomTableCell>Description</CustomTableCell>
                        <CustomTableCell>Goals</CustomTableCell>
                        <CustomTableCell>Account</CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                        <CustomTableCell></CustomTableCell>
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Balance
                        </CustomTableCell>
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Available
                        </CustomTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.account.shares &&
                      this.props.account.shares.length > 0 ? (
                        _.map(
                          this.props.account.shares.filter(
                            item => item.type === enums.AccountType.SHARE_DRAFT,
                          ),
                          (item, index) => (
                            <TableRow className={classes.row} key={item.uniqueId + index}>
                              <CustomTableCell>
                                {item.allowTransactionsInquiry ? (
                                  <Link
                                    style={{ fontWeight: "700" }}
                                    to={`/dashboard/accounts/${item.uniqueId}/${item.accountType}/history`}
                                  >
                                    {item.isTrusted ? (
                                      <span>
                                        {item.description || item.defaultDescription}
                                        <span
                                          style={{
                                            color: "rgb(102, 203, 255)",
                                            fontStyle: "italic",
                                            fontSize: 12,
                                          }}
                                        >
                                          &nbsp;({item.fullName})
                                        </span>
                                      </span>
                                    ) : (
                                      <span>
                                        {item.description || item.defaultDescription}
                                      </span>
                                    )}
                                  </Link>
                                ) : item.isTrusted ? (
                                  <span>
                                    {item.description || item.defaultDescription}
                                    <span
                                      style={{
                                        color: "rgb(102, 203, 255)",
                                        fontStyle: "italic",
                                        fontSize: 12,
                                      }}
                                    >
                                      &nbsp;({item.fullName})
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {item.description || item.defaultDescription}
                                  </span>
                                )}
                              </CustomTableCell>
                              <CustomTableCell>
                                {item.hasGoal && (
                                  <PieChart
                                    onClick={() => this.props.push("/dashboard/goals")}
                                  />
                                )}
                              </CustomTableCell>
                              <CustomTableCell>{item.id}</CustomTableCell>
                              <CustomTableCell></CustomTableCell>
                              <CustomTableCell></CustomTableCell>
                              <CustomTableCell style={{ textAlign: "right" }}>
                                <CurrencyFormat
                                  value={item.balance}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              </CustomTableCell>
                              <CustomTableCell style={{ textAlign: "right" }}>
                                <CurrencyFormat
                                  value={item.available}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              </CustomTableCell>
                            </TableRow>
                          ),
                        )
                      ) : (
                        <TableRow>
                          <CustomTableCell>{"You don't have Drafts"}</CustomTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <br />
                  <Heading>LOANS</Heading>
                  <Table className={classes.table}>
                    <TableHead className={classes.thead}>
                      <TableRow className={classes.row}>
                        <CustomTableCell>Description</CustomTableCell>
                        <CustomTableCell>Goals</CustomTableCell>
                        <CustomTableCell>Account</CustomTableCell>
                        {/* TODO: It is commented because the core no longer has the rate
                      <CustomTableCell>Rate</CustomTableCell> */}
                        <CustomTableCell>Next Due Date</CustomTableCell>
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Pmt Amount
                        </CustomTableCell>
                        {/* TODO: It is commented because the core no longer has the min amt due
                      <CustomTableCell>Min Amt Due</CustomTableCell> */}
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Balance
                        </CustomTableCell>
                        <CustomTableCell style={{ textAlign: "right" }}>
                          Available
                        </CustomTableCell>
                        {/* TODO: It is commented because the core no longer has the payoff
                      <CustomTableCell>Payoff</CustomTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.account.loans && this.props.account.loans.length > 0 ? (
                        _.map(this.props.account.loans, (item, i) => (
                          <TableRow className={classes.row} key={item.uniqueId + i}>
                            <CustomTableCell>
                              {item.allowTransactionsInquiry ? (
                                <Link
                                  style={{ fontWeight: "700" }}
                                  to={`/dashboard/accounts/${item.uniqueId}/${item.accountType}/history`}
                                >
                                  {item.isTrusted ? (
                                    <span>
                                      {this.uppercase(item.description) ||
                                        this.uppercase(item.defaultDescription)}
                                      <span
                                        style={{
                                          color: "rgb(102, 203, 255)",
                                          fontStyle: "italic",
                                          fontSize: 12,
                                        }}
                                      >
                                        ({item.fullName})
                                      </span>
                                    </span>
                                  ) : (
                                    <span>
                                      {this.uppercase(item.description) ||
                                        this.uppercase(item.defaultDescription)}
                                    </span>
                                  )}
                                </Link>
                              ) : item.isTrusted ? (
                                <span>
                                  {item.description || item.defaultDescription}
                                  <span
                                    style={{
                                      color: "rgb(102, 203, 255)",
                                      fontStyle: "italic",
                                      fontSize: 12,
                                    }}
                                  >
                                    ({item.fullName})
                                  </span>
                                </span>
                              ) : (
                                <span>{item.description || item.defaultDescription}</span>
                              )}
                            </CustomTableCell>
                            <CustomTableCell>
                              {item.hasGoal && (
                                <PieChart
                                  onClick={() => this.props.push("/dashboard/goals")}
                                />
                              )}
                            </CustomTableCell>
                            <CustomTableCell>{item.id}</CustomTableCell>
                            {/* TODO: It is commented because the core no longer has the rate
                          <CustomTableCell>{item.rate}</CustomTableCell> */}
                            <CustomTableCell>{item.nextDueDate}</CustomTableCell>
                            <CustomTableCell style={{ textAlign: "right" }}>
                              <CurrencyFormat
                                value={item.regularPmt}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            {/* TODO: It is commented because the core no longer has the min amt date
                          <CustomTableCell><CurrencyFormat value={item.minAmtDate} displayType={'text'} thousandSeparator prefix={'$'} /></CustomTableCell> */}
                            <CustomTableCell style={{ textAlign: "right" }}>
                              <CurrencyFormat
                                value={item.balance}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            <CustomTableCell style={{ textAlign: "right" }}>
                              <CurrencyFormat
                                value={item.available}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            {/* TODO: It is commented because the core no longer has the payoff
                          <CustomTableCell><CurrencyFormat value={item.payoff} displayType={'text'} thousandSeparator prefix={'$'} /></CustomTableCell> */}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <CustomTableCell>{"You don't have loans"}</CustomTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <br />
                  <Heading>CREDIT CARD</Heading>
                  <Table className={classes.table}>
                    {/* <TableHead className={classes.thead}>
                      <TableRow className={classes.row}>
                        {this.props.creditCardRowSsoList.length > 0 ? (
                          <CustomTableCell></CustomTableCell>
                        ) : null}
                        <CustomTableCell>Card</CustomTableCell>
                        <CustomTableCell>Card Name</CustomTableCell>
                        <CustomTableCell>As Of Date</CustomTableCell>
                        <CustomTableCell>Credit Limit</CustomTableCell>
                        <CustomTableCell>Min Amt Due</CustomTableCell>
                        <CustomTableCell>Due Date</CustomTableCell>
                        <CustomTableCell>Balance</CustomTableCell>
                        <CustomTableCell>Available</CustomTableCell>
                        <CustomTableCell>Last Pmt Date</CustomTableCell>
                        <CustomTableCell>Last Pmt Amt</CustomTableCell>
                      </TableRow>
                    </TableHead> */}
                    <TableBody>
                      {this.props.account.creditCards &&
                      this.props.account.creditCards.length > 0 ? (
                        _.map(this.props.account.creditCards, (item, index) => (
                          <TableRow className={classes.row} key={item.uniqueId + index}>
                            {this.props.creditCardRowSsoList &&
                            this.props.creditCardRowSsoList.length > 0 ? (
                              <CustomTableCell>
                                {this.props.creditCardRowSsoList &&
                                  _.map(this.props.creditCardRowSsoList, (sso, i) => {
                                    return (
                                      <button
                                        key={sso.id + i}
                                        onClick={e => this.ssoClicked(e, sso)}
                                      >
                                        <ExternalLink />
                                      </button>
                                    );
                                  })}
                              </CustomTableCell>
                            ) : (
                              ""
                            )}
                            <CustomTableCell>{item.cardNumber}</CustomTableCell>
                            <CustomTableCell>{item.cardName}</CustomTableCell>
                            <CustomTableCell>{item.asOfDate}</CustomTableCell>
                            <CustomTableCell>
                              <CurrencyFormat
                                value={item.creditLimit}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <CurrencyFormat
                                value={item.minAmtDate}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            <CustomTableCell>{item.dueDate}</CustomTableCell>
                            <CustomTableCell>
                              <CurrencyFormat
                                value={item.balance}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            <CustomTableCell>
                              <CurrencyFormat
                                value={item.available}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                            <CustomTableCell>{item.lastPmtDate}</CustomTableCell>
                            <CustomTableCell>
                              <CurrencyFormat
                                value={item.lastPmtAmt}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <CustomTableCell>
                            {"You don't have credit cards"}
                          </CustomTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </OverflowContent>
              </div>
            </Desktop>
            <Mobile>
              <div>
                {/* <Box column>
                <Button
                  primary
                  onClick={() =>
                    this.props.push('/dashboard/accounts/search')
                  }
                >
                  Advanced Search
                </Button>
                <Space />
                <Button
                  primary
                  onClick={() => this.props.push('/dashboard/accounts/history')}
                >
                  transaction History
                </Button>
                <Space />
                {this.props.accountsButtonsSsoList &&
                  _.map(this.props.accountsButtonsSsoList, (sso, i) => (
                    <Button
                      key={sso.id + i}
                      outline
                      onClick={(e) => this.ssoClicked(e, sso)}
                    >
                      {sso.buttonText}
                    </Button>
                  ))}
              </Box> */}

                <MobileTable title={"SHARES"}>
                  {this.props.account.shares && this.props.account.shares.length > 0 ? (
                    _.map(
                      this.props.account.shares,
                      (
                        {
                          id,
                          description,
                          defaultDescription,
                          rate,
                          hasGoal,
                          accountType,
                          balance,
                          available,
                          maturityDate,
                          uniqueId,
                          fullName,
                          isTrusted,
                          display,
                          allowTransactionsInquiry,
                        },
                        index,
                      ) => (
                        <MobileTableRow key={uniqueId + index}>
                          <Box
                            column
                            fx={1}
                            onClick={() =>
                              this.props.push(
                                `/dashboard/accounts/${uniqueId}/${accountType}/history`,
                              )
                            }
                          >
                            <MobileTableHeading
                              name={"Description"}
                              value={
                                isTrusted ? (
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <span style={{ flex: 1 }}>
                                      <b>{description || defaultDescription}</b>
                                      <span
                                        style={{
                                          color: "rgb(102, 203, 255)",
                                          fontStyle: "italic",
                                          fontSize: 12,
                                        }}
                                      >
                                        &nbsp;({fullName})
                                      </span>
                                    </span>
                                    {/* {hasGoal && <PieChart onClick={() => this.props.push('/dashboard/goals')} />} */}
                                  </div>
                                ) : (
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <span style={{ flex: 1 }}>
                                      <b>{description || defaultDescription}</b>
                                    </span>
                                    {/* {hasGoal && <PieChart onClick={() => this.props.push('/dashboard/goals')} />} */}
                                  </div>
                                )
                              }
                            />
                            <Box className="home" fx={1}>
                              <div>
                                {/* <MobileTableCell name={'Id'} value={String(id)} /> */}
                                {/* TODO: It is commented because the core no longer has the rate
                              <MobileTableCell name={'Rate'} value={rate} /> */}
                                <MobileTableCell
                                  name={"Balance"}
                                  value={
                                    <CurrencyFormat
                                      value={balance}
                                      displayType={"text"}
                                      thousandSeparator
                                      prefix={"$"}
                                    />
                                  }
                                />
                                <MobileTableCell
                                  name={"Available"}
                                  value={
                                    <CurrencyFormat
                                      value={available}
                                      displayType={"text"}
                                      thousandSeparator
                                      prefix={"$"}
                                    />
                                  }
                                />
                                {/*
                                TODO: It is commented because the core no longer has the matury date
                                <MobileTableCell name={'Maturity Date'} value={<CurrencyFormat value={maturityDate} displayType={'text'} thousandSeparator prefix={'$'} />} />
                              */}
                              </div>
                              {/* {allowTransactionsInquiry
                              && (
                                <GoArray>
                                  <ChevronRightBlack onClick={() => this.props.push(`/dashboard/accounts/${uniqueId}/${accountType}/history`)} />
                                </GoArray>
                              )
                            } */}
                            </Box>
                          </Box>
                        </MobileTableRow>
                      ),
                    )
                  ) : (
                    <MobileTableRow>
                      <P>{"You don't have any shares"}</P>
                    </MobileTableRow>
                  )}
                </MobileTable>
                <MobileTable title={"DRAFTS"}>
                  {this.props.account.shares && this.props.account.shares.length > 0 ? (
                    _.map(
                      this.props.account.shares.filter(
                        item => item.type === enums.AccountType.SHARE_DRAFT,
                      ),
                      (
                        {
                          id,
                          description,
                          defaultDescription,
                          hasGoal,
                          accountType,
                          balance,
                          available,
                          uniqueId,
                          fullName,
                          isTrusted,
                          allowTransactionsInquiry,
                        },
                        index,
                      ) => (
                        <MobileTableRow key={uniqueId + index}>
                          <Box
                            column
                            fx={1}
                            onClick={() =>
                              this.props.push(
                                `/dashboard/accounts/${uniqueId}/${accountType}/history`,
                              )
                            }
                          >
                            <MobileTableHeading
                              name={"Description"}
                              value={
                                isTrusted ? (
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <span style={{ flex: 1 }}>
                                      <b>{description || defaultDescription}</b>
                                      <span
                                        style={{
                                          color: "rgb(102, 203, 255)",
                                          fontStyle: "italic",
                                          fontSize: 12,
                                        }}
                                      >
                                        &nbsp;({fullName})
                                      </span>
                                    </span>
                                    {/* {hasGoal && <PieChart onClick={() => this.props.push('/dashboard/goals')} />} */}
                                  </div>
                                ) : (
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <span style={{ flex: 1 }}>
                                      <b>{description || defaultDescription}</b>
                                    </span>
                                    {/* {hasGoal && <PieChart onClick={() => this.props.push('/dashboard/goals')} />} */}
                                  </div>
                                )
                              }
                            />
                            <Box fx={1} className="home">
                              <div>
                                <MobileTableCell
                                  name={"Balance"}
                                  value={
                                    <CurrencyFormat
                                      value={balance}
                                      displayType={"text"}
                                      thousandSeparator
                                      prefix={"$"}
                                    />
                                  }
                                />
                                <MobileTableCell
                                  name={"Available"}
                                  value={
                                    <CurrencyFormat
                                      value={available}
                                      displayType={"text"}
                                      thousandSeparator
                                      prefix={"$"}
                                    />
                                  }
                                />
                              </div>
                              {/* {allowTransactionsInquiry
                              && (
                                <GoArray>
                                  <ChevronRightBlack onClick={() => this.props.push(`/dashboard/accounts/${uniqueId}/${accountType}/history`)} />
                                </GoArray>
                              )
                            } */}
                            </Box>
                          </Box>
                        </MobileTableRow>
                      ),
                    )
                  ) : (
                    <MobileTableRow>
                      <P>{"You don't have any shares"}</P>
                    </MobileTableRow>
                  )}
                </MobileTable>
                <MobileTable title={"LOANS"}>
                  {this.props.account.loans && this.props.account.loans.length > 0 ? (
                    _.map(
                      this.props.account.loans,
                      (
                        {
                          display,
                          accountType,
                          hasGoal,
                          allowTransactionsInquiry,
                          isTrusted,
                          fullName,
                          uniqueId,
                          id,
                          description,
                          defaultDescription,
                          rate,
                          balance,
                          available,
                          regularPmt,
                          minAmtDate,
                          nextDueDate,
                          payoff,
                        },
                        i,
                      ) => (
                        <MobileTableRow key={uniqueId + i}>
                          <Box
                            column
                            fx={1}
                            onClick={() =>
                              this.props.push(
                                `/dashboard/accounts/${uniqueId}/${accountType}/history`,
                              )
                            }
                          >
                            <MobileTableHeading
                              name={"Description"}
                              value={
                                isTrusted ? (
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <span style={{ flex: 1 }}>
                                      {this.uppercase(description || defaultDescription)}
                                      <span>&nbsp;({fullName})</span>
                                    </span>
                                    {/* {hasGoal && <PieChart onClick={() => this.props.push('/dashboard/goals')} />} */}
                                  </div>
                                ) : (
                                  <div style={{ display: "flex", width: "100%" }}>
                                    <span style={{ flex: 1 }}>
                                      <b>
                                        {this.uppercase(
                                          description || defaultDescription,
                                        )}
                                      </b>
                                    </span>
                                    {/* {hasGoal && <PieChart onClick={() => this.props.push('/dashboard/goals')} />} */}
                                  </div>
                                )
                              }
                            />
                            <Box fx={1} className="home">
                              <div>
                                {/* <MobileTableCell name={'Id'} value={String(id)} /> */}
                                {/* TODO: It is commented because the core no longer has the rate
                              <MobileTableCell name={'Rate'} value={rate} /> */}
                                <MobileTableCell
                                  name={"Pmt Amount"}
                                  value={
                                    <CurrencyFormat
                                      value={regularPmt}
                                      displayType={"text"}
                                      thousandSeparator
                                      prefix={"$"}
                                    />
                                  }
                                />
                                {/* <MobileTableCell name={'min amt due'} value={<CurrencyFormat value={minAmtDate} displayType={'text'} thousandSeparator prefix={'$'} />} /> */}
                                <MobileTableCell
                                  name={"Next due date"}
                                  value={<span>{nextDueDate}</span>}
                                />
                                <MobileTableCell
                                  name={"Balance"}
                                  value={
                                    <CurrencyFormat
                                      value={balance}
                                      displayType={"text"}
                                      thousandSeparator
                                      prefix={"$"}
                                    />
                                  }
                                />
                                {/* <MobileTableCell name={'Available'} value={<CurrencyFormat value={available} displayType={'text'} thousandSeparator prefix={'$'} />} /> */}
                                {/* <MobileTableCell name={'payoff'} value={<CurrencyFormat value={payoff} displayType={'text'} thousandSeparator prefix={'$'} />} /> */}
                              </div>
                              {/* {allowTransactionsInquiry
                              && (
                                <GoArray>
                                  <ChevronRightBlack onClick={() => this.props.push(`/dashboard/accounts/${uniqueId}/${accountType}/history`)} />
                                </GoArray>
                              )
                            } */}
                            </Box>
                          </Box>
                        </MobileTableRow>
                      ),
                    )
                  ) : (
                    <MobileTableRow>
                      <P>{"You don't have any loans"}</P>
                    </MobileTableRow>
                  )}
                </MobileTable>
                <MobileTable title={"CREDIT CARDS"}>
                  {this.props.account.creditCards &&
                  this.props.account.creditCards.length > 0 ? (
                    _.map(
                      this.props.account.creditCards,
                      (
                        {
                          uniqueId,
                          cardName,
                          cardNumber,
                          asOfDate,
                          creditLimit,
                          minAmtDate,
                          dueDate,
                          balance,
                          available,
                          lastPmtDate,
                          lastPmtAmt,
                        },
                        idx,
                      ) => (
                        <MobileTableRow key={uniqueId + idx}>
                          <div>
                            <MobileTableHeading value={cardName} />
                            <MobileTableCell name={"Card"} value={cardNumber} />
                            <MobileTableCell name={"Card name"} value={cardName} />
                            <MobileTableCell name={"as of date"} value={asOfDate} />
                            <MobileTableCell
                              name={"credit limit"}
                              value={
                                <CurrencyFormat
                                  value={creditLimit}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              }
                            />
                            <MobileTableCell
                              name={"min amt due"}
                              value={
                                <CurrencyFormat
                                  value={minAmtDate}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              }
                            />
                            <MobileTableCell name={"due date"} value={dueDate} />
                            <MobileTableCell
                              name={"balance"}
                              value={
                                <CurrencyFormat
                                  value={balance}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              }
                            />
                            <MobileTableCell
                              name={"available"}
                              value={
                                <CurrencyFormat
                                  value={available}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              }
                            />
                            <MobileTableCell name={"last pmt date"} value={lastPmtDate} />
                            <MobileTableCell
                              name={"last pmt amt"}
                              value={
                                <CurrencyFormat
                                  value={lastPmtAmt}
                                  displayType={"text"}
                                  thousandSeparator
                                  prefix={"$"}
                                />
                              }
                            />
                          </div>
                        </MobileTableRow>
                      ),
                    )
                  ) : (
                    <MobileTableRow>
                      <P>{"You don't have credit cards"}</P>
                    </MobileTableRow>
                  )}
                </MobileTable>
              </div>
            </Mobile>
          </AccountPageBody>
          <CustomModal isOpen={this.state.isOpenCustomModalLoan}>
            <div className="CustomModalHeader">
              <HeadingModal>New Loan</HeadingModal>
              <Cancel onClick={() => this.setState({ isOpenCustomModalLoan: false })} />
            </div>
            <LoanRequestInformation setState={this.getResponse} />
          </CustomModal>
        </Container>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = state => ({
  account: selectAccounts(state),
  transactions: state.account.transactions,
  error: state.account.error,
  isFetching: state.account.isFetching,
  creditCardRowSsoList: state.sso.creditCardRowSsoList,
  accountsButtonsSsoList: state.sso.accountsButtonsSsoList,
  redirectUrl: state.sso.redirectUrl,
  isPost: state.sso.isPost,
  formParams: state.sso.formParams,
  quickenDownloadModal: state.account.quickenDownloadModal,
  quickenDownloadError: state.account.quickenDownloadError,
  fileData: state.account.fileData,
  isExcelDoanloadModalOpen: state.account.showExcelDoanloadModal,
});

const concatenatedActions = { ...actions, ...ssoActions };

export default withStyles(styles)(
  connect(mapStateToProps, { ...concatenatedActions, push })(AccountSummaryPage),
);
