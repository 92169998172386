import React from "react";
import styled from "styled-components";
import { Box, P, PageTitle } from "../../../components/commons";
import { DashboardScreens } from "../DashboardInstance/Dashboard";
const Container = styled.div`
  flex: 1;
`;
const PaymentDisclosure = () => (
  <DashboardScreens>
    <Container>
      <PageTitle>Payment Disclosure</PageTitle>
      <Box>
        <P>
          NSFCU is pleased to offer E-Pay, which provides our members the convenience of
          on-line bill payment services through E-Branch. All E-Branch disclosures apply
          and by signing the Automated Services Request Form, you authorize us to serve as
          your agent in processing payments which you create and schedule through E-Pay
          services and you authorize us to post such payments to your designated account.
          E-Pay is currently available only to members with a NSFCU share draft (checking)
          account and who have signed up for E-statements. We limit the number of free
          transactions to 15 per month and may charge a fee for subsequent transactions.
          In order to utilize this service you must understand and agree that the
          following apply:
          <ul>
            <li>
              We may not make certain payments if sufficient funds are not available in
              your designated account.
            </li>
            <li>
              We will process payments only to those payees for whom we have a proper
              name, billing address and valid account number. We will not process payments
              if the required transaction information is incomplete.
            </li>
            <li>
              In order for a payment to be processed, it must be set up properly by 10:00
              am on the intended transaction date. The funds will be withdrawn from your
              designated account at any time on the date we process payments.
            </li>
            <li>
              We will process your payment within one (1) business day of the date you
              schedule it for payment. Payments are not processed on Saturdays, Sundays,
              or holidays; payments scheduled on such dates will be processed the
              following business day.
            </li>
            <li>
              Payments made by paper check are mailed via the US Postal Service and may be
              subject to a delay in delivery. You must allow sufficient time for mail
              delivery and for the payee to process your payment after they receive it
              from us. You should allow as much time as if you were mailing the payment
              yourself.
            </li>
            <li>
              Some payments will be processed in a paperless ACH/EFT format and delivered
              to your payee electronically.
            </li>
            <li>
              We cannot guarantee the date and/or time that any payment will be credited
              to your account by the payee.
            </li>
            <li>
              We will not be responsible for any late fees, charges or penalties you incur
              as a result of a payment not being scheduled in a manner that allows for
              sufficient delivery and processing time.
            </li>
            <li>
              To prevent a payment from posting, you must un-schedule the payment prior to
              the date you had it scheduled to be paid.
            </li>
            <li>
              You are responsible for verifying the accuracy of payment information before
              the payment is scheduled or processed. We will not be liable for any payment
              initiated by you which contains inaccurate payment amounts, account
              information, or payee information.
            </li>
          </ul>
        </P>
      </Box>
    </Container>
  </DashboardScreens>
);

export default PaymentDisclosure;
