import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import Modal from "react-modal";
import TextField from "../../../components/commons/TextField";
import { SuccessModal } from "../../../components/commons/Modals";
import { InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import {
  Toggle,
  Button,
  Separator,
  Loader,
  Box,
  Space,
  P,
  Small,
  Heading,
  PageTitle,
  TextError,
} from "../../../components/commons";
import { Collapsable, Header, Content } from "../../../components/commons/Collapsable";
import enums from "../../../utils/enums";
import {
  ArrowDown,
  ArrowUp,
  Cancel,
  Check,
  Edit,
} from "../../../components/commons/icons";
import {
  getCustomerProfile,
  saveCustomerProfile,
  updateProfileForm,
  saveAccountDescription,
  updateAccountDescription,
  showAccountDescriptionModal,
} from "./actions";
import { selectAccounts } from "./selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

import {
  getChallengeQuestions,
  saveChallengeQuestions,
  changeUserPassword,
  changeUserPhrase,
  openChangePasswordModal,
  closeChangePasswordModal,
  closeSuccessModal,
  openPhraseModal,
  closePhraseModal,
  updateChallengeQuestionsValues,
} from "../user/actions";

import styles from "./styles.module.css";

const Container = styled.div`
  flex: 1;
`;

const ProfileForm = styled.form``;

class AccountPersonalInformationPage extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    getCustomerProfile: PropTypes.func,
    saveCustomerProfile: PropTypes.func,
    accounts: PropTypes.object,
    saveAccountDescription: PropTypes.func,
    showAccountDescriptionModal: PropTypes.func,
    updateProfileForm: PropTypes.func,
    updateAccountDescription: PropTypes.func,
    updateChallengeQuestionsValues: PropTypes.func,
    getChallengeQuestions: PropTypes.func,
    saveChallengeQuestions: PropTypes.func,
    customerProfile: PropTypes.object,
    changeUserPassword: PropTypes.func,
    changeUserPhrase: PropTypes.func,
    changePasswordModal: PropTypes.bool,
    isAccountDescriptionModalOpen: PropTypes.bool,
    successModal: PropTypes.bool,
    phraseModal: PropTypes.bool,
    openChangePasswordModal: PropTypes.func,
    closeChangePasswordModal: PropTypes.func,
    closeSuccessModal: PropTypes.func,
    openPhraseModal: PropTypes.func,
    closePhraseModal: PropTypes.func,
    challengeQuestions: PropTypes.array,
    classes: PropTypes.object,
  };
  state = {
    newPassword: "",
    confirmPassword: "",
    newPhrase: "",
    confirmPhrase: "",
    modalChallengeQuestionSuccessIsOpen: false,
    inputError: null,
    inputsWithError: [],
  };
  componentDidMount() {
    this.props.getCustomerProfile();
    this.props.getChallengeQuestions();
  }
  openChangePasswordModal = e => {
    e.preventDefault();
    this.props.openChangePasswordModal();
  };
  closeChangePasswordModal = () => {
    this.setState({ error: null });
    this.props.closeChangePasswordModal();
  };
  openChangePhraseModal = e => {
    e.preventDefault();
    this.props.openPhraseModal();
  };
  closeChangePhraseModal = () => {
    this.setState({ error: null });
    this.props.closePhraseModal();
  };
  openChallengeQuestionSuccessModal = () => {
    this.setState({ modalChallengeQuestionSuccessIsOpen: true });
  };
  closeChallengeQuestionsSuccessModal = () => {
    this.setState({ modalChallengeQuestionSuccessIsOpen: false });
  };
  handleOnChange = event => this.setState({ [event.target.name]: event.target.value });

  handleChangeUserPhrase = () => {
    const { newPhrase, confirmPhrase } = this.state;
    if (newPhrase !== "" && confirmPhrase !== "") {
      if (newPhrase === confirmPhrase) {
        this.setState({ error: null });
        this.props.changeUserPhrase({ newPhrase, confirmPhrase });
      } else {
        this.setState({ error: "phrases don't match" });
      }
    } else {
      this.setState({ error: "please complete the form" });
    }
  };
  handleChangeUserPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        this.setState({ error: null });
        this.props.changeUserPassword({ newPassword, confirmPassword });
      } else {
        this.setState({ error: "passwords don't match, try again" });
      }
    } else {
      this.setState({ error: "please complete the form" });
    }
  };
  handleChangeChallengeRow = (id, e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.props.updateChallengeQuestionsValues(id, name, value);
  };
  handleSumbitProfileForm(e) {
    e.preventDefault();
    const {
      name,
      address,
      address2,
      address3,
      email,
      mobile,
      homePhone,
      city,
      state,
      zip,
    } = !_.isEmpty(this.props.customerProfile) && this.props.customerProfile;
    //if (name && address && email && mobile && homePhone && city && state ) {
    this.props.saveCustomerProfile({
      name,
      address,
      address2,
      address3,
      email,
      mobile,
      homePhone,
      city,
      state,
      zip,
    });
    //}
    //else {
    // handle form validation
    //}
  }
  handleSubmitChallengeQuestions = async e => {
    e.preventDefault();
    const { challengeQuestions } = this.props;
    // validate then send save request
    if (challengeQuestions && challengeQuestions.length > 0) {
      const promises = _.map(
        challengeQuestions,
        ({ id, secret, answer }) =>
          id && this.props.saveChallengeQuestions({ id, secret, answer }),
      );
      await Promise.all(promises);
      this.openChallengeQuestionSuccessModal();
    } else {
      this.setState({ challengeQuestionError: "please fill all the fields" });
    }
  };
  handleSaveAccountDescription = e => {
    e.preventDefault();
    this.setState({ inputsWithError: [], inputError: null });

    const { shares, loans } = this.props.accounts;
    // get the inputs that were updated
    const sharesToUpdate =
      shares &&
      _.map(
        shares,
        ({ uniqueId, description, updated }) =>
          updated && { uniqueId, description, accountType: enums.AccountType.SHARE },
      );
    const loansToUpdate =
      loans &&
      _.map(
        loans,
        ({ uniqueId, description, updated }) =>
          updated && { uniqueId, description, accountType: enums.AccountType.LOAN },
      );

    // concat shares and loans accounts
    let accountsToUpdate = sharesToUpdate.concat(loansToUpdate);
    accountsToUpdate =
      accountsToUpdate && _.filter(accountsToUpdate, acc => !undefined && acc);

    // validate inputs description that were updated
    let inputsWithError = _.map(
      accountsToUpdate,
      account => account.description === "" && account.uniqueId,
    );
    inputsWithError =
      inputsWithError.length > 0 &&
      _.filter(inputsWithError, inputId => inputId !== false);

    // if theres inputs without description display error validation
    if (inputsWithError && inputsWithError.length > 0) {
      return this.setState({
        inputsWithError,
        inputError: "please add a valid description",
      });
    }
    // else save accounts description
    const promises =
      accountsToUpdate &&
      _.map(accountsToUpdate, account => this.props.saveAccountDescription(account));
    return Promise.all(promises);
  };
  renderChangePasswordModal() {
    return (
      <Modal
        isOpen={this.props.changePasswordModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: 400,
            width: "100%",
            padding: "1rem 1.5rem 3rem 1.5rem",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 0.3,
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            zIndex: 30,
            backgroundColor: "rgba(230, 230, 230, 0.8)",
          },
        }}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <span style={{ flex: 3 }}>
              <Heading>Please Complete Password Update</Heading>
            </span>
            <span style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              <Cancel onClick={this.closeChangePasswordModal} />
            </span>
          </div>
          <Separator style={{ marginBottom: "0.6" }} />
          <form style={{ display: "flex", flexDirection: "column", padding: "0 1.5rem" }}>
            <TextField
              onChange={e => this.setState({ newPassword: e.target.value })}
              value={this.state.newPassword}
              name="newPassword"
              id="newPassword"
              type="password"
              error={this.state.error}
              // helperText={this.state.error}
              label={"New Password"}
            />
            <TextField
              onChange={e => this.setState({ confirmPassword: e.target.value })}
              value={this.state.password}
              name="password"
              id="password"
              type="password"
              error={this.state.error}
              // helperText={this.state.error}
              label={"Confirm Password"}
            />
          </form>
          {this.state.error && <TextError>{this.state.error}</TextError>}
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button primary onClick={this.handleChangeUserPassword}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
  renderChangePhraseModal() {
    return (
      <Modal
        isOpen={this.props.phraseModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: 400,
            width: "100%",
            margin: "auto",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 0.3,
            transform: "translate(-50%, -50%)",
          },
          overlay: {
            zIndex: 30,
            backgroundColor: "rgba(230, 230, 230, 0.8)",
          },
        }}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", padding: 8 }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <span style={{ flex: 3 }}>
              <Heading>Please Complete Phrase Update</Heading>
            </span>
            <span style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
              <Cancel onClick={this.closeChangePhraseModal} />
            </span>
          </div>
          <Separator style={{ marginBottom: "0.6" }} />
          <form style={{ display: "flex", flexDirection: "column", padding: "0 1.5rem" }}>
            <TextField
              onChange={e => this.setState({ newPhrase: e.target.value })}
              value={this.state.newPhrase}
              name="newPhrase"
              id="newPhrase"
              type="text"
              error={this.state.error}
              // helperText={this.state.error}
              label={"New Phrase"}
            />

            <TextField
              onChange={e => this.setState({ confirmPhrase: e.target.value })}
              value={this.state.confirmPhrase}
              name="confirmPhrase"
              id="confirmPhrase"
              type="text"
              error={this.state.error}
              // helperText={this.state.error}
              label={"Confirm Phrase"}
            />
          </form>
          {this.state.error && <TextError>{this.state.error}</TextError>}
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button primary onClick={this.handleChangeUserPhrase}>
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
  renderChallengeQuestionsSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.state.modalChallengeQuestionSuccessIsOpen}
        onClose={this.closeChallengeQuestionsSuccessModal}
        headingText="Challenge questions saved successfully"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderAccountDescriptionSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.isAccountDescriptionModalOpen}
        onClose={() => this.props.showAccountDescriptionModal(false)}
        headingText="Bank Accounts Updated succesfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderSuccessModal() {
    return (
      <Modal
        isOpen={this.props.successModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            width: "100%",
            maxWidth: "400",
            margin: "auto",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
          },
          overlay: {
            zIndex: 30,
            backgroundColor: "rgba(230, 230, 230, 0.8)",
          },
        }}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ flex: 3 }}>
            <Heading>Update Successful!</Heading>
          </span>
          <span style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Cancel onClick={() => this.props.closeSuccessModal()} />
          </span>
        </div>
        <div>
          <Separator />
          <br />
          <span style={{ textAlign: "center" }}>
            <Check />
          </span>
        </div>
      </Modal>
    );
  }

  render() {
    const {
      name,
      address,
      address2,
      address3,
      email,
      mobile,
      homePhone,
      city,
      state,
      zip,
    } = this.props.customerProfile && this.props.customerProfile;
    const { shares, loans } = this.props.accounts;
    return (
      <DashboardScreens>
        <Container>
          {this.renderChangePasswordModal()}
          {this.renderChangePhraseModal()}
          {this.renderSuccessModal()}
          {this.renderChallengeQuestionsSuccessModal()}
          {this.renderAccountDescriptionSuccessModal()}
          <Loader isLoading={this.props.isFetching} />
          <PageTitle>My Account</PageTitle>
          <Separator style={{ margin: "1em 0 2em 0" }} />
          <ProfileForm>
            <Desktop>
              <div>
                <Heading>Personal Information</Heading>
                <Box>
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={name || ""}
                    type="text"
                    name="name"
                    id="profileName"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Name"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={address || ""}
                    type="text"
                    name="address"
                    id="profileAddress"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Address"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={address2 || ""}
                    type="text"
                    name="address2"
                    id="profileAddress2"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Address 2"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={address3 || ""}
                    type="text"
                    name="address3"
                    id="profileAddress3"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Address 3"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={email || ""}
                    type="text"
                    name="email"
                    id="profilEmail"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Email"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box>
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={mobile || ""}
                    type="text"
                    name="mobile"
                    id="profileMobile"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Mobile Number"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={homePhone || ""}
                    type="text"
                    name="homePhone"
                    id="profilehomePhone"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Home Phone Number"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={city || ""}
                    type="text"
                    name="city"
                    id="profilecity"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"City"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={state || ""}
                    type="text"
                    name="state"
                    id="profilestate"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"State"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={e =>
                      this.props.updateProfileForm(e.target.name, e.target.value)
                    }
                    value={zip || ""}
                    type="text"
                    name="zip"
                    id="profilezip"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Zip"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                </Box>
                <br />
                <Button primary onClick={e => this.handleSumbitProfileForm(e)}>
                  Save Changes
                </Button>
                <br />
                <br />
                <div>
                  <Heading>Account Information</Heading>
                  <br />
                  <Box>
                    <div style={{ flexDirection: "column", flex: 2 }}>
                      <Box>
                        <Box>
                          <Button outline onClick={this.openChangePasswordModal}>
                            Change Password
                          </Button>
                        </Box>
                        <Space />
                        <Box fx={1}>
                          <Button outline onClick={this.openChangePhraseModal}>
                            Change Phrase
                          </Button>
                        </Box>
                      </Box>
                      <br />
                      <Separator style={{ margin: "1em 0 2em 0" }} />
                      <div>
                        <Toggle
                          render={({ on, toggle }) => (
                            <Collapsable>
                              <Header>
                                <span>Login Challenge Questions</span>
                                <span
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {on ? (
                                    <ArrowUp onClick={() => toggle()} />
                                  ) : (
                                    <ArrowDown onClick={() => toggle()} />
                                  )}
                                </span>
                              </Header>
                              <Content column on={on}>
                                {this.props.challengeQuestions &&
                                  _.map(
                                    this.props.challengeQuestions,
                                    ({ id, secret, answer }, index) => (
                                      <div
                                        key={id + index + secret}
                                        className={styles.profileInformationToggleContent}
                                      >
                                        <div style={{ flex: 1 }}>
                                          <P>#&nbsp;{index + 1}</P>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <TextField
                                            type="text"
                                            label={"Question"}
                                            id="secret"
                                            name="secret"
                                            aria-label="Challenge Question"
                                            value={secret}
                                            onChange={e =>
                                              this.handleChangeChallengeRow(id, e)
                                            }
                                          />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <TextField
                                            type="text"
                                            id="answer"
                                            label={"Answer"}
                                            name="answer"
                                            aria-label="Challenge Answer"
                                            value={answer}
                                            onChange={e =>
                                              this.handleChangeChallengeRow(id, e)
                                            }
                                          />
                                        </div>
                                      </div>
                                    ),
                                  )}
                                <br />
                                <Button
                                  style={{ maxWidth: 160 }}
                                  primary
                                  onClick={e => this.handleSubmitChallengeQuestions(e)}
                                >
                                  Save Questions
                                </Button>
                              </Content>
                            </Collapsable>
                          )}
                        />
                        <Toggle
                          render={({ on, toggle }) => (
                            <div>
                              <Collapsable>
                                <Header>
                                  <span>Accounts Description</span>
                                  <span
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {on ? (
                                      <ArrowUp onClick={() => toggle()} />
                                    ) : (
                                      <ArrowDown onClick={() => toggle()} />
                                    )}
                                  </span>
                                </Header>
                                <Content column on={on}>
                                  {shares &&
                                    _.map(
                                      shares,
                                      (
                                        {
                                          id,
                                          uniqueId,
                                          description,
                                          defaultDescription,
                                          isTrusted,
                                        },
                                        index,
                                      ) =>
                                        !isTrusted && (
                                          <div
                                            key={id + uniqueId + index}
                                            className={
                                              styles.profileInformationToggleContent
                                            }
                                          >
                                            <div style={{ flex: 1 }}>
                                              <Small>Account ID</Small>
                                              <Space />
                                              <P>{`S-${id}`}</P>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <Small>Description</Small>
                                              <Space />
                                              <P>{defaultDescription}</P>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                              }}
                                            >
                                              <TextField
                                                onChange={e =>
                                                  this.props.updateAccountDescription(
                                                    uniqueId,
                                                    e.target.name,
                                                    e.target.value,
                                                  )
                                                }
                                                value={description}
                                                type="text"
                                                name="description"
                                                id="description"
                                                error={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                helperText={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                label={"My Description"}
                                              />
                                            </div>
                                          </div>
                                        ),
                                    )}
                                  {loans &&
                                    _.map(
                                      loans,
                                      (
                                        {
                                          id,
                                          uniqueId,
                                          description,
                                          defaultDescription,
                                          isTrusted,
                                        },
                                        i,
                                      ) =>
                                        !isTrusted && (
                                          <div
                                            key={id + uniqueId + i}
                                            className={
                                              styles.profileInformationToggleContent
                                            }
                                          >
                                            <div style={{ flex: 1 }}>
                                              <Small>Account ID *</Small>
                                              <Space />
                                              <P>{`L-${id}`}</P>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <Small>Description</Small>
                                              <Space />
                                              <P>{defaultDescription}</P>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                              }}
                                            >
                                              <TextField
                                                value={description}
                                                onChange={e =>
                                                  this.props.updateAccountDescription(
                                                    uniqueId,
                                                    e.target.name,
                                                    e.target.value,
                                                  )
                                                }
                                                type="text"
                                                name="defaultDescription"
                                                id="defaultDescription"
                                                error={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                helperText={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                label={"My Description"}
                                              />
                                            </div>
                                          </div>
                                        ),
                                    )}
                                  <br />
                                  {(shares || loans) && (
                                    <Button
                                      style={{ maxWidth: 160 }}
                                      primary
                                      onClick={e => this.handleSaveAccountDescription(e)}
                                    >
                                      Save Changes
                                    </Button>
                                  )}
                                </Content>
                              </Collapsable>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div style={{ flex: 1 }} />
                  </Box>
                </div>
              </div>
            </Desktop>

            <Mobile>
              <Box column>
                <Box column>
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={address || ""}
                    type="text"
                    name="name"
                    id="profileName"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Name"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={name || ""}
                    type="text"
                    name="address"
                    id="profileAddress"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Address"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={address2 || ""}
                    type="text"
                    name="address2"
                    id="profileAddress2"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Address 2"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={address3 || ""}
                    type="text"
                    name="address3"
                    id="profileAddress3"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Address 3"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={email || ""}
                    type="text"
                    name="email"
                    id="profilEmail"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Email"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={mobile || ""}
                    type="text"
                    name="mobile"
                    id="profileMobile"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Mobile Number"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={homePhone || ""}
                    type="text"
                    name="homePhone"
                    id="profilehomePhone"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Home Phone Number"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={city || ""}
                    type="text"
                    name="city"
                    id="profilecity"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"City"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={state || ""}
                    type="text"
                    name="state"
                    id="profilestate"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"State"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <TextField
                    onChange={({ target }) =>
                      this.props.updateProfileForm(target.name, target.value)
                    }
                    value={zip || ""}
                    type="text"
                    name="zip"
                    id="profilezip"
                    // error={this.state.formError || this.state.amountError}
                    // helperText={this.state.formError || this.state.amountError}
                    label={"Zip"}
                    endAdornment={
                      <InputAdornment position="end">
                        <Edit />
                      </InputAdornment>
                    }
                  />
                  <br />
                  <Button primary>Save Changes</Button>
                </Box>
                <div>
                  <div style={{ paddingTop: "2em" }}>
                    <Heading>Account Information</Heading>
                    <Separator
                      style={{
                        borderBottom: "1px solid rgba(18,55,77,0.08)",
                        margin: "1em 0 2em 0",
                      }}
                    />
                    <div>
                      <Box column>
                        <Button outline onClick={this.openChangePasswordModal}>
                          Change Password
                        </Button>
                        <br />
                        <Button outline onClick={this.openChangePhraseModal}>
                          Change Phrase
                        </Button>
                      </Box>
                      <Separator style={{ margin: "1em 0 2em 0 " }} />
                      <div>
                        <Toggle
                          render={({ on, toggle }) => (
                            <Collapsable>
                              <Header>
                                <span>Login Challenge Questions</span>
                                <span
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  {on ? (
                                    <ArrowDown onClick={() => toggle()} />
                                  ) : (
                                    <ArrowUp onClick={() => toggle()} />
                                  )}
                                </span>
                              </Header>
                              <Content on={on}>
                                <div style={{ flex: 1 }}>
                                  {this.props.challengeQuestions &&
                                    _.map(
                                      this.props.challengeQuestions,
                                      ({ id, secret, answer }, index) => (
                                        <ChallengeQuestionRow
                                          index={index}
                                          key={id + index + secret}
                                          secret={secret}
                                          answer={answer}
                                          onChange={e =>
                                            this.handleChangeChallengeRow(id, e)
                                          }
                                        />
                                      ),
                                    )}
                                  <Button
                                    primary
                                    onClick={e => this.handleSubmitChallengeQuestions(e)}
                                  >
                                    Save Questions
                                  </Button>
                                </div>
                                <br />
                              </Content>
                            </Collapsable>
                          )}
                        />
                        <Toggle
                          render={({ on, toggle }) => (
                            <div>
                              <Collapsable>
                                <Header>
                                  <span>Accounts Description</span>
                                  <span
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {on ? (
                                      <ArrowDown onClick={() => toggle()} />
                                    ) : (
                                      <ArrowUp onClick={() => toggle()} />
                                    )}
                                  </span>
                                </Header>
                                <Content column on={on}>
                                  {shares &&
                                    _.map(
                                      shares,
                                      (
                                        {
                                          id,
                                          uniqueId,
                                          description,
                                          defaultDescription,
                                          isTrusted,
                                        },
                                        index,
                                      ) =>
                                        !isTrusted && (
                                          <div
                                            key={id + uniqueId + index}
                                            className={
                                              styles.profileInformationToggleContent
                                            }
                                          >
                                            <div style={{ flex: 1 }}>
                                              <Small>Account ID</Small>
                                              <Space />
                                              <P>{`S-${id}`}</P>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <Small>Description</Small>
                                              <Space />
                                              <P>{defaultDescription}</P>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                              }}
                                            >
                                              <TextField
                                                onChange={e =>
                                                  this.props.updateAccountDescription(
                                                    uniqueId,
                                                    e.target.name,
                                                    e.target.value,
                                                  )
                                                }
                                                value={description}
                                                type="text"
                                                style={{ marginLeft: -16 }}
                                                name="description"
                                                id="description"
                                                error={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                helperText={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                label={"My Description"}
                                              />
                                            </div>
                                          </div>
                                        ),
                                    )}
                                  {loans &&
                                    _.map(
                                      loans,
                                      (
                                        {
                                          id,
                                          uniqueId,
                                          description,
                                          defaultDescription,
                                          isTrusted,
                                        },
                                        i,
                                      ) =>
                                        !isTrusted && (
                                          <div
                                            key={id + uniqueId + i}
                                            className={
                                              styles.profileInformationToggleContent
                                            }
                                          >
                                            <div style={{ flex: 1 }}>
                                              <Small>Account ID *</Small>
                                              <Space />
                                              <P>{`L-${id}`}</P>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <Small>Description</Small>
                                              <Space />
                                              <P>{defaultDescription}</P>
                                            </div>
                                            <div
                                              style={{
                                                display: "flex",
                                                flex: 1,
                                                flexDirection: "column",
                                              }}
                                            >
                                              <TextField
                                                value={description}
                                                onChange={e =>
                                                  this.props.updateAccountDescription(
                                                    uniqueId,
                                                    e.target.name,
                                                    e.target.value,
                                                  )
                                                }
                                                type="text"
                                                name="defaultDescription"
                                                id="defaultDescription"
                                                error={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                helperText={
                                                  this.state.inputsWithError &&
                                                  this.state.inputsWithError.length > 0 &&
                                                  _.includes(
                                                    this.state.inputsWithError,
                                                    uniqueId,
                                                  ) &&
                                                  this.state.inputError
                                                }
                                                label={"My Description"}
                                              />
                                            </div>
                                          </div>
                                        ),
                                    )}
                                  <br />
                                  {(shares || loans) && (
                                    <Button
                                      style={{ maxWidth: 160 }}
                                      primary
                                      onClick={e => this.handleSaveAccountDescription(e)}
                                    >
                                      Save Changes
                                    </Button>
                                  )}
                                </Content>
                              </Collapsable>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Mobile>
          </ProfileForm>
        </Container>
      </DashboardScreens>
    );
  }
}

const ChallengeQuestionRow = ({ secret, answer, onChange, index }) => (
  <div className={styles.profileInformationToggleContent}>
    <TextField
      onChange={onChange}
      value={secret}
      type="text"
      name="secret"
      id={secret}
      // error={this.state.formError || this.state.amountError}
      // helperText={this.state.formError || this.state.amountError}
      label={`Question #${index + 1}`}
    />
    <TextField
      onChange={onChange}
      value={answer}
      type="text"
      name="answer"
      id={answer}
      // error={this.state.formError || this.state.amountError}
      // helperText={this.state.formError || this.state.amountError}
      label={`Answer Question #${index + 1}`}
    />
    <br />
  </div>
);
ChallengeQuestionRow.propTypes = {
  secret: PropTypes.string,
  answer: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  isFetching: state.account.isFetching,
  accounts: selectAccounts(state),
  customerProfile: state.account.customerProfile,
  challengeQuestions: state.user.challengeQuestions,
  changePasswordModal: state.user.changePasswordModal,
  successModal: state.user.successModal,
  phraseModal: state.user.phraseModal,
  isAccountDescriptionModalOpen: state.account.isAccountDescriptionModalOpen,
  error: state.account.error,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCustomerProfile,
    updateAccountDescription,
    saveCustomerProfile,
    saveAccountDescription,
    updateProfileForm,
    getChallengeQuestions,
    saveChallengeQuestions,
    changeUserPassword,
    changeUserPhrase,
    openChangePasswordModal,
    closeChangePasswordModal,
    closeSuccessModal,
    openPhraseModal,
    closePhraseModal,
    updateChallengeQuestionsValues,
    showAccountDescriptionModal,
  })(AccountPersonalInformationPage),
);
