import * as types from "./constants";
const initialState = {
  error: null,
  isFetching: false,
  questions: null,
  helpText: null,
  helpEmail: null,
  helpPhone: null,
};

export default function helpReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_FAQ_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_FAQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        questions: payload.faqList,
        helpText: payload.helpText,
        helpEmail: payload.helpEmail,
        helpPhone: payload.helpPhone,
      };
    case types.GET_FAQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
}
