import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { push } from "react-router-redux";
import TextField from "../../../../components/commons/TextField";
import { getAccountSummary } from "../../Accounts/actions";
import Select from "../../../../components/commons/SelectInput";
import enums from "../../../../utils/enums";
import Calendar from "./Calendar";
import { MenuItem, InputAdornment } from "@material-ui/core";
import _ from "lodash";
import CurrencyFormat from "react-currency-format";
import Modal from "react-modal";
import { Button, Loader, P, HeadingModal } from "../../../../components/commons";
import { Mobile, Desktop } from "../../../../components/commons/Responsive";
import {
  SuccessModal,
  ConfirmationModal,
  CustomModal,
} from "../../../../components/commons/Modals";
import {
  Cancel,
  Check,
  Plus,
  SearchGray as IconSearch,
} from "../../../../components/commons/icons";
import { selectAutopayList, selectSharesOptions, selectQuickpays } from "../selectors";
import { selectAccounts } from "../../Accounts/selectors";
import * as actions from "../actions";
import { DashboardScreensWithSubmMenu } from "../../DashboardInstance/Dashboard";
import { AddButton } from "../../Goals/GoalsInstance";
import InputNormal from "../../SendMoney/InputNormal";
import Payee from "./Payee";
import SelectCustom from "../../FundsTransfer/SelectCustom";
import NewPayee from "../../EPayments/NewPayee";

const ContainerMobil = styled.div`
  width: 100%;
  .payees {
    display: flex;
    margin: 15px 0px;
    align-items: center;

    span {
      width: 100%;
    }

    .subTitle {
      color: #3fc78e;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
      flex: 2;
      display: flex;
    }
  }

  .containerPayee {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const SelectContainer = styled.div`
  width: 49%;
  display: flex;
  align-self: flex-end;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const SendContainer = styled.div`
  position: relative;
  padding-bottom: 20px;
  @media (min-width: 1101px) {
    margin-top: auto;
    width: 100%;
  }
  .MuiFormControl-root,
  .MuiInputBase-root {
    min-width: auto;
  }
`;

const ContainerDesktop = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .containerLeft {
    max-width: 450px;
    padding-left: 58px;
    padding: 0;
    padding-right: 2%;
    width: 40%;
    @media (max-width: 1100px) {
      border-left: none;
      padding: 0;
      max-width: inherit;
      width: 100%;
    }
    .title {
      color: #141f2d;
      font-family: "Kumbh Sans";
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 50px;
      margin-top: 40px;
      display: block;
    }
    .subTitle {
      color: #3fc78e;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .containerNote {
      display: flex;
      height: 100%;
    }
    .buttonTransfer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .separation {
      margin-top: 20px;
      height: 1px;
      width: 100%;
      background-color: #ebeff3;
    }
    .payees {
      display: flex;
      margin-bottom: 23px;

      span {
        width: 100%;
      }
    }
    .inputNormal {
      margin-bottom: 20px;
    }

    .containerPayees {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .containerRight {
    max-width: 650px;
    padding: 0px 1%;
    width: 55%;
    border-left: 1px solid #ebeff3;
    display: flex;
    flex-direction: column;
    height: 88vh;
    @media (max-width: 1400px) {
      padding: 0;
      border-left: none;
    }
    @media (max-width: 1100px) {
      min-width: inherit;
      width: 100%;
    }

    .title {
      opacity: 0.8;
      color: #4e5d71;
      font-family: "Kumbh Sans";
      font-size: 14px;
      padding-bottom: 10px;
      display: block;
    }

    .header {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebeff3;
      padding-bottom: 20px;
      .infoPayee {
        width: 100%;
        .namePayee {
          display: block;
          color: #000000;
          font-family: "Kumbh Sans";
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
        }
        .descriptionPayee {
          display: block;
          color: rgba(111, 109, 122, 0.6);
          font-family: "Kumbh Sans";
          font-size: 17px;
          letter-spacing: 0;
        }
      }
      .actions {
        display: flex;
      }
    }

    .containerNote {
      display: flex;
      margin-bottom: 118%;
    }

    .titleSendFrom,
    .titleSendOn {
      color: #3fc78e;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
      display: block;
      margin-top: 30px;
    }
    .separation {
      height: 1px;
      background-color: #ebeff3;
    }
    .buttonSave {
      justify-content: flex-end;
      display: flex;
      margin-top: 20px;

      button {
        color: #ffffff;
        font-family: "Kumbh Sans";
        font-size: 17px;
      }
    }
  }
`;

const TwoContainers = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    padding-top: 20px;
    padding-bottom: 5px;
    & > div:last-child {
      padding-top: 20px;
    }
  }
`;

const Container = styled.div`
  flex: 1;
`;

class EPaySummary extends Component {
  static propTypes = {
    push: PropTypes.func,
    classes: PropTypes.object,
    getAutoPays: PropTypes.func,
    showSuccessModal: PropTypes.func,
    openConfirmModal: PropTypes.func,
    closeConfirmModal: PropTypes.func,
    openRemoveAutoPayModal: PropTypes.func,
    closeRemoveAutopaySuccessModal: PropTypes.func,
    getQuickPays: PropTypes.func,
    removeQuickPayRow: PropTypes.func,
    removeAutopay: PropTypes.func,
    makePayment: PropTypes.func,
    updateQuickPayForm: PropTypes.func,
    setLastPayDayValue: PropTypes.func,
    quickpayToAutopay: PropTypes.func,
    makePayments: PropTypes.func,
    autopayList: PropTypes.array,
    isFetching: PropTypes.bool,
    removeAutopayModalSuccess: PropTypes.bool,
    confirmModal: PropTypes.bool,
    removeAutoPayModal: PropTypes.bool,
    removeAutoPayModalError: PropTypes.string,
    quickPayRows: PropTypes.array,
    sharesOptions: PropTypes.array,
    error: PropTypes.string,
    quickPays: PropTypes.array,
    isShowSuccessModal: PropTypes.bool,
    setQuickPayToAutoPay: PropTypes.func,
    setAutoPayForUpdate: PropTypes.func,
    autoPayToQuickPay: PropTypes.func,
    autoPayToQuickPayEntity: PropTypes.object,
    hideAutoPayToQuickPayConfirmModal: PropTypes.func,
    autoPayToQuickPayConfirmModalOpen: PropTypes.bool,
    getAccountSummary: PropTypes.func,
    accounts: PropTypes.object,
  };
  state = {
    totalPayments: 0,
    currentSelectedRowId: null,
    startDate: "",
    page: 0,
    rowsPerPage: 5,
    removeAutoPayPayeeName: "",
    removeAutoPayUniqueId: "",
    stopSending: "",
    typesOfStopSending: [
      { key: 0, value: "Never" },
      { key: 1, value: "On select date" },
    ],
    frequency: "",
    typesOfFrequency: [
      { key: 0, value: "One time" },
      { key: 1, value: "Once a month" },
      { key: 2, value: "Weekly" },
      { key: 3, value: "Every 2 weeks" },
      { key: 4, value: "Every 2 months" },
      { key: 5, value: "Every 3 months" },
      { key: 6, value: "Every 6 months" },
      { key: 7, value: "Annually" },
    ],
    filteredQuickpays: [
      {
        IdPayment: 1,
        IdPayeeNo: 22,
        IdShare: 13,
        AutoPmtPayFromx: 1,
        PmtPayeeName: "test 2",
        fromAccountId: 1,
        PmtNickName: "test 2",
        AutoPmtAmountReg: 1000,
        AutoPmtAmountLast: 1000,
        AutoPmtFreqData: "week",
        AutoPmtStartDate: "05/14/2020",
        AutoPmtLastDate: "05/14/2020",
        AutoPmtNextDate: "05/14/2020",
        userId: 4,
      },
    ],
    quickpayFilterValue: 0,
    order: "ASC",
    selectedRowFrom: {
      display: true,
      allowTransactionsFrom: true,
      id: "123",
      uniqueId: "1192-000",
      description: "Regular Share",
    },
    isOpenCustomModal: false,
    isOpenDeleteModal: false,
    actionTitle: "Add",
  };

  async componentDidMount() {
    try {
      const { getAutoPays, getQuickPays, getAccountSummary } = this.props;
      // await Promise.all([getAutoPays(), getQuickPays(), getAccountSummary()]);
    } catch (err) {
      throw err;
      // show modal error or redirect
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.quickPays && this.props.quickPays) {
      if (nextProps.quickPays.length !== this.props.quickPays) {
        this.setState({ filteredQuickpays: nextProps.quickPays });
      }
    }
  }

  handleRadioChangeFrom(newValue, radioIdFrom, row, accountType) {
    if (radioIdFrom === this.state.radioIdTo) {
      return false;
    }
    if (accountType === enums.AccountType.SHARE && !row.allowBalanceInquiry) {
      row.balance = " - ";
      row.available = " - ";
    }
    this.setState({
      selectedValueFrom: newValue,
      selectedRowFrom: row,
      radioIdFrom,
    });

    return true;
  }

  renderRemoveAutopaySuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.removeAutopayModalSuccess}
        onClose={() => this.props.closeRemoveAutopaySuccessModal()}
        headingText="Auto Pay Removed Successfully"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  render() {
    return (
      <DashboardScreensWithSubmMenu>
        <Container>
          {this.renderRemoveAutopaySuccessModal()}
          <Loader isLoading={this.props.isFetching} />
          <div>
            <Mobile>
              <ContainerMobil>
                <div className="payees">
                  <span className="subTitle">PAYEES</span>
                  <AddButton
                    primary
                    style={{ height: "24px", width: "24px" }}
                    onClick={() => this.setState({ isOpenCustomModal: true })}
                  >
                    <Plus stylesInput={{ height: "18px", width: "18px" }} />
                  </AddButton>
                </div>
                <InputNormal
                  placeholder="Name, Email, Address, …"
                  classNameContainer="inputNormal"
                  icon={
                    <IconSearch
                      stylesInput={{
                        height: "20px",
                        width: "20px",
                        padding: "12px",
                      }}
                    />
                  }
                />
                <div className="containerPayee">
                  <Payee name="Payee Name" description="Description" />
                  <Payee name="Payee Name" description="Description" />
                  <Payee name="Payee Name" description="Description" />
                  <Payee name="Payee Name" description="Description" />
                  <Payee name="Payee Name" description="Description" />
                  <Payee name="Payee Name" description="Mouse Over" />
                  <Payee name="Payee Name" description="Description" />
                </div>
              </ContainerMobil>
            </Mobile>
            <Desktop>
              <ContainerDesktop>
                <div className="containerLeft">
                  <span className="title">Bills</span>
                  <div className="payees">
                    <span className="subTitle">PAYEES</span>
                    <AddButton
                      primary
                      style={{ height: "24px", width: "24px" }}
                      onClick={() => {
                        this.setState({ actionTitle: "Add" });
                        this.setState({ isOpenCustomModal: true });
                      }}
                    >
                      <Plus stylesInput={{ height: "18px", width: "18px" }} />
                    </AddButton>
                  </div>
                  <InputNormal
                    placeholder="Name, Email, Address, …"
                    classNameContainer="inputNormal"
                    icon={
                      <IconSearch
                        stylesInput={{
                          height: "20px",
                          width: "20px",
                          padding: "12px",
                        }}
                      />
                    }
                  />
                  <div className="containerPayees">
                    {this.state.filteredQuickpays ? (
                      this.state.filteredQuickpays
                        // todo: uncomment this part later
                        // .slice(
                        //   this.state.page * this.state.rowsPerPage,
                        //   this.state.page * this.state.rowsPerPage +
                        //     this.state.rowsPerPage,
                        // )
                        .map(quickPay => (
                          <Payee
                            name="Payee Name"
                            description="Description"
                            openModal={() => this.setState({ isOpenCustomModal: true })}
                            titleModal={() => this.setState({ actionTitle: "Edit" })}
                            openDeleteModal={() =>
                              this.setState({ isOpenDeleteModal: true })
                            }
                          />
                        ))
                    ) : (
                      <p style={{ padding: "0", width: "100%", margin: "0" }}>
                        {"You don't have payees"}
                      </p>
                    )}
                  </div>
                </div>
                <div className="containerRight">
                  {/* todo: uncomment this part later */}
                  {/* <div className="header">
                      <div className="infoPayee">
                        <span className="namePayee">Payee Name</span>
                        <span className="descriptionPayee">Description</span>
                      </div>
                      <div className="actions">
                        <BlackTrash
                          stylesInput={{
                            height: "20px",
                            width: "20px",
                            marginRight: "10px",
                            opacity: 1,
                          }}
                        />
                        <Edit
                          stylesInput={{ height: "20px", width: "20px", opacity: 1 }}
                        />
                      </div>
                    </div> */}
                  <CurrencyFormat
                    name="amount"
                    id="amount"
                    customInput={TextField}
                    thousandSeparator={true}
                    bigField
                    startAdornment={
                      <InputAdornment position="start">
                        <span className="bigAdornment">$</span>
                      </InputAdornment>
                    }
                  />
                  <SendContainer>
                    <span className="titleSendFrom">SEND FROM:</span>
                    <SelectCustom
                      error={this.state.formError}
                      options={[
                        ...this.props.accounts.shares,
                        ...this.props.accounts.loans,
                      ]}
                      onClickItemSelect={(e, item) => {
                        return this.handleRadioChangeFrom(
                          e,
                          item.uniqueId,
                          item,
                          enums.AccountType.SHARE,
                        );
                      }}
                      selected={this.state.selectedRowFrom}
                    />
                    <TwoContainers>
                      <SelectContainer>
                        <Calendar
                          label={
                            this.state.frequency !== "" && this.state.frequency !== 0
                              ? "Start Sending On"
                              : "Sending On"
                          }
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <Select
                          fullWidth
                          customSize
                          id="frequency"
                          name="frequency"
                          aria-label="frequency"
                          withoutMargin
                          label={"Frequency"}
                          value={this.state.frequency}
                          onChange={e => {
                            this.setState({ frequency: e.target.value });
                          }}
                        >
                          {_.map(this.state.typesOfFrequency, (option, idx) => (
                            <MenuItem key={idx} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectContainer>
                    </TwoContainers>

                    <TwoContainers
                      style={
                        this.state.frequency !== "" && this.state.frequency !== 0
                          ? { display: "flex", justifyContent: "space-between" }
                          : { display: "none" }
                      }
                    >
                      <SelectContainer>
                        <Select
                          fullWidth
                          customSize
                          id="stopSending"
                          name="stopSending"
                          aria-label="stopSending"
                          withoutMargin
                          label={"Stop Sending On"}
                          value={this.state.stopSending}
                          onChange={e => {
                            this.setState({ stopSending: e.target.value });
                          }}
                        >
                          {_.map(this.state.typesOfStopSending, (option, idx) => (
                            <MenuItem key={idx} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectContainer>
                      <SelectContainer
                        style={
                          this.state.stopSending !== "" && this.state.stopSending !== 0
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        <Calendar label="Stop Date" />
                      </SelectContainer>
                    </TwoContainers>

                    <div className="buttonSave">
                      <Button
                        //disabled={this.state.amountError}
                        //primary={!this.state.amountError}
                        //onClick={this.hanldeOpenConfirmationModal}
                        style={{
                          backgroundColor: "#3FC78E",
                          width: "168px",
                          padding: "10px 0",
                        }}
                        primary
                        onClick={() => this.props.showSuccessModal(true)}
                      >
                        Save
                      </Button>
                    </div>
                  </SendContainer>
                </div>
              </ContainerDesktop>
            </Desktop>
            <CustomModal isOpen={this.state.isOpenCustomModal}>
              <div className="CustomModalHeader">
                <HeadingModal>{this.state.actionTitle} Payee</HeadingModal>
                <Cancel onClick={() => this.setState({ isOpenCustomModal: false })} />
              </div>
              <NewPayee closeModal={() => this.setState({ isOpenCustomModal: false })} />
              {/* <LoanRequestInformation setState={this.getResponse}/> */}
            </CustomModal>

            <ConfirmationModal
              isOpen={this.state.isOpenDeleteModal}
              onClose={() => this.setState({ isOpenDeleteModal: false })}
              onSuccess={() => {
                // delete action
              }}
              headingText="Delete Payee"
              confirmationText={<P>Are you sure you want to delete this Payee?</P>}
              cancelButtonText="Close"
              successButtonText={"Confirm"}
              aria-hidden={this.state.isConfirmationModalOpen}
              aria={{
                labelledby: "heading",
                describedby: "full_description",
              }}
            />
          </div>
        </Container>
      </DashboardScreensWithSubmMenu>
    );
  }
}

const mapStateToProps = state => ({
  accounts: selectAccounts(state, true),
  isFetching: state.epayment.isFetching,
  error: state.epayment.error,
  sharesOptions: selectSharesOptions(state),
  autopayList: selectAutopayList(state),
  quickPays: selectQuickpays(state),
  isShowSuccessModal: state.epayment.showSuccessModal,
  removeAutoPayModal: state.epayment.removeAutoPayModal,
  removeAutoPayModalError: state.epayment.removeAutoPayModalError,
  removeAutopayModalSuccess: state.epayment.removeAutopayModalSuccess,
  autoPayToQuickPayEntity: state.epayment.autoPayToQuickPayEntity,
  autoPayToQuickPayConfirmModalOpen: state.epayment.autoPayToQuickPayConfirmModalOpen,
});

export default connect(mapStateToProps, { push, ...actions, getAccountSummary })(
  EPaySummary,
);
