/* eslint no-restricted-globals:0 */
import axios from "axios";
import publicIp from "public-ip";
import constants from "../utils/constants";
import { isArray } from "../utils/type";
import { newToken, validationInactive, validateRouteAdmin, isLocalhost } from "./helper";

const ROOT_URL = `${constants.BACKEND_URL}`;

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (
      validationInactive(location.pathname) &&
      !localStorage.getItem("request_update_token") &&
      localStorage.getItem("maxSessionMinutes")
    ) {
      newToken(localStorage.getItem("maxSessionMinutes"));
    }

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export const post = async (url, body = {}) => {
  let headers = await getHeaders();

  return axios.post(`${ROOT_URL}${url}`, body, { headers });
};

export const put = async (url, body = {}) => {
  let headers = await getHeaders();
  return axios.put(`${ROOT_URL}${url}`, body, { headers });
};

export const get = async (url, query) => {
  let headers = await getHeaders();

  let queryParams = "";
  if (query) {
    queryParams = `?${formatQueryUrl(query)}`;
  }

  return axios.get(`${ROOT_URL}${url}${queryParams}`, { headers });
};

export const refreshToken = async () => {
  let result = null;
  await post("/refreshToken")
    .then(({ data }) => {
      result = data.data;
      if (validateRouteAdmin()) {
        localStorage.removeItem("request_update_token_admin");
      } else {
        localStorage.removeItem("request_update_token");
      }
    })
    .catch(() => {
      if (validateRouteAdmin()) {
        localStorage.removeItem("request_update_token_admin");
      } else {
        localStorage.removeItem("request_update_token");
      }
    });

  if (validateRouteAdmin()) {
    if (localStorage.getItem("tokenAdmin") && result) {
      localStorage.setItem("tokenAdmin", result.token);
    }

    if (localStorage.getItem("tokenStartDateAdmin") && result) {
      localStorage.setItem("tokenStartDateAdmin", result.iat);
    }
  } else {
    if (localStorage.getItem("token") && result) {
      localStorage.setItem("token", result.token);
    }

    if (localStorage.getItem("tokenStartDate") && result) {
      localStorage.setItem("tokenStartDate", result.iat);
    }
  }
};

function formatQueryUrl(queryParams) {
  return Object.entries(queryParams)
    .map(([key, value]) => {
      if (value === undefined || value === null) {
        return "";
      }

      if (isArray(value)) {
        return `${key}=${JSON.stringify(value)}`;
      }

      return `${key}=${value}`;
    })
    .join("&");
}

const getToken = () => {
  if (validateRouteAdmin()) {
    return localStorage.getItem("tokenAdmin");
  }

  return localStorage.getItem("token");
};

const getHeaders = async () => {
  const token = getToken();
  let headers = { "Access-Control-Allow-Origin": "*" };

  if (!isLocalhost()) {
    headers = { ...headers, "x-forwarded-for": await publicIp.v4() };
  }

  if (token) {
    headers = { ...headers, authorization: token };
  }

  return headers;
};
