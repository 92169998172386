import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import * as types from "./constants";
import { post } from "../../../utils/server";
const ROOT_URL = `/user`;

export const getChallengeQuestions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CHALLENGE_QUESTIONS_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const {
      data: { data: challengeQuestions, success, error },
    } = await post(`${ROOT_URL}/getchallengequestions`, { userId });
    if (success) {
      dispatch({
        type: types.GET_CHALLENGE_QUESTIONS_SUCCESS,
        payload: challengeQuestions,
      });
    } else {
      dispatch({ type: types.GET_CHALLENGE_QUESTIONS_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    dispatch({
      type: types.GET_CHALLENGE_QUESTIONS_FAILURE,
      payload: "error trying to get user's challenge questions",
    });
  }
};

export const saveChallengeQuestions = ({ id, secret, answer }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.SAVE_CHALLENGE_QUESTIONS_REQUEST });
    const {
      auth: { userId },
    } = getState();

    if (id || (secret && answer)) {
      const {
        data: { data: challengeQuestions, success, error },
      } = await post(`${ROOT_URL}/savechallengequestion`, {
        userId,
        questionId: id,
        question: secret,
        answer,
      });

      if (success) {
        dispatch({
          type: types.SAVE_CHALLENGE_QUESTIONS_SUCCESS,
          payload: challengeQuestions,
        });
      } else {
        dispatch({ type: types.SAVE_CHALLENGE_QUESTIONS_FAILURE, payload: error });
      }
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    dispatch({
      type: types.SAVE_CHALLENGE_QUESTIONS_FAILURE,
      payload: "error trying to save user's challenge questions",
    });
  }
};

export const changeUserPassword = ({
  newPassword,
  confirmPassword,
  currentPassword,
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CHANGE_USER_PASSWORD_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const {
      data: { data: updatedUser, success, error },
    } = await post(`${ROOT_URL}/changepassword`, {
      idOLBUser,
      newPassword,
      confirmPassword,
      currentPassword,
    });
    if (success) {
      dispatch({ type: types.CHANGE_USER_PASSWORD_SUCCESS, payload: updatedUser });
      dispatch(closeChangePasswordModal());
      dispatch(openSuccessModal());
    } else {
      dispatch({ type: types.CHANGE_USER_PASSWORD_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    dispatch({
      type: types.CHANGE_USER_PASSWORD_FAILURE,
      payload: "error trying to change user's password",
    });
  }
};

export const changeUserPhrase = ({ newPhrase, confirmPhrase }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.CHANGE_USER_PHRASE_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const {
      data: { data: updatedUser, success, error },
    } = await post(`${ROOT_URL}/changephrase`, { idOLBUser, newPhrase, confirmPhrase });
    if (success) {
      dispatch({ type: types.CHANGE_USER_PHRASE_SUCCESS, payload: updatedUser });
      dispatch(closePhraseModal());
      dispatch(openSuccessModal());
    } else {
      dispatch({ type: types.CHANGE_USER_PHRASE_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    dispatch({
      type: types.CHANGE_USER_PHRASE_FAILURE,
      payload: "error trying to change user's phrase",
    });
  }
};

export const updateChallengeQuestionsValues = (id, name, value) => dispatch =>
  dispatch({
    type: types.CHALLENGE_QUESTIONS_UPDATE_VALUE,
    payload: { id, name, value },
  });

export const openChangePasswordModal = () => dispatch =>
  dispatch({ type: "OPEN_CHANGE_PASSWORD_MODAL", payload: true });
export const closeChangePasswordModal = () => dispatch =>
  dispatch({ type: "CLOSE_CHANGE_PASSWORD_MODAL", payload: false });

export const openPhraseModal = () => dispatch =>
  dispatch({ type: "OPEN_PHRASE_MODAL", payload: true });
export const closePhraseModal = () => dispatch =>
  dispatch({ type: "CLOSE_PHRASE_MODAL", payload: false });

export const openSuccessModal = () => dispatch =>
  dispatch({ type: "OPEN_SUCCESS_MODAL", payload: true });
export const closeSuccessModal = () => dispatch =>
  dispatch({ type: "CLOSE_SUCCESS_MODAL", payload: false });

export const openSecurityQuestionsModal = () => dispatch =>
  dispatch({ type: "OPEN_SECURITY_QUESTIONS_MODAL", payload: true });
export const closeSecurityQuestionsModal = () => dispatch =>
  dispatch({ type: "CLOSE_SECURITY_QUESTIONS_MODAL", payload: false });
