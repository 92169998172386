/*
 *
 * Dashboard constants
 *
 */

// ui
export const SHOW_MOBILE_MENU = "show_mobile_menu";
export const SHOW_MOBILE_USER_MENU = "show_mobile_user_menu";
export const SHOW_MOBILE_LOGOUT = "show_mobile_logout";
export const SHOW_DASHBOARD_MENU = "show_dashboard_menu";
export const DEFAULT_ACTION = "default_action";
