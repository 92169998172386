import styled from "styled-components";
export default styled.div`
  display: flex;
  flex: ${({ fx }) => fx || "0"};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "wrap")};
  align-items: ${({ centered }) => (centered ? "center" : "stretch")};
  justify-content: ${({ centered }) => (centered ? "center" : "flex-start")};
  width: ${({ auto }) => (auto ? "auto" : "100%")};
  position: relative;
  order: 1;
  &.order:nth-child(3) {
    width: 100%;
    order: 0;
  }

  &.home > div {
    width: 100%;
  }

  /* & div > div {
    width: 100%;
  } */
`;
