import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import styled from "styled-components";
import TextField from "../../components/commons/TextField";
import Select from "../../components/commons/SelectInput";
import { MenuItem } from "@material-ui/core";
import Checkbox from "../../components/commons/Checkbox";
import enums from "../../utils/enums";
import { PageTitle, Separator, Button, Loader } from "../../components/commons";
import { SuccessModal } from "../../components/commons/Modals";
import { Check } from "../../components/commons/icons";
import { selectAllCustomers } from "./selectors";
import {
  getAllCustomers,
  createCustomerMessage,
  createCustomerDirectMessage,
  showCreateDirectMessageSuccessModal,
  showCreateMessageSuccessModal,
} from "./actions";

const Layout = styled.div`
  width: 100%;
  margin-top: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  background-color: white;
  width: 450px;
  padding: 1.5rem 3rem;
  max-height: 700px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem 0 0;

  ${Button} {
    margin-left: 1.5rem;
  }
`;

const CheckBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
`;

class CreateMessage extends Component {
  static propTypes = {
    push: PropTypes.func,
    getAllCustomers: PropTypes.func,
    createCustomerMessage: PropTypes.func,
    createCustomerDirectMessage: PropTypes.func,
    isFetching: PropTypes.bool,
    customers: PropTypes.array,
    error: PropTypes.string,
    showSuccessModal: PropTypes.bool,
    showCreateMessageSuccessModal: PropTypes.func,
    showCreateDirectMessageSuccessModal: PropTypes.func,
    showDirectMessageSuccessModal: PropTypes.bool,
  };
  state = {
    message: "",
    messageTypes: enums.MessageTypes,
    messageType: "",
    imageUrl: "",
    important: false,
    dismissable: false,
    isDirectMessage: false,
    idOLBUser: "",
    formError: false,
  };
  componentDidMount() {
    this.props.getAllCustomers();
  }

  handleCreateMessage = async () => {
    try {
      const { message, messageType, imageUrl, important, dismissable } = this.state;
      if (message !== "" && messageType !== "") {
        this.setState({ formError: false });
        const messageBody = {
          message,
          messageType,
          imageUrl,
          important,
          dismissable,
        };
        await this.props.createCustomerMessage(messageBody);
        return this.resetState();
      }
      return this.setState({ formError: true });
    } catch (err) {
      // ...
    }
    return true;
  };

  handleCreateDirectMessage = async () => {
    try {
      const {
        message,
        messageType,
        imageUrl,
        important,
        dismissable,
        idOLBUser,
      } = this.state;
      if (message !== "" && messageType !== "" && idOLBUser !== "") {
        this.setState({ formError: false });
        const messageBody = {
          message,
          messageType,
          imageUrl,
          important,
          dismissable,
          idOLBUser,
        };
        await this.props.createCustomerDirectMessage(messageBody);
        return this.resetState();
      }
      return this.setState({ formError: true });
    } catch (err) {
      // ...
    }
    return true;
  };

  resetState() {
    this.setState({
      message: "",
      messageTypes: enums.MessageTypes,
      messageType: "",
      imageUrl: "",
      important: false,
      dismissable: false,
      isDirectMessage: false,
      idOLBUser: "",
      formError: false,
    });
  }

  renderCreateMessageSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.showSuccessModal}
        onClose={() => this.props.showCreateMessageSuccessModal(false)}
        headingText="Message Created Successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  renderCreateDirectMessageSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.showDirectMessageSuccessModal}
        onClose={() => this.props.showCreateDirectMessageSuccessModal(false)}
        headingText="Message Created Successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  handleValue(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.isDirectMessage) {
          if (this.state.message && this.state.messageType && this.state.idOLBUser) {
            this.setState({
              formError: false,
            });
          }
        } else {
          if (this.state.message && this.state.messageType) {
            this.setState({
              formError: false,
            });
          }
        }
      },
    );
  }

  render() {
    return (
      <Layout>
        {this.renderCreateMessageSuccessModal()}
        {this.renderCreateDirectMessageSuccessModal()}
        <Loader isLoading={this.props.isFetching} />
        <Container>
          <PageTitle>Create Message</PageTitle>
          <Separator />
          <Form>
            <TextField
              id="message"
              name="message"
              label="Message *"
              value={this.state.message}
              onChange={e => this.handleValue(e)}
              error={this.state.formError}
              helperText={this.state.formError && "please add a valid message"}
            />
            <Select
              id="messageType"
              name="messageType"
              aria-label="messga type"
              label={"Message Type *"}
              error={this.state.formError}
              helperText={this.state.formError && "please select a valid message type"}
              value={this.state.messageType}
              onChange={e => this.handleValue(e)}
            >
              {this.state.messageTypes &&
                Object.keys(this.state.messageTypes).map(
                  (key, val) =>
                    key !== "UNDEFINED" && (
                      <MenuItem
                        style={{ backgroundColor: "white" }}
                        key={key}
                        value={val}
                      >
                        {key}
                      </MenuItem>
                    ),
                )}
            </Select>
            <TextField
              id="messageUrl"
              label="Insert Image URL here"
              value={this.state.imageUrl}
              onChange={e => this.setState({ imageUrl: e.target.value })}
            />
            <CheckBoxArea>
              <Checkbox
                onChange={e => this.setState({ important: e.target.checked })}
                checked={this.state.important}
                label={"Important"}
              />
              <Checkbox
                onChange={e => this.setState({ dismissable: e.target.checked })}
                checked={this.state.dismissable}
                label={"Dismissable"}
              />
              <Checkbox
                onChange={e => this.setState({ isDirectMessage: e.target.checked })}
                checked={this.state.isDirectMessage}
                label={"Direct Message *"}
              />
            </CheckBoxArea>
            {this.state.isDirectMessage && (
              <Select
                id="customerList"
                name="idOLBUser"
                aria-label="customer list"
                label={"Member List"}
                error={this.state.formError}
                helperText={
                  this.state.formError && "please select a customer for direct message"
                }
                value={this.state.idOLBUser}
                onChange={e => this.handleValue(e)}
              >
                {this.props.olbUsers ? (
                  _.map(this.props.olbUsers, ({ username, idOLBUser }, index) => (
                    <MenuItem
                      style={{ backgroundColor: "white" }}
                      key={index}
                      value={idOLBUser}
                    >
                      {username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem style={{ backgroundColor: "white" }} value="">
                    Select an Option
                  </MenuItem>
                )}
              </Select>
            )}
          </Form>
          <FormControls>
            <Button info onClick={() => this.props.push("/admin/panel/")}>
              Go Back
            </Button>
            <Button
              primary
              onClick={
                this.state.isDirectMessage
                  ? this.handleCreateDirectMessage
                  : this.handleCreateMessage
              }
            >
              Create Message
            </Button>
          </FormControls>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  olbUsers: selectAllCustomers(state),
  isFetching: state.admin.isFetching,
  error: state.admin.error,
  showSuccessModal: state.admin.showCreateMessageSuccessModal,
  showDirectMessageSuccessModal: state.admin.showCreateDirectMessageSuccessModal,
});

export default connect(mapStateToProps, {
  push,
  getAllCustomers,
  createCustomerMessage,
  createCustomerDirectMessage,
  showCreateDirectMessageSuccessModal,
  showCreateMessageSuccessModal,
})(CreateMessage);
