/*
 *
 * SSO Reducer
 *
 */

import * as types from "./constants";

const initialState = {
  isFetching: false,
  creditCardRowSsoList: [],
  quickLinkSsoList: [],
  redirectUrl: null,
  formParams: [],
  isPost: null,
  error: null,
};

function ssoReducer(state = initialState, action) {
  switch (action.type) {
    // Single sign ons
    case types.REQUEST_GET_SSO_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_SSO_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        creditCardRowSsoList: action.payload.creditCardRowSsoList,
        quickLinkSsoList: action.payload.quickLinkSsoList,
        accountsButtonsSsoList: action.payload.accountsButtonsSsoList,
        transactionHistoryChecksSsoList: action.payload.transactionHistoryChecksSsoList,
        formsSsoList: action.payload.formsSsoList,
      };
    case types.GET_SSO_LIST_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.REQUEST_GET_SSO_REDIRECT_URL:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_SSO_REDIRECT_URL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        redirectUrl: action.payload.redirectUrl,
        formParams: action.payload.postParameters,
        isPost: action.payload.isPost,
      };
    case types.GET_SSO_REDIRECT_URL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default ssoReducer;
