import React from "react";
import PropTypes from "prop-types";
import {
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { FormFirstContainer } from "./LoginStyles";
import TextField from "./TextField";
import { createMuiTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { InfoGreen } from "./icons";
import { Button } from "./index";
import MediaQuery from "react-responsive";

const checkBoxTheme = createMuiTheme({
  palette: {
    secondary: {
      main: "#3fc78e",
    },
  },
});

const styles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});

const AuthFirstStep = ({
  classes,
  title = "",
  disableEmail,
  email,
  onKeyPressEmail,
  onBlurEmail,
  onChangeEmail,
  password,
  disablePassword,
  onKeyPressPassword,
  onBlurPassword,
  onChangePassword,
  rememberDevice = false,
  onChangeRememberDevice,
  isFetching = false,
  error = false,
  isPrimaryLoginButton = false,
  disableLoginButton = false,
  phrase = "",
  onClickLoginButton,
}) => {
  const renderLoading = isFetching && (
    <InputAdornment position="end">
      <CircularProgress
        className={classes.progress}
        style={{ color: "#05CE86", width: 30, height: 30 }}
      />
    </InputAdornment>
  );

  return (
    <FormFirstContainer>
      <h1 className="title">{title}</h1>

      <TextField
        onChange={onChangeEmail}
        value={email}
        disable={disableEmail}
        onKeyPress={onKeyPressEmail}
        type="text"
        autoFocus
        name="email"
        id="email"
        notMargin
        labelGray
        label={"Username"}
        onBlur={onBlurEmail}
        endAdornmentendAdornment={renderLoading}
        //error={stepError || this.state.error}
        //helperText={stepError || this.state.error}
      />

      <div className="separation" />

      <TextField
        onChange={onChangePassword}
        value={password}
        onKeyPress={onKeyPressPassword}
        type="password"
        name="password"
        id="password"
        label={"Password"}
        onBlur={onBlurPassword}
        notMargin
        labelGray
        disabled={disablePassword}
        endAdornment={renderLoading}
        //error={stepError || this.state.error}
        //helperText={stepError || this.state.error}
      />

      {error && <FormHelperText error>{error}</FormHelperText>}

      <div className="containerForgotPassword">
        <Grid
          container
          justify="center"
          alignItems="center"
          wrap="nowrap"
          style={{ opacity: rememberDevice ? 1.0 : 0.4 }}
        >
          <Grid item xs={2}>
            <ThemeProvider theme={checkBoxTheme}>
              <Checkbox
                checked={rememberDevice}
                onChange={onChangeRememberDevice}
                name="rememberDevice"
                id="rememberDevice"
                classes="rememberDevice"
                className="rememberDeviceCheckbox"
              />
            </ThemeProvider>
          </Grid>

          <div style={{ flex: 1 }}>
            <label htmlFor="rememberDevice" className="rememberDevice">
              Remember my device
            </label>
          </div>
        </Grid>

        <a href="/forgot-password" className="forgotPassword" style={{ minWidth: 130 }}>
          Forgot Password?
        </a>
      </div>

      <MediaQuery query="(min-height: 697px)">
        <div className="containerPhrase" style={{ marginTop: "25px" }}>
          <InfoGreen stylesInput={{ width: "20px", height: "20px" }} />

          {phrase ? (
            <span className="descriptionPhrase">
              Confirm your personal phrase is right before you proceed to login.
            </span>
          ) : (
            <span className="descriptionPhrase">
              Once you fill in your email we will show you your security phrase for you to
              confirm is the right one.
            </span>
          )}

          {!!phrase && <span className="titlePhrase">Personal Phrase:</span>}

          {!!phrase ? (
            <span className="phraseActive">{phrase}</span>
          ) : (
            <span className="phrase">-</span>
          )}
        </div>
      </MediaQuery>

      <Button
        primary={isPrimaryLoginButton}
        disabled={disableLoginButton}
        onClick={onClickLoginButton}
        className="btnLogin"
      >
        Log In
      </Button>

      <MediaQuery query="(max-height: 696px)">
        <div className="containerPhrase">
          <InfoGreen stylesInput={{ width: "20px", height: "20px" }} />

          {phrase ? (
            <span className="descriptionPhrase">
              Confirm your personal phrase is right before you proceed to login.
            </span>
          ) : (
            <span className="descriptionPhrase">
              Once you fill in your email we will show you your security phrase for you to
              confirm is the right one.
            </span>
          )}

          {!!phrase && <span className="titlePhrase">Personal Phrase:</span>}

          {!!phrase ? (
            <span className="phraseActive">{phrase}</span>
          ) : (
            <span className="phrase">-</span>
          )}
        </div>
      </MediaQuery>
    </FormFirstContainer>
  );
};

AuthFirstStep.defaultProps = {
  disableEmail: false,
  disablePassword: false,
  error: false,
  disableLoginButton: false,
  isPrimaryLoginButton: false,
  rememberDevice: false,
  phrase: "",
};

AuthFirstStep.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.any.isRequired,
  email: PropTypes.string.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  rememberDevice: PropTypes.bool.isRequired,
  onChangeRememberDevice: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClickLoginButton: PropTypes.func.isRequired,
  disableLoginButton: PropTypes.bool,
  disableEmail: PropTypes.bool,
  isPrimaryLoginButton: PropTypes.bool,
  onKeyPressEmail: PropTypes.func,
  onBlurEmail: PropTypes.func,
  disablePassword: PropTypes.bool,
  onKeyPressPassword: PropTypes.func,
  onBlurPassword: PropTypes.func,
  error: PropTypes.bool,
  phrase: PropTypes.string,
};

export default withStyles(styles)(AuthFirstStep);
