import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import TextField from "../../../components/commons/TextField";
import Checkbox from "../../../components/commons/Checkbox";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import { SuccessModal } from "../../../components/commons/Modals";
import {
  MobileTable,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import { Button, Separator, Box, P, Loader } from "../../../components/commons";
import { Check } from "../../../components/commons/icons";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

import styles from "./styles.module.css";

const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#7A7A7A",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    padding: "1rem",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#7A7A7A",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

const Container = styled.div``;
class TextBankingAccountSetup extends Component {
  static propTypes = {
    getLinkedAccounts: PropTypes.func,
    updateLinkedAccount: PropTypes.func,
    updateLinkedAccountsFormValue: PropTypes.func,
    closeSuccessModal: PropTypes.func,
    linkedAccounts: PropTypes.array,
    classes: PropTypes.object,
    isFetching: PropTypes.bool,
    match: PropTypes.object,
    showSuccessModal: PropTypes.bool,
  };

  async componentDidMount() {
    try {
      const { phoneId } = this.props.match.params;
      await this.props.getLinkedAccounts(phoneId);
    } catch (ex) {
      throw ex;
    }
  }

  handleLinkedAccountChange(id, name, e) {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.props.updateLinkedAccountsFormValue(id, name, value);
  }

  handleUpdateButtonClicked = () => {
    const { updateLinkedAccount } = this.props;
    const promiseArray = [];
    this.props.linkedAccounts.forEach(linkedAccount => {
      if (linkedAccount.updated) {
        promiseArray.push(updateLinkedAccount(linkedAccount));
      }
    });
    return Promise.all(promiseArray);
  };

  renderSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.showSuccessModal}
        onClose={this.props.closeSuccessModal}
        headingText="Linked Accounts Updated Successfully"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={this.props.isFetching} />
          {this.renderSuccessModal()}
          <Box>
            <Box fx={1}>
              <P>
                Please select the accounts whose available balance you want to be
                displayed on a balance inquiry. You may also enter a short Text
                Description for the account like SAV for Savings, CHK for Checking, or MMA
                for Money Market Account.
              </P>
            </Box>
            <Box className={styles.desktopFx} />
          </Box>
          <Separator style={{ margin: "1em 0 2em 0" }} />
          <Mobile>
            <div>
              <MobileTable title={"account setup"}>
                {this.props.linkedAccounts && this.props.linkedAccounts.length > 0 ? (
                  _.map(this.props.linkedAccounts, (linkedAccount, i) => (
                    <MobileTableRow key={i}>
                      <div style={{ width: "100%" }}>
                        <MobileTableHeading
                          value={
                            linkedAccount.accountDescription ||
                            linkedAccount.defaultDescription
                          }
                        />
                        <MobileTableCell
                          name={"Account id"}
                          value={linkedAccount.displayAccountId}
                        />
                        <MobileTableCell
                          name={"description"}
                          value={
                            linkedAccount.accountDescription ||
                            linkedAccount.defaultDescription
                          }
                        />
                        <MobileTableCell
                          name={"include account"}
                          value={
                            <Checkbox
                              onChange={e =>
                                this.handleLinkedAccountChange(
                                  linkedAccount.displayAccountId,
                                  "includeAccount",
                                  e,
                                )
                              }
                              checked={linkedAccount.includeAccount || false}
                            />
                          }
                        />
                        <MobileTableCell>
                          <Box column>
                            <TextField
                              type="text"
                              label={"Text Description"}
                              id="textDescription"
                              aria-label="input text description"
                              value={linkedAccount.textDescription}
                              onChange={e =>
                                this.handleLinkedAccountChange(
                                  linkedAccount.displayAccountId,
                                  "textDescription",
                                  e,
                                )
                              }
                            />
                          </Box>
                        </MobileTableCell>
                        <br />
                      </div>
                    </MobileTableRow>
                  ))
                ) : (
                  <MobileTableRow>
                    <MobileTableCell
                      textAlign={"center"}
                      value={"You don't have any loans"}
                    />
                  </MobileTableRow>
                )}
              </MobileTable>
              <br />
              <Button primary onClick={this.handleUpdateButtonClicked}>
                Update
              </Button>
            </div>
          </Mobile>
          <Desktop>
            <div>
              <div style={{ overflowX: "auto" }}>
                <Table className={classes.table}>
                  <TableHead className={classes.thead}>
                    <TableRow>
                      <CustomTableCell>Account id</CustomTableCell>
                      <CustomTableCell>Description</CustomTableCell>
                      <CustomTableCell>Text Description</CustomTableCell>
                      <CustomTableCell>Include Account</CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.linkedAccounts &&
                      _.map(this.props.linkedAccounts, (linkedAccount, index) => (
                        <TableRow key={index}>
                          <CustomTableCell>
                            {linkedAccount.displayAccountId}
                          </CustomTableCell>
                          <CustomTableCell>
                            {linkedAccount.accountDescription ||
                              linkedAccount.defaultDescription}
                          </CustomTableCell>
                          <CustomTableCell>
                            <TextField
                              type="text"
                              label={"Text Description"}
                              id="textDescription"
                              aria-label="input text description"
                              value={linkedAccount.textDescription}
                              onChange={e =>
                                this.handleLinkedAccountChange(
                                  linkedAccount.displayAccountId,
                                  "textDescription",
                                  e,
                                )
                              }
                            />
                          </CustomTableCell>
                          <CustomTableCell>
                            <Checkbox
                              onChange={e =>
                                this.handleLinkedAccountChange(
                                  linkedAccount.displayAccountId,
                                  "includeAccount",
                                  e,
                                )
                              }
                              checked={linkedAccount.includeAccount || false}
                            />
                          </CustomTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
              <br />
              <div>
                <Button primary onClick={this.handleUpdateButtonClicked}>
                  Update
                </Button>
              </div>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.textBanking.isFetching,
  linkedAccounts: state.textBanking.linkedAccounts,
  showSuccessModal: state.textBanking.showSuccessModal,
});

export default withStyles(tableStyles)(
  connect(mapStateToProps, { ...actions, push })(TextBankingAccountSetup),
);
