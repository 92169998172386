import React from "react";

import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

const ContainerArrows = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .chevron-left,
  .chevron-right {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .chevron-left {
    margin-left: 5px;
  }
  .chevron-right {
    margin-top: 5px;
  }
`;

const ContainerArrowsOn = styled.div`
  display: flex;
  margin: 20px 10px;
  width: 100%;
  align-items: center;
  input {
    padding-left: 20px;
  }
`;

const RenderArrows = ({ on, onOpen, fiSideNavLogoCollapsed, fiSideNavLogoExtended }) => (
  <ContainerArrows>
    {on ? (
      <ContainerArrowsOn>
        <img
          src={fiSideNavLogoExtended}
          style={{ width: "104px", opacity: 1, display: "flex", paddingLeft: "20px" }}
        />
        <FeatherIcon
          icon="chevron-left"
          className="chevron-left"
          onClick={() => onOpen(false)}
        />
      </ContainerArrowsOn>
    ) : (
      <div style={{ margin: "15px 0px" }}>
        <img
          src={fiSideNavLogoCollapsed}
          style={{ opacity: 1, width: "20px", display: "flex" }}
        />
        <FeatherIcon
          icon="chevron-right"
          className="chevron-right"
          onClick={() => onOpen(false)}
        />
      </div>
    )}
  </ContainerArrows>
);

RenderArrows.propTypes = {
  on: PropTypes.bool,
  onOpen: PropTypes.func,
  fiSideNavLogoCollapsed: PropTypes.string,
  fiSideNavLogoExtended: PropTypes.string,
};

export default RenderArrows;
