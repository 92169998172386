import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import Calendar from "../EPayments/EPaySummary/Calendar";
import Modal from "react-modal";
import TextField from "../../../components/commons/TextField";
import Select from "../../../components/commons/SelectInput";
import {
  MenuItem,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";
import {
  Button,
  Space,
  Loader,
  Heading,
  HeadingModal,
  P,
} from "../../../components/commons";
import {
  Cancel,
  ArrowDownCircle,
  Plus,
  ArrowLeft,
} from "../../../components/commons/icons";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import { withStyles } from "@material-ui/core/styles";
import {
  MobileTable,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import enums from "../../../utils/enums";
import { selectAccounts } from "../Accounts/selectors";
import { DashboardScreensWithSubmMenu } from "../DashboardInstance/Dashboard";
import { AddButton } from "../Goals/GoalsInstance";
import SelectCustom from "./SelectCustom";

// actions
import * as actions from "./actions";
import { getAccountSummary } from "../Accounts/actions";
import NoteInput from "./NoteInput";
import CircularProgress from "@material-ui/core/CircularProgress";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "100%",
    maxWidth: 400,
    margin: "auto",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 30,
    backgroundColor: "rgba(230, 230, 230, 0.8)",
  },
};

const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
  checked: {
    color: "#0AC77B !important",
  },
  default: {
    right: 10,
    color: "#0AC77B",
  },
  size: {
    width: 40,
    height: 40,
  },
  sizeIcon: {
    fontSize: 20,
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#7A7A7A",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    padding: "1rem",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#7A7A7A",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

const TitleSelectAccount = styled.span`
  color: #3fc78e;
  font-family: Kumbh Sans;
  font-size: 12px;
  font-weight: bold;
  display: block;
  letter-spacing: 2px;
  margin-top: 5px;
`;

const SelectContainer = styled.div`
  width: 49%;
  display: flex;
  align-self: flex-end;
  @media (max-width: 850px) {
    width: 100%;
  }
`;

const TwoContainers = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    padding-top: 20px;
    padding-bottom: 5px;
    & > div:last-child {
      padding-top: 20px;
    }
  }
`;

const Container = styled.div``;

const ContainerMobil = styled.div`
  width: 100%;
  .backArrow {
    display: flex;
    margin-top: 10px;
    span {
      color: #000000;
      font-family: "Kumbh Sans";
      font-size: 16px;
      font-weight: bold;
    }
  }
  .containerTransferHistory {
    display: flex;
    align-items: center;
    margin: 15px 0px 20px 0px;

    span {
      color: #60d39d;
      font-family: Kumbh Sans;
      font-size: 12px;
      font-weight: bold;
      width: 100%;
      text-transform: capitalize;
      letter-spacing: 2px;
    }
  }
`;

const ContainerDesktop = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .containerLeft {
    max-width: 650px;
    padding: 0px 5%;
    width: 50%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1100px) {
      padding: 0;
    }
    @media (max-width: 1100px) {
      min-width: inherit;
      width: 100%;
    }
    .title {
      color: #152134;
      font-family: "Kumbh Sans";
      font-size: 36px;
      font-weight: bold;
    }
    .containerNote {
      display: flex;
      @media (max-width: 1100px) {
        margin-bottom: 50px;
      }
      & > div {
        width: 85%;
      }
    }

    .fixedBottom {
      position: relative;
      padding-bottom: 20px;
      @media (min-width: 1101px) {
        margin-top: auto;
        width: 100%;
      }
      .MuiFormControl-root,
      .MuiInputBase-root {
        min-width: auto;
      }
    }

    .buttonTransfer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
    .separation {
      margin-top: 20px;
      height: 1px;
      width: 100%;
      background-color: #ebeff3;
    }
  }
  .containerRight {
    overflow-y: auto;
    max-height: 88vh;
    max-width: 450px;
    border-left: 1px solid #ebeff3;
    padding-left: 58px;
    padding: 0;
    padding-left: 2%;
    padding-right: 2%;
    width: 35%;
    @media (max-width: 1100px) {
      border-left: none;
      padding: 0;
      max-width: inherit;
      width: 100%;
    }
    .title {
      color: #60d39d;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 20px;
      display: block;
      letter-spacing: 2px;
    }
  }
`;

const PTransferBold = styled.p`
  font-size: 16px;
  color: #292b36;
  font-weight: bold;
`;

const PTransferNormal = styled.p`
  font-size: 16px;
  color: #292b36;
  font-weight: normal;
  margin: 0;
`;

const PTransferAmount = styled.p`
  font-size: 36px;
  color: #292b36;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  width: 100%;
`;

const PTransferGreen = styled.p`
  color: #60d39d;
  font-size: 12px;
  font-weight: bold;
  display: block;
  letter-spacing: 2px;
  margin: 0;
  margin-top: 24px;
`;

const PTransferGreen2 = styled.p`
  color: #60d39d;
  font-size: 17px;
  font-weight: bold;
  display: block;
  margin: 0;
  margin-top: 24px;
`;

const DivTransfer = styled.div`
  text-align: center;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

const TransferSummary = ({
  title,
  balance,
  available,
  description,
  transferType = undefined,
}) => (
  <div style={{ width: "100%" }}>
    {title && <Heading>{title}</Heading>}
    <Table
      style={{
        minWidth: "100%",
        border: "1px solid rgba(18,55,77,0.08)",
        borderRadius: 10,
        borderCollapse: "separate",
      }}
    >
      <TableHead style={{ backgroundColor: "#f8f9fa" }}>
        <TableRow>
          <CustomTableCell>
            {transferType === "CREDIT_CARD" ? "Card" : "Description"}
          </CustomTableCell>
          <CustomTableCell>Balance</CustomTableCell>
          <CustomTableCell>Available</CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <CustomTableCell>{description}</CustomTableCell>
          <CustomTableCell>
            <CurrencyFormat
              value={balance}
              prefix={"$"}
              displayType={"text"}
              thousandSeparator
            />
          </CustomTableCell>
          <CustomTableCell>
            <CurrencyFormat
              value={available}
              prefix={"$"}
              displayType={"text"}
              thousandSeparator
            />
          </CustomTableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

const ContainerTransfers = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ebeff3;
  padding: 5px 0px;

  .accounts {
    flex: 2;

    .from {
      display: block;
      color: #000000;
      font-family: Kumbh Sans;
      font-size: 15px;
    }

    .to {
      display: block;
      color: rgba(69, 68, 77, 0.6);
      font-family: Kumbh Sans;
      font-size: 15px;
    }
  }
  .date {
    display: block;
    color: rgba(78, 77, 87, 0.6);
    font-family: Kumbh Sans;
    font-size: 12px;
  }
  .amount {
    display: block;
    color: #141f2d;
    font-family: Kumbh Sans;
    font-size: 17px;
    text-align: right;
  }
`;

const CardTransferent = ({ effectiveDate, amount, to, from }) => (
  <ContainerTransfers>
    <ArrowDownCircle
      styles={{
        display: "flex",
        alignItems: "center",
        marginRight: "15px",
      }}
    />
    <div className="accounts">
      <span className="from">From:{` ${from}`}</span>
      <span className="to">To:{` ${to}`}</span>
    </div>
    <div>
      <span className="date">{moment(effectiveDate).utc().format("MM-DD-YYYY")}</span>
      <span className="amount">${amount}</span>
    </div>
  </ContainerTransfers>
);

const TransferSummaryMobile = ({ title, balance, available, description }) => (
  <div>
    <MobileTable title={title}>
      <MobileTableHeading value={description} />
      <MobileTableRow>
        <div>
          <MobileTableCell
            name={"balance"}
            value={
              <CurrencyFormat
                value={balance}
                prefix={"$"}
                displayType={"text"}
                thousandSeparator
              />
            }
          />
          <MobileTableCell
            name={"available"}
            value={
              <CurrencyFormat
                value={available}
                prefix={"$"}
                displayType={"text"}
                thousandSeparator
              />
            }
          />
        </div>
      </MobileTableRow>
    </MobileTable>
  </div>
);

TransferSummaryMobile.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  available: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
TransferSummaryMobile.defaultProps = {
  title: "Your Title",
  balance: 0,
  available: 0,
  description: "Your Description",
};
TransferSummary.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  available: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transferType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
TransferSummary.defaultProps = {
  title: "Your Title",
  balance: 0,
  available: 0,
  description: "Your Description",
};

class FundsTransfer extends Component {
  static propTypes = {
    classes: PropTypes.object,
    accounts: PropTypes.object,
    postFundsTransfer: PropTypes.func,
    dispatchValidateAmount: PropTypes.func,
    showTransferSuccessModal: PropTypes.func,
    showTransferErrorModal: PropTypes.func,
    getAccountSummary: PropTypes.func,
    isSuccessModalOpen: PropTypes.bool,
    isTransferErrorModalOpen: PropTypes.bool,
    confirmNum: PropTypes.string,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
  };
  state = {
    amount: 0,
    amountError: false,
    comment: "",
    selectedValueFrom: "",
    selectedValueTo: "",
    selectedTransferType: null,
    radioIdFrom: null,
    radioIdTo: null,
    formError: null,
    selectedRowFrom: {},
    selectedRowTo: {},
    isConfirmTransferModalOpen: false,
    stopSending: "",
    typesOfStopSending: [
      { key: 0, value: "Never" },
      { key: 1, value: "On select date" },
    ],
    frequency: "",
    typesOfFrequency: [
      { key: 0, value: "One time" },
      { key: 1, value: "Once a month" },
      { key: 2, value: "Weekly" },
      { key: 3, value: "Every 2 weeks" },
      { key: 4, value: "Every 2 months" },
      { key: 5, value: "Every 3 months" },
      { key: 6, value: "Every 6 months" },
      { key: 7, value: "Annually" },
    ],
  };

  componentDidMount() {
    this.props.getAccountSummary(true);
    this.props.getTransfers();
  }

  hanldeOpenConfirmationModal = () => {
    const { amount, amountError, radioIdFrom, radioIdTo } = this.state;
    if (
      !amount ||
      amount === "" ||
      !radioIdFrom ||
      radioIdFrom === "" ||
      !radioIdTo ||
      radioIdTo === "" ||
      amountError
    ) {
      this.setState({ formError: "Please complete all fields" });
      return false;
    }
    return this.setState({ isConfirmTransferModalOpen: true });
  };

  handleRadioChangeFrom(newValue, radioIdFrom, row, accountType) {
    let amount = parseFloat(this.state.amount.replace(/,/g, ""));
    if (amount < 0) {
      this.props.dispatchValidateAmount("Write an positive amount.");
      this.props.showTransferErrorModal(true);
      return;
    }
    if (amount > row.available) {
      this.props.dispatchValidateAmount("Insufficient Funds.");
      this.props.showTransferErrorModal(true);
      return;
    }
    if (radioIdFrom === this.state.radioIdTo) {
      return false;
    }
    if (accountType === enums.AccountType.SHARE && !row.allowBalanceInquiry) {
      row.balance = " - ";
      row.available = " - ";
    }
    this.setState({
      selectedValueFrom: newValue,
      selectedRowFrom: row,
      radioIdFrom,
    });

    return true;
  }

  handleRadioChangeTo(newValue, radioIdTo, row, accountType) {
    if (radioIdTo === this.state.radioIdFrom) {
      return false;
    }
    if (accountType === enums.AccountType.SHARE && !row.allowBalanceInquiry) {
      row.balance = " - ";
      row.available = " - ";
    }
    this.setState({
      selectedValueTo: newValue,
      selectedRowTo: accountType ? { ...row, accountType } : row,
      radioIdTo,
    });

    return true;
  }

  handleValidateAmount = async e => {
    const amount = e.target.value;
    await this.setState({ amount });
    const {
      selectedRowFrom: { available, legacyMinAdvanceAmount },
      amount: currentAmount,
    } = this.state;
    if (currentAmount * 100 > available * 100) {
      this.setState({
        amountError: true,
        formError: "Amount exceeds available balance",
      });
    } else if (legacyMinAdvanceAmount && legacyMinAdvanceAmount > amount) {
      this.setState({
        amountError: true,
        formError: `Minimum advace amount is ${legacyMinAdvanceAmount}`,
      });
    } else {
      this.setState({ amountError: false, formError: "" });
    }
  };

  resetState = () => {
    this.setState({
      amount: "",
      amountError: false,
      comment: "",
      selectedValueFrom: "",
      selectedValueTo: "",
      selectedTransferType: null,
      radioIdFrom: null,
      radioIdTo: null,
      formError: null,
      selectedRowFrom: {},
      selectedRowTo: {},
      isConfirmTransferModalOpen: false,
    });
  };

  handleSubmit = async () => {
    try {
      const {
        comment,
        amount,
        selectedRowFrom: { id: fromId, accountNumber: fromAccountNum },
        selectedRowTo: { id: toId, accountNumber: toAccountNum },
      } = this.state;
      await this.props.postFundsTransfer({
        comment,
        amount: parseFloat(amount.replace(/,/g, "")),
        fromId,
        toId,
        fromAccountNum,
        toAccountNum,
        transferType: 1,
      });
      await this.resetState();
      await this.props.getAccountSummary(true);
    } catch (err) {
      // console.log(error)
    }
  };

  renderTransferSuccessModal() {
    return (
      <Modal
        isOpen={this.props.isSuccessModalOpen}
        style={customStyles}
        aria-hidden={this.props.isSuccessModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <span style={{ flex: 2 }}>
            <HeadingModal id="heading">Confirm Transfer</HeadingModal>
          </span>
          <span>
            <Cancel onClick={() => this.props.showTransferSuccessModal(false)} />
          </span>
        </div>
        <div id="full_description" className="successModalBody">
          <DivTransfer>
            <PTransferAmount>
              <span role="img" aria-label="FlyingMoney">
                💸
              </span>
            </PTransferAmount>
            <PTransferGreen2>Transfer Successful</PTransferGreen2>
            <PTransferAmount>Your money has been transferred</PTransferAmount>
          </DivTransfer>
        </div>
        <div className="modalFooter">
          <Button primary onClick={() => this.props.showTransferSuccessModal(false)}>
            Done
          </Button>
        </div>
      </Modal>
    );
  }

  renderTransferErrorModal() {
    return (
      <Modal
        isOpen={this.props.isTransferErrorModalOpen}
        style={customStyles}
        aria-hidden={this.props.isTransferErrorModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <span style={{ flex: 2 }}>
            <HeadingModal id="heading">Transfer Error</HeadingModal>
          </span>
          <span>
            <Cancel onClick={() => this.props.showTransferErrorModal(false)} />
          </span>
        </div>
        <div id="full_description" className="successModalBody">
          <p>{this.props.error}</p>
        </div>
        <div className="modalFooter">
          <Button primary onClick={() => this.props.showTransferErrorModal(false)}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }

  renderTransferConfirmationModal() {
    return (
      <Modal
        isOpen={this.state.isConfirmTransferModalOpen}
        style={customStyles}
        aria-hidden={this.state.isConfirmTransferModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <span style={{ flex: 1 }}>
            <HeadingModal id="heading">Confirm Transfer</HeadingModal>
          </span>
          <span>
            <Cancel
              onClick={() => this.setState({ isConfirmTransferModalOpen: false })}
            />
          </span>
        </div>
        <div id="full_description" style={{ padding: "1rem", textAlign: "center" }}>
          <PTransferBold>Transfer</PTransferBold>
          <PTransferAmount>
            {(
              <CurrencyFormat
                value={this.state.amount}
                displayType={"text"}
                thousandSeparator
                prefix={"$"}
              />
            ) || ""}
          </PTransferAmount>
          <PTransferGreen>FROM:</PTransferGreen>
          <PTransferNormal>
            {this.state.selectedRowFrom.description ||
              this.state.selectedRowFrom.defaultDescription ||
              ""}
          </PTransferNormal>
          <PTransferGreen>TO:</PTransferGreen>
          <PTransferNormal>
            {" "}
            {this.state.selectedRowTo.description ||
              this.state.selectedRowTo.defaultDescription ||
              ""}
          </PTransferNormal>
          <P>
            <span>{this.state.comment || ""}</span>
          </P>
        </div>
        <br />
        <div className="modalFooter">
          <Button
            onClick={() => this.setState({ isConfirmTransferModalOpen: false })}
            disabled={this.props.isFetching}
          >
            Cancel
          </Button>
          <Space />
          <Button primary onClick={this.handleSubmit} disabled={this.props.isFetching}>
            {this.props.isFetching ? <CircularProgress /> : "Submit"}
          </Button>
        </div>
      </Modal>
    );
  }

  formatUniqueId(account, suffix) {
    let newSuffix = suffix;

    if (String(suffix).length < 3) {
      newSuffix = "";
      for (let i = 0; i < 3 - String(suffix).length; i++) {
        newSuffix = `${newSuffix}0`;
      }
      newSuffix = `${newSuffix}${suffix}`;
    }

    return `${account}-${newSuffix}`;
  }

  getFromAccount(description) {
    let fromAccount = description.substring(
      description.indexOf(":") + 1,
      description.indexOf(";") !== -1 ? description.indexOf(";") : description.length,
    );
    let firstDigits = fromAccount.substring(0, fromAccount.indexOf("-"));
    let secondDigits = fromAccount.substring(
      fromAccount.indexOf("-") + 1,
      fromAccount.length,
    );

    return this.formatUniqueId(parseInt(firstDigits), parseInt(secondDigits));
  }

  render() {
    return (
      <DashboardScreensWithSubmMenu style={{ padding: "0px 20px" }}>
        <Container>
          <Loader isLoading={this.props.isFetching} />
          {this.renderTransferSuccessModal()}
          {this.renderTransferConfirmationModal()}
          {this.renderTransferErrorModal()}

          <Mobile>
            <ContainerMobil>
              {this.state.mobileNewTransfer ? (
                <>
                  <div>
                    <div className="backArrow">
                      <ArrowLeft
                        styles={{ marginRight: "5px" }}
                        stylesInput={{
                          opacity: 1,
                          width: "24px",
                          height: "24px",
                        }}
                        onClick={() => this.setState({ mobileNewTransfer: false })}
                      />
                      <span>New Transfer</span>
                    </div>

                    <CurrencyFormat
                      notMargin
                      name="amount"
                      id="amount"
                      customInput={TextField}
                      thousandSeparator={true}
                      error={this.state.formError || this.state.amountError}
                      helperText={this.state.formError}
                      value={this.state.amount}
                      onChange={this.handleValidateAmount}
                      bigField
                      startAdornment={
                        <InputAdornment position="start">
                          <span className="bigAdornment">$</span>
                        </InputAdornment>
                      }
                    />
                    <NoteInput
                      onChange={e => this.setState({ comment: e.target.value })}
                      type="text"
                      id="comment"
                      name="personaleComment"
                      label="Note"
                      value={this.state.comment}
                      notMargin
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <TitleSelectAccount>FROM:</TitleSelectAccount>
                    <SelectCustom
                      error={this.state.formError}
                      options={[
                        ...this.props.accounts.shares,
                        ...this.props.accounts.loans,
                      ]}
                      onClickItemSelect={(e, item) => {
                        return this.handleRadioChangeFrom(
                          e,
                          item.uniqueId,
                          item,
                          enums.AccountType.SHARE,
                        );
                      }}
                      selected={this.state.selectedRowFrom}
                    />
                    <TitleSelectAccount style={{ marginTop: "10px" }}>
                      TO :
                    </TitleSelectAccount>
                    <SelectCustom
                      error={this.state.formError}
                      options={[
                        ...this.props.accounts.shares,
                        ...this.props.accounts.loans,
                      ]}
                      onClickItemSelect={(e, item) => {
                        return this.handleRadioChangeTo(
                          e,
                          item.uniqueId,
                          item,
                          enums.AccountType.SHARE,
                        );
                      }}
                      selected={this.state.selectedRowTo}
                    />
                    <TwoContainers>
                      <SelectContainer>
                        <Calendar
                          label={
                            this.state.frequency !== "" && this.state.frequency !== 0
                              ? "Start Sending On"
                              : "Sending On"
                          }
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <Select
                          fullWidth
                          customSize
                          id="frequency"
                          name="frequency"
                          aria-label="frequency"
                          withoutMargin
                          label={"Frequency"}
                          value={this.state.frequency}
                          onChange={e => {
                            this.setState({ frequency: e.target.value });
                          }}
                        >
                          {_.map(this.state.typesOfFrequency, (option, idx) => (
                            <MenuItem key={idx} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectContainer>
                    </TwoContainers>
                    <TwoContainers
                      style={
                        this.state.frequency !== "" && this.state.frequency !== 0
                          ? { display: "flex" }
                          : { display: "none" }
                      }
                    >
                      <SelectContainer>
                        <Select
                          fullWidth
                          customSize
                          id="stopSending"
                          name="stopSending"
                          aria-label="stopSending"
                          withoutMargin
                          label={"Stop Sending On"}
                          value={this.state.stopSending}
                          onChange={e => {
                            this.setState({ stopSending: e.target.value });
                          }}
                        >
                          {_.map(this.state.typesOfStopSending, (option, idx) => (
                            <MenuItem key={idx} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectContainer>
                      <SelectContainer
                        style={
                          this.state.stopSending !== "" && this.state.stopSending !== 0
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        <Calendar label="Stop Date" />
                      </SelectContainer>
                    </TwoContainers>
                    <Button
                      disabled={this.state.amountError}
                      primary={!this.state.amountError}
                      onClick={this.hanldeOpenConfirmationModal}
                      className="buttonTransferentMobil"
                      style={{
                        backgroundColor: "#3FC78E",
                        width: "100%",
                        marginTop: "20px",
                        marginBottom: "10px",
                        color: "#FFFFFF",
                        fontFamily: "Kumbh Sans",
                        fontSize: "17px",
                        fontWeight: "normal",
                      }}
                    >
                      Transfer
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="containerTransferHistory">
                    <span>TRANSFER HISTORY</span>
                    <AddButton
                      primary
                      onClick={() => this.setState({ mobileNewTransfer: true })}
                      style={{ width: "28px", height: "28px" }}
                    >
                      <Plus />
                    </AddButton>
                  </div>
                  {this.props.transfers &&
                    _.map(this.props.transfers, (item, index) => (
                      <CardTransferent
                        key={index}
                        effectiveDate={item.effectiveDate}
                        amount={item.amount}
                        to={item.accountId}
                        from={this.getFromAccount(item.description)}
                      />
                    ))}
                </>
              )}
            </ContainerMobil>
          </Mobile>
          <Desktop>
            <ContainerDesktop>
              <div className="containerLeft">
                <span className="title">Transfer</span>
                <div className="separation" />
                <CurrencyFormat
                  notMargin
                  name="amount"
                  id="amount"
                  customInput={TextField}
                  thousandSeparator={true}
                  error={this.state.formError || this.state.amountError}
                  helperText={this.state.formError}
                  value={this.state.amount}
                  onChange={this.handleValidateAmount}
                  bigField
                  startAdornment={
                    <InputAdornment position="start">
                      <span className="bigAdornment">$</span>
                    </InputAdornment>
                  }
                />

                <div className="containerNote">
                  <NoteInput
                    onChange={e => this.setState({ comment: e.target.value })}
                    type="text"
                    id="comment"
                    name="personaleComment"
                    label="Note"
                    value={this.state.comment}
                    notMargin
                    fullWidth
                  />
                </div>
                <div className="fixedBottom">
                  <div>
                    <div>
                      <TitleSelectAccount>SEND FROM:</TitleSelectAccount>
                      <SelectCustom
                        error={this.state.formError}
                        options={[
                          ...this.props.accounts.shares,
                          ...this.props.accounts.loans,
                        ]}
                        onClickItemSelect={(e, item) => {
                          return this.handleRadioChangeFrom(
                            e,
                            item.uniqueId,
                            item,
                            enums.AccountType.SHARE,
                          );
                        }}
                        selected={this.state.selectedRowFrom}
                        styleContainer={{ padding: "0px" }}
                      />
                    </div>
                    <div>
                      <TitleSelectAccount style={{ marginTop: "20px" }}>
                        SEND TO:
                      </TitleSelectAccount>
                      <SelectCustom
                        error={this.state.formError}
                        options={[
                          ...this.props.accounts.shares,
                          ...this.props.accounts.loans,
                        ]}
                        onClickItemSelect={(e, item) => {
                          return this.handleRadioChangeTo(
                            e,
                            item.uniqueId,
                            item,
                            enums.AccountType.SHARE,
                          );
                        }}
                        selected={this.state.selectedRowTo}
                        styleContainer={{ padding: "0px" }}
                      />
                    </div>
                    <TwoContainers>
                      <SelectContainer>
                        <Calendar
                          label={
                            this.state.frequency !== "" && this.state.frequency !== 0
                              ? "Start Sending On"
                              : "Sending On"
                          }
                        />
                      </SelectContainer>
                      <SelectContainer>
                        <Select
                          fullWidth
                          customSize
                          id="frequency"
                          name="frequency"
                          aria-label="frequency"
                          withoutMargin
                          label={"Frequency"}
                          value={this.state.frequency}
                          onChange={e => {
                            this.setState({ frequency: e.target.value });
                          }}
                        >
                          {_.map(this.state.typesOfFrequency, (option, idx) => (
                            <MenuItem key={idx} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectContainer>
                    </TwoContainers>
                    <TwoContainers
                      style={
                        this.state.frequency !== "" && this.state.frequency !== 0
                          ? { display: "flex" }
                          : { display: "none" }
                      }
                    >
                      <SelectContainer>
                        <Select
                          fullWidth
                          customSize
                          id="stopSending"
                          name="stopSending"
                          aria-label="stopSending"
                          withoutMargin
                          label={"Stop Sending On"}
                          value={this.state.stopSending}
                          onChange={e => {
                            this.setState({ stopSending: e.target.value });
                          }}
                        >
                          {_.map(this.state.typesOfStopSending, (option, idx) => (
                            <MenuItem key={idx} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </SelectContainer>
                      <SelectContainer
                        style={
                          this.state.stopSending !== "" && this.state.stopSending !== 0
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        <Calendar label="Stop Date" />
                      </SelectContainer>
                    </TwoContainers>
                  </div>
                  <div className="buttonTransfer">
                    <Button
                      disabled={this.state.amountError}
                      primary={!this.state.amountError}
                      onClick={this.hanldeOpenConfirmationModal}
                      style={{ backgroundColor: "#3FC78E", width: "200px" }}
                    >
                      Transfer
                    </Button>
                  </div>
                </div>
              </div>
              <div className="containerRight">
                <span className="title">TRANSFER HISTORY</span>
                {this.props.transfers &&
                  _.map(this.props.transfers, item => (
                    <CardTransferent
                      key={item.id}
                      effectiveDate={item.effectiveDate}
                      amount={item.amount}
                      to={item.accountId}
                      from={this.getFromAccount(item.description)}
                    />
                  ))}
              </div>
            </ContainerDesktop>
          </Desktop>
        </Container>
      </DashboardScreensWithSubmMenu>
    );
  }
}

const mapStateToProps = state => ({
  accounts: selectAccounts(state, true),
  isSuccessModalOpen: state.fundsTransfer.isSuccessModalOpen,
  transfers: state.fundsTransfer.transfers,
  isTransferErrorModalOpen: state.fundsTransfer.isTransferErrorModalOpen,
  confirmNum: state.fundsTransfer.confirmNum,
  isFetching: state.fundsTransfer.isFetching,
  error: state.fundsTransfer.error,
});

export default withStyles(tableStyles)(
  connect(mapStateToProps, { ...actions, getAccountSummary })(FundsTransfer),
);
