import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 8px 14px 0 rgba(0, 0, 0, 0.1);",
    fontSize: 12,
    borderRadius: "10px",
    padding: 0,
    height: "25px",
    display: "flex",
    alignItems: "center",
    width: "79px",
    justifyContent: "center",
    "&>p": {
      display: "flex",
      alignItems: "center",
    },
  },
}))(Tooltip);

export const StatusContainer = styled.div`
  display: flex;
  float: right;
  width: 60px;
  cursor: pointer;
  height: 30px;
  justify-content: left;
  align-items: center;
  &:hover > div > a {
    opacity: 1;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  min-width: 195px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 15px;
  right: -2px;
  top: 20px;
  border-radius: 12px;
  padding: 0 12px;
`;

export const DropdownItem = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 6px 15px;
  align-items: center;
  color: ${({ danger }) => (danger ? "#cb3737" : "#141f2d")};
  &:hover {
    background-color: #ebeef1;
    border-radius: 12px;
  }
`;

export const IconContainer = styled.a`
  margin-left: 8px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  opacity: 1;
  background-color: ${({ clicked }) => (clicked ? "#ebeef1" : "transparent")};
  opacity: ${({ clicked }) => (clicked ? "1" : "0")};
  border-radius: 50%;
  div {
    display: flex;
  }
  &:svg {
    height: 25px;
    width: 25px;
  }
  &:hover {
    opacity: 0.8;
    background-color: #ebeef1;
  }
`;

export const Sort = styled.a`
  padding: 9px 5px 0 5px;
`;

export const NoPhoto = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 22px;
  background-color: #ebeff3;
`;

export const PhotoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#ffffff",
    color: "#1f2427",
    opacity: "0.4",
    fontSize: "14px",
    padding: "0.5rem 1rem",
    fontWeight: "normal",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    borderBottom: "1px solid rgba(18,55,77,0.08)",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "0.5rem 1rem",
    fontSize: "16px",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#141F2D",
    lineHeight: "1rem",
    borderBottom: "1px solid rgba(18,55,77,0.08)",
    fontFamily: "Kumbh Sans",
    width: "15%",
  },
}))(TableCell);
