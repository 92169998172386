import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import styled from "styled-components";
import TextField from "../../components/commons/TextField";
import Select from "../../components/commons/SelectInput";
import { MenuItem } from "@material-ui/core";
import Checkbox from "../../components/commons/Checkbox";
import { SuccessModal } from "../../components/commons/Modals";
import { Check } from "../../components/commons/icons";
import { PageTitle, Separator, Button, Loader } from "../../components/commons";
import { selectAllCustomers } from "./selectors";
import {
  getAllCustomers,
  createCustomerNotification,
  createCustomerDirectNotification,
  showCreateDirectNotificationSuccessModal,
  showCreateNotificationSuccessModal,
} from "./actions";
const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  background-color: white;
  width: 450px;
  padding: 1.5rem 3rem;
  max-height: 600px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1rem 0 0;

  ${Button} {
    margin-left: 1.5rem;
  }
`;

const CheckBoxArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 0 0.9rem;
`;

class CreateNotification extends Component {
  static propTypes = {
    push: PropTypes.func,
    getAllCustomers: PropTypes.func,
    createCustomerNotification: PropTypes.func,
    createCustomerDirectNotification: PropTypes.func,
    isFetching: PropTypes.bool,
    customers: PropTypes.array,
    error: PropTypes.string,
    showSuccessModal: PropTypes.bool,
    showCreateNotificationSuccessModal: PropTypes.func,
    showCreateDirectNotificationSuccessModal: PropTypes.func,
    showDirectNotificationSuccessModal: PropTypes.bool,
  };
  state = {
    isDirectMessage: false,
    customerId: "",
    formError: false,
    important: false,
    imageUrl: "",
    notification: "",
  };
  componentDidMount() {
    this.props.getAllCustomers();
  }
  handleCreateNotification = async () => {
    try {
      const { notification, imageUrl, important } = this.state;
      if (notification && notification !== "") {
        this.setState({ formError: false });
        const notificationBody = {
          notification,
          imageUrl,
          important,
        };
        await this.props.createCustomerNotification(notificationBody);
        return this.resetState();
      }
      return this.setState({ formError: true });
    } catch (err) {
      // ...
    }
    return true;
  };

  handleCreateDirectNotification = async () => {
    try {
      const { notification, imageUrl, important, customerId } = this.state;
      if (notification !== "" && customerId !== "") {
        this.setState({ formError: false });
        const notificationBody = {
          notification,
          imageUrl,
          important,
          idOLBUser: customerId,
        };
        await this.props.createCustomerDirectNotification(notificationBody);
        return this.resetState();
      }
      return this.setState({ formError: true });
    } catch (err) {
      // ...
    }
    return true;
  };

  resetState() {
    this.setState({
      isDirectMessage: false,
      customerId: "",
      formError: false,
      important: false,
      imageUrl: "",
      notification: "",
    });
  }
  renderCreateNotificationSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.showSuccessModal}
        onClose={() => this.props.showCreateNotificationSuccessModal(false)}
        headingText="Notification Created Successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  renderCreateDirectNotificationSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.showDirectNotificationSuccessModal}
        onClose={() => this.props.showCreateDirectNotificationSuccessModal(false)}
        headingText="Notification Created Successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  render() {
    return (
      <Layout>
        {this.renderCreateNotificationSuccessModal()}
        {this.renderCreateDirectNotificationSuccessModal()}
        <Loader isLoading={this.props.isFetching} />
        <Container>
          <PageTitle>Create Notification</PageTitle>
          <Separator />
          <Form>
            <CheckBoxArea>
              <Checkbox
                onChange={e => this.setState({ important: e.target.checked })}
                checked={this.state.important}
                label={"Important"}
              />
            </CheckBoxArea>
            <TextField
              id="notification"
              label="Notification *"
              value={this.state.notification}
              error={this.state.formError}
              helperText={this.state.formError && "please add a valid notification"}
              onChange={e => this.setState({ notification: e.target.value })}
            />
            <TextField
              id="notificationUrl"
              label="Insert Image URL here"
              value={this.state.imageUrl}
              onChange={e => this.setState({ imageUrl: e.target.value })}
            />
            <CheckBoxArea>
              <Checkbox
                onChange={e => this.setState({ isDirectMessage: e.target.checked })}
                checked={this.state.isDirectMessage}
                label={"Direct Message"}
              />
            </CheckBoxArea>
            {this.state.isDirectMessage && (
              <Select
                id="customerList"
                name="customer list"
                aria-label="customer list"
                label={"Customer List"}
                error={this.state.formError}
                helperText={
                  this.state.formError &&
                  "please select a customer for direct notification"
                }
                value={this.state.customerId}
                onChange={e => this.setState({ customerId: e.target.value })}
              >
                {this.props.customers ? (
                  _.map(this.props.customers, (customer, index) => (
                    <MenuItem
                      style={{ backgroundColor: "white" }}
                      key={index}
                      value={customer.idOLBUser}
                    >
                      {customer.username}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem style={{ backgroundColor: "white" }} value="">
                    Select an Option
                  </MenuItem>
                )}
              </Select>
            )}
          </Form>
          <FormControls>
            <Button info onClick={() => this.props.push("/admin/panel/")}>
              Go Back
            </Button>
            <Button
              primary
              onClick={
                this.state.isDirectMessage
                  ? this.handleCreateDirectNotification
                  : this.handleCreateNotification
              }
            >
              Create Notification
            </Button>
          </FormControls>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  customers: selectAllCustomers(state),
  isFetching: state.admin.isFetching,
  error: state.admin.error,
  showSuccessModal: state.admin.showCreateNotificationSuccessModal,
  showDirectNotificationSuccessModal:
    state.admin.showCreateDirectNotificationSuccessModal,
});

export default connect(mapStateToProps, {
  push,
  getAllCustomers,
  createCustomerNotification,
  createCustomerDirectNotification,
  showCreateDirectNotificationSuccessModal,
  showCreateNotificationSuccessModal,
})(CreateNotification);
