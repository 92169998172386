import React from "react";

import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useSortableData, SortIcon } from "../../../../hooks/useSortableTable";
import {
  CustomTableCell,
  Sort,
  NoPhoto,
  PhotoContainer,
} from "../../../commons/SortableTableStyles";
import Status from "./Status";

const SortableTable = ({ classes, members, loading, changeStatusMember }) => {
  const { items, requestSort, sortConfig } = useSortableData(members);

  return (
    <Table className={classes.table}>
      <TableHead className={classes.thead}>
        <TableRow className={classes.row}>
          <CustomTableCell>
            <Sort onClick={() => requestSort("name")}>
              <SortIcon tableHeader="name" sortConfig={sortConfig} />
            </Sort>
            Name
          </CustomTableCell>
          <CustomTableCell>
            <Sort onClick={() => requestSort("username")}>
              <SortIcon tableHeader="username" sortConfig={sortConfig} />
            </Sort>
            Username
          </CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell style={{ textAlign: "right" }}>
            Status
            <Sort onClick={() => requestSort("status")}>
              <SortIcon tableHeader="status" sortConfig={sortConfig} />
            </Sort>
          </CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <CustomTableCell style={{ width: "100%", textAlign: "center" }} colSpan={6}>
              <CircularProgress style={{ color: "#05CE86" }} />
            </CustomTableCell>
          </TableRow>
        ) : (
          _.map(items, ({ id, username, name, status }, key) => (
            <TableRow key={key}>
              <CustomTableCell>
                <PhotoContainer>
                  <NoPhoto>
                    <FeatherIcon icon="user" size="20" />
                  </NoPhoto>
                  {name}
                </PhotoContainer>
              </CustomTableCell>
              <CustomTableCell>@{username}</CustomTableCell>
              <CustomTableCell></CustomTableCell>
              <CustomTableCell style={{ padding: 0 }}>
                <Status
                  name={name}
                  username={username}
                  status={status}
                  changeStatusMember={status => changeStatusMember(status, id)}
                />
              </CustomTableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

SortableTable.prototype = {
  classes: PropTypes.object,
  members: PropTypes.array,
  loading: PropTypes.bool,
  changeStatusMember: PropTypes.func,
};

export default SortableTable;
