// import { showExternalConfirmationModal } from 'containers/Dashboard/QuickLinks/actions';
import * as types from "./constants";
import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import { post } from "../../../utils/server";
const ROOT_URL_SSO = `/sso`;

// single sign ons
export const getSsoList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_GET_SSO_LIST });
    const {
      auth: { userId },
    } = getState();
    const {
      data: { data: ssoList, success, error },
    } = await post(`${ROOT_URL_SSO}/getlist`, { userId });
    if (success) {
      dispatch({ type: types.GET_SSO_LIST_SUCCESS, payload: ssoList });
    } else {
      dispatch({ type: types.GET_SSO_LIST_FAILURE, payload: error });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_SSO_LIST_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_SSO_LIST_FAILURE,
        payload: "error trying to get single sign on list.",
      });
    }
  }
};

export const getSsoRedirectUrl = ssoId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_GET_SSO_REDIRECT_URL });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL_SSO}/geturl`, { userId, ssoId });
    if (data.success) {
      dispatch({
        type: types.GET_SSO_REDIRECT_URL_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_SSO_REDIRECT_URL_FAILURE,
        payload: data.message || "error trying to get redirect url",
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_SSO_REDIRECT_URL_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_SSO_REDIRECT_URL_FAILURE,
        payload: "error trying to get redirect url",
      });
    }
  }
};
