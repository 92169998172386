import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import * as types from "./constants";
import { post } from "../../../utils/server";
const ROOT_URL = `/textbanking`;
const FI_ROOT_URL = `/fi`;

export const getTextBankingUsage = (
  phoneNumber,
  fromDate = null,
  toDate = null,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_TEXT_BANKING_USAGE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getusage`, {
      userId,
      phoneNumber,
      fromDate,
      toDate,
    });
    if (data.success) {
      dispatch({
        type: types.GET_TEXT_BANKING_USAGE_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_TEXT_BANKING_USAGE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_TEXT_BANKING_USAGE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_TEXT_BANKING_USAGE_FAILURE,
        payload: "error trying to get banking usage",
      });
    }
  }
};

export const getCustomerPhones = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CUSTOMER_PHONES_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getphones`, { userId });
    if (data.success) {
      dispatch({
        type: types.GET_CUSTOMER_PHONES_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_PHONES_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_CUSTOMER_PHONES_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_PHONES_FAILURE,
        payload: "error trying to get customer's phones",
      });
    }
  }
};

export const insertCustomerPhoneStep1 = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.STEP_1_INSERT_CUSTOMER_PHONE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/insertphone/1`, { userId, ...payload });
    if (data.success) {
      dispatch({
        type: types.STEP_1_INSERT_CUSTOMER_PHONE_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.STEP_1_INSERT_CUSTOMER_PHONE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.STEP_1_INSERT_CUSTOMER_PHONE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.STEP_1_INSERT_CUSTOMER_PHONE_FAILURE,
        payload: "error trying to insert customer's phone",
      });
    }
  }
};

export const insertCustomerPhoneStep2 = smsCode => async (dispatch, getState) => {
  try {
    dispatch({ type: types.STEP_2_INSERT_CUSTOMER_PHONE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/insertphone/2`, { userId, smsCode });
    if (data.success) {
      dispatch({
        type: types.STEP_2_INSERT_CUSTOMER_PHONE_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.STEP_2_INSERT_CUSTOMER_PHONE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.STEP_2_INSERT_CUSTOMER_PHONE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.STEP_2_INSERT_CUSTOMER_PHONE_FAILURE,
        payload: "error trying to insert customer's phone",
      });
    }
  }
};

export const removeCustomerPhone = phoneNumber => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REMOVE_CUSTOMER_PHONE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/removephone`, { userId, phoneNumber });
    if (data.success) {
      dispatch({
        type: types.REMOVE_CUSTOMER_PHONE_SUCCESS,
        payload: data.data.idRemoved,
      });
      dispatch(closeRemovePhoneModal());
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_PHONE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_CUSTOMER_PHONE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_PHONE_FAILURE,
        payload: "error trying to remove customer's phone",
      });
    }
  }
};

export const getCommands = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_TEXT_BANKING_COMMANDS_REQUEST });
    const {
      config: { financialInstitutionId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getcommands`, { financialInstitutionId });
    if (data.success) {
      dispatch({ type: types.GET_TEXT_BANKING_COMMANDS_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_TEXT_BANKING_COMMANDS_FAILURE,
        payload: data.message || "",
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_TEXT_BANKING_COMMANDS_FAILURE,
        payload: err.response.data.message || "error trying to get commands",
      });
    } else {
      dispatch({
        type: types.GET_TEXT_BANKING_COMMANDS_FAILURE,
        payload: "error trying to get commands",
      });
    }
  }
};

export const getLinkedAccounts = phoneId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_PHONE_LINKED_ACCOUNTS_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getlinkedaccounts`, { userId, phoneId });
    if (data.success) {
      dispatch({ type: types.GET_PHONE_LINKED_ACCOUNTS_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_PHONE_LINKED_ACCOUNTS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_PHONE_LINKED_ACCOUNTS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_PHONE_LINKED_ACCOUNTS_FAILURE,
        payload: "error trying to get linked accounts.",
      });
    }
  }
};

export const updateLinkedAccount = linkedAccount => async dispatch => {
  try {
    dispatch({ type: types.UPDATE_LINKED_ACCOUNT_REQUEST });
    const { data } = await post(`${ROOT_URL}/updatelinkedaccount`, {
      phoneLinkedAccountId: linkedAccount.id,
      ...linkedAccount,
    });
    if (data.success) {
      dispatch({ type: types.UPDATE_LINKED_ACCOUNT_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.UPDATE_LINKED_ACCOUNT_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.UPDATE_LINKED_ACCOUNT_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.UPDATE_LINKED_ACCOUNT_FAILURE,
        payload: "error trying to update linked account.",
      });
    }
  }
};

export const updateLinkedAccountsFormValue = (id, name, value) => dispatch =>
  dispatch({
    type: types.UPDATE_LINKED_ACCOUNTS_FORM_VALUE,
    payload: { id, name, value },
  });

export const getTextBankingDisclosure = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_TEXT_BANKING_DISCLOSURE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${FI_ROOT_URL}/gettextbankingdisclosure`, { userId });
    if (data.success) {
      dispatch({ type: types.GET_TEXT_BANKING_DISCLOSURE_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_TEXT_BANKING_DISCLOSURE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_TEXT_BANKING_DISCLOSURE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_TEXT_BANKING_DISCLOSURE_FAILURE,
        payload: "Error getting Disclosure.",
      });
    }
  }
};

export const openRemovePhoneModal = () => ({ type: types.OPEN_CONFIRM_DELETE_MODAL });
export const closeRemovePhoneModal = () => ({ type: types.CLOSE_CONFIRM_DELETE_MODAL });
export const closeSuccessModal = () => ({ type: "CLOSE_SUCCESS_MODAL" });
