import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Button } from "../../../commons";
import CustomModal from "../../../commons/CustomModal";

const Title = styled.span`
  margin: 0 54px 31px 56px;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 17px;
  text-align: center;
  color: #000000;
  width: 76%;
`;

const User = styled.span`
  font-family: "Kumbh Sans", sans-serif;
  width: 100%;
  font-size: 16px;
  text-align: center;
  color: #000000;
`;

const Username = styled.span`
  width: 100%;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 10px;
  text-align: center;
  color: #a5a7a9;
  line-height: 1;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  & > button:focus {
    outline: #ffffff;
  }
`;

const ContainerPhoto = styled.div`
  display: flex;
  align-items: center;
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 66px;
    width: 66px;
  }
`;

function AdminModal({
  danger,
  open,
  handleClose,
  handleAction,
  name,
  username,
  actionQuestion,
  actionTextButton,
}) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <Title>{actionQuestion}</Title>
      <ContainerPhoto>
        <img
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXUViUQMThPkBwVp05lO7luNnq9FaNQB3G-Q&usqp=CAU"
          alt="ImageProfile"
        />
      </ContainerPhoto>
      <User>{name}</User>
      <Username>@{username}</Username>
      <Footer>
        <Button
          style={{ width: "100%" }}
          primary={!danger}
          danger={danger}
          onClick={e => {
            handleAction();
            handleClose(e);
          }}
        >
          {actionTextButton}
        </Button>
        <Button style={{ width: "100%", padding: "0.5rem 0" }} onClick={handleClose}>
          Cancel
        </Button>
      </Footer>
    </CustomModal>
  );
}

AdminModal.prototype = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAction: PropTypes.func,
  name: PropTypes.string,
  username: PropTypes.string,
};

export default AdminModal;
