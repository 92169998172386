import styled from "styled-components";
const Heading = styled.h4`
  margin-bottom: 1rem;
  line-height: 1.2;
  font-size: 12px;
  color: #3fc78e;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 2px;
  @media (max-width: 967px) {
    color: #899fbc;
    letter-spacing: normal;
    font-weight: normal;
  }
`;

const TransactionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    text-align: left;
  }

  p.available {
    color: #828991;
    font-size: 15px;
    text-align: right;
    margin: 0;
  }

  h2 {
    margin: 0;
    color: #292b36;
    font-size: 20px;
    text-align: right;
  }
`;

const HeadingModal = styled.h4`
  margin: 0;
  font-size: 18px;
  color: #141f2d;
  font-weight: bold;
`;

const PageTitle = styled.h2`
  line-height: 1.2;
  color: #141f2d;
  font-weight: 700;
  font-size: 36px;

  p {
    font-size: 15px;
    font-weight: normal;
    color: #828991;
    margin-top: 0;
  }
`;

const FiltersForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

const P = styled.p`
  font-weight: 300;
  line-height: 1.5;
  color: #8e949c;
  font-size: 1rem;
  @media (max-width: 967px) {
    span {
      color: #292b36;
    }
    letter-spacing: normal;
    font-weight: normal;
    margin: 0 0 16px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const B = styled.span`
  font-weight: 500;
`;
const Small = styled.span`
  font-size: 12px;
  color: #b9b9b9;
  line-height: 1.5;
  font-weight: 300;
`;

export {
  TransactionsContainer,
  PageTitle,
  FiltersForm,
  Heading,
  HeadingModal,
  P,
  B,
  Small,
};
