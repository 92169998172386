import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import FeatherIcon from "feather-icons-react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import ControlPanel from "../../components/commons/controlPanel";
import { DashboardScreens } from "../Dashboard/DashboardInstance/Dashboard";
import { getAllMembers, changeStatusMember } from "./actions";
import { UserStatus } from "../../utils/enums";
import SortableTable from "../../components/containers/Admin/Members/SortableTable";

const Container = styled.div`
  .MuiOutlinedInput-adornedStart {
    padding-left: 0px;
  }
`;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
    borderSpacing: 0,
  },
  table: {
    minWidth: 700,
    border: "1px solid #ebeef1",
    borderRadius: 5,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#fff",
    height: "43px",
  },
});

const filterItems = [
  {
    id: 1,
    title: "Status",
    link: "/",
    sub: [
      {
        id: 11,
        title: "Active",
        link: "/",
        sub: null,
        value: UserStatus.NONE,
      },
      {
        id: 12,
        title: "Locked",
        link: "/",
        sub: null,
        value: UserStatus.BLOCKED,
      },
      // {
      //   id: 13,
      //   title: "Suspended",
      //   link: "/",
      //   sub: null,
      //   value: UserStatus.BLOCKED,
      // },
    ],
  },
];

const Members = ({ classes, getAllMembers, members, isFetching, changeStatusMember }) => {
  const [search, setSearch] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  const handlerOnKeyPressSearch = e => {
    if (e.key === "Enter") {
      if (search.length > 0) {
        setIsSearch(true);
        getAllMembers({
          search,
          filterStatus:
            activeFilters.length === 0 ? null : activeFilters.map(item => item.value),
        });
      } else {
        getAllMembers({
          filterStatus:
            activeFilters.length === 0 ? null : activeFilters.map(item => item.value),
        });
      }
      e.preventDefault();
    }
  };

  const handlerChangeStatus = (status, idOLBUser) => {
    changeStatusMember({
      status,
      idOLBUser,
      search,
      filterStatus:
        activeFilters.length === 0 ? null : activeFilters.map(item => item.value),
    });
  };

  useEffect(() => {
    getAllMembers({});
  }, []);

  return (
    <DashboardScreens>
      <Container>
        <ControlPanel
          title="Members"
          filterItems={filterItems}
          valueSearch={search}
          onChangeSearch={e => setSearch(e.target.value)}
          onKeyPressSearch={handlerOnKeyPressSearch}
          deleteFilter={id => {
            const filter = activeFilters.filter(item => item.id !== id);
            setActiveFilters(filter);
            getAllMembers({
              search,
              filterStatus: filter.length === 0 ? null : filter.map(item => item.value),
            });
          }}
          activeFilters={activeFilters}
          setActiveFilters={filters => {
            setActiveFilters(filters);
            getAllMembers({
              search,
              filterStatus: filters.length === 0 ? null : filters.map(item => item.value),
            });
          }}
          loading={isFetching}
          formStyleSearch={{ marginLeft: "0px" }}
          endAdornmentSearch={
            search ? (
              <FeatherIcon
                icon="x"
                size="6"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                  if (isSearch) {
                    getAllMembers({});
                    setIsSearch(false);
                  }
                }}
              />
            ) : null
          }
        />
        <MediaQuery query="(min-width: 768px)">
          <SortableTable
            members={members}
            classes={classes}
            loading={isFetching}
            changeStatusMember={handlerChangeStatus}
          />
        </MediaQuery>
      </Container>
    </DashboardScreens>
  );
};

Members.prototype = {
  classes: PropTypes.object,
  getAllMembers: PropTypes.func,
  members: PropTypes.array,
  isFetching: PropTypes.object,
  changeStatusMember: PropTypes.func,
};

const mapStateToProps = state => ({
  members: state.admin.members,
  isFetching: state.admin.isFetching,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllMembers, changeStatusMember })(Members),
);
