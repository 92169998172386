import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import TextField from "../../../components/commons/TextField";
import { SuccessModal } from "../../../components/commons/Modals";
import { withStyles } from "@material-ui/core/styles";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import { Button, Loader, PageTitle } from "../../../components/commons";
import enums from "../../../utils/enums";
import { Check } from "../../../components/commons/icons";
import {
  getCustomerProfile,
  saveCustomerProfile,
  updateProfileForm,
  saveAccountDescription,
  updateAccountDescription,
  showAccountDescriptionModal,
} from "../Accounts/actions";
import { selectAccounts } from "../Accounts/selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

import {
  changeUserPassword,
  changeUserPhrase,
  openChangePasswordModal,
  closeChangePasswordModal,
  closeSuccessModal,
  openPhraseModal,
  closePhraseModal,
} from "../user/actions";

import styles from "../Accounts/styles.module.css";

const ProfileForm = styled.form`
  height: 100%;
  .containerForm {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
  .account {
    display: flex;
    margin-top: 10px;
    align-items: center;

    @media (max-width: 767px) {
      flex-flow: column;
    }

    .infoAccount {
      display: flex;
      flex: 1;
      box-sizing: border-box;
      border: 1px solid #ebeff3;
      border-radius: 10px;
      background-color: #fcfcfc;
      padding: 10px;

      .titleAccountId {
        display: block;
        color: #acabb2;
        font-family: "Kumbh Sans";
        font-size: 12px;
      }
      .accountId {
        display: block;
        color: #000000;
        font-family: "Kumbh Sans";
        font-size: 14px;
      }
      .titledescription {
        display: block;
        color: #acabb2;
        font-family: "Kumbh Sans";
        font-size: 12px;
      }
      .description {
        display: block;
        color: #000000;
        font-family: "Kumbh Sans";
        font-size: 14px;
      }
    }
  }
`;

const BankAccounts = ({
  getCustomerProfile,
  accounts,
  saveAccountDescription,
  isFetching,
  updateAccountDescription,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [state, setState] = useState({
    newPassword: "",
    confirmPassword: "",
    newPhrase: "",
    confirmPhrase: "",
    modalChallengeQuestionSuccessIsOpen: false,
    inputError: null,
    inputsWithError: [],
  });

  useEffect(() => {
    getCustomerProfile();
  }, [getCustomerProfile]);

  const handleSaveAccountDescription = e => {
    e.preventDefault();
    setState({ inputsWithError: [], inputError: null });

    const { shares, loans } = accounts;
    // get the inputs that were updated
    const sharesToUpdate =
      shares &&
      _.map(
        shares,
        ({ uniqueId, description, updated }) =>
          updated && {
            uniqueId,
            description,
            accountType: enums.AccountType.SHARE,
          },
      );
    const loansToUpdate =
      loans &&
      _.map(
        loans,
        ({ uniqueId, description, updated }) =>
          updated && {
            uniqueId,
            description,
            accountType: enums.AccountType.LOAN,
          },
      );

    // concat shares and loans accounts
    let accountsToUpdate = sharesToUpdate.concat(loansToUpdate);
    accountsToUpdate =
      accountsToUpdate && _.filter(accountsToUpdate, acc => !undefined && acc);

    setIsOpenModal(true);
    // else save accounts description
    const promises =
      accountsToUpdate &&
      _.map(accountsToUpdate, account => saveAccountDescription(account));
    return Promise.all(promises);
  };

  const renderSuccessModal = () => {
    return (
      <SuccessModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        headingText="Bank Account updated succesfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  };

  const { shares, loans } = accounts;
  return (
    <DashboardScreens>
      <Loader isLoading={isFetching} />
      {renderSuccessModal()}
      <ProfileForm>
        <Desktop>
          <div className="containerForm" style={{ maxWidth: "700px" }}>
            <PageTitle>Bank Accounts</PageTitle>
            {shares &&
              _.map(
                shares,
                ({ id, uniqueId, description, defaultDescription, isTrusted }, index) =>
                  !isTrusted && (
                    <div key={id + uniqueId + index} className="account">
                      <div className="infoAccount">
                        <div style={{ flex: 1 }}>
                          <span className="titleAccountId">Account ID</span>
                          <span className="accountId">{`S-${id}`}</span>
                        </div>
                        <div style={{ flex: 1 }}>
                          <span className="titledescription">Description</span>
                          <span className="description">{defaultDescription}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <TextField
                          onChange={e =>
                            updateAccountDescription(
                              uniqueId,
                              e.target.name,
                              e.target.value,
                            )
                          }
                          value={description}
                          type="text"
                          name="description"
                          id="description"
                          error={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          helperText={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          label={"My Description"}
                        />
                      </div>
                    </div>
                  ),
              )}
            {loans &&
              _.map(
                loans,
                ({ id, uniqueId, description, defaultDescription, isTrusted }, i) =>
                  !isTrusted && (
                    <div key={id + uniqueId + i} className="account">
                      <div className="infoAccount">
                        <div style={{ flex: 1 }}>
                          <span className="titleAccountId">Account ID</span>
                          <span className="accountId">{`S-${id}`}</span>
                        </div>
                        <div style={{ flex: 1 }}>
                          <span className="titledescription">Description</span>
                          <span className="description">{defaultDescription}</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <TextField
                          value={description}
                          onChange={e =>
                            updateAccountDescription(
                              uniqueId,
                              e.target.name,
                              e.target.value,
                            )
                          }
                          type="text"
                          name="description"
                          id="description"
                          error={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          helperText={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          label={"My Description"}
                        />
                      </div>
                    </div>
                  ),
              )}
            <br />
            {(shares || loans) && (
              <Button
                style={{
                  maxWidth: 160,
                  marginTop: "auto",
                  marginLeft: "auto",
                }}
                primary
                onClick={e => handleSaveAccountDescription(e)}
              >
                Save
              </Button>
            )}
          </div>
        </Desktop>

        <Mobile>
          <div className="containerForm">
            <h1 className="title">Bank Accounts</h1>
            {shares &&
              _.map(
                shares,
                ({ id, uniqueId, description, defaultDescription, isTrusted }, index) =>
                  !isTrusted && (
                    <div key={id + uniqueId + index} className="account">
                      <div className="infoAccount">
                        <div style={{ flex: 1 }}>
                          <span className="titleAccountId">Account ID</span>
                          <span className="accountId">{`S-${id}`}</span>
                        </div>
                        <div style={{ flex: 1 }}>
                          <span className="titledescription">Description</span>
                          <span className="description">{defaultDescription}</span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <TextField
                          onChange={e =>
                            updateAccountDescription(
                              uniqueId,
                              e.target.name,
                              e.target.value,
                            )
                          }
                          value={description}
                          type="text"
                          name="description"
                          id="description"
                          error={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          helperText={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          label={"My Description"}
                        />
                      </div>
                    </div>
                  ),
              )}
            {loans &&
              _.map(
                loans,
                ({ id, uniqueId, description, defaultDescription, isTrusted }, i) =>
                  !isTrusted && (
                    <div key={id + uniqueId + i} className="account">
                      <div className="infoAccount">
                        <div style={{ flex: 1 }}>
                          <span className="titleAccountId">Account ID</span>
                          <span className="accountId">{`S-${id}`}</span>
                        </div>
                        <div style={{ flex: 1 }}>
                          <span className="titledescription">Description</span>
                          <span className="description">{defaultDescription}</span>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <TextField
                          value={description}
                          onChange={e =>
                            updateAccountDescription(
                              uniqueId,
                              e.target.name,
                              e.target.value,
                            )
                          }
                          type="text"
                          name="description"
                          id="description"
                          error={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          helperText={
                            state.inputsWithError &&
                            state.inputsWithError.length > 0 &&
                            _.includes(state.inputsWithError, uniqueId) &&
                            state.inputError
                          }
                          label={"My Description"}
                        />
                      </div>
                    </div>
                  ),
              )}
            <br />
            {(shares || loans) && (
              <Button
                style={{
                  maxWidth: 160,
                  marginTop: "auto",
                  marginLeft: "auto",
                }}
                primary
                onClick={e => handleSaveAccountDescription(e)}
              >
                Save
              </Button>
            )}
          </div>
        </Mobile>
      </ProfileForm>
    </DashboardScreens>
  );
};
BankAccounts.prototype = {
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  getCustomerProfile: PropTypes.func,
  saveCustomerProfile: PropTypes.func,
  accounts: PropTypes.object,
  saveAccountDescription: PropTypes.func,
  showAccountDescriptionModal: PropTypes.func,
  updateProfileForm: PropTypes.func,
  updateAccountDescription: PropTypes.func,
  updateChallengeQuestionsValues: PropTypes.func,
  getChallengeQuestions: PropTypes.func,
  saveChallengeQuestions: PropTypes.func,
  customerProfile: PropTypes.object,
  changeUserPassword: PropTypes.func,
  changeUserPhrase: PropTypes.func,
  changePasswordModal: PropTypes.bool,
  isAccountDescriptionModalOpen: PropTypes.bool,
  successModal: PropTypes.bool,
  phraseModal: PropTypes.bool,
  openChangePasswordModal: PropTypes.func,
  closeChangePasswordModal: PropTypes.func,
  closeSuccessModal: PropTypes.func,
  openPhraseModal: PropTypes.func,
  closePhraseModal: PropTypes.func,
  challengeQuestions: PropTypes.array,
  classes: PropTypes.object,
};
const ChallengeQuestionRow = ({ secret, answer, onChange, index }) => (
  <div className={styles.profileInformationToggleContent}>
    <TextField
      onChange={onChange}
      value={secret}
      type="text"
      name="secret"
      id={secret}
      // error={state.formError || state.amountError}
      // helperText={state.formError || state.amountError}
      label={`Question #${index + 1}`}
    />
    <TextField
      onChange={onChange}
      value={answer}
      type="text"
      name="answer"
      id={answer}
      // error={state.formError || state.amountError}
      // helperText={state.formError || state.amountError}
      label={`Answer Question #${index + 1}`}
    />
    <br />
  </div>
);
ChallengeQuestionRow.propTypes = {
  secret: PropTypes.string,
  answer: PropTypes.string,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  isFetching: state.account.isFetching,
  accounts: selectAccounts(state),
  customerProfile: state.account.customerProfile,
  challengeQuestions: state.user.challengeQuestions,
  changePasswordModal: state.user.changePasswordModal,
  successModal: state.user.successModal,
  phraseModal: state.user.phraseModal,
  isAccountDescriptionModalOpen: state.account.isAccountDescriptionModalOpen,
  error: state.account.error,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCustomerProfile,
    updateAccountDescription,
    saveCustomerProfile,
    saveAccountDescription,
    updateProfileForm,
    changeUserPassword,
    changeUserPhrase,
    openChangePasswordModal,
    closeChangePasswordModal,
    closeSuccessModal,
    openPhraseModal,
    closePhraseModal,
    showAccountDescriptionModal,
  })(BankAccounts),
);
