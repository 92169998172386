export const setActiveIconColorSubmenu = (currentLocation, iconPath) => {
  const activeStyleColor = "#141F2D";
  const locationBasePath = currentLocation.split("/")[2];
  const iconBasePath = iconPath.split("/")[2];

  if (currentLocation === iconPath) {
    return activeStyleColor;
  } else if (locationBasePath === iconBasePath) {
    return activeStyleColor;
  }
  return "#4E5D71";
};

export const setActiveItemStyleSubMenu = (currentLocation, iconPath, mobile = false) => {
  let activeStyleColor = {
    color: "#141F2D",
    backgroundColor: "rgba(255,255,255,0.5)",
  };
  const locationBasePath = currentLocation.split("/")[2];
  const iconBasePath = iconPath.split("/")[2];

  if (mobile) {
    activeStyleColor = {
      ...activeStyleColor,
      borderBottomColor: "#4CD893",
      borderBottomWidth: "3px",
      borderBottomStyle: "solid",
    };
  } else {
    activeStyleColor = {
      ...activeStyleColor,
      borderRightColor: "#4CD893",
      borderRightWidth: "3px",
      borderRightStyle: "solid",
    };
  }

  if (currentLocation === iconPath) {
    return activeStyleColor;
  } else if (locationBasePath === iconBasePath) {
    return activeStyleColor;
  }
};
