import styled from "styled-components";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ on }) => (on ? "228px" : "100%")};
  align-items: center;
  height: 100%;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  background: #f8f9fa;
  background-color: #141f2d;
  box-shadow: 0 0 10px 0 rgba(91, 102, 121, 0.05);
`;

const MenuItem = styled.div`
  display: flex;
  padding: 0;
  box-sizing: border-box;
  justify-content: ${({ on }) => (on ? "start" : "center")};
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: ${({ on }) => (on ? "0.7rem 1.5rem" : "1rem")};

  :hover svg {
    stroke: #05ce86 !important;
    color: #05ce86 !important;
    transition: all 0.3s ease;
  }

  :hover span {
    color: #05ce86;
    transition: all 0.3s ease;
  }
`;

const A4Sidenav = styled.div`
  height: 100vh;
`;

const A4SubNav = styled.div`
  height: 100vh;
`;

const A4SubNavMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 253px;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  background-color: #edf2f4;
  box-shadow: 0 0 10px 0 rgba(91, 102, 121, 0.05);
`;

const A4SubNavMenuItem = styled.div`
  display: flex;
  padding: 0;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 1rem 36px;
  height: 42px;
`;

const A4SubNavMenuMobil = styled.div`
  display: inline-flex;
  align-items: center;
  width: 95%;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeff3;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  overflow-x: auto;
`;

const A4SubNavMenuItemMobil = styled.div`
  display: flex;
  padding: 0;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 42px;

  a {
    padding-left: 8px;
  }
`;

export {
  A4Sidenav,
  Menu,
  MenuItem,
  A4SubNav,
  A4SubNavMenu,
  A4SubNavMenuItem,
  A4SubNavMenuMobil,
  A4SubNavMenuItemMobil,
};
