import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ConfirmationModal } from "../../../components/commons/Modals";
import { withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import {
  Button,
  Loader,
  PageTitle,
  P,
  Box,
  Separator,
} from "../../../components/commons";
import {
  MobileTable,
  MobileTableButton,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import { getCurrentTime, getViewUsageFromDate } from "../../../utils/helper";
import TextField from "../../../components/commons/TextField";
import _ from "lodash";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import moment from "moment";
import * as actions from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

import componentStyles from "./styles.module.css";

const Container = styled.div`
  flex: 1;
`;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#7A7A7A",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    padding: "1rem",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#7A7A7A",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

class TextBankingUsage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    phoneUsage: PropTypes.array,
    isFetching: PropTypes.bool,
    match: PropTypes.object,
    getTextBankingUsage: PropTypes.func,
  };
  state = {
    modalViewSmsIsOpen: false,
    modalViewResponseIsOpen: false,
    selectedSms: {},
    startDate: getViewUsageFromDate(),
    endDate: getCurrentTime(),
  };

  componentDidMount() {
    const { phoneNumber } = this.props.match.params;
    const fromDate = moment(this.state.startDate).utc().format("MM/DD/YYYY");
    const toDate = moment(this.state.endDate).utc().format("MM/DD/YYYY");
    return this.props.getTextBankingUsage(phoneNumber, fromDate, toDate) || true;
  }

  openViewSmsModal = () => {
    this.setState({ modalViewSmsIsOpen: true });
  };

  openViewResponseModal = () => {
    this.setState({ modalViewResponseIsOpen: true });
  };

  // afterOpenModal = () => {
  //   // references are now sync'd and can be accessed.
  //   this.subtitle.style.color = '#f00';
  // };

  closeViewSmsModal = () => {
    this.setState({ modalViewSmsIsOpen: false });
  };

  closeViewResponseModal = () => {
    this.setState({ modalViewResponseIsOpen: false });
  };

  handleChangeStartDate = e => {
    this.setState({
      startDate: e.target.value,
    });
  };

  handleChangeEndDate = e => {
    this.setState({
      endDate: e.target.value,
    });
  };

  handleSearchButtonClick = () => {
    const { phoneNumber } = this.props.match.params;
    this.props.getTextBankingUsage(
      phoneNumber,
      moment(this.state.startDate).utc().format("MM/DD/YYYY"),
      moment(this.state.endDate).utc().format("MM/DD/YYYY"),
    );
  };

  handleViewSmsModal = sms =>
    this.setState({ modalViewSmsIsOpen: true, selectedSms: sms });

  handleViewResponseModal = sms =>
    this.setState({ modalViewResponseIsOpen: true, selectedSms: sms });

  render() {
    const { classes } = this.props;
    const { isFetching } = this.props;
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={isFetching} />
          <ConfirmationModal
            isOpen={this.state.modalViewSmsIsOpen}
            onClose={this.closeViewSmsModal}
            onSuccess={null}
            headingText="SMS Sent"
            confirmationText={
              <div>
                <P>
                  The following text message on the phone named{" "}
                  <b>{this.state.selectedSms.phoneNickname}</b> was found on{" "}
                  <b>
                    {moment(this.state.selectedSms.smsDate)
                      .utc()
                      .format("MM/DD/YYYY hh:mm a")}
                  </b>
                </P>
                <P>
                  <span style={{ color: "#05CE86" }}>
                    <strong>Command: </strong>
                  </span>
                  {this.state.selectedSms.textMessage}
                </P>
                <P>
                  <span style={{ color: "#05CE86" }}>
                    <strong>Response: </strong>
                  </span>
                </P>
                <pre style={{ maxHeight: 250, borderRadius: 10, padding: 8 }}>
                  {this.state.selectedSms.responseData || "-"}
                </pre>
              </div>
            }
            aria-hidden={this.state.modalViewSmsIsOpen}
            aria={{
              labelledby: "heading",
              describedby: "full_description",
            }}
          />
          <PageTitle>Text Banking History</PageTitle>
          <P>
            Your usage information is listed below. You can view the text message sent to
            your wireless phone by clicking the View link.
          </P>
          <Separator style={{ margin: "0 0 1.5rem 0" }} />
          <Box className={componentStyles.phoneUsageSearchFilters}>
            <TextField
              id="startDate"
              label="From Date"
              type="date"
              value={this.state.startDate}
              onChange={this.handleChangeStartDate}
            />
            <TextField
              id="endDate"
              label="To Date"
              type="date"
              value={this.state.endDate}
              onChange={this.handleChangeEndDate}
            />
          </Box>
          <Button primary onClick={this.handleSearchButtonClick}>
            Search
          </Button>
          <Separator style={{ margin: "1.5rem 0 3rem 0" }} />
          <Mobile>
            <MobileTable title={"Phone Usage"}>
              {this.props.phoneUsage ? (
                _.map(this.props.phoneUsage, (sms, index) => (
                  <MobileTableRow key={sms.id + index}>
                    <Box column fx={1}>
                      <Box fx={1}>
                        <div style={{ flex: 1 }}>
                          <MobileTableHeading value={sms.phoneNickname} />
                          <MobileTableCell name={"Phone number"} value={sms.from} />
                          <MobileTableCell
                            name={"Nickname"}
                            value={sms.phoneNickname || "-"}
                          />
                          <MobileTableCell
                            name={"Command"}
                            value={sms.command || "Error"}
                          />
                          <MobileTableCell
                            name={"Date"}
                            value={moment(sms.smsDate).utc().format("MM/DD/YYYY hh:mm a")}
                          />
                        </div>
                      </Box>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "0.6rem",
                        }}
                      >
                        <MobileTableButton onClick={() => this.handleViewSmsModal(sms)}>
                          <p style={{ color: "#05CE86", fontWeight: "bold" }}>
                            Text Message
                          </p>
                        </MobileTableButton>
                      </div>
                    </Box>
                  </MobileTableRow>
                ))
              ) : (
                <MobileTableRow>
                  <P>{"You don't have text messages history"}</P>
                </MobileTableRow>
              )}
            </MobileTable>
          </Mobile>
          <Desktop>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", flex: 1 }}>
                <div style={{ overflowX: "auto" }}>
                  <Table className={classes.table}>
                    <TableHead className={classes.thead}>
                      <CustomTableCell>Phone number</CustomTableCell>
                      <CustomTableCell>Nickname</CustomTableCell>
                      <CustomTableCell>Text message</CustomTableCell>
                      <CustomTableCell>Command</CustomTableCell>
                      <CustomTableCell>Transaction date</CustomTableCell>
                    </TableHead>
                    <TableBody>
                      {this.props.phoneUsage && this.props.phoneUsage.length > 0 ? (
                        _.map(this.props.phoneUsage, (sms, index) => (
                          <TableRow key={sms.id + index}>
                            <CustomTableCell>{sms.from}</CustomTableCell>
                            <CustomTableCell>
                              {sms.wasSuccessful ? sms.phoneNickname : "-"}
                            </CustomTableCell>
                            <CustomTableCell>
                              <Button
                                md
                                primary
                                onClick={() => this.handleViewSmsModal(sms)}
                              >
                                View
                              </Button>
                            </CustomTableCell>
                            <CustomTableCell>
                              {sms.wasSuccessful ? sms.command : "Error"}
                            </CustomTableCell>
                            <CustomTableCell>
                              {moment(sms.smsDate).utc().format("MM/DD/YYYY hh:mm a")}
                            </CustomTableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <CustomTableCell>
                            {"You Don't Have Text Messages History"}
                          </CustomTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div style={{ display: "flex", flex: 1 }}></div>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.textBanking.isFetching,
  phoneUsage: state.textBanking.phoneUsage,
});

export default withStyles(styles)(
  connect(mapStateToProps, { ...actions, push })(TextBankingUsage),
);
