import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import "./global-styles.css";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";

// Import all the third party stuff
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import createHistory from "history/createBrowserHistory";

import configureStore from "./configureStore";

import { AUTH_USER } from "./containers/Auth/types";

// Create redux store with history.
const initialState = {};
const history = createHistory();
const { persistor, store } = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("root");

const token = localStorage.getItem("token");
if (token) {
  store.dispatch({ type: AUTH_USER });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  MOUNT_NODE,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
