import React from "react";
import styled from "styled-components";

const ContainerInputNormal = styled.div`
  box-sizing: border-box;
  height: 41px;
  border: 1px solid #ebeff3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 12px;

  input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-family: "Kumbh Sans";
    font-size: 15px;
    height: 100%;
    width: 100%;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #899fbc;
      font-family: "Kumbh Sans";
      font-size: 15px;
    }

    &:focus {
      outline-offset: 0px;
      outline: none;
    }
  }
`;

const InputNormal = ({ icon, classNameContainer, ...rest }) => {
  return (
    <ContainerInputNormal className={classNameContainer}>
      <input {...rest} />
      {icon}
    </ContainerInputNormal>
  );
};

export default InputNormal;
