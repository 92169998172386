import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../components/commons";
import TextField from "../../../components/commons/TextField";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, CircularProgress } from "@material-ui/core";
import { Unlock } from "../../../components/commons/icons";
import {
  StepBox,
  StepBody,
  StepTitle,
  StepControls,
  StepForm,
  CurrentStep,
  StepLogo,
  StepText,
} from "../steps/Steps";

class ChangeYourPassoword extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    stepError: PropTypes.string,
    isFetching: PropTypes.bool,
    classes: PropTypes.object,
  };
  state = {
    newPassword: "",
    confirmNewPassword: "",
    error: null,
  };

  handleSubmit = () => {
    const { newPassword, confirmNewPassword } = this.state;
    const isNewPasswordValid = newPassword !== null && newPassword !== "";
    const isConfirmNewPasswordValid =
      confirmNewPassword !== null && confirmNewPassword !== "";

    if (isNewPasswordValid && isConfirmNewPasswordValid) {
      if (newPassword === confirmNewPassword) {
        this.props.onSubmit(newPassword, confirmNewPassword);
        this.setState({ error: null });
      } else {
        this.setState({ error: "password don't match" });
      }
    } else {
      this.setState({ error: "please fill the form" });
    }
  };
  render() {
    const { config } = this.props;
    return (
      <StepBox>
        <StepLogo src={config && config.fiLogo} />
        <StepBody>
          <CurrentStep step={"Step 3 of 3"} />
          <StepTitle>Password reset successful!</StepTitle>
          <StepText>For security reasons please change your password.</StepText>
          <StepForm>
            <TextField
              onChange={event => this.setState({ newPassword: event.target.value })}
              value={this.state.answer}
              onKeyPress={this.handleKeyPress}
              type="text"
              name="newPassword"
              id="newPassword"
              error={this.props.stepError || this.state.error}
              helperText={this.props.stepError || this.state.error}
              label={"New Password"}
              startAdornment={
                <InputAdornment position="start">
                  <Unlock />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {this.props.isFetching && (
                    <CircularProgress
                      className={this.props.classes.progress}
                      style={{ color: "#05CE86", width: 30, height: 30 }}
                    />
                  )}
                </InputAdornment>
              }
            />
            <TextField
              onChange={event =>
                this.setState({ confirmNewPassword: event.target.value })
              }
              value={this.state.confirmNewPassword}
              onKeyPress={this.handleKeyPress}
              type="text"
              name="confirmPassword"
              id="confirmPassword"
              error={this.props.stepError || this.state.error}
              helperText={this.props.stepError || this.state.error}
              label={"Confirm Password"}
              startAdornment={
                <InputAdornment position="start">
                  <Unlock />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {this.props.isFetching && (
                    <CircularProgress
                      className={this.props.classes.progress}
                      style={{ color: "#05CE86", width: 30, height: 30 }}
                    />
                  )}
                </InputAdornment>
              }
            />
          </StepForm>
          <StepControls>
            <Button
              disabled={this.props.isFetching}
              primary={!this.props.isFetching}
              onClick={this.handleSubmit}
            >
              Login
            </Button>
          </StepControls>
        </StepBody>
      </StepBox>
    );
  }
}
const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});
export default withStyles(loaderStyles)(ChangeYourPassoword);
