import React from "react";
import { connect } from "react-redux";

import SimpleAnalytics from "../../components/containers/Admin/SimpleAnalytics";

function SimpleAnalyticsOlb({ simpleAnalyticsUrl }) {
  return <SimpleAnalytics simpleAnalyticsUrl={simpleAnalyticsUrl} isOlb />;
}

const mapStateToProps = state => ({
  simpleAnalyticsUrl: state.config.config.simpleAnalyticsUrlOlb,
});

export default connect(mapStateToProps, {})(SimpleAnalyticsOlb);
