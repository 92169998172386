import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import PanelDropdown from "./PanelDropdown";
import FeatherIcon from "feather-icons-react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { ReactSVG } from "react-svg";
import { checkLocation } from "../../utils/helper";
import FilterTags from "./FilterTags";
import Search from "./Search";

const ControlPanelContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-right: 10px;
  border-right: 1px solid #e6ecf0;
  height: 25px;
  align-items: center;
  margin-top: 2px;
  margin-right: 10px;
  & > span {
    font-family: "Kumbh Sans";
    font-size: 14px;
    font-weight: normal;
    color: #a5a7a9;
    padding-left: 10px;
    width: max-content;
  }
`;

const ControlPanelTitle = styled.span`
  margin-top: 23px;
  font-family: "Kumbh Sans";
  font-size: 26px;
  font-weight: bold;
  color: #1f2427;
  margin-bottom: 23px;
`;

const ControlPanelMenuTabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 60px;
`;
const ControlPanelTabs = styled.div`
  display: flex;
`;
const ControlPanelSubmenuPrincipal = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  border-top: 1px solid #ebeff3;
  padding: 0.4rem 0;
`;

const Tab = styled.a`
  display: flex;
  min-width: 80px;
  justify-content: center;
  align-items: center;
  border-bottom: ${({ active }) => (active ? "3px solid #3fc78e" : "3px solid #fff")};
  padding-left: 20px;
  padding-right: 20px;
  &:hover {
    border-bottom: 3px solid #000;
  }
`;

const IconTab = styled.div`
  margin-right: 8px;
`;

const TitleTab = styled.span`
  font-family: "Kumbh Sans";
  font-size: 14px;
  font-weight: normal;
  color: #1f2427;
`;

const ActionButton = styled.button`
  background-color: #3fc78e;
  width: 25px;
  height: 25px;
  box-shadow: 0 6px 13px 0 rgba(63, 199, 142, 0.16);
  border-radius: 50%;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: #3fc78e;
    outline: none;
    box-shadow: 0 6px 13px 0 rgba(63, 199, 142, 0.48);
  }
`;

function ControlPanel({
  title,
  tabs,
  filterItems,
  actionButton,
  ComponentNotSearch,
  push,
  onChangeSearch,
  valueSearch,
  onKeyPressSearch,
  endAdornmentSearch,
  formStyleSearch,
  activeFilters,
  setActiveFilters,
  loading,
  deleteFilter,
  handleOpen,
  doClickTab,
  tabIndex,
}) {
  const onSelectFilter = value => {
    try {
      if (activeFilters.some(item => item.title === value.title)) {
        setActiveFilters([...activeFilters.filter(item => item.id !== value.id)]);
      } else {
        activeFilters.push(value);
        setActiveFilters([...activeFilters]);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const notFilters = setShowDropdown => {
    if (activeFilters && activeFilters.length > 0) {
      setActiveFilters([]);
    } else {
      setShowDropdown(false);
    }
  };

  const isClickedItem = id => {
    if (activeFilters.find(item => item.id === id)) {
      return true;
    }
    return false;
  };

  return (
    <ControlPanelContainer>
      <MediaQuery query="(min-width: 768px)">
        <ControlPanelMenuTabs>
          <ControlPanelTitle>{title}</ControlPanelTitle>

          <ControlPanelTabs>
            {_.map(tabs, ({ displayName, path, iconName }, key) => {
              const withController = tabIndex !== undefined && tabIndex !== null;
              const active = tabIndex === key;
              const defaultActive = checkLocation(window.location.pathname, path)
                ? true
                : false;
              const isActive = !!withController ? active : defaultActive;

              const onClick = () => {
                if (!!doClickTab) {
                  doClickTab({ displayName, path, iconName }, key);
                } else push(path);
              };

              const Icon = () => {
                switch (iconName) {
                  case "roles":
                    return (
                      <ReactSVG
                        src={`../../../../icons/roles.svg`}
                        evalScripts="always"
                        beforeInjection={svg => {
                          svg.setAttribute(
                            "style",
                            `color:${isActive ? "#3fc78e" : "#1f2427"};`,
                          );
                        }}
                      />
                    );

                  case "marketing-website":
                    return (
                      <ReactSVG
                        src={`../../../../icons/marketing-website.svg`}
                        evalScripts="always"
                        beforeInjection={svg => {
                          svg.setAttribute(
                            "style",
                            `color:${isActive ? "#3fc78e" : "#1f2427"};`,
                          );
                        }}
                      />
                    );

                  case "online-banking":
                    return (
                      <ReactSVG
                        src={`../../../../icons/online-banking.svg`}
                        evalScripts="always"
                        beforeInjection={svg => {
                          svg.setAttribute(
                            "style",
                            `color:${isActive ? "#3fc78e" : "#1f2427"};`,
                          );
                        }}
                      />
                    );

                  default:
                    return (
                      <FeatherIcon
                        icon={iconName}
                        size="17"
                        stroke={isActive ? "#3fc78e" : "#1f2427"}
                        color={isActive ? "#3fc78e" : "#1f2427"}
                      />
                    );
                }
              };

              return (
                <Tab key={key} active={isActive} onClick={onClick}>
                  <IconTab>
                    <Icon />
                  </IconTab>

                  <TitleTab>{displayName}</TitleTab>
                </Tab>
              );
            })}
          </ControlPanelTabs>
        </ControlPanelMenuTabs>

        <ControlPanelSubmenuPrincipal>
          {ComponentNotSearch ? (
            ComponentNotSearch
          ) : (
            <>
              {actionButton !== undefined && (
                <ButtonContainer>
                  <ActionButton onClick={handleOpen}>
                    <FeatherIcon icon="plus" size="21" stroke="white" />
                  </ActionButton>
                  <span>{actionButton}</span>
                </ButtonContainer>
              )}
              <Search
                value={valueSearch}
                onChange={onChangeSearch}
                onKeyPress={onKeyPressSearch}
                endAdornment={endAdornmentSearch}
                formStyleSearch={formStyleSearch}
              />
              {loading ? (
                <CircularProgress
                  style={{ color: "#05CE86", width: "20px", height: "25px" }}
                />
              ) : (
                <FilterTags tags={activeFilters} deleteFilter={deleteFilter} />
              )}

              <PanelDropdown
                onSelectFilter={onSelectFilter}
                filterItems={filterItems}
                notFilters={notFilters}
                loading={loading}
                isClickedItem={isClickedItem}
              />
            </>
          )}
        </ControlPanelSubmenuPrincipal>
      </MediaQuery>
    </ControlPanelContainer>
  );
}

ControlPanel.defaultProps = {
  tabs: [],
};

ControlPanel.prototype = {
  title: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  filterItems: PropTypes.array,
  actionButton: PropTypes.string,
  ComponentNotSearch: PropTypes.element,
  push: PropTypes.func,
  loading: PropTypes.bool,
  onChangeSearch: PropTypes.func,
  valueSearch: PropTypes.string,
  onKeyPressSearch: PropTypes.func,
  endAdornmentSearch: PropTypes.element,
  formStyleSearch: PropTypes.object,
  activeFilters: PropTypes.array,
  setActiveFilters: PropTypes.func,
  deleteFilter: PropTypes.func,
  doClickTab: PropTypes.func,
  tabIndex: PropTypes.number,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { push })(ControlPanel);
