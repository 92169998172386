import _ from "lodash";
import moment from "moment";
import * as enums from "../../../utils/enums";

export const selectPayeeInformation = (state, uniqueId) =>
  _.find(state.epayment.payees, p => p.uniqueId === uniqueId);

export const selectPayeesOptions = state =>
  state.epayment.payees &&
  _.map(state.epayment.payees, p => ({
    key: p.id,
    value: p.name,
    lastPayDate: p.lastPayDate,
  }));

export const selectAutopayList = ({ epayment, account }) => {
  const { autopayList } = epayment;
  if (!_.isEmpty(autopayList)) {
    return _.map(autopayList, item => {
      let frequencyName;
      let payFromDescription;
      switch (item.frequency) {
        case enums.AutoPayFrequency.WEEKLY:
          frequencyName = "Weekly";
          break;
        case enums.AutoPayFrequency.BI_WEEKLY:
          frequencyName = "Bi weekly";
          break;
        case enums.AutoPayFrequency.QUAD_WEEKLY:
          frequencyName = "Quad weekly";
          break;
        case enums.AutoPayFrequency.MONTHLY:
          frequencyName = "Monthly";
          break;
        case enums.AutoPayFrequency.SEMI_MONTHLY:
          frequencyName = "Semi monthly";
          break;
        case enums.AutoPayFrequency.BI_MONTHLY:
          frequencyName = "Bi monthly";
          break;
        case enums.AutoPayFrequency.QUATERLY:
          frequencyName = "Quaterly";
          break;
        case enums.AutoPayFrequency.SEMI_ANNUAL:
          frequencyName = "Semi annual";
          break;
        case enums.AutoPayFrequency.YEARLY:
          frequencyName = "Yearly";
          break;
        default:
          frequencyName = "Unknown";
          break;
      }
      switch (item.fromAccountType) {
        case 1: // shares
          const shareObj =
            account.shares && _.find(account.shares, o => o.id === item.fromAccountId);
          payFromDescription = `S-${item.fromAccountId} ${shareObj.description}`;

          break;
        case 2: // loans
          const loanObj =
            account.loans && _.find(account.loans, o => o.id === item.fromAccountId);
          payFromDescription = `L-${item.fromAccountId} ${loanObj.description}`;

          break;
        case 3: // credit cards
          // get credit card name
          const cardsObj =
            account.creditCards &&
            _.find(account.creditCards, o => o.id === item.fromAccountId);
          payFromDescription = `C-${item.fromAccountId} ${cardsObj.description}`;

          break;

        default:
          payFromDescription = "";
          break;
      }
      return {
        ...item,
        regularAmount: item.regularAmount || 0,
        finalAmount: item.finalAmount || 0,
        lastDate: moment.utc(item.lastDate).format("L"),
        nextDate: moment.utc(item.nextDate).format("L"),
        startDate: moment.utc(item.startDate).format("L"),
        remaining: item.remaining < 0 ? "Continuous" : item.remaining,
        frequencyName,
        payFromDescription,
      };
    });
  }
  return epayment.autopayList;
};

export const selectDefaultPayees = ({ epayment: { defaultPayees } }) => {
  let filteredPayees;
  if (defaultPayees) {
    filteredPayees = _.filter(defaultPayees, payee => payee.isGlobal === true);
  }

  const globalPayeeOptions =
    filteredPayees &&
    _.map(filteredPayees, payee => {
      const value = `${payee.name} / ${payee.address1}, ${payee.state}`;
      return {
        key: payee.uniqueId,
        value,
      };
    });
  return { filteredPayees, globalPayeeOptions };
};

export const selectSharesOptions = ({ account: { shares } }) =>
  shares &&
  _.map(shares, ({ id, description }) => ({
    key: id,
    value: `S-${id} ${description}`,
  }));

export const selectQuickpays = ({ epayment: { quickPayRows } }) =>
  quickPayRows && _.map(quickPayRows, row => row);

export const selectScheduledPayments = scheduledPayments =>
  scheduledPayments &&
  _.map(scheduledPayments, item => ({
    ...item,
    payFrom:
      item.fromAccountType === enums.AccountType.SHARE
        ? `S-${item.fromAccountId}`
        : `L-${item.fromAccountId}`,
  }));
