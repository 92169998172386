import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { push } from 'react-router-redux';
import _ from "lodash";
import CurrencyFormat from "react-currency-format";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import {
  MobileTable,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import styled from "styled-components";
import {
  Separator,
  Loader,
  Box,
  P,
  B,
  PageTitle,
  Button,
} from "../../../components/commons";
import { ConfirmationModal } from "../../../components/commons/Modals";
import { withStyles } from "@material-ui/core/styles";
import { formatDate } from "../../../utils/helper";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { getScheduledPayments, getPaymentActivity, unschedulePayment } from "./actions";
import styles from "./styles.module.css";
import { selectScheduledPayments } from "./selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const Container = styled.div`
  flex: 1;
`;

const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#7A7A7A",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    padding: "0.5rem 1rem",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#7A7A7A",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

class SchedulePayments extends Component {
  static propTypes = {
    classes: PropTypes.object,
    // push: PropTypes.func,
    getScheduledPayments: PropTypes.func,
    isFetching: PropTypes.bool,
    scheduledPayments: PropTypes.array,
    unschedulePayment: PropTypes.func,
  };

  state = { modalIsOpen: false, selectedRowToDelete: {} };

  componentDidMount() {
    this.props.getScheduledPayments();
  }

  handleUnscheduleButton = async () => {
    await this.props.unschedulePayment(this.state.selectedRowToDelete.uniqueId);
    this.setState({ modalIsOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={this.props.isFetching} />
          <PageTitle>Scheduled Payments</PageTitle>
          <ConfirmationModal
            isOpen={this.state.modalIsOpen}
            onClose={() => this.setState({ modalIsOpen: false })}
            onSuccess={this.handleUnscheduleButton}
            headingText="Delete Scheduled Payment"
            confirmationText={
              <span>
                <P>Are you sure you want to Delete this Scheduled Payment?</P> <br />{" "}
                <br />
                <P>
                  <B>Payee:</B>&nbsp;{this.state.selectedRowToDelete.payeeName}
                </P>{" "}
                <br />
                <P>
                  <B>Amount:</B>&nbsp;
                  <CurrencyFormat
                    value={this.state.selectedRowToDelete.amount}
                    displayType={"text"}
                    thousandSeparator
                    prefix={"$"}
                  />
                </P>{" "}
                <br />
                <P>
                  <B>Scheduled Date:</B>&nbsp;
                  {formatDate(this.state.selectedRowToDelete.scheduledDate)}
                </P>{" "}
                <br /> <br />
              </span>
            }
            cancelButtonText="Close"
            successButtonText={"Confirm"}
            aria-hidden={this.state.modalIsOpen}
            aria={{
              labelledby: "heading",
              describedby: "full_description",
            }}
          />
          <Box>
            <Box fx={1}>
              <P>
                Your scheduled payments are list below. if you need to uncheduled a
                payment, click the unschedule link beside the payment Note: You cannot
                unschedule an auto pay from this screen. Please use the E-Pay Summary
                Screen to delete the auto pay.
              </P>
            </Box>
            <Box className={styles.desktopFx} />
          </Box>
          <Separator style={{ margin: "0 0 3rem 0" }} />
          <Mobile>
            <MobileTable title={"activity"}>
              {this.props.scheduledPayments && this.props.scheduledPayments.length > 0 ? (
                _.map(
                  this.props.scheduledPayments,
                  (
                    {
                      uniqueId,
                      payeeName,
                      payeeNickname,
                      scheduledDate,
                      amount,
                      payFrom,
                      payFromType,
                      comment,
                    },
                    index,
                  ) => (
                    <MobileTableRow key={index}>
                      <div>
                        <MobileTableHeading
                          value={
                            <div>
                              <div>{payeeName}</div>
                              <br />
                              <Button
                                style={{
                                  color: "rgb(253, 103, 115)",
                                  fontWeight: 500,
                                  padding: 0,
                                  fontSize: 16,
                                  textTransform: "capitalize",
                                  width: "auto",
                                }}
                                onClick={() =>
                                  this.setState({
                                    modalIsOpen: true,
                                    selectedRowToDelete: {
                                      uniqueId,
                                      payeeName,
                                      amount,
                                      scheduledDate,
                                    },
                                  })
                                }
                              >
                                Delete
                              </Button>
                            </div>
                          }
                        />
                        <MobileTableCell name={"Payee"} value={payeeName} />
                        <MobileTableCell name={"nickname"} value={payeeNickname} />
                        <MobileTableCell
                          name={"payment date"}
                          value={formatDate(scheduledDate)}
                        />
                        <MobileTableCell
                          name={"amount"}
                          value={
                            <CurrencyFormat
                              value={amount}
                              displayType={"text"}
                              thousandSeparator
                              prefix={"$"}
                            />
                          }
                        />
                        <MobileTableCell name={"pay from"} value={payFrom} />
                        <MobileTableCell name={"payment type"} value={payFromType} />
                        <MobileTableCell name={"comment"} value={comment} />
                      </div>
                    </MobileTableRow>
                  ),
                )
              ) : (
                <MobileTableRow>
                  <P>{"You don't have recent activity"}</P>
                </MobileTableRow>
              )}
            </MobileTable>
          </Mobile>
          <Desktop>
            <div style={{ overflowX: "auto" }}>
              <Table className={classes.table}>
                <TableHead className={classes.thead}>
                  <TableRow>
                    <CustomTableCell>Payee</CustomTableCell>
                    <CustomTableCell>Nickname</CustomTableCell>
                    <CustomTableCell>Payment date</CustomTableCell>
                    <CustomTableCell>Amount</CustomTableCell>
                    <CustomTableCell>Pay From</CustomTableCell>
                    <CustomTableCell>Payment Type</CustomTableCell>
                    <CustomTableCell>Comment</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.scheduledPayments &&
                  this.props.scheduledPayments.length > 0 ? (
                    _.map(
                      this.props.scheduledPayments,
                      (
                        {
                          uniqueId,
                          payeeName,
                          payeeNickname,
                          scheduledDate,
                          amount,
                          payFrom,
                          paymentOrigin,
                          comment,
                        },
                        key,
                      ) => (
                        <TableRow key={key}>
                          <CustomTableCell>
                            <p>{payeeName}</p>
                            {paymentOrigin && paymentOrigin !== "Auto Pay" && (
                              <Button
                                style={{
                                  color: "rgb(253, 103, 115)",
                                  fontWeight: 400,
                                  padding: 0,
                                  fontSize: 14,
                                }}
                                onClick={() =>
                                  this.setState({
                                    modalIsOpen: true,
                                    selectedRowToDelete: {
                                      uniqueId,
                                      payeeName,
                                      amount,
                                      scheduledDate,
                                    },
                                  })
                                }
                              >
                                Delete
                              </Button>
                            )}
                          </CustomTableCell>
                          <CustomTableCell>{payeeNickname}</CustomTableCell>
                          <CustomTableCell>{formatDate(scheduledDate)}</CustomTableCell>
                          <CustomTableCell>
                            <CurrencyFormat
                              value={amount}
                              displayType={"text"}
                              thousandSeparator
                              prefix={"$"}
                            />
                          </CustomTableCell>
                          <CustomTableCell>{payFrom}</CustomTableCell>
                          <CustomTableCell>{paymentOrigin}</CustomTableCell>
                          <CustomTableCell>{comment}</CustomTableCell>
                        </TableRow>
                      ),
                    )
                  ) : (
                    <TableRow>
                      <CustomTableCell>
                        {"you don't have payment activity"}
                      </CustomTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = state => ({
  isFetching: state.epayment.isFetching,
  error: state.epayment.error,
  scheduledPayments: selectScheduledPayments(state.epayment.scheduledPayments),
});

export default withStyles(tableStyles)(
  connect(mapStateToProps, {
    getScheduledPayments,
    getPaymentActivity,
    unschedulePayment,
  })(SchedulePayments),
);
