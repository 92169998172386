import styled from "styled-components";

const UserName = styled.span`
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #141f2d;
  font-family: "Kumbh Sans";
`;

export default UserName;
