import "date-fns";
import React, { useState } from "react";
import { Calendar as IconCalendar } from "../../../../components/commons/icons";
import styled from "styled-components";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import FeatherIcon from "feather-icons-react";
const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "1px solid #ccc",
    },
    "&&:after": {
      borderBottom: "1px solid #000",
    },
  },
  input: {
    color: "#000000",
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    letterSpacing: 0,
    padding: "6px 0px 0px 0px",
    width: "100%",
  },
});

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: "100%",
        minWidth: "200px",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#bdbcc3",
        fontFamily: "Kumbh Sans",
        "&$focused": {
          color: "#3EC78D",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3FC78E",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#3FC78E",
      },
    },
    PrivateNotchedOutline: {
      root: {
        border: "none",
      },
    },
    MuiIconButton: {
      root: {
        padding: "4px",
      },
    },
  },
});

const ContainerCalendar = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  .date {
    color: #000000;
    font-family: "Kumbh Sans";
    font-size: 17px;
    letter-spacing: 0;
    width: 100%;
  }
`;

const Calendar = ({ label }) => {
  const [selectedDate, handleDateChange] = useState(new Date());
  const classes = useStyles();
  return (
    <ContainerCalendar>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="standard"
            format="MM/dd/yyyy"
            value={selectedDate}
            InputAdornmentProps={{ position: "end" }}
            onChange={date => handleDateChange(date)}
            InputProps={{ classes }}
            label={label}
            keyboardIcon={
              <FeatherIcon
                icon="chevron-down"
                size="20"
                stroke="#000000"
                style={{ cursor: "pointer" }}
              />
            }
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ContainerCalendar>
  );
};

export default Calendar;
