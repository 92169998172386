import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import moment from "moment";
import styled from "styled-components";

import { Loader } from "../../../commons";
import ControlPanel from "../../../commons/controlPanel";
import { post } from "../../../../utils/server";
import { DashboardScreens } from "../../../../containers/Dashboard/DashboardInstance/Dashboard";

const tabs = [
  {
    id: 1,
    iconName: "marketing-website",
    path: "/admin/panel/simple-analytics",
    displayName: "Online Banking",
  },
  {
    id: 2,
    iconName: "online-banking",
    path: "/admin/panel/simple-analytics/marketing",
    displayName: "Marketing Website",
  },
];

function SimpleAnalytics({ simpleAnalyticsUrl, isOlb }) {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    async function fetchSimpleAnalyticsData() {
      try {
        const { data } = await post("/analytics/stats", { isOlb });
        if (data.success) {
          const parsedData = parseResponse(["browser_names", "countries"], data.data);

          setData(parsedData);
        }
      } catch (error) {
        console.log(error);
        alert("Data could not be loaded, try again later");
      } finally {
        setIsFetching(false);
      }
    }

    fetchSimpleAnalyticsData();
  }, []);

  return (
    <Layout>
      <Loader isLoading={isFetching} />
      <DashboardScreens>
        <ControlPanel
          title="Web Analytics"
          tabs={tabs}
          formStyleSearch={{ marginLeft: "0px" }}
          ComponentNotSearch
        />
        {data && data.visitors && (
          <TitleSection
            visitors={data.visitors}
            simpleAnalyticsUrl={simpleAnalyticsUrl}
          />
        )}
        {data && data.histogram && <Graph data={data.histogram} />}
        <VisitInformationSection>
          {data && data.referrers && (
            <VisitInformationBoxComponent
              title="Referrers"
              isLink={true}
              data={data.referrers}
              simpleAnalyticsUrl={simpleAnalyticsUrl}
            />
          )}
          {data && data.pages && (
            <VisitInformationBoxComponent
              title="Pages"
              isLink={true}
              isGoToPage={true}
              data={data.pages}
            />
          )}
          <div>
            {data && data.device_types && (
              <VisitInformationBoxComponent
                title="Device Types"
                isLink={false}
                data={data.device_types}
              />
            )}
            {data && data.browser_names && (
              <VisitInformationBoxComponent
                title="Browser Names"
                isLink={false}
                data={data.browser_names}
                isBackgroundImgColor={true}
              />
            )}
          </div>
          {data && data.countries && (
            <VisitInformationBoxComponent
              title="Countries"
              isLink={false}
              data={data?.countries}
              imgWidth="100%"
              imgHeight="100%"
            />
          )}
        </VisitInformationSection>
      </DashboardScreens>
    </Layout>
  );
}

export default SimpleAnalytics;

// ---------- Components ----------

function TitleSection({ visitors, simpleAnalyticsUrl }) {
  const formatTitle = title => {
    title = title.replace("simpleanalytics.com/", "");
    title = title.replace(".json", "");

    return title;
  };

  return (
    <TitleSectionCont>
      <TitleContainer>
        <Title>Analytics for </Title>
        <WebLink href={simpleAnalyticsUrl} target="_blank" rel="noopener noreferrer">
          {simpleAnalyticsUrl && formatTitle(simpleAnalyticsUrl.slice(8))}
        </WebLink>
      </TitleContainer>
      <p>They have had {visitors} visitors</p>
    </TitleSectionCont>
  );
}

function Graph({ data }) {
  const dataGrap = data.map(e => {
    return { ...e, date: moment(e.date).format("MMM Do YYYY") };
  });

  return (
    <GraphContainer>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={dataGrap}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="date" tick={{ fontSize: "16px" }} />
          <YAxis tick={{ fontSize: "16px" }} />
          <Tooltip />
          <Area dataKey="pageviews" stackId="1" stroke="#24acfc" fill="#abe0ff" />
          <Area dataKey="visitors" stackId="2" stroke="#04c6e5" fill="#dff6fa" />
        </AreaChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
}

function VisitInformationBoxComponent({
  title,
  isLink,
  isGoToPage,
  data,
  isBackgroundImgColor,
  imgWidth,
  imgHeight,
  simpleAnalyticsUrl,
}) {
  const totalVisits = data.reduce((result, curr) => {
    return result + curr.visits;
  }, 0);

  return (
    <VisitInformationBox>
      <VisitInformationBoxTitle>{title}</VisitInformationBoxTitle>
      {data.map((cardData, index) => (
        <div key={`visitInformationBox_${index}`}>
          <VisitInformationRenderCard
            isLink={isLink}
            isGoToPage={isGoToPage}
            cardData={cardData}
            totalVisits={totalVisits}
            isBackgroundImgColor={isBackgroundImgColor}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            simpleAnalyticsUrl={simpleAnalyticsUrl}
          />
        </div>
      ))}
    </VisitInformationBox>
  );
}

function VisitInformationRenderCard({
  isLink,
  isGoToPage,
  cardData,
  totalVisits,
  isBackgroundImgColor,
  imgWidth,
  imgHeight,
  simpleAnalyticsUrl,
}) {
  if (isLink) {
    return (
      <VisitInformationCard>
        <VisitInformationCardNumberText>{cardData.visits}</VisitInformationCardNumberText>
        <VisitInformationCardText>
          {reduceLinkText(cardData.value)}
        </VisitInformationCardText>
        {isGoToPage && (
          <GoToContainer
            href={`${simpleAnalyticsUrl}${cardData.value}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GoToImage src="/icons/simple-analytics/go-to.svg" />
          </GoToContainer>
        )}
      </VisitInformationCard>
    );
  }

  const totalVisitsPercentage =
    totalVisits === 0 ? 0 : (cardData.visits * 100) / totalVisits;

  const responseValue =
    cardData.value === "us" ? "united states" : cardData.value.toLowerCase();

  return (
    <VisitInformationCard>
      <ContainerImage isBackgroundImgColor={isBackgroundImgColor}>
        {cardData.value === "iOS Safari" && (
          <MobileIndicatorImage>M</MobileIndicatorImage>
        )}
        <Image
          src={`/icons/simple-analytics/${responseValue.replace(/ /g, "-")}.svg`}
          width={imgWidth}
          height={imgHeight}
          alt={cardData.value}
        />
      </ContainerImage>
      <VisitInformationCardPercentage>
        <VisitInformationCampDescription>
          {`${responseValue} ${totalVisitsPercentage.toFixed(1)}%`}
        </VisitInformationCampDescription>
        <VisitInformationCardPercentageNumber>
          {totalVisitsPercentage.toFixed(1)}%
        </VisitInformationCardPercentageNumber>
        <VisitInformationCardPercentageColor
          theme={{ percentage: totalVisitsPercentage }}
        />
      </VisitInformationCardPercentage>
    </VisitInformationCard>
  );
}

function reduceLinkText(string) {
  if (string.length > 20 && string.length <= 35) {
    return `${string.slice(0, 20)}...`;
  }

  if (string.length > 35) {
    return `${string.slice(0, 20)}...${string.substring(30)}`;
  }

  return string;
}

function getSupportedValues(field) {
  if (field === "countries") {
    return ["us"];
  }

  if (field === "browser_names") {
    return ["Chromium", "Chrome", "iOS Safari", "Safari"];
  }

  return [];
}

function parseResponse(fields, response) {
  const result = fields.reduce(
    (result, field) => {
      if (!Array.isArray(response[field])) {
        return result;
      }

      const supportedValues = getSupportedValues(field);

      const supportedItems = result[field].filter(item => {
        return supportedValues.find(value => value === item.value) !== undefined;
      });

      const unsupportedItems = result[field]
        .filter(item => {
          return supportedValues.find(value => value === item.value) === undefined;
        })
        .reduce(
          (result, curr) => {
            return {
              value: "More",
              visits: result.visits + curr.visits,
              uniqueVisits: result.uniqueVisits + curr.uniqueVisits,
            };
          },
          { value: "More", visits: 0, uniqueVisits: 0 },
        );

      result[field] = supportedItems.concat([unsupportedItems]);

      return result;
    },
    { ...response },
  );

  return result;
}

// ---------- Styles ----------

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TitleSectionCont = styled.div`
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  flex-direction: row;
  align-items: center;
  line-height: 0.5;
  text-align: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Title = styled.h1`
  text-align: left;
  font-size: 46px;
  margin-right: 10px;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`;

const WebLink = styled.a`
  color: #00c5e5;
  text-transform: capitalize;
  font-size: 40px;
  margin-top: 4px;

  @media (max-width: 700px) {
    font-size: 20px;
    width: 100%;
  }
`;

const GraphContainer = styled.div`
  margin: 40px 0px;
  width: 100%;
  height: 550px;

  @media (max-width: 640px) {
    margin: 0px 0px;
    height: 300px;
  }
`;

const VisitInformationSection = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const VisitInformationBox = styled.div`
  margin: 0rem 0.5rem;
  min-width: 200px;
  max-width: 600px;

  @media (max-width: 640px) {
    min-width: 100%;
  }
`;

const VisitInformationBoxTitle = styled.h3`
  font-size: 20px;
`;

const VisitInformationCard = styled.div`
  position: relative;
  margin: 15px 0px;
  display: flex;
  align-items: center;
`;

const VisitInformationCardNumberText = styled.p`
  margin: 0px;
  font-size: 16px;
  color: #00c5e5;
  width: 45px;
  overflow: hidden;
`;

const VisitInformationCardText = styled.p`
  margin: 0 0.5rem;
  min-width: 200px;
  font-size: 16px;
`;

const GoToContainer = styled.a`
  visibility: hidden;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #00b1ce;
  margin-bottom: 3px;
  position: absolute;
  top: 5%;
  right: 0%;

  &:active {
    opacity: 0.5;
  }

  ${VisitInformationCard}:hover & {
    visibility: visible;
  }
`;

const GoToImage = styled.img`
  width: 50%;
  height: 50%;
  margin: auto;
`;

const ContainerImage = styled.div`
  background-color: ${props =>
    props.isBackgroundImgColor ? "rgba(191,237,245,.5)" : "none"};
  border-radius: 3px;
  position: relative;
  width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  ${VisitInformationBox}:hover & {
    opacity: 1;
    background-color: ${props => props.theme.OpacityBackground};
  }
`;

const Image = styled.img`
  width: ${props => props.width || "70%"};
  height: ${props => props.height || "70%"};
  object-fit: contain;
`;

const MobileIndicatorImage = styled.span`
  position: absolute;
  top: 0%;
  right: 5%;
  font-size: 10px;
`;

const VisitInformationCardPercentage = styled.div`
  margin: 0 0.5rem;
  position: relative;
  width: 125px;
  height: 40px;
  display: flex;
`;

const VisitInformationCardPercentageNumber = styled.p`
  margin: auto;
  font-size: 16px;
`;

const VisitInformationCardPercentageColor = styled.div`
  position: absolute;
  top: 0px;
  width: ${props => props.theme.percentage}%;
  border-radius: 3px;
  background-color: rgba(191, 237, 245, 0.5);
  height: 100%;

  ${VisitInformationCard}:hover & {
    background-color: transparent;
  }
`;

const VisitInformationCampDescription = styled.div`
  visibility: hidden;
  font-size: 12px;
  font-weight: bold;
  color: white;
  line-height: 1.2;
  position: absolute;
  top: 0px;
  width: 0%;
  border-radius: 3px;
  background-color: #00b1ce;
  padding: 0px 10px;
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  overflow: hidden;

  ${VisitInformationCard}:hover & {
    width: 100%;
    visibility: visible;
    transition: width 0.5s;
    white-space: nowrap;
  }
`;
