import React from "react";
import PropTypes from "prop-types";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Table from "./Table";

const materialGreenColor = {
  50: "#3fc78e",
  100: "#3fc78e",
  200: "#3fc78e",
  300: "#3fc78e",
  400: "#3fc78e",
  500: "#3fc78e",
  600: "#3fc78e",
  700: "#3fc78e",
  800: "#3fc78e",
  900: "#3fc78e",
  A100: "#3fc78e",
  A200: "#3fc78e",
  A400: "#3fc78e",
  A700: "#3fc78e",
  light: "#3fc78e",
  main: "#3fc78e",
  dark: "#3fc78e",
  contrastText: "#3fc78e",
};

const theme = createMuiTheme({
  palette: { primary: materialGreenColor, secondary: materialGreenColor },
});

const Layout = ({
  /** Table Props */
  children,
  /** Table Props */
  handleClickRow,
  handleClickSort,
  handleCloseSortMenu,
  isOpenSortMenu,
  sortMenuItems,
  sortLabel,
  tableRows,
  tableType,
  title,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Grid container>
        <Table
          handleClickRow={handleClickRow}
          handleClickSort={handleClickSort}
          handleCloseSortMenu={handleCloseSortMenu}
          isOpenSortMenu={isOpenSortMenu}
          sortMenuItems={sortMenuItems}
          sortLabel={sortLabel}
          title={title}
          type={tableType}
          rows={tableRows}
        />
        {!!children && (
          <Grid container item xs={12}>
            {children}
          </Grid>
        )}
      </Grid>
    </MuiThemeProvider>
  );
};

Layout.defaultProps = {
  /** Table Default Props */
  handleClickSort: event => null,
  handleCloseSortMenu: event => null,
  isOpenSortMenu: false,
  sortMenuItems: [],
  sortLabel: "",
  tableRows: [],
  tableType: "",
  title: "",
};

Layout.propTypes = {
  /** Table Props */
  handleClickRow: PropTypes.func.isRequired,
  handleClickSort: PropTypes.func.isRequired,
  handleCloseSortMenu: PropTypes.func.isRequired,
  isOpenSortMenu: PropTypes.bool.isRequired,
  sortMenuItems: PropTypes.array.isRequired,
  sortLabel: PropTypes.string.isRequired,
  tableRows: PropTypes.array.isRequired,
  tableType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Layout;
