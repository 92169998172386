/*
 *
 * e - pay constants
 *
 */
export const GET_SCHEDULED_PAYMENTS_REQUEST = "get_scheduled_payments_request";
export const GET_SCHEDULED_PAYMENTS_SUCCESS = "get_scheduled_payments_success";
export const GET_SCHEDULED_PAYMENTS_FAILURE = "get_scheduled_payments_failure";

export const UNSCHEDULE_PAYMENT_REQUEST = "unschedule_payment_request";
export const UNSCHEDULE_PAYMENT_SUCCESS = "unschedule_payment_success";
export const UNSCHEDULE_PAYMENT_FAILURE = "unschedule_payment_failure";

export const GET_QUICK_PAYS_REQUEST = "get_quick_pays_request";
export const GET_QUICK_PAYS_SUCCESS = "get_quick_pays_success";
export const GET_QUICK_PAYS_FAILURE = "get_quick_pays_failure";

export const GET_AUTOPAY_REQUEST = "get_autopay_request";
export const GET_AUTOPAY_SUCCESS = "get_autopay_success";
export const GET_AUTOPAY_FAILURE = "get_autopay_failure";

export const REMOVE_AUTOPAY_REQUEST = "remove_autopay_request";
export const REMOVE_AUTOPAY_SUCCESS = "remove_autopay_success";
export const REMOVE_AUTOPAY_FAILURE = "remove_autopay_failure";

export const OPEN_CONFIRM_MODAL = "open_confirm_modal";
export const CLOSE_CONFIRM_MODAL = "close_confirm_modal";

export const OPEN_REMOVE_AUTOPAY_MODAL = "open_remove_autopay_modal";
export const CLOSE_REMOVE_AUTOPAY_MODAL = "close_remove_autopay_modal";

export const QUICKPAY_TO_AUTOPAY_REQUEST = "quickpay_to_autopay_request";
export const QUICKPAY_TO_AUTOPAY_SUCCESS = "quickpay_to_autopay_success";
export const QUICKPAY_TO_AUTOPAY_FAILURE = "quickpay_to_autopay_failure";

export const SET_OPTIONS_FROM_TYPE_QUICKPAY_ROW = "set_options_from_type_quickpay_row";

export const GET_PAYMENT_ACTIVITY_REQUEST = "get_payment_activity_request";
export const GET_PAYMENT_ACTIVITY_SUCCESS = "get_payment_activity_success";
export const GET_PAYMENT_ACTIVITY_FAILURE = "get_payment_activity_failure";

export const INSERT_PAYEE_REQUEST = "insert_payee_request";
export const INSERT_PAYEE_SUCCESS = "insert_payee_success";
export const INSERT_PAYEE_FAILURE = "insert_payee_failure";

export const UPDATE_PAYEE_REQUEST = "update_payee_request";
export const UPDATE_PAYEE_SUCCESS = "update_payee_success";
export const UPDATE_PAYEE_FAILURE = "update_payee_failure";

export const MAKE_PAYMENT_REQUEST = "make_payment_request";
export const MAKE_PAYMENT_SUCCESS = "make_payment_success";
export const MAKE_PAYMENT_FAILURE = "make_payment_failure";

export const OPEN_MAKE_PAYMENTS_CONFIRM_MODAL = "open_make_payments_confirm_modal";
export const CLOSE_MAKE_PAYMENTS_CONFIRM_MODAL = "close_make_payments_confirm_modal";

export const GET_PAYEE_DETAIL_REQUEST = "get_payee_detail_request";
export const GET_PAYEE_DETAIL_SUCCESS = "get_payee_detail_success";
export const GET_PAYEE_DETAIL_FAILURE = "get_payee_detail_failure";

export const GET_DEFAULT_PAYEES_REQUEST = "get_default_payees_request";
export const GET_DEFAULT_PAYEES_SUCCESS = "get_default_payees_success";
export const GET_DEFAULT_PAYEES_FAILURE = "get_default_payees_failure";

export const SET_LAST_PAY_DAY_VALUE = "set_last_pay_day_value";

export const SHOW_SUCCESS_MODAL = "show_success_modal";

export const REQUEST_REMOVE_QUICKPAY_ROW = "request_remove_quickpay_row";
export const REMOVE_QUICKPAY_ROW_SUCCESS = "quickpay_row_success";
export const REMOVE_QUICKPAY_ROW_FAILURE = "quickpay_row_failure";

export const FORM_QUICKPAY_UPDATE_VALUE = "form_quickpay_update_value";
export const UPDATE_PAYEE_DETAIL_FORM_VALUE = "update_payee_detail_form_value";
export const FORM_QUICKPAY_RESET = "form_quickpay_reset";

export const SET_QUICKPAY_TO_AUTOPAY = "set_quickpay_to_autopay";
export const SET_AUTOPAY_FOR_UPDATE = "set_autopay_for_update";

export const CHANGE_E_PAYMENTS_ERROR = "change_e_payments_error";

export const SHOW_SCHEDULE_AUTOPAY_SUCCESS_MODAL = "show_schedule_autopay_success_modal";
export const HIDE_SCHEDULE_AUTOPAY_SUCCESS_MODAL = "hide_schedule_autopay_success_modal";

export const UPDATE_AUTOPAY_REQUEST = "update_autopay_request";
export const UPDATE_AUTOPAY_SUCCESS = "update_autopay_success";
export const UPDATE_AUTOPAY_FAILURE = "update_autopay_failure";

export const AUTOPAY_TO_QUICKPAY_REQUEST = "autopay_to_quickpay_request";
export const AUTOPAY_TO_QUICKPAY_SUCCESS = "autopay_to_quickpay_success";
export const AUTOPAY_TO_QUICKPAY_FAILURE = "autopay_to_quickpay_failure";

export const SHOW_AUTOPAY_TO_QUICKPAY_CONFIRM_MODAL =
  "show_autopay_to_quickpay_confirm_modal";
export const HIDE_AUTOPAY_TO_QUICKPAY_CONFIRM_MODAL =
  "hide_autopay_to_quickpay_confirm_modal";

export const SHOW_AUTOPAY_UPDATE_CONFIRM_MODAL = "show_autopay_update_confirm_modal";
export const HIDE_AUTOPAY_UPDATE_CONFIRM_MODAL = "hide_autopay_update_confirm_modal";
