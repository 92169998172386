import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { ConfirmationModal } from "../../../components/commons/Modals";
import { validationInactive } from "../../../utils/helper";
import { logout } from "../../../containers/Auth/actions";
import * as actions from "../../../containers/App/actions";

const Inactive = ({ maxSessionMinutes, route, logout, isAdmin }) => {
  const [state, setState] = useState({
    openModalInactive: false,
    minutesExpired: 0,
  });

  //minutes available before token expires
  const getAvailableMinutes = isAdmin => {
    const expiredToken = isAdmin
      ? localStorage.getItem("expiredTokenAdmin")
      : localStorage.getItem("expiredToken");

    if (isAdmin && expiredToken !== null) {
      return parseFloat(expiredToken);
    } else {
      if (expiredToken !== null) {
        return parseFloat(expiredToken);
      }
    }

    return 0;
  };

  //seconds that have not expired
  const calculateMinutesWithoutExpirationToken = dateTokenStart => {
    const currentDate = moment();
    return dateTokenStart.diff(currentDate, "minutes", true);
  };

  //date when token expires
  const calculateExpirationTokenDate = isAdmin => {
    const tokenStartDate = isAdmin
      ? localStorage.getItem("tokenStartDateAdmin")
      : localStorage.getItem("tokenStartDate");

    if (isAdmin && tokenStartDate) {
      return moment(localStorage.getItem("tokenStartDateAdmin")).add(
        maxSessionMinutes,
        "minutes",
        true,
      );
    } else {
      if (tokenStartDate) {
        return moment(localStorage.getItem("tokenStartDate")).add(
          maxSessionMinutes,
          "minutes",
          true,
        );
      }
    }

    return null;
  };

  const inactiveAlert = () => {
    return setInterval(() => {
      const token = isAdmin
        ? localStorage.getItem("tokenAdmin")
        : localStorage.getItem("token");

      if (token) {
        let minutesAvailable = Math.ceil(getAvailableMinutes(isAdmin));
        let tokenExpiredDate = calculateExpirationTokenDate(isAdmin);
        let minutesToExpireToken = calculateMinutesWithoutExpirationToken(
          tokenExpiredDate,
        );

        let minutesToExpireTokenRound = Math.ceil(minutesToExpireToken);

        // only shows the alert once when the minute changes
        if (minutesAvailable !== minutesToExpireTokenRound) {
          // minutes in which you want to show the alert
          if (minutesToExpireTokenRound === 1 || minutesToExpireTokenRound === 3) {
            if (!state.openModalInactive) {
              setState(stateParams => ({
                ...stateParams,
                openModalInactive: true,
              }));
            }
            setState(stateParams => ({
              ...stateParams,
              minutesExpired: minutesToExpireTokenRound,
            }));
          }

          if (minutesToExpireTokenRound <= -0) {
            setState(stateParams => ({
              ...stateParams,
              minutesExpired: minutesToExpireTokenRound,
              openModalInactive: true,
            }));
          }
        }

        if (isAdmin) {
          localStorage.setItem("expiredTokenAdmin", minutesToExpireToken);
        } else {
          localStorage.setItem("expiredToken", minutesToExpireToken);
        }
      }
    }, 3000);
  };

  useEffect(() => {
    let interval = null;
    if (route && route.location && route.location.pathname) {
      if (validationInactive(route.location.pathname)) {
        interval = inactiveAlert();
      }
    }

    return () => clearInterval(interval);

    // eslint-disable-next-line
  }, [route]);

  return (
    <ConfirmationModal
      isOpen={state.openModalInactive}
      onClose={() => {
        let token = isAdmin
          ? localStorage.getItem("tokenAdmin")
          : localStorage.getItem("token");

        if (token && state.minutesExpired <= -0) {
          logout(isAdmin);
        }
        setState({ ...state, openModalInactive: false });
      }}
      headingText="Message"
      confirmationText={
        <p>
          {state.minutesExpired > 0
            ? `Your session expires in ${state.minutesExpired} minutes`
            : "Your session expired"}
        </p>
      }
      cancelButtonText="Close"
      aria-hidden={state.openModalInactive}
      aria={{
        labelledby: "heading",
        describedby: "full_description",
      }}
    />
  );
};

Inactive.prototype = {
  maxSessionMinutes: PropTypes.number,
  isAdmin: PropTypes.bool,
  // auth
  logout: PropTypes.func,
};

const mapStateToProps = state => ({
  route: state.route,
});

export default connect(mapStateToProps, { ...actions, logout })(Inactive);
