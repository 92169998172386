import React from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { Avatar, Grid } from "@material-ui/core";
import RowDateAndHour from "./RowDateAndHour";
import RowTitle from "./RowTitle";
import RowStatus from "./RowStatus";
import RowNickName from "./RowNickName";
import TableGridItem from "./TableGridItem";
import { WrapperButton } from "../../../components/commons";

const Row = ({
  avatarSize,
  avatarSource,
  date,
  hour,
  name,
  username,
  status,
  onClick,
}) => {
  const containerProps = {
    xs: 12,
    container: true,
    item: true,
    direction: "row",
    wrap: "nowrap",
    justify: "flex-start",
    alignItems: "center",
  };

  return (
    <TableGridItem {...containerProps}>
      <WrapperButton onClick={onClick} error={!status.ok}>
        <Grid
          container
          item
          direction="row"
          wrap="nowrap"
          justify="flex-start"
          alignItems="center"
          xs={10}
        >
          {!!avatarSource && (
            <Grid item style={{ marginRight: 12 }}>
              <Avatar
                src={avatarSource}
                style={{ width: avatarSize, height: avatarSize }}
              />
            </Grid>
          )}

          <Grid container item direction="column" justify="center" alignItems="stretch">
            <Grid item style={{ marginBottom: 5 }}>
              <RowTitle>{name}</RowTitle>
            </Grid>

            <Grid
              container
              item
              direction="row"
              wrap="nowrap"
              justify="flex-start"
              alignItems="center"
            >
              <Grid
                container
                item
                direction="row"
                wrap="nowrap"
                justify="flex-start"
                alignItems="center"
                style={{ marginRight: 5 }}
              >
                {isString(status.icon) ? (
                  <img src={status.icon} style={{ height: 18 }} />
                ) : (
                  status.icon
                )}

                <RowStatus error={!status.ok} style={{ margin: "0 5px" }}>
                  {status.label}
                </RowStatus>
                <RowNickName>{username}</RowNickName>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          direction="column"
          justify="center"
          alignItems="center"
          xs={2}
          style={{ alignItems: "flex-end" }}
        >
          <Grid item style={{ margin: 0, paddingBottom: 6 }}>
            <RowDateAndHour>{hour}</RowDateAndHour>
          </Grid>
          <Grid item style={{ margin: 0, paddingBottom: 0 }}>
            <RowDateAndHour>{date}</RowDateAndHour>
          </Grid>
        </Grid>
      </WrapperButton>
    </TableGridItem>
  );
};

Row.defaultProps = {
  avatarSize: 40,
  avatarSource: "",
  date: "",
  hour: "",
  name: "",
  username: "",
  onClick: event => null,
  status: {
    ok: false,
    label: "",
    icon: "",
  },
};

Row.propTypes = {
  avatarSize: PropTypes.number,
  avatarSource: PropTypes.string,
  date: PropTypes.string.isRequired,
  hour: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.shape({
    ok: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }),
};

export default Row;
