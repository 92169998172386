import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextField from "../../../components/commons/TextField";
import Select from "../../../components/commons/SelectInput";
import { MenuItem, InputAdornment } from "@material-ui/core";
import { Button, Loader, Space, TextError } from "../../../components/commons";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import CurrencyFormat from "react-currency-format";
import _ from "lodash";

import { LoanStep, LoanProgress, LoanForm, LoanControls, LoanBody } from "./LoanApp";
import LoanAppPage from "../Loans/LoanAppPage";
import { startLoanApp } from "./actions";

class LoanRequestInformation extends Component {
  static propTypes = {
    startLoanApp: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    currentStep: PropTypes.number,
    isOpenCustomModalLoan: PropTypes.bool,
  };
  state = {
    typesOfLoan: [
      { key: 1, value: "New Vehicle" },
      { key: 2, value: "Used Vehicle" },
      { key: 3, value: "Recreational Equipment" },
      { key: 4, value: "Home Equity" },
      { key: 5, value: "Home Equity Line of Credit" },
      { key: 6, value: "Share Secured" },
      { key: 7, value: "Unsecured" },
    ],
    coApplicantOptions: [
      { key: 1, value: "Yes" },
      { key: 2, value: "No" },
    ],
    amountRequested: "",
    typeOfLoan: "",
    purposeOfLoan: "",
    coApplicantsNumber: "No",
    formError: null,
  };

  onSubmit = () => {
    const { amountRequested, typeOfLoan, purposeOfLoan, coApplicantsNumber } = this.state;
    if (
      !amountRequested ||
      amountRequested === "" ||
      !typeOfLoan ||
      typeOfLoan === "" ||
      !coApplicantsNumber ||
      coApplicantsNumber === ""
    ) {
      return this.setState({ formError: "please fill all the fields" });
    }
    const withCoApplicant = this.stringToBoolean(coApplicantsNumber);
    this.setState({ formError: null });
    const bodyToPost = {
      amount: parseFloat(amountRequested.replace(/,/g, "")),
      typeOfLoan: parseInt(typeOfLoan, 10),
      purposeOfLoan,
      coApplicantsNumber: withCoApplicant,
    };

    this.props.startLoanApp(bodyToPost);
  };

  stringToBoolean(string) {
    switch (string) {
      case "Yes":
      case "1":
        return true;
      case "No":
      case "0":
      case null:
        return false;
      default:
        return Boolean(string);
    }
  }

  RenderFormError = () =>
    this.state.formError && <TextError>{this.state.formError}</TextError>;
  RenderRequestError = () =>
    this.props.error && <TextError>{this.props.error}</TextError>;

  changeState = state => {
    this.setState(state);
  };

  closeLoanModal = () => {
    this.props.getResponse(false);
  };

  render() {
    return (
      <>
        {this.props.currentStep > 0 ? (
          <>
            <LoanAppPage setState={this.changeState} />
          </>
        ) : (
          <LoanStep>
            <Loader isLoading={this.props.isFetching} />
            {/* <LoanTitle title={'Applicant: Request Information'} /> */}
            <LoanBody>
              <Mobile>
                <LoanForm>
                  <CurrencyFormat
                    label={"Requested Amount ($)"}
                    required
                    value={this.state.amountRequested}
                    name="amount requested"
                    id="amountRequested"
                    customInput={TextField}
                    thousandSeparator={true}
                    onChange={e => this.setState({ amountRequested: e.target.value })}
                    bigField
                    startAdornment={
                      <InputAdornment position="start">
                        <span className="bigAdornment">$</span>
                      </InputAdornment>
                    }
                  />
                  <br />
                  <Select
                    id="typeOfLoanSelect"
                    aria-label="types of loan"
                    label={"Select Loan Type"}
                    value={this.state.typeOfLoan}
                    required
                    onChange={e => this.setState({ typeOfLoan: e.target.value })}
                  >
                    {_.map(this.state.typesOfLoan, (option, idx) => (
                      <MenuItem key={idx} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>

                  <br />

                  <TextField
                    onChange={e => this.setState({ purposeOfLoan: e.target.value })}
                    value={this.state.purposeOfLoan}
                    id="purposeOfLoan"
                    type="text"
                    name="purposeofloan"
                    label={"Please provide the purpose"}
                  />

                  <br />

                  {/* <Select
                id="coApplicantsNumber"
                label={'Using Co-Applicant'}
                value={this.state.coApplicantsNumber}
                aria-label="Select Co-applicant"
                onChange={e => this.setState({ coApplicantsNumber: e.target.value })}
              >
                {_.map(this.state.coApplicantOptions, (option, key) => <MenuItem key={key} value={option.value}>{option.value}</MenuItem>)}
              </Select> */}
                </LoanForm>
                <LoanProgress
                  text={"Progress"}
                  progress={"0%"}
                  title={"Step 01 - Loan Details"}
                />
                <br />
                {this.RenderFormError()}
                {this.RenderRequestError()}
                <br />
                <LoanControls>
                  <Button
                    onClick={() => this.props.setState({ isOpenCustomModalLoan: false })}
                  >
                    Cancel
                  </Button>
                  <Space />
                  <Button primary onClick={this.onSubmit}>
                    Continue
                  </Button>
                </LoanControls>
              </Mobile>
              <Desktop>
                <LoanProgress
                  text={"Progress"}
                  progress={"0%"}
                  title={"Step 01 - Loan Details"}
                />
                <LoanForm>
                  <CurrencyFormat
                    placeholder={"0.00"}
                    label={"Requested Amount ($)"}
                    required
                    value={this.state.amountRequested}
                    name="amount requested"
                    id="amountRequested"
                    customInput={TextField}
                    thousandSeparator={true}
                    onChange={e => this.setState({ amountRequested: e.target.value })}
                    bigField
                    startAdornment={
                      <InputAdornment position="start">
                        <span className="bigAdornment">$</span>
                      </InputAdornment>
                    }
                  />
                  <Select
                    id="typeOfLoanSelect"
                    aria-label="types of loan"
                    label={"Select Loan Type"}
                    value={this.state.typeOfLoan}
                    required
                    onChange={e => this.setState({ typeOfLoan: e.target.value })}
                  >
                    {_.map(this.state.typesOfLoan, (option, idx) => (
                      <MenuItem key={idx} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>

                  <TextField
                    onChange={e => this.setState({ purposeOfLoan: e.target.value })}
                    value={this.state.purposeOfLoan}
                    id="purposeOfLoan"
                    type="text"
                    name="purposeofloan"
                    label={"Please provide the purpose"}
                  />

                  {/* <Select
                  id="coApplicantsNumber"
                  label={'Using Co-Applicant'}
                  value={this.state.coApplicantsNumber}
                  aria-label="Select Co-applicant"
                  onChange={e => this.setState({ coApplicantsNumber: e.target.value })}
                >
                  {_.map(this.state.coApplicantOptions, (option, key) => <MenuItem key={key} value={option.value}>{option.value}</MenuItem>)}
                </Select> */}
                </LoanForm>
                <br />
                {this.RenderFormError()}
                {this.RenderRequestError()}
                <LoanControls>
                  <Button
                    onClick={() => this.props.setState({ isOpenCustomModalLoan: false })}
                  >
                    Cancel
                  </Button>
                  <Space />
                  <Button primary onClick={this.onSubmit}>
                    Continue
                  </Button>
                </LoanControls>
              </Desktop>
            </LoanBody>
          </LoanStep>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.loan.isFetching,
  currentStep: state.loan.currentStep,
  error: state.loan.error,
});
export default connect(mapStateToProps, { startLoanApp })(LoanRequestInformation);
