/*
 *
 * funds transfer constants
 *
 */
export const REQUEST_POST_FUNDS_TRANSFER = "request_post_funds_transfer";
export const POST_FUNDS_TRANSFER_SUCCESS = "post_funds_transfer_success";
export const POST_FUNDS_TRANSFER_FAILURE = "post_funds_transfer_failure";

export const SHOW_TRANSFER_SUCCESS_MODAL = "show_transfer_success_modal";
export const SHOW_TRANSFER_ERROR_MODAL = "show_transfer_error_modal";

// transfers
export const REQUEST_GET_ACCOUNT_TRANSFERS = "request_get_account_transfers";
export const GET_ACCOUNT_TRANSFERS_SUCCESS = "get_account_transfers_success";
export const GET_ACCOUNT_TRANSFERS_FAILURE = "get_account_transfers_failure";

// validate amount
export const DISPATCH_VALIDATE_AMOUNT = "dispatch_validate_amount";
