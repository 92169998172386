import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button } from "../../../commons";
import TextField from "../../../../components/commons/TextField";
import AdminCheckboxContainer from "./AdminCheckboxContainer";
import CustomModal from "../../../commons/CustomModal";

const Title = styled.span`
  margin-bottom: 49px;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 36px;
  text-align: left;
  color: #152134;
  width: 100%;
  font-weight: bold;
  display: inline-block;
`;

const AssignText = styled.span`
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px;
  color: #a5a7a9;
  margin-bottom: 12.5px;
  margin-top: 48px;
  display: inline-block;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  & > button:focus {
    outline: #ffffff;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
`;

const fakedata = [
  {
    id: 1,
    title: "Full Access",
    description: "Access to all features in the system.",
  },
  {
    id: 2,
    title: "Banking Access",
    description:
      "Can access Alerts, Messages, Member eView, MFA Reset, Analytics, CMS Link, Document Storage.",
  },
  {
    id: 3,
    title: "Content Management",
    description: "Can access analytics, CMS Link.",
  },
];

function NewAdminModal({ open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <ModalContainer>
        <Title>New Admin</Title>
        <TextField
          // onChange={}
          // value={}
          // onKeyPress={}
          fullWidth
          type="text"
          autoFocus
          name="email"
          id="email"
          notMargin
          label={"Email"}
        />

        <AssignText>Assign Role</AssignText>
        <AdminCheckboxContainer checkboxs={fakedata} />
      </ModalContainer>
      <Footer>
        <Button
          modalButton
          style={{ width: "100%" }}
          primary
          onClick={e => {
            handleClose(e);
          }}
        >
          Create and Send Invitation
        </Button>
        <Button modalButton style={{ width: "100%" }} onClick={handleClose}>
          Cancel
        </Button>
      </Footer>
    </CustomModal>
  );
}

NewAdminModal.prototype = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUnlok: PropTypes.func,
  name: PropTypes.string,
  username: PropTypes.string,
};

export default NewAdminModal;
