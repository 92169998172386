import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { handleSubmitSSOForm } from "../../../utils/helper";
import { QuickLinks, QLink } from "../../../components/commons/QuickLinks";
import { getSsoList, getSsoRedirectUrl } from "../../../containers/Dashboard/SSO/actions";
import { Button, Loader, Separator, PageTitle, P } from "../../../components/commons";
import { ConfirmationModal } from "../../../components/commons/Modals";
import {
  getQuickLinks,
  getExternalSiteMessage,
  showExternalConfirmationModal,
} from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

class QuickLinksPage extends React.Component {
  static propTypes = {
    quickLinks: PropTypes.array,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    redirectUrl: PropTypes.string,
    isPost: PropTypes.bool,
    formParams: PropTypes.bool,
    getQuickLinks: PropTypes.func,
    quickLinkSsoList: PropTypes.array,
    getExternalSiteMessage: PropTypes.func,
    getSsoList: PropTypes.func,
    getSsoRedirectUrl: PropTypes.func,
    showExternalConfirmationModal: PropTypes.func,
    externalSiteMessage: PropTypes.string,
    isExternalConfirmationModalOpen: PropTypes.bool,
  };
  state = { selectedQuickLinkUrl: null };
  async componentDidMount() {
    await Promise.all([
      this.props.getQuickLinks(),
      this.props.getSsoList(),
      this.props.getExternalSiteMessage(),
    ]);
  }
  handleClickRequestSSOLink = async (e, ssoId) => {
    e.preventDefault();
    await this.props.getSsoRedirectUrl(ssoId);
    if (this.props.isPost && this.props.formParams) {
      handleSubmitSSOForm(this.props.redirectUrl, this.props.formParams, "post");
    } else {
      handleSubmitSSOForm(this.props.redirectUrl, this.props.formParams, "get");
    }
  };

  handleOpenQuickLink(e, isExternal, url) {
    e.preventDefault();
    if (isExternal) {
      this.props.showExternalConfirmationModal(true);
      this.setState({ selectedQuickLinkUrl: url });
    } else {
      window.open(url);
    }
  }
  handleOpenExternalLinkSuccess = () => {
    this.props.showExternalConfirmationModal(false);
    window.open(this.state.selectedQuickLinkUrl);
  };

  render() {
    return (
      <DashboardScreens>
        <div>
          <Loader isLoading={this.props.isFetching} />
          <PageTitle>Quick Links</PageTitle>
          <Separator style={{ marginBottom: "3rem" }} />
          <ConfirmationModal
            isOpen={this.props.isExternalConfirmationModalOpen}
            onClose={() => this.props.showExternalConfirmationModal(false)}
            onSuccess={this.handleOpenExternalLinkSuccess}
            headingText="Quick Link"
            confirmationText={<P>{this.props.externalSiteMessage}</P>}
            cancelButtonText="Cancel"
            successButtonText="Confirm"
            aria-hidden={this.props.isExternalConfirmationModalOpen}
            aria={{
              labelledby: "heading",
              describedby: "full_description",
            }}
          >
            <P style={{ color: "red" }}>{this.props.error || ""}</P>
          </ConfirmationModal>
          <QuickLinks>
            {this.props.quickLinks &&
              _.map(
                this.props.quickLinks,
                ({ id, description, buttonText, isExternal, url, imageUrl }) => (
                  <QLink key={id} imageUrl={imageUrl}>
                    <div
                      style={{
                        background: "black",
                        opacity: 0.6,
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 1,
                        borderRadius: 10,
                      }}
                    />
                    <div style={{ zIndex: 2, position: "relative" }}>
                      <small style={{ fontWeight: "300", color: "white" }}>
                        {description}
                      </small>
                      <Button
                        style={{ margin: "20px auto", maxWidth: 200 }}
                        primary
                        onClick={e => this.handleOpenQuickLink(e, isExternal, url)}
                      >
                        {buttonText}
                      </Button>
                    </div>
                  </QLink>
                ),
              )}
            {this.props.quickLinkSsoList &&
              _.map(this.props.quickLinkSsoList, sso => (
                <QLink key={`${sso.id}-${sso.name}`} imageUrl={sso.imageUrl}>
                  <div
                    style={{
                      background: "black",
                      opacity: 0.6,
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                      zIndex: 1,
                      borderRadius: 10,
                    }}
                  />
                  <div style={{ zIndex: 2, position: "relative" }}>
                    <p style={{ color: "white" }}>{sso.name}</p>
                    <small style={{ fontWeight: "300", color: "white" }}>
                      {sso.description}
                    </small>
                    <Button
                      onClick={e => this.handleClickRequestSSOLink(e, sso.id)}
                      style={{ margin: "20px auto", maxWidth: 160 }}
                      primary
                    >
                      {sso.buttonText}
                    </Button>
                  </div>
                </QLink>
              ))}
          </QuickLinks>
        </div>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = ({
  links: {
    quickLinks,
    isFetching,
    error,
    externalSiteMessage,
    isExternalConfirmationModalOpen,
  },
  sso: { quickLinkSsoList, redirectUrl, isPost, formParams },
}) => ({
  quickLinks,
  isFetching,
  error,
  quickLinkSsoList,
  externalSiteMessage,
  isExternalConfirmationModalOpen,
  redirectUrl,
  isPost,
  formParams,
});

export default connect(mapStateToProps, {
  getQuickLinks,
  getSsoList,
  getSsoRedirectUrl,
  getExternalSiteMessage,
  showExternalConfirmationModal,
})(QuickLinksPage);
