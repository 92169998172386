import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    "&$checked": {
      color: "#05CE86",
    },
  },
  checked: {
    color: "#05CE86",
  },
  label: {
    fontWeight: "300",
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.87)",
  },
};

const CheckBox = props => {
  const { classes, formControlLabelStyle, checkBoxStyle, label, ...rest } = props;
  return (
    <FormControlLabel
      style={formControlLabelStyle}
      control={<Checkbox style={checkBoxStyle} className={classes.root} {...rest} />}
      classes={{ label: classes.label }}
      label={label}
    />
  );
};

CheckBox.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  checkBoxStyle: PropTypes.object,
  formControlLabelStyle: PropTypes.object,
};

export default withStyles(styles)(CheckBox);
