export const GET_CUSTOMER_GOALS_REQUEST = "get_customer_goals_request";
export const GET_CUSTOMER_GOALS_SUCCESS = "get_customer_goals_success";
export const GET_CUSTOMER_GOALS_FAILURE = "get_customer_goals_failure";

export const REMOVE_CUSTOMER_GOALS_REQUEST = "remove_customer_goals_request";
export const REMOVE_CUSTOMER_GOALS_SUCCESS = "remove_customer_goals_success";
export const REMOVE_CUSTOMER_GOALS_FAILURE = "remove_customer_goals_failure";

export const CREATE_CUSTOMER_GOAL_REQUEST = "create_customer_goal_request";
export const CREATE_CUSTOMER_GOAL_SUCCESS = "create_customer_goal_success";
export const CREATE_CUSTOMER_GOAL_FAILURE = "create_customer_goal_failure";

export const UPDATE_CUSTOMER_GOAL_REQUEST = "update_customer_goal_request";
export const UPDATE_CUSTOMER_GOAL_SUCCESS = "update_customer_goal_success";
export const UPDATE_CUSTOMER_GOAL_FAILURE = "update_customer_goal_failure";

export const UPDATE_GOAL_VALUE = "update_goal_value";

export const SHOW_CREATE_GOAL_MODAL = "show_create_goal_modal";
export const SHOW_UPDATE_GOAL_MODAL = "show_update_goal_modal";
export const SHOW_CREATE_GOAL_SUCCESS_MODAL = "show_create_goal_success_modal";

export const INIT_GOAL_VALUES = "init_goal_values";

export const DISPATCH_VALIDATE_AMOUNT = "dispatch_validate_amount";
export const SHOW_ERROR_AMOUNT_MODAL = "show_error_amount_modal";
