import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import {
  CheckRoundGreen,
  Edit,
  BlackTrash,
  MoreVertical,
  RedTrash,
} from "../../../../components/commons/icons";
import { Mobile, Desktop } from "../../../../components/commons/Responsive";

const ContainerCard = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #ebeff3;
  padding: 10px 20px;
  cursor: pointer;

  .name {
    display: block;
    color: #000000;
    font-family: "Kumbh Sans";
    font-size: 15px;
    letter-spacing: 0;
  }

  .description {
    display: block;
    color: rgba(100, 98, 110, 0.6);
    font-family: "Kumbh Sans";
    font-size: 15px;
    letter-spacing: 0;
  }

  .containerText {
    width: 100%;
  }

  .actions {
    display: none;
  }

  &:hover {
    border-radius: 12px;
    background-color: rgba(218, 218, 218, 0.1);

    .actions {
      display: flex;
      justify-content: space-around;
      min-width: 76px;
    }
  }

  &.selected {
    background-color: rgba(63, 199, 142, 0.1);
    border-bottom: none;
    border-radius: 12px;
    padding: 0px 10px;
  }

  .dropdown-content {
    a {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
      }
    }
  }
`;

const Payee = ({
  name,
  description,
  openModal,
  titleModal,
  openDeleteModal,
  selected,
}) => {
  return (
    <>
      <Mobile>
        <ContainerCard>
          <div className="containerText">
            <span className="name">{name}</span>
            <span className="description">{description}</span>
          </div>
          <div>
            <div className="dropdown">
              <a href="#any" onClick={() => {}}>
                <MoreVertical />
              </a>
              <div className="dropdown-content">
                <a href="#any" onClick={() => {}}>
                  <Edit /> <span>Edit Payee</span>
                </a>
                <a href="#any" onClick={() => {}}>
                  <RedTrash /> <span>Delete Payee</span>
                </a>
              </div>
            </div>
          </div>
        </ContainerCard>
      </Mobile>
      <Desktop>
        <ContainerCard className={`${selected ? "selected" : ""}`}>
          <div className="containerText">
            <span className="name">{name}</span>
            <span className="description">{description}</span>
          </div>
          {selected ? (
            <CheckRoundGreen
              stylesInput={{
                width: "20px",
                height: "20px",
                opacity: 1,
              }}
            />
          ) : (
            <div className="actions">
              <FeatherIcon
                icon="trash"
                size="20"
                stroke="#000"
                onClick={openDeleteModal}
              />
              <FeatherIcon
                icon="edit"
                size="20"
                stroke="#000"
                onClick={() => {
                  titleModal();
                  openModal();
                }}
              />
            </div>
          )}
        </ContainerCard>
      </Desktop>
    </>
  );
};

export default Payee;
