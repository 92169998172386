import styled from "styled-components";
export default styled.p`
  color: #fd6773;
  font-size: 12px;
  font-weight: 400;
  padding-left: 1rem;

  @media (max-width: 480px) {
    margin: 0px;
  }
`;
