import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import Checkbox from "../../../../components/commons/Checkbox";
import FormActionMenu from "./FormActionMenu";

const FormHoverContainer = styled.div`
  display: flex;
  flex: 1;

  .footer,
  .header,
  .container-left {
    display: flex;
  }

  .container-left {
    align-items: center;
    justify-content: center;
    margin-right: 3%;
  }

  .container-right {
    flex: 1;

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .accountName {
      font-size: 14px;
    }

    .title,
    .accountName,
    .date,
    .type {
      color: #141f2d;
      margin: 0px;
    }

    .footer,
    .header {
      flex: 1;
    }

    .footer {
      .container-left {
        flex: 1;
        display: block;
      }
    }

    .header {
      .source {
        color: #8e949c;
        flex: 1;
        margin: 0px;
      }

      .date {
        flex: 1;
        text-align: right;
      }

      .date,
      .source {
        font-size: 12px;
      }
    }
  }
`;

const FormHover = ({
  source,
  date,
  status,
  title,
  accountName,
  checked,
  handlerChangeStatusForm,
  onChangeCheckboxForm,
}) => {
  return (
    <FormHoverContainer className="form-hover">
      <div className="container-left">
        <Checkbox
          onChange={onChangeCheckboxForm}
          checked={checked}
          label={""}
          formControlLabelStyle={{ margin: 0 }}
          onClick={e => e.stopPropagation()}
          checkBoxStyle={{ padding: 0, color: checked ? "#3fc78e" : "currentColor" }}
        />
      </div>
      <div className="container-right">
        <div className="header">
          <p className="source">
            Source: <span className="type">{source}</span>
          </p>
          <p className="date">{date}</p>
        </div>
        <div className="footer">
          <div className="container-left">
            <p className="title">{title}</p>
            <p className="accountName">{accountName}</p>
          </div>
          <FormActionMenu
            handlerChangeStatusForm={handlerChangeStatusForm}
            status={status}
          />
        </div>
      </div>
    </FormHoverContainer>
  );
};

FormHover.propTypes = {
  source: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  accountName: PropTypes.number,
  status: PropTypes.string,
  checked: PropTypes.bool,
  handlerChangeStatusForm: PropTypes.func,
  onChangeCheckboxForm: PropTypes.func,
};

export default FormHover;
