import React from "react";
import PropTypes from "prop-types";
import { Space } from "../../../components/commons";
import {
  StepBox,
  StepBody,
  StepTitle,
  StepBackButton,
  StepLogo,
  StepText,
} from "./Steps";
import styles from "./step.module.css";

function CantLogIn({ handleChangeStep, config }) {
  return (
    <StepBox>
      <StepLogo src={config && config.fiLogo} />
      <StepBody>
        <StepTitle>I Can&#39;t Log In</StepTitle>
        <StepText>
          Sorry for the inconvenience. Please choose any of the following alternatives to
          contact us:
        </StepText>
        <div style={{ padding: "1rem", marginBottom: "3rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/icons/smartphone.svg" alt="smartphone" />
            <Space />
            <small className={styles.primaryText}>
              Call us at: {config.fiContactPhone || ""}
            </small>
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src="/icons/mail.svg" alt="smartphone" />
            <Space />
            <small className={styles.primaryText}>
              Email us at: {config.fiContactEmail || ""}
            </small>
          </div>
        </div>
      </StepBody>
      <StepBackButton onClick={() => handleChangeStep("1")} />
    </StepBox>
  );
}

CantLogIn.propTypes = {
  handleChangeStep: PropTypes.func.isRequired,
  config: PropTypes.object,
};

CantLogIn.defaultProps = {
  handleChangeStep: () => {},
};

export default CantLogIn;
