import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Layout from "./Layout";
import { REPORT_STATUS, tableTypes } from "./";
import { useFilters, useSearch, useTable } from "./hooks";
import { loginAsMemberEview } from "../../Admin/actions";

const { REACT_APP_AWS_S3_URL } = process.env;

const Members = ({ loginAsMemberEview, data, setSearchQuery, searchQuery, filters }) => {
  const getReportStatus = ({ action = REPORT_STATUS.MEMBERS.NONE }) => {
    switch (action) {
      case REPORT_STATUS.MEMBERS.SIGN_IN_FAILED:
        return {
          ok: false,
          label: "Failed Login",
          icon: `${REACT_APP_AWS_S3_URL}/images/error-circle.png`,
        };

      case REPORT_STATUS.MEMBERS.SIGN_IN_SUCCESSFUL:
        return {
          ok: true,
          label: "Successful Login",
          icon: `${REACT_APP_AWS_S3_URL}/images/checked.png`,
        };

      default:
        return null;
    }
  };

  const {
    dataSorted,
    handleClickFilter,
    handleClickSort,
    handleCloseSortMenu,
    filtersTypes,
    isOpenMenu,
  } = useFilters({
    getReportStatus,
    data,
  });

  const { data: searchData } = useSearch({
    query: searchQuery,
    data: dataSorted,
    searchBy: filters,
    userType: tableTypes.MEMBERS,
  });

  const title = `${searchData.length} Members Logs`;

  const sortLabel = "Sort: Online First";

  const sortMenuItems = [
    { label: "Username", type: filtersTypes.USERNAME },
    { label: "Date", type: filtersTypes.DATE },
    { label: "None", type: filtersTypes.NONE },
  ];

  const afterHandleClickRow = (row = {}) => {
    loginAsMemberEview(row.userId);
  };

  const { handleClickRow } = useTable({ afterHandleClickRow });

  return (
    <Layout
      title={title}
      tableType={tableTypes.MEMBERS}
      sortLabel={sortLabel}
      isOpenSortMenu={isOpenMenu}
      tableRows={searchData}
      searchQuery={searchQuery}
      sortMenuItems={sortMenuItems}
      handleChangeSearchQuery={setSearchQuery}
      handleClickFilter={handleClickFilter}
      handleClickSort={handleClickSort}
      handleCloseSortMenu={handleCloseSortMenu}
      handleClickRow={handleClickRow}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { loginAsMemberEview };

Members.propTypes = {
  loginAsMemberEview: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  searchQuery: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Members);
