import React from "react";
import FeatherIcon from "feather-icons-react";

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export const SortIcon = ({ tableHeader, sortConfig }) => {
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  switch (getClassNamesFor(tableHeader)) {
    case "ascending":
      return <FeatherIcon icon="chevron-up" size="16" stroke="#797C7E" />;
    case "descending":
      return <FeatherIcon icon="chevron-down" size="16" stroke="#797C7E" />;
    default:
      return <FeatherIcon icon="chevron-down" size="16" stroke="#797C7E" />;
  }
};
