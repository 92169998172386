import React, { Component } from "react";
import PropTypes from "prop-types";
import { goBack } from "react-router-redux";
import { connect } from "react-redux";
import styled from "styled-components";
import TextField from "../../../components/commons/TextField";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Edit } from "../../../components/commons/icons";
import {
  Separator,
  Button,
  Loader,
  Heading,
  P,
  PageTitle,
} from "../../../components/commons";
import { selectPayeeInformation } from "./selectors";
import { updatePayee, getPayeeDetail, updatePayeeDetailFormValue } from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const Container = styled.div`
  flex: 1;
`;

const PayeeForm = styled.form`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 992px) {
    flex: 2;
    padding: 1px;
    display: flex;
    flex-wrap: wrap;
  }
  @media (min-width: 1400px) {
    flex: 2;
  }

  @media (min-width: 1600px) {
    flex: 2;
  }

  @media (min-width: 1900px) {
    flex: 2;
  }
`;
class PayeeInformation extends Component {
  static propTypes = {
    goBack: PropTypes.func,
    updatePayee: PropTypes.func,
    getPayeeDetail: PropTypes.func,
    updatePayeeDetailFormValue: PropTypes.func,
    payeeDetails: PropTypes.object,
    payee: PropTypes.object,
    match: PropTypes.object,
    isFetching: PropTypes.bool,
    name: PropTypes.string,
    phone: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    nickname: PropTypes.string,
    accountNumber: PropTypes.string,
  };
  componentDidMount() {
    let legacyPayeeId;
    let legacyPaymentId;
    let itsQuickPay;
    const { payee } = this.props;
    const { uniqueId } = this.props.match.params;
    if (payee && payee.legacyPayeeId && payee.legacyPaymentId) {
      legacyPayeeId = payee.legacyPayeeId;
      legacyPaymentId = payee.legacyPaymentId;
    }
    if (uniqueId) {
      // determine if it's an auto pay or a quick pay unique id.
      // first character should say:
      // a-...  --> it's an auto pay
      // q-...  --> it's a quick pay
      // according to that, send it in the api call
      itsQuickPay = uniqueId.charAt(0) === "q";
    }
    if (legacyPayeeId && legacyPaymentId) {
      // if this info was provided, then send only these parameters
      this.props.getPayeeDetail({ legacyPayeeId, legacyPaymentId });
      return;
    }
    if (itsQuickPay) {
      this.props.getPayeeDetail({ quickPayUniqueId: uniqueId });
    } else {
      this.props.getPayeeDetail({ autoPayUniqueId: uniqueId });
    }
  }
  onSubmit() {
    this.props.updatePayee(this.props.payeeDetails);
  }
  handleChange = e => {
    const { name, value } = e.target;
    this.props.updatePayeeDetailFormValue(name, value);
  };
  render() {
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={this.props.isFetching} />
          <PageTitle>Payee Information</PageTitle>
          <div style={{ display: "flex" }}>
            <P style={{ flex: 1 }}>
              Your payee information is listed below. you can also edit the payee
              information from here
            </P>
            <div style={{ flex: 1 }} />
          </div>
          <Separator />
          <Heading>Payee information (* Required fields)</Heading>
          <PayeeForm>
            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.name || ""}
              type="text"
              name="name"
              id="name"
              label={"Name*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.address2 || ""}
              type="text"
              name="address2"
              id="address2"
              label={"Address 2"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.zipCode || ""}
              type="text"
              name="zipCode"
              id="zip"
              label={"Zip Code*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.phone || ""}
              type="text"
              name="phone"
              id="phone"
              label={"Phone*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.city || ""}
              type="text"
              name="city"
              id="city"
              label={"City*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.nickname || ""}
              type="text"
              name="nickname"
              id="nickname"
              label={"Nickname*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.address1 || ""}
              type="text"
              name="address1"
              id="address1"
              label={"Address 1*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.state || ""}
              type="text"
              name="state"
              id="code"
              label={"State Code*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />

            <TextField
              onChange={this.handleChange}
              value={this.props.payeeDetails.accountNumber || ""}
              type="text"
              name="accountNumber"
              id="account"
              label={"Account # / ID Info*"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Edit />
                  </IconButton>
                </InputAdornment>
              }
            />
          </PayeeForm>
          <br />
          <br />
          <div style={{ display: "flex" }}>
            <Button primary onClick={() => this.onSubmit()}>
              Update
            </Button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Button danger onClick={() => this.props.goBack()}>
              Cancel
            </Button>
          </div>
        </Container>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const { payeeId } = ownProps.match.params;
  return {
    payee: selectPayeeInformation(state, payeeId),
    payeeDetails: state.epayment.payeeDetails,
    isFetching: state.epayment.isFetching,
  };
};
export default connect(mapStateToProps, {
  updatePayee,
  getPayeeDetail,
  goBack,
  updatePayeeDetailFormValue,
})(PayeeInformation);
