import React from "react";

import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";

import { FinancialInstitutionSecuredFormsCustomerStatus } from "../../../../utils/enums";
import { isArchived } from "../../../../utils/containers/admin/forms";

const HeaderActionTab = ({ status, handlerChangeStatusFormMany }) => {
  const {
    ARCHIVED_UNREAD,
    UNREAD,
    FAVORITE,
    ARCHIVED_FAVORITE,
  } = FinancialInstitutionSecuredFormsCustomerStatus;
  return (
    <div style={{ display: "flex" }}>
      <FeatherIcon
        icon={isArchived(status) ? "inbox" : "archive"}
        size="20"
        style={{ paddingLeft: "5px", paddingRight: "10px", cursor: "pointer" }}
        onClick={() =>
          handlerChangeStatusFormMany(isArchived(status) ? UNREAD : ARCHIVED_UNREAD)
        }
      />
      <FeatherIcon
        icon="star"
        size="20"
        style={{ cursor: "pointer" }}
        onClick={() =>
          handlerChangeStatusFormMany(isArchived(status) ? ARCHIVED_FAVORITE : FAVORITE)
        }
      />
    </div>
  );
};

HeaderActionTab.prototype = {
  handlerChangeStatusFormMany: PropTypes.func,
  status: PropTypes.number,
};

export default HeaderActionTab;
