import styled from "styled-components";
const Collapsable = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  border: 1px solid #eee;
`;

const Header = styled.div`
  display: flex;
  padding: 12px;
  cursor: pointer;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
`;

const Content = styled.div`
  display: ${({ on }) => (on ? "flex" : "none")};
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  padding: 1.5rem;
`;

export { Collapsable, Header, Content };
