import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { push, replace } from "react-router-redux";
import CurrencyFormat from "react-currency-format";
import {
  Button,
  TransactionsContainer,
  Loader,
  Box,
  FiltersForm,
  Heading,
  PageTitle,
  P,
  OverflowContent,
} from "../../../components/commons";
import _ from "lodash";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import {
  Calendar,
  Cancel,
  Plus,
  Minus,
  Check,
  Link as ExternalLink,
} from "../../../components/commons/icons";
import {
  MobileTable,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
} from "@material-ui/core";
import { getFromDate, getToDate } from "../../../utils/helper";
import Select from "../../../components/commons/SelectInput";
import * as enums from "../../../utils/enums";
import { getCustomerTransactions, getAccountSummary } from "./actions";
import { getSsoList, getSsoRedirectUrl } from "../SSO/actions";
import {
  selectAccounts,
  selectTransactions,
  selectAccountIds,
  selectTransactionTypes,
} from "./selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";
import DatePickerCustom from "../../../components/commons/DatePickerCustom";
import DatepickerTransactions from "../../../components/commons/DatepickerTransactions";
import { ReceiptModal } from "../../../components/commons/Modals";
import { post } from "../../../utils/server";
const ROOT_URL = `/customer`;

const Container = styled.div``;

const VerticalSeparator = styled.div`
  height: 25px;
  border-right: 1px solid #e6ecf0;
  margin: 12px 2px 0 9px;
`;

const TransactionRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: rgb(20, 31, 45);
  font-size: 16px;
  padding: 10px 0;
`;

const DatepickerContainer = styled.div`
  height: 31px;
  width: 346.5px;
  border-radius: 8px;
  background-color: #f4f6f8;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.5s;
  margin-top: 12px;

  &.hide {
    opacity: 0;
    position: absolute;
    height: 0;
    visibility: hidden;
  }
`;

const CalendarContainer = styled.div`
  opacity: 1;
  transition: opacity 0;
  margin-top: 12px;
  &.hide {
    opacity: 0;
    position: absolute;
  }
`;

// import me
const MobileFiltersHeader = ({ onClick, isOpen }) => (
  <Box centered>
    <h3 style={{ letterSpacing: "1.5px", flex: 1 }}>Filters</h3>
    <span style={{ flex: 0 }}>
      {isOpen && isOpen ? <Minus onClick={onClick} /> : <Plus onClick={onClick} />}
    </span>
  </Box>
);

const DatepickerAnimation = () => {
  var container = document.getElementById("DatepickerContainer");
  var datepicker = document.getElementById("Calendar");

  if (container.classList.contains("hide")) {
    container.classList.remove("hide");
    datepicker.classList.add("hide");
  } else {
    container.classList.add("hide");
    datepicker.classList.remove("hide");
  }
};

MobileFiltersHeader.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

const TransactionsHistoryPage = ({
  classes,
  location,
  match,
  getSsoList,
  getCustomerTransactions,
  accountIds,
  getAccountSummary,
  account,
  config,
  replace,
  isFetching,
  transactions,
}) => {
  let params = new URLSearchParams(location.search);

  const [state, setState] = useState({
    showFilters: false,
    fromDate: params.get("fromDate") ? params.get("fromDate") : getFromDate(),
    toDate: params.get("toDate") ? params.get("toDate") : getToDate(),
    transactionType: params.get("transactionType") ? params.get("transactionType") : "",
    accountOptions: [],
    accountId: "",
    filterError: null,
    url: "",
    effectiveDate: "",
    amount: "",
    description: "",
  });

  const [available, setAvailable] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const { accountId, accountType } = match.params;
    const { fromDate, toDate } = state;
    //getSsoList();
    if (accountId && accountType && fromDate && toDate) {
      getCustomerTransactions({
        uniqueId: accountId,
        accountType: parseInt(accountType, 10),
        type: state.transactionType,
        from: fromDate,
        to: toDate,
      });
      // Init account id, transaction type dropdown when user arrives from share/loan account
      const { value } =
        accountIds &&
        _.find(
          accountIds,
          accountOption => accountId === JSON.parse(accountOption.value).uniqueId,
        );
      getAvailable(value);
      setState({
        ...state,
        accountId: value,
        transactionType: params.get("transactionType")
          ? params.get("transactionType")
          : 0,
      });
    }

    // eslint-disable-next-line
  }, []);
  const getAvailable = async accountId => {
    await getAccountSummary();
    const accountOption =
      JSON.parse(accountId).type === enums.AccountType.SHARE
        ? account.shares.find(item => item.uniqueId === JSON.parse(accountId).uniqueId)
        : account.loans.find(item => item.uniqueId === JSON.parse(accountId).uniqueId);
    setAvailable(accountOption.available);
  };

  const receiptModal = () => {
    const { url, amount, effectiveDate, description } = state;
    return (
      <ReceiptModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        headingText={description}
        receiptImage={url}
        amount={amount}
        effectiveDate={effectiveDate}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  };

  const handleClickCheckImage = async transaction => {
    let index = 0;
    let urlParam;
    let params = config.Check_Images.config.params;
    let urlValue = config.Check_Images.config.url + "?";
    let baseUrl = config.Check_Images.config.url;
    for (urlParam in params) {
      if (index > 0) {
        urlValue += "&";
      }
      if (params[urlParam] === "image_key_after_line") {
        let extraInfo = transaction.extraInfo;
        if (extraInfo.img_key && extraInfo.img_key !== "-") {
          let position = extraInfo.img_key.indexOf("-");
          urlValue +=
            urlParam +
            "=" +
            extraInfo.img_key.substr(position + 1, extraInfo.img_key.length);
        }
      } else if (params[urlParam] === "image_key_before_line") {
        let extraInfo = transaction.extraInfo;
        if (extraInfo.img_key && extraInfo.img_key !== "-") {
          let position = extraInfo.img_key.indexOf("-");
          urlValue += urlParam + "=" + extraInfo.img_key.substr(0, position);
        }
      } else if (params[urlParam] === "tran_amt") {
        urlValue += urlParam + "=" + transaction.amount;
      } else if (params[urlParam] === "chk_number") {
        urlValue += urlParam + "=" + transaction.extraInfo.chk_number;
      } else {
        urlValue += urlParam + "=" + params[urlParam];
      }
      index++;
    }

    const { data } = await post(`${ROOT_URL}/checkTransactionHtml`, {
      transaction,
      config: config.Check_Images.config,
    });
    if (data.success) {
      setState({
        ...state,
        url: data.data,
        amount: transaction.amount,
        effectiveDate: transaction.effectiveDate,
        description: transaction.description,
      });
    } else {
      setState({
        ...state,
        url:
          "<html><head><title>Share Draft Image Error</title></head><p><p>We are unable to provide the image you have requested.  Please contact your credit Union for assistance.</p><p>Please note: If your image cleared today, it may not be available for 24 hours.  Please try again tomorrow.</p></p></html>",
        amount: "",
        effectiveDate: "",
        description: "",
      });
    }

    setIsOpenModal(true);
  };

  const handleChangeFromDate = e => {
    setState({
      ...state,
      fromDate: moment(e.target.value).format("MM/DD/YYYY"),
    });
  };

  const handleChangeToDate = e => {
    setState({
      ...state,
      toDate: moment(e.target.value).format("MM/DD/YYYY"),
    });
    setSubmitForm(true);
  };

  const handleChangeTransactionType = e => {
    setState({
      ...state,
      transactionType: e.target.value,
    });
    setSubmitForm(true);
  };

  const handleApplyFilter = () => {
    const { accountId, transactionType, fromDate, toDate } = state;
    let account = JSON.parse(accountId);
    let params = "";

    if (fromDate) {
      params += `?fromDate=${fromDate}`;
    }

    if (toDate) {
      params += `&toDate=${toDate}`;
    }

    if (transactionType) {
      params += `&transactionType=${transactionType}`;
    }

    replace(`/dashboard/accounts/${account.uniqueId}/${account.type}/history${params}`);
    // this.props.getCustomerTransactions({ uniqueId: accountId === 0 ? null : accountId, accountType: parseInt(this.props.match.params.accountType), type: transactionType, from: moment(fromDate).utc().format('MM/DD/YYYY'), to: moment(toDate).utc().format('MM/DD/YYYY') });
  };

  const [submitForm, setSubmitForm] = useState(false);

  useEffect(() => {
    if (submitForm) {
      handleApplyFilter();
    }

    // eslint-disable-next-line
  }, [submitForm]);

  return (
    <DashboardScreens>
      {receiptModal()}
      <Container role="rowgroup">
        <Loader isLoading={isFetching} />
        <Mobile>
          <div>
            <PageTitle>Transaction History</PageTitle>
            <form className={classes.mobileFormContainer} autoComplete="off">
              <Box column>
                {/* <Select
                  id="accountIds"
                  name="accountId"
                  aria-label="Account Id"
                  label={'Account Id'}
                  value={state.accountId}
                  onChange={(e) => setState({...state, accountId: e.target.value })}
                >
                  {props.accountIds && _.map(props.accountIds, (option, idx) => (
                    <MenuItem key={idx} value={option.value}>{option.key}</MenuItem>
                  ))}
                </Select> */}
                <DatePickerCustom
                  style={{ width: "100%" }}
                  label="From"
                  name="fromDate"
                  value={state.fromDate}
                  onChange={e => handleChangeFromDate(e)}
                />

                <DatePickerCustom
                  style={{ width: "100%" }}
                  label="To"
                  name="toDate"
                  value={state.toDate}
                  onChange={e => handleChangeToDate(e)}
                />
                <Select
                  id="transactionType"
                  name="transactionType"
                  aria-label="Transaction Type"
                  label={"Transaction Type"}
                  value={state.transactionType}
                  onChange={e => setState({ ...state, transactionType: e.target.value })}
                >
                  {_.map(selectTransactionTypes(), (option, idx) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.key.replace("_", " ")}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </form>
            <Box column>
              <label htmlFor>&nbsp;</label>
              <Button primary onClick={handleApplyFilter}>
                Apply Filters
              </Button>
            </Box>
            {/* <Box column>
              <label htmlFor>&nbsp;</label>
              <Button outline onClick={() => props.push('/dashboard/accounts/search')}> Advanced Search</Button>
            </Box> */}
            {/* <Separator style={{ margin: '1em 0 2em 0' }} /> */}
            {/* TODO: It is commented because the core no longer has the pending transactions
            <MobileTable title={'Pending Transactions'}>
              {this.props.transactions.pendingTransactions && this.props.transactions.pendingTransactions.length > 0
                ? (_.map(this.props.transactions.pendingTransactions, ({ id, actualDate, description, transactionDate, amount, settleDate }, key) => (
                  <MobileTableRow key={key}>
                    <div>
                      <MobileTableHeading value={description} />
                      <MobileTableCell name={'Id'} value={id} />
                      <MobileTableCell name={'Created'} value={transactionDate} />
                      <MobileTableCell name={'pending type'} value={''} />
                      <MobileTableCell name={'posting type'} value={''} />
                      <MobileTableCell name={'amount'} value={<CurrencyFormat value={amount} renderText={(text) => <span style={{ color: amount > 0 ? 'inherit' : '#fd6773' }}>{text}</span>} displayType={'text'} thousandSeparator prefix={'$'} />} />
                      <MobileTableCell name={'actual date'} value={actualDate} />
                      <MobileTableCell name={'settle date'} value={settleDate} />
                    </div>
                  </MobileTableRow>
                )))
                : <MobileTableRow><P>{"You don't have pending transactions"}</P></MobileTableRow>
              }
            </MobileTable> 
            <Separator style={{ margin: '1em 0 2em 0' }} />*/}
            <MobileTable title={"TRANSACTIONS"}>
              {transactions.postedTransactions &&
                transactions.postedTransactions.length > 0 ? (
                _.map(transactions.postedTransactions, (item, key) => (
                  <MobileTableRow key={key}>
                    <TransactionRow>
                      <div style={{ width: "70%", marginRight: "5%" }}>
                        <div>{item.effectiveDate}</div>
                        <div
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "200px",
                          }}
                        >
                          {item.description}
                        </div>
                        <div>
                          <CurrencyFormat
                            value={item.amount}
                            displayType={"text"}
                            renderText={text => (
                              <span
                                style={{
                                  color: item.amount > 0 ? "inherit" : "#fd6773",
                                  fontWeight: "bold",
                                }}
                              >
                                {text}
                              </span>
                            )}
                            thousandSeparator
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          textAlign: "end",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {
                          <CurrencyFormat
                            value={item.balance}
                            displayType={"text"}
                            thousandSeparator
                            prefix={"$"}
                          />
                        }
                        {parseInt(match.params.accountType) === enums.AccountType.SHARE &&
                          config.Check_Images && (
                            <div style={{ width: "100%" }}>
                              {item.extraInfo &&
                                item.extraInfo.img_key &&
                                item.extraInfo.img_key !== "-" && (
                                  <ExternalLink
                                    onClick={() => handleClickCheckImage(item)}
                                  />
                                )}
                            </div>
                          )}
                      </div>
                    </TransactionRow>
                    {parseInt(match.params.accountType) === enums.AccountType.LOAN && (
                      <MobileTableCell
                        name={"interest"}
                        value={
                          <CurrencyFormat
                            value={item.interest}
                            displayType={"text"}
                            thousandSeparator
                            prefix={"$"}
                          />
                        }
                      />
                    )}
                    {/* <MobileTableCell name={'draft'} value={draft} />
                      <MobileTableCell name={'principal'} value={<CurrencyFormat value={principal} displayType={'text'} thousandSeparator prefix={'$'} />} />
                      <MobileTableCell name={'fee'} value={<CurrencyFormat value={fee} displayType={'text'} thousandSeparator prefix={'$'} />} />
                      <MobileTableCell name={'comment'} value={comment} /> */}
                    <MobileTableCell
                      name={"balance"}
                      value={
                        <CurrencyFormat
                          value={item.balance}
                          displayType={"text"}
                          thousandSeparator
                          prefix={"$"}
                        />
                      }
                    />
                  </MobileTableRow>
                ))
              ) : (
                <MobileTableRow>
                  <P>{"You don't have posted transactions"}</P>
                </MobileTableRow>
              )}
            </MobileTable>
          </div>
        </Mobile>
        <Desktop>
          <div>
            <TransactionsContainer>
              <PageTitle style={{ fontSize: "26px", marginBottom: "0" }}>
                My Checking
                <p>{match.params.accountId}</p>
              </PageTitle>
              <div>
                <p className="available">Available: </p>
                <h2>
                  <CurrencyFormat
                    value={available}
                    displayType={"text"}
                    thousandSeparator
                    prefix={"$"}
                  />
                </h2>
              </div>
            </TransactionsContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Heading id="gridTransactionHistory">TRANSACTION HISTORY</Heading>
              <FiltersForm>
                <CalendarContainer id="Calendar">
                  <Calendar
                    onClick={() => DatepickerAnimation()}
                    stylesInput={{
                      opacity: 1,
                      margin: "7.5px 10px",
                      width: "17px",
                    }}
                  />
                </CalendarContainer>
                <DatepickerContainer id="DatepickerContainer" className="hide">
                  <Calendar
                    stylesInput={{
                      opacity: 1,
                      margin: "7.5px 10px",
                      width: "17px",
                      cursor: "initial",
                    }}
                  />
                  <DatepickerTransactions
                    label="From"
                    value={state.fromDate}
                    onChange={e => handleChangeFromDate(e)}
                  />

                  <DatepickerTransactions
                    name="toDate"
                    label="To"
                    value={state.toDate}
                    onChange={e => handleChangeToDate(e)}
                  />
                  <Cancel
                    onClick={() => DatepickerAnimation()}
                    stylesInput={{
                      opacity: 1,
                      width: "17px",
                      marginRight: "10px",
                    }}
                  />
                </DatepickerContainer>
                <VerticalSeparator />
                <Select
                  customSize
                  noneborderBottom
                  id="transactionType"
                  name="transactionType"
                  aria-label="Transaction Type"
                  // label={'Transaction Type'}
                  value={state.transactionType}
                  onChange={e => handleChangeTransactionType(e)}
                >
                  {_.map(selectTransactionTypes(), (option, idx) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.key.replace("_", " ")}
                    </MenuItem>
                  ))}
                </Select>
              </FiltersForm>
            </div>
            {/* <div style={{ display: 'flex', width: '50%', margin: '1rem' }}> */}

            {/* <Space /> */}
            {/* <Button outline onClick={() => props.push('/dashboard/accounts/search')}> Advanced Search</Button> */}
            {/* </div> */}
            {/* <Separator style={{ margin: '1rem 0 1.5rem 0' }} /> */}
            <OverflowContent>
              {/* TODO: It is commented because the core no longer has the pending transactions
              <Heading id="gridPendingHistory">Pending Transactions</Heading>
              <Table className={classes.table} aria-labelledby="Pending History">
                <TableHead className={classes.thead}>
                  <TableRow>
                    <CustomTableCell>Description</CustomTableCell>
                    <CustomTableCell>Id</CustomTableCell>
                    <CustomTableCell>Actual Date</CustomTableCell>
                    <CustomTableCell>Pending Type</CustomTableCell>
                    <CustomTableCell>Posting Type</CustomTableCell>
                    <CustomTableCell>Amount</CustomTableCell>
                    <CustomTableCell>Settle Date</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.transactions.pendingTransactions && this.props.transactions.pendingTransactions.length > 0
                    ? (_.map(this.props.transactions.pendingTransactions, (item, key) => (
                      <TableRow key={key}>
                        <CustomTableCell>{item.description}</CustomTableCell>
                        <CustomTableCell>{item.accountId}</CustomTableCell>
                        <CustomTableCell>{moment(item.actualDate).utc().format('MM/DD/YYYY')}</CustomTableCell>
                        <CustomTableCell>{item.pendingType}</CustomTableCell>
                        <CustomTableCell>{item.type}</CustomTableCell>
                        <CustomTableCell>
                          <CurrencyFormat
                            value={item.amount}
                            renderText={(text) => <span style={{ color: item.amount > 0 ? 'inherit' : '#fd6773' }}>{text}</span>}
                            displayType={'text'}
                            thousandSeparator
                            prefix={'$'}
                          />
                        </CustomTableCell>
                        <CustomTableCell>{item.settleDate}</CustomTableCell>
                      </TableRow>
                    )))
                    : <TableRow><CustomTableCell>{"You don't have pending transactions"}</CustomTableCell></TableRow>
                  }
                </TableBody>
              </Table>
              <br /> */}
              <Table className={classes.table} aria-labelledby="gridTransactionHistory">
                <TableHead className={classes.thead}>
                  <TableRow>
                    {/* <CustomTableCell>Id</CustomTableCell> */}
                    <CustomTableCell>Date</CustomTableCell>
                    {/* <CustomTableCell>Actual Date</CustomTableCell> */}
                    <CustomTableCell>Description</CustomTableCell>
                    {parseInt(match.params.accountType) === enums.AccountType.SHARE &&
                      config.Check_Images && <CustomTableCell>Check</CustomTableCell>}
                    <CustomTableCell style={{ textAlign: "right" }}>
                      Amount
                    </CustomTableCell>
                    {/* <CustomTableCell>Draft</CustomTableCell> */}
                    {/* <CustomTableCell>Principal</CustomTableCell> */}
                    {parseInt(match.params.accountType) === enums.AccountType.LOAN && (
                      <CustomTableCell style={{ textAlign: "right" }}>
                        Interest
                      </CustomTableCell>
                    )}
                    {/* <CustomTableCell>Fee</CustomTableCell> */}
                    {/* <CustomTableCell>Comment</CustomTableCell> */}
                    <CustomTableCell style={{ textAlign: "right" }}>
                      Balance
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.postedTransactions &&
                    transactions.postedTransactions.length > 0 ? (
                    _.map(transactions.postedTransactions, (item, key) => (
                      <TableRow key={key}>
                        {/* <CustomTableCell>{item.uniqueId}</CustomTableCell> */}
                        <CustomTableCell>
                          {moment(item.effectiveDate).utc().format("MM/DD/YYYY")}
                        </CustomTableCell>
                        {/* <CustomTableCell>{moment(item.actualDate).utc().format('MM/DD/YYYY')}</CustomTableCell> */}
                        <CustomTableCell className="descriptionHistory">
                          {item.description}
                        </CustomTableCell>
                        {parseInt(match.params.accountType) === enums.AccountType.SHARE &&
                          config.Check_Images && (
                            <CustomTableCell>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                {item.extraInfo &&
                                  item.extraInfo.img_key &&
                                  item.extraInfo.img_key !== "-" && (
                                    <ExternalLink
                                      onClick={() => handleClickCheckImage(item)}
                                    />
                                  )}
                              </div>
                            </CustomTableCell>
                          )}
                        <CustomTableCell style={{ textAlign: "right" }}>
                          <CurrencyFormat
                            value={item.amount}
                            renderText={text => (
                              <span
                                style={{
                                  fontWeight: "300",
                                  color: item.amount > 0 ? "inherit" : "#fd6773",
                                }}
                              >
                                {text}
                              </span>
                            )}
                            displayType={"text"}
                            thousandSeparator
                            prefix={"$"}
                          />
                        </CustomTableCell>
                        {/* <CustomTableCell>
                          {item.draft &&
                            this.props.transactionHistoryChecksSsoList &&
                            _.map(this.props.transactionHistoryChecksSsoList, (sso) => {
                              return (<div style={{ display: 'flex', alignItems: 'center' }}>
                                <ExternalLink key={sso.id} onClick={() => this.handleClickSSOForm(sso.id)} />
                                &nbsp;<span>{item.draft}</span></div>)
                            })
                          }
                        </CustomTableCell> */}
                        {/* <CustomTableCell><CurrencyFormat value={item.principal} displayType={'text'} thousandSeparator prefix={'$'} /></CustomTableCell> */}
                        {parseInt(match.params.accountType) ===
                          enums.AccountType.LOAN && (
                            <CustomTableCell style={{ textAlign: "right" }}>
                              <CurrencyFormat
                                value={item.interest}
                                displayType={"text"}
                                thousandSeparator
                                prefix={"$"}
                              />
                            </CustomTableCell>
                          )}
                        {/* <CustomTableCell><CurrencyFormat value={item.fee} displayType={'text'} thousandSeparator prefix={'$'} /></CustomTableCell> */}
                        {/* <CustomTableCell>{item.comment}</CustomTableCell> */}
                        <CustomTableCell style={{ textAlign: "right" }}>
                          <CurrencyFormat
                            value={item.balance}
                            displayType={"text"}
                            thousandSeparator
                            prefix={"$"}
                          />
                        </CustomTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <CustomTableCell style={{ borderBottom: "none" }}>
                        {"You don't have recent posted transactions"}
                      </CustomTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </OverflowContent>
          </div>
        </Desktop>
      </Container>
    </DashboardScreens>
  );
};

TransactionsHistoryPage.prototype = {
  push: PropTypes.func,
  replace: PropTypes.func,
  classes: PropTypes.object,
  transactions: PropTypes.object,
  config: PropTypes.object,
  pendingTransactions: PropTypes.array,
  postedTransactions: PropTypes.array,
  transactionHistoryChecksSsoList: PropTypes.array,
  accountIds: PropTypes.array,
  isFetching: PropTypes.bool,
  isPost: PropTypes.bool,
  formParams: PropTypes.array,
  redirectUrl: PropTypes.string,
  getCustomerTransactions: PropTypes.func,
  getSsoList: PropTypes.func,
  getSsoRedirectUrl: PropTypes.func,
  getAccountSummary: PropTypes.func,
  // match: PropTypes.object,
};

const mapStateToProps = state => ({
  account: selectAccounts(state),
  transactions: selectTransactions(state),
  accountIds: selectAccountIds(state),
  isFetching: state.account.isFetching,
  redirectUrl: state.sso.redirectUrl,
  isPost: state.sso.isPost,
  formParams: state.sso.formParams,
  transactionHistoryChecksSsoList: state.sso.transactionHistoryChecksSsoList,
  config: state.config,
});

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
  },
  table: {
    minWidth: 700,
    border: "1px solid #EBEFF3",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#FCFCFC",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FCFCFC",
    color: "#999EA6",
    fontSize: "14px",
    padding: "14px 20px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "14px 20px",
    fontSize: "15px",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#2F303E",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCustomerTransactions,
    getAccountSummary,
    push,
    replace,
    getSsoRedirectUrl,
    getSsoList,
  })(TransactionsHistoryPage),
);
