import * as types from "./constants";
const initialState = {
  error: null,
  isFetching: false,
  quickLinks: null,
  externalSiteMessage: null,
  isExternalConfirmationModalOpen: false,
};

export default function linksReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_QUICKLINKS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_QUICKLINKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        quickLinks: payload,
      };
    case types.GET_QUICKLINKS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_EXTERNAL_SITE_MESSAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_EXTERNAL_SITE_MESSAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        externalSiteMessage: payload,
      };
    case types.GET_EXTERNAL_SITE_MESSAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.SHOW_EXTERNAL_CONFIRMATION_MODAL:
      return {
        ...state,
        isFetching: false,
        isExternalConfirmationModalOpen: payload,
      };
    default:
      return state;
  }
}
