import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ButtonBase as MaterialButtonBase } from "@material-ui/core";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
  },
  image: {
    padding: "10px 25px",
    width: "100% !important",
    position: "relative",
    height: "auto",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      height: "auto",
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      "& $imageTitle": {
        border: "4px solid currentColor",
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#3fc78e",
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdropSuccess: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#3fc78e",
    opacity: 0.01,
    transition: theme.transitions.create("opacity"),
  },
  imageBackdropError: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "#cb3737",
    opacity: 0.01,
    transition: theme.transitions.create("opacity"),
  },
});

const ButtonBases = ({ url, width, classes, children, error, ...otherProps }) => {
  return (
    <MaterialButtonBase
      {...otherProps}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      style={{ width }}
    >
      {!!url && (
        <span className={classes.imageSrc} style={{ backgroundImage: `url(${url})` }} />
      )}

      <span
        className={!!error ? classes.imageBackdropError : classes.imageBackdropSuccess}
      />

      {children}
    </MaterialButtonBase>
  );
};

ButtonBases.defaultProps = {
  error: false,
  url: "",
  width: "30%",
};

ButtonBases.propTypes = {
  error: PropTypes.bool,
  classes: PropTypes.object,
  url: PropTypes.string,
  width: PropTypes.string,
};

export default withStyles(styles)(ButtonBases);
