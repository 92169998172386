import React from "react";

import _ from "lodash";
import { push } from "react-router-redux";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { MenuItem } from "../../commons/GridNav";
import { NavLinkWithHover } from "../../commons";

import {
  setActiveItemExpandedMenu,
  setActiveIconColor,
  getSubPath,
} from "../../../utils/sideNav";

const MenuLabel = styled.span`
  display: "flex";
  font-family: "Kumbh Sans";
  font-size: 14px;
  margin-left: 20px;
  opacity: 0.8;
  vertical-align: middle;
  line-height: normal;
`;

const MenuItemCustom = styled(MenuItem)`
  align-items: center;
  padding: 0.7rem 1.5rem;

  svg {
    width: 20px;
    height: 20px;
  }

  a {
    color: #f4f6f8;
    flex: 1;
  }

  .external-link {
    stroke: #ebeef1;
    width: 12px;
    height: 12px;
  }
`;

const ItemExpanded = ({
  displayName,
  on,
  location,
  path,
  subMenu,
  iconSrc,
  externalLink,
  push,
}) => {
  const styleActiveItem = setActiveItemExpandedMenu(location.pathname, path, subMenu);
  const colorActiveIcon = setActiveIconColor(location.pathname, path, subMenu);
  const subPath = getSubPath(location.pathname, subMenu);

  return (
    <MenuItemCustom
      key={`expanded-${displayName}`}
      aria-label={`menu item ${displayName}`}
      role="menuitem"
      on={on}
      style={{ ...styleActiveItem }}
      onClick={() => {
        if (externalLink) {
          window.open(path);
        } else {
          push(subPath ? subPath : path);
        }
      }}
    >
      {iconSrc === "roles" ? (
        <ReactSVG
          src={`../../../../icons/roles.svg`}
          evalScripts="always"
          beforeInjection={svg => {
            svg.setAttribute("style", `color:${colorActiveIcon};`);
          }}
        />
      ) : (
        <FeatherIcon icon={iconSrc} style={{ stroke: colorActiveIcon }} />
      )}
      <NavLinkWithHover
        aria-label={`link ${displayName}`}
        role="link"
        activeStyle={{ color: "#05CE86" }}
        to={subPath ? subPath : externalLink ? "#" : path}
      >
        <MenuLabel>{displayName}</MenuLabel>
      </NavLinkWithHover>

      {externalLink && <FeatherIcon icon="external-link" className="external-link" />}
    </MenuItemCustom>
  );
};

ItemExpanded.prototype = {
  displayName: PropTypes.string,
  on: PropTypes.bool,
  location: PropTypes.object,
  path: PropTypes.string,
  subMenu: PropTypes.object,
  iconSrc: PropTypes.string,
  externalLink: PropTypes.bool,
  push: PropTypes.func,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { push })(ItemExpanded);
