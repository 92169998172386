import React from "react";

import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import FeatherIcon from "feather-icons-react";
import _ from "lodash";

import { Bell, Inbox, Burger, Close } from "../../commons/icons";
import PoupNotifications from "./PoupNotifications";
import PoupMessages from "./PoupMessages";
import { Mobile } from "../../commons/Responsive";
import { IconHover } from "../../commons";
import { HeaderColumn } from "./styles";

const HeaderMobile = ({
  isMobileMenuOpen,
  onOpenMobileMenu,
  push,
  isAdmin,
  popup,
  messages,
  setState,
  handleClickOutside,
  notifications,
}) => {
  return (
    <Mobile>
      <header
        style={{
          display: "flex",
          padding: "0 20px",
          height: 70,
          justifyContent: "space-between",
        }}
        role="banner"
        aria-label="accounts dashboard"
      >
        <HeaderColumn>
          {isMobileMenuOpen ? (
            <Close onClick={() => onOpenMobileMenu(false)} />
          ) : (
            <Burger onClick={() => onOpenMobileMenu(true)} />
          )}
        </HeaderColumn>
        <HeaderColumn fx={5} />
        <HeaderColumn>
          <IconHover>
            <FeatherIcon
              icon="life-buoy"
              style={{
                cursor: "pointer",
                width: "22px",
                height: "22px",
                stroke: "#1f2427",
                display: "flex",
              }}
              onClick={() => push(isAdmin ? "/admin/panel/help" : "/dashboard/help")}
            />
          </IconHover>
        </HeaderColumn>

        {!isAdmin && (
          <HeaderColumn>
            <IconHover>
              <Inbox
                hasPopup
                hasFocus={popup.messages.ariaFocus}
                aria-controls="messagesPopup"
                ariaLabel={`${messages.numberOfMessages} messages`}
                stylesInput={{ width: "22px", height: "22px" }}
                badge={messages.numberOfMessages || 0}
                onClick={() =>
                  setState({
                    popup: {
                      ...popup,
                      messages: {
                        ...popup.messages,
                        isOpen: true,
                        ariaHidden: false,
                        ariaFocus: false,
                      },
                    },
                  })
                }
              />
              <PoupMessages
                popup={popup}
                handleClickOutside={handleClickOutside}
                messages={messages}
                isAdmin={isAdmin}
              />
            </IconHover>
          </HeaderColumn>
        )}

        <HeaderColumn>
          <IconHover>
            <Bell
              hasPopup
              hasFocus={popup.notifications.ariaFocus}
              ariaLabel={`${notifications.numberOfNotifications} notifications`}
              aria-controls="notificationsPopup"
              badge={notifications.numberOfNotifications || 0}
              stylesInput={{ width: "22px", height: "22px" }}
              onClick={() =>
                setState({
                  popup: {
                    ...popup,
                    notifications: {
                      ...popup.notifications,
                      isOpen: true,
                      ariaFocus: false,
                      ariaHidden: false,
                    },
                  },
                })
              }
            />
            <PoupNotifications
              popup={popup}
              handleClickOutside={handleClickOutside}
              notifications={notifications}
              isAdmin={isAdmin}
            />
          </IconHover>
        </HeaderColumn>
      </header>
    </Mobile>
  );
};

HeaderMobile.prototype = {
  onOpenMobileMenu: PropTypes.func,
  isMobileMenuOpen: PropTypes.bool,
  isAdmin: PropTypes.bool,
  notifications: PropTypes.object,
  messages: PropTypes.object,
  push: PropTypes.func,
  popup: PropTypes.object,
  setState: PropTypes.func,
  handleClickOutside: PropTypes.func,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { push })(HeaderMobile);
