module.exports = {
  UserRoles: Object.freeze({
    NONE: 0,
    ADMIN: 1,
    CUSTOMER: 2,
  }),
  UserStatus: Object.freeze({
    NONE: 0,
    BLOCKED: 1,
    SUSPENDED: 2,
  }),
  UserMfaType: Object.freeze({
    NONE: 0,
    CLASSIC: 1,
    SMS: 2,
  }),
  CustomerAccountType: Object.freeze({
    REGULAR_SHARE: 0,
    SHARE_DRAFT: 1,
    ESCROW: 2,
    IRA: 3,
    SPECIAL: 4,
    CLUB: 5,
    CERTIFICATE: 6,
    IRA_CERTIFICATE: 7,
  }),
  CreditCardType: Object.freeze({
    NONE: 0,
    VISA: 1,
    MASTERCARD: 2,
    AMERICAN_EXPRESS: 3,
    OTHER: 99,
  }),
  TransactionStatus: Object.freeze({
    UNDEFINED: 0,
    PENDING: 1,
    POSTED: 2,
  }),
  FromShLnFlag: Object.freeze({
    UNDEFINED: 0,
    SHARE: 1,
    LOAN: 2,
  }),
  ToShLnFlag: Object.freeze({
    UNDEFINED: 0,
    SHARE: 1,
    LOAN: 2,
    CREDIT_CARD: 3,
  }),
  ShLnFlag: Object.freeze({
    NONE: 0,
    SHARE: 1,
    LOAN: 2,
  }),
  TranType: Object.freeze({
    UNDEFINED: 0,
    ATM: 1,
    HOME_BANKING: 2,
    AUDIO_RESPONSE: 3,
  }),
  InterfaceType: Object.freeze({
    NONE: 0,
    HOME_BANKING: 1,
    AUDIO_BANKING: 2,
  }),
  ErrorType: Object.freeze({
    UNDEFINED: "undefined",
    USER_ERROR: "user error",
    LEGACY_API_ERROR: "legacy api error",
    BACKEND_ERROR: "backend internal error",
    DB_ERROR: "db read or write error",
    SMS_ERROR: "sms error",
    ADMIN_ERROR: "admin error",
  }),
  TransferType: Object.freeze({
    UNDEFINED: 0,
    SHARE: 1,
    LOAN: 2,
    CREDIT_CARD: 3,
    MAIL_CHECK: 4,
  }),
  PaymentFromType: Object.freeze({
    UNDEFINED: 0,
    SHARE: 1,
    LOAN: 2,
    CREDIT_CARD: 3,
  }),
  TempObjectType: Object.freeze({
    UNDEFINED: 0,
    RECOVER_PASS_TEMPPASSWORD: 1,
    RECOVER_PASS_SMSCODE: 2,
    REGISTER_PHONE_SMSCODE: 3,
    LOGIN_SMSCODE: 4,
  }),
  MaritalStatus: Object.freeze({
    UNDEFINED: 0,
    SINGLE: 1,
    MARRIED: 2,
    DIVORCED: 3,
    WIDOWED: 4,
  }),
  LoanAppDebtType: Object.freeze({
    UNDEFINED: 0,
    HOUSING_EXPENSES: 1,
    DEBT_OWED_1: 2,
    DEBT_OWED_2: 3,
    CHILD_SUPPORT: 4,
  }),
  LoanAppContactPreference: Object.freeze({
    NONE: 0,
    EMAIL: 1,
    HOME_PHONE: 2,
    WORK_PHONE: 3,
    MOBILE_PHONE: 4,
  }),
  LoanType: Object.freeze({
    UNDEFINED: 0,
    NEW_VEHICLE: 1,
    USED_VEHICLE: 2,
    RECREATIONAL_EQUIPMENT: 3,
    HOME_EQUITY: 4,
    HOME_EQUITY_LINE_OF_CREDIT: 5,
    SHARE_SECURED: 6,
    UNSECURED: 7,
  }),
  LoanAppStep: Object.freeze({
    UNDEFINED: 0,
    LOAN_REQUEST: 1,
    PERSONAL_INFO: 2,
    EMPLOYMENT_INFO: 3,
    DEBT_INFO: 4,
    CONTACT_INFO: 5,
    CO_PERSONAL_INFO: 6,
    CO_EMPLOYMENT_INFO: 7,
    CO_DEBT_INFO: 8,
  }),
  PaymentType: Object.freeze({
    NONE: 0,
    QUICK_PAY: 1,
    AUTO_PAY: 2,
    SCHEDULED_PAY: 3,
  }),
  PaymentStatus: Object.freeze({
    NONE: 0,
    PENDING: 1,
    CANCELED: 2,
    PAID: 3,
  }),
  OwnOrRentHome: Object.freeze({
    RENT: 0,
    OWN: 1,
  }),
  AccountType: Object.freeze({
    UNDEFINED: 0,
    SHARE: 1,
    LOAN: 2,
    CREDIT_CARD: 3,
    SHARE_DRAFT: 4,
  }),
  CruisenetResponseResult: Object.freeze({
    NOT_SUCCESSFUL: "Result_NOT_SUCCESSFUL",
    SUCCESSFUL_WITH_DATA: "Result_SUCCESSFUL_WITH_DATA",
    INVALID_ACCT_CREDENTIALS: "Result_NOT_SUCCESSFUL_INVALID_CREDENTIALS",
    SUCCESSFUL_NO_DATA: "Result_SUCCESSFUL_NO_DATA",
  }),
  CruisenetResponseStatus: Object.freeze({
    OK: "Status_OK",
    INVALID_CREDENTIALS: "Status_INVALID_CREDENTIALS",
    INVALID_FROM_ACCOUNT: "Status_INVALID_FROM_ACCOUNT",
    INVALID_TO_ACCOUNT: "Status_INVALID_TO_ACCOUNT",
    INVALID_DATA_PASSED: "Status_INVALID_DATA_PASSED",
    MISSING_REQUIRED_DATA: "Status_MISSING_REQUIRED_DATA",
    TRANSACTION_BLOCKED_BY_REGULATION_D: "Status_REG_D_BLOCKED_ACCOUNT_XFER",
    TO_ACCOUNT_DEPOSITS_NOW_ALLOWED: "Status_TO_ACCOUNT_DEPOSITS_NOT_ALLOWED",
    PENDING_PAYMENTS_EXIST: "Status_PENDING_PAYMENTS_EXIST",
    PAYMENT_DATE_HAS_PASSED: "Status_PAYMENT_DATE_HAS_PASSED",
  }),
  MemberShareLoanNumbers_Purpose: Object.freeze({
    NONE: 0,
    TRANSACTION_SEARCH: 1,
    ACCOUNT_SUMMARY_SEARCH: 2,
    FUNDS_TRANSFER_IN: 3,
    FUNDS_TRANSFER_OUT: 4,
    SAVED_SEARCHS: 5,
    QUICKEN_SEARCH: 6,
    BILL_PAY_SEARCH: 7,
  }),
  ShrType: Object.freeze({
    REGULAR_SHARE: 0,
    SHARE_DRAFT: 1,
    ESCROW: 2,
    IRA: 3,
    SPECIAL: 4,
    CLUB: 5,
    CERTIFICATE: 6,
    IRA_CERTIFICATE: 7,
  }),
  TransactionSearchType: Object.freeze({
    ALL_TRANSACTIONS: 0,
    ATM_Withdrawal: 1,
    ACH_Deposit: 2,
    Withdraw_Transfer: 3,
    ACH_Withdrawal: 4,
    Share_Draft: 5,
    Deposit_Transfer: 6,
    Payment_Transfer: 7,
    Disburse_Transfer: 8,
    // DEPOSIT_TRANSACTIONS: 1,
    // WITHDRAWAL_TRANSACTIONS: 2,
    // CLEARED_CHECKS: 3,
    // ETF_TRANSACTIONS: 4,
    // ATM_TRANSACTIONS: 5,
    // DEBIT_CARD_TRANSACTIONS: 6,
    // CREDIT_CARD_TRANSACTIONS: 7,
    // DIVIDEND_TRANSACTIONS: 8,
    // LOAN_PAYMENT_TRANSACTIONS: 9,
    // LOAN_ADVANCE_TRANSACTIONS: 10
  }),
  SlFlag: Object.freeze({
    NONE: 0,
    SHARE: 1,
    LOAN: 2,
  }),
  ShareLoanSearchType: Object.freeze({
    RETRIEVE_ALL_HISTORY: 0,
    RETRIEVE_FOR_SPECIFIC_ACCOUNT: 1,
  }),
  QuickPaySortOrder: Object.freeze({
    INVALID: 0,
    PAYEE: 1,
    SHARE_NUMBER: 2,
    LAST_PAYMENT_DATE: 3,
  }),
  PayeeMethod: Object.freeze({
    UNKNOWN: "Unknown",
    CHECK: "Check",
    ELECTRONIC: "Electronic",
  }),
  XfrrCode: Object.freeze({
    ALL_TRANSACTIONS_ALLOWED: 0,
    WITHDRAWALS_ONLY: 1,
    DEPOSITS_ONLY: 2,
    NO_TRANSACTIONS_ALLOWED: 3,
    DO_NOT_DISPLAY: 4,
  }),
  AutoPayFrequency: Object.freeze({
    WEEKLY: 0,
    BI_WEEKLY: 1,
    QUAD_WEEKLY: 2,
    MONTHLY: 3,
    SEMI_MONTHLY: 4,
    BI_MONTHLY: 5,
    QUATERLY: 6,
    SEMI_ANNUAL: 7,
    YEARLY: 8,
  }),
  PayeeTypeCode: Object.freeze({
    UNKNOWN: "",
    CREATED_BY_CU: "CU",
    CREATED_BY_ME: "MY",
  }),
  ScheduledPaymentOrigin: Object.freeze({
    UNKNOWN: "",
    QUICK_PAY: "Quick Pay",
    AUTO_PAY: "Auto Pay",
  }),
  QuickPayToAutoPayMethod: Object.freeze({
    CONTINUOUS: "continuous",
    FIXED: "fixed",
  }),
  SsoShowOnSection: Object.freeze({
    UNDEFINED: 0,
    CREDIT_CARD_ROWS: 1,
    QUICK_LINKS: 2,
    ACCOUNTS_BUTTONS: 3,
    FORMS: 4,
    TRANSACTION_HISTORY_CHECKS: 5,
  }),
  ssoType: Object.freeze({
    UNDEFINED: 0,
    PSCUFS_INFOLINKPLUS: 1,
    MVI_EVIEW: 2,
    AGILITYORG_ESTATEMENTS: 3,
    AGILITYORG_CONTACT_ADDRESS_CHANGE: 4,
    AGILITYORG_WIRE_TRANSFER: 5,
    AGILITYORG_LOAN_APPLICATION: 6,
    AGILITYORG_HUNGER_HERO: 7,
    AGILITYORG_TRAVEL_NOTIFICATION: 8,
    PALMETO_CHECK_IMAGE_VIEWER: 9,
  }),
  TextCommandActions: Object.freeze({
    UNKNOWN: "",
    GET_BALANCES: "B",
    GET_HISTORY: "H",
    FUNDS_TRANSFER: "X",
    FUNDS_TRANSFER_BETWEEN_TWO_ACCOUNTS: "XB",
  }),
  MessageTypes: Object.freeze({
    UNDEFINED: 0,
    INFO: 1,
    BANNER: 2,
  }),
  GoalTypes: Object.freeze({
    UNDEFINED: 0,
    SAVING: 1,
    LOAN: 2,
  }),
  AuthType: Object.freeze({
    UNKNOWN: 0,
    LOGIN: 1,
    RECOVER_PASS_EMAIL: 2,
    RECOVER_PASS_SMS: 3,
    RECOVER_PASS: 4,
  }),
  LegacySystemType: Object.freeze({
    UNDEFINED: 0,
    CRUISENET: 1,
  }),
  SavedSearch_ShrLnType: Object.freeze({
    UNDEFINED: "",
    SHARE: "share",
    LOAN: "loan",
  }),
  FinancialInstitutionSecuredFormsCustomerStatus: Object.freeze({
    UNREAD: "UNREAD",
    READ: "READ",
    FAVORITE: "FAVORITE",
    ARCHIVED_FAVORITE: "ARCHIVED_FAVORITE",
    ARCHIVED_READ: "ARCHIVED_READ",
    ARCHIVED_UNREAD: "ARCHIVED_UNREAD",
  }),
  SortFormsType: Object.freeze({
    UNREAD_FIRST: "UNREAD_FIRST",
    A_TO_Z: "A_TO_Z",
  }),
  FinancialInstitutionSecuredFormsCustomerSource: Object.freeze({
    WEBSITE: "WEBSITE",
    BANKING_APP: "BANKING_APP",
  }),
};
