import React, { useState } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";

const OptionContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const ItemSvg = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const OptionInnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 7.5px;
`;

const Title = styled.span`
  width: 100%;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 18px;
  color: #1f2427;
`;

const Description = styled.span`
  width: 100%;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 12px;
  color: #a5a7a9;
  line-height: 1.2;
`;

const Oval = styled.div`
  border: solid 2px #3fc78e;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  & > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3fc78e;
  }
`;

function AdminCheckbox({ id, title, description, setClickedItem, active }) {
  const handleInactive = () => {
    setClickedItem(null);
  };

  const handleActive = () => {
    setClickedItem(id);
  };

  return (
    <OptionContainer>
      <ItemSvg>
        {active ? (
          <Oval onClick={handleInactive}>
            <div></div>
          </Oval>
        ) : (
          <FeatherIcon icon="disc" size="16" stroke="#A4A7A9" onClick={handleActive} />
        )}
      </ItemSvg>
      <OptionInnerContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </OptionInnerContainer>
    </OptionContainer>
  );
}

export default AdminCheckbox;
