import React, { useState } from "react";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const verifyInputTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#50cd96",
    },
    secondary: {
      main: "#50cd96",
    },
  },
});

const useStyles = makeStyles({
  root: ({
    borderRadius = 8,
    borderWidth = 1,
    borderColor = undefined,
    height = 0,
    width = 0,
  }) => ({
    height,
    width,

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius,
      borderWidth,
      borderColor,
    },
  }),
});

const OneValueTextField = ({
  value = "",
  onChange = () => null,
  onKeyPress = () => null,
  size: width = 48,
  heightFactor = 1.34,
  borderRadius = 8,
  padding = 0,
  color = "#3fc78e",
  error = false,
  success = false,
  ...lessProps
}) => {
  const height = width * heightFactor;

  const [touched, setTouched] = useState(false);

  const classes = useStyles({
    borderRadius,
    borderWidth: success ? 2 : 1,
    borderColor: success ? color : undefined,
    height,
    width,
  });

  const handleToggleTouched = event => {
    if (!touched) setTouched(!touched);
  };

  return (
    <ThemeProvider theme={verifyInputTheme}>
      <TextField
        {...lessProps}
        error={error && touched}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        className={classes.root}
        onFocus={handleToggleTouched}
        onClick={handleToggleTouched}
        inputProps={{
          style: {
            height,
            width,
            padding,
            borderRadius,
            color,
            fontSize: 28,
            fontFamily: '"Kumbh Sans", sans-serif',
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "center",
          },
        }}
      />
    </ThemeProvider>
  );
};

export default OneValueTextField;
