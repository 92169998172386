import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import CurrencyFormat from "react-currency-format";
import { ArrowDown } from "../../../components/commons/icons";
import { Popup } from "../../../components/commons";
import CardAccounts from "./CardAccounts";
import { FormHelperText } from "@material-ui/core";
import { Fragment } from "react";

const ContainerCard = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 5px;
  cursor: pointer;

  .selected {
    width: 100%;
    display: flex;
    .description {
      display: block;
      color: #1b2846;
      font-family: Kumbh Sans;
      font-size: 14px;
      width: 100%;
      line-height: 1.5;
    }

    .account {
      display: block;
      color: #c9d0d4;
      font-family: Kumbh Sans;
      font-size: 12px;
      width: 100%;
      line-height: 1.5;
    }
    .descriptionBox {
      width: 80%;
    }
    .available {
      width: 20%;
      text-align: end;
      padding-right: 10px;
    }
  }
`;

const SelectCustom = props => {
  const { selected, options, onClickItemSelect, error, styleContainer } = props;
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  return (
    <>
      <ContainerCard
        id="selectnombre"
        style={{
          borderBottom: error ? "2px solid #f44336;" : "1px solid #18243C",
          ...styleContainer,
        }}
      >
        <div
          className="selected"
          style={{}}
          aria-controls="customSelect"
          onClick={() => setIsOpenSelect(true)}
        >
          <div className="descriptionBox">
            {selected.imgDescription ? (
              <span>
                <img src={selected.description} alt="ImageItemSelect" />
              </span>
            ) : (
              <span className="description">
                {selected.description || selected.defaultDescription}
              </span>
            )}
            <span className="account">{selected.uniqueId}</span>
          </div>
          <div className="available">
            {selected.available !== undefined && (
              <>
                <span className="account">Available</span>
                <span className="description">
                  <CurrencyFormat
                    value={selected.available}
                    displayType={"text"}
                    thousandSeparator
                    prefix={"$"}
                  />
                </span>
              </>
            )}
          </div>
        </div>
        <Popup
          id="selectCustom"
          role="listbox"
          aria-labelledby="selectCustom"
          aria-label="selectCustom"
          aria-expanded={isOpenSelect}
          isOpen={isOpenSelect}
          onClose={() => setIsOpenSelect(false)}
          style={{
            left: "0px",
            width: "100%",
            borderRadius: "12px",
            top: "auto",
            bottom: "10%",
            minHeight: "38px",
            maxHeight: "40vh",
            overflowY: "auto",
          }}
        >
          <div>
            {options &&
              _.map(options, item =>
                item.display ? (
                  <Fragment key={item.id}>
                    {item.display
                      ? item.allowTransactionsFrom && (
                          <CardAccounts
                            img={item.imgDescription}
                            description={item.description || item.defaultDescription}
                            numberAccount={item.uniqueId}
                            amount={item.available}
                            isSelected={selected.uniqueId === item.uniqueId}
                            style={{ width: "auto" }}
                            onClick={e => {
                              if (onClickItemSelect(e, item)) {
                                setIsOpenSelect(false);
                              }
                            }}
                          />
                        )
                      : null}
                  </Fragment>
                ) : (
                  ""
                ),
              )}
          </div>
        </Popup>
        <ArrowDown
          styles={{
            alignItems: "center",
            display: "flex",
          }}
          stylesInput={{ width: "22px", height: "22px" }}
          onClick={() => setIsOpenSelect(true)}
        />
      </ContainerCard>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default SelectCustom;
