import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import styled from "styled-components";
import TextField from "../../components/commons/TextField";
import { Heading, P, Button, Loader } from "../../components/commons";
import { ArrowBack } from "../../components/commons/icons";
import { loginAsMemberEview } from "./actions";

const Content = styled.div`
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 3px;

  @media (max-width: 967px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media (min-width: 968px) {
    justify-content: start;

    & > form > div {
      width: 95%;
    }
    & > form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & > form > button {
      margin: 1rem 0 1rem 16px;
    }
  }
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;

  & > small {
    color: #05ce86;
  }

  @media (max-width: 967px) {
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

class MemberEview extends Component {
  static propTypes = {
    loginAsMemberEview: PropTypes.func,
    goBack: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
  };
  state = { accountNumber: "" };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (this.state.accountNumber !== "") {
        this.props.loginAsMemberEview(this.state.accountNumber);
      } else {
        this.setState({ error: "Please fill the form" });
      }
    }
  };
  render() {
    return (
      <div
        style={{
          marginTop: "10%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader isloading={this.props.isFetching} />
        <Content>
          <Heading>Member Log In</Heading>
          <P>Please enter the member&apos;s username and continue</P>
          <P>
            <strong>PLEASE NOTE:</strong>&nbsp;Do not open multiple account numbers at the
            same time
          </P>
          <form>
            <TextField
              onChange={event => this.setState({ accountNumber: event.target.value })}
              value={this.state.accountNumber}
              autoFocus
              onKeyPress={this.handleKeyPress}
              type="text"
              name="accountNumber"
              id="accountNumber"
              error={!!this.state.error || !!this.props.error}
              helperText={this.state.error || this.props.error}
              label={"Username"}
            />
            <Button
              primary
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (this.state.accountNumber !== "") {
                  this.props.loginAsMemberEview(this.state.accountNumber);
                } else this.setState({ error: "please fill the form" });
              }}
            >
              Continue
            </Button>
          </form>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <BackButton>
              <ArrowBack onClick={() => this.props.goBack()} />
              <small>GO BACK</small>
            </BackButton>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.admin.isFetching,
  error: state.admin.error,
});
export default connect(mapStateToProps, { loginAsMemberEview, goBack })(MemberEview);
