import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import styled from "styled-components";
import { Check, Cancel, Shield, User, Phone } from "../../../components/commons/icons";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import { SuccessModal, ConfirmationModal } from "../../../components/commons/Modals";
import {
  MobileTable,
  MobileTableButtonGroup,
  MobileTableButton,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import Modal from "react-modal";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
} from "@material-ui/core";
import TextField from "../../../components/commons/TextField";
import {
  Button,
  Separator,
  Loader,
  Box,
  P,
  B,
  Heading,
  PageTitle,
} from "../../../components/commons";
import * as actions from "./actions";
import { selectActivePhones } from "./selectors";
import styles from "./styles.module.css";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#F9F9F9",
    color: "#7A7A7A",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    padding: "1rem",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "0.5rem 1rem",
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#7A7A7A",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

const Container = styled.div``;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById("app"));

class TextBanking extends Component {
  static propTypes = {
    insertCustomerPhoneStep1: PropTypes.func,
    insertCustomerPhoneStep2: PropTypes.func,
    openRemovePhoneModal: PropTypes.func,
    closeRemovePhoneModal: PropTypes.func,
    removeCustomerPhone: PropTypes.func,
    getTextBankingDisclosure: PropTypes.func,
    removePhoneModal: PropTypes.bool,
    isFetching: PropTypes.bool,
    push: PropTypes.func,
    error: PropTypes.string,
    classes: PropTypes.object,
    config: PropTypes.object,
    customerPhones: PropTypes.array,
    commands: PropTypes.array,
    disclosure: PropTypes.string,
  };
  state = {
    modalRegisterPhoneIsOpen: false,
    modalConfirmCodeIsOpen: false,
    modalRegisterPhoneSuccessIsOpen: false,
    modalDisclosureIsOpen: false,
    phoneNumber: "",
    ssn: "",
    smsCode: "",
    nickname: "",
    phoneToRemove: null,
  };
  async componentDidMount() {
    try {
      await Promise.all([this.props.getCustomerPhones(), this.props.getCommands()]);
    } catch (err) {
      throw err;
    }
  }

  handleRemovePhone(number) {
    this.setState({ phoneToRemove: null });
    this.props.removeCustomerPhone(number);
  }

  handleOpenRemovePhoneModal(mobile) {
    this.setState({ phoneToRemove: mobile });
    this.props.openRemovePhoneModal();
  }

  handleCloseRemovePhoneModal = () => {
    this.setState({ phoneToRemove: null });
    this.props.closeRemovePhoneModal();
  };

  openRegisterPhoneModal = async () => {
    const { getTextBankingDisclosure } = this.props;
    await Promise.all([getTextBankingDisclosure()]);
    this.setState({
      modalRegisterPhoneIsOpen: true,
      modalDisclosureIsOpen: false,
      modalConfirmCodeIsOpen: false,
      phoneNumber: "",
      ssn: "",
      nickname: "",
    });
  };

  openConfirmCodeModal = () => {
    this.setState({ modalConfirmCodeIsOpen: true });
  };

  // afterOpenModal = () => {
  //   // references are now sync'd and can be accessed.
  //   this.subtitle.style.color = '#f00';
  // };

  closeRegisterPhoneModal = () => {
    this.setState({ modalRegisterPhoneIsOpen: false, error: null });
  };

  closeDisclosureModal = () => {
    this.setState({
      modalDisclosureIsOpen: false,
      modalRegisterPhoneIsOpen: true,
      error: null,
    });
  };

  closeConfirmCodeModal = () => {
    this.setState({ modalConfirmCodeIsOpen: false, error: null });
  };

  closeSuccessModal = () => {
    this.setState({ modalRegisterPhoneSuccessIsOpen: false, error: null });
  };

  confirmPhone = async () => {
    const { phoneNumber, ssn, nickname } = this.state;
    await this.props.insertCustomerPhoneStep1({ phoneNumber, ssn, nickname });
    if (this.props.error) {
      this.setState({ error: this.props.error });
    } else {
      this.setState({
        modalDisclosureIsOpen: false,
        modalConfirmCodeIsOpen: true,
        error: null,
      });
    }
  };

  showDisclosureModal = () => {
    const { phoneNumber, ssn, nickname } = this.state;
    if (!phoneNumber || !ssn || !nickname) {
      this.setState({ error: "Please, complete the form" });
    } else {
      this.setState({
        modalRegisterPhoneIsOpen: false,
        modalDisclosureIsOpen: true,
        error: null,
      });
    }
  };

  registerPhone = async () => {
    if (this.state.smsCode !== "") {
      await this.props.insertCustomerPhoneStep2(this.state.smsCode);
      if (this.props.error) {
        this.setState({ error: this.props.error });
      } else {
        this.setState({
          smsCode: "",
          modalConfirmCodeIsOpen: false,
          modalRegisterPhoneSuccessIsOpen: true,
          error: null,
        });
      }
    }
    this.setState({ error: "please complete the form" });
  };

  renderRegisterPhoneModal() {
    return (
      <ConfirmationModal
        isOpen={this.state.modalRegisterPhoneIsOpen}
        onClose={this.closeRegisterPhoneModal}
        onSuccess={this.showDisclosureModal}
        headingText="Mobile Phone Registration"
        confirmationText={
          <P>
            Please enter your 10 digit wireless phone number (with no dashes), and then
            the last 4 digits of your Social Security Number. You can register an
            aditional phone or phones by choosing <B>Register Phone</B>&nbsp;again.
          </P>
        }
        cancelButtonText="Cancel"
        successButtonText="Confirm"
        aria-hidden={this.state.modalConfirmCodeIsOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <form
          style={{ display: "flex", flexDirection: "column", padding: "1rem 1.5rem" }}
        >
          <TextField
            onChange={event => this.setState({ phoneNumber: event.target.value })}
            value={this.state.phoneNumber}
            name="mobileNumber"
            id="mobileMobile"
            type="number"
            maxLength={12}
            error={this.state.error}
            // helperText={this.state.error}
            label={"Mobile Number"}
            startAdornment={
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            }
          />
          <TextField
            onChange={event => this.setState({ ssn: event.target.value })}
            value={this.state.ssn}
            type="text"
            name="ssnDigits"
            id="ssnDigits"
            maxLength="4"
            error={this.state.error}
            // helperText={this.state.error}
            label={"last four digits of SSN"}
            startAdornment={
              <InputAdornment position="start">
                <Shield />
              </InputAdornment>
            }
          />
          <TextField
            onChange={event => this.setState({ nickname: event.target.value })}
            value={this.state.nickname}
            type="text"
            name="nickname"
            id="nickname"
            error={this.state.error}
            helperText={this.state.error}
            label={"Nickname"}
            startAdornment={
              <InputAdornment position="start">
                <User />
              </InputAdornment>
            }
          />
        </form>
      </ConfirmationModal>
    );
  }

  renderDisclosureModal() {
    return (
      <ConfirmationModal
        isOpen={this.state.modalDisclosureIsOpen}
        onClose={this.closeDisclosureModal}
        onSuccess={this.confirmPhone}
        headingText="Text Banking Disclosure"
        confirmationText={<P>{this.props.disclosure}</P>}
        cancelButtonText="Back"
        successButtonText="Accept"
        aria-hidden={this.state.modalConfirmCodeIsOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <P style={{ color: "red" }}>{this.state.error || ""}</P>
      </ConfirmationModal>
    );
  }

  renderConfirmCodeModal() {
    return (
      <ConfirmationModal
        isOpen={this.state.modalConfirmCodeIsOpen}
        onClose={this.closeConfirmCodeModal}
        onSuccess={this.registerPhone}
        headingText="Mobile Phone Confirmation"
        confirmationText={
          <P>
            Please type the 4 digit confirmation code sent to the mobile phone you are
            registering.
          </P>
        }
        cancelButtonText="Cancel"
        successButtonText="Register Phone"
        aria-hidden={this.state.modalConfirmCodeIsOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 1.5rem 1rem 1.5rem",
          }}
        >
          <TextField
            onChange={event => this.setState({ smsCode: event.target.value })}
            value={this.state.smsCode}
            type="text"
            name="smsCode"
            maxLength={4}
            id="smsCode"
            error={this.state.error}
            helperText={this.state.error}
            label={"SMS Code"}
          />
        </form>
      </ConfirmationModal>
    );
  }

  renderRemovePhoneModal() {
    return (
      <ConfirmationModal
        isOpen={this.props.removePhoneModal}
        onClose={this.handleCloseRemovePhoneModal}
        onSuccess={() => this.handleRemovePhone(this.state.phoneToRemove)}
        headingText="Delete Registered Phone"
        confirmationText={
          <P>
            Are you sure you want to delete this registered phone? Phone:{" "}
            {this.state.phoneToRemove && (
              <P style={{ color: "#66CBFF", fontWeight: "400" }}>
                {this.state.phoneToRemove}
              </P>
            )}
          </P>
        }
        cancelButtonText="Cancel"
        successButtonText="Confirm"
        aria-hidden={this.state.modalConfirmCodeIsOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  renderRegisterPhoneSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.state.modalRegisterPhoneSuccessIsOpen}
        onClose={this.closeSuccessModal}
        headingText="Mobile Phone registered successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  render() {
    const { classes, isFetching, commands } = this.props;
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={isFetching} />
          {this.renderRegisterPhoneModal()}
          {this.renderConfirmCodeModal()}
          {this.renderRegisterPhoneSuccessModal()}
          {this.renderRemovePhoneModal()}
          {this.renderDisclosureModal()}
          <PageTitle>Text Banking</PageTitle>
          <Box>
            <Box fx={1}>
              <P>
                Registering for text message banking is quick and easy. Simply click on
                the <B>Register Phone</B> link below and once that is complete, click the{" "}
                <B>Linked Account</B> button. After registering, you will be able to
                retrieve your account information by sending text message commands to{" "}
                <B>{this.props.config && this.props.config.fiTextBankingNumber} </B> from
                your wireless phone. The available commands are listed below.
                <br />
                <br />
                <span style={{ color: "#66CBFF", fontWeight: "400" }}>Note:</span> Opting
                in to receive free <B>E-Statements</B> is a requirement for text message
                banking. If you have not done so already, please{" "}
                <a href="#any" style={{ fontWeight: "400" }}>
                  click here
                </a>{" "}
                and return to this section to complete your registration.
              </P>
            </Box>
            <Box className={styles.desktopFx} />
          </Box>
          <Separator style={{ marginBottom: "1.5rem" }} />
          <Mobile>
            <div>
              <Box column>
                <Button primary onClick={this.openRegisterPhoneModal}>
                  Register Phone
                </Button>
              </Box>
              <Separator style={{ margin: "1.5rem 0" }} />
              <MobileTable title={"registered phones"}>
                {this.props.customerPhones && this.props.customerPhones.length > 0 ? (
                  _.map(this.props.customerPhones, p => (
                    <MobileTableRow key={p.id}>
                      <Box column fx={1}>
                        <Box fx={1}>
                          <div style={{ flex: 1 }}>
                            <MobileTableHeading value={p.nickname} />
                            <MobileTableCell name={"Phone number"} value={p.mobile} />
                            <MobileTableCell name={"nickname"} value={p.nickname} />
                          </div>
                        </Box>
                        <div>
                          <MobileTableButtonGroup>
                            <MobileTableButton
                              onClick={() =>
                                this.props.push(
                                  `/dashboard/text-banking/phone-usage/${p.mobile}`,
                                )
                              }
                            >
                              <p>USAGE</p>
                            </MobileTableButton>
                            <span>&nbsp;</span>
                            <MobileTableButton
                              onClick={() =>
                                this.props.push(
                                  `/dashboard/text-banking/linked-accounts/${p.id}`,
                                )
                              }
                            >
                              <p>LINKED ACCT</p>
                            </MobileTableButton>
                            <span>&nbsp;</span>
                            <MobileTableButton
                              onClick={() => this.handleOpenRemovePhoneModal(p.mobile)}
                              danger
                            >
                              <p>DELETE</p>
                            </MobileTableButton>
                          </MobileTableButtonGroup>
                          <br />
                        </div>
                      </Box>
                    </MobileTableRow>
                  ))
                ) : (
                  <MobileTableRow>
                    <P>{"You don't have any phones"}</P>
                  </MobileTableRow>
                )}
              </MobileTable>
              <Separator style={{ margin: "1em 0 2em 0" }} />
              <MobileTable title={"banking commands"}>
                {commands && commands.length > 0 ? (
                  _.map(commands, ({ id, command, action }) => (
                    <MobileTableRow key={id}>
                      <div>
                        <MobileTableCell name={"command"} value={command} />
                        <MobileTableCell name={"action"} value={action} />
                      </div>
                    </MobileTableRow>
                  ))
                ) : (
                  <MobileTableRow>
                    <P>{"You don't have comands"}</P>
                  </MobileTableRow>
                )}
              </MobileTable>
              <br />
              <br />
            </div>
          </Mobile>
          <Desktop>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 2 }}>
                <Box>
                  <Button primary onClick={this.openRegisterPhoneModal}>
                    Register Phone
                  </Button>
                </Box>
                <Separator style={{ margin: "1.5rem 0 3rem 0" }} />
                <Heading>Registered phones for Text Messaging</Heading>
                <Table className={classes.table}>
                  <TableHead className={classes.thead}>
                    <TableRow>
                      <CustomTableCell>Phone Number</CustomTableCell>
                      <CustomTableCell>Nickname</CustomTableCell>
                      <CustomTableCell>Usage</CustomTableCell>
                      <CustomTableCell>Linked Accounts</CustomTableCell>
                      <CustomTableCell>Delete</CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.customerPhones && this.props.customerPhones.length > 0 ? (
                      _.map(this.props.customerPhones, p => (
                        <TableRow key={p.id}>
                          <CustomTableCell>{p.mobile}</CustomTableCell>
                          <CustomTableCell>{p.nickname}</CustomTableCell>
                          <CustomTableCell>
                            <Button
                              md
                              primary
                              onClick={() =>
                                this.props.push(
                                  `/dashboard/text-banking/phone-usage/${p.mobile}`,
                                )
                              }
                            >
                              View
                            </Button>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Button
                              md
                              primary
                              onClick={() =>
                                this.props.push(
                                  `/dashboard/text-banking/linked-accounts/${p.id}`,
                                )
                              }
                            >
                              View
                            </Button>
                          </CustomTableCell>
                          <CustomTableCell>
                            <Cancel
                              onClick={() => this.handleOpenRemovePhoneModal(p.mobile)}
                            />
                          </CustomTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <CustomTableCell>{"You don't have any phones"}</CustomTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <br />
                <Heading>Text Message Banking Commands</Heading>
                <P>
                  <span style={{ color: "#66CBFF", fontWeight: "400" }}>Note:</span> These
                  commands wont be recognized if you have a signature at the end of your
                  text.
                </P>
                <Table className={classes.table}>
                  <TableHead className={classes.thead}>
                    <TableRow>
                      <CustomTableCell>Command</CustomTableCell>
                      <CustomTableCell>Action</CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commands ? (
                      _.map(commands, item => (
                        <TableRow key={item.id}>
                          <CustomTableCell>{item.command}</CustomTableCell>
                          <CustomTableCell>{item.action}</CustomTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <CustomTableCell>{"You don't have any commands"}</CustomTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <div style={{ flex: 1 }}></div>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = ({ textBanking, config }) => ({
  isFetching: textBanking.isFetching,
  removePhoneModal: textBanking.removePhoneModal,
  commands: textBanking.commands,
  customerPhones: selectActivePhones(textBanking),
  error: textBanking.error,
  disclosure: textBanking.disclosure,
  config: config.config,
});

export default withStyles(tableStyles)(
  connect(mapStateToProps, { ...actions, push })(TextBanking),
);
