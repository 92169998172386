/**
 *
 * Login Container
 *
 */
import React from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import PropTypes from "prop-types";
import AdminFirstStepAuth from "./AdminFirstStep";
import {
  handleAuthNavigation,
  onError,
  requestAdminPage,
  setToken,
  validateAdminFirstStep,
  validateAdminSecondStep,
  validateAdminThirdStep,
} from "./actions";
import { doSendConfirmationCode } from "../Auth/actions";
import { AuthBox, AuthContainer, Grid } from "../../components/commons/LoginStyles";
import { VerificationCodePage } from "../Auth/steps";
import AdminSecondStepAuth from "./AdminSecondStep";

class AdminAuthPage extends React.Component {
  static defaultProps = {};
  static propTypes = {
    currentStep: PropTypes.string,
    message: PropTypes.string,
    validateAdminFirstStep: PropTypes.func,
    validateAdminSecondStep: PropTypes.func,
    doSendConfirmationCode: PropTypes.func.isRequired,
    setToken: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    validateAdminThirdStep: PropTypes.func,
    replace: PropTypes.func,
    handleAuthNavigation: PropTypes.func,
    requestAdminPage: PropTypes.func,
    phrase: PropTypes.string,
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool,
    mfa: PropTypes.string,
    isSucessUserName: PropTypes.bool,
  };

  state = {
    rememberDevice: false,
    showAdmin: false,
    username: "",
    password: "",
  };

  async componentDidMount() {
    try {
      await this.props.requestAdminPage(this.setState);

      const rememberDevice = !!localStorage.getItem("rememberDevice");

      this.setState({ ...this.state, rememberDevice });
    } catch (err) {
      this.props.replace("/404");
    }

    if (this.props.message && this.props.message === "allowed") {
      this.setState({ showAdmin: true });
    }

    if (this.props.message === "not allowed") {
      this.props.replace("/404");
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.message && nextProps.message === "allowed") {
      this.setState({ showAdmin: true });
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.message === "not allowed") {
      this.props.replace("/404");
    }
  }

  validateAdminFirstStep = username => {
    this.props.validateAdminFirstStep(username);
  };

  validateAdminSecondStep = () => {
    const { username, password } = this.state;

    this.props.validateAdminSecondStep(
      password,
      username,
      async ({ error, data, success = false, handlers = {} }) => {
        if (!!success && !!data && !!data.token && !!data.iat && !error) {
          this.props.setToken({
            iat: data.iat,
            token: data.token,
          });

          if (!!handlers && !!handlers.replace) {
            handlers.replace("/admin/panel/members");
          }
        }
      },
    );
  };

  validateAdminThirdStep = (mfaAnswer = "", code = "") => {
    const { username } = this.state;

    this.props.validateAdminThirdStep(mfaAnswer, code, username);
  };

  handleAuthNavigation = step => {
    this.props.handleAuthNavigation(step);
  };

  renderSteps() {
    const { mfa, phrase, isFetching, isSucessUserName } = this.props;

    const firstStep = (
      <AdminFirstStepAuth
        validateFirstStep={this.validateFirstStep}
        validateSecondStep={this.validateSecondStep}
        phrase={phrase}
        isSucessUserName={isSucessUserName}
        isFetching={isFetching}
        config={this.props.config}
        username={this.state.username}
        password={this.state.password}
        rememberDevice={this.state.rememberDevice}
        stepError={this.props.errorMessage}
        onSubmit={this.validateAdminSecondStep}
        validateAdminFirstStep={this.validateAdminFirstStep}
        onError={this.handleTroubleshooting}
        onChangePassword={password => this.setState({ ...this.state, password })}
        onChangeUsername={username => this.setState({ ...this.state, username })}
        onChangeRememberDevice={rememberDevice =>
          this.setState({ ...this.state, rememberDevice })
        }
      />
    );

    switch (this.props.currentStep) {
      case "1":
        return firstStep;

      case "2":
        return (
          <AdminSecondStepAuth
            onSubmit={validateAdminSecondStep}
            phrase={phrase}
            isFetching={isFetching}
            handleChangeStep={this.handleAuthNavigation}
            stepError={this.props.errorMessage}
          />
        );

      case "3":
        return (
          <VerificationCodePage
            phrase={phrase}
            isFetching={isFetching}
            stepError={this.props.errorMessage}
            config={this.props.config}
            onSubmit={(code = "") =>
              this.validateAdminThirdStep(undefined, code, this.state.username)
            }
            onError={this.handleTroubleshooting}
            handleChangeStep={this.handleAuthNavigation}
            doSendConfirmationCode={async () =>
              await this.props.doSendConfirmationCode(this.state.password)
            }
          />
        );

      default:
        return firstStep;
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div>{this.props.errorMessage}</div>;
    }

    return null;
  }

  render() {
    return (
      <AuthContainer>
        <div className="logo">
          <img
            src={
              this.props.config && this.props.config.auth && this.props.config.auth.fiLogo
            }
            style={{ height: "37px" }}
          />
        </div>

        <Grid>
          <div className="logoMobil">
            <img
              src={
                this.props.config &&
                this.props.config.auth &&
                this.props.config.auth.fiLogo
              }
              style={{ height: "37px" }}
            />
          </div>

          <AuthBox>{this.renderSteps()}</AuthBox>
        </Grid>
      </AuthContainer>
    );
  }
}

const mapStateToProps = ({ admin, auth, config }) => ({
  message: admin.message,
  errorMessage: admin.error,
  phrase: admin.phrase,
  currentStep: admin.currentStep,
  isFetching: admin.isFetching,
  mfa: admin.mfa,
  isSucessUserName: admin.isSucessUserName,
  config: config.config,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
});

export default connect(mapStateToProps, {
  setToken,
  onError,
  requestAdminPage,
  validateAdminFirstStep,
  validateAdminSecondStep,
  validateAdminThirdStep,
  handleAuthNavigation,
  replace,
  doSendConfirmationCode,
})(AdminAuthPage);
