import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import enums from "../../../utils/enums";
import { BlackTrash, Check } from "../../../components/commons/icons";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import { ConfirmationModal, SuccessModal } from "../../../components/commons/Modals";
import {
  MobileTable,
  MobileTableButtonGroup,
  MobileTableButton,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import { withStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableHead, TableRow, Table } from "@material-ui/core";
import { getCurrentTime } from "../../../utils/helper";
import { Space, P, PageTitle, Separator, ReadMore } from "../../../components/commons";
import { selectMessages } from "../../../containers/Dashboard/Accounts/selectors";
import {
  markMessagesAsViewed,
  removeMessage,
  getCustomerMessages,
  showRemoveMessageConfirmationModal,
  showRemoveMessageSuccessModal,
} from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";
const Container = styled.div`
  flex: 1;
`;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid #EBEFF3",
    borderRadius: "5px",
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#FCFCFC",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    color: "#999EA6",
    fontSize: "14px",
    padding: "1rem",
    fontWeight: "normal",
    whiteSpace: "nowrap",
    lineHeight: "18px",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "1rem",
    fontSize: "15px",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#2F303E",
    lineHeight: "18px",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);
class MessagesPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    removeMessage: PropTypes.func,
    showRemoveMessageConfirmationModal: PropTypes.func,
    showRemoveMessageSuccessModal: PropTypes.func,
    markMessagesAsViewed: PropTypes.func,
    getCustomerMessages: PropTypes.func,
    messages: PropTypes.object,
    isRemoveModalOpen: PropTypes.bool,
    isRemoveSuccessModalOpen: PropTypes.bool,
  };
  state = {
    messageToRemove: null,
    fromDate: getCurrentTime(),
    toDate: getCurrentTime(),
    selectedImportantValue: "",
    selectImportantOptions: [
      { Key: 0, value: "All" },
      { key: 1, value: "Yes" },
      { key: 2, value: "No" },
    ],
  };
  componentDidMount() {
    this.props.markMessagesAsViewed();
    this.props.getCustomerMessages();
  }
  handleChangeFromDate = e => {
    this.setState({
      fromDate: e.target.value,
    });
  };

  handleChangeToDate = e => {
    this.setState({
      toDate: e.target.value,
    });
  };
  handleFilterMessages(options) {
    this.props.getCustomerMessages(options);
  }
  renderRemoveConfirmationModal() {
    return (
      <ConfirmationModal
        isOpen={this.props.isRemoveModalOpen}
        onClose={() => this.props.showRemoveMessageConfirmationModal(false)}
        onSuccess={() => this.props.removeMessage(this.state.messageToRemove)}
        headingText="Delete Message"
        confirmationText={<P>Are you sure you want to Delete this Message?</P>}
        cancelButtonText="Close"
        successButtonText={"Confirm"}
        aria-hidden={this.state.isRemoveMessageModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderRemoveMessageSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.isRemoveSuccessModalOpen}
        onClose={() => {
          this.props.showRemoveMessageSuccessModal(false);
          this.setState({ messageToRemove: null });
        }}
        headingText="Message Removed successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <DashboardScreens>
        <Container>
          {this.renderRemoveConfirmationModal()}
          {this.renderRemoveMessageSuccessModal()}
          <PageTitle>Messages</PageTitle>
          <Mobile>
            <div>
              <MobileTable title={"messages"}>
                {this.props.messages.messages &&
                this.props.messages.messages.length > 0 ? (
                  _.map(
                    this.props.messages.messages,
                    ({
                      id,
                      message,
                      postedDate,
                      messageType,
                      important,
                      dismissable,
                    }) => (
                      <MobileTableRow key={postedDate}>
                        <div style={{ width: "100%" }}>
                          <MobileTableHeading value={"Message"} />
                          <MobileTableCell
                            name={"Date"}
                            value={moment(postedDate).format("MM/DD/YYYY")}
                          />
                          <MobileTableCell
                            name={"message type"}
                            value={Object.keys(enums.MessageTypes)[messageType]}
                          />
                          <MobileTableCell
                            name={"message"}
                            value={<ReadMore maxLength={30}>{message}</ReadMore>}
                          />
                          <MobileTableCell
                            name={"important"}
                            value={important ? "Yes" : "No"}
                          />
                          <MobileTableCell>
                            {!dismissable ? (
                              ""
                            ) : (
                              <MobileTableButtonGroup>
                                <MobileTableButton danger>
                                  <BlackTrash
                                    onClick={() => {
                                      this.setState({ messageToRemove: id });
                                      this.props.showRemoveMessageConfirmationModal(true);
                                    }}
                                  />
                                  <Space />
                                  <p>CANCEL</p>
                                </MobileTableButton>
                              </MobileTableButtonGroup>
                            )}
                          </MobileTableCell>
                        </div>
                      </MobileTableRow>
                    ),
                  )
                ) : (
                  <MobileTableRow>
                    <P>{"You don't have messages"}</P>
                  </MobileTableRow>
                )}
              </MobileTable>
              <Separator style={{ margin: "1.5rem auto" }} />
              <MobileTable title={"Banners"}>
                <MobileTableRow>
                  <div>
                    <img
                      style={{ height: 70, borderRadius: 3, width: "100%" }}
                      src="https://lorempixel.com/output/business-q-c-924-150-8.jpg"
                      alt="banner-img"
                    />
                  </div>
                </MobileTableRow>
              </MobileTable>
            </div>
          </Mobile>

          <Desktop>
            <div>
              <div style={{ overflowX: "auto", width: "100%" }}>
                <Table className={classes.table}>
                  <TableHead className={classes.thead}>
                    <TableRow>
                      <CustomTableCell>Date</CustomTableCell>
                      <CustomTableCell>Message</CustomTableCell>
                      <CustomTableCell></CustomTableCell>
                      <CustomTableCell></CustomTableCell>
                      <CustomTableCell>Delete</CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.messages.messages &&
                    this.props.messages.messages.length > 0 ? (
                      _.map(this.props.messages.messages, (item, index) => {
                        if (
                          Object.keys(enums.MessageTypes)[item.messageType] === "INFO"
                        ) {
                          return (
                            <TableRow key={item.postedDate + index}>
                              <CustomTableCell>
                                {moment(item.postedDate).format("MM/DD/YYYY")}
                              </CustomTableCell>
                              <CustomTableCell>
                                <ReadMore maxLength={60}>{item.message}</ReadMore>
                              </CustomTableCell>
                              <CustomTableCell>
                                {item.important ? "Yes" : ""}
                              </CustomTableCell>
                              <CustomTableCell>
                                <span className="badge" style={{ margin: "0" }}>
                                  {Object.keys(enums.MessageTypes)[item.messageType]}
                                </span>
                              </CustomTableCell>
                              <CustomTableCell>
                                {!item.dismissable ? (
                                  ""
                                ) : (
                                  <BlackTrash
                                    onClick={() => {
                                      this.setState({ messageToRemove: item.id });
                                      this.props.showRemoveMessageConfirmationModal(true);
                                    }}
                                  />
                                )}
                              </CustomTableCell>
                            </TableRow>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <TableRow>
                        <CustomTableCell style={{ borderBottom: "none" }}>
                          {"You don't have messages"}
                        </CustomTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <Separator style={{ margin: "1.5rem auto" }} />
                <Table className={classes.table}>
                  <TableHead className={classes.thead}>
                    <TableRow>
                      <CustomTableCell>Banners</CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.messages.messages &&
                    this.props.messages.messages.length > 0 ? (
                      _.map(this.props.messages.messages, item => {
                        if (
                          Object.keys(enums.MessageTypes)[item.messageType] === "BANNER"
                        ) {
                          return (
                            <TableRow key={item.postedDate}>
                              <CustomTableCell>
                                <img
                                  style={{ height: 70, borderRadius: 3, width: "100%" }}
                                  src={item.imageUrl}
                                  alt="banner-img"
                                />
                              </CustomTableCell>
                            </TableRow>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <TableRow>
                        <CustomTableCell style={{ borderBottom: "none" }}>
                          {"You don't have messages"}
                        </CustomTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = state => ({
  messages: selectMessages(state),
  isRemoveModalOpen: state.account.isRemoveMessageModalOpen,
  isRemoveSuccessModalOpen: state.account.isRemoveMessageSuccessModalOpen,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCustomerMessages,
    removeMessage,
    showRemoveMessageConfirmationModal,
    showRemoveMessageSuccessModal,
    markMessagesAsViewed,
  })(MessagesPage),
);
