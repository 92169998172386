export const GET_ADMIN_PAGE_REQUEST = "get_admin_page_request";
export const GET_ADMIN_PAGE_SUCCESS = "get_admin_page_success";
export const GET_ADMIN_PAGE_FAILURE = "get_admin_page_failure";

export const GET_ADMIN_FORMS_REQUEST = "get_admin_forms_request";
export const GET_ADMIN_FORMS_SUCCESS = "get_admin_forms_success";
export const GET_ADMIN_FORMS_FAILURE = "get_admin_forms_failure";
export const FILTER_ADMIN_FORMS = "filter_admin_forms";
export const FILTER_ADMIN_FORMS_FAILURE = "filter_admin_forms_failure";
export const UPDATE_ADMIN_FORM_STATUS_REQUEST = "update_admin_form_status_request";
export const UPDATE_ADMIN_FORM_STATUS_SUCCESS = "update_admin_form_status_success";
export const UPDATE_ADMIN_FORM_STATUS_FAILURE = "update_admin_form_status_failure";
export const UPDATE_ADMIN_FORM_MANY_STATUS_REQUEST =
  "update_admin_form_many_status_request";
export const UPDATE_ADMIN_FORM_MANY_STATUS_SUCCESS =
  "update_admin_form_many_status_success";
export const UPDATE_ADMIN_FORM_MANY_STATUS_FAILURE =
  "update_admin_form_many_status_failure";

export const FIRST_STEP_AUTH_ADMIN_REQUEST = "first_step_auth_admin_request";
export const FIRST_STEP_AUTH_ADMIN_SUCCESS = "first_step_auth_admin_success";
export const FIRST_STEP_AUTH_ADMIN_FAILURE = "first_step_auth_admin_failure";

export const SECOND_STEP_AUTH_ADMIN_REQUEST = "second_step_auth_admin_request";
export const SECOND_STEP_AUTH_ADMIN_SUCCESS = "second_step_auth_admin_success";
export const SECOND_STEP_AUTH_ADMIN_FAILURE = "second_step_auth_admin_failure";

export const THIRD_STEP_AUTH_ADMIN_REQUEST = "third_step_auth_admin_request";
export const THIRD_STEP_AUTH_ADMIN_SUCCESS = "third_step_auth_admin_success";
export const THIRD_STEP_AUTH_ADMIN_FAILURE = "third_step_auth_admin_failure";

export const AUTH_ADMIN_USER = "auth_admin_user";
export const UNAUTH_ADMIN_USER = "unauth_admin_user";

export const RESET_MFA_REQUEST = "reset_mfa_request";
export const RESET_MFA_SUCCESS = "reset_mfa_success";
export const RESET_MFA_FAILURE = "reset_mfa_failure";

export const LOGIN_AS_MEMBER_REQUEST = "login_as_member_request";
export const LOGIN_AS_MEMBER_SUCCESS = "login_as_member_success";
export const LOGIN_AS_MEMBER_FAILURE = "login_as_member_failure";

export const SHOW_RESET_MFA_MODAL = "show_reset_mfa_modal";
export const SHOW_RESET_MFA_SUCCESS_MODAL = "show_reset_mfa_success_modal";

export const GET_ALL_CUSTOMERS_REQUEST = "get_all_customers_request";
export const GET_ALL_CUSTOMERS_SUCCESS = "get_all_customers_success";
export const GET_ALL_CUSTOMERS_FAILURE = "get_all_customers_failure";

export const SHOW_CREATE_MESSAGE_SUCCESS_MODAL = "show_create_message_success_modal";
export const SHOW_CREATE_DIRECT_MESSAGE_SUCCESS_MODAL =
  "show_create_direct_message_success_modal";

export const SHOW_CREATE_NOTIFICATION_SUCCESS_MODAL =
  "show_create_notification_success_modal";
export const SHOW_CREATE_DIRECT_NOTIFICATION_SUCCESS_MODAL =
  "show_create_direct_notification_success_modal";

export const CREATE_CUSTOMER_MESSAGE_REQUEST = "create_customer_message_request";
export const CREATE_CUSTOMER_MESSAGE_SUCCESS = "create_customer_message_success";
export const CREATE_CUSTOMER_MESSAGE_FAILURE = "create_customer_message_failure";

export const CREATE_CUSTOMER_DIRECT_MESSAGE_SUCCESS =
  "create_customer_direct_message_success";
export const CREATE_CUSTOMER_DIRECT_MESSAGE_FAILURE =
  "create_customer_direct_message_failure";
export const CREATE_CUSTOMER_DIRECT_MESSAGE_REQUEST =
  "create_customer_direct_message_request";

export const CREATE_CUSTOMER_NOTIFICATION_REQUEST =
  "create_customer_notification_request";
export const CREATE_CUSTOMER_NOTIFICATION_SUCCESS =
  "create_customer_notification_success";
export const CREATE_CUSTOMER_NOTIFICATION_FAILURE =
  "create_customer_notification_failure";

export const CREATE_CUSTOMER_DIRECT_NOTIFICATION_REQUEST =
  "create_customer_direct_notification_request";
export const CREATE_CUSTOMER_DIRECT_NOTIFICATION_SUCCESS =
  "create_customer_direct_notification_success";
export const CREATE_CUSTOMER_DIRECT_NOTIFICATION_FAILURE =
  "create_customer_direct_notification_failure";

export const GET_ALL_MEMBERS_REQUEST = "get_all_members_request";
export const GET_ALL_MEMBERS_SUCCESS = "get_all_members_success";
export const GET_ALL_MEMBERS_FAILURE = "get_all_members_failure";
export const CHANGE_STATUS_MEMBER_REQUEST = "change_status_member_request";
export const CHANGE_STATUS_MEMBER_SUCCESS = "change_status_member_success";
export const CHANGE_STATUS_MEMBER_FAILURE = "change_status_member_failure";
