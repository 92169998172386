import React, { useState } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import { DashboardScreensWithSubmMenu } from "../DashboardInstance/Dashboard";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import CurrencyFormat from "react-currency-format";
import SelectCustom from "../FundsTransfer/SelectCustom";
import { SuccessModal } from "../../../components/commons/Modals";
import { Cancel, Plus, UserBlack, Check } from "../../../components/commons/icons";
import { SearchGray as IconSearch } from "../../../components/commons/icons";
import { AddButton } from "../Goals/GoalsInstance";
import Contact from "./Contact";
import InputNormal from "./InputNormal";
import TextField from "../../../components/commons/TextField";
import { CustomModal } from "../../../components/commons/Modals";
import { InputAdornment } from "@material-ui/core";
import { Button, Space, HeadingModal } from "../../../components/commons";
import NoteInput from "../FundsTransfer/NoteInput";

const ModalContent = styled.form`

&>div:last-child{
	width: 89%;
	@media(max-width: 420px) {
	max-width: 50%;
	}
}

@media(min-width: 421px and max-width: 1024px) {
 & > div {
	 width: 100%;
	 margin: 16px 0;
 }

 @media(max-width: 420px) {
	text-align: center;
	& > div {
		text-align: left;
		width: auto;
		margin: 16px 0;
	}

`;
const ModalControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #f4f6f8;
`;

const ContainerMobil = styled.div`
  width: 100%;
  .yourContacts {
    display: flex;
    margin: 15px 0px;
    align-items: center;

    span {
      width: 100%;
    }

    .subTitle {
      color: #3fc78e;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      flex: 2;
      display: flex;
    }
  }

  .containerContact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const ContainerDesktop = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .containerLeft {
    max-width: 450px;
    padding-left: 58px;
    padding: 0;
    padding-right: 2%;
    width: 40%;
    @media (max-width: 1100px) {
      border-left: none;
      padding: 0;
      max-width: inherit;
      width: 100%;
    }
    .title {
      color: #141f2d;
      font-family: "Kumbh Sans";
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 50px;
      margin-top: 40px;
      display: block;
    }
    .subTitle {
      color: #3fc78e;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .containerNote {
      display: flex;
      height: 100%;
    }

    .buttonTransfer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .separation {
      margin-top: 20px;
      height: 1px;
      width: 100%;
      background-color: #ebeff3;
    }
    .yourContacts {
      display: flex;
      margin-bottom: 10px;

      span {
        width: 100%;
      }
    }
    .inputNormal {
      margin-bottom: 20px;
    }

    .containerContact {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .containerRight {
    max-width: 650px;
    padding: 0px 1%;
    width: 55%;
    border-left: 1px solid #ebeff3;
    display: flex;
    flex-direction: column;
    height: 88vh;
    @media (max-width: 1400px) {
      padding: 0;
      border-left: none;
    }
    @media (max-width: 1100px) {
      min-width: inherit;
      width: 100%;
    }
    .sendingTo {
      max-width: 570px;
      @media (max-width: 1100px) {
        margin-top: 50px;
        width: 100%;
        max-width: none;
      }
    }
    .title {
      opacity: 0.8;
      color: #4e5d71;
      font-family: "Kumbh Sans";
      font-size: 14px;
      padding-bottom: 10px;
      display: block;
    }

    .headerSendingTo {
      display: flex;
      border-bottom: 1px solid #ebeff3;
      padding-bottom: 20px;
      .infoContact {
        display: flex;
        width: 100%;
        .nameContact {
          color: #000000;
          font-family: "Kumbh Sans";
          font-size: 17px;
        }
      }
      .actions {
        display: flex;
        svg {
          cursor: pointer;
          margin-left: 8px;
        }
      }
    }

    .containerNote {
      display: flex;
      & > div {
        width: 85%;
      }
    }

    .fixedBottom {
      position: relative;
      padding-bottom: 20px;
      @media (min-width: 1101px) {
        margin-top: auto;
        width: 100%;
      }
      .MuiFormControl-root,
      .MuiInputBase-root {
        min-width: auto;
      }
    }

    .titleSendFrom {
      color: #3fc78e;
      font-family: "Kumbh Sans";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .separation {
      height: 1px;
      background-color: #ebeff3;
    }
    .buttonReviewSend {
      justify-content: flex-end;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;

      button {
        color: #ffffff;
        font-family: "Kumbh Sans";
        font-size: 17px;
      }
    }
  }
`;

const TitleSelectAccount = styled.span`
  color: #3fc78e;
  font-family: Kumbh Sans;
  font-size: 12px;
  font-weight: bold;
  display: block;
  letter-spacing: 2px;
  margin-top: 5px;
`;

const SendMoney = () => {
  const [selectedRowFrom, setSelectedRowFrom] = useState({
    display: true,
    allowTransactionsFrom: true,
    id: "123",
    uniqueId: "1192-000",
    description: "Regular Share",
  });
  const [selectedRowTo, setSelectedRowTo] = useState({
    display: true,
    allowTransactionsFrom: true,
    id: "123",
    uniqueId: "•••••••6521",
    description: "Yolanda’s Checking",
  });
  const [isCreateContactModalOpen, setIsCreateContactModalOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [onInputchange, setOnInputchange] = useState({
    name: "Yolanda Barrueco",
    address1: "655 NW 129 St",
    nickname: "",
    address2: "",
    city: "Miami",
    state: "FL",
    zip: "33022",
    paypal: "yola@gmail.com",
    venmo: "yola1888",
    routing: "190192012",
    account: "",
  });

  function renderCreateContactModal() {
    return (
      <CustomModal isOpen={isCreateContactModalOpen}>
        <div className="CustomModalHeader">
          <HeadingModal>New Contact</HeadingModal>
          <Cancel onClick={() => setIsCreateContactModalOpen(false)} />
        </div>
        <ModalContent style={{ margin: "2rem 0" }}>
          <form>
            <TextField
              name="name"
              value={onInputchange.name}
              onChange={e => setOnInputchange({ ...onInputchange, name: e.target.value })}
              id="name"
              type="text"
              label={"Name"}
            />
            <TextField
              name="nickname"
              value={onInputchange.nickname}
              onChange={e =>
                setOnInputchange({ ...onInputchange, nickname: e.target.value })
              }
              id="nickname"
              type="text"
              label={"Nickname"}
            />
            <TextField
              name="address1"
              value={onInputchange.address1}
              onChange={e =>
                setOnInputchange({ ...onInputchange, address1: e.target.value })
              }
              id="address1"
              type="text"
              label={"Address 1"}
            />

            <TextField
              name="address2"
              value={onInputchange.address2}
              onChange={e =>
                setOnInputchange({ ...onInputchange, address2: e.target.value })
              }
              id="address2"
              type="text"
              label={"Address 2"}
            />
            <TextField
              name="city"
              value={onInputchange.city}
              onChange={e => setOnInputchange({ ...onInputchange, city: e.target.value })}
              id="city"
              type="text"
              label={"City"}
            />
            <TextField
              midSize
              name="state"
              value={onInputchange.state}
              onChange={e =>
                setOnInputchange({ ...onInputchange, state: e.target.value })
              }
              id="state"
              type="text"
              label={"State"}
            />
            <TextField
              midSize
              name="zip"
              value={onInputchange.zip}
              onChange={e => setOnInputchange({ ...onInputchange, zip: e.target.value })}
              id="zipcode"
              type="text"
              label={"Zip Code"}
            />
            <TextField
              name="paypal"
              value={onInputchange.paypal}
              onChange={e =>
                setOnInputchange({ ...onInputchange, paypal: e.target.value })
              }
              id="paypal"
              type="text"
              label={"Paypal ID"}
            />
            <TextField
              name="venmo"
              value={onInputchange.venmo}
              onChange={e =>
                setOnInputchange({ ...onInputchange, venmo: e.target.value })
              }
              id="venmo"
              type="text"
              label={"Venmo"}
            />
            <TextField
              name="routing"
              value={onInputchange.routing}
              onChange={e =>
                setOnInputchange({ ...onInputchange, routing: e.target.value })
              }
              id="routing"
              type="text"
              label={"Routing Number"}
            />
            <TextField
              name="account"
              value={onInputchange.account}
              onChange={e =>
                setOnInputchange({ ...onInputchange, account: e.target.value })
              }
              id="account"
              type="text"
              label={"Account"}
            />
          </form>
        </ModalContent>
        <ModalControls>
          <Button onClick={() => setIsCreateContactModalOpen(false)}>Cancel</Button>
          <Space />
          <Button primary onClick={() => setIsCreateContactModalOpen(false)}>
            Send
          </Button>
        </ModalControls>
      </CustomModal>
    );
  }

  const renderSuccessModal = () => {
    return (
      <SuccessModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        headingText="Money has been sent!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  };

  return (
    <DashboardScreensWithSubmMenu>
      {renderCreateContactModal()}
      {renderSuccessModal()}
      <Mobile>
        <ContainerMobil>
          <div className="yourContacts">
            <span className="subTitle">YOUR CONTACTS</span>
            <AddButton
              primary
              style={{ height: "24px", width: "24px" }}
              onClick={() => setIsCreateContactModalOpen(true)}
            >
              <Plus stylesInput={{ height: "18px", width: "18px" }} />
            </AddButton>
          </div>
          <InputNormal
            placeholder="Name, Email, Address, …"
            classNameContainer="inputNormal"
            icon={
              <IconSearch
                stylesInput={{
                  height: "20px",
                  width: "20px",
                  padding: "12px",
                }}
              />
            }
          />
          <div className="containerContact">
            <Contact name="Faadi Al Rahman" />
            <Contact name="Mouse Over" />
            <Contact name="Justine Robinson" />
            <Contact name="Yolanda Barrueco" selected />
            <Contact name="Cecilia Pozo" />
            <Contact name="Faadi Al Rahman" />
            <Contact name="Yvonne Knight" />
          </div>
        </ContainerMobil>
      </Mobile>
      <Desktop>
        <ContainerDesktop>
          <div className="containerLeft">
            <span className="title">Send Money</span>
            <div className="yourContacts">
              <span className="subTitle">YOUR CONTACTS</span>
              <AddButton
                primary
                style={{ height: "24px", width: "24px" }}
                onClick={() => setIsCreateContactModalOpen(true)}
              >
                <Plus stylesInput={{ height: "18px", width: "18px" }} />
              </AddButton>
            </div>
            <InputNormal
              placeholder="Name, Email, Address, …"
              classNameContainer="inputNormal"
              icon={
                <IconSearch
                  stylesInput={{
                    height: "20px",
                    width: "20px",
                    padding: "12px",
                  }}
                />
              }
            />
            <div className="containerContact">
              <Contact name="Faadi Al Rahman" />
              <Contact name="Mouse Over" />
              <Contact name="Justine Robinson" />
              <Contact name="Yolanda Barrueco" selected />
              <Contact name="Cecilia Pozo" />
              <Contact name="Faadi Al Rahman" />
              <Contact name="Yvonne Knight" />
            </div>
          </div>
          <div className="containerRight">
            <div className="sendingTo">
              <span className="title">Sending to</span>
              <div className="headerSendingTo">
                <div className="infoContact">
                  <UserBlack
                    stylesInput={{
                      height: "20px",
                      width: "20px",
                      paddingRight: "10px",
                    }}
                  />
                  <span className="nameContact">Yolanda Barrueco</span>
                </div>
                <div className="actions">
                  <FeatherIcon icon="trash" size="20" stroke="#000" />
                  <FeatherIcon icon="edit" size="20" stroke="#000" />
                </div>
              </div>
            </div>

            <CurrencyFormat
              name="amount"
              id="amount"
              customInput={TextField}
              thousandSeparator={true}
              bigField
              startAdornment={
                <InputAdornment position="start">
                  <span className="bigAdornment">$</span>
                </InputAdornment>
              }
            />

            <div className="containerNote">
              <NoteInput
                type="text"
                id="comment"
                name="personaleComment"
                label="Note"
                margin
              />
            </div>
            <div className="fixedBottom">
              <div>
                <TitleSelectAccount>SEND FROM:</TitleSelectAccount>
                <SelectCustom
                  // error={this.state.formError}
                  options={[
                    {
                      display: true,
                      allowTransactionsFrom: true,
                      id: "123",
                      uniqueId: "1192-000",
                      description: "Regular Share",
                    },
                  ]}
                  onClickItemSelect={(e, item) => {
                    setSelectedRowFrom(item);
                  }}
                  selected={selectedRowFrom}
                />
              </div>
              <br />
              <div>
                <TitleSelectAccount>SEND TO:</TitleSelectAccount>
                <SelectCustom
                  options={[
                    {
                      display: true,
                      allowTransactionsFrom: true,
                      id: "123",
                      uniqueId: "•••••••6521",
                      description: "Yolanda’s Checking",
                    },
                    {
                      display: true,
                      allowTransactionsFrom: true,
                      id: "123",
                      uniqueId: "@yolanda88",
                      description: "../../../../icons/venmo.png",
                      imgDescription: true,
                    },
                    {
                      display: true,
                      allowTransactionsFrom: true,
                      id: "123",
                      uniqueId: "yola1988@gmail.com",
                      description: "../../../../icons/paypal.png",
                      imgDescription: true,
                    },
                  ]}
                  onClickItemSelect={(e, item) => {
                    setSelectedRowTo(item);
                  }}
                  selected={selectedRowTo}
                />
              </div>
              <br />
              <div className="buttonReviewSend">
                <Button
                  //disabled={this.state.amountError}
                  //primary={!this.state.amountError}
                  onClick={() => setIsOpenModal(true)}
                  style={{
                    backgroundColor: "#3FC78E",
                    width: "228px",
                    padding: "10px 0",
                  }}
                  primary
                >
                  Review and Send
                </Button>
              </div>
            </div>
          </div>
        </ContainerDesktop>
        <div></div>
      </Desktop>
    </DashboardScreensWithSubmMenu>
  );
};

export default SendMoney;
