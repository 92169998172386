import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import Zoom from "@material-ui/core/Zoom";
import {
  LightTooltip,
  StatusContainer,
  Dropdown,
  DropdownContent,
  DropdownItem,
  IconContainer,
} from "../../../commons/SortableTableStyles";
import AdminModal from "../Members/AdminModal";
import EditAdminModal from "./EditAdminModal";

const checkStatus = status => {
  switch (status) {
    case "Active":
      return <FeatherIcon icon="check-circle" size="16" stroke="#3fc78e" />;
    case "Pending":
      return <FeatherIcon icon="check-circle" size="16" stroke="#797c7e" />;
    default:
      return <></>;
  }
};

const StatusAdmin = ({ status, name, username }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClick = () => {
    setShowDropdown(prev => !prev);
  };

  const handleClickAway = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <StatusContainer>
          {checkStatus(status)}
          <Dropdown>
            <IconContainer clicked={showDropdown} onClick={handleClick}>
              <FeatherIcon icon="more-vertical" size="16" stroke="#141f2d" />
            </IconContainer>
            {showDropdown && (
              <DropdownContent>
                <DropdownItem onClick={() => setOpenEdit(true)}>Change Role</DropdownItem>
                <DropdownItem onClick={() => setOpenReset(true)}>
                  Reset Password
                </DropdownItem>
                <DropdownItem danger onClick={() => setOpenDelete(true)}>
                  Delete
                </DropdownItem>
              </DropdownContent>
            )}
          </Dropdown>
        </StatusContainer>
      </ClickAwayListener>

      <EditAdminModal
        name={name}
        username={username}
        open={openEdit}
        handleOpen={() => setOpenEdit(true)}
        handleClose={() => setOpenEdit(false)}
      />

      <AdminModal
        open={openDelete}
        name={name}
        username={username}
        handleOpen={() => setOpenDelete(true)}
        handleClose={() => setOpenDelete(false)}
        handleAction={() => console.log("delete action comes here")}
        actionQuestion="Are you sure you want to Delete this Admin?"
        actionTextButton="Delete"
        danger
      />

      <AdminModal
        open={openReset}
        name={name}
        username={username}
        handleOpen={() => setOpenReset(true)}
        handleClose={() => setOpenReset(false)}
        handleAction={() => console.log("reset action comes here")}
        actionQuestion="Are you sure you want to Reset Password?"
        actionTextButton="Reset Password"
      />
    </>
  );
};

StatusAdmin.prototype = {
  name: PropTypes.string,
  username: PropTypes.string,
  status: PropTypes.number,
  push: PropTypes.func,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { push })(StatusAdmin);
