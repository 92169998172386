import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { createMuiTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Link } from "@material-ui/core";
import { Button, OneValueTextField } from "../../../components/commons";
import { StepText, StepTitle } from "./Steps";

import styles from "./step.module.css";
import { withRouter } from "react-router-dom";

const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});

const greenTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#50cd96",
    },
    secondary: {
      main: "#50cd96",
    },
  },
});

class VerificationCodePage extends Component {
  static defaultProps = {
    doSendConfirmationCode: () => {},
    handleChangeStep: () => {},
  };

  static propTypes = {
    doSendConfirmationCode: PropTypes.func.isRequired,
    validateThirdStep: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    numberVerify: ["", "", "", "", "", ""],
    numberVerifyError: false,
    timeOut: false,
    expiresOn: 900000 /** 15 minutes as milliseconds */,
  };

  get code() {
    return this.state.numberVerify.join("");
  }

  async componentDidMount() {
    await this.resetTimeout();
  }

  handleOnSubmit = ({ target }) => {
    if (this.isValid()) {
      this.props.onSubmit(this.code);
    }
  };

  resetTimeout = (otherStates = {}) => {
    this.setState({
      ...this.state,
      ...otherStates,
      timeOut: false,
      numberVerify: ["", "", "", "", "", ""],
    });

    setTimeout(
      () => this.setState({ ...this.state, timeOut: true }),
      this.state.expiresOn,
    );
  };

  resendCode = async () => {
    await this.props.doSendConfirmationCode();

    this.resetTimeout({ numberVerifyError: false });
  };

  handleNumberVerify = ({ target }, key = 0) => {
    const value = target.value || "";

    const inRange = value.length >= 0 && value.length < 2;

    const isValidValue = /[0-9]/gi.test(value);

    let nextTarget = true;

    const numberVerify = this.state.numberVerify.map((number, numberIndex) => {
      if (inRange && numberIndex === key) {
        if (isValidValue) return value;
        else {
          nextTarget = false;

          return value;
        }
      }

      return number;
    });

    this.setState({ numberVerify }, () => {
      const inputTarget = target?.parentNode?.parentNode?.parentNode;

      const nexInputTarget =
        inputTarget?.nextSibling?.firstChild?.firstChild?.firstChild || undefined;

      const backInputTarget =
        inputTarget?.previousSibling?.firstChild?.firstChild?.firstChild || undefined;

      if (!!nexInputTarget && !!nextTarget) nexInputTarget.focus();
      else if (!!backInputTarget && value === "") backInputTarget.focus();
    });
  };

  isValid = () =>
    this.state.numberVerify.every(number => /[0-9]/gi.test(number || "")) &&
    !this.state.timeOut;

  hasError = () => !!this.state.timeOut || !!this.state.numberVerifyError;

  goToForgotYourPassword = () => window.location.reload();

  handleKeyPress = event => {
    const { key } = event;

    if (key === "Enter" && this.isValid()) this.handleOnSubmit(event);
  };

  render() {
    const isValid = this.isValid();

    return (
      <Container maxWidth="sm" style={{ height: "100%", display: "grid" }}>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          wrap="nowrap"
          className={styles.newSecondStepAuthMainContainer}
        >
          <Grid item style={{ margin: "25px 0 75px 0" }}>
            <StepTitle className={styles.newSecondStepAuthMainContainerTitle}>
              Email Verification
            </StepTitle>
          </Grid>

          <Grid item style={{ marginBottom: 45 }}>
            <StepText className={styles.newSecondStepAuthMainContainerSubTitle}>
              We have sent a code to your email. Enter the code in the boxes below to
              verify this device.
            </StepText>
          </Grid>

          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="space-between"
            alignItems="center"
            item
            style={{ marginBottom: 45 }}
          >
            {this.state.numberVerify.map((number = "", numberIndex = 0) => {
              const hasError = !/[0-9]/gi.test(number);

              return (
                <Grid
                  item
                  key={`container-number-verify-${numberIndex}`}
                  style={{ margin: "0 5px 0 0" }}
                >
                  <OneValueTextField
                    maxlength={1}
                    name={`numberVerify-${numberIndex}`}
                    id={`numberVerify-${numberIndex}`}
                    variant="outlined"
                    margin="dense"
                    error={hasError && !!number}
                    success={!hasError}
                    autoFocus={numberIndex === 0}
                    value={number}
                    onKeyPress={this.handleKeyPress}
                    onChange={inputEvent =>
                      this.handleNumberVerify(inputEvent, numberIndex)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            item
            direction="row"
            wrap="nowrap"
            justify="flex-start"
            alignItems="center"
            spacing={2}
            style={{ marginBottom: 5 }}
          >
            {!this.hasError() ? (
              <Grid item>
                <StepText className={styles.newSecondStepAuthMainContainerNote}>
                  Did not get a code?
                </StepText>
              </Grid>
            ) : (
              <Fragment>
                <Grid>
                  <img
                    alt="Timeout icon"
                    src={
                      this.state.numberVerifyError
                        ? "https://olb-assets.s3.us-east-2.amazonaws.com/images/WarningIcon.png"
                        : "https://olb-assets.s3.us-east-2.amazonaws.com/images/ClockIcon.png"
                    }
                    style={{ objectFit: "cover", width: 25 }}
                  />
                </Grid>

                <Grid item>
                  <StepText
                    className={styles.newSecondStepAuthMainContainerNote}
                    style={{ color: "#cb3737" }}
                  >
                    {!this.hasError()
                      ? "This code has expired."
                      : "The code you entered is invalid."}
                  </StepText>
                </Grid>
              </Fragment>
            )}
          </Grid>

          <Grid
            item
            style={{
              marginBottom: 50,
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ThemeProvider theme={greenTheme}>
              <Link onClick={this.resendCode}>
                <StepText
                  className={styles.newSecondStepAuthMainContainerNote}
                  style={{ color: "#50cd96" }}
                >
                  Resend Code
                </StepText>
              </Link>
            </ThemeProvider>
          </Grid>

          {/* <Grid item xs={12} /> */}

          <Grid
            container
            direction="column"
            item
            style={{
              marginBottom: 50,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              primary={isValid}
              disabled={!isValid}
              onClick={event => this.handleOnSubmit(event)}
              style={{
                width: "100%",
                fontWeight: "bold",
                marginBottom: 20,
                color: "#ffffff",
              }}
            >
              Verify
            </Button>

            <Button
              onClick={this.goToForgotYourPassword}
              style={{
                width: "38%",
                fontWeight: "bold",
                color: "#141f2d",
                alignItems: "center",
              }}
            >
              Back To Login
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(loaderStyles)(withRouter(VerificationCodePage));
