import React, { Component } from "react";
import PropTypes from "prop-types";
// import moment from 'moment';
import { connect } from "react-redux";
import { getCurrentTime } from "../../../utils/helper";
import { push } from "react-router-redux";
import TextField from "../../../components/commons/TextField";
import { MenuItem } from "@material-ui/core";
import Select from "../../../components/commons/SelectInput";
import {
  Button,
  Loader,
  Box,
  Space,
  TextError,
  HeadingModal,
} from "../../../components/commons";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import _ from "lodash";
// import { getCurrentTime } from 'utils';
import Modal from "react-modal";
import { Check, Cancel } from "../../../components/commons/icons";
import { LoanStep, LoanProgress, LoanForm, LoanControls, LoanBody } from "./LoanApp";
import * as actions from "./actions";
import { selectCurrentStep } from "./selectors";
import styles from "./styles.module.css";
import { ReactSVG } from "react-svg";

// require('components/commons/datepicker/datepicker.css');
// const DatePicker = require('components/commons/datepicker/DatePicker.js');

// TODO: add default props
class LoanAppPage extends Component {
  static defaultProps = {};
  static propTypes = {
    setLoanAppProgress: PropTypes.func,
    displaySuccessModal: PropTypes.func,
    showSuccessModal: PropTypes.bool,
    updateLoanValues: PropTypes.func,
    goStepBack: PropTypes.func,
    push: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    currentStepId: PropTypes.number,
    step: PropTypes.object,
  };

  state = { formError: null, defaultValue: getCurrentTime() };

  onSubmit(lastStep) {
    const {
      step: { fields },
    } = this.props;
    let isReadyToSubmit = true;
    let isEmailValidated = false;
    fields &&
      _.forEach(fields, ({ enteredValue, type, required }) => {
        if (type && type !== "select") {
          if (required) {
            if (_.isEmpty(enteredValue)) {
              this.setState({ formError: "please complete all required fields" });
              isReadyToSubmit = false;
            }
          }
        }
        if (type && type === "select") {
          if (required) {
            if (
              enteredValue === "" ||
              enteredValue === null ||
              enteredValue === undefined
            ) {
              this.setState({ formError: "please complete all required fields" });
              isReadyToSubmit = false;
            }
          }
        }
        if (type && type === "email") {
          isEmailValidated = this.handleValidateEmail(enteredValue);
          if (!isEmailValidated) isReadyToSubmit = false;
          this.setState({ formError: "please add a valid email address" });
        }
      });
    if (isReadyToSubmit) {
      this.setState({ formError: null });
      this.props.setLoanAppProgress(lastStep);
    }
  }
  handleValidateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  handleStepBack = () => {
    if (this.props.currentStepId === 1) {
      this.props.setState({ step: "" });
      // this.props.push('/dashboard/loans/request-information');
    }
    this.props.goStepBack(this.props.currentStepId);
  };
  handleChange = e =>
    this.props.updateLoanValues(this.props.step.name, e.target.name, e.target.value);

  handleCloseSuccessModal = () => {
    this.props.displaySuccessModal(false);
    this.props.push("/dashboard/accounts");
  };
  RenderFormError = () =>
    this.state.formError && <TextError>{this.state.formError}</TextError>;

  RenderRequestError = () =>
    this.props.error && <TextError>{this.props.error}</TextError>;

  renderSuccessModal() {
    return (
      <Modal
        isOpen={this.props.showSuccessModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            maxWidth: 400,
            width: "100%",
            margin: "auto",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            borderRadius: 0.3,
            transform: "translate(-50%, -50%)",
            padding: 0,
          },
          overlay: {
            zIndex: 30,
            backgroundColor: "rgba(230, 230, 230, 0.8)",
          },
        }}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <span style={{ flex: 1 }}>
            <HeadingModal id="heading">
              Loan Application Submited Successfully!
            </HeadingModal>
          </span>
          <span>
            <Cancel onClick={() => this.props.displaySuccessModal(false)} />
          </span>
        </div>
        <div>
          <span className="successModalBody">
            <Check />
          </span>
        </div>
        <div className="modalFooter">
          <Button primary onClick={this.handleCloseSuccessModal}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }
  getValueInt(value) {
    if (value === "" || value === undefined || value === null) {
      return undefined;
    }
    return parseInt(value, 10);
  }
  render() {
    return (
      <LoanStep>
        {this.renderSuccessModal()}
        <Loader isLoading={this.props.isFetching} />
        {/* <br />
        <LoanTitle title={this.props.step.title || ''} /> */}
        <LoanBody>
          <Mobile>
            <LoanForm>
              {this.props.step.fields &&
                _.map(this.props.step.fields, field => {
                  // type select
                  if (field && field.type === "select") {
                    if (!_.isEmpty(field.possibleValues)) {
                      return (
                        <Select
                          id={field.name}
                          aria-label={field.label}
                          name={field.name}
                          required={field.required}
                          label={field.label}
                          value={field.enteredValue}
                          onChange={this.handleChange}
                          s
                        >
                          {field.possibleValues &&
                            _.map(field.possibleValues, (option, index) => (
                              <MenuItem key={index} value={option.id}>
                                {option.value}
                              </MenuItem>
                            ))}
                        </Select>
                      );
                    }
                  }
                  // type number
                  if (field && field.type === "number") {
                    return (
                      <TextField
                        fullWidth={field.big}
                        onChange={this.handleChange}
                        aria-label={field.name}
                        id={field.name}
                        required={field.required}
                        type={field.type}
                        name={field.name}
                        label={field.label}
                        value={field.enteredValue}
                      />
                    );
                  }
                  // type text
                  if (field && field.type === "text") {
                    return (
                      <TextField
                        fullWidth={field.big}
                        onChange={this.handleChange}
                        aria-label={field.name}
                        id={field.name}
                        type={field.type}
                        required={field.required}
                        name={field.name}
                        label={field.label}
                        value={field.enteredValue}
                      />
                    );
                  }
                  // type email
                  if (field && field.type === "email") {
                    return (
                      <TextField
                        fullWidth={field.big}
                        onChange={this.handleChange}
                        aria-label={field.name}
                        id={field.name}
                        type={field.type}
                        required={field.required}
                        name={field.name}
                        label={field.label}
                        value={field.enteredValue}
                      />
                    );
                  }
                  // type date
                  if (field && field.type === "date") {
                    return (
                      <TextField
                        fullWidth={field.big}
                        onChange={this.handleChange}
                        aria-label={field.name}
                        id={field.name}
                        type={field.type}
                        style={{ padding: "6px 0" }}
                        name={field.name}
                        required={field.required}
                        defaultValue={this.state.defaultValue}
                        label={field.label}
                        value={field.enteredValue}
                      />
                    );
                  }
                  // type textarea
                  if (field && field.type === "textarea") {
                    return (
                      <TextField
                        fullWidth={field.big}
                        multiline
                        rowsMax="4"
                        onChange={this.handleChange}
                        aria-label={field.name}
                        id={field.name}
                        name={field.name}
                        required={field.required}
                        label={field.label}
                        value={field.enteredValue}
                      />
                    );
                  }

                  if (field.type === "description_submit") {
                    return (
                      <div style={{ display: "flex", padding: "0px 16px" }}>
                        <div
                          style={{
                            marginTop: "7px",
                            marginRight: "5px",
                          }}
                        >
                          <ReactSVG
                            src={`../../../../icons/${field.icon}.svg`}
                            evalScripts="always"
                            beforeInjection={svg => {
                              svg.setAttribute(
                                "style",
                                `width: 20px;height: 20px;display: flex; `,
                              );
                            }}
                          />
                        </div>
                        <div>
                          {field.value.map(item => (
                            <>
                              <p className={styles.descriptionSubmit}>{item}</p>
                              {`\n`}
                            </>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
            </LoanForm>
            <LoanProgress
              text={"Progress"}
              progress={this.props.step.progressBar || 0}
              title={this.props.step.title || ""}
            />
          </Mobile>

          <Desktop>
            <LoanProgress
              text={"Progress"}
              progress={this.props.step.progressBar || 0}
              title={this.props.step.title || ""}
            />
            <Box>
              <form
                style={{ display: "flex", flexWrap: "wrap" }}
                className={styles.historyFiltersFx}
              >
                {this.props.step.fields &&
                  _.map(this.props.step.fields, field => {
                    // type select
                    if (field && field.type === "select") {
                      if (!_.isEmpty(field.possibleValues)) {
                        return (
                          <Select
                            id={field.name}
                            aria-label={field.label}
                            name={field.name}
                            label={field.label}
                            required={field.required}
                            value={field.enteredValue}
                            onChange={this.handleChange}
                          >
                            {field.possibleValues &&
                              _.map(field.possibleValues, (option, index) => (
                                <MenuItem key={index} value={option.id}>
                                  {option.value}
                                </MenuItem>
                              ))}
                          </Select>
                        );
                      }
                    }
                    // type number
                    if (field && field.type === "number") {
                      return (
                        <TextField
                          fullWidth={field.big}
                          onChange={this.handleChange}
                          aria-label={field.name}
                          id={field.name}
                          type={field.type}
                          name={field.name}
                          required={field.required}
                          label={field.label}
                          value={field.enteredValue}
                        />
                      );
                    }
                    // type text
                    if (field && field.type === "text") {
                      return (
                        <TextField
                          fullWidth={field.big}
                          onChange={this.handleChange}
                          aria-label={field.name}
                          id={field.name}
                          type={field.type}
                          name={field.name}
                          required={field.required}
                          label={field.label}
                          value={field.enteredValue}
                        />
                      );
                    }
                    // type email
                    if (field && field.type === "email") {
                      return (
                        <TextField
                          fullWidth={field.big}
                          onChange={e => this.handleChange(e, "email")}
                          aria-label={field.name}
                          id={field.name}
                          type={field.type}
                          name={field.name}
                          required={field.required}
                          label={field.label}
                          value={field.enteredValue}
                        />
                      );
                    }
                    // type date
                    if (field && field.type === "date") {
                      return (
                        <TextField
                          fullWidth={field.big}
                          onChange={this.handleChange}
                          aria-label={field.name}
                          id={field.name}
                          type={field.type}
                          name={field.name}
                          required={field.required}
                          label={field.label}
                          value={field.enteredValue}
                        />
                      );
                    }
                    // type textarea
                    if (field && field.type === "textarea") {
                      return (
                        <TextField
                          fullWidth={field.big}
                          type="text"
                          style={{ width: 432 }}
                          onChange={this.handleChange}
                          aria-label={field.name}
                          id={field.name}
                          required={field.required}
                          name={field.name}
                          label={field.label}
                          value={field.enteredValue}
                        />
                      );
                    }

                    if (field.type === "description_submit") {
                      return (
                        <div style={{ display: "flex", padding: "0px 16px" }}>
                          <div
                            style={{
                              marginTop: "7px",
                              marginRight: "5px",
                            }}
                          >
                            <ReactSVG
                              src={`../../../../icons/${field.icon}.svg`}
                              evalScripts="always"
                              beforeInjection={svg => {
                                svg.setAttribute(
                                  "style",
                                  `width: 20px;height: 20px;display: flex; `,
                                );
                              }}
                            />
                          </div>
                          <div>
                            {field.value.map(item => (
                              <>
                                <p className={styles.descriptionSubmit}>{item}</p>
                                {`\n`}
                              </>
                            ))}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </form>
            </Box>
          </Desktop>
        </LoanBody>
        {this.RenderFormError()}
        {this.RenderRequestError()}
        <br />
        <LoanControls>
          <Button onClick={this.handleStepBack}>Back</Button>
          <Space />
          {!this.props.step.lastStep && (
            <Button primary onClick={() => this.onSubmit(false)}>
              Continue
            </Button>
          )}
          {this.props.step.lastStep && (
            <Button primary onClick={() => this.onSubmit(true)}>
              Submit
            </Button>
          )}
        </LoanControls>
      </LoanStep>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.loan.isFetching,
  error: state.loan.error,
  step: selectCurrentStep(state),
  currentStepId: state.loan.currentStep,
  showSuccessModal: state.loan.showSuccessModal,
});
export default connect(mapStateToProps, { ...actions, push })(LoanAppPage);
