import { useEffect, useState } from "react";
import { isEmpty, lowerCase, some } from "lodash";
import PropTypes from "prop-types";
import { tableTypes } from "../";

const useSearch = ({ data = [], userType = "", query = "", searchBy = [] }) => {
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    if (!isEmpty(data) && !!query && !isEmpty(searchBy)) {
      const dataFiltered = data.filter(item => {
        let pass = searchBy.map(filter => {
          const key = lowerCase(filter.title);
          const isValid = !!item[key];
          const regex = new RegExp(query, "g").test(item[key]);
          return isValid && regex;
        });

        return some(pass, Boolean);
      });

      setSearchData(dataFiltered);
    } else if (!isEmpty(data) && !query) setSearchData(data);
    else setSearchData([]);
  }, [data, query]);

  return {
    data: searchData,
  };
};

useSearch.defaultProps = {
  data: [],
  userType: "",
  getReportStatus: (item = {}) => ({
    ok: false,
    label: "",
    icon: "",
  }),
};

useSearch.prototype = {
  userType: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  searchBy: PropTypes.array.isRequired,
};

export default useSearch;
