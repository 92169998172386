import * as types from "./constants";
import { get } from "../../utils/server";

export const getConfigByDomain = () => async dispatch => {
  try {
    dispatch({ type: types.GET_FI_CONFIG_REQUEST });
    const { data } = await get("/config");
    if (data.success) {
      const {
        data: { setup, maxSessionMinutes, financialInstitutionId, Check_Images },
      } = data;
      localStorage.setItem("maxSessionMinutes", maxSessionMinutes);
      dispatch({
        type: types.GET_FI_CONFIG_SUCCESS,
        payload: {
          setup: setup,
          maxSessionMinutes: maxSessionMinutes,
          financialInstitutionId,
          Check_Images: Check_Images,
        },
      });
    } else {
      dispatch({
        type: types.GET_FI_CONFIG_FAILURE,
        payload: "error trying to get config file",
      });
    }
  } catch (err) {
    if (err.response && err.response !== undefined && err) {
      dispatch({
        type: types.GET_FI_CONFIG_FAILURE,
        payload: "error trying to get config file",
      });
    } else {
      dispatch({
        type: types.GET_FI_CONFIG_FAILURE,
        payload: "error trying to get config file",
      });
    }
  }
};
