import { push, replace } from "react-router-redux";
import ubid from "ubid";
import * as types from "./types";
import { UNAUTH_ADMIN_USER } from "../Admin/constants";
import { post } from "../../utils/server";
import { handleHttpError } from "../../utils/helper";
import { toast } from "react-toastify";

export const setToken = ({ token = "", iat = "" }) => async (dispatch, getState) => {
  localStorage.setItem("token", token);

  localStorage.setItem("tokenStartDate", iat);

  localStorage.removeItem("request_update_token");

  dispatch({ type: types.THIRD_STEP_AUTH_USER_SUCCESS });
  dispatch({ type: types.AUTH_USER });
};

export const onError = (payload = "") => async (dispatch, getState) => {
  dispatch({
    type: types.THIRD_STEP_AUTH_USER_FAILURE,
    payload: payload,
  });
};

export const doSendConfirmationCode = (password = "", email = "") => async (
  dispatch,
  getState,
) => {
  try {
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();

    dispatch({ type: types.SEND_CONFIRMATION_CODE });

    const body = {
      password,
      username: email || username,
      financialInstitutionName: fiName,
    };

    let rememberDevice = !!localStorage.getItem("rememberDevice");

    if (rememberDevice) {
      await ubid.get((error, signatureData) => {
        const uuid = signatureData.browser.signature;

        rememberDevice = uuid;

        body.rememberDevice = { remember: !!uuid, uuid };
      });
    }

    const { data: response } = await post("/auth/sign-in/2", body);

    const { success, data } = response;

    if (success) {
      dispatch({
        type: types.SEND_CONFIRMATION_CODE_SUCCESS,
        payload: { data },
      });
    } else {
      dispatch({
        type: types.SEND_CONFIRMATION_CODE_FAILURE,
        payload: data.message,
      });
    }

    return response;
  } catch (error) {
    if (handleHttpError(error, { dispatch, push })) {
      return error;
    }

    if (error?.response?.data?.message) {
      dispatch({
        type: types.SEND_CONFIRMATION_CODE_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: types.SEND_CONFIRMATION_CODE_FAILURE,
        payload: { error: "error trying to change remember device ID" },
      });
    }

    return error;
  }
};

export const doVerifyConfirmationCode = (code = "", username = "") => async (
  dispatch,
  getState,
) => {
  try {
    const {
      config: {
        config: { fiName },
      },
    } = getState();

    dispatch({ type: types.VERIFY_CONFIRMATION_CODE });

    const body = {
      code,
      username,
      financialInstitutionName: fiName,
    };

    let rememberDevice = localStorage.getItem("rememberDevice");

    if (!!rememberDevice) {
      await ubid.get((error, signatureData) => {
        const uuid = signatureData.browser.signature;

        rememberDevice = uuid;

        body.rememberDevice = { remember: !!uuid, uuid };
      });
    }

    const { data: response } = await post("/auth/sign-in/3", body);

    const { success, data } = response;

    if (success) {
      dispatch({
        type: types.VERIFY_CONFIRMATION_CODE_SUCCESS,
        payload: { data },
      });
    } else {
      dispatch({
        type: types.VERIFY_CONFIRMATION_CODE_FAILURE,
        payload: { error: data },
      });
    }

    return response;
  } catch (error) {
    if (!!error && !!error.response && !!error.response.data) {
      const { success = false, message = "" } = error.response.data;

      if (!!message) toast.error(message);
    }

    if (handleHttpError(error, { dispatch, push })) {
      return error;
    }

    if (!!error.response) {
      dispatch({
        type: types.VERIFY_CONFIRMATION_CODE_FAILURE,
        payload: { error: error.response.statusText },
      });
    } else {
      dispatch({
        type: types.VERIFY_CONFIRMATION_CODE_FAILURE,
        payload: { error: "error trying to change remember device ID" },
      });
    }

    return error;
  }
};

export const authError = error => ({
  type: types.AUTH_ERROR,
  payload: error,
});

export const logout = isAdmin => async dispatch => {
  try {
    if (isAdmin) {
      await localStorage.removeItem("tokenAdmin");
      await localStorage.removeItem("request_update_token_admin");
      await localStorage.removeItem("tokenStartDateAdmin");
      await localStorage.removeItem("expiredTokenAdmin");

      await dispatch({ type: UNAUTH_ADMIN_USER });
    } else {
      await localStorage.removeItem("token");
      await localStorage.removeItem("request_update_token");
      await localStorage.removeItem("tokenStartDate");
      await localStorage.removeItem("expiredToken");
      await dispatch({ type: types.UNAUTH_USER });
    }
  } catch (err) {
    console.log("++Error on Logout++");
    console.log(err);
    console.log("+++++");
  }
};

// auth

// step 1
export const validateFirstStep = username => async (dispatch, getState) => {
  try {
    dispatch({ type: types.FIRST_STEP_AUTH_USER_REQUEST });

    const {
      config: {
        config: { fiName },
      },
    } = getState();

    const body = {
      username,
      financialInstitutionName: fiName,
    };

    const { data } = await post("/auth/sign-in/1", body);

    if (data.success) {
      const {
        data: { id, phrase },
      } = data;

      dispatch({
        type: types.FIRST_STEP_AUTH_USER_SUCCESS,
        payload: {
          id,
          phrase,
          username: data.data.username,
          name: data.data.name,
        },
      });
    } else {
      dispatch({
        type: types.FIRST_STEP_AUTH_USER_FAILURE,
        payload: { action: "username", error: data.message },
      });
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      console.log("++Error on Login 1++");
      console.log(error);
      console.log("+++++");

      dispatch({
        type: types.FIRST_STEP_AUTH_USER_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: types.FIRST_STEP_AUTH_USER_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// step 2
export const validateSecondStep = (
  password,
  email,
  callback = async (success = false) => undefined,
) => async (dispatch, getState) => {
  const handlers = {
    replace: (path = "/") => dispatch(replace(path)),
  };

  try {
    dispatch({ type: types.SECOND_STEP_AUTH_USER_REQUEST });

    if (!!password && !!email) {
      const { data, success } = await doSendConfirmationCode(password, email)(
        dispatch,
        getState,
      );

      if (success) {
        dispatch({
          type: types.SECOND_STEP_AUTH_USER_SUCCESS,
          payload: {
            // mfa: data.data.mfa,
            // mfaId: data.data.mfaId,
          },
        });

        await callback({
          data,
          success,
          handlers,
        });
      } else {
        const payload = data || "error trying to log into your account";

        await dispatch({
          type: types.SECOND_STEP_AUTH_USER_FAILURE,
          payload,
        });

        await callback({
          data: payload,
          success: false,
          handlers,
        });
      }
    } else {
      const payload = `${!password ? "Password" : "Email"} is required`;

      await dispatch({ type: types.SECOND_STEP_AUTH_USER_FAILURE, payload });

      await callback({
        data: payload,
        success: false,
        handlers,
      });
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      await dispatch({
        type: types.SECOND_STEP_AUTH_USER_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      await dispatch({
        type: types.SECOND_STEP_AUTH_USER_FAILURE,
        payload: "error trying to log into your account",
      });
    }

    await callback({
      error,
      success: false,
      handlers,
    });
  }
};

// step 3
export const validateThirdStep = (mfaAnswer = "", code = "", username = "") => async (
  dispatch,
  getState,
) => {
  try {
    await dispatch({ type: types.THIRD_STEP_AUTH_USER_REQUEST });

    const {
      auth: { mfaId },
      config: {
        config: { fiName },
      },
    } = getState();

    if (!!mfaAnswer) {
      const body = {
        username,
        mfaAnswer,
        financialInstitutionName: fiName,
        mfaId,
      };

      const { data } = await post("/login/3", body);

      if (data.success) {
        await setToken({
          token: data.data.token,
          iat: data.data.iat,
          dispatch,
        })(dispatch, getState);

        dispatch(push("/dashboard/accounts"));
      } else await onError({ dispatch, payload: data.message })(dispatch, getState);
    } else {
      const {
        data: verifyConfirmationData,
        success: verifyConfirmationSuccess = false,
      } = await doVerifyConfirmationCode(code, username)(dispatch, getState);

      if (verifyConfirmationSuccess) {
        await setToken({
          token: verifyConfirmationData.token,
          iat: verifyConfirmationData.iat,
          dispatch,
        })(dispatch, getState);

        await dispatch(push("/dashboard/accounts"));
      } else {
        await onError({ dispatch, payload: verifyConfirmationData })(dispatch, getState);
      }
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      await dispatch({
        type: types.THIRD_STEP_AUTH_USER_FAILURE,
        payload: err?.response?.data?.message || "",
      });
    } else {
      await dispatch({
        type: types.THIRD_STEP_AUTH_USER_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// recover password

// recover step 1
export const firstStepRecoverPassword = (ssn, dob) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.FIRST_STEP_RECOVER_PASSWORD_REQUEST });
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      ssn,
      dob,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/recoverpassword/email/1", body);
    if (data.success) {
      dispatch({
        type: types.FIRST_STEP_RECOVER_PASSWORD_SUCCESS,
        payload: {},
      });
      dispatch(handleAuthNavigation("temporary_password"));
    } else if (data.error) {
      dispatch({
        type: types.FIRST_STEP_RECOVER_PASSWORD_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.FIRST_STEP_RECOVER_PASSWORD_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.FIRST_STEP_RECOVER_PASSWORD_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// recover step 2
export const secondStepRecoverPassword = tempPassword => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SECOND_STEP_RECOVER_PASSWORD_REQUEST });
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      tempPassword,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/recoverpassword/email/2", body);
    if (data.success) {
      dispatch({ type: types.SECOND_STEP_RECOVER_PASSWORD_SUCCESS });
      dispatch(handleAuthNavigation("reset_password"));
    } else {
      dispatch({
        type: types.SECOND_STEP_RECOVER_PASSWORD_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.SECOND_STEP_RECOVER_PASSWORD_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.SECOND_STEP_RECOVER_PASSWORD_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};
// recover step 3
export const thirdStepRecoverPassword = (newPassword, verifyPassword) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.THIRD_STEP_RECOVER_PASSWORD_REQUEST });
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      newPassword,
      verifyPassword,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/recoverpassword/final", body);
    if (data.success) {
      dispatch({
        type: types.THIRD_STEP_RECOVER_PASSWORD_SUCCESS,
        payload: { mfa: data.data.mfa, mfaId: data.data.mfaId },
      });
      dispatch(handleAuthNavigation("3"));
    } else {
      dispatch({
        type: types.THIRD_STEP_RECOVER_PASSWORD_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.THIRD_STEP_RECOVER_PASSWORD_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.THIRD_STEP_RECOVER_PASSWORD_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// request SMS Code
export const requestSMSCode = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_SMS_CODE });
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/recoverpassword/sms/1", body);
    if (data.success) {
      dispatch({ type: types.REQUEST_SMS_CODE_SUCCESS });
      dispatch(handleAuthNavigation("via_sms_code"));
    } else {
      dispatch({
        type: types.REQUEST_SMS_CODE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REQUEST_SMS_CODE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REQUEST_SMS_CODE_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// validate SMS Code
export const validateSMSCode = smsCode => async (dispatch, getState) => {
  try {
    dispatch({ type: types.VALIDATE_SMS_CODE_REQUEST });
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      smsCode,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/recoverpassword/sms/2", body);
    if (data.success) {
      dispatch({ type: types.VALIDATE_SMS_CODE_SUCCESS });
      dispatch(handleAuthNavigation("reset_password"));
    } else {
      dispatch({
        type: types.VALIDATE_SMS_CODE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.VALIDATE_SMS_CODE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.VALIDATE_SMS_CODE_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// export const unblockUser = () => async (dispatch, getState) => {
//   try {
//   } catch (err) {
//   }
// };

export const changePassword = (oldPassword, newPassword, verifyPassword) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.CHANGE_PASSWORD_REQUEST });
    const {
      auth: { username, userId },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      userId,
      oldPassword,
      newPassword,
      verifyPassword,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/user/changepassword", body);
    if (data.success) {
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS, payload: data });
    } else {
      dispatch({ type: types.CHANGE_PASSWORD_FAILURE, payload: data.error });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

export const doSendRecoveryPasswordEmail = (
  username = "",
  callback = (success = false) => undefined,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SEND_RECOVERY_PASSWORD_EMAIL });

    const body = { username };

    const { data: response } = await post("/auth/password-recovery/send-email", body);

    const { data, success } = response;

    const user = {};

    if (!!data.name) user.name = data.name;
    if (!!data.email) user.email = data.email;
    if (!!data.avatar) user.avatar = data.avatar;

    if (!!success) {
      dispatch({ type: types.SEND_RECOVERY_PASSWORD_EMAIL_SUCCESS });
    } else {
      dispatch({ type: types.SEND_RECOVERY_PASSWORD_EMAIL_FAILURE });
    }

    await callback(!!success, user);
  } catch (error) {
    dispatch({ type: types.SEND_RECOVERY_PASSWORD_EMAIL_CRASH });

    await callback(false);
  }
};

export const doRecoveryPassword = (
  { password = "", idOLBUser = "", hash = "" },
  callback = (success = false) => undefined,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.RECOVERY_PASSWORD });

    const body = {
      idOLBUser,
      hash,
      password,
    };

    const { data: response } = await post(
      "/auth/password-recovery/change-password",
      body,
    );

    const { data, success } = response;

    const user = {};

    if (!!data.name) user.name = data.name;
    if (!!data.email) user.email = data.email;
    if (!!data.avatar) user.avatar = data.avatar;

    if (!!success) {
      dispatch({ type: types.RECOVERY_PASSWORD_SUCCESS });
    } else {
      dispatch({ type: types.RECOVERY_PASSWORD_FAILURE });
    }

    await callback(!!success, user);
  } catch (error) {
    dispatch({ type: types.RECOVERY_PASSWORD_CRASH });

    await callback(false);
  }
};

export const changeNonSecurePassword = (
  accountNumber,
  oldPassword,
  newPassword,
  verifyPassword,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CHANGE_NON_SECURE_PASSWORD_REQUEST });
    const {
      auth: { username },
      config: {
        config: { fiName },
      },
    } = getState();
    const body = {
      username,
      accountNumber,
      oldPassword,
      newPassword,
      verifyPassword,
      financialInstitutionName: fiName,
    };
    const { data } = await post("/user/changenonsecurepassword", body);
    if (data.success) {
      dispatch({
        type: types.CHANGE_NON_SECURE_PASSWORD_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.CHANGE_NON_SECURE_PASSWORD_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CHANGE_NON_SECURE_PASSWORD_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CHANGE_NON_SECURE_PASSWORD_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

export const handleTroubleshooting = type => dispatch => {
  dispatch({ type: types.SHOW_TROUBLESHOOTING_INFO, payload: type });
};

export const handleAuthNavigation = step => dispatch => {
  dispatch({ type: types.CHANGE_STEP_TO, payload: step });
};
