import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { AlertMessage } from "../../components/commons";
import styled from "styled-components";
import { Inbox } from "../../components/commons/icons";

const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  width: 450px;
  font-size: 0.9rem;
`;
// TODO: Change fixed width on this Grid!

export default function HorizontalList({ items, onItemRemoved }) {
  return (
    <Grid>
      {items &&
        _.map(items, ({ removable, id, text }, index) =>
          removable ? (
            <AlertMessage key={id}>
              {text}
              <Inbox onClick={() => onItemRemoved(index)} />
            </AlertMessage>
          ) : (
            <AlertMessage>{text}</AlertMessage>
          ),
        )}
    </Grid>
  );
}

HorizontalList.propTypes = {
  items: PropTypes.array.isRequired,
  onItemRemoved: PropTypes.func,
};
