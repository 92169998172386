import React, { useState } from "react";
import _ from "lodash";
import AdminCheckbox from "./AdminCheckbox";

function AdminCheckboxContainer({ checkboxs }) {
  const [active, setActive] = useState();

  const setClickedItem = id => {
    if (id !== null || id !== undefined) {
      setActive(id);
      return;
    }
    setActive(null);
  };

  const checkActive = id => {
    if (id === active) {
      return true;
    }
    return false;
  };

  return (
    <>
      {_.map(checkboxs, ({ id, title, description }, index) => (
        <AdminCheckbox
          active={checkActive(id)}
          key={index}
          id={id}
          title={title}
          description={description}
          setClickedItem={setClickedItem}
        />
      ))}
    </>
  );
}

export default AdminCheckboxContainer;
