import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "321px",
    borderRadius: "10px",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: "38px 34px 22px",
    "&:focus": {
      outline: "none",
    },
  },
  backDrop: {
    backdropFilter: "blur(12px)",
    backgroundColor: "rgba(216, 216, 216, 0.1)",
  },
}));

function CustomModal({ children, open, handleClose }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>{children}</div>
      </Fade>
    </Modal>
  );
}

export default CustomModal;
