import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "../../../../utils/server";

const useReports = (data = {}) => {
  const { userId, fiId } = data;

  const membersUrl = `/activity-reports/members/history/${fiId}`;
  const adminsUrl = `/activity-reports/admin/all`;

  const [members, setMembers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const asyncInit = useCallback(() => {
    if (userId && fiId) {
      get(membersUrl)
        .then(membersResponse => {
          if (membersResponse) {
            const { data = [] } = membersResponse.data;

            if (Array.isArray(data)) setMembers(data);
          }
        })
        .catch(console.warn);

      get(adminsUrl)
        .then(adminsResponse => {
          if (adminsResponse) {
            const { data = [] } = adminsResponse.data;

            if (Array.isArray(data)) setAdmins(data);
          }
        })
        .catch(console.warn);
    }
  }, [userId, fiId]);

  useEffect(asyncInit, []);

  return { members, admins };
};

useReports.defaultPrototype = {
  data: {},
};

useReports.prototype = {
  data: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    fiId: PropTypes.number.isRequired,
  }).isRequired,
};

export default useReports;
