import React, { useRef } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { Fade, Grid, Menu, MenuItem } from "@material-ui/core";
import TableGrid from "./TableGrid";
import TableGridItem from "./TableGridItem";
import Row from "./Row";
import TableTitle from "./TableTitle";
import TableSortTitle from "./TableSortTitle";
import FeatherIcon from "feather-icons-react";

const Table = ({
  handleClickRow,
  handleClickSort,
  handleCloseSortMenu,
  isOpenSortMenu,
  rows,
  sortMenuItems,
  sortLabel,
  title,
  type,
}) => {
  const menuRef = useRef(null);

  return (
    <TableGrid container>
      <TableGridItem padding container item justify="space-between" alignItems="center">
        <Grid item>
          <TableTitle>{title}</TableTitle>
        </Grid>

        <Grid item ref={menuRef}>
          <a onClick={handleClickSort}>
            <TableSortTitle>
              {sortLabel}&nbsp;
              <FeatherIcon icon="chevron-down" size="14" />
            </TableSortTitle>
          </a>

          <Menu
            id="fade-menu"
            anchorEl={menuRef.current}
            open={isOpenSortMenu}
            onClose={handleCloseSortMenu}
            TransitionComponent={Fade}
          >
            {sortMenuItems.map(item => (
              <MenuItem
                key={nanoid(15)}
                onClick={event => handleCloseSortMenu(event, item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </TableGridItem>

      {rows.map(row => (
        <Row
          {...row}
          key={nanoid(15)}
          onClick={async event => await handleClickRow(event, row)}
        />
      ))}
    </TableGrid>
  );
};

Table.defaultProps = {
  handleClickRow: (event, row) => null,
  handleClickSort: event => null,
  handleCloseSortMenu: event => null,
  isOpenSortMenu: false,
  rows: [],
  sortMenuItems: [],
  sortLabel: "",
  title: "",
  type: "",
};

Table.propTypes = {
  handleClickRow: PropTypes.func.isRequired,
  handleClickSort: PropTypes.func.isRequired,
  handleCloseSortMenu: PropTypes.func.isRequired,
  isOpenSortMenu: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  sortMenuItems: PropTypes.array.isRequired,
  sortLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Table;
