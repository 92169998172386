import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push, goBack } from "react-router-redux";
import styled from "styled-components";
import TextField from "../../components/commons/TextField";
import { Heading, P, Button, Loader } from "../../components/commons";
import { ConfirmationModal, SuccessModal } from "../../components/commons/Modals";
import { Check, ArrowBack } from "../../components/commons/icons";
import { resetMFA, showMFAResetModal, showMFAResetSuccessModal } from "./actions";

const Content = styled.div`
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 3px;

  @media (max-width: 967px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @media (min-width: 968px) {
    justify-content: start;

    & > form > div {
      width: 95%;
    }
    & > form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    & > form > button {
      margin: 1rem 0 1rem 16px;
    }
  }
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;

  & > small {
    color: #05ce86;
  }

  @media (max-width: 967px) {
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

class MFAUnlock extends Component {
  static propTypes = {
    isResetModalOpen: PropTypes.bool,
    isResetSuccessModalOpen: PropTypes.bool,
    resetMFA: PropTypes.func,
    showMFAResetModal: PropTypes.func,
    showMFAResetSuccessModal: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    push: PropTypes.func,
    goBack: PropTypes.func,
  };
  state = { accountNumber: "", submitSuccess: false };

  componentWillUpdate(nextProps, nextState) {
    if (!this.state.submitSuccess && nextState && nextState.submitSuccess) {
      this.props.push("/admin/panel/");
    }
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (this.state.accountNumber !== "") {
        this.props.resetMFA(this.state.accountNumber);
      } else {
        this.setState({ error: "Please fill the form" });
      }
    }
  };

  renderConfirmationModal() {
    return (
      <ConfirmationModal
        isOpen={this.props.isResetModalOpen}
        onClose={() => this.props.showMFAResetModal(false)}
        onSuccess={() => this.props.resetMFA(this.state.accountNumber)}
        headingText="Reset MFA"
        confirmationText={
          <span>
            <P>
              {
                "Please confirm this is the correct member before clicking the Reset button"
              }
            </P>{" "}
            <br /> <br />
            <span>Member: {this.state.accountNumber}</span>
          </span>
        }
        cancelButtonText="Back"
        successButtonText={"Reset"}
        aria-hidden={this.props.isResetModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderResetMFASuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.isResetSuccessModalOpen}
        onClose={() => {
          this.props.showMFAResetSuccessModal(false);
          this.setState({ submitSuccess: true });
        }}
        headingText="User Unblocked Successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  render() {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {this.renderConfirmationModal()}
        {this.renderResetMFASuccessModal()}
        <Loader isloading={this.props.isFetching} />
        <Content>
          <Heading>Reset MFA Settings</Heading>
          <P>Please enter the member&apos;s account number and continue</P>
          <form>
            <TextField
              onChange={event => this.setState({ accountNumber: event.target.value })}
              value={this.state.accountNumber}
              autoFocus
              onKeyPress={this.handleKeyPress}
              type="text"
              name="accountNumber"
              id="accountNumber"
              error={!!this.state.error || !!this.props.error}
              helperText={this.state.error || this.props.error}
              label={"Username or Account #"}
            />
            <Button
              primary
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                if (this.state.accountNumber !== "") {
                  this.props.showMFAResetModal(true);
                } else this.setState({ error: "please fill the form" });
              }}
            >
              Continue
            </Button>
          </form>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <BackButton>
              <ArrowBack onClick={() => this.props.goBack()} />
              <small>GO BACK</small>
            </BackButton>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isFetching: state.admin.isFetching,
  error: state.admin.error,
  isResetModalOpen: state.admin.showResetMFAModal,
  isResetSuccessModalOpen: state.admin.showResetMFASuccessModal,
});
export default connect(mapStateToProps, {
  push,
  goBack,
  resetMFA,
  showMFAResetModal,
  showMFAResetSuccessModal,
})(MFAUnlock);
