export const GET_CHALLENGE_QUESTIONS_REQUEST = "get_challenge_questions_request";
export const GET_CHALLENGE_QUESTIONS_SUCCESS = "get_challenge_questions_success";
export const GET_CHALLENGE_QUESTIONS_FAILURE = "get_challenge_questions_failure";

export const SAVE_CHALLENGE_QUESTIONS_REQUEST = "save_challenge_questions_request";
export const SAVE_CHALLENGE_QUESTIONS_SUCCESS = "save_challenge_questions_success";
export const SAVE_CHALLENGE_QUESTIONS_FAILURE = "save_challenge_questions_failure";

export const CHALLENGE_QUESTIONS_UPDATE_VALUE = "challenge_questions_update_value";

export const CHANGE_USER_PASSWORD_REQUEST = "change_user_password_request";
export const CHANGE_USER_PASSWORD_SUCCESS = "change_user_password_success";
export const CHANGE_USER_PASSWORD_FAILURE = "change_user_password_failure";

export const CHANGE_USER_PHRASE_REQUEST = "change_user_phrase_request";
export const CHANGE_USER_PHRASE_SUCCESS = "change_user_phrase_success";
export const CHANGE_USER_PHRASE_FAILURE = "change_user_phrase_failure";
