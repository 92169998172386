import React, { useEffect, useState } from "react";
import { Avatar, Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "../../../components/commons";
import { StepTitle } from "../steps/Steps";
import styles from "./changePasswordSuccess.module.css";

function ChangePassword({ history }) {
  const [user, setUser] = useState({
    avatar: "",
    name: "",
    email: "",
  });

  const goToLogin = () => {
    history.replace("/");
  };

  useEffect(() => {
    const { user = {} } = history.location.state || {};

    const { avatar = "", name = "", email = "" } = user;

    setUser({
      avatar,
      name,
      email,
    });
  }, []);

  return (
    <Container
      maxWidth="xs"
      style={{ minHeight: "100vh", display: "grid", paddingTop: 20 }}
    >
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        wrap="nowrap"
      >
        <Grid item xs={12} style={{ marginTop: 25 }} />
        <Grid item style={{ marginBottom: 50 }}>
          <StepTitle className={styles.changePasswordSuccessTitle}>
            Your password has been changed!
          </StepTitle>
        </Grid>

        {!!user.avatar && (
          <Grid item style={{ marginBottom: 35 }}>
            <Avatar
              alt="User Avatar"
              src={user.avatar}
              className={styles.changePasswordSuccessAvatar}
            />
          </Grid>
        )}

        {!!user.name && (
          <Grid item style={{ marginBottom: 10 }}>
            <StepTitle className={styles.changePasswordSuccessUsername}>
              {user.name}
            </StepTitle>
          </Grid>
        )}

        {!!user.email && (
          <Grid item style={{ marginBottom: 125 }}>
            <StepTitle className={styles.changePasswordSuccessUserEmail}>
              {user.email}
            </StepTitle>
          </Grid>
        )}

        <Grid item style={{ marginBottom: 45 }}>
          <StepTitle className={styles.changePasswordSuccessNote}>
            You can now log into Blossom.
          </StepTitle>
        </Grid>
        <Grid container direction="column" item style={{ marginBottom: 50 }}>
          <Button
            primary
            onClick={event => goToLogin(event)}
            style={{
              width: "100%",
              fontWeight: "bold",
              marginBottom: 20,
              color: "#ffffff",
            }}
          >
            Go to Login
          </Button>
        </Grid>
        <Grid item xs={12} />
      </Grid>
    </Container>
  );
}

ChangePassword.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

ChangePassword.defaultProps = {};

export default connect(null, {})(ChangePassword);
