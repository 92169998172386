import { REHYDRATE } from "redux-persist/lib/constants";
import _ from "lodash";
import moment from "moment";
import * as types from "./constants";

const initialState = {
  error: null,
  isFetching: false,
  scheduledPayments: null,
  payees: null,
  defaultPayees: null,
  autopayList: [],
  paymentActivityList: null,
  quickPayRows: [],
  autopayModal: false,
  payeeDetails: {},
  showSuccessModal: false,
  removeAutopayModalSuccess: false,
  quickPayToAutoPay: {},
  autoPayToQuickPayEntity: {},
  autoPayForUpdate: {},
  autoPayToQuickPayConfirmModalOpen: false,
  autoPayUpdateConfirmModalOpen: false,
};

export default function ePaymentReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REHYDRATE:
      const incomingState = payload && payload.epayment;
      if (incomingState) {
        return { ...state, quickPayRows: [], error: null };
      }
      return initialState;
    case types.GET_SCHEDULED_PAYMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_SCHEDULED_PAYMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        scheduledPayments: payload,
      };
    case types.GET_SCHEDULED_PAYMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.UNSCHEDULE_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.UNSCHEDULE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        scheduledPayments:
          state.scheduledPayments &&
          _.filter(state.scheduledPayments, ({ uniqueId }) => uniqueId !== payload),
      };
    case types.UNSCHEDULE_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_QUICK_PAYS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_QUICK_PAYS_SUCCESS:
      const quickPaymentsRows = [];
      payload.forEach(quickPay => {
        quickPaymentsRows.push({
          ...quickPay,
          amount: 0,
          fromAccountId: quickPay.defaultPayFromId,
          fromAccountType: quickPay.defaultPayFromType,
          paymentDate: moment().format(),
          comment: "",
          includeCommentOnCheck: false,
        });
      });
      return {
        ...state,
        isFetching: false,
        payees: payload,
        quickPayRows: quickPaymentsRows,
      };
    case types.GET_QUICK_PAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_DEFAULT_PAYEES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_DEFAULT_PAYEES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        defaultPayees: payload,
      };
    case types.GET_DEFAULT_PAYEES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_PAYEE_DETAIL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_PAYEE_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        payeeDetails: payload,
      };
    case types.GET_PAYEE_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.OPEN_REMOVE_AUTOPAY_MODAL:
      return {
        ...state,
        removeAutoPayModal: true,
      };
    case types.CLOSE_REMOVE_AUTOPAY_MODAL:
      return {
        ...state,
        removeAutoPayModal: false,
      };
    case types.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: true,
      };
    case types.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: false,
      };
    case types.QUICKPAY_TO_AUTOPAY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case types.QUICKPAY_TO_AUTOPAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.QUICKPAY_TO_AUTOPAY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.UPDATE_AUTOPAY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case types.UPDATE_AUTOPAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.UPDATE_AUTOPAY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.AUTOPAY_TO_QUICKPAY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case types.AUTOPAY_TO_QUICKPAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.AUTOPAY_TO_QUICKPAY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.REMOVE_AUTOPAY_REQUEST:
      return {
        ...state,
        isFetching: true,
        removeAutoPayModalError: "",
      };
    case types.REMOVE_AUTOPAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        autopayList:
          state.autopayList &&
          _.filter(state.autopayList, item => item.uniqueId !== payload),
        removeAutoPayModalError: "",
        removeAutoPayModal: false,
        removeAutopayModalSuccess: true,
      };
    case types.REMOVE_AUTOPAY_FAILURE:
      return {
        ...state,
        isFetching: false,
        removeAutoPayModalError: payload,
        error: payload,
      };
    case types.GET_PAYMENT_ACTIVITY_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_PAYMENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        paymentActivityList: payload,
      };
    case types.GET_PAYMENT_ACTIVITY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_AUTOPAY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.GET_AUTOPAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        autopayList: payload,
      };
    case types.GET_AUTOPAY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.INSERT_PAYEE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.INSERT_PAYEE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.INSERT_PAYEE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.UPDATE_PAYEE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.UPDATE_PAYEE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.UPDATE_PAYEE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.REQUEST_REMOVE_QUICKPAY_ROW:
      return {
        ...state,
        isFetching: true,
      };

    case types.REMOVE_QUICKPAY_ROW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        quickPayRows:
          state.quickPayRows &&
          _.filter(state.quickPayRows, ({ uniqueId }) => uniqueId !== payload),
      };
    case types.REMOVE_QUICKPAY_ROW_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.FORM_QUICKPAY_UPDATE_VALUE:
      const rowToUpdate =
        state.quickPayRows &&
        _.find(state.quickPayRows, row => row.uniqueId === payload.uniqueId);
      return {
        ...state,
        quickPayRows:
          state.quickPayRows &&
          rowToUpdate &&
          _.map(state.quickPayRows, row => {
            if (row.uniqueId === payload.uniqueId) {
              return {
                ...row,
                updated: true,
                [payload.name]: payload.value,
              };
            }
            return row;
          }),
      };
    case types.UPDATE_PAYEE_DETAIL_FORM_VALUE:
      return {
        ...state,
        payeeDetails: {
          ...state.payeeDetails,
          [payload.name]: payload.value,
        },
      };
    case types.FORM_QUICKPAY_RESET:
      return {
        ...state,
        quickPayRows: [],
      };
    case types.SHOW_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: payload,
      };
    case types.SHOW_SCHEDULE_AUTOPAY_SUCCESS_MODAL:
      return {
        ...state,
        autoPayToQuickPayConfirmModalOpen: true,
      };
    case types.HIDE_SCHEDULE_AUTOPAY_SUCCESS_MODAL:
      return {
        ...state,
        autoPayToQuickPayConfirmModalOpen: false,
      };
    case types.SET_LAST_PAY_DAY_VALUE:
      return {
        ...state,
        quickPayRows:
          state.quickPayRows &&
          _.map(state.quickPayRows, row => {
            if (row.id === payload.id) {
              return {
                ...row,
                lastPayDate: moment(payload.value).format("L"),
              };
            }
            return row;
          }),
      };
    case types.SET_OPTIONS_FROM_TYPE_QUICKPAY_ROW:
      return {
        ...state,
        quickPayRows:
          state.quickPayRows &&
          _.map(state.quickPayRows, row => {
            if (row.id === payload.id) {
              return {
                ...row,
                options: row.options.slice(0, 0).concat(payload.options),
              };
            }
            return row;
          }),
      };
    case "CLOSE_AUTOPAY_SUCCESS_MODAL":
      return {
        ...state,
        removeAutopayModalSuccess: false,
      };
    case types.SET_QUICKPAY_TO_AUTOPAY:
      return {
        ...state,
        quickPayToAutoPay: payload,
      };
    case types.SET_AUTOPAY_FOR_UPDATE:
      return {
        ...state,
        autoPayForUpdate: payload,
      };
    case types.CHANGE_E_PAYMENTS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case types.SHOW_AUTOPAY_TO_QUICKPAY_CONFIRM_MODAL:
      return {
        ...state,
        autoPayToQuickPayEntity: payload,
        autoPayToQuickPayConfirmModalOpen: true,
      };
    case types.HIDE_AUTOPAY_TO_QUICKPAY_CONFIRM_MODAL:
      return {
        ...state,
        autoPayToQuickPayEntity: {},
        autoPayToQuickPayConfirmModalOpen: false,
      };
    case types.SHOW_AUTOPAY_UPDATE_CONFIRM_MODAL:
      return {
        ...state,
        autoPayUpdateConfirmModalOpen: true,
      };
    case types.HIDE_AUTOPAY_UPDATE_CONFIRM_MODAL:
      return {
        ...state,
        autoPayUpdateConfirmModalOpen: false,
      };
    default:
      return state;
  }
}
