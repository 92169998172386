import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ sm }) => (sm ? "200px" : "343px")};
  overflow: hidden;
  background: #ffffff;
  position: absolute;
  border-radius: 12px;
  max-height: 400px;
  top: 70%;
  right: 50%;
  z-index: 20;
  border: 1px solid #ebeff3;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  overflow-y: auto;

  .notificationsFooter {
    background-color: #f4f6f8;
    border-top: 1px solid #ebeff3;
    button {
      font-size: 14px;
      font-weight: bold;
      padding-left: 19px;
      &:focus {
        outline: none;
      }
    }
  }
`;

const PopupRow = styled.div.attrs({ role: "option" })`
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 13px 19px;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
  flex-wrap: wrap;
  letter-spacing: 0;
  line-height: 19px;

  span {
    width: 100%;
  }

  .date {
    color: #828991;
    font-size: 15px;
    width: 100%;
  }

  &:hover {
    background: #fcfcfc;
  }
`;

class Popup extends React.Component {
  static defaultProps = {
    isOpen: false,
    children: null,
  };
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]),
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  render() {
    return (
      this.props.isOpen && (
        <Container innerRef={el => (this.popup = el)} {...this.props}>
          {this.props.children}
        </Container>
      )
    );
  }
}

const enhanceComponent = onClickOutside(Popup);

export { PopupRow };
export default enhanceComponent;
