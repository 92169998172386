import * as types from "./constants";

const initialState = {
  error: null,
  fundsTransfer: null,
  transfers: null,
  isFetching: false,
  confirmNum: null,
  isSuccessModalOpen: false,
};

export default function fundsTransferReducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_POST_FUNDS_TRANSFER:
      return {
        ...state,
        isFetching: true,
      };
    case types.POST_FUNDS_TRANSFER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        confirmNum: action.payload,
      };
    case types.POST_FUNDS_TRANSFER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.DISPATCH_VALIDATE_AMOUNT:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.SHOW_TRANSFER_SUCCESS_MODAL:
      return {
        ...state,
        isSuccessModalOpen: action.payload,
      };
    case types.SHOW_TRANSFER_ERROR_MODAL:
      return {
        ...state,
        isTransferErrorModalOpen: action.payload,
      };
    case types.REQUEST_GET_ACCOUNT_TRANSFERS:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_ACCOUNT_TRANSFERS_SUCCESS:
      const { transfers } = action.payload;
      return {
        ...state,
        isFetching: false,
        transfers,
      };
    case types.GET_ACCOUNT_TRANSFERS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
