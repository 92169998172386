import * as types from "./constants";
import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import { post } from "../../../utils/server";
const ROOT_URL = `/customer`;

export const postFundsTransfer = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_POST_FUNDS_TRANSFER });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post("/fundstransfer", {
      idOLBUser,
      ...payload,
      amount: payload.amount,
    });
    if (data.success) {
      dispatch({
        type: types.POST_FUNDS_TRANSFER_SUCCESS,
        payload: data.data.confirmNum,
      });
      dispatch(showTransferSuccessModal(true));
    } else {
      dispatch({
        type: types.POST_FUNDS_TRANSFER_FAILURE,
        payload: data.message,
      });
      dispatch(showTransferErrorModal(true));
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.POST_FUNDS_TRANSFER_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.POST_FUNDS_TRANSFER_FAILURE,
        payload: "Error trying to make a transfer",
      });
    }
    dispatch(showTransferErrorModal(true));
  }
};

export const getTransfers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_GET_ACCOUNT_TRANSFERS });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post(`${ROOT_URL}/transfersHistory`, { idOLBUser });
    if (data.success) {
      dispatch({
        type: types.GET_ACCOUNT_TRANSFERS_SUCCESS,
        payload: { transfers: data.data },
      });
    } else {
      dispatch({
        type: types.GET_ACCOUNT_TRANSFERS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_ACCOUNT_TRANSFERS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_ACCOUNT_TRANSFERS_FAILURE,
        payload: "error trying to get customer's transfers",
      });
    }
  }
};

export const showTransferSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_TRANSFER_SUCCESS_MODAL, payload: show });
export const showTransferErrorModal = show => dispatch =>
  dispatch({ type: types.SHOW_TRANSFER_ERROR_MODAL, payload: show });
export const dispatchValidateAmount = (message = "") => dispatch =>
  dispatch({ type: types.DISPATCH_VALIDATE_AMOUNT, payload: message });
