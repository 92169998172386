import moment from "moment";
import _ from "lodash";
import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import * as types from "./constants";
import { post } from "../../../utils/server";

export const startLoanApp = (payload, changeState) => async (dispatch, getState) => {
  try {
    dispatch({
      type: types.START_LOAN_APPLICATION_REQUEST,
      payload: payload.usingCoApplicant,
    });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post("/loanapp/startapp", { idOLBUser, ...payload });
    if (data.success) {
      const templateJson = JSON.parse(data.data.templateJson);
      const stepsWithDatesInitialized =
        templateJson.steps &&
        _.forEach(templateJson.steps, step => {
          if (step && step.fields) {
            _.forEach(step.fields, field => {
              if (field && field.type === "date" && field.enteredValue === "") {
                field.enteredValue = moment().format("YYYY-MM-DD");
              }
            });
          }
        });
      templateJson.steps = stepsWithDatesInitialized;
      dispatch({
        type: types.START_LOAN_APPLICATION_SUCCESS,
        payload: { ...data.data, templateJson },
      });
    } else {
      dispatch({
        type: types.START_LOAN_APPLICATION_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.START_LOAN_APPLICATION_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.START_LOAN_APPLICATION_FAILURE,
        payload: "error trying to start loan application",
      });
    }
  }
};

export const setLoanAppProgress = lastStep => async (dispatch, getState) => {
  try {
    dispatch({ type: types.SET_LOAN_APP_PROGRESS_REQUEST });
    const {
      auth: { userId: idOLBUser },
      loan: { applicationId, currentStep, templateJson },
    } = getState();
    const body = {
      idOLBUser,
      applicationId,
      applicationJson: templateJson,
      step: currentStep,
      submit: lastStep,
    };
    const { data } = await post("/loanapp/setprogress", body);
    if (data.success) {
      if (lastStep) {
        dispatch({
          type: types.SET_LOAN_APP_PROGRESS_SUCCESS,
          payload: { updatedJson: "{}", nextStep: 1 },
        });
        dispatch(displaySuccessModal(true));
      } else {
        dispatch({ type: types.SET_LOAN_APP_PROGRESS_SUCCESS, payload: data.data });
      }
    } else {
      dispatch({
        type: types.SET_LOAN_APP_PROGRESS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.SET_LOAN_APP_PROGRESS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.SET_LOAN_APP_PROGRESS_FAILURE,
        payload: "error trying to set loan app",
      });
    }
  }
};

export const goStepBack = currentStep => async (dispatch, getState) => {
  await dispatch({ type: types.LOAN_GO_STEP_BACK, payload: currentStep });
  // TODO: handle some error case
};
export const updateLoanValues = (key, name, value) => dispatch =>
  dispatch({ type: types.UPDATE_LOAN_VALUES, payload: { key, name, value } });
export const displaySuccessModal = display => dispatch =>
  dispatch({ type: types.DISPLAY_LOAN_APP_SUCCESS_MODAL, payload: display });
