import React from "react";

import _ from "lodash";
import styled from "styled-components";
import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";

const TagsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
`;

const TagContainer = styled.div`
  justify-content: start;
  display: flex;
  height: 13px;
  background-color: #ebeef1;
  border-radius: 5px;
  padding: 5px;
  margin-right: 6.5px;
  width: max-content;
  align-items: center;
`;

const TitleTag = styled.span`
  font-family: "Kumbh Sans";
  font-size: 12px;
  font-weight: normal;
  color: #1f2427;
`;

const IconTag = styled.div`
  margin-left: 14px;
  cursor: pointer;
`;

function FilterTags({ tags, deleteFilter }) {
  return (
    <TagsContainer>
      {tags &&
        _.map(tags, ({ title, id }) => (
          <TagContainer>
            <TitleTag>{title}</TitleTag>
            <IconTag onClick={() => deleteFilter(id)}>
              <FeatherIcon
                icon="x"
                stroke="#6b7073"
                style={{ width: "13px", height: "13px" }}
              />
            </IconTag>
          </TagContainer>
        ))}
    </TagsContainer>
  );
}

FilterTags.prototype = {
  tags: PropTypes.array,
  deleteFilter: PropTypes.func,
};

export default FilterTags;
