import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormThirdStep from "./FormThirdStep";

class ThirdStepAuth extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    handleChangeStep: PropTypes.func.isRequired,
    stepError: PropTypes.string,
    isFetching: PropTypes.bool,
    mfa: PropTypes.string,
  };

  render() {
    return (
      <>
        <FormThirdStep {...this.props} />
      </>
    );
  }
}

const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});

export default withStyles(loaderStyles)(ThirdStepAuth);
