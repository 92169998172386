import PropTypes from "prop-types";

const useTable = ({ afterHandleClickRow }) => {
  const handleClickRow = ({ target }, row = {}) => {
    // if (!!afterHandleClickRow) afterHandleClickRow(row);
  };

  return {
    handleClickRow,
  };
};

useTable.prototype = {
  loginAsMemberEview: (row = {}) => null,
};

useTable.prototype = {
  loginAsMemberEview: PropTypes.func.isRequired,
};

export default useTable;
