import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;

  p {
    display: inline;
  }
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    background: #e6444e;
    text-align: center;
    padding: 8px;
    width: 8px;
    color: white;
    font-size: 8px;
    font-weight: 500;
    border-radius: 50%;
  }
`;

export default function Icon({ src, badge, onClick }) {
  return (
    <ImageContainer onClick={onClick}>
      <img style={{ cursor: "pointer" }} src={src} alt="icon" />
      {badge && (
        <p>
          <span>{badge}</span>
        </p>
      )}
    </ImageContainer>
  );
}

Icon.propTypes = {
  onClick: PropTypes.func,
  src: PropTypes.string.isRequired,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
