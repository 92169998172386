import React from "react";

import MediaQuery from "react-responsive";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Dashboard,
  DashboardBody,
  DashboardContainer,
} from "../../Dashboard/DashboardInstance/Dashboard";
import DashboardHeader from "../../../components/layout/Header";
import AdminDashboardRoutes from "./AdminDashboardRoutes";
import * as actions from "../../Dashboard/actions";
import { logout } from "../../Auth/actions";
import SideNavDesktop from "../../../components/layout/SideNav/SideNavDesktop";
import SideNavMobile from "../../../components/layout/SideNav/SideNavMobile";

//TODO: change path

// this class only cares about UI interactions
class AdminDashboardInstance extends React.Component {
  static propTypes = {
    // header
    isSidenavOpen: PropTypes.bool,
    isLogoutModalOpen: PropTypes.bool,
    isMobileUserMenuOpen: PropTypes.bool,
    isMobileMenuOpen: PropTypes.bool,
    showMobileMenu: PropTypes.func,
    showMobileUserMenu: PropTypes.func,
    showMobileLogout: PropTypes.func,
    // body
    location: PropTypes.object,
    match: PropTypes.object,
    // sidenav
    showDashboardMenu: PropTypes.func,
    // auth
    logout: PropTypes.func,
    // config
    config: PropTypes.object,
  };

  handleOpenMobileMenu = show => {
    if (this.props.isMobileUserMenuOpen || this.props.isLogoutModalOpen) {
      return null;
    }
    return this.props.showMobileMenu(show);
  };
  handleOpenMobileUserMenu = show => {
    if (this.props.isMobileMenuOpen || this.props.isLogoutModalOpen) {
      return null;
    }
    return this.props.showMobileUserMenu(show);
  };
  handleLogout = () => this.props.logout(true);

  handleOpenSidenav = show => this.props.showDashboardMenu(show);

  render() {
    return (
      <div>
        <MediaQuery query="(max-width: 767px)">
          <div>
            {this.props.config && (
              <SideNavMobile
                menuConfig={this.props.config.adminMenuConfig}
                isMobileMenuOpen={this.props.isMobileMenuOpen}
                onLogout={this.handleLogout}
              />
            )}
          </div>
        </MediaQuery>
        <Dashboard>
          <DashboardContainer>
            <MediaQuery query="(min-width: 768px)">
              <SideNavDesktop
                onOpen={this.handleOpenSidenav}
                isSidenavOpen={this.props.isSidenavOpen}
                location={this.props.location}
                config={this.props.config}
                isAdmin
              />
            </MediaQuery>

            <DashboardBody
              isLogoutModalOpen={this.props.isLogoutModalOpen}
              isSidenavOpen={this.props.isSidenavOpen}
              isMobileMenuOpen={this.props.isMobileMenuOpen}
              isMobileUserMenuOpen={this.props.isMobileUserMenuOpen}
            >
              <DashboardHeader
                onOpenMobileMenu={this.handleOpenMobileMenu}
                onOpenMobileUserMenu={this.handleOpenMobileUserMenu}
                onOpenLogoutModal={this.handleOpenLogoutModal}
                onLogout={this.handleLogout}
                name={this.props.name}
                isSidenavOpen={this.props.isSidenavOpen}
                isAdmin
              />
              <AdminDashboardRoutes match={this.props.match} />
            </DashboardBody>
          </DashboardContainer>
        </Dashboard>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobileMenuOpen: state.ui.showMobileMenu,
  isMobileUserMenuOpen: state.ui.showMobileUserMenu,
  isLogoutModalOpen: state.ui.showLogoutModal,
  authenticated: state.admin.authenticated,
  name: state.admin.name,
  isSidenavOpen: state.ui.showDashboardMenu,
  config: state.config.config,
});

export default connect(mapStateToProps, { ...actions, logout })(AdminDashboardInstance);
