import React from "react";

import FeatherIcon from "feather-icons-react";
import { push } from "react-router-redux";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { Bell, Inbox, ArrowDown, User, Logout } from "../../../components/commons/icons";
import { Popup, PopupRow, Separator, IconHover } from "../../../components/commons";
import PoupNotifications from "../../../components/layout/Header/PoupNotifications";
import PoupMessages from "../../../components/layout/Header/PoupMessages";
import { Desktop } from "../../../components/commons/Responsive";
import { HeaderColumn } from "./styles";

const Header = styled.header`
  display: flex;
  min-height: 72px;
  background-color: #fcfcfc;
  padding: 0px 1rem;
  padding-right: 2rem;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
`;

const SeparatorHeader = styled.div`
  box-sizing: border-box;
  height: 60px;
  background: #ebeff3;
  width: 1px;
  margin-left: 20px;
  margin-right: 20px;
`;

const UserName = styled.span`
  margin-right: 5px;
  color: #1f2427;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
`;

const HeaderDesktop = ({
  isSidenavOpen,
  push,
  popup,
  isAdmin,
  notifications,
  setState,
  handleClickOutside,
  messages,
  name,
  onLogout,
  ...otherProps
}) => {
  const formatName = name => {
    if (name !== "") {
      name = `${name.substring(0, 1).toUpperCase()}${name
        .substring(1, name.length)
        .toLowerCase()}`;
    }

    if (name.indexOf(" ") > -1) {
      name = `${name.substring(0, name.indexOf(" "))}`;
    }

    return name;
  };

  return (
    <Desktop>
      <Header
        role="banner"
        aria-label="accounts dashboard"
        on={isSidenavOpen}
        {...otherProps}
      >
        <HeaderColumn fx={3} style={{ justifyContent: "flex-start" }}></HeaderColumn>
        <HeaderColumn>
          <FeatherIcon
            icon="life-buoy"
            style={{
              cursor: "pointer",
              stroke: "#1f2427",
              width: "22px",
              height: "22px",
              padding: "12px",
            }}
            onClick={() => push(isAdmin ? "/admin/panel/help" : "/dashboard/help")}
          />
        </HeaderColumn>

        <HeaderColumn>
          <IconHover>
            <Bell
              hasPopup
              hasFocus={popup.notifications.ariaFocus}
              ariaLabel={`${notifications.numberOfNotifications} notifications`}
              aria-controls="notificationsPopup"
              badge={notifications.numberOfNotifications || 0}
              stylesInput={{ width: "22px", height: "22px" }}
              onClick={() =>
                setState({
                  popup: {
                    ...popup,
                    notifications: {
                      ...popup.notifications,
                      isOpen: true,
                      ariaFocus: false,
                      ariaHidden: false,
                    },
                  },
                })
              }
            />
            <PoupNotifications
              popup={popup}
              handleClickOutside={handleClickOutside}
              notifications={notifications}
              isAdmin={isAdmin}
            />
          </IconHover>
        </HeaderColumn>
        {!isAdmin && (
          <>
            <HeaderColumn>
              <IconHover>
                <Inbox
                  hasPopup
                  hasFocus={popup.messages.ariaFocus}
                  aria-controls="messagesPopup"
                  ariaLabel={`${messages.numberOfMessages} messages`}
                  stylesInput={{ width: "22px", height: "22px" }}
                  badge={messages.numberOfMessages || 0}
                  onClick={() =>
                    setState({
                      popup: {
                        ...popup,
                        messages: {
                          ...popup.messages,
                          isOpen: true,
                          ariaHidden: false,
                          ariaFocus: false,
                        },
                      },
                    })
                  }
                />
                <PoupMessages
                  popup={popup}
                  handleClickOutside={handleClickOutside}
                  messages={messages}
                  isAdmin={isAdmin}
                />
              </IconHover>
            </HeaderColumn>
          </>
        )}

        <SeparatorHeader />
        <HeaderColumn>
          <img
            src="https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png"
            width="32"
            height="32"
            style={{ borderRadius: "50%", marginRight: "10px" }}
            alt="Header icon"
          />

          {name && <UserName>{formatName(name)}</UserName>}

          <IconHover padding="0px">
            <ArrowDown
              hasPopup
              hasFocus={popup.logout.ariaFocus}
              aria-controls="logoutPopup"
              ariaLabel="user options"
              stylesInput={{ height: "22px", width: "22px" }}
              onClick={() =>
                setState({
                  popup: {
                    ...popup,
                    logout: {
                      ...popup.logout,
                      isOpen: true,
                      ariaFocus: false,
                      ariaHidden: false,
                    },
                  },
                })
              }
            />
            <Popup
              sm
              id="logoutPopup"
              role="listbox"
              aria-expanded={popup.logout.isOpen}
              aria-hidden={popup.logout.ariaHidden}
              isOpen={popup.logout.isOpen}
              onClose={handleClickOutside}
            >
              <PopupRow
                role="rowgroup"
                aria-label="my account"
                onClick={() =>
                  push(isAdmin ? "/admin/panel/profile" : "/dashboard/profile")
                }
              >
                <span style={{ flex: "0 0 50px" }}>
                  <User />
                </span>
                <span style={{ flex: 1 }}>My Account</span>
              </PopupRow>
              <Separator />
              <PopupRow role="link" aria-label="logout" onClick={() => onLogout()}>
                <span style={{ flex: "0 0 50px" }}>
                  <Logout />
                </span>
                <span style={{ flex: 1 }}>Logout</span>
              </PopupRow>
            </Popup>
          </IconHover>
        </HeaderColumn>
      </Header>
    </Desktop>
  );
};

HeaderDesktop.prototype = {
  onLogout: PropTypes.func,
  isSidenavOpen: PropTypes.bool,
  isAdmin: PropTypes.bool,
  notifications: PropTypes.object,
  messages: PropTypes.object,
  push: PropTypes.func,
  name: PropTypes.string,
  popup: PropTypes.object,
  setState: PropTypes.func,
  handleClickOutside: PropTypes.func,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { push })(HeaderDesktop);
