import {
  FinancialInstitutionSecuredFormsCustomerStatus,
  FinancialInstitutionSecuredFormsCustomerSource,
} from "../../../enums";

export const isFavorite = status => {
  if (status === FinancialInstitutionSecuredFormsCustomerStatus.FAVORITE) {
    return true;
  }

  if (status === FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_FAVORITE) {
    return true;
  }

  return false;
};

export const changeStatus = (
  form,
  newStatus,
  oldStatus,
  changeFormSelected,
  changeStatusForm,
  sort,
  currentLocation,
  searchFriendlyName,
) => {
  const {
    FAVORITE,
    READ,
    ARCHIVED_READ,
    ARCHIVED_FAVORITE,
  } = FinancialInstitutionSecuredFormsCustomerStatus;

  if (newStatus === oldStatus && oldStatus === FAVORITE) {
    newStatus = READ;
  }

  if (newStatus === oldStatus && oldStatus === ARCHIVED_FAVORITE) {
    newStatus = ARCHIVED_READ;
  }

  if (changeFormSelected) {
    changeFormSelected({ ...form, status: newStatus });
  }

  changeStatusForm({
    id: form.id,
    status: newStatus,
    currentLocation,
    searchFriendlyName,
    sort: sort.value,
  });
};

export const isArchived = status => {
  const {
    ARCHIVED_UNREAD,
    ARCHIVED_READ,
    ARCHIVED_FAVORITE,
  } = FinancialInstitutionSecuredFormsCustomerStatus;

  if (
    status === ARCHIVED_UNREAD ||
    status === ARCHIVED_READ ||
    status === ARCHIVED_FAVORITE
  ) {
    return true;
  }

  return false;
};

export const getTextSource = source => {
  const { BANKING_APP } = FinancialInstitutionSecuredFormsCustomerSource;

  if (source === BANKING_APP) {
    return "Banking App";
  }

  return "Web";
};
