/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import "react-toastify/dist/ReactToastify.css";

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Grid } from "../../components/commons/icons";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import RequireAuth from "../../components/RequireAuth";
import { Heading, Loader, P } from "../../components/commons";
import RequireAdminAuth from "../../components/RequireAdminAuth";
import AuthPage from "../Auth";
import DashboardPage from "../Dashboard";
import AdminAuthPage from "../Admin/AdminAuthPage";
import AdminPanel from "../Admin/Panel";
import { getConfigByDomain } from "./actions";
import { newToken } from "../../utils/helper";
import {
  ChangePassword,
  ChangePasswordSuccess,
  ForgotYourPassword,
  ResetPasswordViaEmail,
} from "../Auth/recoverPassword";

function App({ hasFetched, getConfigByDomain, maxSessionMinutes, location, isFetching }) {
  useEffect(() => {
    //if (!this.props.hasFetched) {
    getConfigByDomain();
    if (maxSessionMinutes) {
      newToken(maxSessionMinutes);
    }
    //}
  }, [hasFetched, getConfigByDomain, maxSessionMinutes]);

  useEffect(() => {
    if (localStorage.getItem("request_update_token")) {
      localStorage.removeItem("request_update_token");
    }

    if (localStorage.getItem("request_update_token_admin")) {
      localStorage.removeItem("request_update_token_admin");
    }
  }, []);

  return (
    <>
      <Loader isLoading={isFetching} />

      <Switch>
        <Route exact path="/" component={AuthPage} />
        <Route path="/dashboard" component={RequireAuth(DashboardPage)} />
        <Route exact path="/admin" component={AdminAuthPage} />
        {/* Temporal route */}
        <Route exact path="/forgot-password" component={ForgotYourPassword} />
        <Route exact path="/reset-password-via-email" component={ResetPasswordViaEmail} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/change-password-success" component={ChangePasswordSuccess} />
        {/* End - Temporal route */}
        <Route path="/admin/panel/" component={RequireAdminAuth(AdminPanel)} />
        <Route
          path="/401"
          render={() => (
            <div
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid />
                <Heading>Your session expired</Heading>
                <P>
                  <Link to="/">Back</Link>
                </P>
              </div>
            </div>
          )}
        />
        <Route
          path="*"
          render={() => (
            <div
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid />
                <Heading>Page Not Found</Heading>
                <P>
                  <Link to="/">Back</Link>
                </P>
              </div>
            </div>
          )}
        />
      </Switch>

      <ToastContainer
        limit={2}
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

App.prototype = {
  getConfigByDomain: PropTypes.func,
  config: PropTypes.object,
  isFetching: PropTypes.bool,
  hasFetched: PropTypes.bool,
};

const mapStateToProps = state => ({
  config: state.config.config,
  isFetching: state.config.isFetching,
  maxSessionMinutes: state.config.maxSessionMinutes,
  hasFetched: state.config.hasFetched,
});
export default withRouter(connect(mapStateToProps, { getConfigByDomain })(App));
