import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FeatherIcon from "feather-icons-react";
import {
  InputLabel,
  Input,
  FormControl,
  FormHelperText,
  Select,
} from "@material-ui/core";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(1) * 2,
  },
  textField: {
    minWidth: 250,
    maxWidth: "auto",
    color: "#000000",
    fontSize: "0.875rem",
    fontWeight: "300",
    fontFamily: "Kumbh Sans",
  },
  cssLabel: {
    fontWeight: "300",
    fontFamily: "Kumbh Sans",
    fontSize: "15px",
    "&$cssFocused": {
      color: "#ACABB2",
    },
  },
  cssFocused: {
    color: "#BDBCC3",
  },
  cssUnderline: {
    "&:after": {
      borderColor: "#18243C",
      borderWidth: "1px",
    },
    "&:before": {
      borderColor: "#B9C3C7",
      borderWidth: "1px",
    },
    "&:hover:before": {
      borderBottom: "1px solid #B9C3C7 !important",
    },
  },
  select: {
    "&:focus": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  cssNoneborderBottom: {
    "&:after": {
      borderColor: "#fff",
    },
    "&:before": {
      borderColor: "#fff",
    },
    "&:hover:before": {
      borderBottom: "1px solid white !important",
    },
  },
  customSize: {
    minWidth: "200px",
  },
});

const Select2 = props => {
  const {
    classes,
    helperText,
    error,
    required,
    noneborderBottom,
    customSize,
    withoutMargin,
    ...inputProps
  } = props;
  return (
    <FormControl
      style={withoutMargin ? { margin: 0 } : { margin: "16px" }}
      required={required}
      fullWidth={props.fullWidth}
      className={classes.formControl}
    >
      <InputLabel
        error={!!props.error}
        classes={{
          root: classes.cssLabel,
          focused: !props.error && classes.cssFocused,
        }}
        htmlFor="age-helper"
      >
        {props.label}
      </InputLabel>
      <Select
        IconComponent={props => (
          <FeatherIcon
            {...props}
            icon="chevron-down"
            size="20"
            stroke="#000000"
            style={{ cursor: "pointer" }}
          />
        )}
        classes={{ root: classes.select }}
        input={
          <Input
            {...inputProps}
            error={!!props.error}
            classes={{
              underline: props.error
                ? null
                : noneborderBottom
                ? classes.cssNoneborderBottom
                : classes.cssUnderline,
            }}
            className={props.customSize ? classes.customSize : classes.textField}
          />
        }
      >
        {props.children}
      </Select>
      {props.helperText && <FormHelperText error>{props.helperText}</FormHelperText>}
    </FormControl>
  );
};

Select2.propTypes = {
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classes: PropTypes.object,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
};

export default withStyles(styles)(Select2);
