import React, { Component } from "react";
import { push } from "react-router-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { validationInactive, newToken } from "../utils/helper";
import Inactive from "./layout/Inactive";
import { withRouter } from "react-router-dom";

export default function (ComposedComponent) {
  class Authentication extends Component {
    static propTypes = {
      authenticated: PropTypes.bool,
      push: PropTypes.func,
    };

    refresToken(location, maxSessionMinutes) {
      if (
        validationInactive(location.pathname) &&
        !localStorage.getItem("request_update_token")
      ) {
        newToken(maxSessionMinutes);
      }
    }

    componentWillMount() {
      if (!this.props.authenticated) {
        this.props.push("/");
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        this.props.push("/");
      }
    }

    render() {
      return (
        <div
          onClick={() =>
            this.refresToken(this.props.location, this.props.maxSessionMinutes)
          }
          onKeyUp={() =>
            this.refresToken(this.props.location, this.props.maxSessionMinutes)
          }
        >
          {this.props.authenticated ? (
            <ComposedComponent {...this.props} />
          ) : (
            <Redirect to="/" />
          )}
          <Inactive maxSessionMinutes={this.props.maxSessionMinutes} />
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.auth.authenticated,
      maxSessionMinutes: state.config.maxSessionMinutes,
    };
  }

  return withRouter(connect(mapStateToProps, { push })(Authentication));
}
