import React, { Component } from "react";

import onClickOutside from "react-onclickoutside";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  selectNotifications,
  selectMessages,
} from "../../../containers/Dashboard/Accounts/selectors";
import HeaderMobile from "../../../components/layout/Header/HeaderMobile";
import HeaderDesktop from "../../../components/layout/Header/HeaderDesktop";

class Header extends Component {
  static defaultProps = {
    config: {
      fiLogo: "",
    },
  };
  static propTypes = {
    onLogout: PropTypes.func,
    onOpenMobileMenu: PropTypes.func,
    onOpenMobileUserMenu: PropTypes.func,
    onOpenLogoutModal: PropTypes.func,
    isSidenavOpen: PropTypes.bool,
    isMobileMenuOpen: PropTypes.bool,
    isAdmin: PropTypes.bool,
    notifications: PropTypes.object,
    messages: PropTypes.object,
    push: PropTypes.func,
    currentUsername: PropTypes.string,
    config: PropTypes.object,
  };
  state = {
    popup: {
      logout: {
        name: "logout",
        isOpen: false,
        ariaFocus: false,
        ariaHidden: true,
      },
      messages: {
        name: "messages",
        isOpen: false,
        ariaFocus: false,
        ariaHidden: true,
      },
      notifications: {
        name: "notifications",
        isOpen: false,
        ariaFocus: false,
        ariaHidden: true,
      },
    },
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  resetPopup() {
    this.setState({
      popup: {
        logout: {
          name: "logout",
          isOpen: false,
          ariaFocus: false,
          ariaHidden: true,
        },
        messages: {
          name: "messages",
          isOpen: false,
          ariaFocus: false,
          ariaHidden: true,
        },
        notifications: {
          name: "notifications",
          isOpen: false,
          ariaFocus: false,
          ariaHidden: true,
        },
      },
    });
  }

  escFunction = event => {
    if (event.keyCode === 27) {
      const popups = _.map(this.state.popup);
      const selectedPopup = _.find(popups, p => p.isOpen === true);
      if (selectedPopup) {
        this.setState({
          popup: {
            notifications:
              this.state.popup.notifications.name === selectedPopup.name
                ? {
                    ...this.state.popup.notifications,
                    ariaFocus: true,
                    ariaHidden: true,
                    isOpen: false,
                  }
                : { ...this.state.popup.notifications },
            messages:
              this.state.popup.messages.name === selectedPopup.name
                ? {
                    ...this.state.popup.messages,
                    ariaFocus: true,
                    ariaHidden: true,
                    isOpen: false,
                  }
                : { ...this.state.popup.messages },
            logout:
              this.state.popup.logout.name === selectedPopup.name
                ? {
                    ...this.state.popup.logout,
                    ariaFocus: true,
                    ariaHidden: true,
                    isOpen: false,
                  }
                : { ...this.state.popup.logout },
          },
        });
      }
    }
  };

  handleClickOutside = () => {
    this.resetPopup();
  };

  render() {
    const similarProps = {
      isAdmin: this.props.isAdmin,
      notifications: this.props.notifications,
      messages: this.props.messages,
      popup: this.state.popup,
      setState: data => this.setState(data),
      handleClickOutside: this.handleClickOutside,
    };

    return (
      <div style={{ position: "relative", zIndex: 20 }}>
        <HeaderMobile
          onOpenMobileMenu={this.props.onOpenMobileMenu}
          isMobileMenuOpen={this.props.isMobileMenuOpen}
          {...similarProps}
        />
        <HeaderDesktop
          isSidenavOpen={this.props.isSidenavOpen}
          popup={this.state.popup}
          {...similarProps}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: selectNotifications(state),
  messages: selectMessages(state),
  currentUsername: state.auth.username,
  isMobileMenuOpen: state.ui.showMobileMenu,
  config: state.config.config,
});

const enhanceComponent = onClickOutside(Header);

export default connect(mapStateToProps, { push })(enhanceComponent);
