import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BtnWithoutBg = styled.a`
  background: none;
  cursor: pointer;
  font-weight: ${props => (props.primary ? "400" : "100")};
  display: flex;
  line-height: 1.5;
  max-height: 40px;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 160px;
  text-transform: capitalize;
  font-size: 1rem;
  padding: ${props => (props.primary || props.danger || props.info ? "0.5rem" : "1 px")};
  margin: ${({ margin }) => margin || "1px"};
  color: ${({ primary, danger, info }) =>
    primary ? "#05CE86" : danger ? "#FF6670" : info ? "#66CBFF" : "#7A7A7A"};
  border: none;
  outline: none;

  & > img {
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }
`;

export default function ButtonWithIcon(props) {
  return (
    <BtnWithoutBg
      primary={props.primary}
      danger={props.danger}
      info={props.info}
      margin={props.margin}
    >
      <div style={{ padding: "0px 15px" }}>{props.icon()}</div>
      {props.text}
    </BtnWithoutBg>
  );
}

ButtonWithIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  text: PropTypes.string,
  primary: PropTypes.bool,
  margin: PropTypes.string,
  danger: PropTypes.string,
  info: PropTypes.string,
};
