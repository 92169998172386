import React from "react";
import styled from "styled-components";
import { CheckRoundGreen } from "../../../components/commons/icons";
import CurrencyFormat from "react-currency-format";

const ContainerCard = styled.span`
  height: 74px;
  width: 100%;
  display: flex;
  border-radius: 12px;
  padding: 0px 10px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(63, 199, 142, 0.1);
  }
  .container {
    width: 100%;
  }
  .description {
    display: block;
    color: #000000;
    font-family: Kumbh Sans;
    font-size: 15px;
  }
  .numberAccount {
    display: block;
    color: #899fbc;
    font-family: Kumbh Sans;
    font-size: 14px;
  }
  .amount {
    display: block;
    color: #899fbc;
    font-size: 14px;
  }
  .iconSelect {
    display: flex;
    align-items: center;
  }
`;

const CardAccounts = props => {
  const { isSelected, description, numberAccount, amount, onClick, style, img } = props;
  return (
    <ContainerCard
      style={{
        backgroundColor: isSelected && "rgba(63,199,142,0.1)",
        ...style,
      }}
      onClick={e => onClick(numberAccount)}
    >
      <div className="container">
        {img ? (
          <span className="imgDescription">
            <img src={description} alt="ImageDescription" />
          </span>
        ) : (
          <span className="description">{description}</span>
        )}
        <span className="numberAccount">{numberAccount}</span>
        <span className="amount">
          <CurrencyFormat
            value={amount}
            displayType={"text"}
            thousandSeparator
            prefix={"$"}
          />
        </span>
      </div>
      {isSelected && (
        <div className="iconSelect">
          <CheckRoundGreen stylesInput={{ width: "20px", height: "20px", opacity: 1 }} />
        </div>
      )}
    </ContainerCard>
  );
};

export default CardAccounts;
