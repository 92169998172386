import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.label`
  text-decoration: none;
  border: 3px solid #05ce86;
  border-radius: 50%;
  color: #90ddd0;
  line-height: 0;
  input[type="radio"] {
    -moz-appearance: none; /* Firefox */
    -ms-appearance: none; /* IE */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    border: 3px solid ${({ checked }) => (checked ? "#05ce86" : "transparent")};
    border-radius: 50%;
    margin: 2px;
    cursor: pointer;
  }
`;

class RadioButton extends Component {
  static propTypes = {
    label: PropTypes.string,
  };
  state = {
    checked: false,
  };
  render() {
    return (
      <Container
        checked={this.state.checked}
        onClick={() => this.setState({ checked: !this.state.checked })}
      >
        <input type="radio" value="option1" checked={this.state.checked} />
        {this.props.label}
      </Container>
    );
  }
}

export default RadioButton;
