import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { HeadingModal, Button } from "../../components/commons";
import { Cancel } from "../../components/commons/icons";
import styled from "styled-components";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "80%",
    minWidth: 255,
    maxWidth: 400,
    height: "auto",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    transform: "translate(-50%, -50%)",
    padding: "0",
    overflow: "hidden",
    ".title": {
      fontSize: "12px",
    },
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 30,
    backgroundColor: "rgba(230, 230, 230, 0.8)",
  },
};

const ContainerPhoto = styled.div`
  display: flex;
  align-items: center;
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 116px;
    width: 286px;
  }
`;

const SuccessModal = props => (
  <Modal
    {...props}
    isOpen={props.isOpen}
    style={customStyles}
    aria={{
      labelledby: "heading",
      describedby: "full_description",
    }}
  >
    <div className="CustomModalHeader">
      <span>
        <HeadingModal>{props.headingText}</HeadingModal>
      </span>
      <span>
        <Cancel onClick={props.onClose} />
      </span>
    </div>
    <div className="successModalBody">{props.successIcon}</div>
  </Modal>
);

const ReceiptModal = props => (
  <Modal
    {...props}
    isOpen={props.isOpen}
    style={customStyles}
    aria={{
      labelledby: "heading",
      describedby: "full_description",
    }}
  >
    <div className="CustomModalHeader">
      <span>
        <HeadingModal>{props.headingText}</HeadingModal>
      </span>
      <span>
        <Cancel onClick={props.onClose} />
      </span>
    </div>
    <div className="successModalBody">
      <ContainerPhoto>
        <div
          dangerouslySetInnerHTML={{
            __html: props.receiptImage
          }}
        >

        </div>
      </ContainerPhoto>

      {/* <img
          src={props.receiptImage}
          // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXUViUQMThPkBwVp05lO7luNnq9FaNQB3G-Q&usqp=CAU"
          alt="ReceiptImage"
        /> */}

    </div>
    <div className="receiptModalBody">
      Check Amount
      <span className="receiptModalValue">
        ${props.amount}
      </span>
    </div>
    <div className="receiptModalBody">
      Deposit date
      <span className="receiptModalValue">
        {props.effectiveDate}
      </span>
    </div>
  </Modal>
);

const CustomModal = props => (
  <Modal
    isOpen={props.isOpen}
    style={{
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "auto",
        minWidth: 255,
        maxWidth: 600,
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
        transform: "translate(-50%, -50%)",
        padding: "0",
        borderRadius: "10px",
        maxHeight: "85%",
      },
      overlay: {
        zIndex: 30,
        height: "100vh",
        width: "100%",
        backgroundColor: "rgba(230, 230, 230, 0.8)",
      },
    }}
  >
    {props.children}
  </Modal>
);

const ConfirmationModal = props => (
  <Modal {...props} isOpen={props.isOpen} style={customStyles}>
    <div className="CustomModalHeader">
      <span style={{ flex: 1 }}>
        <HeadingModal id="heading">{props.headingText}</HeadingModal>
      </span>
      <span>
        <Cancel onClick={props.onClose} />
      </span>
    </div>
    <div style={{ overflow: "auto", padding: "1rem" }} id="full_description">
      <div>{props.confirmationText}</div>
    </div>
    {props.children && props.children}
    <div className="modalFooter">
      {props.cancelButtonText && (
        <Button onClick={props.onClose}>{props.cancelButtonText || "Cancel"}</Button>
      )}
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      {props.successButtonText && (
        <Button primary onClick={props.onSuccess}>
          {props.successButtonText || "Ok"}
        </Button>
      )}
    </div>
  </Modal>
);

const ModalMobile = ({ isOpen, children, style }) => (
  <Modal
    isOpen={isOpen}
    style={{
      overlay: {
        position: "absolute",
        top: 80,
      },
      content: {
        margin: 0,
        top: -10,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 2,
        padding: "40px 18px",
        ...style,
      },
    }}
  >
    {children}
  </Modal>
);

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  headingText: PropTypes.string,
  confirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelButtonText: PropTypes.string,
  successButtonText: PropTypes.string,
  children: PropTypes.any,
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  headingText: PropTypes.string,
  ReceiptModal: PropTypes.any,
};

ReceiptModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  headingText: PropTypes.string,
  receiptImage: PropTypes.any,
  amount: PropTypes.string,
  effectiveDate: PropTypes.string,

};

CustomModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

ModalMobile.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

export { ModalMobile, SuccessModal, ConfirmationModal, CustomModal, ReceiptModal };
