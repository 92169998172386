import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../components/commons";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment, CircularProgress } from "@material-ui/core";
import TextField from "../../../components/commons/TextField";
import {
  StepBox,
  StepBody,
  StepBackButton,
  StepTitle,
  StepControls,
  StepForm,
  CurrentStep,
  StepLogo,
  StepText,
} from "../steps/Steps";

class EnterLoginCode extends React.Component {
  static defaultProps = {
    onSubmit: () => {},
    handleChangeStep: PropTypes.func,
  };
  static propTypes = {
    handleChangeStep: PropTypes.func,
    classes: PropTypes.object,
    onSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    stepError: PropTypes.string,
  };
  state = {
    temporaryPassword: "",
    error: "",
    smsCode: "",
  };

  handleSubmit() {
    const { smsCode } = this.state;
    if (smsCode && smsCode !== "") {
      this.props.onSubmit(smsCode);
      this.setState({ error: null });
    }
    this.setState({ error: "Please enter sms code" });
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.handleSubmit();
    }
  };

  render() {
    const { config } = this.props;
    return (
      <StepBox>
        <StepLogo src={config && config.fiLogo} />
        <StepBody>
          <CurrentStep step={"Step 2 of 3"} />
          <StepTitle>Please enter 4 digit recovery code</StepTitle>
          <StepText>The recovery code was sent to your phone number.</StepText>
          <StepForm>
            <TextField
              onChange={event => this.setState({ smsCode: event.target.value })}
              value={this.state.smsCode}
              onKeyPress={this.handleKeyPress}
              type="text"
              maxLength={4}
              name="smscode"
              id="smscode"
              error={this.props.stepError || this.state.error}
              helperText={this.props.stepError || this.state.error}
              label={"SMS Code"}
              endAdornment={
                <InputAdornment position="end">
                  {this.props.isFetching && (
                    <CircularProgress
                      className={this.props.classes.progress}
                      style={{ color: "#05CE86", width: 30, height: 30 }}
                    />
                  )}
                </InputAdornment>
              }
            />
          </StepForm>
          <StepControls>
            <Button
              disabled={this.props.isFetching}
              primary={!this.props.isFetching}
              onClick={() => this.handleSubmit()}
            >
              Confirm
            </Button>
          </StepControls>
        </StepBody>
        <StepBackButton onClick={() => this.props.handleChangeStep("forgot_password")} />
      </StepBox>
    );
  }
}
const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});
export default withStyles(loaderStyles)(EnterLoginCode);
