import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { validationInactive, newToken } from "../utils/helper";
import Inactive from "./layout/Inactive";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux";

export default function (ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    static propTypes = {
      authenticated: PropTypes.bool,
      replace: PropTypes.func,
    };

    refresToken(location, maxSessionMinutes) {
      if (
        validationInactive(location.pathname) &&
        !localStorage.getItem("request_update_token_admin")
      ) {
        newToken(maxSessionMinutes, true);
      }
    }

    componentWillMount() {
      if (!this.props.authenticated) {
        this.props.push("/admin");
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        this.props.push("/admin");
      }
    }

    render() {
      return (
        <div
          onClick={() =>
            this.refresToken(this.props.location, this.props.maxSessionMinutes)
          }
          onKeyUp={() =>
            this.refresToken(this.props.location, this.props.maxSessionMinutes)
          }
        >
          <ComposedComponent {...this.props} />
          <Inactive maxSessionMinutes={this.props.maxSessionMinutes} isAdmin />
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      authenticated: state.admin.authenticated,
      maxSessionMinutes: state.config.maxSessionMinutes,
    };
  }

  return withRouter(connect(mapStateToProps, { push })(Authentication));
}
