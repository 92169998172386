import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

const BackButton = styled.button`
  display: ${({ currentStep }) => (currentStep !== "1" ? "flex" : "none")};
  width: 120px;
  height: 35px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: #0ac77b;
  cursor: pointer;
  background: transparent;
  border: 0;
  font-size: 14px;
`;
BackButton.propTypes = {
  currentStep: PropTypes.string,
};
export default connect(({ auth: { currentStep } }) => ({ currentStep }))(BackButton);
