import styled from "styled-components";

const QuickLinks = styled.div`
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 320px) {
    flex-direction: column;
  }

  @media (max-width: 769px) {
    flex-direction: row;
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const QLink = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 310px;
  min-width: 310px;
  height: 220px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-image: ${({ imageUrl }) => imageUrl && `url(${imageUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 769px) {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 769px) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export { QuickLinks, QLink };
