import { REHYDRATE } from "redux-persist/lib/constants";
import * as types from "./constants";

const initialState = {
  isFetching: false,
  error: null,
  templateJson: {},
  currentStep: 0,
  applicationId: null,
  showSuccessModal: false,
};

export default function loanReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REHYDRATE:
      const incomingState = payload && payload.loan;
      if (incomingState) {
        return { ...state, ...incomingState, error: null };
      }
      return state;
    case types.START_LOAN_APPLICATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.START_LOAN_APPLICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentStep: 1,
        templateJson: payload.templateJson,
        applicationId: payload.applicationId,
      };
    case types.START_LOAN_APPLICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.SET_LOAN_APP_PROGRESS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.SET_LOAN_APP_PROGRESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        templateJson: JSON.parse(payload.updatedJson),
        currentStep: payload.nextStep,
        error: null,
      };
    case types.SET_LOAN_APP_PROGRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.LOAN_GO_STEP_BACK:
      return {
        ...state,
        currentStep: state.currentStep >= 1 ? payload - 1 : payload,
        applicationId: state.currentStep > 1 ? state.applicationId : null,
        error: null,
      };
    case types.DISPLAY_LOAN_APP_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessModal: payload,
        currentStep: payload ? state.currentStep : 0,
      };
    case types.UPDATE_LOAN_VALUES:
      const { key, name, value } = payload;
      return {
        ...state,
        templateJson: {
          ...state.templateJson,
          steps: {
            ...state.templateJson.steps,
            [key]: {
              ...state.templateJson.steps[key],
              fields: {
                ...state.templateJson.steps[key].fields,
                [name]: {
                  ...state.templateJson.steps[key].fields[name],
                  enteredValue: value,
                },
              },
            },
          },
        },
      };
    default:
      return state;
  }
}
