import styled from "styled-components";
import PropTypes from "prop-types";

const RowStatus = styled.strong`
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ error = false }) => (!!error ? "#cb3737" : "#3fc78e")};
  font-family: "Kumbh Sans";
`;

RowStatus.defaultProps = {
  error: false,
};

RowStatus.propTypes = {
  error: PropTypes.bool.isRequired,
};

export default RowStatus;
