import * as types from "./constants";
const fiInitialState = {
  isFetching: false,
  hasFetched: false,
  config: null,
  error: null,
  financialInstitutionId: null,
  helpSections: null,
};

function fiReducer(state = fiInitialState, action) {
  switch (action.type) {
    case types.GET_FI_CONFIG_REQUEST:
      return { ...state, isFetching: true };
    case types.GET_FI_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        config: action.payload.setup,
        hasFetched: true,
        financialInstitutionId: action.payload.financialInstitutionId,
        maxSessionMinutes: action.payload.maxSessionMinutes,
        Check_Images: action.payload.Check_Images,
      };
    case types.GET_FI_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        config: {
          fiName: "",
          fiLogo: "",
          fiFooterLogo: "",
          fiDisclaimer: "",
          fiPaymentDisclosure: "",
          fiContactEmail: "",
          fiContactPhone: "",
          fiTextBankingNumber: "",
          menuConfig: [],
        },
      };
    default:
      return state;
  }
}

export default fiReducer;
