export const sortFormOptions = [
  {
    label: "Unread First",
    value: "UNREAD_FIRST",
  },
  {
    label: "A to Z",
    value: "A_TO_Z",
  },
];
