import styled from "styled-components";
export default styled.input`
  border-bottom: ${({ errorMessage, dark }) =>
    dark ? "none" : errorMessage ? "1px solid #fd6773" : "1px solid #DCDCDC"};
  background-color: ${({ dark }) => (dark ? "#EFEFEF" : "transparent")};
  padding: ${({ dark }) => (dark ? "0.4rem 1rem" : "0")};
  border-radius: ${({ dark }) => (dark ? "10px" : "initial")};
  font-size: 0.9rem;
  outline: transparent;
  width: ${({ width }) => width || "auto"};
  height: ${({ dark }) => (dark ? "auto" : "35px")};
`;
