export const START_LOAN_APPLICATION_REQUEST = "start_loan_application_request";
export const START_LOAN_APPLICATION_SUCCESS = "start_loan_application_success";
export const START_LOAN_APPLICATION_FAILURE = "start_loan_application_failure";

export const SET_LOAN_APP_PROGRESS_REQUEST = "set_loan_app_progress_request";
export const SET_LOAN_APP_PROGRESS_SUCCESS = "set_loan_app_progress_success";
export const SET_LOAN_APP_PROGRESS_FAILURE = "set_loan_app_progress_failure";

export const SUBMIT_LOAN_APPLICATION_REQUEST = "submit_loan_application_request";
export const SUBMIT_LOAN_APPLICATION_SUCCESS = "submit_loan_application_success";
export const SUBMIT_LOAN_APPLICATION_FAILURE = "submit_loan_application_";

export const UPDATE_LOAN_VALUES = "update_loan_values";
export const LOAN_GO_STEP_BACK = "loan_go_step_back";
export const DISPLAY_LOAN_APP_SUCCESS_MODAL = "show_loan_app_success_modal";
