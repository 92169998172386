import React from "react";
import PropTypes from "prop-types";
import styles from "./dashboard.module.css";

const Dashboard = ({ children }) => <div>{children}</div>;
const DashboardHeaderInstance = ({ children }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>
);

class DashboardBody extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isLogoutModalOpen !== this.props.isLogoutModalOpen ||
      nextProps.isMobileMenuOpen !== this.props.isMobileMenuOpen ||
      nextProps.isMobileUserMenuOpen !== this.props.isMobileUserMenuOpen ||
      nextProps.isSidenavOpen !== this.props.isSidenavOpen
    )
      return false;

    return true;
  }
  render() {
    return <div className={styles.body}>{this.props.children}</div>;
  }
}

const DashboardScreens = ({ children }) => (
  <div className={styles.screens}>{children}</div>
);
const DashboardScreensWithSubmMenu = ({ children }) => (
  <div className={styles.screensSubMenu}>{children}</div>
);
const DashboardContainer = ({ children }) => (
  <div style={{ display: "flex" }}>{children}</div>
);

// Types
Dashboard.propTypes = { children: PropTypes.node };
DashboardContainer.propTypes = { children: PropTypes.node };
DashboardHeaderInstance.propTypes = { children: PropTypes.node };
DashboardBody.propTypes = {
  children: PropTypes.node,
  isSidenavOpen: PropTypes.bool,
  isLogoutModalOpen: PropTypes.bool,
  isMobileMenuOpen: PropTypes.bool,
  isMobileUserMenuOpen: PropTypes.bool,
};
DashboardScreens.propTypes = { children: PropTypes.node };

export {
  Dashboard,
  DashboardHeaderInstance,
  DashboardBody,
  DashboardScreens,
  DashboardContainer,
  DashboardScreensWithSubmMenu,
};
