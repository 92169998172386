import _ from "lodash";
import * as types from "./constants";
const initialState = {
  error: null,
  isFetching: false,
  customerPhones: null,
  commands: null,
  removePhoneModal: false,
  phoneUsage: null,
  showSuccessModal: false,
  linkedAccounts: [],
};

export default function textBankingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_TEXT_BANKING_USAGE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_TEXT_BANKING_USAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        phoneUsage: payload,
      };
    case types.GET_TEXT_BANKING_USAGE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_TEXT_BANKING_DISCLOSURE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_TEXT_BANKING_DISCLOSURE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        disclosure: payload,
      };
    case types.GET_TEXT_BANKING_DISCLOSURE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_CUSTOMER_PHONES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CUSTOMER_PHONES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        customerPhones: payload,
      };
    case types.GET_CUSTOMER_PHONES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.STEP_1_INSERT_CUSTOMER_PHONE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case types.STEP_1_INSERT_CUSTOMER_PHONE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.STEP_1_INSERT_CUSTOMER_PHONE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.STEP_2_INSERT_CUSTOMER_PHONE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.STEP_2_INSERT_CUSTOMER_PHONE_SUCCESS:
      return {
        ...state,
        customerPhones: [...state.customerPhones, payload.phone],
        isFetching: false,
      };
    case types.STEP_2_INSERT_CUSTOMER_PHONE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.REMOVE_CUSTOMER_PHONE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.REMOVE_CUSTOMER_PHONE_SUCCESS:
      return {
        ...state,
        customerPhones: _.filter(state.customerPhones, p => p.id !== payload),
        isFetching: false,
      };
    case types.REMOVE_CUSTOMER_PHONE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_TEXT_BANKING_COMMANDS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_TEXT_BANKING_COMMANDS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        commands: payload,
      };
    case types.GET_TEXT_BANKING_COMMANDS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.GET_PHONE_LINKED_ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_PHONE_LINKED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        linkedAccounts: payload,
      };
    case types.GET_PHONE_LINKED_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.UPDATE_LINKED_ACCOUNT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.UPDATE_LINKED_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        showSuccessModal: true,
      };
    case types.UPDATE_LINKED_ACCOUNT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.INITIALIZE_LINKED_ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.INITIALIZE_LINKED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.INITIALIZE_LINKED_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case types.OPEN_CONFIRM_DELETE_MODAL:
      return {
        ...state,
        removePhoneModal: true,
        error: null,
      };
    case types.CLOSE_CONFIRM_DELETE_MODAL:
      return {
        ...state,
        removePhoneModal: false,
        error: null,
      };
    case "CLOSE_SUCCESS_MODAL":
      return {
        ...state,
        showSuccessModal: false,
      };
    case types.UPDATE_LINKED_ACCOUNTS_FORM_VALUE:
      return {
        ...state,
        linkedAccounts:
          state.linkedAccounts &&
          _.map(state.linkedAccounts, acc => {
            if (acc.displayAccountId && acc.displayAccountId === payload.id) {
              return {
                ...acc,
                [payload.name]: payload.value,
                updated: true,
              };
            }
            return acc;
          }),
      };
    default:
      return state;
  }
}
