/*
 *
 * Dashboard constants
 *
 */

// CUSTOMER ENDPOINT (ACCOUNT)

// accounts
export const REQUEST_GET_ACCOUNT_SUMMARY = "request_get_account_summary";
export const GET_ACCOUNT_SUMMARY_SUCCESS = "get_account_summary_success";
export const GET_ACCOUNT_SUMMARY_FAILURE = "get_account_summary_failure";

// messages
export const REQUEST_GET_CUSTOMER_MESSAGES = "request_get_customer_messages";
export const GET_CUSTOMER_MESSAGES_SUCCESS = "get_customer_messages_success";
export const GET_CUSTOMER_MESSAGES_FAILURE = "get_customer_messages_failure";

export const REQUEST_REMOVE_CUSTOMER_MESSAGE = "request_remove_customer_message";
export const REMOVE_CUSTOMER_MESSAGE_SUCCESS = "remove_customer_message_success";
export const REMOVE_CUSTOMER_MESSAGE_FAILURE = "remove_customer_message_failure";
export const SHOW_REMOVE_MESSAGE_CONFIRMATION_MODAL =
  "show_remove_message_confirmation_modal";
export const SHOW_REMOVE_MESSAGE_SUCCESS_MODAL = "show_remove_message_success_modal";

// notifications
export const REQUEST_GET_CUSTOMER_NOTIFICATIONS = "request_get_customer_notifications";
export const GET_CUSTOMER_NOTIFICATIONS_SUCCESS = "get_customer_notifications_success";
export const GET_CUSTOMER_NOTIFICATIONS_FAILURE = "get_customer_notifications_failure";

export const REQUEST_REMOVE_CUSTOMER_NOTIFICATION =
  "request_remove_customer_notification";
export const REMOVE_CUSTOMER_NOTIFICATION_SUCCESS =
  "remove_customer_notification_success";
export const REMOVE_CUSTOMER_NOTIFICATION_FAILURE =
  "remove_customer_notification_failure";

// transactions (history)
export const REQUEST_GET_CUSTOMER_TRANSACTIONS = "request_get_customer_transactions";
export const GET_CUSTOMER_TRANSACTIONS_SUCCESS = "get_customer_transactions_success";
export const GET_CUSTOMER_TRANSACTIONS_FAILURE = "get_customer_transactions_failure";

// get customer profile
export const GET_CUSTOMER_PROFILE_REQUEST = "get_customer_profile_request";
export const GET_CUSTOMER_PROFILE_SUCCESS = "get_customer_profile_success";
export const GET_CUSTOMER_PROFILE_FAILURE = "get_customer_profile_failure";

// save customer profile
export const SAVE_CUSTOMER_PROFILE_REQUEST = "save_customer_profile_request";
export const SAVE_CUSTOMER_PROFILE_SUCCESS = "save_customer_profile_success";
export const SAVE_CUSTOMER_PROFILE_FAILURE = "save_customer_profile_failure";

// update profile form
export const FORM_CUSTOMER_PROFILE_UPDATE_VALUE = "form_customer_profile_update_vaue";
export const ACCOUNT_DESCRIPTION_UPDATE_VALUE = "account_description_update_vaue";

export const SAVE_ACCOUNT_DESCRIPTION_REQUEST = "save_account_description_request";
export const SAVE_ACCOUNT_DESCRIPTION_SUCCESS = "save_account_description_success";
export const SAVE_ACCOUNT_DESCRIPTION_FAILURE = "save_account_description_failure";

// get sso list
export const REQUEST_GET_SSO_LIST = "request_get_sso_list";
export const GET_SSO_LIST_SUCCESS = "get_sso_list_success";
export const GET_SSO_LIST_FAILURE = "get_sso_list_failure";

// quicken download
export const OPEN_QUICKEN_DOWNLOAD_MODAL = "open_quicken_download_modal";
export const CLOSE_QUICKEN_DOWNLOAD_MODAL = "close_quicken_download_modal";

export const SHOW_EXCEL_DOWNLOAD_MODAL = "show_excel_download_modal";

export const REQUEST_GET_QUICKEN_FILE = "request_account_quicken_file";
export const GET_QUICKEN_FILE_SUCCESS = "get_quicken_file_success";
export const GET_QUICKEN_FILE_FAILURE = "get_quicken_file_failure";

export const GET_SAVED_SEARCHES_REQUEST = "get_saved_searches_request";
export const GET_SAVED_SEARCHES_SUCCESS = "get_saved_searches_success";
export const GET_SAVED_SEARCHES_FAILURE = "get_saved_searches_failure";

export const SAVE_AND_PERFORM_SEARCH_REQUEST = "save_and_perform_search_request";
export const SAVE_AND_PERFORM_SEARCH_SUCCESS = "save_and_perform_search_success";
export const SAVE_AND_PERFORM_SEARCH_FAILURE = "save_and_perform_search_failure";

export const REMOVE_SAVED_SEARCH_SUCCESS = "remove_saved_search_success";
export const REMOVE_SAVED_SEARCH_REQUEST = "remove_saved_search_request";
export const REMOVE_SAVED_SEARCH_FAILURE = "remove_saved_search_failure";

export const PERFORM_SEARCH_REQUEST = "perform_search_request";
export const PERFORM_SEARCH_SUCCESS = "perform_search_success";
export const PERFORM_SEARCH_FAILURE = "perform_search_failure";
