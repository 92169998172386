import { REHYDRATE } from "redux-persist/lib/constants";
import * as types from "./types";
import { LOGIN_AS_MEMBER_SUCCESS } from "../Admin/constants";

const initialState = {
  error: null,
  username: null,
  name: null,
  userId: null,
  phrase: null,
  mfa: null,
  mfaId: null,
  authenticated: false,
  currentStep: "1",
  isFetching: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case types.RECOVERY_PASSWORD:
      return { ...state };
    case types.RECOVERY_PASSWORD_SUCCESS:
      return { ...state };
    case types.RECOVERY_PASSWORD_FAILURE:
      return { ...state };
    case types.RECOVERY_PASSWORD_CRASH:
      return { ...state };

    case types.SEND_RECOVERY_PASSWORD_EMAIL:
      return { ...state };
    case types.SEND_RECOVERY_PASSWORD_EMAIL_SUCCESS:
      return { ...state };
    case types.SEND_RECOVERY_PASSWORD_EMAIL_FAILURE:
      return { ...state };
    case types.SEND_RECOVERY_PASSWORD_EMAIL_CRASH:
      return { ...state };

    case types.SEND_CONFIRMATION_CODE:
      return { ...state };

    case types.SEND_CONFIRMATION_CODE_SUCCESS:
      return { ...state };

    case types.SEND_CONFIRMATION_CODE_FAILURE:
      // const { error } = payload;

      return { ...state };

    case REHYDRATE:
      const incomingState = payload && payload.auth;
      if (incomingState) {
        return {
          ...state,
          ...incomingState,
          currentStep: "1",
          error: null,
          isFetching: false,
          isSucessUserName: false,
          phrase: "",
        };
      }

      return state;

    case types.FIRST_STEP_AUTH_USER_REQUEST:
      return { ...state, isFetching: true };

    case types.FIRST_STEP_AUTH_USER_SUCCESS:
      return {
        ...state,
        phrase: payload.phrase,
        //currentStep: '2',
        isSucessUserName: true,
        isFetching: false,
        username: payload.username,
        userId: payload.id,
        name: payload.name,
        error: null,
      };

    case types.FIRST_STEP_AUTH_USER_FAILURE:
      return { ...state, isFetching: false, error: payload, isSucessUserName: false };

    case types.SECOND_STEP_AUTH_USER_REQUEST:
      return { ...state, isFetching: true };

    case types.SECOND_STEP_AUTH_USER_SUCCESS:
      return {
        ...state,
        phrase: null,
        currentStep: "3",
        isFetching: false,
        // mfa: payload.mfa,
        // mfaId: payload.mfaId,
        error: null,
      };
    case types.SECOND_STEP_AUTH_USER_FAILURE:
      return {
        ...state,
        //currentStep: '2',
        isFetching: false,
        error: payload,
      };

    case types.THIRD_STEP_AUTH_USER_REQUEST:
      return { ...state, isFetching: true };

    case types.THIRD_STEP_AUTH_USER_SUCCESS:
      return { ...state, currentStep: "1", isFetching: false, mfa: null, error: null };

    case types.THIRD_STEP_AUTH_USER_FAILURE:
      return {
        ...state,
        currentStep: "3",
        isFetching: false,
        error: payload,
      };

    case types.AUTH_USER:
      return { ...state, authenticated: true };

    case types.UNAUTH_USER:
      return {
        ...state,
        error: null,
        username: null,
        userId: null,
        phrase: null,
        mfa: null,
        authenticated: false,
        currentStep: "1",
        isFetching: false,
      };

    case types.AUTH_ERROR:
      return { ...state, error: payload };

    case types.SHOW_TROUBLESHOOTING_INFO:
      return { ...state, currentStep: payload, error: null, isFetching: false };

    case types.CHANGE_STEP_TO:
      return { ...state, currentStep: payload, error: null, isFetching: false };

    case types.FIRST_STEP_RECOVER_PASSWORD_REQUEST:
      return { ...state, isFetching: true };

    case types.FIRST_STEP_RECOVER_PASSWORD_SUCCESS:
      return { ...state, isFetching: false };

    case types.FIRST_STEP_RECOVER_PASSWORD_FAILURE:
      return { ...state, isFetching: false, error: payload };

    case types.SECOND_STEP_RECOVER_PASSWORD_REQUEST:
      return { ...state, isFetching: true, error: null };

    case types.SECOND_STEP_RECOVER_PASSWORD_SUCCESS:
      return { ...state, isFetching: false };

    case types.SECOND_STEP_RECOVER_PASSWORD_FAILURE:
      return { ...state, isFetching: false, error: payload };

    case types.THIRD_STEP_RECOVER_PASSWORD_REQUEST:
      return { ...state, isFetching: true, error: null };

    case types.THIRD_STEP_RECOVER_PASSWORD_SUCCESS:
      return { ...state, isFetching: false, mfa: payload.mfa, mfaId: payload.mfaId };

    case types.THIRD_STEP_RECOVER_PASSWORD_FAILURE:
      return { ...state, isFetching: false, error: payload };

    case types.REQUEST_SMS_CODE:
      return { ...state, isFetching: true };

    case types.REQUEST_SMS_CODE_SUCCESS:
      return { ...state, isFetching: false };

    case types.REQUEST_SMS_CODE_FAILURE:
      return { ...state, isFetching: false, error: payload };

    case types.VALIDATE_SMS_CODE_REQUEST:
      return { ...state, isFetching: true };

    case types.VALIDATE_SMS_CODE_SUCCESS:
      return { ...state, isFetching: false };

    case types.VALIDATE_SMS_CODE_FAILURE:
      return { ...state, isFetching: false, error: payload };

    case LOGIN_AS_MEMBER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userId: payload.userId,
        username: payload.username,
      };

    default:
      return state;
  }
}
