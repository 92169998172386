import React, { Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { A4Sidenav, Menu } from "../../commons/GridNav";
import ItemCollapsed from "./ItemCollapsed";
import ItemExpanded from "./ItemExpanded";
import RenderArrows from "./RenderArrows";

import { getMenuLinks } from "../../../utils/sideNav";

const renderCollapsed = (on, onOpen, location, menu, configSideNav) => {
  const fiSideNavLogoCollapsed =
    configSideNav && configSideNav.fiIconLogoTop && configSideNav.fiIconLogoTop;
  const fiIconLogoBottom =
    configSideNav && configSideNav.fiIconLogoBottom && configSideNav.fiIconLogoBottom;

  return (
    <Menu
      id="collapsableNav"
      on={on}
      role="menu"
      aria-label="collapsed navigation"
      aria-expanded={on}
    >
      <RenderArrows
        collapsed
        on={on}
        onOpen={onOpen}
        fiSideNavLogoCollapsed={fiSideNavLogoCollapsed}
      />
      {menu &&
        _.map(menu, ({ displayName, bottom, ...props }, index) => (
          <Fragment key={`collapsed-${displayName}-${index}`}>
            {!bottom && (
              <ItemCollapsed displayName={displayName} location={location} {...props} />
            )}
          </Fragment>
        ))}
      <div style={{ height: "100%" }} />
      <div style={{ width: "100%" }}>
        {menu &&
          _.map(menu, ({ displayName, bottom, ...props }, index) => (
            <Fragment key={`collapsed-${displayName}-${index}`}>
              {bottom && (
                <ItemCollapsed displayName={displayName} location={location} {...props} />
              )}
            </Fragment>
          ))}
        <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
          <img style={{ width: "20px" }} src={fiIconLogoBottom} />
        </div>
      </div>
    </Menu>
  );
};

const renderExpanded = (on, onOpen, location, menu, configSideNav) => {
  const fiSideNavLogoExtended =
    configSideNav && configSideNav.fiLogoTop && configSideNav.fiLogoTop;
  const fiLogoBottom =
    configSideNav && configSideNav.fiLogoBottom && configSideNav.fiLogoBottom;

  return (
    <Menu
      id="collapsableNav"
      on={on}
      role="menu"
      aria-label="expanded navigation"
      aria-expanded={on}
    >
      <RenderArrows
        on={on}
        onOpen={onOpen}
        fiSideNavLogoExtended={fiSideNavLogoExtended}
      />
      <>
        {menu &&
          _.map(menu, ({ bottom, displayName, ...props }, index) => (
            <Fragment key={`expanded-${displayName}`}>
              {!bottom && (
                <ItemExpanded displayName={displayName} location={location} {...props} />
              )}
            </Fragment>
          ))}
        <div style={{ height: "100%" }} />
        <div style={{ width: "100%" }}>
          {menu &&
            _.map(menu, ({ bottom, displayName, ...props }, index) => (
              <Fragment key={`expanded-${displayName}`}>
                {bottom && (
                  <ItemExpanded
                    displayName={displayName}
                    location={location}
                    {...props}
                  />
                )}
              </Fragment>
            ))}
          <div style={{ display: "flex", padding: "1rem", paddingLeft: "1.5rem" }}>
            <img style={{ width: "100px" }} src={fiLogoBottom} />
          </div>
        </div>
      </>
    </Menu>
  );
};

const SideNavDesktop = ({ onOpen, isSidenavOpen, location, config, isAdmin }) => {
  const menu = config
    ? getMenuLinks(isAdmin ? config.adminMenuConfig : config.menuConfig)
    : [];

  return (
    <A4Sidenav
      aria-expanded={isSidenavOpen}
      aria-controls="collapsableNav"
      role="navigation"
    >
      {isSidenavOpen
        ? renderExpanded(isSidenavOpen, onOpen, location, menu, config && config.sideNav)
        : renderCollapsed(
            isSidenavOpen,
            onOpen,
            location,
            menu,
            config && config.sideNav,
          )}
    </A4Sidenav>
  );
};

SideNavDesktop.propTypes = {
  onOpen: PropTypes.func,
  isSidenavOpen: PropTypes.bool,
  location: PropTypes.object,
  config: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default SideNavDesktop;
