import _ from "lodash";

export const getSubPath = (currentLocation, subMenu) => {
  const locationBasePath = getLocation(currentLocation);
  if (subMenu !== undefined) {
    let selectSubMenu = subMenu.filter(item => item.path === currentLocation);
    let selectSubMenuSplit = subMenu.filter(
      item => getLocation(item.path) === locationBasePath,
    );

    if (selectSubMenu.length > 0) {
      return selectSubMenu[0].path;
    }
    if (selectSubMenuSplit.length > 0) {
      return selectSubMenuSplit[0].path;
    }
  }

  return null;
};

export const getLocation = pathname => {
  let pathLocation;
  pathname.split("/")[2] === "panel"
    ? (pathLocation = pathname.split("/")[3])
    : (pathLocation = pathname.split("/")[2]);
  return pathLocation;
};

export const setActiveItemExpandedMenu = (currentLocation, iconPath, subMenu) => {
  const activeStyleColor = {
    borderLeftColor: "#4CD893",
    borderLeftWidth: "3px",
    borderLeftStyle: "solid",
    color: "#141F2D",
    backgroundColor: "rgba(76,216,147,0.1)",
  };
  const locationBasePath = getLocation(currentLocation);

  if (subMenu === undefined) {
    const iconBasePath = getLocation(iconPath);
    if (currentLocation === iconPath) {
      return activeStyleColor;
    } else if (locationBasePath === iconBasePath) {
      return activeStyleColor;
    }
  } else {
    if (subMenu.filter(item => item.path === currentLocation).length > 0) {
      return activeStyleColor;
    }

    if (subMenu.filter(item => getLocation(item.path) === locationBasePath).length > 0) {
      return activeStyleColor;
    }
  }

  return {};
};

export const setActiveIconColor = (
  currentLocation,
  iconPath,
  subMenu,
  mobile = false,
) => {
  const activeStyleColor = mobile ? "#4CD893" : "#05CE86";
  const locationBasePath = getLocation(currentLocation);
  if (subMenu === undefined) {
    const iconBasePath = getLocation(iconPath);
    if (currentLocation === iconPath) {
      return activeStyleColor;
    } else if (locationBasePath === iconBasePath) {
      return activeStyleColor;
    }
  } else {
    if (subMenu.filter(item => item.path === currentLocation).length > 0) {
      return activeStyleColor;
    }

    if (subMenu.filter(item => getLocation(item.path) == locationBasePath).length > 0) {
      return activeStyleColor;
    }
  }

  return mobile ? "#000" : "#FFFFFF";
};

export const getMenuLinks = menuConfig => {
  const menu = menuConfig
    ? _.map(
        menuConfig.filter(item => item.showMenu !== false),
        ({
          displayName,
          path,
          iconName,
          subMenu,
          bottom,
          iconRightName,
          externalLink,
        }) => {
          if (subMenu !== undefined) {
            path = subMenu[0].path;
          }

          return {
            path,
            subMenu,
            bottom,
            iconSrc: iconName,
            displayName,
            iconRightName,
            externalLink,
          };
        },
      )
    : undefined;

  return menu;
};
