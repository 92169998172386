import React from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import {
  CheckRoundGreen,
  UserBlack,
  Edit,
  BlackTrash,
} from "../../../components/commons/icons";

const ContainerCard = styled.div`
  display: flex;
  height: 65px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #ebeff3;
  padding: 0px 5px;
  cursor: pointer;

  span {
    color: #000000;
    font-family: "Kumbh Sans";
    font-size: 15px;
    display: block;
    width: 100%;
  }

  .actions {
    display: none;
  }

  &:hover {
    border-radius: 12px;
    background-color: rgba(218, 218, 218, 0.1);

    .actions {
      display: flex;
      svg {
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }

  &.selected {
    background-color: rgba(63, 199, 142, 0.1);
    border-bottom: none;
    border-radius: 12px;
    padding: 0px 10px;
  }

  .containerContact {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Contact = ({ name, selected }) => {
  return (
    <ContainerCard className={`${selected ? "selected" : ""}`}>
      <UserBlack
        stylesInput={{
          height: "20px",
          width: "20px",
          paddingRight: "10px",
        }}
      />
      <span>{name}</span>

      {selected ? (
        <CheckRoundGreen
          stylesInput={{
            width: "20px",
            height: "20px",
            opacity: 1,
          }}
        />
      ) : (
        <div className="actions">
          <FeatherIcon icon="trash" size="20" stroke="#000" />
          <FeatherIcon icon="edit" size="20" stroke="#000" />
        </div>
      )}
    </ContainerCard>
  );
};

export default Contact;
