import React from "react";

import _ from "lodash";
import { push } from "react-router-redux";
import FeatherIcon from "feather-icons-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MenuItem } from "../../commons/GridNav";
import { NavLinkWithHover } from "../../commons";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";

import {
  setActiveItemExpandedMenu,
  setActiveIconColor,
  getSubPath,
} from "../../../utils/sideNav";

const MenuItemCustom = styled(MenuItem)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const ItemCollapsed = ({
  displayName,
  location,
  path,
  subMenu,
  iconSrc,
  externalLink,
  push,
}) => {
  const styleItemActive = setActiveItemExpandedMenu(location.pathname, path, subMenu);
  const colorActiveIcon = setActiveIconColor(location.pathname, path, subMenu);
  const subPath = getSubPath(location.pathname, subMenu);

  return (
    <MenuItemCustom
      aria-label={`menu item ${displayName}`}
      role="menuitem"
      style={{ ...styleItemActive }}
      onClick={e => {
        if (externalLink) {
          window.open(path);
        } else {
          push(subPath ? subPath : path);
        }
      }}
    >
      <NavLinkWithHover
        aria-label={`link ${displayName}`}
        role="link"
        style={{ display: "flex" }}
        activeStyle={{ color: "#05CE86" }}
        to={subPath ? subPath : externalLink ? "#" : path}
      >
        {iconSrc === "roles" ? (
          <ReactSVG
            src={`../../../../icons/roles.svg`}
            evalScripts="always"
            beforeInjection={svg => {
              svg.setAttribute("style", `stroke:${colorActiveIcon};`);
            }}
          />
        ) : (
          <FeatherIcon icon={iconSrc} style={{ stroke: colorActiveIcon }} />
        )}
      </NavLinkWithHover>
    </MenuItemCustom>
  );
};

ItemCollapsed.prototype = {
  displayName: PropTypes.string,
  location: PropTypes.object,
  path: PropTypes.string,
  subMenu: PropTypes.object,
  iconSrc: PropTypes.string,
  externalLink: PropTypes.bool,
  push: PropTypes.func,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { push })(ItemCollapsed);
