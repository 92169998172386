import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import _ from "lodash";

import Checkbox from "../../../../components/commons/Checkbox";

import { sortFormOptions } from "../../../../utils/options";
import usePopover from "../../../../hooks/usePopover";

const HeaderListFormContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 5px;

  .header-list-form-left {
    flex: 1;
    .results,
    .unselect-all {
      font-size: 14px;
    }

    .results {
      color: #1f2427;
    }

    .unselect-all {
      color: #a5a7a9;
    }
  }

  .header-list-form-right {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      cursor: pointer;
      font-size: 14px;
      color: #a5a7a9;
    }
  }
`;

const HeaderListForm = ({
  numberForms,
  resultDescription,
  sort,
  changeSort,
  checkedForms,
  removeAllCheckedForms,
}) => {
  const [anchorEl, setAnchorEl, open, id] = usePopover("simple-popover");
  const checkeds = checkedForms.length > 0;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckbox = e => {
    if (!e.target.checked) {
      removeAllCheckedForms();
    }
  };

  return (
    <HeaderListFormContainer>
      <div className="header-list-form-left">
        {checkeds ? (
          <>
            <Checkbox
              label={""}
              checked={checkeds}
              onChange={handleCheckbox}
              formControlLabelStyle={{ margin: 0 }}
              checkedIcon={
                <FeatherIcon
                  icon="minus-square"
                  style={{ fill: "#3fc78e", stroke: "#fff" }}
                />
              }
            />
            <span className="unselect-all">Unselect All</span>{" "}
          </>
        ) : (
          <span className="results">
            {numberForms} {resultDescription}
          </span>
        )}
      </div>
      <div aria-describedby={id} className="header-list-form-right" onClick={handleClick}>
        <span>Sort: {sort.label}</span>
        <FeatherIcon icon="chevron-down" size="13" />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List component="nav" aria-label="secondary mailbox folders">
          {sortFormOptions &&
            _.map(sortFormOptions, ({ label, value }, index) => {
              return (
                <ListItem
                  key={`header-list-form-${value}-${index}`}
                  onClick={() => {
                    changeSort({ label, value });
                    handleClose();
                  }}
                  button
                >
                  <ListItemText primary={label} />
                </ListItem>
              );
            })}
        </List>
      </Popover>
    </HeaderListFormContainer>
  );
};

HeaderListForm.propTypes = {
  numberForms: PropTypes.string,
  sort: PropTypes.object,
  changeSort: PropTypes.func,
  checkedForms: PropTypes.array,
  removeAllCheckedForms: PropTypes.func,
};

export default HeaderListForm;
