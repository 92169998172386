import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button } from "../../../commons";
import AdminCheckboxContainer from "./AdminCheckboxContainer";
import CustomModal from "../../../commons/CustomModal";

const Title = styled.span`
  font-family: "Kumbh Sans", sans-serif;
  font-size: 36px;
  text-align: left;
  color: #152134;
  width: 100%;
  font-weight: bold;
  display: inline-block;
  line-height: 1;
`;

const UsernameText = styled.span`
  font-family: "Kumbh Sans", sans-serif;
  font-size: 22px;
  color: #152134;
  margin-bottom: 49px;
  width: 100%;
  display: inline-block;
`;

const AssignText = styled.span`
  font-family: "Kumbh Sans", sans-serif;
  font-size: 14px;
  color: #a5a7a9;
  margin-bottom: 12.5px;
  display: inline-block;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  & > button:focus {
    outline: #ffffff;
  }
`;

const ContainerPhoto = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14.6px;
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 46px;
    width: 46px;
  }
`;

const ModalContainer = styled.div`
  width: 100%;
`;

const fakedata = [
  {
    id: 1,
    title: "Full Access",
    description: "Access to all features in the system.",
  },
  {
    id: 2,
    title: "Banking Access",
    description:
      "Can access Alerts, Messages, Member eView, MFA Reset, Analytics, CMS Link, Document Storage.",
  },
  {
    id: 3,
    title: "Content Management",
    description: "Can access analytics, CMS Link.",
  },
];

function EditAdminModal({ name, username, open, handleClose }) {
  return (
    <CustomModal open={open} handleClose={handleClose}>
      <ModalContainer>
        <ContainerPhoto>
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXUViUQMThPkBwVp05lO7luNnq9FaNQB3G-Q&usqp=CAU"
            alt="ImageProfile"
          />
        </ContainerPhoto>
        <Title>{name}</Title>
        <UsernameText>@{username}</UsernameText>
        <AssignText>Assign New Role</AssignText>
        <AdminCheckboxContainer checkboxs={fakedata} />
      </ModalContainer>
      <Footer>
        <Button
          modalButton
          style={{ width: "100%" }}
          primary
          onClick={e => {
            handleClose(e);
          }}
        >
          Save
        </Button>
        <Button modalButton style={{ width: "100%" }} onClick={handleClose}>
          Cancel
        </Button>
      </Footer>
    </CustomModal>
  );
}

EditAdminModal.prototype = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUnlok: PropTypes.func,
  name: PropTypes.string,
  username: PropTypes.string,
};

export default EditAdminModal;
