import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import styled from "styled-components";
import CurrencyFormat from "react-currency-format";
import {
  Button,
  Space,
  HeadingModal,
  P,
  TextError,
  Loader,
  PageTitle,
} from "../../../components/commons";
import TextField from "../../../components/commons/TextField";
import { InputAdornment, MenuItem } from "@material-ui/core";
import {
  CustomModal,
  SuccessModal,
  ConfirmationModal,
} from "../../../components/commons/Modals";
import {
  Cancel,
  Check,
  Plus,
  MoreVertical,
  Edit,
  RedTrash,
} from "../../../components/commons/icons";
import Select from "../../../components/commons/SelectInput";
import { getCurrentTime } from "../../../utils/helper";
import enums from "../../../utils/enums";
import {
  GoalBox,
  GoalsContainer,
  GoalControls,
  GoalProgress,
  Goal,
  GoalTitle,
  AddButton,
} from "./GoalsInstance";
import * as actions from "./actions";
import { getAccountSummary } from "../Accounts/actions";
import { selectGoals } from "./selectors";
import { selectAccounts } from "../Accounts/selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";
import DatePickerCustom from "../../../components/commons/DatePickerCustom";
import Modal from "react-modal";

const ModalContent = styled.form`

&>div:last-child{
	width: 89%;
	@media(max-width: 420px) {
	max-width: 50%;
	}
}

@media(min-width: 421px and max-width: 1024px) {
 & > div {
	 width: 100%;
	 margin: 16px 0;
 }

 @media(max-width: 420px) {
	text-align: center;
	& > div {
		text-align: left;
		width: auto;
		margin: 16px 0;
	}

`;
const ModalControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #f4f6f8;
`;

const TextInfo = styled.p`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0px;
  color: #00acee;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "100%",
    maxWidth: 400,
    margin: "auto",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: "10px",
  },
  overlay: {
    zIndex: 30,
    backgroundColor: "rgba(230, 230, 230, 0.8)",
  },
};

class GoalsPage extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    isSuccessModalOpen: PropTypes.bool,
    isCreateGoalModalOpen: PropTypes.bool,
    isUpdateGoalModalOpen: PropTypes.bool,
    error: PropTypes.string,
    successMessage: PropTypes.string,
    goals: PropTypes.array,
    getCustomerGoals: PropTypes.func,
    removeCustomerGoals: PropTypes.func,
    createCustomerGoal: PropTypes.func,
    updateCustomerGoal: PropTypes.func,
    getAccountSummary: PropTypes.func,
    updateGoalValue: PropTypes.func,
    initGoalValues: PropTypes.func,
    showSuccessModal: PropTypes.func,
    showCreateGoalModal: PropTypes.func,
    showUpdateGoalModal: PropTypes.func,
    accounts: PropTypes.object,
    dispatchValidateAmount: PropTypes.func,
    showErrorAmountModal: PropTypes.func,
  };
  state = {
    goalAccounts: [],
    goalName: "",
    goalDescription: "",
    goalAmount: 0,
    goalDeadline: new Date(),
    goalType: "",
    selectedGoalAccount: "",
    editGoal: null,
    createGoalError: "",
    fromScratch: false,
    infoChangeAccount: "",
  };
  async componentDidMount() {
    try {
      if (this.props.accounts) {
        const { shares, loans } = this.props.accounts;
        if (_.isEmpty(shares) || _.isEmpty(loans)) {
          Promise.all([this.props.getAccountSummary(), this.props.getCustomerGoals()]);
        } else {
          await this.props.getCustomerGoals();
        }
      }
    } catch (ex) {
      // .. do some redirect
      throw ex;
    }
  }
  resetState = () => {
    this.setState({
      goalAccounts: [],
      goalName: "",
      goalDescription: "",
      goalAmount: 0,
      goalDeadline: new Date(),
      goalType: "",
      createGoalError: "",
      isConfirmationModalOpen: false,
      fromScratch: false,
    });
  };

  handleCreateCustomerGoal = () => {
    if (this.state.goalAmount === "" || this.state.goalAmount <= 0) {
      this.props.dispatchValidateAmount("Write an amount different of 0.");
      this.props.showErrorAmountModal(true);
      return;
    }

    this.setState({ createGoalError: null });
    const {
      selectedGoalAccount,
      goalType,
      goalName,
      goalDescription,
      goalAmount,
      goalDeadline,
      fromScratch,
    } = this.state;
    if (
      selectedGoalAccount !== "" &&
      goalType !== "" &&
      goalName !== "" &&
      goalDescription !== "" &&
      goalAmount !== "" &&
      goalDeadline !== ""
    ) {
      const goal = {
        accountUniqueId: selectedGoalAccount,
        goalType,
        name: goalName,
        description: goalDescription,
        amount: goalAmount,
        deadline: moment(goalDeadline).format("MM/DD/YYYY"),
        fromScratch,
      };
      this.props.createCustomerGoal(goal);
    } else {
      this.setState({ createGoalError: "Please complete all the fields" });
    }
  };
  handleUpdateGoal = async ({
    id,
    name,
    description,
    amount,
    deadline,
    accountUniqueId,
    goalType,
  }) => {
    if (amount === "" || amount <= 0) {
      this.props.dispatchValidateAmount("Write an amount different of 0.");
      this.props.showErrorAmountModal(true);
      return;
    }
    if (
      goalType !== "" &&
      name !== "" &&
      description !== "" &&
      deadline &&
      amount !== "" &&
      accountUniqueId !== ""
    ) {
      await this.props.updateCustomerGoal(id, {
        name,
        description,
        deadline: moment(deadline).format("MM/DD/YYYY"),
        amount,
        accountUniqueId,
        goalType,
      });
      this.setState({ infoChangeAccount: null, updateGoalError: null });
    } else {
      this.setState({ updateGoalError: "Please complete all the fields" });
    }

    await this.props.getCustomerGoals();
  };
  handleRemoveCustomerGoal = goalId => {
    this.setState({ isConfirmationModalOpen: true, selectedGoalAccount: goalId });
  };
  filterAccountsByType = async accountType => {
    const { shares, loans } = this.props.accounts;
    if (accountType === "SAVING") {
      const savingsUniqueIds =
        shares &&
        _.map(shares, share => {
          const maskedId =
            share &&
            share.uniqueId &&
            (share.uniqueId.substr(share.uniqueId.length - 4) || "");
          return {
            maskedId: share.defaultDescription,
            accountUniqueId: share.uniqueId,
          };
        });
      await this.setState({ goalAccounts: savingsUniqueIds });
    }
    if (accountType === "LOAN") {
      const loansUniqueIds =
        loans &&
        _.map(loans, loan => {
          const maskedId =
            loan &&
            loan.uniqueId &&
            (loan.uniqueId.substr(loan.uniqueId.length - 4) || "");
          return {
            maskedId: loan.defaultDescription,
            accountUniqueId: loan.uniqueId,
          };
        });
      await this.setState({ goalAccounts: loansUniqueIds });
    }
  };
  handleOpenUpdateGoalModal = goal => {
    goal.deadline = goal.deadline.substring(0, 19);
    this.setState({ editGoal: goal });
    this.filterAccountsByType(Object.keys(enums.GoalTypes)[goal.goalType]);
    this.props.showUpdateGoalModal(true);
  };
  handleOpenCreateModal = show => {
    this.resetState();
    this.props.showCreateGoalModal(show);
  };

  handleCreateChangeGoalType = async e => {
    await this.setState({ goalType: e.target.value });
    await this.filterAccountsByType(Object.keys(enums.GoalTypes)[e.target.value]);
  };

  handleUpdateChangeGoalType = async e => {
    await this.setState({
      editGoal: {
        ...this.state.editGoal,
        goalType: e.target.value,
        accountUniqueId: null,
      },
    });
    await this.filterAccountsByType(Object.keys(enums.GoalTypes)[e.target.value]);
  };
  handleChangeFromScratch = e => this.setState({ fromScratch: e.target.checked });
  handleChangeGoal = e => this.setState({ [e.target.name]: e.target.value });
  handleChangeUpdateGoalValue = (name, value) => {
    this.setState({
      editGoal: {
        ...this.state.editGoal,
        [name]: value,
      },
    });
  };

  handleChangeSelectedAccount = e =>
    this.setState({ selectedGoalAccount: e.target.value });
  renderSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.isSuccessModalOpen}
        onClose={() => this.props.showSuccessModal(false)}
        headingText={this.props.successMessage || "Successfully"}
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderErrorModal() {
    return (
      <Modal
        isOpen={this.props.isErrorAmountModalOpen}
        style={customStyles}
        aria-hidden={this.props.isErrorAmountModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      >
        <div className="CustomModalHeader">
          <span style={{ flex: 2 }}>
            <HeadingModal id="heading">Goal Error</HeadingModal>
          </span>
          <span>
            <Cancel onClick={() => this.props.showErrorAmountModal(false)} />
          </span>
        </div>
        <div id="full_description" className="successModalBody">
          <p>{this.props.error}</p>
        </div>
        <div className="modalFooter">
          <Button primary onClick={() => this.props.showErrorAmountModal(false)}>
            Close
          </Button>
        </div>
      </Modal>
    );
  }
  renderRemoveGoalConfirmationModal() {
    return (
      <ConfirmationModal
        isOpen={this.state.isConfirmationModalOpen}
        onClose={() => this.setState({ isConfirmationModalOpen: false })}
        onSuccess={() => {
          this.props.removeCustomerGoals(this.state.selectedGoalAccount);
          this.setState({ isConfirmationModalOpen: false, selectedGoalAccount: null });
        }}
        headingText="Delete Goal"
        confirmationText={<P>Are you sure you want to delete this Goal?</P>}
        cancelButtonText="Close"
        successButtonText={"Confirm"}
        aria-hidden={this.state.isConfirmationModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderUpdateGoalModal() {
    const goal = this.state.editGoal;

    return (
      <CustomModal isOpen={this.props.isUpdateGoalModalOpen}>
        <div className="CustomModalHeader">
          <HeadingModal>Edit Goal</HeadingModal>
          <Cancel onClick={() => this.props.showUpdateGoalModal(false)} />
        </div>
        {!_.isEmpty(goal) ? (
          <ModalContent>
            <CurrencyFormat
              label={"My Goal"}
              value={goal.amount}
              name="amount"
              id="goalAmount"
              customInput={TextField}
              thousandSeparator={true}
              onValueChange={values => {
                const { floatValue } = values;
                if (floatValue) {
                  this.handleChangeUpdateGoalValue("amount", floatValue);
                } else {
                  this.handleChangeUpdateGoalValue("amount", 0);
                }
              }}
              bigField
              startAdornment={
                <InputAdornment position="start">
                  <span className="bigAdornment">$</span>
                </InputAdornment>
              }
            />

            <TextField
              id="goalName"
              name="name"
              label="Goal Name"
              type="text"
              value={goal.name}
              onChange={e =>
                this.handleChangeUpdateGoalValue(e.target.name, e.target.value)
              }
            />
            <DatePickerCustom
              label="Goal Deadline"
              name="deadline"
              disablePast
              value={goal.deadline}
              onChange={e =>
                this.handleChangeUpdateGoalValue(e.target.name, e.target.value)
              }
            />
            <Select
              id="goalType"
              name="goalType"
              aria-label="Goal Type"
              label={"Goal Type"}
              onChange={this.handleUpdateChangeGoalType}
              value={goal.goalType}
            >
              {enums.GoalTypes &&
                _.map(
                  enums.GoalTypes,
                  (val, i) =>
                    val !== enums.GoalTypes.UNDEFINED && (
                      <MenuItem key={i} value={val}>
                        {i}
                      </MenuItem>
                    ),
                )}
            </Select>
            <Select
              id="goalAccount"
              name="accountUniqueId"
              aria-label="Goal Account"
              label={"Account"}
              onChange={e => {
                if (goal) {
                  const goalSelected = this.props.goals.find(({ id }) => goal.id === id);
                  if (goalSelected) {
                    if (e.target.value !== goalSelected.accountUniqueId) {
                      this.setState({
                        infoChangeAccount:
                          "If you change the account, the goal progress is reset",
                      });
                    } else {
                      this.setState({
                        infoChangeAccount: null,
                      });
                    }
                  }
                }

                this.handleChangeUpdateGoalValue("accountUniqueId", e.target.value);
              }}
              value={goal.accountUniqueId}
              style={{ maxWidth: 200 }}
            >
              {!this.state.goalAccounts || this.state.goalAccounts.length === 0 ? (
                <MenuItem value={"select one"}>Select Goal Type</MenuItem>
              ) : (
                _.map(this.state.goalAccounts, (account, i) => (
                  <MenuItem key={i} value={account.accountUniqueId}>
                    {account.maskedId}
                  </MenuItem>
                ))
              )}
            </Select>
            <TextField
              id="goalDescription"
              name="description"
              label="Goal Description"
              type="text"
              value={goal.description}
              onChange={e =>
                this.handleChangeUpdateGoalValue(e.target.name, e.target.value)
              }
            />
          </ModalContent>
        ) : null}
        {this.state.updateGoalError && (
          <TextError>{this.state.updateGoalError}</TextError>
        )}
        {this.props.error && <TextError>{this.props.error}</TextError>}
        {this.state.infoChangeAccount && (
          <TextInfo>{this.state.infoChangeAccount}</TextInfo>
        )}
        <ModalControls>
          <Button onClick={() => this.props.showUpdateGoalModal(false)}>Cancel</Button>
          <Space />
          <Button primary onClick={() => this.handleUpdateGoal(goal)}>
            Save Changes
          </Button>
        </ModalControls>
      </CustomModal>
    );
  }
  renderCreateGoalModal() {
    return (
      <CustomModal isOpen={this.props.isCreateGoalModalOpen}>
        <div className="CustomModalHeader">
          <HeadingModal>Add Goal</HeadingModal>
          <Cancel onClick={() => this.handleOpenCreateModal(false)} />
        </div>
        <ModalContent>
          <CurrencyFormat
            label={"My Goal"}
            value={this.state.goalAmount}
            name="goalAmount"
            id="goalAmount"
            customInput={TextField}
            thousandSeparator={true}
            onValueChange={values => {
              const { floatValue } = values;
              if (floatValue) {
                this.handleChangeGoal({
                  target: {
                    name: "goalAmount",
                    value: floatValue,
                  },
                });
              } else {
                this.handleChangeGoal({
                  target: {
                    name: "goalAmount",
                    value: 0,
                  },
                });
              }
            }}
            bigField
            startAdornment={
              <InputAdornment position="start">
                <span className="bigAdornment">$</span>
              </InputAdornment>
            }
          />
          <TextField
            id="goalName"
            name="goalName"
            label="Name"
            type="text"
            value={this.state.goalName}
            onChange={this.handleChangeGoal}
          />
          <DatePickerCustom
            label="Deadline"
            disablePast
            name="goalDeadline"
            value={this.state.goalDeadline}
            onChange={e => this.handleChangeGoal(e)}
          />
          <Select
            id="goalType"
            name="goalType"
            aria-label="Goal Type"
            label={"Goal Type"}
            onChange={this.handleCreateChangeGoalType}
            value={this.state.goalType}
          >
            {enums.GoalTypes &&
              _.map(
                enums.GoalTypes,
                (val, i) =>
                  i !== "UNDEFINED" && (
                    <MenuItem key={i} value={val}>
                      {i}
                    </MenuItem>
                  ),
              )}
          </Select>
          <Select
            id="goalAccount"
            name="goalAccount"
            aria-label="Goal Account"
            label={"Account"}
            onChange={this.handleChangeSelectedAccount}
            value={this.state.selectedGoalAccount}
            style={{ maxWidth: 200 }}
          >
            {!this.state.goalAccounts || this.state.goalAccounts.length === 0 ? (
              <MenuItem value={"select one"}>Select Goal Type</MenuItem>
            ) : (
              _.map(this.state.goalAccounts, (account, i) => (
                <MenuItem key={i} value={account.accountUniqueId}>
                  {account.maskedId}
                </MenuItem>
              ))
            )}
          </Select>
          <TextField
            id="goalDescription"
            name="goalDescription"
            label="Description"
            type="text"
            value={this.state.goalDescription}
            onChange={this.handleChangeGoal}
          />
        </ModalContent>
        {this.state.createGoalError && (
          <TextError>{this.state.createGoalError}</TextError>
        )}
        {this.props.error && <TextError>{this.props.error}</TextError>}
        <ModalControls>
          <Button onClick={() => this.handleOpenCreateModal(false)}>Cancel</Button>
          <Space />
          <Button primary onClick={this.handleCreateCustomerGoal}>
            Create Goal
          </Button>
        </ModalControls>
      </CustomModal>
    );
  }

  financial = num => {
    if (Number(num) === num && num % 1 !== 0) {
      return Number(num).toFixed(2);
    } else {
      return num;
    }
  };

  renderGoalTitleDescription = (goalType, goalUniqueId) => {
    const accountType = Object.keys(enums.GoalTypes)[goalType];
    if (accountType === "SAVING") {
      const share =
        this.props.accounts.shares &&
        _.find(this.props.accounts.shares, s => s.uniqueId === goalUniqueId);
      const maskedId =
        share &&
        share.uniqueId &&
        (share.uniqueId.substr(share.uniqueId.length - 4) || "");
      return (
        share &&
        share.description &&
        maskedId && <span>{`${share.description} S-${share.id} ****${maskedId}`}</span>
      );
    }
    if (accountType === "LOAN") {
      const loan =
        this.props.accounts.loans &&
        _.find(this.props.accounts.loans, l => l.uniqueId === goalUniqueId);
      const maskedId =
        loan && loan.uniqueId && (loan.uniqueId.substr(loan.uniqueId.length - 4) || "");
      return (
        loan &&
        loan.description &&
        maskedId && <span>{`${loan.description} L-${loan.id} ****${maskedId}`}</span>
      );
    }
    return null;
  };
  render() {
    return (
      <DashboardScreens>
        <div>
          <Loader isLoading={this.props.isFetching} />
          {this.renderRemoveGoalConfirmationModal()}
          {this.renderCreateGoalModal()}
          {this.renderUpdateGoalModal()}
          {this.renderSuccessModal()}
          {this.renderErrorModal()}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageTitle style={{ width: "50%" }}>Goals</PageTitle>
            <AddButton primary onClick={() => this.handleOpenCreateModal(true)}>
              <Plus />
            </AddButton>
          </div>
          <GoalsContainer>
            {this.props.goals.length !== 0 ? (
              _.map(this.props.goals, (goal, index) => (
                <GoalBox key={goal.accountUniqueId + index}>
                  <div className="flex">
                    <GoalTitle>{goal.name}</GoalTitle>
                    <p className="textT">
                      {`${Object.keys(enums.GoalTypes)[goal.goalType]}`}
                    </p>
                    <p className="description">{goal.description}</p>
                  </div>
                  <div className="flex bigFlex">
                    <p>{"Current"}:</p>
                    <Goal>
                      <CurrencyFormat
                        value={this.financial(goal.amount - goal.amountLeft)}
                        displayType={"text"}
                        thousandSeparator
                        prefix={"$"}
                      />
                    </Goal>
                    <p className="mediumSize description">
                      Of{" "}
                      <CurrencyFormat
                        value={this.financial(goal.amount)}
                        displayType={"text"}
                        thousandSeparator
                        prefix={"$"}
                      />
                    </p>
                    <GoalProgress percentage={goal.percentageCompleted}>
                      <span
                        className="contentProgress"
                        style={{ width: `${goal.percentageCompleted}%` }}
                      ></span>
                    </GoalProgress>
                    <p className="mobile">
                      Account:{" "}
                      {this.renderGoalTitleDescription(
                        goal.goalType,
                        goal.accountUniqueId,
                      )}
                    </p>
                  </div>
                  <div className="flex smallFlex">
                    <p className="desktop font-s">
                      Account:{" "}
                      {this.renderGoalTitleDescription(
                        goal.goalType,
                        goal.accountUniqueId,
                      )}
                    </p>
                    <p className="font-s">{`Deadline: ${moment(goal.deadline).format(
                      "MM/DD/YYYY",
                    )}`}</p>
                    <div className="dropdown">
                      <a href="#any" onClick={e => e.preventDefault()}>
                        <MoreVertical />
                      </a>
                      <div className="dropdown-content">
                        <a
                          href="#any"
                          onClick={e => {
                            e.preventDefault();
                            this.handleOpenUpdateGoalModal(goal);
                          }}
                        >
                          {" "}
                          <Edit /> Edit Goal
                        </a>
                        <a
                          href="#any"
                          onClick={e => {
                            e.preventDefault();
                            this.handleRemoveCustomerGoal(goal.id);
                          }}
                        >
                          {" "}
                          <RedTrash /> Delete Goal
                        </a>
                      </div>
                    </div>
                  </div>
                  <GoalControls>
                    <Button
                      danger
                      style={{ maxWidth: 120 }}
                      onClick={e => {
                        e.preventDefault();
                        this.handleRemoveCustomerGoal(goal.id);
                      }}
                    >
                      Delete
                    </Button>
                    <Space />
                    <Button
                      primary
                      style={{ maxWidth: 120 }}
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ infoChangeAccount: null });
                        this.handleOpenUpdateGoalModal(goal);
                      }}
                    >
                      Edit
                    </Button>
                  </GoalControls>
                </GoalBox>
              ))
            ) : (
              <div>
                <p style={{ margin: "0", fontSize: "28px" }}>Saving for something?</p>
                <p style={{ margin: "0", color: "#8E949C" }}>
                  Let's set a goal to help you stay on track
                </p>
              </div>
            )}
          </GoalsContainer>
        </div>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = state => ({
  goals: selectGoals(state),
  isFetching: state.goals.isFetching,
  error: state.goals.error,
  isSuccessModalOpen: state.goals.isSuccessModalOpen,
  isCreateGoalModalOpen: state.goals.isCreateGoalModalOpen,
  isUpdateGoalModalOpen: state.goals.isUpdateGoalModalOpen,
  isErrorAmountModalOpen: state.goals.isErrorAmountModalOpen,
  successMessage: state.goals.successMessage,
  accounts: selectAccounts(state),
});
export default connect(mapStateToProps, { ...actions, getAccountSummary })(GoalsPage);
