import "date-fns";
import React from "react";
import styled from "styled-components";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    "&:after": {
      borderColor: "#18243C",
    },
    "&:before": {
      borderColor: "#B9C3C7",
    },
  },
  input: {
    color: "#000000",
    fontSize: "0.875rem",
    fontWeight: "300",
    "&:hover:before": {
      borderBottom: "1px solid #18243C !important",
    },
  },
  cssLabel: {
    fontSize: "15px",
    fontWeight: "300",
    color: "#ACABB2",
    fontFamily: "Kumbh Sans",
    "&$cssFocused": {
      color: "#ACABB2",
    },
  },
});

const defaultMaterialTheme = createMuiTheme({
  root: {
    border: "none",
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3FC78E",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#3FC78E",
      },
    },
    MuiOutlinedInput: {
      border: "none",
    },
    PrivateNotchedOutline: {
      root: {
        border: "none",
      },
    },
    MuiInputBase: {
      root: {
        minWidth: "250px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "12px 0px 12px 0px",
      },
    },
  },
});

const ContainerDatePickerCustom = styled.div`
  display: inline-flex;
  margin: 16px;
`;

const DatePickerCustom = props => {
  const { name, onChange, ...rest } = props;
  const classes = useStyles();
  return (
    <ContainerDatePickerCustom>
      <ThemeProvider theme={defaultMaterialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            {...rest}
            autoOk
            inputVariant="standard"
            variant="inline"
            format="MM/dd/yyyy"
            onChange={date =>
              onChange({
                target: {
                  name,
                  value: date,
                },
              })
            }
            InputProps={{
              classes,
            }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ContainerDatePickerCustom>
  );
};

export default DatePickerCustom;
