import React, { useEffect, Fragment } from "react";

import PropTypes from "prop-types";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import _ from "lodash";

import { isArray } from "../../../../utils/type";
import { getHourMinutesPmAm } from "../../../../utils/helper";
import { FinancialInstitutionSecuredFormsCustomerStatus } from "../../../../utils/enums";
import { isArchived, isFavorite } from "../../../../utils/containers/admin/forms";

const FormDetailContainer = styled.div`
  .container-form-selected {
    display: flex;
    flex: 1;
    height: 80px;
    border-bottom: solid 1px #ebeef1;

    .container-form-selected-left {
      flex: 1;
      span {
        display: block;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #1f2427;
      }

      .origin {
        font-size: 14px;
        color: #1f2427;
      }
    }
    .container-form-selected-right {
      display: flex;
      height: 2rem;
      flex: 0.5;
      align-items: center;
      justify-content: flex-end;

      .start-selected,
      .archive,
      .inbox {
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-right: 5%;
      }

      .time {
        font-size: 12px;
        font-weight: bold;
        color: #1f2427;
        line-height: normal;
      }
    }
  }

  .container-section {
    border-bottom: solid 1px #ebeff3;
    .title-section,
    .title-subValue,
    .value {
      display: block;
    }

    .title-section,
    .title-subValue {
      text-transform: capitalize;
    }
    .title-section {
      padding: 1rem 0px;
      font-size: 22px;
      font-weight: bold;
      line-height: normal;
      color: #1f2427;
    }

    .title-subValue {
      font-size: 16px;
      line-height: normal;
      color: #a5a7a9;
    }

    .value {
      font-size: 22px;
      line-height: normal;
      color: #1f2427;
      padding-bottom: 1rem;
    }
  }
`;

const FormDetail = ({ form, handlerOnChangeStatus }) => {
  const formatTextSpace = string => {
    string = string.replace("-", " ");
    string = string.replace(/[A-Z]/, " " + "$&");

    return string;
  };

  const formatValue = value => {
    if (isArray(value)) {
      return value.map((item, index) => {
        if (value.length === index + 1) return item + ". ";

        return item + ", ";
      });
    }

    return value;
  };
  const onClickIconAction = newStatus => {
    handlerOnChangeStatus(form, form.status, newStatus);
  };

  useEffect(() => {
    if (form.status === FinancialInstitutionSecuredFormsCustomerStatus.UNREAD) {
      handlerOnChangeStatus(
        form,
        form.status,
        FinancialInstitutionSecuredFormsCustomerStatus.READ,
      );
    }

    if (form.status === FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD) {
      handlerOnChangeStatus(
        form,
        form.status,
        FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_READ,
      );
    }
  }, [form]);

  return (
    <FormDetailContainer>
      <div className="container-form-selected">
        <div className="container-form-selected-left">
          <span className="title">
            {form.OLBFinancialInstitutionSecuredForm.OLBSecuredForm.friendlyName}
          </span>
          <span className="origin">{form.origin}</span>
        </div>
        <div className="container-form-selected-right">
          <FeatherIcon
            icon="star"
            size="18"
            className="start-selected"
            style={{
              stroke: isFavorite(form.status) ? "#3fc78e" : "currentColor",
              fill: isFavorite(form.status) ? "#3fc78e" : "none",
            }}
            onClick={() =>
              onClickIconAction(
                isArchived(form.status)
                  ? FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_FAVORITE
                  : FinancialInstitutionSecuredFormsCustomerStatus.FAVORITE,
              )
            }
          />
          {isArchived(form.status) ? (
            <FeatherIcon
              icon="inbox"
              size="18"
              className="inbox"
              onClick={() =>
                onClickIconAction(FinancialInstitutionSecuredFormsCustomerStatus.UNREAD)
              }
            />
          ) : (
            <FeatherIcon
              icon="archive"
              size="18"
              className="archive"
              onClick={() =>
                onClickIconAction(
                  FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD,
                )
              }
            />
          )}

          <span className="time">{getHourMinutesPmAm(form.createdAt)}</span>
        </div>
      </div>

      {form &&
        _.map(form.securedFormValues, (securedFormValue, index) => {
          return (
            <div
              key={`secured-form-value-${securedFormValue.title}-${index}`}
              className="container-section"
            >
              <span className="title-section">
                {formatTextSpace(securedFormValue.title)}
              </span>
              {securedFormValue.values &&
                _.map(securedFormValue.values, ({ titleValue, value }, index) => {
                  return (
                    <Fragment key={`secured-form-value-value-${titleValue}-${index}`}>
                      <span className="title-subValue">
                        {formatTextSpace(titleValue)}
                      </span>
                      <span className="value">{formatValue(value)}</span>
                    </Fragment>
                  );
                })}
            </div>
          );
        })}
    </FormDetailContainer>
  );
};

FormDetail.propTypes = {
  form: PropTypes.object,
  handlerOnChangeStatus: PropTypes.func,
};

export default FormDetail;
