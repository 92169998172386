import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { sortBy, lowerCase, upperCase, isEmpty } from "lodash";
import moment from "moment";
import { getTableRows } from "../utils";

const useFilters = ({ getReportStatus, data = [] }) => {
  const filtersTypes = {
    ACTION: "ACTION",
    DATE: "DATE",
    USERNAME: "USERNAME",
    NONE: "NONE",
  };

  const [dataSorted, setDataSorted] = useState([]);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleClickFilter = ({ target }) => {};

  const handleClickSort = ({ target }) => {
    if (!isOpenMenu) {
      setIsOpenMenu(true);
    }
  };

  const handleCloseSortMenu = ({ target }, menuItem = {}) => {
    if (isOpenMenu) {
      setIsOpenMenu(false);

      sortData(filtersTypes[upperCase(menuItem.type)]);
    }
  };

  const sortData = (type = "") => {
    const currentData = getTableRows(data, getReportStatus);
    switch (type) {
      case filtersTypes.ACTION:
        const dataSortedByAction = sortBy(currentData, [lowerCase(filtersTypes.ACTION)]);

        setDataSorted(dataSortedByAction);
        break;

      case filtersTypes.DATE:
        const dataSortedByDate = currentData.sort((prevData, nextData) => {
          const prevDate = moment(
            `${prevData.date} ${prevData.hour.slice(0, -3)}:00`,
          ).unix();
          const nextDate = moment(
            `${nextData.date} ${nextData.hour.slice(0, -3)}:00`,
          ).unix();

          if (prevDate < nextDate) return 1;
          if (prevDate > nextDate) return -1;
          return 0;
        });

        setDataSorted(dataSortedByDate);
        break;

      case filtersTypes.USERNAME:
        const DateSortedByUsername = sortBy(currentData, [
          lowerCase(filtersTypes.USERNAME),
        ]);

        setDataSorted(DateSortedByUsername);
        break;

      default:
        setDataSorted(currentData);
        break;
    }
  };

  useEffect(() => {
    const currentData = getTableRows(data, getReportStatus);

    if (!isEmpty(data)) setDataSorted(currentData);
  }, [data]);

  return {
    isOpenMenu,
    dataSorted,
    filtersTypes,
    handleClickFilter,
    handleClickSort,
    handleCloseSortMenu,
  };
};

useFilters.defaultProps = {
  data: [],
  getReportStatus: (item = {}) => ({
    ok: false,
    label: "",
    icon: "",
  }),
};

useFilters.prototype = {
  data: PropTypes.array.isRequired,
  getReportStatus: PropTypes.func.isRequired,
};

export default useFilters;
