import React from "react";
import { connect } from "react-redux";

import SimpleAnalytics from "../../components/containers/Admin/SimpleAnalytics";

function SimpleAnalyticsMarketing({ simpleAnalyticsUrl }) {
  return <SimpleAnalytics simpleAnalyticsUrl={simpleAnalyticsUrl} />;
}

const mapStateToProps = state => ({
  simpleAnalyticsUrl: state.config.config.simpleAnalyticsUrl,
});

export default connect(mapStateToProps, {})(SimpleAnalyticsMarketing);
