import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { ReactSVG } from "react-svg";

const SearchInput = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "7px 10px 5px 10px",
      fontFamily: "Kumbh Sans",
      fontSize: "14px",
      "&::placeholder": {
        color: "#a5a7a9",
        opacity: 1,
      },
      "&:focus": {
        "&::placeholder": {
          opacity: 0,
        },
      },
    },
    "& .MuiInputAdornment-positionStart": {
      margin: "0",
      paddingLeft: "10px",
      paddingTop: "3px",
    },
    "& .MuiOutlinedInput-adornedStart svg": {
      stroke: "#838486",
      width: "20px",
      height: "20px",
    },
    "&:hover": {
      "& .MuiOutlinedInput-input": {
        "&::placeholder": {
          color: "#1f2427",
          opacity: 1,
        },
      },
      "& .MuiOutlinedInput-adornedStart svg": {
        stroke: "#1f2427",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3fc78e",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffffff",
      },
      "&:hover fieldset": {
        borderColor: "#ffffff",
      },
      "&.Mui-focused": {
        backgroundColor: "rgba(235, 238, 241, 0.4)",
        "& .MuiInputAdornment-root svg": {
          stroke: "#1f2427",
        },
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3fc78e",
        borderWidth: "1px",
      },
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

export default function Search({ endAdornment, formStyleSearch, ...inputProps }) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate style={formStyleSearch}>
      <SearchInput
        style={{ marginLeft: 0 }}
        fullWidth
        placeholder="Search"
        {...inputProps}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ReactSVG src={"../../../../icons/search.svg"} />
            </InputAdornment>
          ),
          endAdornment: endAdornment,
        }}
      />
    </form>
  );
}
