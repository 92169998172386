import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { P, Box } from "../../components/commons";
// import { Box } from 'components/commons';

const MobileTableButton = styled.button`
  display: flex;
  flex: 1;
  border: none;
  font-size: 13px;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  background: ${({ danger }) => (danger ? "rgb(255, 244, 245)" : "#f8f9fa")};

  & > p {
    font-weight: 500;
    color: ${({ danger }) => (danger ? "rgb(255, 102, 112)" : "#7A7A7A")};
  }
`;

const MobileTableColumns = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;

const MobileTableButtonGroup = ({ children }) => (
  <div style={{ display: "flex" }}>{children}</div>
);
MobileTableButtonGroup.propTypes = { children: PropTypes.any };

const MobileTable = ({ children, title }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {title && (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          padding: 12,
          height: 20,
          textTransform: "capitalize",
          fontWeight: "bold",
          fontSize: "13px",
          color: "#3FC78E",
          letterSpacing: "2px",
        }}
      >
        {title}
      </div>
    )}
    <div
      style={{
        padding: "0",
        background: "#FCFCFC",
        border: "1px solid #EBEFF3",
        borderRadius: "5px",
      }}
    >
      {children}
    </div>
  </div>
);
MobileTable.propTypes = { children: PropTypes.any, title: PropTypes.string };

const MobileTableRow = ({ children }) => (
  <div
    style={{
      display: "flex",
      padding: "16px 16px 0 16px",
      borderBottom: "1px solid #EBEFF3",
    }}
  >
    {children}
  </div>
);
MobileTableRow.propTypes = { children: PropTypes.any };

const MobileTableHeading = ({ value, name }) => (
  <div
    style={{
      color: "#8E949C",
      marginBottom: "16px",
      fontSize: "16px",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    {name}&nbsp;<span style={{ color: "#141F2D" }}>{value}</span>
  </div>
);
MobileTableHeading.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const MobileTableCell = ({ children, name, value, textAlign }) => (
  <Box column className="order">
    {name && value && (
      <MobileTableColumns>
        {name === "Pmt Amount" && (
          <P>
            {name} {value || ""}
          </P>
        )}
        {name === "Next due date" && (
          <P>
            {name} {value || ""}{" "}
          </P>
        )}
        {name === "Balance" && (
          <P>
            {name} {value || ""}
          </P>
        )}
        {name === "Available" && (
          <P>
            {name} {value || ""}
          </P>
        )}
        {name === "Date" && <P>{value || ""}</P>}
        {name === "message" && <P>{value || ""}</P>}
      </MobileTableColumns>
    )}
    <div>{children}</div>
  </Box>
);
MobileTableCell.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.any,
  textAlign: PropTypes.string,
};

export {
  MobileTable,
  MobileTableButtonGroup,
  MobileTableButton,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
};
