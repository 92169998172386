import React, { useState } from "react";
// import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button, Divider, TextField } from "../../../components/commons";
import { StepText, StepTitle } from "../steps/Steps";
import styles from "../steps/step.module.css";
import isValidEmail from "../../../utils/isValidEmail";

const forgotPasswordInputTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#3fc78e",
    },
    secondary: {
      main: "#3fc78e",
    },
  },
});

function ResetPasswordViaEmail({}) {
  const [emailOrUsername, setEmailOrUsername] = useState("");

  const isValid =
    !!emailOrUsername && emailOrUsername.length > 5 && isValidEmail(emailOrUsername);

  const handleOnSubmit = ({ target }) => {
    if (isValid) {
      console.log("Send Email", emailOrUsername);
    }
  };

  const handleChangeVerifyValue = ({ target }) => {
    setEmailOrUsername(target.value);
  };

  const handleKeyPress = event => {
    const { key, preventDefault, stopPropagation } = event;

    if (key === "Enter") {
      preventDefault();

      stopPropagation();

      handleOnSubmit(event);
    }
  };

  const goToForgotYourPassword = () => {
    window.location.replace("/forgot-password");
  };

  return (
    <Container maxWidth="sm" style={{ height: "100%", display: "grid", padding: 20 }}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        wrap="nowrap"
        className={styles.newSecondStepAuthMainContainer}
      >
        <Grid item style={{ marginBottom: 10, height: 50 }}>
          <a href="/">
            <img
              src="https://olb-assets.s3.us-east-2.amazonaws.com/images/elements-brand-large-color.svg"
              alt="Powered Logo"
              className={styles.coverImage}
            />
          </a>
        </Grid>

        <Grid item style={{ margin: "15px 0", height: 1 }}>
          <Divider />
        </Grid>

        <Grid item style={{ margin: "25px 0 85px 0" }}>
          <StepTitle className={styles.newSecondStepAuthMainContainerTitle}>
            Reset Password
          </StepTitle>
        </Grid>

        <Grid item style={{ marginBottom: 35 }}>
          <StepText className={styles.newSecondStepAuthMainContainerDetails}>
            Please enter your e-mail so that we can send you a link to reset your
            password.
          </StepText>
        </Grid>

        <Grid item style={{ marginBottom: 50 }}>
          <ThemeProvider theme={forgotPasswordInputTheme}>
            <TextField
              type="email"
              label="Email or Username"
              notMargin
              fullWidth
              onChange={handleChangeVerifyValue}
              value={emailOrUsername || ""}
              autoFocus
              onKeyPress={handleKeyPress}
              name="resetPasswordViaEmailInput"
              id="resetPasswordViaEmailInput"
              className={styles.newSecondStepAuthMainContainerInput}
              style={{ fontSize: 18, color: "#1b2846" }}
            />
          </ThemeProvider>
        </Grid>

        <Grid item xs={12} />

        <Grid container direction="column" item style={{ marginBottom: 50 }}>
          <Button
            primary={isValid}
            disabled={!isValid}
            onClick={event => handleOnSubmit(event)}
            style={{
              width: "100%",
              fontWeight: "bold",
              marginBottom: 20,
              color: "#ffffff",
            }}
          >
            Send Email
          </Button>

          <Button
            onClick={goToForgotYourPassword}
            style={{ width: "100%", fontWeight: "bold", color: "#141f2d" }}
          >
            Back To Login
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

ResetPasswordViaEmail.propTypes = {
  handleChangeStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
};

ResetPasswordViaEmail.defaultProps = {
  handleChangeStep: () => {},
  onSubmit: () => {},
};

export default ResetPasswordViaEmail;
