import React, { useState } from "react";
import TextField from "../../../components/commons/TextField";
import styled from "styled-components";
import { PlusCircleBlack } from "../../../components/commons/icons";

const ContainerNoteInput = styled.div`
  cursor: pointer;
  .notePlus {
    display: flex;
    .note {
      color: #000000;
      font-family: "Kumbh Sans";
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
    }
  }
`;
const NoteInput = props => {
  const [showInput, setShowInput] = useState(false);
  return (
    <ContainerNoteInput>
      {showInput ? (
        <TextField {...props} fullWidth noneborderBottom />
      ) : (
        <div className="notePlus" onClick={() => setShowInput(true)}>
          <PlusCircleBlack
            styles={{
              display: "flex",
              alignItems: "center",
              marginRight: "5px",
            }}
            stylesInput={{
              opacity: 1,
              width: "20px",
              height: "20px",
            }}
          />
          <span className="note">Note</span>
        </div>
      )}
    </ContainerNoteInput>
  );
};

export default NoteInput;
