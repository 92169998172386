import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Select = styled.select`
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  cursor: pointer;
  font-weight: 400;
  background-image: url("../../icons/chevron-down.svg");
  background-position: right;
  background-position-x: 95%;
  background-repeat: no-repeat;
  background-color: #edeced;
  border: none;
  height: auto;
  border-radius: 10px;
  line-height: 1.5em;
  font-size: 0.9rem;
  padding: 0.4rem 1rem;
`;
export default class A4Select extends Component {
  static propTypes = {
    children: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };
  state = {
    value: "Select an Option",
  };
  onChange = () => {
    this.props.onChange();
  };
  render() {
    return (
      <Select value={this.props.value} onChange={this.onChange} {...this.props}>
        <option value="Select an Option">Select an Option</option>
        {this.props.children}
      </Select>
    );
  }
}
