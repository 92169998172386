/*
 *
 * Dashboard actions
 *
 */
import {
  SHOW_DASHBOARD_MENU,
  SHOW_MOBILE_LOGOUT,
  SHOW_MOBILE_MENU,
  SHOW_MOBILE_USER_MENU,
} from "./constants";

export const showMobileMenu = (show = undefined) => dispatch =>
  dispatch({ type: SHOW_MOBILE_MENU, payload: show });

export const showMobileUserMenu = (show = undefined) => dispatch =>
  dispatch({ type: SHOW_MOBILE_USER_MENU, payload: show });

export const showMobileLogout = (show = undefined) => dispatch =>
  dispatch({ type: SHOW_MOBILE_LOGOUT, payload: show });

export const showDashboardMenu = (show = undefined) => dispatch =>
  dispatch({ type: SHOW_DASHBOARD_MENU, payload: show });
