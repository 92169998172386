import React from "react";
import PropTypes from "prop-types";
const ProgressBar = ({ progress }) => (
  <div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#4CD994",
        width: progress || "0px",
        height: 7,
        borderRadius: 10,
        border: "none",
      }}
    ></div>
  </div>
);

ProgressBar.propTypes = {
  progress: PropTypes.string,
};

export default ProgressBar;
