import _ from "lodash";
import moment from "moment";
import { refreshToken } from "../server";

export const getCurrentTime = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!
  const yyyy = today.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  today = `${yyyy}-${mm}-${dd}`;
  return today;
};

export const getFromDate = () => {
  const now = new Date();
  now.setDate(1);
  now.setMonth(now.getMonth() - 5);
  let dd = now.getDate();
  let mm = now.getMonth() + 1; // January is 0!
  const yyyy = now.getFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
};

export const getToDate = () => {
  const now = new Date();
  const dd = now.getDate();
  let mm = now.getMonth() + 1; // January is 0!
  const yyyy = now.getFullYear();

  const lastDayOfCurrentMonth = new Date(yyyy, mm, 0).getDate();
  const lastMonthOfCurrentYear = 12;
  let advancedDate = dd + 3;
  let toDate;

  if (advancedDate > lastDayOfCurrentMonth) {
    if (mm === lastMonthOfCurrentYear) {
      toDate = new Date(yyyy + 1, 0, advancedDate - lastDayOfCurrentMonth);
    }
    toDate = new Date(yyyy, mm, advancedDate - lastDayOfCurrentMonth);
    let toDateDay = toDate.getDate();
    let toDateMonth = toDate.getMonth() + 1;

    if (toDateDay < 10) {
      toDateDay = `0${toDateDay}`;
    }
    if (toDateMonth < 10) {
      toDateMonth = `0${toDateMonth}`;
    }
    return `${toDate.getFullYear()}-${toDateMonth}-${toDateDay}`;
  }
  if (advancedDate < 10) {
    advancedDate = `0${advancedDate}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${advancedDate}`;
};

export const getViewUsageFromDate = () => {
  const now = new Date();
  const dd = now.getDate();
  let mm = now.getMonth() + 1; // January is 0!
  const yyyy = now.getFullYear(); // current year

  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth()).getDate();
  const firstMonth = 1;
  let beforeDate = dd - 7;
  const lastDayOfLastMonth = new Date(yyyy, mm - 1, 0).getDate();
  let fromDate;

  if (mm === firstMonth) {
    if (beforeDate < firstDayOfMonth) {
      const lastMonthOfYear = 11;
      const lastDayOfJanuary = 31;
      fromDate = new Date(yyyy - 1, lastMonthOfYear, lastDayOfJanuary + beforeDate);

      let fromDateDay = fromDate.getDate();
      let fromDateMonth = 12;
      const fromDateYear = fromDate.getFullYear();

      if (fromDateDay < 10) {
        fromDateDay = `0${fromDateDay}`;
      }
      if (fromDateMonth < 10) {
        fromDateMonth = `0${fromDateMonth}`;
      }
      return `${fromDateYear}-${fromDateMonth}-${fromDateDay}`;
    }
  }

  if (mm > firstMonth && beforeDate < firstDayOfMonth) {
    fromDate = new Date(yyyy, mm - 1, lastDayOfLastMonth + beforeDate);

    let fromDateDay = fromDate.getDate();
    let fromDateMonth = fromDate.getMonth();

    if (fromDateDay < 10) {
      fromDateDay = `0${fromDateDay}`;
    }
    if (fromDateMonth < 10) {
      fromDateMonth = `0${fromDateMonth}`;
    }
    return `${yyyy}-${fromDateMonth}-${fromDateDay}`;
  }

  fromDate = new Date(yyyy, mm, beforeDate);
  if (beforeDate < 10) {
    beforeDate = `0${beforeDate}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${beforeDate}`;
};

export const formatDate = (dateString, format = null) => {
  const date = new Date(dateString);

  let dd = date.getUTCDate();
  let mm = date.getUTCMonth() + 1; // January is 0!
  const yyyy = date.getUTCFullYear();

  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  return format === "MM-DD-YYYY" ? `${mm}-${dd}-${yyyy}` : `${yyyy}-${mm}-${dd}`;
};

// es-lint disable next line
export function handleHttpError({ response }, { dispatch, push }) {
  const { status } = response;
  if (response && status) {
    switch (status) {
      case 401:
        if (validationInactive(window.location.pathname)) {
          dispatch(push("/401"));
        }
        return true;
      default:
        return false;
    }
  }
  return false;
}

export const handleSubmitSSOForm = (url, params, type) => {
  if (type === "get") {
    return window.open(url);
  }
  const form = document.createElement("form");
  form.setAttribute("id", "formId");
  form.setAttribute("method", type);
  form.setAttribute("action", url);

  _.forEach(params, ({ name, value }) => {
    const hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", name);
    hiddenField.setAttribute("value", value);

    form.appendChild(hiddenField);
  });

  const Window = window.open(url);
  Window.document.body.appendChild(form);

  form.submit();
  Window.document.getElementById("formId").remove();
  return true;
};

export const newToken = (maxSessionMinutes, isAdmin = false) => {
  if (isAdmin) {
    validateDateToken(
      localStorage.getItem("tokenAdmin"),
      localStorage.getItem("tokenStartDateAdmin"),
      "request_update_token_admin",
      maxSessionMinutes,
    );
  } else {
    validateDateToken(
      localStorage.getItem("token"),
      localStorage.getItem("tokenStartDate"),
      "request_update_token",
      maxSessionMinutes,
    );
  }
};

const validateDateToken = (
  token,
  tokenStartDate,
  request_update_token,
  maxSessionMinutes,
) => {
  if (token) {
    let tokenExpired = null;

    tokenExpired = moment(tokenStartDate).add(maxSessionMinutes, "minutes");

    let currentDate = moment();
    let minutesToExpireToken = tokenExpired.diff(currentDate, "minutes", true);
    // it only does it when the token date is less than or equal to the current one
    if (currentDate.unix() <= tokenExpired.unix()) {
      // it is validated that a minute passes from the token date to update the token
      if (maxSessionMinutes - minutesToExpireToken >= 1) {
        localStorage.setItem(request_update_token, true);

        refreshToken();
      }
    }
  }
};

export const validationInactive = pathname => {
  if (
    pathname !== "/401" &&
    pathname !== "/404" &&
    pathname !== "/" &&
    pathname !== "/admin"
  ) {
    return true;
  }
  return false;
};

export const getHourMinutesPmAm = dateString => {
  const date = moment(dateString);

  return date.format("hh:mm A");
};

export const checkLocation = (currentLocation, path) => {
  if (currentLocation === path) {
    return true;
  }

  return false;
};

export const validateRouteAdmin = () => {
  let path = window.location.pathname;

  if (path && path[0] === "/") {
    path = path.substring(1, path.length);
  }

  if (
    path &&
    path.indexOf("/") > -1 &&
    path.substring(0, path.indexOf("/")) === "admin"
  ) {
    return true;
  }

  if (path && path.indexOf("/") === -1 && path === "admin") {
    return true;
  }

  return false;
};

export const isLocalhost = () => {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );
};
