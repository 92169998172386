import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import _ from "lodash";
import TextField from "../../../components/commons/TextField";
import Select from "../../../components/commons/SelectInput";
import { InputAdornment, MenuItem } from "@material-ui/core";
import {
  PageTitle,
  P,
  Heading,
  Separator,
  Button,
  Space,
  Box,
  Loader,
} from "../../../components/commons";
import { Check } from "../../../components/commons/icons";
import { SuccessModal } from "../../../components/commons/Modals";
import { getCurrentTime } from "../../../utils/helper";
import enums from "../../../utils/enums";
import moment from "moment";
import { selectSharesOptions } from "./selectors";
import * as actions from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const frequencyOptions = enums.AutoPayFrequency;
const autopayMethodOptions = enums.QuickPayToAutoPayMethod;

class ScheduleAutopay extends Component {
  static propTypes = {
    payFromOptions: PropTypes.array,
    goBack: PropTypes.func,
    quickPay: PropTypes.object,
    quickpayToAutopay: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    changeEPaymentsError: PropTypes.func,
    showAutoPayScheduleSuccessModal: PropTypes.func,
    hideScheduleAutoPaySuccessModal: PropTypes.func,
    autoPayToQuickPayConfirmModalOpen: PropTypes.bool,
    push: PropTypes.func,
  };
  state = {
    frequency: "Select Frequency",
    method: enums.QuickPayToAutoPayMethod.CONTINUOUS,
    startDate: getCurrentTime(),
    numberOfPayments: 0,
    paymentAmount: "",
    finalPaymentAmount: "",
    payFrom: "",
  };
  componentWillMount() {
    if (!this.props.quickPay || !this.props.quickPay.uniqueId) {
      // no quick pay was selected or came here by mistake... redirect to main payments page
      this.props.push("/dashboard/payments");
    }
  }
  handleChangeQuickPayToAutoPay() {
    /*
  payload should be:
    - quickPayUniqueId
    - frequency       --> enum
    - startDate       --> MM/DD/YYYY
    - method          --> enum
    - amount
    - fromAccountId
    - fromAccountType --> enum
    - paymentCount    --> not required
    - finalAmount     --> not required
	*/
    const {
      frequency,
      startDate,
      method,
      paymentAmount,
      payFrom,
      numberOfPayments,
      finalPaymentAmount,
    } = this.state;
    if (!this.props.quickPay.uniqueId) {
      this.props.changeEPaymentsError("Quick pay is not valid.");
      return;
    }
    if (!frequency && frequency !== 0) {
      this.props.changeEPaymentsError("Frequency is required.");
      return;
    }
    if (!startDate || !moment(startDate).isValid()) {
      this.props.changeEPaymentsError("Start date is invalid.");
      return;
    }
    if (!method) {
      this.props.changeEPaymentsError("Method is required.");
      return;
    }
    if (!paymentAmount) {
      this.props.changeEPaymentsError("Payment amount is required.");
      return;
    }
    if (!payFrom && payFrom !== 0) {
      this.props.changeEPaymentsError("Pay from is required");
      return;
    }
    if (method === enums.QuickPayToAutoPayMethod.FIXED && !numberOfPayments) {
      this.props.changeEPaymentsError("Number of payments is required");
      return;
    }
    this.props.quickpayToAutopay({
      quickPayUniqueId: this.props.quickPay.uniqueId,
      frequency,
      startDate: moment(startDate).format("MM/DD/YYYY"),
      method,
      amount: paymentAmount,
      fromAccountId: payFrom,
      fromAccountType: enums.AccountType.SHARE,
      paymentCount: numberOfPayments || 0,
      finalAmount: finalPaymentAmount || 0,
    });
  }
  renderSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.autoPayToQuickPayConfirmModalOpen}
        onClose={() => this.props.hideScheduleAutoPaySuccessModal(false)}
        headingText="AutoPay scheduled successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  render() {
    const renderContinuousFields = this.state.method ===
      enums.QuickPayToAutoPayMethod.FIXED && (
      <div>
        <TextField
          type="number"
          id="numberOfPayments"
          label="Number of Payments*"
          name="numberOfPayments"
          aria-label="Number Of Payments"
          value={this.state.numberOfPayments}
          onChange={e => this.setState({ numberOfPayments: e.target.value })}
        />

        <TextField
          type="text"
          id="finalPaymentAmount"
          label="Final Payment Amount*"
          name="finalPaymentAmount"
          aria-label="Final Payment Amount"
          value={this.state.finalPaymentAmount}
          onChange={e => this.setState({ finalPaymentAmount: e.target.value })}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
      </div>
    );

    return (
      <DashboardScreens>
        <div>
          <Loader isLoading={this.props.isFetching} />
          {this.renderSuccessModal()}
          <PageTitle>Schedule Auto Pay</PageTitle>
          <Separator style={{ margin: "1.5rem 0" }} />
          <P>
            To schedule an auto pay to <strong>{this.props.quickPay.payeeName}</strong>,{" "}
            <br /> please enter the information below and click OK.
          </P>
          <Separator style={{ margin: "1.5rem 0" }} />
          <Heading>
            Payement Information <small>(* Required fields)</small>
          </Heading>
          <form
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "3rem",
              padding: 1,
            }}
          >
            <Select
              id="frequency"
              name="frequency"
              aria-label="Payees Names"
              label={"Frequency*"}
              onChange={e => this.setState({ frequency: e.target.value })}
              value={this.state.frequency}
            >
              <MenuItem value={this.state.frequency}>{this.state.frequency}</MenuItem>
              {frequencyOptions &&
                _.map(frequencyOptions, (option, key) => (
                  <MenuItem key={key} value={option}>
                    {key}
                  </MenuItem>
                ))}
            </Select>

            <TextField
              type="date"
              id="startDate"
              label="Starting Payment Date"
              name="startDate"
              aria-label="Starting Payment Date"
              value={this.state.startDate}
              onChange={e => this.setState({ startDate: e.target.value })}
            />

            <TextField
              type="text"
              id="paymentAmount"
              label="Payment Amount*"
              name="startDate"
              aria-label="Starting Payment Date"
              value={this.state.paymentAmount}
              onChange={e => this.setState({ paymentAmount: e.target.value })}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />

            <Select
              id="method"
              name="method"
              aria-label="Auto Pay Method"
              label={"Auto Pay Method*"}
              onChange={e => this.setState({ method: e.target.value })}
              value={this.state.method}
            >
              {autopayMethodOptions &&
                _.map(autopayMethodOptions, (option, key) => (
                  <MenuItem key={key} value={option}>
                    {key}
                  </MenuItem>
                ))}
            </Select>

            {renderContinuousFields}

            <Select
              id="payFrom"
              name="payFrom"
              aria-label="Pay From"
              label={"Pay From*"}
              onChange={e => this.setState({ payFrom: e.target.value })}
              value={this.state.payFrom}
            >
              {this.props.payFromOptions &&
                _.map(this.props.payFromOptions, (option, key) => (
                  <MenuItem key={key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
            </Select>
          </form>
          {this.props.error ? (
            <P style={{ color: "darkred" }}> {this.props.error} </P>
          ) : (
            ""
          )}
          <Box>
            <Button danger onClick={() => this.props.goBack()}>
              Cancel
            </Button>
            <Space />
            <Button onClick={() => this.handleChangeQuickPayToAutoPay()} primary>
              OK
            </Button>
          </Box>
        </div>
      </DashboardScreens>
    );
  }
}
const mapStateToProps = state => ({
  payFromOptions: selectSharesOptions(state),
  quickPay: state.epayment.quickPayToAutoPay,
  error: state.epayment.error,
  isFetching: state.epayment.isFetching,
  autoPayToQuickPayConfirmModalOpen: state.epayment.autoPayToQuickPayConfirmModalOpen,
});
export default connect(mapStateToProps, { goBack, ...actions })(ScheduleAutopay);
