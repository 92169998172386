import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { Loader, PageTitle, Box } from "../../../components/commons";
import { getFAQs } from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const Container = styled.div``;

const Space10 = styled.div`
  height: 25px;
`;

const DivFx = styled.div`
  flex: 1;
  @media (max-width: 450px) {
    flex: 0;
  }
`;

const PHelp = styled.p`
  font-size: 16px;
  color: #292b36;
  margin: 0;
`;

const Faqs = styled.p`
  font-size: 24px;
  color: #292b36;
  margin-bottom: 20px;
  font-weight: bold;
`;

const PHelpBold = styled.p`
  font-size: 16px;
  color: #292b36;
  font-weight: bold;
  margin: 0;
`;

class HelpPage extends Component {
  static propTypes = {
    getFAQs: PropTypes.func,
    questions: PropTypes.array,
    isFetching: PropTypes.bool,
    helpEmail: PropTypes.string,
    helpPhone: PropTypes.string,
    helpText: PropTypes.string,
    // error: PropTypes.string,
  };
  componentDidMount() {
    this.props.getFAQs();
  }
  render() {
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={this.props.isFetching} />
          <PageTitle>Help</PageTitle>
          <PHelp>{this.props.helpText}</PHelp>
          <Space10 />
          <div>
            <br />
            <div>
              <PHelpBold>Call Us</PHelpBold>
              <a href="#any">
                <PHelp>{this.props.helpPhone}</PHelp>
              </a>
            </div>
            <Space10 />
            <div>
              <PHelpBold>Email Us</PHelpBold>
              <a href="#any" onClick={() => {}}>
                <PHelp>{this.props.helpEmail}</PHelp>
              </a>
            </div>
          </div>
          <Space10 />
          <Space10 />
          <Faqs>FAQs</Faqs>
          <Box>
            <div>
              {this.props.questions &&
                _.map(this.props.questions, ({ question, answer }, i) => (
                  <div>
                    <PHelpBold>{question}</PHelpBold>
                    <PHelp>{answer}</PHelp>
                    <Space10 />
                  </div>
                ))}
            </div>
            <DivFx />
          </Box>
        </Container>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = ({
  help: { questions, error, isFetching, helpText, helpPhone, helpEmail },
}) => ({ questions, error, isFetching, helpText, helpPhone, helpEmail });
export default connect(mapStateToProps, { getFAQs })(HelpPage);
