import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import Form from "../../components/containers/Admin/Forms/Form";
import FormDetail from "../../components/containers/Admin/Forms/FormDetail";
import HeaderListForm from "../../components/containers/Admin/Forms/HeaderListForm";
import HeaderActionTab from "../../components/containers/Admin/Forms/HeaderActionTab";
import ControlPanel from "../../components/commons/controlPanel";
import { Desktop } from "../../components/commons/Responsive";

import { getForms, sortForms, changeStatusFormMany, changeStatusForm } from "./actions";
import { sortFormOptions } from "../../utils/options";
import { changeStatus, isArchived } from "../../utils/containers/admin/forms";
import enums, {
  FinancialInstitutionSecuredFormsCustomerStatus,
  FinancialInstitutionSecuredFormsCustomerSource,
} from "../../utils/enums";
import { checkLocation } from "../../utils/helper";

const ListFormContainer = styled.div`
  display: flex;
  border: solid 1px #ebeef1;

  .list-container-left {
    flex: 0.6;
    border-right: solid 1px #ebeef1;
  }

  .list-container-right {
    flex: 1;
    padding: 3% 4% 4% 4%;

    .not-form-selected,
    .loader-detail-form {
      flex: 1;
      align-self: center;
      text-align: center;
    }
  }
`;

const Container = styled.div`
  min-height: 10%;
  padding: 2% 5% 2% 5%;

  .MuiOutlinedInput-adornedStart {
    padding-left: 0px;
  }
`;

const tabs = [
  {
    id: 1,
    iconName: "inbox",
    path: "/admin/panel/forms",
    displayName: "Inbox",
  },
  {
    id: 2,
    iconName: "archive",
    path: "/admin/panel/forms/archived",
    displayName: "Archived",
  },
];

const Forms = ({
  forms,
  getForms,
  sortForms,
  changeStatusFormMany,
  changeStatusForm,
  isFetching,
}) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [sort, setSort] = useState(sortFormOptions[0]);
  const [formSelected, setFormSelected] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState(null);
  const [checkedForms, setCheckedForms] = useState([]);
  const [formsSelectedFirst, setFormsSelectedFirst] = useState([]);
  const currentLocation = window.location.pathname;
  const { WEBSITE, BANKING_APP } = FinancialInstitutionSecuredFormsCustomerSource;
  const {
    UNREAD,
    ARCHIVED_UNREAD,
    READ,
    ARCHIVED_READ,
    FAVORITE,
    ARCHIVED_FAVORITE,
  } = FinancialInstitutionSecuredFormsCustomerStatus;

  const filterItems = [
    {
      id: 1,
      title: "Status",
      link: "/",
      sub: [
        {
          id: 11,
          title: "Unread",
          link: "/",
          value: checkLocation(currentLocation, "/admin/panel/forms")
            ? UNREAD
            : ARCHIVED_UNREAD,
        },
        {
          id: 12,
          title: "Read",
          link: "/",
          value: checkLocation(currentLocation, "/admin/panel/forms")
            ? READ
            : ARCHIVED_READ,
        },
      ],
    },
    {
      id: 2,
      title: "From",
      link: "/",
      sub: [
        {
          id: 21,
          title: "Banking App",
          link: "/",
          value: BANKING_APP,
        },
        {
          id: 22,
          title: "Website",
          link: "/",
          value: WEBSITE,
        },
      ],
    },
    {
      id: 3,
      title: "More Filters",
      link: "/",
      sub: [
        {
          id: 31,
          title: "Favorite",
          link: "/",
          value: checkLocation(currentLocation, "/admin/panel/forms")
            ? FAVORITE
            : ARCHIVED_FAVORITE,
        },
      ],
    },
  ];

  const formatSecuredFormValues = securedFormValues => {
    let newSecuredFormValues = [];

    Object.entries(securedFormValues).forEach(([key, value]) => {
      let subValues = Object.entries(value).map(([key, value]) => ({
        titleValue: key,
        value,
      }));

      newSecuredFormValues.push({ title: key, values: subValues });
    });

    return newSecuredFormValues;
  };

  const sortFirstFormSelect = form => {
    return [form, ...forms.filter(item => item.id !== form.id)];
  };

  const onSelectForm = form => {
    if (formSelected && formSelected.id === form.id) {
      setFormsSelectedFirst([]);
      setFormSelected(null);
    } else {
      setFormsSelectedFirst(sortFirstFormSelect(form));
      setFormSelected({
        ...form,
        securedFormValues: formatSecuredFormValues(form.securedFormValues),
      });
    }
  };

  const addCheckedForms = form => {
    setCheckedForms([...checkedForms, form]);
  };

  const removeAllCheckedForms = () => {
    setCheckedForms([]);
  };

  const removeCheckedForms = id => {
    setCheckedForms([...checkedForms.filter(item => item.id !== id)]);
  };

  const formIsChecked = id => {
    const form = checkedForms.find(item => item.id === id);

    return form ? true : false;
  };

  const formIsSelected = id => {
    if (formSelected && formSelected.id === id) return true;

    return false;
  };

  const onChangeCheckboxForm = (e, form) => {
    e.target.checked ? addCheckedForms(form) : removeCheckedForms(form.id);
  };

  const getNumberForms = () => {
    if (isSearch) return forms ? forms.length : 0;

    return forms
      ? forms.filter(item => item.status === UNREAD || item.status === ARCHIVED_UNREAD)
          .length
      : 0;
  };

  const handlerChangeStatusFormMany = status => {
    changeStatusFormMany({
      formCustomers: checkedForms.map(item => ({ status, id: item.id })),
      sort: sort.value,
      currentLocation,
      searchFriendlyName: search,
    });
    setCheckedForms([]);
  };

  const isFormsArchived = () => {
    if (checkedForms.length > 0 && isArchived(checkedForms[0].status)) {
      return FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_READ;
    }
    return FinancialInstitutionSecuredFormsCustomerStatus.READ;
  };

  const handlerOnKeyPressSearch = e => {
    if (e.key === "Enter") {
      if (search.length > 0) {
        setIsSearch(true);
        refreshForms(activeFilters, search);
      } else {
        setIsSearch(false);
        refreshForms(activeFilters, search);
      }
      e.preventDefault();
    }
  };

  const handlerOnChangeStatus = (form, oldStatus, newStatus) => {
    changeStatus(
      form,
      newStatus,
      oldStatus,
      formSelected && formSelected.id === form.id ? setFormSelected : null,
      changeStatusForm,
      sort,
      currentLocation,
      search,
    );

    if (oldStatus == enums.FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_READ) {
      setFormSelected(null);
    }
  };

  const getFiltersSource = filters => {
    return filters.filter(item => item.value === WEBSITE || item.value === BANKING_APP);
  };

  const getFiltersStatus = filters => {
    return filters.filter(item => item.value !== WEBSITE && item.value !== BANKING_APP);
  };

  const refreshForms = (formRefresh = [], searchFriendlyName) => {
    const status = getFiltersStatus(formRefresh);
    const sources = getFiltersSource(formRefresh);

    getForms({
      currentLocation,
      sort: sort.value,
      searchFriendlyName,
      status: status.length === 0 ? null : status.map(item => item.value),
      sources: sources.length === 0 ? null : sources.map(item => item.value),
    });
  };

  useEffect(() => {
    refreshForms(activeFilters, search);
  }, []);

  useEffect(() => {
    sortForms(sort.value);
  }, [sort]);

  useEffect(() => {
    if (formSelected && forms && !forms.find(item => item.id === formSelected.id)) {
      setFormSelected(null);
      setFormsSelectedFirst([]);
    }

    if (formSelected && forms && forms.find(item => item.id === formSelected.id)) {
      setFormsSelectedFirst(sortFirstFormSelect(formSelected));
    }
  }, [forms]);

  return (
    <Desktop>
      <Container>
        <ControlPanel
          title="Forms"
          tabs={tabs}
          filterItems={filterItems}
          onChangeSearch={e => setSearch(e.target.value)}
          valueSearch={search}
          onKeyPressSearch={handlerOnKeyPressSearch}
          deleteFilter={id => {
            const filter = activeFilters.filter(item => item.id !== id);
            setActiveFilters(filter);
            refreshForms(filter, search);
          }}
          activeFilters={activeFilters}
          setActiveFilters={filters => {
            setActiveFilters(filters);
            refreshForms(filters, search);
          }}
          ComponentNotSearch={
            checkedForms.length > 0 ? (
              <HeaderActionTab
                handlerChangeStatusFormMany={handlerChangeStatusFormMany}
                status={isFormsArchived()}
              />
            ) : null
          }
          loading={isFetching}
          endAdornmentSearch={
            search ? (
              <FeatherIcon
                icon="x"
                size="6"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                  setIsSearch(false);
                  if (isSearch) {
                    refreshForms(activeFilters, "");
                  }
                }}
              />
            ) : null
          }
          formStyleSearch={{ marginLeft: "0px" }}
        />
        <ListFormContainer>
          <div className="list-container-left">
            <HeaderListForm
              numberForms={getNumberForms()}
              sort={sort}
              changeSort={setSort}
              checkedForms={checkedForms}
              removeAllCheckedForms={removeAllCheckedForms}
              resultDescription={isSearch ? "Results Found" : "New Messages"}
            />
            {isFetching ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress style={{ color: "#05CE86" }} />
              </div>
            ) : forms && forms.length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <span>Empty</span>
              </div>
            ) : (
              <>
                {forms &&
                  _.map(
                    formsSelectedFirst.length > 0 ? formsSelectedFirst : forms,
                    form => {
                      const { id } = form;
                      return (
                        <Form
                          key={`form-${id}`}
                          form={
                            formSelected && formSelected.id == id ? formSelected : form
                          }
                          onSelectForm={() => onSelectForm(form)}
                          formIsChecked={formIsChecked}
                          formIsSelected={formIsSelected}
                          onChangeCheckboxForm={onChangeCheckboxForm}
                          handlerOnChangeStatus={handlerOnChangeStatus}
                        />
                      );
                    },
                  )}
              </>
            )}
          </div>
          <div
            className="list-container-right"
            style={{ display: formSelected && !isFetching ? "block" : "flex" }}
          >
            {isFetching ? (
              <span className="loader-detail-form">
                <CircularProgress style={{ color: "#05CE86" }} />
              </span>
            ) : formSelected ? (
              <FormDetail
                form={formSelected}
                handlerOnChangeStatus={handlerOnChangeStatus}
              />
            ) : (
              <span className="not-form-selected">No Form Selected</span>
            )}
          </div>
        </ListFormContainer>
      </Container>
    </Desktop>
  );
};

Forms.prototype = {
  forms: PropTypes.array,
  getForms: PropTypes.func,
  sortForms: PropTypes.func,
  changeStatusFormMany: PropTypes.func,
  changeStatusForm: PropTypes.func,
  isFetching: PropTypes.bool,
};

const mapStateToProps = state => ({
  forms: state.admin.forms,
  isFetching: state.admin.isFetching,
});

export default connect(mapStateToProps, {
  getForms,
  sortForms,
  changeStatusFormMany,
  changeStatusForm,
})(Forms);
