import React from "react";

import PropTypes from "prop-types";
import FeatherIcon from "feather-icons-react";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { FinancialInstitutionSecuredFormsCustomerStatus } from "../../../../utils/enums";
import { isArchived } from "../../../../utils/containers/admin/forms";
import usePopover from "../../../../hooks/usePopover";

const FormActionMenu = ({ handlerChangeStatusForm, status }) => {
  const [anchorElPopover, setAnchorElPopover, openPopover, idPopover] = usePopover(
    "simple-popover",
  );

  const handleClosePopover = e => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorElPopover(null);
  };

  const handleClickPopover = event => {
    setAnchorElPopover(event.currentTarget);
  };

  const isUnread = status => {
    if (
      status === FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD ||
      status === FinancialInstitutionSecuredFormsCustomerStatus.UNREAD
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      <FeatherIcon
        aria-describedby={idPopover}
        icon="more-vertical"
        size="20"
        onClick={e => {
          e.stopPropagation();
          handleClickPopover(e);
        }}
      />
      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorElPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClick={handleClosePopover}
      >
        <ClickAwayListener onClickAway={handleClosePopover}>
          <List component="nav" aria-label="secondary mailbox folders">
            {!isArchived(status) && (
              <ListItem
                button
                onClick={e => {
                  handleClosePopover(e);
                  handlerChangeStatusForm(
                    FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD,
                  );
                }}
              >
                <ListItemText primary="Archive" />
              </ListItem>
            )}

            {isArchived(status) && (
              <ListItem
                button
                onClick={e => {
                  console.log(FinancialInstitutionSecuredFormsCustomerStatus.UNREAD)
                  handleClosePopover(e);
                  handlerChangeStatusForm(
                    FinancialInstitutionSecuredFormsCustomerStatus.UNREAD,
                  );
                }}
              >
                <ListItemText primary="Inbox" />
              </ListItem>
            )}

            {isUnread(status) && (
              <ListItem
                button
                onClick={e => {
                  handleClosePopover(e);
                  handlerChangeStatusForm(
                    isArchived(status)
                      ? FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_READ
                      : FinancialInstitutionSecuredFormsCustomerStatus.READ,
                  );
                }}
              >
                <ListItemText primary="Mark a Read" />
              </ListItem>
            )}

            <ListItem
              button
              onClick={e => {
                handleClosePopover(e);
                handlerChangeStatusForm(
                  isArchived(status)
                    ? FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_FAVORITE
                    : FinancialInstitutionSecuredFormsCustomerStatus.FAVORITE,
                );
              }}
            >
              <ListItemText primary="Favorite" />
            </ListItem>
          </List>
        </ClickAwayListener>
      </Popover>
    </>
  );
};

FormActionMenu.propTypes = {
  handlerChangeStatusForm: PropTypes.func,
  status: PropTypes.string,
};

export default FormActionMenu;
