import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import Zoom from "@material-ui/core/Zoom";
import AdminModal from "./AdminModal";
import { UserStatus } from "../../../../utils/enums";
import {
  LightTooltip,
  StatusContainer,
  Dropdown,
  DropdownContent,
  DropdownItem,
  IconContainer,
} from "../../../commons/SortableTableStyles";

const checkStatus = status => {
  switch (status) {
    case UserStatus.NONE:
      return <FeatherIcon icon="check-circle" size="16" stroke="#3fc78e" />;
    case UserStatus.BLOCKED:
      return (
        <LightTooltip
          TransitionComponent={Zoom}
          title={
            <p>
              <FeatherIcon icon="info" size="13" stroke="#000000" />
              &nbsp;Locked
            </p>
          }
          placement="left"
        >
          <FeatherIcon icon="lock" size="16" stroke="#141f2d" />
        </LightTooltip>
      );
    case "suspended":
      return <FeatherIcon icon="x-circle" size="16" stroke="#797C7E" />;
    default:
      return <></>;
  }
};

const Status = ({ name, username, status, changeStatusMember, push }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleClick = () => {
    setShowDropdown(prev => !prev);
  };

  const handleClickAway = () => {
    setShowDropdown(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AdminModal
        open={open}
        name={name}
        username={username}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleAction={() => changeStatusMember(UserStatus.NONE)}
        actionQuestion="Are you sure you want to unlock this Member?"
        actionTextButton="Unlock"
      />
      <ClickAwayListener onClickAway={handleClickAway}>
        <StatusContainer>
          {checkStatus(status)}
          <Dropdown>
            <IconContainer clicked={showDropdown} onClick={handleClick}>
              <FeatherIcon icon="more-vertical" size="16" stroke="#141f2d" />
            </IconContainer>
            {showDropdown && (
              <DropdownContent>
                {status === UserStatus.NONE ? (
                  <>
                    <DropdownItem onClick={() => setConfirmationModal(true)}>
                      Lock
                    </DropdownItem>
                    {/* <DropdownItem
                        onClick={() => changeStatusMember(UserStatus.SUSPENDED)}
                      >
                        Suspend
                      </DropdownItem> */}
                  </>
                ) : status === UserStatus.SUSPENDED ? (
                  <DropdownItem onClick={() => changeStatusMember(UserStatus.NONE)}>
                    Activate
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={handleOpen}>Unlock</DropdownItem>
                )}
                <DropdownItem onClick={() => push("/admin/panel/member-eview")}>
                  eView <FeatherIcon icon="external-link" size="16" stroke="#1f2427" />
                </DropdownItem>
              </DropdownContent>
            )}
          </Dropdown>
        </StatusContainer>
      </ClickAwayListener>

      <AdminModal
        open={confirmationModal}
        name={name}
        username={username}
        handleClose={() => setConfirmationModal(false)}
        handleAction={() => changeStatusMember(UserStatus.BLOCKED)}
        actionQuestion="Are you sure you want to lock this Member?"
        actionTextButton="Lock"
      />
    </>
  );
};

Status.prototype = {
  name: PropTypes.string,
  username: PropTypes.string,
  status: PropTypes.number,
  changeStatusMember: PropTypes.func,
  push: PropTypes.func,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { push })(Status);
