import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import RequireAdminAuth from "../../../components/RequireAdminAuth";
import MFAUnlock from "../../Admin/ResetMAF";
import MemberEview from "../../Admin/MemberEview";
import CreateMessage from "../../Admin/CreateMessage";
import CreateNotification from "../../Admin/CreateNotification";
import SimpleAnalyticsOlb from "../SimpleAnalyticsOlb";
import SimpleAnalyticsMarketing from "../SimpleAnalyticsMarketing";
import Members from "../../Admin/Members";
import Admins from "../../Admin/Admins";
import Forms from "../../Admin/Forms";
import ActivityReports from "../../Dashboard/ActivityReports";

function AdminDashboardRoutes({ match }) {
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}/reset-mfa`}
        component={RequireAdminAuth(MFAUnlock)}
      />
      <Route
        exact
        path={`${match.url}/member-eview`}
        component={RequireAdminAuth(MemberEview)}
      />
      <Route
        exact
        path={`${match.url}/create-message`}
        component={RequireAdminAuth(CreateMessage)}
      />
      <Route
        exact
        path={`${match.url}/create-notification`}
        component={RequireAdminAuth(CreateNotification)}
      />
      <Route
        exact
        path={`${match.url}/simple-analytics`}
        component={RequireAdminAuth(SimpleAnalyticsOlb)}
      />
      <Route
        exact
        path={`${match.url}/simple-analytics/marketing`}
        component={RequireAdminAuth(SimpleAnalyticsMarketing)}
      />
      <Route exact path={`${match.url}/forms`} component={RequireAdminAuth(Forms)} />
      <Route
        exact
        path={`${match.url}/forms/archived`}
        component={RequireAdminAuth(Forms)}
      />
      <Route exact path={`${match.url}/members`} component={RequireAdminAuth(Members)} />
      <Route exact path={`${match.url}/admins`} component={RequireAdminAuth(Admins)} />
      <Route
        exact
        path={`${match.url}/activity-reports`}
        component={RequireAdminAuth(ActivityReports)}
      />
    </Switch>
  );
}

AdminDashboardRoutes.propTypes = { match: PropTypes.object };

export default AdminDashboardRoutes;
