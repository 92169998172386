import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  cover: {
    backgroundColor: "rgba(230, 230, 230, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 30,
    bottom: 0,
  },
};

const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});

function Loader({ isLoading, classes }) {
  return (
    <div>
      {isLoading && (
        <div style={styles.cover}>
          <CircularProgress className={classes.progress} style={{ color: "#05CE86" }} />
        </div>
      )}
    </div>
  );
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(loaderStyles)(Loader);
