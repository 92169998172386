import moment from "moment";
import { nanoid } from "nanoid";
import { isEmpty } from "lodash";

import isValidEmail from "../../../../utils/isValidEmail";

export default (data = [], getReportStatus = (item = {}) => ({})) =>
  data.map(item => {
    const { report = {}, OLBUser = {} } = item;
    const { updatedAt } = report;

    const date = moment(updatedAt).format("MM/DD/YYYY");
    const hour = moment(updatedAt).format("HH:mm A");

    if (isEmpty(report)) {
      const { action } = item;
      const { BlossomUser, OLBProfile } = OLBUser;
      const { username, primaryEmail } = BlossomUser;
      const { name } = OLBProfile;

      return {
        id: nanoid(),
        idOLBUser: OLBUser.id,
        avatarSource:
          "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        username: isValidEmail(username) ? username : `@${username}`,
        name: name,
        email: primaryEmail,
        date,
        hour,
        action,
        status: getReportStatus(item),
      };
    } else {
      return {
        id: nanoid(),
        idOLBUser: report.idOLBUser,
        avatarSource:
          "https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
        username: isValidEmail(OLBUser.username)
          ? OLBUser.username
          : `@${OLBUser.username}`,
        name: OLBUser.name,
        email: OLBUser.email,
        date,
        hour,
        status: getReportStatus(report),
      };
    }
  });
