import styled from "styled-components";

const TableSortTitle = styled.h5`
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #a5a7a9;
  text-transform: capitalize;
  font-family: "Kumbh Sans";
`;

export default TableSortTitle;
