import _ from "lodash";
import moment from "moment";
import enums from "../../../utils/enums";

const transactionStatus = _.omit(enums.TransactionStatus, ["UNDEFINED"]);
const accountTypes = enums.AccountType;
const transactionTypes = enums.TransactionSearchType;
export const selectAccounts = (state, forFundsTransfer) => {
  let shares;
  let loans;
  let creditCards;
  if (forFundsTransfer && state.account.fundsTransferSummary) {
    shares = state.account.fundsTransferSummary.shares;
    loans = state.account.fundsTransferSummary.loans;
    creditCards = state.account.fundsTransferSummary.creditCards;
  } else {
    shares = state.account.shares;
    loans = state.account.loans;
    creditCards = state.account.creditCards;
  }
  const shareAccounts =
    shares &&
    _.map(
      shares,
      ({
        uniqueId,
        accountNumber,
        allowBalanceInquiry,
        allowTransactionsFrom,
        allowTransactionsInquiry,
        allowTransactionsTo,
        isTrusted,
        fullName,
        id,
        display,
        description,
        defaultDescription,
        rate,
        balance,
        available,
        hasGoal,
        madurityDate,
        updated,
        type,
      }) => ({
        uniqueId,
        allowBalanceInquiry,
        allowTransactionsFrom,
        allowTransactionsInquiry,
        allowTransactionsTo,
        accountNumber,
        isTrusted,
        fullName,
        display,
        hasGoal,
        id,
        type,
        description,
        defaultDescription,
        rate: rate > 0 ? `${rate.toFixed(3)}%` : "",
        balance: balance,
        available: available,
        madurityDate: moment(madurityDate).utc().format("MM/DD/YYYY"),
        accountType: accountTypes.SHARE,
        updated,
      }),
    );

  const loanAccounts =
    loans &&
    _.map(
      loans,
      ({
        uniqueId,
        accountNumber,
        allowBalanceInquiry,
        allowTransactionsFrom,
        allowTransactionsInquiry,
        allowTransactionsTo,
        display,
        isTrusted,
        fullName,
        hasGoal,
        id,
        description,
        defaultDescription,
        rate,
        regularPmt,
        minAmtDate,
        nextDueDate,
        balance,
        available,
        payoff,
        updated,
        legacyMinAdvanceAmount,
      }) => ({
        id,
        accountNumber,
        allowBalanceInquiry,
        allowTransactionsFrom,
        allowTransactionsInquiry,
        allowTransactionsTo,
        display,
        description,
        hasGoal,
        defaultDescription,
        uniqueId,
        isTrusted,
        fullName,
        rate: rate > 0 ? `${rate.toFixed(3)}%` : "",
        regularPmt: regularPmt,
        minAmtDate: minAmtDate,
        nextDueDate: moment(nextDueDate).utc().format("MM/DD/YYYY"),
        balance: balance,
        available: available,
        payoff: payoff,
        accountType: accountTypes.LOAN,
        updated,
        legacyMinAdvanceAmount,
      }),
    );

  const ccAccounts =
    creditCards &&
    _.map(
      creditCards,
      ({
        id,
        cardNumber,
        cardName,
        asOfDate,
        creditLimit,
        minAmtDate,
        dueDate,
        balance,
        available,
        lastPmtDate,
        lastPmtAmt,
      }) => ({
        id,
        cardNumber,
        cardName,
        description: cardName,
        asOfDate: moment(asOfDate).utc().format("MM/DD/YYYY"),
        creditLimit: creditLimit,
        minAmtDate: minAmtDate,
        dueDate: moment(dueDate).utc().format("MM/DD/YYYY"),
        balance: balance,
        available: available,
        lastPmtDate: moment(lastPmtDate).utc().format("MM/DD/YYYY"),
        lastPmtAmt: lastPmtAmt,
      }),
    );

  return { shares: shareAccounts, loans: loanAccounts, creditCards: ccAccounts };
};
export const selectTransactions = ({ account: { transactions } }) => {
  const trans = _.map(
    transactions,
    ({
      uniqueId,
      id,
      accountId,
      type,
      description,
      transactionDate,
      amount,
      actualDate,
      settleDate,
      pendingType,
      effectiveDate,
      draft,
      status,
      principal,
      interest,
      fee,
      comment,
      balance,
      accountType,
      extraInfo,
    }) => {
      let extraInfoJson = extraInfo;

      if (extraInfo) {
        try {
          extraInfoJson = JSON.parse(extraInfo);
        } catch (error) {
          console.log(error);
        }
      }

      return {
        uniqueId,
        id,
        accountId,
        type,
        description,
        transactionDate: moment(transactionDate).utc().format("MM/DD/YYYY"),
        amount: amount,
        settleDate:
          moment(settleDate).utc().format("MM/DD/YYYY") === "01/01/1970"
            ? "n/a"
            : moment(settleDate).utc().format("MM/DD/YYYY"),
        actualDate: moment(actualDate).utc().format("MM/DD/YYYY"),
        pendingType,
        effectiveDate: moment(effectiveDate).utc().format("MM/DD/YYYY"),
        draft: draft > 0 ? draft : "",
        principal: accountType === accountTypes.LOAN ? (principal / 100).toFixed(2) : "",
        status,
        accountType,
        // interest: accountType === accountTypes.LOAN ? (interest / 100).toFixed(2) : '',
        interest,
        fee: fee > 0 ? fee : "",
        comment,
        balance: balance,
        extraInfo: extraInfoJson,
      };
    },
  );
  const postedTransactions = _.filter(trans, t => t.status === transactionStatus.POSTED);
  const pendingTransactions = _.filter(
    trans,
    t => t.status === transactionStatus.PENDING,
  );
  return { pendingTransactions, postedTransactions };
};

export const selectNotifications = ({ account: { notifications } }) => {
  const numberOfNotifications =
    !_.isEmpty(notifications) && _.filter(notifications, n => !n.viewed && n).length;
  const copyOfNotifications = !_.isEmpty(notifications) && notifications.slice(0);
  if (copyOfNotifications.length > 1) {
    copyOfNotifications.sort(
      (a, b) => new Date(b.postedDate).getTime() - new Date(a.postedDate).getTime(),
    );
  }

  let cont = 0;
  const notificationsToShow = [];
  !_.isEmpty(copyOfNotifications) &&
    _.map(copyOfNotifications, msg => {
      if (cont <= 2) {
        notificationsToShow.push(msg);
        cont += 1;
      }
      return msg;
    });
  return {
    numberOfNotifications,
    notificationsToShow,
    notifications,
  };
};

export const selectMessages = ({ account: { messages } }) => {
  const numberOfMessages =
    !_.isEmpty(messages) && _.filter(messages, m => !m.viewed && m).length;
  const copyOfMessages = !_.isEmpty(messages) && messages.slice(0);
  if (copyOfMessages.length > 1) {
    copyOfMessages.sort(
      (a, b) => new Date(b.postedDate).getTime() - new Date(a.postedDate).getTime(),
    );
  }

  let cont = 0;
  const messagesToShow = [];
  !_.isEmpty(copyOfMessages) &&
    _.map(copyOfMessages, msg => {
      if (cont <= 2) {
        messagesToShow.push(msg);
        cont += 1;
      }
      return msg;
    });
  return {
    numberOfMessages,
    messagesToShow,
    messages,
  };
};

export const selectAccountIds = ({ account: { shares, loans } }) => {
  const sharesIds =
    shares &&
    _.map(shares, obj => {
      if (obj.isTrusted) {
        return {
          key: `S-${obj.id} (${obj.fullName})`,
          value: JSON.stringify({
            uniqueId: obj.uniqueId,
            type: enums.AccountType.SHARE,
          }),
        };
      }
      return {
        key: `S-${obj.id}`,
        value: JSON.stringify({ uniqueId: obj.uniqueId, type: enums.AccountType.SHARE }),
      };
    });
  const loansIds =
    loans &&
    _.map(loans, obj => {
      if (obj.isTrusted) {
        return {
          key: `L-${obj.id} (${obj.fullName})`,
          value: JSON.stringify({ uniqueId: obj.uniqueId, type: enums.AccountType.LOAN }),
        };
      }
      return {
        key: `L-${obj.id}`,
        value: JSON.stringify({ uniqueId: obj.uniqueId, type: enums.AccountType.LOAN }),
      };
    });
  let accountIds = sharesIds && loansIds && sharesIds.concat(loansIds);
  accountIds = [{ key: "Select Account", value: 0 }, ...accountIds];

  return accountIds;
};
export const selectTransactionTypes = () =>
  transactionTypes && _.map(transactionTypes, (value, key) => ({ key, value }));

export const selectSavedSearches = ({ account: { savedSearches } }) =>
  savedSearches &&
  _.map(savedSearches, ({ id, name, description, filters, displayPreferences }) => ({
    id,
    name,
    description,
    filters,
    displayPreferences,
  }));
