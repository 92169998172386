import styled from "styled-components";
export default styled.div`
  border-radius: 50%;
  padding: ${({ padding }) => padding || "12px"};
  z-index: 0;

  &:hover {
    background: #f1f1f1;
  }
`;
