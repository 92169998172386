import { push, replace } from "react-router-redux";
import * as types from "./constants";
import { AUTH_USER, CHANGE_STEP_TO, FIRST_STEP_AUTH_USER_SUCCESS } from "../Auth/types";
import { get, post, put } from "../../utils/server";
import { doSendConfirmationCode, doVerifyConfirmationCode } from "../Auth/actions";
import enums from "../../utils/enums";
import { sortFormsList } from "../../utils/sort";
import { checkLocation } from "../../utils/helper";
import Cookie from "js-cookie";
import { toast } from "react-toastify";

export const setToken = ({ token = "", iat = "" }) => async (dispatch, getState) => {
  localStorage.setItem("tokenAdmin", token);

  localStorage.setItem("tokenStartDateAdmin", iat);

  localStorage.removeItem("request_update_token");

  dispatch({ type: types.THIRD_STEP_AUTH_ADMIN_SUCCESS });
  dispatch({ type: types.AUTH_ADMIN_USER });
};

export const onError = (payload = "") => async (dispatch, getState) => {
  dispatch({
    type: types.THIRD_STEP_AUTH_ADMIN_FAILURE,
    payload: payload,
  });
};

export const requestAdminPage = () => async dispatch => {
  try {
    dispatch({ type: types.GET_ADMIN_PAGE_REQUEST });
    const { data } = await post("/admin");
    if (data.message) {
      dispatch({
        type: types.GET_ADMIN_PAGE_SUCCESS,
        payload: data.message,
      });
    } else {
      dispatch({
        type: types.GET_ADMIN_PAGE_FAILURE,
        payload: "not allowed",
      });
    }
  } catch (err) {
    if (err.response && err.response !== undefined) {
      dispatch({ type: types.GET_ADMIN_PAGE_FAILURE, payload: "not allowed" });
    } else {
      dispatch({ type: types.GET_ADMIN_PAGE_FAILURE, payload: "not allowed" });
    }
  }
};

export const getForms = ({
  currentLocation,
  searchFriendlyName,
  sort,
  sources,
  status,
}) => async (dispatch, getState) => {
  try {
    const {
      FAVORITE,
      READ,
      UNREAD,
      ARCHIVED_FAVORITE,
      ARCHIVED_UNREAD,
      ARCHIVED_READ,
    } = enums.FinancialInstitutionSecuredFormsCustomerStatus;
    const { WEBSITE, BANKING_APP } = enums.FinancialInstitutionSecuredFormsCustomerSource;

    dispatch({ type: types.GET_ADMIN_FORMS_REQUEST });
    const {
      config: {
        config: { fiFriendlyName },
      },
    } = getState();
    let query = { financialInstitutionFriendlyName: fiFriendlyName };
    let statusQuery = [];
    let sourcesQuery = [WEBSITE, BANKING_APP];

    if (checkLocation(currentLocation, "/admin/panel/forms")) {
      statusQuery = [FAVORITE, READ, UNREAD];

      if (status) {
        statusQuery = status;
      }

      if (sources) {
        sourcesQuery = sources;
      }
    }

    if (checkLocation(currentLocation, "/admin/panel/forms/archived")) {
      statusQuery = [ARCHIVED_FAVORITE, ARCHIVED_UNREAD, ARCHIVED_READ];

      if (status) {
        statusQuery = status;
      }

      if (sources) {
        sourcesQuery = sources;
      }
    }

    if (searchFriendlyName) query = { ...query, searchFriendlyName };

    if (statusQuery) query = { ...query, status: statusQuery };

    if (sourcesQuery) query = { ...query, sources: sourcesQuery };

    const { data } = await get("/financial-institution-secured-form-customer", {
      ...query,
    });

    if (data.success) {
      dispatch({
        type: types.GET_ADMIN_FORMS_SUCCESS,
        payload: sortFormsList(data.data, sort),
      });
    }
  } catch (err) {
    if (err.response && err.response !== undefined) {
      dispatch({ type: types.GET_ADMIN_FORMS_FAILURE, payload: err.response.data });
    } else {
      dispatch({
        type: types.GET_ADMIN_FORMS_FAILURE,
        payload: "error trying to get forms",
      });
    }
  }
};

export const sortForms = typeFilter => async (dispatch, getState) => {
  try {
    const {
      admin: { forms },
    } = getState();
    let newForms = sortFormsList(forms, typeFilter);
    dispatch({ type: types.FILTER_ADMIN_FORMS, payload: [...newForms] });
  } catch (err) {
    dispatch({
      type: types.FILTER_ADMIN_FORMS_FAILURE,
      payload: "error trying to filter forms",
    });
  }
};

export const changeStatusForm = ({
  status,
  id,
  currentLocation,
  searchFriendlyName,
  sort,
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.UPDATE_ADMIN_FORM_STATUS_REQUEST });

    const { data } = await put("/financial-institution-secured-form-customer", {
      status,
      id,
    });
    if (data.success) {
      dispatch({
        type: types.UPDATE_ADMIN_FORM_STATUS_SUCCESS,
        payload: { ...data.data, sort },
      });
      dispatch(getForms({ currentLocation, searchFriendlyName, sort }));
    }
  } catch (err) {
    dispatch({
      type: types.UPDATE_ADMIN_FORM_STATUS_FAILURE,
      payload: "error trying to update status form",
    });
  }
};

export const changeStatusFormMany = ({
  formCustomers,
  sort,
  currentLocation,
  searchFriendlyName,
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.UPDATE_ADMIN_FORM_MANY_STATUS_REQUEST });

    const { data } = await put("/financial-institution-secured-form-customer/many", {
      formCustomers,
    });

    if (data.success) {
      dispatch({ type: types.UPDATE_ADMIN_FORM_MANY_STATUS_SUCCESS });
      dispatch(getForms({ currentLocation, searchFriendlyName, sort }));
    }
  } catch (err) {
    dispatch({
      type: types.UPDATE_ADMIN_FORM_MANY_STATUS_FAILURE,
      payload: "error trying to update many status form",
    });
  }
};

export const validateAdminFirstStep = username => async (dispatch, getState) => {
  try {
    dispatch({ type: types.FIRST_STEP_AUTH_ADMIN_REQUEST });

    const {
      config: {
        config: { fiName },
      },
    } = getState();

    const body = {
      username,
      financialInstitutionName: fiName,
      isAdmin: true,
    };

    //  const { data } = await post("/auth/sign-in/1", body);
    const { data: response } = await post("/auth/sign-in/1", body);

    const { data, success = false } = response;

    if (success) {
      const { id, phrase, username, name } = data;

      dispatch({
        type: types.FIRST_STEP_AUTH_ADMIN_SUCCESS,
        payload: {
          id,
          phrase,
          username,
          name,
        },
      });
    } else {
      dispatch({
        type: types.FIRST_STEP_AUTH_ADMIN_FAILURE,
        payload: data.error.textToUser || "user not allowed",
      });
    }
  } catch (err) {
    if (err.response && err.reponse !== undefined) {
      dispatch({
        type: types.FIRST_STEP_AUTH_ADMIN_FAILURE,
        payload: err?.response?.data?.error?.textToUser || "",
      });
    } else {
      dispatch({
        type: types.FIRST_STEP_AUTH_ADMIN_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

// step 2
export const validateAdminSecondStep = (
  password,
  email,
  callback = async (success = false) => undefined,
) => async (dispatch, getState) => {
  const handlers = {
    replace: (path = "/") => dispatch(replace(path)),
  };

  try {
    dispatch({ type: types.SECOND_STEP_AUTH_ADMIN_REQUEST });

    if (!!password && !!email) {
      const { data, success } = await doSendConfirmationCode(password, email)(
        dispatch,
        getState,
      );

      if (!!success) {
        dispatch({ type: types.SECOND_STEP_AUTH_ADMIN_SUCCESS });
      } else {
        dispatch({
          type: types.SECOND_STEP_AUTH_ADMIN_FAILURE,
          payload: data.message || "user not allowed",
        });
      }

      await callback({
        data,
        success,
        handlers,
      });
    } else {
      const payload = `${!password ? "Password" : "Email"} is required`;

      dispatch({ type: types.SECOND_STEP_AUTH_ADMIN_FAILURE, payload });

      await callback({
        data: payload,
        success: false,
        handlers,
      });
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch({
        type: types.SECOND_STEP_AUTH_ADMIN_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: types.SECOND_STEP_AUTH_ADMIN_FAILURE,
        payload: "error trying to log into your account",
      });
    }

    await callback({
      error,
      success: false,
      handlers,
    });
  }
};

export const validateAdminThirdStep = (
  mfaAnswer = "",
  code = "",
  username = "",
) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.THIRD_STEP_AUTH_ADMIN_REQUEST });

    const {
      config: {
        config: { fiName },
      },
    } = getState();

    if (!!mfaAnswer) {
      const body = {
        username: username,
        financialInstitutionName: fiName,
      };

      const { data } = await post("/auth/sign-in/3", body);

      if (data.success) {
        await setToken({ token: data.data.token, iat: data.data.iat })(
          dispatch,
          getState,
        );

        dispatch(push("/admin/panel/"));
      } else {
        await onError(data.message || "user not allowed");
      }
    } else {
      const {
        data: verifyConfirmationData,
        success: verifyConfirmationSuccess = false,
      } = await doVerifyConfirmationCode(code, username)(dispatch, getState);

      if (verifyConfirmationSuccess) {
        await setToken({
          token: verifyConfirmationData.token,
          iat: verifyConfirmationData.iat,
        })(dispatch, getState);

        dispatch(push("/admin/panel/"));
      } else {
        await onError(verifyConfirmationData)(dispatch, getState);
      }
    }
  } catch (error) {
    if (!!error && !!error.response && !!error.response.data) {
      const { success = false, message = "" } = error.response.data;

      if (!!message) toast.error(message);
    }

    if (error?.response?.data?.message) {
      dispatch({
        type: types.THIRD_STEP_AUTH_ADMIN_FAILURE,
        payload: error.response.data.message,
      });
    } else {
      dispatch({
        type: types.THIRD_STEP_AUTH_ADMIN_FAILURE,
        payload: "error trying to log into your account",
      });
    }
  }
};

export const resetMFA = username => async (dispatch, getState) => {
  try {
    dispatch({ type: types.RESET_MFA_REQUEST });
    const {
      admin: { userId: adminId },
    } = getState();
    const body = {
      username,
      adminId,
    };
    const { data } = await post("/admin/unblock", body);

    if (data.success) {
      dispatch({ type: types.RESET_MFA_SUCCESS });
      dispatch(showMFAResetModal(false));
      dispatch(showMFAResetSuccessModal(true));
    } else {
      dispatch({
        type: types.RESET_MFA_FAILURE,
        payload: data.message,
      });
      dispatch(showMFAResetModal(false));
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.RESET_MFA_FAILURE,
        payload: err.response.data.message || "",
      });
      dispatch(showMFAResetModal(false));
    } else {
      dispatch({
        type: types.RESET_MFA_FAILURE,
        payload: "error trying to reset user's account",
      });
    }
  }
};

export const loginAsMemberEview = username => async (dispatch, getState) => {
  try {
    dispatch({ type: types.LOGIN_AS_MEMBER_REQUEST });
    const {
      admin: { userId: idOLBUserAdmin },
    } = getState();
    const body = {
      username,
      idOLBUserAdmin,
    };
    const { data } = await post("/admin/membereview", body);

    if (data.success) {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("tokenStartDate", data.data.iat);
      dispatch({ type: types.LOGIN_AS_MEMBER_SUCCESS, payload: data.data });
      dispatch({ type: AUTH_USER });
      dispatch({
        type: FIRST_STEP_AUTH_USER_SUCCESS,
        payload: {
          id: data.data.idOLBUser,
          username: data.data.username,
          name: data.data.name,
        },
      });
      window.open("/dashboard/accounts");
    } else {
      dispatch({
        type: types.LOGIN_AS_MEMBER_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.LOGIN_AS_MEMBER_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.LOGIN_AS_MEMBER_FAILURE,
        payload: "error trying to login as member",
      });
    }
  }
};

export const getAllCustomers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_ALL_CUSTOMERS_REQUEST });
    const {
      admin: { userId: idOLBUser },
    } = getState();
    const body = {
      idOLBUser,
      // limit,
      // offset,
    };
    const { data } = await post("/admin/getallcustomers", body);
    if (data.success) {
      dispatch({ type: types.GET_ALL_CUSTOMERS_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_ALL_CUSTOMERS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_ALL_CUSTOMERS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_ALL_CUSTOMERS_FAILURE,
        payload: "error trying to get all customers",
      });
    }
  }
};

export const createCustomerMessage = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CREATE_CUSTOMER_MESSAGE_REQUEST });
    const {
      admin: { userId: idOLBUser },
    } = getState();
    const body = {
      idOLBUser,
      ...payload,
    };
    const { data } = await post("/admin/createcustomersmsg", body);
    if (data.success) {
      dispatch({ type: types.CREATE_CUSTOMER_MESSAGE_SUCCESS });
      dispatch(showCreateMessageSuccessModal(true));
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_MESSAGE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CREATE_CUSTOMER_MESSAGE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_MESSAGE_FAILURE,
        payload: "error trying to create customer message",
      });
    }
  }
};

export const createCustomerDirectMessage = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CREATE_CUSTOMER_DIRECT_MESSAGE_REQUEST });
    const {
      admin: { userId: idOLBUserAdmin },
    } = getState();
    const body = {
      idOLBUserAdmin,
      ...payload,
    };
    const { data } = await post("/admin/createdirectmsg", body);
    if (data.success) {
      dispatch({ type: types.CREATE_CUSTOMER_DIRECT_MESSAGE_SUCCESS });
      dispatch(showCreateDirectMessageSuccessModal(true));
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_DIRECT_MESSAGE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CREATE_CUSTOMER_DIRECT_MESSAGE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_DIRECT_MESSAGE_FAILURE,
        payload: "error trying to create customer message",
      });
    }
  }
};

export const createCustomerNotification = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CREATE_CUSTOMER_NOTIFICATION_REQUEST });
    const {
      admin: { userId: idOLBUserAdmin },
    } = getState();
    const body = {
      idOLBUserAdmin,
      ...payload,
    };
    const { data } = await post("/admin/createnotification", body);
    if (data.success) {
      dispatch({ type: types.CREATE_CUSTOMER_NOTIFICATION_SUCCESS });
      dispatch(showCreateNotificationSuccessModal(true));
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_NOTIFICATION_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CREATE_CUSTOMER_NOTIFICATION_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_NOTIFICATION_FAILURE,
        payload: "error trying to create customer notification",
      });
    }
  }
};

export const createCustomerDirectNotification = payload => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_REQUEST });
    const {
      admin: { userId: idOLBUserAdmin },
    } = getState();
    const body = {
      idOLBUserAdmin,
      ...payload,
    };
    const { data } = await post("/admin/createdirectnot", body);
    if (data.success) {
      dispatch({ type: types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_SUCCESS });
      dispatch(showCreateDirectNotificationSuccessModal(true));
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_FAILURE,
        payload: "error trying to create customer notification",
      });
    }
  }
};

export const showMFAResetModal = show => dispatch =>
  dispatch({ type: types.SHOW_RESET_MFA_MODAL, payload: show });

export const showMFAResetSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_RESET_MFA_SUCCESS_MODAL, payload: show });

export const showCreateMessageSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_CREATE_MESSAGE_SUCCESS_MODAL, payload: show });

export const showCreateDirectMessageSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_CREATE_DIRECT_MESSAGE_SUCCESS_MODAL, payload: show });

export const showCreateNotificationSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_CREATE_NOTIFICATION_SUCCESS_MODAL, payload: show });

export const showCreateDirectNotificationSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_CREATE_DIRECT_NOTIFICATION_SUCCESS_MODAL, payload: show });

export const handleAuthNavigation = step => dispatch => {
  dispatch({ type: CHANGE_STEP_TO, payload: step });
};

// ------- Admin Members -------

export const getAllMembers = ({ filterStatus = null, search = null }) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: types.GET_ALL_MEMBERS_REQUEST });

    const {
      config: { financialInstitutionId },
    } = getState();

    const { data } = await get("/admin/members", {
      financialInstitutionId,
      search,
      filterStatus,
    });

    if (data.success) {
      dispatch({ type: types.GET_ALL_MEMBERS_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_ALL_MEMBERS_FAILURE,
        payload: data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: types.GET_ALL_MEMBERS_FAILURE,
      payload: "error trying to get all members",
    });
  }
};

export const changeStatusMember = ({
  idOLBUser,
  status,
  filterStatus = null,
  search = null,
}) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CHANGE_STATUS_MEMBER_REQUEST });

    const { data } = await put("/admin/member/change-status", { idOLBUser, status });

    if (data.success) {
      dispatch({ type: types.CHANGE_STATUS_MEMBER_SUCCESS, payload: data.data });
      dispatch(
        getAllMembers({
          filterStatus,
          search,
        }),
      );
    } else {
      dispatch({
        type: types.CHANGE_STATUS_MEMBER_FAILURE,
        payload: data.data,
      });
    }
  } catch (err) {
    dispatch({
      type: types.CHANGE_STATUS_MEMBER_FAILURE,
      payload: "error trying to cahnge status member",
    });
  }
};
