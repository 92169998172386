import { Component } from "react";
import PropTypes from "prop-types";

class Toggle extends Component {
  static defaultProps = { onToggle: () => {} };
  static propTypes = {
    onToggle: PropTypes.func,
    render: PropTypes.any,
  };
  state = {
    on: false,
  };

  toggle = () =>
    this.setState(
      ({ on }) => ({ on: !on }),
      () => this.props.onToggle(this.state.on),
    );
  render() {
    return this.props.render({
      on: this.state.on,
      toggle: this.toggle,
    });
  }
}

export default Toggle;
