import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { handleSubmitSSOForm } from "../../../utils/helper";
import { QuickLinks, QLink } from "../../../components/commons/QuickLinks";
import { Button, Loader, Separator, PageTitle } from "../../../components/commons";
import _ from "lodash";
import { getSsoList, getSsoRedirectUrl } from "../SSO/actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

class FormsPage extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    getSsoList: PropTypes.func,
    getSsoRedirectUrl: PropTypes.func,
    formsSsoList: PropTypes.array,
    redirectUrl: PropTypes.string,
    isPost: PropTypes.bool,
    formParams: PropTypes.array,
  };
  componentDidMount() {
    this.props.getSsoList();
  }
  async formClicked(e, sso) {
    e.preventDefault();
    await this.props.getSsoRedirectUrl(sso.id);
    if (this.props.isPost && this.props.formParams) {
      handleSubmitSSOForm(this.props.redirectUrl, this.props.formParams, "post");
    } else {
      handleSubmitSSOForm(this.props.redirectUrl, this.props.formParams, "get");
    }
  }

  render() {
    return (
      <DashboardScreens>
        <div>
          <Loader isLoading={this.props.isFetching} />
          <PageTitle>Forms</PageTitle>
          <Separator style={{ marginBottom: "3rem" }} />
          <QuickLinks>
            {this.props.formsSsoList &&
              _.map(this.props.formsSsoList, sso => (
                <QLink key={`${sso.id}-${sso.name}`}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "8px",
                      alignItems: "center",
                    }}
                  >
                    <p>{sso.name}</p>
                    <small style={{ fontWeight: "300", marginBottom: 10 }}>
                      {sso.description}
                    </small>
                    <Button
                      onClick={e => this.formClicked(e, sso)}
                      style={{ margin: "20x auto", maxWidth: 200 }}
                      primary
                    >
                      {sso.buttonText}
                    </Button>
                  </div>
                </QLink>
              ))}
          </QuickLinks>
        </div>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = ({
  sso: { isFetching, formsSsoList, redirectUrl, isPost, formParams },
}) => ({
  isFetching,
  formsSsoList,
  redirectUrl,
  isPost,
  formParams,
});

export default connect(mapStateToProps, { getSsoList, getSsoRedirectUrl })(FormsPage);
