import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Popup, PopupRow, Button, Separator } from "../../../components/commons";
import _ from "lodash";

const PoupNotifications = ({
  popup,
  handleClickOutside,
  notifications,
  push,
  isAdmin,
}) => {
  return (
    <Popup
      id="notificationsPopup"
      role="listbox"
      aria-labelledby="notificationsPopup"
      aria-label="notifications dialog"
      aria-expanded={popup.notifications.isOpen}
      aria-hidden={popup.notifications.ariaHidden}
      isOpen={popup.notifications.isOpen}
      onClose={handleClickOutside}
      sm
    >
      {notifications.notificationsToShow &&
        _.map(
          notifications.notificationsToShow,
          ({ id, viewed, notification, postedDate }) => (
            <div key={id + postedDate} style={{ background: !viewed && "#FCFCFC" }}>
              <PopupRow onClick={() => push("/dashboard/notifications")}>
                <span className="date">
                  {moment(postedDate).utc().format("MM/DD/YYYY")}
                </span>
                <span>{notification}</span>
              </PopupRow>
              <Separator />
            </div>
          ),
        )}
      <div className="notificationsFooter">
        <Button
          onClick={() =>
            push(isAdmin ? "/admin/panel/notifications" : "/dashboard/notifications")
          }
        >
          View All
        </Button>
      </div>
    </Popup>
  );
};

PoupNotifications.prototype = {
  popup: PropTypes.object,
  handleClickOutside: PropTypes.func,
  notifications: PropTypes.object,
  push: PropTypes.func,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { push })(PoupNotifications);
