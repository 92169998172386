import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormFirstStep from "./FormFirstStep";

const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});

class FirstStepAuth extends React.Component {
  static defaultProps = {
    onSubmit: () => {},
    onChangeUsername: (username = "") => undefined,
    onChangePassword: (password = "") => undefined,
  };
  static propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    onChangeUsername: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    rememberDevice: PropTypes.bool.isRequired,
    validateFirstStep: PropTypes.func.isRequired,
    onChangeRememberDevice: PropTypes.func.isRequired,
    validateSecondStep: PropTypes.func.isRequired,
    phrase: PropTypes.string,
    onError: PropTypes.func,
    isSucessUserName: PropTypes.bool,
    classes: PropTypes.object,
    stepError: PropTypes.string,
    isFetching: PropTypes.bool,
    config: PropTypes.object,
  };

  render() {
    return <FormFirstStep {...this.props} />;
  }
}

export default withStyles(loaderStyles)(FirstStepAuth);
