import _ from "lodash";
import * as types from "./constants";
const initialState = {
  error: null,
  isFetching: false,
  goals: [],
  isSuccessModalOpen: false,
  isCreateGoalModalOpen: false,
  isUpdateGoalModalOpen: false,
  successMessage: null,
};

export default function goalsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.GET_CUSTOMER_GOALS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CUSTOMER_GOALS_SUCCESS:
      return {
        ...state,
        goals: payload,
        isFetching: false,
      };
    case types.GET_CUSTOMER_GOALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.CREATE_CUSTOMER_GOAL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.CREATE_CUSTOMER_GOAL_SUCCESS:
      return {
        ...state,
        goals: [...state.goals, payload],
        isFetching: false,
      };
    case types.CREATE_CUSTOMER_GOAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.UPDATE_CUSTOMER_GOAL_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.UPDATE_CUSTOMER_GOAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        goals:
          state.goals &&
          _.map(state.goals, goal => {
            if (goal.id === payload.id) {
              return payload;
            }
            return goal;
          }),
      };
    case types.UPDATE_CUSTOMER_GOAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.REMOVE_CUSTOMER_GOALS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.REMOVE_CUSTOMER_GOALS_SUCCESS:
      return {
        ...state,
        goals: _.filter(state.goals, g => g.id !== payload),
        isFetching: false,
      };
    case types.REMOVE_CUSTOMER_GOALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.SHOW_CREATE_GOAL_SUCCESS_MODAL:
      return {
        ...state,
        isSuccessModalOpen: payload.show,
        successMessage: payload.msg,
      };
    case types.SHOW_CREATE_GOAL_MODAL:
      return {
        ...state,
        isCreateGoalModalOpen: payload,
        error: null,
      };
    case types.DISPATCH_VALIDATE_AMOUNT:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.SHOW_ERROR_AMOUNT_MODAL:
      return {
        ...state,
        isErrorAmountModalOpen: payload,
      };
    case types.SHOW_UPDATE_GOAL_MODAL:
      return {
        ...state,
        isUpdateGoalModalOpen: payload,
        error: null,
      };
    case types.UPDATE_GOAL_VALUE:
      return {
        ...state,
        goals:
          state.goals &&
          _.map(state.goals, goal => {
            if (goal.id === payload.id) {
              return {
                ...goal,
                [payload.name]: payload.value,
              };
            }
            return goal;
          }),
      };
    default:
      return state;
  }
}
