import styled, { keyframes } from "styled-components";

const GoalBox = styled.div`
  display: block;
  background-color: #fcfcfc;
  border: 1px solid #ebeff3;
  position: relative;
  border-radius: 10px;
  text-align: center;
  height: 100%;
  transition: all 0.5s ease;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  padding: 20px 35px 0 20px;
  align-items: center;
  position: relative;

  button {
    display: none;
  }

  div.flex {
    display: flex;
    width: 30%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &.bigFlex {
      width: 40%;
      @media (max-width: 769px) {
        margin-top: 2rem;
        width: 100%;
        text-align: left;
      }
    }
    &.smallFlex {
      @media (max-width: 769px) {
        width: 100% !important;
      }
      height: 45px;
      padding-right: 1%;
      width: 29%;
      > .dropdown > .dropdown-content {
        top: 21px;
        a {
          text-align: initial;
          font-size: 16px;
          display: flex;
          align-items: end;
          &:nth-child(2) {
            color: #d23241;
          }
          input {
            width: 22px !important;
            height: 22px !important;
            margin-right: 15px;
          }
        }
      }
      > .dropdown {
        position: absolute;
        right: 0.6%;
        @media (max-width: 769px) {
          top: 6%;
          right: 3% !important;
        }
        > a {
          & > :hover {
            filter: grayscale(100%) brightness(62%) sepia(87%) hue-rotate(50deg)
              saturate(1000%) contrast(2);
            input {
              filter: grayscale(0) brightness(0) sepia(0) hue-rotate(0) saturate(0)
                contrast(0);
            }
          }
        }
      }
      @media (max-width: 769px) {
        align-items: flex-start;
        height: auto;
      }
    }
    p {
      color: #828991;
      font-size: 15px;
      font-weight: normal;
      width: 100%;
      text-align: left;
      margin: 0;
      line-height: 1.2;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;

      &.font-s {
        font-size: 12px;
        text-align: right;
        @media (max-width: 769px) {
          text-align: left;
        }
      }
      &.description {
        color: #292b36;
        margin-top: 5px;
      }
      &.textT {
        text-transform: lowercase;
        &:first-letter {
          text-transform: capitalize;
        }
      }
      &.mediumSize {
        width: 50%;
        text-align: right;
        @media (max-width: 769px) {
          width: 50%;
          text-align: right;
        }
      }
      &.desktop {
        display: block;
        @media (max-width: 769px) {
          display: none;
        }
      }
      &.mobile {
        display: none;
        &.bfont {
          color: #292b36;
          margin-bottom: 30px;
          margin-top: 5px;
        }
        @media (max-width: 769px) {
          display: block;
          font-size: 12px;
          margin-top: 15px;
        }
      }
      @media (max-width: 769px) {
        font-size: 15px;
        width: 100%;
      }
    }
    @media (max-width: 769px) {
      width: 100%;
    }
  }
`;
const GoalType = styled.h6`
  color: ${({ primary, info }) => (primary ? "#05CE86" : info ? "#66CBFF" : "#eee")};
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-size: 0.8em;
  text-align: center;
`;

const AddButton = styled.button`
  background-color: #3fc78e;
  height: 32px;
  width: 32px;
  box-shadow: 0 6px 13px 0 rgba(63, 199, 142, 0.16);
  border-radius: 50%;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:active,
  &:focus {
    background: #35efa1;
    outline: none;
  }
  input {
    filter: invert(100%) brightness(300%) contrast(100%);
    opacity: 1 !important;
    width: 23px;
    height: 23px;
  }
`;

const GoalTitle = styled.h1`
  font-weight: 700;
  color: #141f2d;
  text-transform: capitalize;
  font-size: 15px;
  width: 100%;
  text-align: left;
  margin: 0;
`;
const GoalText = styled.p`
  font-size: 0.875rem;
  margin-top: 0;
`;

const animationProgress = keyframes`
  0% {width: 0%}
  100% {width: ${({ percentage }) => `${percentage}%` || "100%"}};
`;

const GoalProgress = styled.div`
  height: 7px;
  position: relative;
  background: #555;
  border-radius: 25px;
  background: #ededf1;
  width: 100%;
  margin: 5px 0;
  span.contentProgress {
    display: block;
    height: 100%;
    background-color: #4cd994;
    position: relative;
    overflow: hidden;
    animation: ${animationProgress} 2s 0s both;
    border-radius: 25px;
    max-width: 100%;
  }
`;
const GoalTip = styled.p`
  color: ${({ primary, info }) => (primary ? "#05CE86" : info ? "#66CBFF" : "#eee")};
  color: #05ce86;
  font-size: 0.875rem;
  margin: 0 0 1.5rem 0;
`;
const GoalControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoalPercentage = styled.div`
  width: 240px;
  height: ${({ percentage }) => `${240 / percentage}px` || "0px"};
  position: absolute;
  bottom: 0;
  background: ${({ primary, info }) => (primary ? "#05CE86" : info ? "#66CBFF" : "#eee")};
`;
const GoalCurrentStage = styled.div`
  color: white;
  position: absolute;
  width: 100%;
  font-size: 1rem;
  bottom: 15px;
  left: 0;
  text-align: center;
  font-weight: 500;
`;
const Goal = styled.div`
  font-size: 22px;
  margin-top: 5px;
  font-weight: 700;
  text-align: left;
  color: #292b36;
`;
const GoalsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  @media (min-width: 320px and max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
`;

export {
  GoalBox,
  GoalsContainer,
  GoalControls,
  GoalCurrentStage,
  GoalProgress,
  GoalTip,
  GoalType,
  Goal,
  GoalPercentage,
  GoalTitle,
  AddButton,
  GoalText,
};
