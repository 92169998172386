import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "../../components/commons/Modals";
import { P } from "../../components/commons";

class ReadMore extends Component {
  static propTypes = {
    children: PropTypes.string.isRequired,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };
  state = {
    isModalOpen: false,
    msg: "",
  };
  handleOpenMessage(msg) {
    this.setState({ isModalOpen: true, msg });
  }
  renderModal() {
    return (
      <ConfirmationModal
        isOpen={this.state.isModalOpen}
        onClose={() => this.setState({ isModalOpen: false })}
        headingText="Message"
        confirmationText={<P>{this.state.msg}</P>}
        cancelButtonText="Close"
        aria-hidden={this.state.isModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      ></ConfirmationModal>
    );
  }

  renderMessage() {
    const { children, maxLength } = this.props;
    if (children && maxLength) {
      if (children.length > maxLength) {
        const message = children.substr(0, maxLength);
        const link = (
          <span
            onClick={() => this.handleOpenMessage(children)}
            style={{
              color: "rgb(102, 203, 255)",
              fontWeight: "400",
              cursor: "pointer",
              fontSize: 12,
            }}
          >
            ...read more
          </span>
        );
        return (
          <div>
            {message} {link}
          </div>
        );
      }
      return children;
    }
    return "";
  }
  render() {
    return (
      <div>
        {this.renderModal()}
        {this.renderMessage()}
      </div>
    );
  }
}

export default ReadMore;
