import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../components/commons";
import { withStyles } from "@material-ui/core/styles";
import TextField from "../../components/commons/TextField";
import { InputAdornment, CircularProgress } from "@material-ui/core";
import { Unlock, Eye, EyeOff } from "../../components/commons/icons";
import {
  StepBox,
  StepBody,
  StepTitle,
  StepBackButton,
  StepControls,
  StepForm,
  CurrentStep,
  StepText,
} from "../Auth/steps/Steps";
import styles from "../Auth/steps/step.module.css";

const loaderStyles = theme => ({
  progress: {
    margin: theme.spacing(1) * 2,
  },
});

class AdminSecondStepAuth extends React.Component {
  static defaultProps = {
    handleChangeStep: () => {},
  };
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    stepError: PropTypes.string,
    classes: PropTypes.object,
    phrase: PropTypes.string,
    onError: PropTypes.func,
    handleChangeStep: PropTypes.func,
  };
  state = {
    password: "",
    error: "",
    showPassword: false,
    areCapsLocked: false,
  };

  handleOnSubmit = () => {
    if (this.state.password !== null && this.state.password !== "") {
      this.props.onSubmit(this.state.password);
    } else {
      this.setState({
        error: "please fill the form",
      });
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.handleOnSubmit();
    }
  };

  render() {
    const { stepError, isFetching, phrase, classes } = this.props;
    const renderEyes = this.state.showPassword ? (
      <EyeOff
        alt="show password"
        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
        style={{ position: "relative", marginRight: 10 }}
      />
    ) : (
      <Eye
        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
        style={{ position: "relative", marginRight: 10 }}
      />
    );

    return (
      <StepBox style={{ background: "white" }}>
        <StepBody>
          <CurrentStep step={"Step 2 of 3"} />
          <StepTitle>Personal Phrase Confirmation & Login</StepTitle>
          <StepText>
            Your personal phrase is: <br />
            <strong className={styles.phrase}>{phrase}</strong>
            <br />
            if this is correct, please enter your password.
          </StepText>
          <StepForm>
            <TextField
              onChange={event => this.setState({ password: event.target.value })}
              onKeyDown={e => {
                const areCapsLocked =
                  e.getModifierState && e.getModifierState("CapsLock");
                this.setState({ areCapsLocked });
              }}
              onKeyUp={e => {
                const areCapsLocked =
                  e.getModifierState && e.getModifierState("CapsLock");
                this.setState({ areCapsLocked });
              }}
              value={this.state.password}
              autoFocus
              onKeyPress={this.handleKeyPress}
              type={this.state.showPassword ? "text" : "password"}
              name="password"
              id="password"
              error={stepError || this.state.error}
              helperText={
                stepError ||
                this.state.error ||
                (this.state.areCapsLocked ? "caps lock" : null)
              }
              label={"Password"}
              startAdornment={
                <InputAdornment position="start">
                  <Unlock />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {isFetching ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: "#05CE86", width: 30, height: 30 }}
                    />
                  ) : (
                    renderEyes
                  )}
                </InputAdornment>
              }
            />
          </StepForm>
          <StepControls>
            <Button
              disabled={isFetching}
              primary={!isFetching}
              onClick={this.handleOnSubmit}
            >
              Login
            </Button>
          </StepControls>
        </StepBody>
        <StepBackButton onClick={() => this.props.handleChangeStep("1")} />
      </StepBox>
    );
  }
}

export default withStyles(loaderStyles)(AdminSecondStepAuth);
