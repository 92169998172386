import { LOCATION_CHANGE } from "react-router-redux";
import { combineReducers } from "redux";
import {
  SHOW_DASHBOARD_MENU,
  SHOW_MOBILE_MENU,
  SHOW_MOBILE_USER_MENU,
  SHOW_MOBILE_LOGOUT,
} from "./containers/Dashboard/constants";
import fiReducer from "./containers/App/reducer";
import authReducer from "./containers/Auth/reducer";
import adminReducer from "./containers/Admin/reducer";
import accountReducer from "./containers/Dashboard/Accounts/reducer";
import fundsTransferReducer from "./containers/Dashboard/FundsTransfer/reducer";
import ePaymentReducer from "./containers/Dashboard/EPayments/reducer";
import textBankingReducer from "./containers/Dashboard/TextBanking/reducer";
import loanReducer from "./containers/Dashboard/Loans/reducer";
import FAQReducer from "./containers/Dashboard/Help/reducer";
import linksReducer from "./containers/Dashboard/QuickLinks/reducer";
import userReducer from "./containers/Dashboard/user/reducer";
import ssoReducer from "./containers/Dashboard/SSO/reducer";
import goalsReducer from "./containers/Dashboard/Goals/reducer";

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = {
  location: null,
};

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return {
        ...state,
        location: action.payload,
      };
    default:
      return state;
  }
}

const UIInitialState = {
  showMobileMenu: false,
  showMobileUserMenu: false,
  showLogoutModal: false,
  showDashboardMenu: false,
  isFetching: false,
  error: null,
};

function uiReducer(state = UIInitialState, action) {
  switch (action.type) {
    case SHOW_MOBILE_MENU:
      return { ...state, showMobileMenu: action.payload };
    case SHOW_MOBILE_LOGOUT:
      if (action.payload) {
        return { ...state, showLogoutModal: action.payload };
      }
      return { ...state, showLogoutModal: !state.showLogoutModal };
    case SHOW_MOBILE_USER_MENU:
      if (action.payload) {
        return { ...state, showMobileUserMenu: action.payload };
      }
      return { ...state, showMobileUserMenu: !state.showMobileUserMenu };
    case SHOW_DASHBOARD_MENU:
      if (action.payload) {
        return { ...state, showDashboardMenu: action.payload };
      }
      return { ...state, showDashboardMenu: !state.showDashboardMenu };
    case LOCATION_CHANGE:
      return { ...state, showMobileMenu: false, showMobileUserMenu: false };
    default:
      return state;
  }
}
const AppReducers = combineReducers({
  config: fiReducer,
  route: routeReducer,
  user: userReducer,
  auth: authReducer,
  admin: adminReducer,
  epayment: ePaymentReducer,
  account: accountReducer,
  fundsTransfer: fundsTransferReducer,
  textBanking: textBankingReducer,
  loan: loanReducer,
  ui: uiReducer,
  help: FAQReducer,
  links: linksReducer,
  sso: ssoReducer,
  goals: goalsReducer,
});

export default (state, action) => {
  if (action.type === "unauth_user") {
    if (localStorage.getItem("persist:root")) {
      const root = JSON.parse(localStorage.getItem("persist:root"));
      localStorage.setItem(
        "persist:root",
        JSON.stringify({
          ...root,
          account: "{}",
          auth: "{}",
          epayment: "{}",
          loan: "{}",
        }),
      );
    }
    state = undefined;
  }
  return AppReducers(state, action);
};
