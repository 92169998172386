import * as types from "./constants";
import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import { post } from "../../../utils/server";

export const getFAQs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_FAQ_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post("/fi/gethelpsection", { idOLBUser });
    if (data.success) {
      dispatch({
        type: types.GET_FAQ_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_FAQ_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    dispatch({
      type: types.GET_FAQ_FAILURE,
      payload: "error trying to get FAQ",
    });
  }
};
