import React from "react";
import PropTypes from "prop-types";
import { Button, Space } from "../../../components/commons";
import {
  StepBox,
  StepBackButton,
  StepBody,
  StepTitle,
  StepControls,
  StepLogo,
  StepText,
} from "./Steps";

function ForgotPassword({ handleChangeStep, onSubmit, isFetching, config }) {
  return (
    <StepBox>
      <StepLogo src={config && config.fiLogo} />
      <StepBody>
        <StepTitle>Forgot your Password?</StepTitle>
        <StepText>
          Select which contact details show we use to reset your password.
        </StepText>
        <StepControls>
          <Button primary={!isFetching} disabled={isFetching} onClick={() => onSubmit()}>
            <img src="/icons/white-smartphone.svg" alt="fi-logo" />
            Via SMS
          </Button>
          <Space />
          <Button
            primary={!isFetching}
            disabled={isFetching}
            onClick={() => handleChangeStep("via_email")}
          >
            <img src="/icons/white-email.svg" alt="fi-logo" />
            Via email
          </Button>
        </StepControls>
      </StepBody>
      <StepBackButton onClick={() => handleChangeStep("2")} />
    </StepBox>
  );
}

ForgotPassword.propTypes = {
  handleChangeStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  // stepError: PropTypes.string,
  isFetching: PropTypes.bool,
};

ForgotPassword.defaultProps = {
  handleChangeStep: () => {},
  onSubmit: () => {},
};

export default ForgotPassword;
