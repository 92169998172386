import React from "react";

import MediaQuery from "react-responsive";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import SideNavDesktop from "../../../components/layout/SideNav/SideNavDesktop";
import SideNavMobile from "../../../components/layout/SideNav/SideNavMobile";
import { Dashboard, DashboardBody, DashboardContainer } from "./Dashboard";
import { DashboardRoutes, DashboardSubNav } from "./";
import DashboardHeader from "../../../components/layout/Header";

import { logout } from "../../Auth/actions";
import * as actions from "../actions";

// this class only cares about UI interactions
class DashboardInstance extends React.Component {
  static propTypes = {
    // header
    isSidenavOpen: PropTypes.bool,
    isLogoutModalOpen: PropTypes.bool,
    isMobileUserMenuOpen: PropTypes.bool,
    isMobileMenuOpen: PropTypes.bool,
    showMobileMenu: PropTypes.func,
    showMobileUserMenu: PropTypes.func,
    // body
    location: PropTypes.object,
    match: PropTypes.object,
    // sidenav
    showDashboardMenu: PropTypes.func,
    // auth
    logout: PropTypes.func,
    // config
    config: PropTypes.object,
  };

  getSubMenu = (currentLocation, menuConfig) => {
    let subMenu = null;

    menuConfig.forEach(item => {
      if (item.subMenu !== undefined) {
        if (item.subMenu.filter(item => item.path === currentLocation).length > 0) {
          subMenu = item;
        }
      }
    });

    return subMenu;
  };

  handleOpenMobileMenu = show => {
    if (this.props.isMobileUserMenuOpen || this.props.isLogoutModalOpen) {
      return null;
    }
    return this.props.showMobileMenu(show);
  };
  handleOpenMobileUserMenu = show => {
    if (this.props.isMobileMenuOpen || this.props.isLogoutModalOpen) {
      return null;
    }
    return this.props.showMobileUserMenu(show);
  };
  handleLogout = () => this.props.logout();
  handleOpenSidenav = show => this.props.showDashboardMenu(show);
  render() {
    const subMenu = this.getSubMenu(
      window.location.pathname,
      this.props.config.menuConfig,
    );

    return (
      <div>
        <MediaQuery query="(max-width: 767px)">
          <>
            <SideNavMobile
              menuConfig={this.props.config.menuConfig}
              isMobileMenuOpen={this.props.isMobileMenuOpen}
              onLogout={this.handleLogout}
            />
          </>
        </MediaQuery>
        <Dashboard>
          <DashboardContainer>
            <MediaQuery query="(min-width: 768px)">
              <SideNavDesktop
                onOpen={this.handleOpenSidenav}
                isSidenavOpen={this.props.isSidenavOpen}
                location={this.props.location}
                config={this.props.config}
              />
            </MediaQuery>

            <MediaQuery query="(min-width: 768px)">
              {subMenu && (
                <DashboardSubNav
                  location={this.props.location}
                  config={this.props.config}
                  subMenu={subMenu.subMenu}
                  title={subMenu.displayName}
                />
              )}
            </MediaQuery>

            <DashboardBody
              isLogoutModalOpen={this.props.isLogoutModalOpen}
              isSidenavOpen={this.props.isSidenavOpen}
              isMobileMenuOpen={this.props.isMobileMenuOpen}
              isMobileUserMenuOpen={this.props.isMobileUserMenuOpen}
            >
              <DashboardHeader
                onOpenMobileMenu={this.handleOpenMobileMenu}
                onOpenMobileUserMenu={this.handleOpenMobileUserMenu}
                onOpenLogoutModal={this.handleOpenLogoutModal}
                onLogout={this.handleLogout}
                name={this.props.name}
                isSidenavOpen={this.props.isSidenavOpen}
              />
              {subMenu && (
                <MediaQuery query="(max-width: 767px)">
                  <DashboardSubNav
                    location={this.props.location}
                    config={this.props.config}
                    subMenu={subMenu.subMenu}
                    title={subMenu.displayName}
                  />
                </MediaQuery>
              )}
              <DashboardRoutes match={this.props.match} />
            </DashboardBody>
          </DashboardContainer>
        </Dashboard>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard,
  isMobileMenuOpen: state.ui.showMobileMenu,
  isMobileUserMenuOpen: state.ui.showMobileUserMenu,
  isLogoutModalOpen: state.ui.showLogoutModal,
  authenticated: state.auth.authenticated,
  isSidenavOpen: state.ui.showDashboardMenu,
  name: state.auth.name,
  config: state.config.config,
});

export default connect(mapStateToProps, { ...actions, logout })(DashboardInstance);
