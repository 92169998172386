import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import * as types from "./constants";
import { post } from "../../../utils/server";

export const getQuickLinks = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_QUICKLINKS_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post("/fi/getquicklinks", { userId });
    if (data.success) {
      dispatch({
        type: types.GET_QUICKLINKS_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_QUICKLINKS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    dispatch({
      type: types.GET_QUICKLINKS_FAILURE,
      payload: "error trying to get quick links",
    });
  }
};

export const getExternalSiteMessage = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_EXTERNAL_SITE_MESSAGE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post("/fi/getexternalsitemsg", { userId });
    if (data.success) {
      dispatch({
        type: types.GET_EXTERNAL_SITE_MESSAGE_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_EXTERNAL_SITE_MESSAGE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err.response) {
      dispatch({
        type: types.GET_EXTERNAL_SITE_MESSAGE_FAILURE,
        payload: err.response.data,
      });
    }
    dispatch({
      type: types.GET_EXTERNAL_SITE_MESSAGE_FAILURE,
      payload: "error trying to get external site messages",
    });
  }
};
export const showExternalConfirmationModal = show => dispatch =>
  dispatch({ type: types.SHOW_EXTERNAL_CONFIRMATION_MODAL, payload: show });
