import { REHYDRATE } from "redux-persist/lib/constants";
import * as types from "./constants";
import { CHANGE_STEP_TO } from "../Auth/types";
import { sortFormsList } from "../../utils/sort";

const initialState = {
  error: null,
  username: null,
  userId: null,
  phrase: null,
  name: null,
  mfa: null,
  mfaId: null,
  authenticated: false,
  currentStep: "1",
  isFetching: false,
  message: "",
  showResetMFAModal: false,
  showResetMFASuccessModal: false,
  customers: [],
  olbUsers: [],
  forms: [],
  members: [],
  showCreateMessageSuccessModal: false,
  showCreateDirectMessageSuccessModal: false,
  showCreateNotificationSuccessModal: false,
  showCreateDirectNotificationSuccessModal: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case REHYDRATE:
      const incomingState = payload && payload.admin;
      if (incomingState) {
        return {
          ...state,
          ...incomingState,
          currentStep: "1",
          isSucessUserName: false,
          error: null,
        };
      }
      return state;
    case types.AUTH_ADMIN_USER:
      return { ...state, authenticated: true };
    case types.GET_ADMIN_PAGE_REQUEST:
      return { ...state, isFetching: true };
    case types.GET_ADMIN_PAGE_SUCCESS:
      return {
        ...state,
        message: payload,
        isFetching: false,
        error: null,
      };
    case types.GET_ADMIN_PAGE_FAILURE:
      return { ...state, isFetching: false, message: payload, error: payload };
    case types.FIRST_STEP_AUTH_ADMIN_REQUEST:
      return { ...state, isFetching: true };
    case types.FIRST_STEP_AUTH_ADMIN_SUCCESS:
      return {
        ...state,
        phrase: payload.phrase,
        // currentStep: '2',
        isSucessUserName: true,
        isFetching: false,
        username: payload.username,
        userId: payload.id,
        name: payload.name,
        error: null,
      };
    case types.FIRST_STEP_AUTH_ADMIN_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.SECOND_STEP_AUTH_ADMIN_REQUEST:
      return { ...state, isFetching: true };
    case types.SECOND_STEP_AUTH_ADMIN_SUCCESS:
      return {
        ...state,
        phrase: null,
        currentStep: "3",
        isFetching: false,
        // mfa: payload.mfa,
        // mfaId: payload.mfaId,
        error: null,
      };
    case types.SECOND_STEP_AUTH_ADMIN_FAILURE:
      return {
        ...state,
        // currentStep: "2",
        isFetching: false,
        error: payload,
      };
    case types.THIRD_STEP_AUTH_ADMIN_REQUEST:
      return { ...state, isFetching: true };
    case types.THIRD_STEP_AUTH_ADMIN_SUCCESS:
      return { ...state, currentStep: "1", isFetching: false, mfa: null, error: null };
    case types.THIRD_STEP_AUTH_ADMIN_FAILURE:
      return {
        ...state,
        currentStep: "3",
        isFetching: false,
        error: payload,
      };
    case CHANGE_STEP_TO:
      return { ...state, currentStep: payload, error: null, isFetching: false };
    case types.RESET_MFA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.RESET_MFA_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.RESET_MFA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    case types.SHOW_RESET_MFA_MODAL:
      return { ...state, showResetMFAModal: payload };
    case types.SHOW_RESET_MFA_SUCCESS_MODAL:
      return { ...state, error: null, showResetMFASuccessModal: payload };
    case types.LOGIN_AS_MEMBER_REQUEST:
      return { ...state, isFetching: true };
    case types.LOGIN_AS_MEMBER_SUCCESS:
      return { ...state, isFetching: false };
    case types.LOGIN_AS_MEMBER_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.CREATE_CUSTOMER_MESSAGE_REQUEST:
      return { ...state, isFetching: true };
    case types.CREATE_CUSTOMER_MESSAGE_SUCCESS:
      return { ...state, isFetching: false };
    case types.CREATE_CUSTOMER_MESSAGE_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.GET_ALL_CUSTOMERS_REQUEST:
      return { ...state, isFetching: true };
    case types.GET_ALL_CUSTOMERS_SUCCESS:
      return { ...state, isFetching: false, olbUsers: payload };
    case types.GET_ALL_CUSTOMERS_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.GET_ADMIN_FORMS_REQUEST:
      return { ...state, isFetching: true };
    case types.GET_ADMIN_FORMS_SUCCESS:
      return { ...state, isFetching: false, forms: payload };
    case types.GET_ADMIN_FORMS_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.SHOW_CREATE_MESSAGE_SUCCESS_MODAL:
      return { ...state, showCreateMessageSuccessModal: payload };
    case types.SHOW_CREATE_DIRECT_MESSAGE_SUCCESS_MODAL:
      return { ...state, showCreateDirectMessageSuccessModal: payload };
    case types.CREATE_CUSTOMER_DIRECT_MESSAGE_REQUEST:
      return { ...state, isFetching: true };
    case types.CREATE_CUSTOMER_DIRECT_MESSAGE_SUCCESS:
      return { ...state, isFetching: false };
    case types.CREATE_CUSTOMER_DIRECT_MESSAGE_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.CREATE_CUSTOMER_NOTIFICATION_REQUEST:
      return { ...state, isFetching: true };
    case types.CREATE_CUSTOMER_NOTIFICATION_SUCCESS:
      return { ...state, isFetching: false };
    case types.CREATE_CUSTOMER_NOTIFICATION_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.SHOW_CREATE_NOTIFICATION_SUCCESS_MODAL:
      return { ...state, showCreateNotificationSuccessModal: payload };
    case types.SHOW_CREATE_DIRECT_NOTIFICATION_SUCCESS_MODAL:
      return { ...state, showCreateDirectNotificationSuccessModal: payload };
    case types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_REQUEST:
      return { ...state, isFetching: true };
    case types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_SUCCESS:
      return { ...state, isFetching: false };
    case types.CREATE_CUSTOMER_DIRECT_NOTIFICATION_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.FILTER_ADMIN_FORMS:
      return { ...state, forms: [...payload] };
    case types.FILTER_ADMIN_FORMS_FAILURE:
      return { ...state, error: payload };
    case types.UPDATE_ADMIN_FORM_STATUS_REQUEST:
      return { ...state, isFetching: true };
    case types.UPDATE_ADMIN_FORM_STATUS_SUCCESS:
      let newForms = state.forms.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            status: payload.status,
          };
        }

        return item;
      });
      return {
        ...state,
        forms: [...sortFormsList(newForms, payload.sort)],
        isFetching: false,
      };
    case types.UPDATE_ADMIN_FORM_STATUS_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.UPDATE_ADMIN_FORM_MANY_STATUS_REQUEST:
      return { ...state, isFetching: true };
    case types.UPDATE_ADMIN_FORM_MANY_STATUS_SUCCESS:
      return { ...state, isFetching: false };
    case types.UPDATE_ADMIN_FORM_MANY_STATUS_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.GET_ALL_MEMBERS_REQUEST:
      return { ...state, isFetching: true };
    case types.GET_ALL_MEMBERS_SUCCESS:
      return { ...state, isFetching: false, members: payload };
    case types.GET_ALL_MEMBERS_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.CHANGE_STATUS_MEMBER_REQUEST:
      return { ...state, isFetching: true };
    case types.CHANGE_STATUS_MEMBER_SUCCESS:
      return { ...state, isFetching: false };
    case types.CHANGE_STATUS_MEMBER_FAILURE:
      return { ...state, isFetching: false, error: payload };
    case types.UNAUTH_ADMIN_USER:
      const root = JSON.parse(localStorage.getItem("persist:root"));
      localStorage.setItem(
        "persist:root",
        JSON.stringify({
          ...root,
          admin: "{}",
        }),
      );

      return { ...initialState };
    default:
      return state;
  }
}
