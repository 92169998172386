import _ from "lodash";
import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import * as types from "./constants";
import { post } from "../../../utils/server";
const ROOT_URL = `/payments`;

export const quickpayToAutopay = payload => async (dispatch, getState) => {
  /*
  payload should be:
    - quickPayUniqueId
    - frequency       --> enum
    - startDate       --> MM/DD/YYYY
    - method          --> enum
    - amount
    - fromAccountId
    - fromAccountType --> enum
    - paymentCount    --> not required
    - finalAmount     --> not required
  */
  try {
    dispatch({ type: types.QUICKPAY_TO_AUTOPAY_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/quick2auto`, { userId, ...payload });
    if (data.success) {
      dispatch({
        type: types.QUICKPAY_TO_AUTOPAY_SUCCESS,
        payload: data.data,
      });
      dispatch({ type: types.SHOW_SCHEDULE_AUTOPAY_SUCCESS_MODAL });
    } else {
      dispatch({
        type: types.QUICKPAY_TO_AUTOPAY_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.QUICKPAY_TO_AUTOPAY_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.QUICKPAY_TO_AUTOPAY_FAILURE,
        payload: "Error trying to change quick pay to auto pay.",
      });
    }
  }
};

export const removeAutopay = uniqueId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REMOVE_AUTOPAY_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/removeautopay`, { userId, uniqueId });
    if (data.success) {
      dispatch({
        type: types.REMOVE_AUTOPAY_SUCCESS,
        payload: uniqueId,
      });
    } else {
      dispatch({
        type: types.REMOVE_AUTOPAY_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_AUTOPAY_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REMOVE_AUTOPAY_FAILURE,
        payload: "error trying to remove autopay",
      });
    }
  }
};

export const getAutoPays = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_AUTOPAY_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getautopays`, { userId });
    if (data.success) {
      dispatch({
        type: types.GET_AUTOPAY_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_AUTOPAY_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_AUTOPAY_FAILURE,
        payload: err.response.data.message || "error trying to get autopay",
      });
    } else {
      dispatch({
        type: types.GET_AUTOPAY_FAILURE,
        payload: "error trying to get autopay",
      });
    }
  }
};

export const getPaymentActivity = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_PAYMENT_ACTIVITY_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getactivity`, { userId });
    if (data.success) {
      dispatch({
        type: types.GET_PAYMENT_ACTIVITY_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_PAYMENT_ACTIVITY_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_PAYMENT_ACTIVITY_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_PAYMENT_ACTIVITY_FAILURE,
        payload: "error trying to get payment activity",
      });
    }
  }
};

export const getScheduledPayments = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_SCHEDULED_PAYMENTS_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getscheduledpayments`, { userId });
    if (data.success) {
      dispatch({
        type: types.GET_SCHEDULED_PAYMENTS_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_SCHEDULED_PAYMENTS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_SCHEDULED_PAYMENTS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_SCHEDULED_PAYMENTS_FAILURE,
        payload: "error trying to get scheduled payments",
      });
    }
  }
};

export const getQuickPays = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_QUICK_PAYS_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getquickpays`, { userId });
    if (data.success) {
      dispatch({
        type: types.GET_QUICK_PAYS_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_QUICK_PAYS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_QUICK_PAYS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_QUICK_PAYS_FAILURE,
        payload: "error trying to get payees",
      });
    }
  }
};

export const insertPayee = payee => async (dispatch, getState) => {
  try {
    dispatch({ type: types.INSERT_PAYEE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/insertpayee`, { userId, ...payee });
    if (data.success) {
      dispatch({
        type: types.INSERT_PAYEE_SUCCESS,
        payload: data.data,
      });
      dispatch(push("/dashboard/payments"));
    } else {
      dispatch({
        type: types.INSERT_PAYEE_FAILURE,
        payload: data.message || "error trying to insert payees",
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.INSERT_PAYEE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.INSERT_PAYEE_FAILURE,
        payload: "error trying to insert payees",
      });
    }
  }
};

export const updatePayee = payee => async (dispatch, getState) => {
  try {
    dispatch({ type: types.UPDATE_PAYEE_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/updatepayee`, { userId, ...payee });
    if (data.success) {
      dispatch({
        type: types.UPDATE_PAYEE_SUCCESS,
        payload: data.data,
      });
      dispatch(push("/dashboard/payments"));
    } else {
      dispatch({
        type: types.UPDATE_PAYEE_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.UPDATE_PAYEE_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.UPDATE_PAYEE_FAILURE,
        payload: "error trying to update payees",
      });
    }
  }
};

export const makePayment = payments => async (dispatch, getState) => {
  try {
    const paymentList = payments && _.filter(payments, pay => !undefined && pay);
    dispatch({ type: types.MAKE_PAYMENT_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/makepayments`, { userId, paymentList });
    if (data.success) {
      dispatch({
        type: types.MAKE_PAYMENT_SUCCESS,
        payload: data.data,
      });
      dispatch(getQuickPays());
      dispatch(showSuccessModal(true));
    } else {
      dispatch(getQuickPays());
      dispatch({
        type: types.MAKE_PAYMENT_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.MAKE_PAYMENT_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.MAKE_PAYMENT_FAILURE,
        payload: "error trying to make payment",
      });
    }
  }
};

export const getPayeeDetail = body => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_PAYEE_DETAIL_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/getpayeedetail`, { userId, ...body });
    if (data.success) {
      dispatch({
        type: types.GET_PAYEE_DETAIL_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: types.GET_PAYEE_DETAIL_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_PAYEE_DETAIL_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_PAYEE_DETAIL_FAILURE,
        payload: "error trying to get default payees",
      });
    }
  }
};

export const removeQuickPayRow = uniqueId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REQUEST_REMOVE_QUICKPAY_ROW });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/removequickpay`, { userId, uniqueId });
    if (data.success) {
      dispatch({ type: types.REMOVE_QUICKPAY_ROW_SUCCESS, payload: uniqueId });
    } else {
      dispatch({
        type: types.REMOVE_QUICKPAY_ROW_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_QUICKPAY_ROW_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REMOVE_QUICKPAY_ROW_FAILURE,
        payload: "error trying to remove quick pay row",
      });
    }
  }
};

export const unschedulePayment = uniqueId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.UNSCHEDULE_PAYMENT_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/unschedule`, { userId, uniqueId });
    if (data.success) {
      dispatch({ type: types.UNSCHEDULE_PAYMENT_SUCCESS, payload: uniqueId });
    } else {
      dispatch({
        type: types.UNSCHEDULE_PAYMENT_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.UNSCHEDULE_PAYMENT_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.UNSCHEDULE_PAYMENT_FAILURE,
        payload: "error trying to unschedule payment",
      });
    }
  }
};

export const updateAutoPay = (
  autoPayUniqueId,
  frequency,
  nextDate,
  method,
  amount,
  fromAccountId,
  fromAccountType,
  paymentCount,
  finalAmount,
) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.UPDATE_AUTOPAY_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/updateautopay`, {
      userId,
      autoPayUniqueId,
      frequency,
      nextDate,
      method,
      amount,
      fromAccountId,
      fromAccountType,
      paymentCount,
      finalAmount,
    });
    if (data.success) {
      dispatch({ type: types.UPDATE_AUTOPAY_SUCCESS });
      dispatch(showAutoPayUpdateConfirmModal());
    } else {
      dispatch({ type: types.UPDATE_AUTOPAY_FAILURE, payload: data.message });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.UPDATE_AUTOPAY_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.UPDATE_AUTOPAY_FAILURE,
        payload: "error trying to update auto pay.",
      });
    }
  }
};

export const autoPayToQuickPay = uniqueId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.AUTOPAY_TO_QUICKPAY_REQUEST });
    const {
      auth: { userId },
    } = getState();
    const { data } = await post(`${ROOT_URL}/auto2quick`, {
      userId,
      autoPayUniqueId: uniqueId,
    });
    if (data.success) {
      dispatch({ type: types.AUTOPAY_TO_QUICKPAY_SUCCESS });
      dispatch(hideAutoPayToQuickPayConfirmModal());
      dispatch(push("/dashboard/payments"));
    } else {
      dispatch({
        type: types.AUTOPAY_TO_QUICKPAY_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.AUTOPAY_TO_QUICKPAY_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.AUTOPAY_TO_QUICKPAY_FAILURE,
        payload: "error trying to change auto pay to quick pay.",
      });
    }
  }
};

export const updatePayeeDetailFormValue = (name, value) => dispatch =>
  dispatch({ type: types.UPDATE_PAYEE_DETAIL_FORM_VALUE, payload: { name, value } });
export const setFromTypeOptionsForQuickpayRow = (rowId, options) => dispatch =>
  dispatch({
    type: types.SET_OPTIONS_FROM_TYPE_QUICKPAY_ROW,
    payload: { id: rowId, options },
  });
export const resetQuickPayRow = () => dispatch =>
  dispatch({ type: types.FORM_QUICKPAY_RESET });
export const showSuccessModal = show => dispatch =>
  dispatch({ type: types.SHOW_SUCCESS_MODAL, payload: show });
export const openConfirmModal = () => dispatch =>
  dispatch({ type: types.OPEN_CONFIRM_MODAL });
export const closeConfirmModal = () => dispatch =>
  dispatch({ type: types.CLOSE_CONFIRM_MODAL });
export const openAutoPayToQuickPayConfirmModal = autoPay => dispatch =>
  dispatch({ type: types.SHOW_AUTOPAY_TO_QUICKPAY_CONFIRM_MODAL, payload: autoPay });
export const hideAutoPayToQuickPayConfirmModal = () => dispatch =>
  dispatch({ type: types.HIDE_AUTOPAY_TO_QUICKPAY_CONFIRM_MODAL });
export const openRemoveAutoPayModal = () => dispatch =>
  dispatch({ type: types.OPEN_REMOVE_AUTOPAY_MODAL });
export const closeRemoveAutopaySuccessModal = () => dispatch =>
  dispatch({ type: "CLOSE_AUTOPAY_SUCCESS_MODAL" });
export const closeRemoveAutoPayModal = () => dispatch =>
  dispatch({ type: types.CLOSE_REMOVE_AUTOPAY_MODAL });
export const updateQuickPayForm = payload => dispatch =>
  dispatch({ type: types.FORM_QUICKPAY_UPDATE_VALUE, payload });
export const setLastPayDayValue = (rowId, value) => dispatch =>
  dispatch({ type: types.SET_LAST_PAY_DAY_VALUE, payload: { id: rowId, value } });
export const setQuickPayToAutoPay = quickPay => dispatch =>
  dispatch({ type: types.SET_QUICKPAY_TO_AUTOPAY, payload: quickPay });
export const setAutoPayForUpdate = autoPay => dispatch =>
  dispatch({ type: types.SET_AUTOPAY_FOR_UPDATE, payload: autoPay });
export const changeEPaymentsError = error => dispatch =>
  dispatch({ type: types.CHANGE_E_PAYMENTS_ERROR, payload: error });
export const showScheduleAutoPaySuccessModal = () => dispatch =>
  dispatch({ type: types.SHOW_SCHEDULE_AUTOPAY_SUCCESS_MODAL });
export const hideScheduleAutoPaySuccessModal = () => dispatch => {
  dispatch({ type: types.HIDE_SCHEDULE_AUTOPAY_SUCCESS_MODAL });
  dispatch(push("/dashboard/payments"));
};
export const showAutoPayUpdateConfirmModal = () => dispatch =>
  dispatch({ type: types.SHOW_AUTOPAY_UPDATE_CONFIRM_MODAL });
export const hideAutoPayUpdateConfirmModal = () => dispatch => {
  dispatch({ type: types.HIDE_AUTOPAY_UPDATE_CONFIRM_MODAL });
  dispatch(push("/dashboard/payments"));
};
