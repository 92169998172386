import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import FeatherIcon from "feather-icons-react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import ControlPanel from "../../components/commons/controlPanel";
import { DashboardScreens } from "../Dashboard/DashboardInstance/Dashboard";
import { getAllMembers, changeStatusMember } from "./actions";
import SortableTableAdmins from "../../components/containers/Admin/Admins/SortableTableAdmins";
import NewAdminModal from "../../components/containers/Admin/Admins/NewAdminModal";

const Container = styled.div`
  .MuiOutlinedInput-adornedStart {
    padding-left: 0px;
  }
`;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
    borderSpacing: 0,
  },
  table: {
    minWidth: 700,
    border: "1px solid #ebeef1",
    borderRadius: 5,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#fff",
    height: "43px",
  },
});

const filterItems = [
  {
    id: 1,
    title: "Status",
    link: "/",
    sub: [
      {
        id: 11,
        title: "Active",
        link: "/",
        sub: null,
        value: null,
      },
      {
        id: 12,
        title: "Invitation Pending",
        link: "/",
        sub: null,
        value: null,
      },
    ],
  },
  {
    id: 2,
    title: "Roles",
    link: "/",
    sub: [
      {
        id: 21,
        title: "Full Access",
        link: "/",
        sub: null,
        value: null,
      },
      {
        id: 22,
        title: "Banking Access",
        link: "/",
        sub: null,
        value: null,
      },
      {
        id: 23,
        title: "Content Management",
        link: "/",
        sub: null,
        value: null,
      },
    ],
  },
];

const adminsData = [
  {
    id: 1,
    name: "Marysa Labrone",
    username: "jhonson87",
    role: "Full Access",
    status: "Active",
  },
  {
    id: 2,
    name: "Diane Lansdowne",
    username: "jhonson87",
    role: "Banking Access",
    status: "Active",
  },
  {
    id: 3,
    name: "Njimoluh Ebua",
    username: "jhonson87",
    role: "Content Management",
    status: "Pending",
  },
];

const Admins = ({ classes, getAllMembers, members, isFetching, changeStatusMember }) => {
  const [search, setSearch] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlerOnKeyPressSearch = e => {
    if (e.key === "Enter") {
      if (search.length > 0) {
        setIsSearch(true);
        //get all admins
      } else {
        //get all admins
      }
      e.preventDefault();
    }
  };

  const handlerChangeStatus = (status, userId) => {
    // Change status Admins
  };

  useEffect(() => {
    getAllMembers({});
  }, []);

  return (
    <DashboardScreens>
      <Container>
        <NewAdminModal open={open} handleOpen={handleOpen} handleClose={handleClose} />
        <ControlPanel
          handleOpen={handleOpen}
          actionButton="Add Admin"
          title="Admins"
          filterItems={filterItems}
          valueSearch={search}
          onChangeSearch={e => setSearch(e.target.value)}
          onKeyPressSearch={handlerOnKeyPressSearch}
          deleteFilter={id => {
            const filter = activeFilters.filter(item => item.id !== id);
            setActiveFilters(filter);
            //get all Admins
          }}
          activeFilters={activeFilters}
          setActiveFilters={filters => {
            setActiveFilters(filters);
            //get all Admins
          }}
          loading={isFetching}
          formStyleSearch={{ marginLeft: "0px" }}
          endAdornmentSearch={
            search ? (
              <FeatherIcon
                icon="x"
                size="6"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSearch("");
                  if (isSearch) {
                    //get All Members
                    setIsSearch(false);
                  }
                }}
              />
            ) : null
          }
        />
        <MediaQuery query="(min-width: 768px)">
          <SortableTableAdmins
            members={adminsData}
            classes={classes}
            loading={isFetching}
            changeStatusMember={handlerChangeStatus}
          />
        </MediaQuery>
      </Container>
    </DashboardScreens>
  );
};

Admins.prototype = {
  classes: PropTypes.object,
  getAllMembers: PropTypes.func,
  members: PropTypes.array,
  isFetching: PropTypes.object,
  changeStatusMember: PropTypes.func,
};

const mapStateToProps = state => ({
  members: state.admin.members,
  isFetching: state.admin.isFetching,
});

export default withStyles(styles)(
  connect(mapStateToProps, { getAllMembers, changeStatusMember })(Admins),
);
