import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { REPORT_STATUS, tableTypes } from "./";
import Layout from "./Layout";
import { useFilters, useSearch, useTable } from "./hooks";
import { loginAsMemberEview } from "../../Admin/actions";

const { REACT_APP_AWS_S3_URL } = process.env;

const Admins = ({ loginAsMemberEview, data, searchQuery, setSearchQuery, filters }) => {
  const getReportStatus = ({ action = REPORT_STATUS.ADMINS.NONE }) => {
    switch (action) {
      case REPORT_STATUS.ADMINS.LOCK:
        return {
          ok: true,
          label: "Lock",
          icon: `${REACT_APP_AWS_S3_URL}/images/members.png`,
        };
      case REPORT_STATUS.ADMINS.UNLOCK:
        return {
          ok: true,
          label: "UnLock",
          icon: `${REACT_APP_AWS_S3_URL}/images/members.png`,
        };

      case REPORT_STATUS.ADMINS.READ:
        return {
          ok: true,
          label: "Read",
          icon: `${REACT_APP_AWS_S3_URL}/images/read.png`,
        };

      case REPORT_STATUS.ADMINS.SECURED_FORM_READ:
        return {
          ok: true,
          label: "Secured form read",
          icon: `${REACT_APP_AWS_S3_URL}/images/read.png`,
        };

      case REPORT_STATUS.ADMINS.RESET_PASSWORD:
        return {
          ok: true,
          label: "Reset Password",
          icon: `${REACT_APP_AWS_S3_URL}/images/admins.png`,
        };

      case REPORT_STATUS.ADMINS.SUSPENDED:
        return {
          ok: true,
          label: "Suspended",
          icon: `${REACT_APP_AWS_S3_URL}/images/members.png`,
        };

      case REPORT_STATUS.ADMINS.DELETED:
        return {
          ok: true,
          label: "Deleted",
          icon: `${REACT_APP_AWS_S3_URL}/images/members.png`,
        };

      case REPORT_STATUS.ADMINS.ADD_ADMIN:
        return {
          ok: true,
          label: "Add Admin",
          icon: ``,
        };

      default:
        return { ok: false, label: "", icon: "" };
    }
  };

  const {
    dataSorted,
    handleClickFilter,
    handleClickSort,
    handleCloseSortMenu,
    filtersTypes,
    isOpenMenu,
  } = useFilters({
    getReportStatus,
    data,
  });

  const { data: searchData } = useSearch({
    query: searchQuery,
    data: dataSorted,
    searchBy: filters,
    userType: tableTypes.ADMINS,
  });

  const sortMenuItems = [
    { label: "Action", type: filtersTypes.ACTION },
    { label: "Username", type: filtersTypes.USERNAME },
    { label: "Date", type: filtersTypes.DATE },
    { label: "None", type: filtersTypes.NONE },
  ];

  const title = `${searchData.length} Admins Logs`;

  const sortLabel = "Sort: ";

  const afterHandleClickRow = (row = {}) => {
    loginAsMemberEview(row.idOLBUser);
  };

  const { handleClickRow } = useTable({ afterHandleClickRow });

  return (
    <Layout
      title={title}
      tableType={tableTypes.ADMINS}
      sortLabel={sortLabel}
      isOpenSortMenu={isOpenMenu}
      tableRows={searchData}
      searchQuery={searchQuery}
      sortMenuItems={sortMenuItems}
      handleChangeSearchQuery={setSearchQuery}
      handleClickFilter={handleClickFilter}
      handleClickSort={handleClickSort}
      handleCloseSortMenu={handleCloseSortMenu}
      handleClickRow={handleClickRow}
    />
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = { loginAsMemberEview };

Admins.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
