import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import { ConfirmationModal, SuccessModal } from "../../../components/commons/Modals";
import { BlackTrash, Check } from "../../../components/commons/icons";
import { withStyles } from "@material-ui/core/styles";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import {
  MobileTable,
  MobileTableButtonGroup,
  MobileTableButton,
  MobileTableCell,
  MobileTableRow,
  MobileTableHeading,
} from "../../../components/commons/MobileTable";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Space, P, PageTitle, ReadMore } from "../../../components/commons";
import {
  markNotificationsAsViewed,
  getCustomerNotifications,
  removeNotification,
  showRemoveMessageConfirmationModal,
  showRemoveMessageSuccessModal,
} from "./actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const Container = styled.div`
  flex: 1;
`;

const tableStyles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
});

const CustomTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FCFCFC",
    color: "#8E949C",
    fontSize: "14px",
    padding: "1rem",
    fontWeight: "500",
    whiteSpace: "nowrap",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
  body: {
    padding: "15px 1rem",
    fontSize: "15px",
    fontWeight: "300",
    whiteSpace: "nowrap",
    color: "#2F303E",
    lineHeight: "1rem",
    fontFamily: "Kumbh Sans",
  },
}))(TableCell);

class NotificationsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getCustomerNotifications: PropTypes.func,
    removeNotification: PropTypes.func,
    notifications: PropTypes.array,
    isRemoveModalOpen: PropTypes.bool,
    isRemoveSuccessModalOpen: PropTypes.bool,
    showRemoveMessageConfirmationModal: PropTypes.func,
    showRemoveMessageSuccessModal: PropTypes.func,
    markNotificationsAsViewed: PropTypes.func,
  };
  componentDidMount() {
    this.props.getCustomerNotifications();
    this.props.markNotificationsAsViewed();
  }

  handleRemove(id) {
    this.props.removeNotification(id);
  }

  renderRemoveConfirmationModal() {
    return (
      <ConfirmationModal
        isOpen={this.props.isRemoveModalOpen}
        onClose={() => this.props.showRemoveMessageConfirmationModal(false)}
        onSuccess={() => this.props.removeNotification(this.state.messageToRemove)}
        headingText="Delete Notification"
        confirmationText={<P>Are you sure you want to Delete this Notification?</P>}
        cancelButtonText="Close"
        successButtonText={"Confirm"}
        aria-hidden={this.props.isRemoveModalOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }
  renderRemoveNotificationSuccessModal() {
    return (
      <SuccessModal
        isOpen={this.props.isRemoveSuccessModalOpen}
        onClose={() => {
          this.props.showRemoveMessageSuccessModal(false);
          this.setState({ messageToRemove: null });
        }}
        headingText="Notification Removed successfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <DashboardScreens>
        <Container>
          {this.renderRemoveConfirmationModal()}
          {this.renderRemoveNotificationSuccessModal()}
          <PageTitle>Notifications</PageTitle>
          <Mobile>
            <MobileTable title={"notifications"}>
              {this.props.notifications && this.props.notifications.length > 0 ? (
                _.map(this.props.notifications, (item, index) => (
                  <MobileTableRow key={item.id + index}>
                    <div style={{ width: "100%" }}>
                      <MobileTableHeading value={"Notification"} />
                      <MobileTableCell
                        name={"Date"}
                        value={moment(item.postedDate).utc().format("MM/DD/YYYY")}
                      />
                      <MobileTableCell
                        name={"message"}
                        value={<ReadMore maxLength={30}>{item.notification}</ReadMore>}
                      />
                      <MobileTableCell>
                        <MobileTableButtonGroup>
                          <MobileTableButton danger>
                            <BlackTrash
                              onClick={() => {
                                this.setState({ messageToRemove: item.id });
                                this.props.showRemoveMessageConfirmationModal(true);
                              }}
                            />
                            <Space />
                            <p>DELETE</p>
                          </MobileTableButton>
                        </MobileTableButtonGroup>
                      </MobileTableCell>
                    </div>
                  </MobileTableRow>
                ))
              ) : (
                <MobileTableRow>
                  <P>{"You don't have notifications"}</P>
                </MobileTableRow>
              )}
            </MobileTable>
          </Mobile>

          <Desktop>
            <div style={{ overflowX: "auto" }}>
              <Table className={classes.table}>
                <TableHead className={classes.thead}>
                  <TableRow>
                    <CustomTableCell>Date</CustomTableCell>
                    <CustomTableCell>Message</CustomTableCell>
                    <CustomTableCell>Delete</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.notifications && this.props.notifications.length > 0 ? (
                    _.map(this.props.notifications, (item, index) => (
                      <TableRow key={item.id + index}>
                        <CustomTableCell>
                          {moment(item.postedDate).utc().format("MM/DD/YYYY")}
                        </CustomTableCell>
                        <CustomTableCell>
                          <ReadMore maxLength={60}>{item.notification}</ReadMore>
                        </CustomTableCell>
                        <CustomTableCell>
                          <BlackTrash
                            onClick={() => {
                              this.setState({ messageToRemove: item.id });
                              this.props.showRemoveMessageConfirmationModal(true);
                            }}
                          />
                        </CustomTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <CustomTableCell style={{ borderBottom: "none" }}>
                        {"You don't have notifications"}
                      </CustomTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = ({ account }) => ({
  notifications: account.notifications,
  isRemoveModalOpen: account.isRemoveMessageModalOpen,
  isRemoveSuccessModalOpen: account.isRemoveMessageSuccessModalOpen,
});

export default withStyles(tableStyles)(
  connect(mapStateToProps, {
    markNotificationsAsViewed,
    getCustomerNotifications,
    removeNotification,
    showRemoveMessageConfirmationModal,
    showRemoveMessageSuccessModal,
  })(NotificationsPage),
);
