import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../../components/commons";
import TextField from "../../../components/commons/TextField";
import { FormThirdContainer } from "../../../components/commons/LoginStyles";
import { InputAdornment, CircularProgress } from "@material-ui/core";
import { InfoGreen } from "../../../components/commons/icons";

class FormThirdStep extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    handleChangeStep: PropTypes.func.isRequired,
    stepError: PropTypes.string,
    isFetching: PropTypes.bool,
    mfa: PropTypes.string,
  };
  state = {
    answer: "",
    error: "",
  };

  handleOnSubmit = () => {
    if (this.state.answer !== null && this.state.answer !== "") {
      this.props.onSubmit(this.state.answer);
    } else {
      this.setState({
        error: "please fill the form",
      });
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.handleOnSubmit();
    }
  };

  render() {
    const { mfa, stepError, isFetching, classes } = this.props;
    return (
      <FormThirdContainer>
        <InfoGreen stylesInput={{ width: "17px", height: "17px", marginTop: "10%" }} />
        <span className="descriptionPage">Answer your security question to login</span>
        <span className="titleQuestion">Question:</span>
        <span className="question">{mfa}</span>
        <div className="separation" />
        <TextField
          onChange={event => this.setState({ answer: event.target.value })}
          value={this.state.answer}
          autoFocus
          onKeyPress={this.handleKeyPress}
          type="password"
          name="answer"
          id="answer"
          error={stepError || this.state.error}
          helperText={stepError || this.state.error}
          label={"Your Answer"}
          notMargin
          labelGray
          endAdornment={
            <InputAdornment position="end">
              {isFetching ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: "#05CE86", width: 25, height: 25 }}
                />
              ) : (
                ""
              )}
            </InputAdornment>
          }
        />
        <div className="containerChangeQuestion">
          <a href="#any" className="changeQuestion">
            Change Question
          </a>
        </div>

        <Button
          primary={!isFetching}
          disabled={isFetching}
          onClick={this.handleOnSubmit}
          className="btnLogin"
        >
          Log In
        </Button>
      </FormThirdContainer>
    );
  }
}

export default FormThirdStep;
