import React from "react";
import PropTypes from "prop-types";
import { ProgressBar } from "../../../components/commons";
import _ from "lodash";
import styles from "./styles.module.css";

// TODO: add default values
const LoanStep = ({ children }) => <div className={styles.loanStep}>{children}</div>;
LoanStep.propTypes = { children: PropTypes.any };

const LoanForm = ({ children }) => <form className={styles.loanForm}>{children}</form>;
LoanForm.propTypes = { children: PropTypes.any };

const LoanBody = ({ children }) => <div>{children}</div>;
LoanBody.propTypes = { children: PropTypes.any };

const LoanProgress = ({ progress, text, title }) => (
  <div className="LoanProgressContainer">
    <span className="titleProgress">{text}</span>
    <p>{title}</p>
    <div className={styles.loanProgress}>
      {progress && !_.isEmpty(progress) && progress !== "0%" && (
        <ProgressBar progress={progress} />
      )}
    </div>
  </div>
);
LoanProgress.propTypes = { progress: PropTypes.string, text: PropTypes.string };

// const LoanTitle = ({ title }) => <p style={{ fontSize: '0.875rem', fontWeight: '500', color: '#7A7A7A' }}>{title}</p>;
// LoanTitle.propTypes = { title: PropTypes.string };

const LoanControls = ({ children }) => (
  <div className={styles.loanControls}>{children}</div>
);
LoanControls.propTypes = { children: PropTypes.any };

export {
  LoanStep,
  // LoanTitle,
  LoanBody,
  LoanForm,
  LoanProgress,
  LoanControls,
};
