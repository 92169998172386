import React, { useMemo, useState } from "react";
import styled from "styled-components";
import FeatherIcon from "feather-icons-react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

const FilterContainer = styled.a`
  margin-left: 8px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  opacity: 1;
  background-color: ${({ clicked }) => (clicked ? "#ebeef1" : "transparent")};
  border-radius: 50%;
  div {
    display: flex;
  }
  &:svg {
    height: 30px;
    width: 30px;
  }
  &:hover {
    opacity: 0.8;
    background-color: #ebeef1;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const ItemSvg = styled.div`
  display: flex;
  justify-content: center;
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const DropdownSubTitle = styled.div`
  font-size: 14px;
  color: #a5a7a9;
  border-top: 1px solid #ebeef1;
  border-bottom: 1px solid #ebeef1;
  padding: 0 15px;
`;

const DropdownContent = styled.div`
  position: absolute;
  background-color: #f9f9f9;
  min-width: 195px;
  max-width: 300px;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 15px;
  right: 0;
  border-radius: 12px;
  padding: 0 12px;
`;

const Oval = styled.div`
  border: solid 2px #3fc78e;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 7px;
  & > div {
    width: 7.5px;
    height: 7.5px;
    border-radius: 50%;
    background-color: #3fc78e;
  }
`;

const DropdownItem = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  padding: 6px 15px;
  align-items: center;
  color: ${({ active }) => (active ? "#3fc78e" : "#141f2d")};
  &:hover {
    background-color: #ebeef1;
    border-radius: 12px;
  }
`;

const useEvents = (onSelectFilter, item) => {
  const [hovered, setHovered] = useState();
  const [activeItem, setActiveItem] = useState();

  const eventHandlers = useMemo(
    () => ({
      onMouseOver() {
        setHovered(true);
      },
      onMouseOut() {
        setHovered(false);
      },
      onClick(e) {
        //get value of text
        setActiveItem(e.target.id);
        onSelectFilter({ ...item });
      },
    }),
    [],
  );

  return [hovered, eventHandlers, activeItem];
};

const Item = ({ children, item, onSelectFilter, isClickedItem }) => {
  const clicked = isClickedItem(item.id);
  const [hovered, eventHandlers, activeItem] = useEvents(onSelectFilter, item);

  return (
    <DropdownItem id={item.id} active={clicked ? true : false} {...eventHandlers}>
      {item.title}
      {hovered && !clicked && children}
      {clicked ? (
        <Oval>
          <div></div>
        </Oval>
      ) : null}
    </DropdownItem>
  );
};

function PanelDropdown({
  onSelectFilter,
  filterItems,
  notFilters,
  isClickedItem,
  loading,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleClickAway = () => {
    setShowDropdown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Dropdown>
        <FilterContainer
          clicked={showDropdown}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <FeatherIcon icon="filter" size="16" stroke="#797C7D" />
        </FilterContainer>
        {showDropdown && (
          <DropdownContent>
            {loading ? (
              <DropdownItem style={{ justifyContent: "center" }}>
                <CircularProgress style={{ color: "#05CE86" }} />
              </DropdownItem>
            ) : (
              <>
                {_.isEmpty(filterItems) && (
                  <DropdownItem onClick={() => notFilters(setShowDropdown)}>
                    No Filter
                  </DropdownItem>
                )}

                {!_.isEmpty(filterItems) &&
                  _.map(filterItems, ({ title, path, id, sub }, key) => (
                    <div>
                      <DropdownSubTitle>{title}</DropdownSubTitle>
                      {sub !== null &&
                        _.map(sub, (subItem, key) => (
                          <Item
                            key={key}
                            item={subItem}
                            onSelectFilter={onSelectFilter}
                            isClickedItem={isClickedItem}
                          >
                            <ItemSvg>
                              <FeatherIcon
                                icon="disc"
                                size="18"
                                stroke="#1f2427"
                                style={{ marginLeft: "7px" }}
                              />
                            </ItemSvg>
                          </Item>
                        ))}
                    </div>
                  ))}
              </>
            )}
          </DropdownContent>
        )}
      </Dropdown>
    </ClickAwayListener>
  );
}

export default PanelDropdown;
