import _ from "lodash";

export const selectAllCustomers = ({ admin: { olbUsers } }) => {
  const allOLBUser =
    olbUsers &&
    _.map(olbUsers, ({ BlossomUser, id }) => ({
      idOLBUser: id,
      username: BlossomUser.username,
    }));
  return allOLBUser;
};
