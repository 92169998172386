import React from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import PropTypes from "prop-types";
import { P, Heading, PageTitle, Button } from "../../../components/commons";
import styled from "styled-components";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { DashboardScreens } from "../DashboardInstance/Dashboard";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 3,
    overflow: "hidden",
  },
  table: {
    minWidth: 700,
    border: "1px solid rgba(18,55,77,0.08)",
    borderRadius: 10,
    borderCollapse: "separate",
  },
  thead: {
    backgroundColor: "#f8f9fa",
  },
});

const SearchResultsPage = props => {
  const { classes } = props;
  return (
    <DashboardScreens>
      <Container>
        <div>
          <PageTitle>Search Results for</PageTitle>
          <Heading>{props.lastSearch && props.lastSearch.name}</Heading>
        </div>
        <br />
        <div>
          <Button primary onClick={() => props.goBack()}>
            New Search
          </Button>
        </div>
        <br />
        <div style={{ overflowX: "auto" }}>
          <Table className={classes.table}>
            <TableHead className={classes.thead}>
              <TableRow>
                {props.accountsSummary && props.accountsSummary.length > 0 ? (
                  _.map(
                    props.accountsSummary,
                    (acc, index) =>
                      Object.keys(acc)[index] && (
                        <TableCell key={index}>
                          <span style={{ textTransform: "capitalize" }}>
                            {Object.keys(acc)[index]}
                          </span>
                        </TableCell>
                      ),
                  )
                ) : (
                  <TableCell></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.accountsSummary && props.accountsSummary.length > 0 ? (
                _.map(props.accountsSummary, (accObj, key) => (
                  <TableRow key={key}>
                    {_.map(accObj, (row, i) => (
                      <TableCell key={i}>{row}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <P>No results found</P>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Container>
    </DashboardScreens>
  );
};

SearchResultsPage.propTypes = {
  classes: PropTypes.object,
  accountsSummary: PropTypes.array,
  lastSearch: PropTypes.object,
  goBack: PropTypes.func,
};

export default withStyles(styles)(
  connect(
    ({ account: { accountsSummary, lastSearch } }) => ({ accountsSummary, lastSearch }),
    { goBack },
  )(SearchResultsPage),
);
