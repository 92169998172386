import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

import FormHover from "./FormHover";
import FormNormal from "./FormNormal";

import { formatDate } from "../../../../utils/helper";
import { getTextSource } from "../../../../utils/containers/admin/forms/index";

const FormContainer = styled.div`
  display: flex;
  padding: 15px;
  border-top: solid 1px #ebeef1;

  .form-hover {
    display: none;
  }

  &.checked-selected {
    background-color: rgba(63, 199, 142, 0.1);
    border-left: 4px solid #3fc78e;
  }

  &:hover {
    background-color: #ebeef1;
    cursor: pointer;
    border-left: none;

    .form-hover {
      display: flex;
    }

    .form-normal {
      display: none;
    }
  }
`;

const Form = ({
  onSelectForm,
  form,
  formIsChecked,
  formIsSelected,
  onChangeCheckboxForm,
  handlerOnChangeStatus,
}) => {
  const checked = formIsChecked(form.id);
  const selected = formIsSelected(form.id);

  const { OLBUser, OLBFinancialInstitutionSecuredForm, createdAt, status } = form;
  const similarProps = {
    source: getTextSource(form.source),
    date: createdAt ? formatDate(createdAt) : "",
    title:
      OLBFinancialInstitutionSecuredForm &&
      OLBFinancialInstitutionSecuredForm.OLBSecuredForm
        ? OLBFinancialInstitutionSecuredForm.OLBSecuredForm.friendlyName
        : "",
    accountName: OLBUser && OLBUser.accountNumber ? OLBUser.accountNumber : "",
  };

  return (
    <FormContainer
      className={checked || selected ? "checked-selected" : ""}
      onClick={onSelectForm}
    >
      <FormHover
        {...similarProps}
        checked={checked}
        handlerChangeStatusForm={newStatus =>
          handlerOnChangeStatus(form, status, newStatus)
        }
        onChangeCheckboxForm={e => onChangeCheckboxForm(e, form)}
        status={form.status}
      />

      <FormNormal
        {...similarProps}
        checked={checked}
        selected={selected}
        status={status}
      />
    </FormContainer>
  );
};

Form.propTypes = {
  form: PropTypes.object,
  formIsChecked: PropTypes.func,
  formIsSelected: PropTypes.func,
  onSelectForm: PropTypes.func,
  onChangeCheckboxForm: PropTypes.func,
  handlerOnChangeStatus: PropTypes.func,
};

export default Form;
