import "date-fns";
import React from "react";
import styled from "styled-components";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  input: {
    color: "#000000",
    fontFamily: "Kumbh Sans",
    fontSize: "14px",
    letterSpacing: 0,
    padding: "7px 0px 0px 0px",
    cursor: "pointer",
    width: "80px",
    marginRight: "5px",
  },
});

const defaultMaterialTheme = createMuiTheme({
  root: {
    border: "none",
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#3FC78E",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#3FC78E",
      },
    },
    MuiOutlinedInput: {
      border: "none",
    },
    PrivateNotchedOutline: {
      root: {
        border: "none",
      },
    },
    MuiIconButton: {
      root: {
        padding: "12px 0px 12px 0px",
      },
    },
  },
});

const ContainerCalendar = styled.div`
  display: flex;
  align-items: center;
  .date {
    color: #000000;
    font-family: "Kumbh Sans";
    font-size: 17px;
    letter-spacing: 0;
    width: 100%;
  }
`;

const DatapickerLabel = styled.label`
  color: #828991;
  font-size: 14px;
  line-height: 18px;
`;

const DatepickerTransactions = ({ name, label, value, onChange }) => {
  const classes = useStyles();
  return (
    <ContainerCalendar>
      <div style={{ width: "100%" }}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatapickerLabel>{label}:&nbsp;</DatapickerLabel>
            <DatePicker
              autoOk
              value={value}
              onChange={date =>
                onChange({
                  target: {
                    name,
                    value: date,
                  },
                })
              }
              animateYearScrolling
              variant="inline"
              inputVariant="standard"
              format="MM/dd/yyyy"
              InputProps={{ classes }}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </div>
    </ContainerCalendar>
  );
};

export default DatepickerTransactions;
