import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { InputLabel, Input, FormControl, FormHelperText } from "@material-ui/core";

const styles = theme => ({
  rootInput: {
    fontFamily: "Kumbh Sans",
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    minWidth: 250,
    color: "#000000",
    fontSize: "0.875rem",
    fontWeight: "300",
    "&:hover:before": {
      borderBottom: "1px solid #18243C !important",
    },
  },
  midSize: {
    maxWidth: "110px",
  },
  labelNormal: {
    fontSize: "15px",
    fontWeight: "300",
    color: "#ACABB2",
    fontFamily: "Kumbh Sans",
    "&$cssFocused": {
      color: "#ACABB2",
    },
  },
  cssNoneborderBottom: {
    "&:after": {
      borderColor: "#fff",
    },
    "&:before": {
      borderColor: "#fff",
    },
  },
  BigtextField: {
    color: "#000000",
    fontSize: "3.5rem",
    fontWeight: "700",
    "&::placeholder": {
      fontSize: "3.5rem",
    },
    "&:hover:before": {
      borderBottom: "1px solid #fff !important",
    },
  },
  margin: {
    margin: theme.spacing(1) * 2,
  },
  notMarginLeft: {
    margin: "16px 16px 16px 0px",
  },
  notMargin: {
    margin: "0px",
  },
  helperText: {
    color: "red",
  },
  cssLabel: {
    fontWeight: "300",
    color: "#ACABB2",
    fontFamily: "Kumbh Sans",
    fontSize: "15px",
    "&$cssFocused": {
      color: "#ACABB2",
    },
  },
  cssLabelGray: {
    color: "#C1CACE",
    fontFamily: "Kumbh Sans",
    fontSize: "15px",
    "&$cssFocused": {
      color: "#B9C3C7",
      fontFamily: "Kumbh Sans",
      fontSize: "12px",
    },
  },
  textFieldLabelGray: {
    color: "#1B2846",
    fontFamily: "Kumbh Sans",
    fontSize: "15px",
    "&:hover:before": {
      borderBottom: "1px solid #1B2846 !important",
    },
  },
  cssLabelBig: {
    fontSize: "18px",
    fontFamily: "Kumbh Sans",
    "&$cssFocused": {
      color: "#18243C",
    },
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderColor: "#18243C",
    },
    "&:before": {
      borderColor: "#B9C3C7",
    },
  },
  cssUnderlineBig: {
    "&:hover": {
      borderColor: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
    "&:before": {
      borderColor: "#fff",
    },
  },
  input: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
});

function TextField({
  classes,
  helperText,
  error,
  bigField,
  required,
  labelGray,
  margin,
  noneborderBottom,
  normal,
  midSize,
  fullWidth,
  shrink,
  label,
  maxLength,
  readOnly,
  notMargin,
  ...inputProps
}) {
  return (
    <FormControl
      required={required}
      fullWidth={fullWidth}
      className={notMargin ? classes.notMargin : classes.margin}
    >
      <InputLabel
        error={!!error}
        shrink={shrink}
        classes={{
          root: bigField
            ? classes.cssLabelBig
            : labelGray
            ? classes.cssLabelGray
            : normal
            ? classes.labelNormal
            : classes.cssLabel,
          focused: !error && classes.cssFocused,
        }}
      >
        {label}
      </InputLabel>
      <Input
        {...inputProps}
        error={!!error}
        classes={{
          underline: bigField
            ? classes.cssUnderlineBig
            : noneborderBottom
            ? classes.cssNoneborderBottom
            : error
            ? null
            : classes.cssUnderline,
          root: classes.rootInput,
          input: classes.input,
        }}
        className={
          midSize
            ? classes.midSize
            : bigField
            ? classes.BigtextField
            : labelGray
            ? classes.textFieldLabelGray
            : classes.textField
        }
        inputProps={{
          maxLength: maxLength || null,
          readOnly: readOnly || null,
        }}
      />
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}

TextField.propTypes = {
  classes: PropTypes.object,
  margin: PropTypes.object,
  notMargin: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shrink: PropTypes.bool,
};

export default withStyles(styles)(TextField);
