import React from "react";
import PropTypes from "prop-types";
import { goBack } from "react-router-redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components/commons";
import TextField from "../../../components/commons/TextField";
import { BlossomIconGreen } from "../../../components/commons/icons";
import {
  AuthBox,
  AuthContainer,
  Grid,
  ResetPasswordContainer,
  Space,
} from "../../../components/commons/LoginStyles";
import isValidEmail from "../../../utils/isValidEmail";
import { doSendRecoveryPasswordEmail } from "../actions";
import { withRouter } from "react-router-dom";

class ForgotYourPassword extends React.Component {
  static defaultProps = {
    onSubmit: () => undefined,
    doSendRecoveryPasswordEmail: () => undefined,
  };

  static propTypes = {
    goBack: PropTypes.func.isRequired,
    doSendRecoveryPasswordEmail: PropTypes.func.isRequired,
  };

  state = {
    username: "",
    password: "",
    error: "",
  };

  handleOnSubmit = async () => {
    try {
      const { username } = this.state;
      const { doSendRecoveryPasswordEmail } = this.props;

      if (username) {
        doSendRecoveryPasswordEmail(username, (success = false) => {
          if (!!success) {
            toast.info("The email was sent successfully.");
          } else {
            toast.error("The username is not valid.");
          }
        });
      } else {
        toast.error("The username is not valid.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleKeyPress = async e => {
    if (e.key === "Enter") {
      e.preventDefault();

      e.stopPropagation();

      await this.handleOnSubmit();
    }
  };

  render() {
    return (
      <AuthContainer>
        <div className="logo">
          <BlossomIconGreen stylesInput={{ opacity: 1, width: "27", height: "37px" }} />
        </div>
        <Grid>
          <div className="logoMobil">
            <BlossomIconGreen stylesInput={{ opacity: 1, width: "27", height: "37px" }} />
          </div>
          <AuthBox>
            <ResetPasswordContainer>
              <h1 className="title">Reset Password</h1>

              <span className="descriptionText">
                Please enter your username so that we can send you a link to reset your
                password.
              </span>

              <TextField
                onChange={event => this.setState({ username: event.target.value })}
                value={this.state.username}
                onKeyPress={this.handleKeyPress}
                type="text"
                autoFocus
                name="username"
                id="username"
                notMargin
                labelGray
                label="Username"
              />

              <Space />

              <Button primary onClick={this.handleOnSubmit} className="btnReset">
                Send Email
              </Button>

              <Button onClick={() => this.props.history.replace("/")} className="back">
                Back To Login
              </Button>
            </ResetPasswordContainer>
          </AuthBox>
        </Grid>
      </AuthContainer>
    );
  }
}

ForgotYourPassword.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(null, { goBack, doSendRecoveryPasswordEmail })(
  withRouter(ForgotYourPassword),
);
