export default function isValidPassword(password = "") {
  /** RegEx                     |      Description                                                                                                                                |  applied
   * ^                          |      The password string will start this way                                                                                                    |     ✔
   * (?=.*[az])                 |      The string must contain at least 1 lowercase alphabetical character                                                                        |     ✔
   * (?=.*[AZ])                 |      The string must contain at least 1 uppercase alphabetical character                                                                        |     ✔
   * (?=.*[0-9])                |      The string must contain at least 1 numeric character                                                                                       |     ✔
   * (?=.*[!@#$%^&*])           |      The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict                    |     ❌
   * (?=.{8,})                  |      The string must be eight characters or longer                                                                                              |     ✔
   */
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(password);
}
