import { connect } from "react-redux";
// import { push } from 'react-router-redux';
import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "../../../components/commons/TextField";
import Select from "../../../components/commons/SelectInput";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import { ConfirmationModal } from "../../../components/commons/Modals";
import { nanoid } from "nanoid";
import { ReactSVG } from "react-svg";
import _ from "lodash";
import {
  AlertMessage,
  Box,
  Button,
  Heading,
  Loader,
  P,
  PageTitle,
  Separator,
  Space,
  TextError,
} from "../../../components/commons";
import enums from "../../../utils/enums";
import { getSavedSearches, performSearch, removeSearch, saveAndSearch } from "./actions";
import { selectSavedSearches } from "./selectors";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

const Container = styled.div`
  flex: 1;
`;

const SavedCriteriasContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    ${AlertMessage} {
      width: auto;
      max-width: 100%;
    }
  }
  @media (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

class SearchAccountsPage extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    getSavedSearches: PropTypes.func,
    removeSearch: PropTypes.func,
    saveAndSearch: PropTypes.func,
    performSearch: PropTypes.func,
    savedSearches: PropTypes.array,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    accountsSummary: PropTypes.object,
  };
  state = {
    isOpen: false,
    searchDescription: "",
    newSearchDescription: "",
    newSearchName: "",
    selectedSavedSearchObj: {},
    filterOptions: [
      { key: 0, value: "Select an Option" },
      { key: 1, value: "Account Numbers" },
      { key: 2, value: "Account Type" },
      { key: 3, value: "Amount Ranges" },
      { key: 4, value: "Maturing In Days" },
      /*
        TODO: It is commented because the core no longer has the matury date
        { key: 5, value: 'Maturity Date Ranges' },
      */
    ],
    selectedFilter: 0,
    selectedAccountType: 0,
    amountRangeFrom: 0,
    amountRangeTo: 0,
    maturingInDays: 0,
    maturityDateRangeFrom: 0,
    maturityDateRangeTo: 0,
    filtersToApply: {
      accountType: 0,
      maturityDateRanges: [],
      maturingInDays: 0,
      amountRanges: [],
      accountNumbers: [],
    },
  };

  componentDidMount() {
    this.props.getSavedSearches();
  }

  searchDescriptionChanged = event => {
    this.setState({ searchDescription: event.target.value });
  };

  newSearchNameChanged = event => {
    this.setState({ newSearchName: event.target.value });
  };

  newSearchDescriptionChanged = event => {
    this.setState({ newSearchDescription: event.target.value });
  };

  handleRemoveSearch = () => {
    const { selectedSavedSearchObj } = this.state;
    if (
      selectedSavedSearchObj &&
      selectedSavedSearchObj.id &&
      selectedSavedSearchObj.id !== "0"
    ) {
      this.props.removeSearch(selectedSavedSearchObj.id);
      return this.setState({ isOpen: false, selectedSavedSearchObj: {} });
    }
    return null;
  };

  handleSelectSearch = e => {
    const { savedSearches } = this.props;
    const selectedSearchId = e.target.value;
    let selectedSavedSearchObj = {};

    if (savedSearches && selectedSearchId && selectedSearchId !== "0") {
      selectedSavedSearchObj = _.find(savedSearches, s => s.id === selectedSearchId);
      return this.setState({ selectedSavedSearchObj, error: null });
    }
    return this.setState({ selectedSavedSearchObj: {} });
  };

  // handleSaveAndPerformSearch = () => {
  //   const { selectedSavedSearchObj } = this.state;
  //   if (!_.isEmpty(selectedSavedSearchObj) && selectedSavedSearchObj.id !== '0') {
  //     this.props.saveAndSearch(selectedSavedSearchObj);
  //   } else {
  //     this.setState({ error: 'please select a valid search' });
  //   }
  //   return null;
  // }

  handlePerformSearch = () => {
    const { selectedSavedSearchObj } = this.state;
    if (!_.isEmpty(selectedSavedSearchObj) && selectedSavedSearchObj.id !== "0") {
      this.props.performSearch(selectedSavedSearchObj);
    } else {
      this.setState({ error: "please select a valid search" });
    }
    return null;
  };

  handleAddFilter(filterName, value) {
    if (filterName && value) {
      if (filterName === "accountType" || filterName === "maturingInDays") {
        return this.setState({
          filtersToApply: {
            ...this.state.filtersToApply,
            [filterName]: value,
          },
          selectedAccountType: 0,
          amountRangeFrom: 0,
          amountRangeTo: 0,
          maturingInDays: 0,
          maturityDateRangeFrom: 0,
          maturityDateRangeTo: 0,
        });
      }
      if (filterName !== "accountType" && filterName !== "maturingyInDays") {
        return this.setState({
          filtersToApply: {
            ...this.state.filtersToApply,
            [filterName]: [...this.state.filtersToApply[filterName], value],
          },
          selectedAccountType: 0,
          amountRangeFrom: 0,
          amountRangeTo: 0,
          maturingInDays: 0,
          maturityDateRangeFrom: 0,
          maturityDateRangeTo: 0,
        });
      }
    }
    return null;
  }

  handleRemoveFilter(id, filterName) {
    if (filterName) {
      if (filterName === "accountType" || filterName === "maturingInDays") {
        return this.setState({
          filtersToApply: {
            ...this.state.filtersToApply,
            [filterName]: 0,
          },
          selectedAccountType: 0,
          amountRangeFrom: 0,
          amountRangeTo: 0,
          maturingInDays: 0,
          maturityDateRangeFrom: 0,
          maturityDateRangeTo: 0,
        });
      }
      if (filterName !== "accountType" && filterName !== "maturingyInDays") {
        return this.setState({
          filtersToApply: {
            ...this.state.filtersToApply,
            [filterName]: _.filter(
              this.state.filtersToApply[filterName],
              f => f.id && f.id !== id,
            ),
          },
          selectedAccountType: 0,
          amountRangeFrom: 0,
          amountRangeTo: 0,
          maturingInDays: 0,
          maturityDateRangeFrom: 0,
          maturityDateRangeTo: 0,
        });
      }
    }
    return null;
  }

  renderSavedCriterias() {
    const { selectedSavedSearchObj } = this.state;
    if (selectedSavedSearchObj.filters) {
      return (
        <Box column fx={1}>
          <Heading>Criterias on this saved search:</Heading>
          {selectedSavedSearchObj.filters &&
            _.map(
              [selectedSavedSearchObj.filters],
              (
                {
                  accountType,
                  maturityDateRanges,
                  maturingInDays,
                  amountRanges,
                  accountNumbers,
                },
                key,
              ) => (
                <SavedCriteriasContainer key={key}>
                  <AlertMessage>
                    Account Type: {Object.keys(enums.AccountType)[accountType]}
                  </AlertMessage>
                  {maturingInDays && (
                    <AlertMessage>Maturing In Days: {maturingInDays}</AlertMessage>
                  )}
                  {maturityDateRanges &&
                    _.map(maturityDateRanges, ({ from, to }, k) => (
                      <AlertMessage key={k}>
                        <span>
                          Maturity Date: {from} {"--"} {to}
                        </span>
                      </AlertMessage>
                    ))}
                  {amountRanges &&
                    _.map(amountRanges, ({ from, to }, k) => (
                      <AlertMessage key={k}>
                        <span>
                          Amount Ranged: {from} {"--"} {to}
                        </span>
                      </AlertMessage>
                    ))}
                  {accountNumbers &&
                    _.map(accountNumbers, (n, k) => (
                      <AlertMessage key={k}>
                        <span>Account Numbers: TBD</span>
                      </AlertMessage>
                    ))}
                </SavedCriteriasContainer>
              ),
            )}
        </Box>
      );
    }
    return null;
  }

  renderCurrentFiltersToApply() {
    if (
      _.isEmpty(this.state.filtersToApply.accountType) &&
      _.isEmpty(this.state.filtersToApply.maturityDateRanges) &&
      _.isEmpty(this.state.filtersToApply.maturingInDays) &&
      _.isEmpty(this.state.filtersToApply.amountRanges) &&
      _.isEmpty(this.state.filtersToApply.accountNumbers)
    )
      return null;
    return (
      <Box column fx={1}>
        <Heading>Current Saved Criteria:</Heading>
        {this.state.filtersToApply &&
          _.map(
            [this.state.filtersToApply],
            (
              {
                accountType,
                maturityDateRanges,
                maturingInDays,
                amountRanges,
                accountNumbers,
              },
              key,
            ) => (
              <SavedCriteriasContainer key={key}>
                {accountType !== 0 && (
                  <AlertMessage
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <span>
                      Account Type: {Object.keys(enums.AccountType)[accountType]}
                    </span>
                    <span style={{ position: "relative", top: 3, cursor: "pointer" }}>
                      <ReactSVG
                        src={"../../../../icons/x-circle.svg"}
                        evalScripts="always"
                        beforeInjection={svg => {
                          svg.setAttribute(
                            "style",
                            `stroke:#66CBFF;width: 24px;height: 24px; `,
                          );
                        }}
                        onClick={() => this.handleRemoveFilter(null, "accountType")}
                      />
                    </span>
                  </AlertMessage>
                )}
                {maturingInDays !== 0 && (
                  <AlertMessage
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <span>Maturing In Days: {maturingInDays}</span>
                    <span style={{ position: "relative", top: 3, cursor: "pointer" }}>
                      <ReactSVG
                        src={"../../../../icons/x-circle.svg"}
                        evalScripts="always"
                        beforeInjection={svg => {
                          svg.setAttribute(
                            "style",
                            `stroke:#66CBFF;width: 24px;height: 24px; `,
                          );
                        }}
                        onClick={() => this.handleRemoveFilter("maturingInDays")}
                      />
                    </span>
                  </AlertMessage>
                )}
                {/*
                  TODO: It is commented because the core no longer has the matury date
                  {
                    maturityDateRanges
                      && (_.map(maturityDateRanges, ({ from, to }, k) => (
                        <AlertMessage key={k} style={{ display: 'flex', justifyContent: 'space-around' }}>
                          <span>Maturity Date: {from} {'--'} {to}</span>
                          <span style={{ position: 'relative', top: 3, cursor: 'pointer' }}>
                            <ReactSVG
                              path={'../../../../icons/x-circle.svg'}
                              evalScripts="always"
                              svgStyle={{ stroke: '#66CBFF', width: 24, height: 24 }}
                              onClick={() => this.handleRemoveFilter('maturityDateRanges')}
                            />
                          </span>
                        </AlertMessage>
                      )))
                  }
                */}
                {amountRanges &&
                  _.map(amountRanges, ({ id, from, to }, k) => (
                    <AlertMessage
                      key={k}
                      style={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <span>
                        Amount Ranged: {from} {"--"} {to}
                      </span>
                      <span style={{ position: "relative", top: 3, cursor: "pointer" }}>
                        <ReactSVG
                          src={"../../../../icons/x-circle.svg"}
                          evalScripts="always"
                          beforeInjection={svg => {
                            svg.setAttribute(
                              "style",
                              `stroke:#66CBFF;width: 24px;height: 24px; `,
                            );
                          }}
                          onClick={() => this.handleRemoveFilter(id, "amountRanges")}
                        />
                      </span>
                    </AlertMessage>
                  ))}
                {accountNumbers &&
                  _.map(accountNumbers, (n, k) => (
                    <AlertMessage key={k}>
                      <span>Account Numbers: TBD</span>
                    </AlertMessage>
                  ))}
              </SavedCriteriasContainer>
            ),
          )}
      </Box>
    );
  }

  renderConfirmationModal() {
    return (
      <ConfirmationModal
        isOpen={this.state.isOpen}
        onClose={() => this.setState({ isOpen: false })}
        onSuccess={this.handleRemoveSearch}
        headingText="Remove SavedSearch"
        confirmationText={<P>{"Are you sure you want to delete this saved search"}</P>}
        cancelButtonText="Cancel"
        successButtonText={"Confirm"}
        aria-hidden={this.state.isOpen}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  }

  // <AlertMessage><span>Account Type: {this.state.selectedAccountType !== 0 && Object.keys(enums.AccountType)[this.state.selectedAccountType]}</span></AlertMessage>
  renderAccountTypeForm() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        <Select
          id="selectAccountType"
          aria-label="Select Account Type"
          name="search account type"
          label={"Select Account Type"}
          value={this.state.selectedAccountType}
          onChange={e => this.setState({ selectedAccountType: e.target.value })}
        >
          <MenuItem value={0}>Select an Option</MenuItem>
          <MenuItem value={1}>SHARE</MenuItem>
          <MenuItem value={2}>LOAN</MenuItem>
        </Select>
        {this.state.selectedAccountType !== 0 && (
          <Button
            outline
            onClick={() =>
              this.handleAddFilter("accountType", String(this.state.selectedAccountType))
            }
          >
            Add
          </Button>
        )}
      </div>
    );
  }

  renderAmountRangesForm() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        <div style={{ width: 240 }}>
          <TextField
            id="amountRangeFrom"
            label="Amount Range From"
            type="number"
            value={this.state.amountRangeFrom}
            onChange={e => this.setState({ amountRangeFrom: e.target.value })}
          />
        </div>

        <div style={{ width: 240 }}>
          <TextField
            id="amountRangeTo"
            label="Amount Range To"
            type="number"
            value={this.state.amountRangeTo}
            onChange={e => this.setState({ amountRangeTo: e.target.value })}
          />
        </div>
        <Button
          outline
          onClick={() =>
            this.handleAddFilter("amountRanges", {
              id: nanoid(),
              from: this.state.amountRangeFrom,
              to: this.state.amountRangeTo,
            })
          }
        >
          Add
        </Button>
      </div>
    );
  }

  renderMaturityDateRangesForm() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        {/*
          TODO: It is commented because the core no longer has the matury date
          <div style={{ width: 240 }}>
            <TextField
              id="maturityDateRangesFrom"
              label="Maturity Date Range From"
              type="date"
              value={this.state.maturityDateRangeFrom}
              onChange={(e) => this.setState({ amountRangedFrom: e.target.value })}
            />
          </div>
        */}

        {/*
          TODO: It is commented because the core no longer has the matury date
          <div style={{ width: 240 }}>
            <TextField
              id="maturityDateRangesTo"
              label="Maturity Date Range To"
              type="date"
              value={this.state.maturityDateRangeTo}
              onChange={(e) => this.setState({ amountRangedTo: e.target.value })}
            />
          </div>
        */}
        {/*
          TODO: It is commented because the core no longer has the matury date
          <div style={{ width: 240 }}>
            <AlertMessage><span>Maturity Date Ranges: {this.state.amountRangedFrom} {'--'} {this.state.amountRangedTo}</span></AlertMessage>
          </div>
        */}
      </div>
    );
  }

  renderMaturingInDays() {
    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <TextField
          id="maturingInDays"
          label="Maturing In Days"
          type="number"
          value={this.state.maturingInDays}
          onChange={e => this.setState({ maturingInDays: e.target.value })}
        />
        {this.state.maturingInDays !== 0 && (
          <Button
            outline
            onClick={() =>
              this.handleAddFilter("maturingInDays", String(this.state.maturingInDays))
            }
          >
            Add
          </Button>
        )}
      </div>
    );
  }

  renderFiltersForm() {
    switch (this.state.selectedFilter) {
      case 0:
        return null;
      case 2:
        return this.renderAccountTypeForm();
      case 3:
        return this.renderAmountRangesForm();
      case 4:
        return this.renderMaturingInDays();
      case 5:
        return this.renderMaturityDateRangesForm();
      default:
        break;
    }
    return null;
  }

  render() {
    return (
      <DashboardScreens>
        <Container>
          <Loader isLoading={this.props.isFetching} />

          {this.renderConfirmationModal()}

          <PageTitle>Advanced Search</PageTitle>

          <Separator style={{ margin: "1em 0 2em 0" }} />

          <Mobile>
            <div>
              <Box column>
                <Select
                  id="selectSearch"
                  aria-label="Select Balances"
                  name="search"
                  label={"Select Search"}
                  value={this.state.selectedSavedSearchObj.id || ""}
                  onChange={this.handleSelectSearch}
                  error={this.state.error}
                  helperText={this.state.error}
                >
                  {this.props.savedSearches &&
                    _.map(this.props.savedSearches, ({ id, name }, key) => (
                      <MenuItem key={id + key} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                </Select>

                <TextField
                  id="selectDescription"
                  label="Select Description"
                  type="text"
                  value={this.state.selectedSavedSearchObj.description || ""}
                  readOnly
                />

                {this.renderSavedCriterias()}
                <br />
              </Box>

              <Box>
                <Box fx={1}>
                  <Button danger onClick={() => this.setState({ isOpen: true })}>
                    Delete
                  </Button>
                </Box>
                <Space />
                <Box fx={1}>
                  <Button primary onClick={this.handlePerformSearch}>
                    Search
                  </Button>
                </Box>
              </Box>

              <Separator style={{ margin: "1em 0 2em 0" }} />

              <Heading>Create new search</Heading>

              <Box column>
                <TextField
                  id="searchName"
                  label="Search Name"
                  type="text"
                  value={this.state.newSearchName}
                  onChange={this.newSearchNameChanged}
                />

                <TextField
                  id="searchDescription"
                  label="Search Description"
                  type="text"
                  value={this.state.newSearchDescription}
                  onChange={this.newSearchDescriptionChanged}
                />
              </Box>

              <Heading>Select Search Criteria</Heading>

              <Box column>
                <Select
                  id="selectSearchName"
                  aria-label="Select"
                  name="select"
                  label={"Search Name"}
                  value={""}
                />
              </Box>
            </div>
          </Mobile>

          <Desktop>
            <div>
              <Box style={{ overflow: "hidden" }}>
                <Box style={{ height: "100%" }} column fx={1}>
                  <Box>
                    <Select
                      id="selectSearch"
                      aria-label="Select Balances"
                      name="search"
                      label={"Select Search"}
                      value={this.state.selectedSavedSearchObj.id || "0"}
                      onChange={this.handleSelectSearch}
                      error={this.state.error}
                      helperText={this.state.error}
                    >
                      {this.props.savedSearches &&
                        _.map(this.props.savedSearches, ({ id, name }, key) => (
                          <MenuItem key={id + key} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>

                    <TextField
                      id="selectDescription"
                      label="Select Saved Search"
                      type="text"
                      style={{ width: 240, maxWidth: "100%" }}
                      value={this.state.selectedSavedSearchObj.description || ""}
                      readOnly
                    />
                  </Box>

                  {this.props.error && <TextError>{this.props.error}</TextError>}

                  <Box style={{ margin: "16px 0 0 16px" }}>
                    <Button primary onClick={this.handlePerformSearch}>
                      Search
                    </Button>

                    <Space />

                    {!_.isEmpty(this.state.selectedSavedSearchObj) && (
                      <Button danger onClick={() => this.setState({ isOpen: true })}>
                        Delete
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box fx={1}>{this.renderSavedCriterias()}</Box>
              </Box>

              <Separator style={{ margin: "1em 0px" }} />

              <Heading>Create new search</Heading>

              <Box>
                <TextField
                  id="searchName"
                  label="Search Name"
                  type="text"
                  value={this.state.newSearchName}
                  onChange={this.newSearchNameChanged}
                />

                <TextField
                  id="searchDescription"
                  label="Search Description"
                  type="text"
                  value={this.state.newSearchDescription}
                  onChange={this.newSearchDescriptionChanged}
                />
              </Box>

              <br />

              <Heading>Select Search Criteria</Heading>

              <div style={{ display: "flex" }}>
                <div>
                  <Select
                    id="selectSearchName"
                    aria-label="Select"
                    name="select"
                    style={{ maxWidth: 200 }}
                    label={"Search Criteria"}
                    value={this.state.selectedFilter}
                    onChange={e => this.setState({ selectedFilter: e.target.value })}
                  >
                    {this.state.filterOptions &&
                      _.map(this.state.filterOptions, (option, i) => (
                        <MenuItem key={i} value={option.key}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div>{this.renderFiltersForm()}</div>

                  <div />
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{this.renderCurrentFiltersToApply()}</div>

                <br />

                <div>
                  <Button primary>Save &amp; Search</Button>
                </div>
              </div>
            </div>
          </Desktop>
        </Container>
      </DashboardScreens>
    );
  }
}

const mapStateToProps = state => ({
  savedSearches: selectSavedSearches(state),
  accountsSummary: state.account,
  isFetching: state.account.isFetching,
  error: state.account.error,
});
export default connect(mapStateToProps, {
  getSavedSearches,
  saveAndSearch,
  removeSearch,
  performSearch,
})(SearchAccountsPage);
