import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { goBack } from "react-router-redux";
import { connect } from "react-redux";
// import { IconHover } from 'components/commons';
import { ArrowBack } from "../../components/commons/icons";
import _ from "lodash";

const Grid = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 40px;
  margin: 1rem 2.4rem 0 2.4rem;
  border-bottom: 1px solid #7a7a7a;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: end;
  color: #b9b9b9;
  font-weight: 400;
  height: 100%;
  font-size: 1.25rem;
  line-height: 1.5;
  text-transform: capitalize;

  &:hover {
    opacity: 0.6;
    transition: all 0.5s ease;
  }
`;

class Breadcrumb extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    goBack: PropTypes.func,
  };
  render() {
    const separator = (
      <img alt="breadcrumb separator" src="../../../../icons/chevron-right.svg" />
    );
    const segments = this.props.location.pathname.split("/");
    let navLinks = segments.map((_segment, index) => {
      const segmentsSoFar = segments.slice(0, index + 1);
      return segmentsSoFar.join("/");
    });
    navLinks =
      navLinks &&
      navLinks.map(path => {
        const link = path
          .split("/")
          .map(s => s.replace(/\d/g, "."))
          .filter(s => s.indexOf(".", 0) === -1 && s)
          .filter(s => s !== "dashboard");
        return link;
      });
    const navlinksToRender = navLinks.filter((link, i, arr) => i === arr.length - 1);
    return (
      <Grid aria-label="breadcrumb" aria-current="page">
        <div style={{ flex: 1, display: "flex" }}>
          <ArrowBack onClick={() => this.props.goBack()} />
          <span>&nbsp;&nbsp;</span>
          {navlinksToRender &&
            _.map(navlinksToRender, (link, key) => (
              <div key={key} style={{ display: "flex" }}>
                {link &&
                  _.map(link, (l, i, collection) => {
                    if (i === collection.length - 1) {
                      return (
                        <span
                          key={i}
                          style={{
                            textTransform: "capitalize",
                            position: "relative",
                            top: 2,
                          }}
                        >
                          {l}
                        </span>
                      );
                    }
                    return (
                      <NavLink
                        key={i}
                        aria-label={link}
                        role="link"
                        to={`/dashboard/${l}`}
                      >
                        <span>{l}</span>
                        {i < collection.length - 1 && <span>{separator}</span>}
                      </NavLink>
                    );
                  })}
              </div>
            ))}
        </div>
      </Grid>
    );
  }
}

export default connect(null, { goBack })(Breadcrumb);
