import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import TextField from "../../../components/commons/TextField";
import { SuccessModal } from "../../../components/commons/Modals";
import { withStyles } from "@material-ui/core/styles";
import { Mobile, Desktop } from "../../../components/commons/Responsive";
import { Button, Box, PageTitle } from "../../../components/commons";
import { Check } from "../../../components/commons/icons";
import {
  getCustomerProfile,
  saveCustomerProfile,
  updateProfileForm,
  updateAccountDescription,
} from "../Accounts/actions";
import { DashboardScreens } from "../DashboardInstance/Dashboard";

import { closeSuccessModal } from "../user/actions";

import styles from "../Accounts/styles.module.css";

const ProfileForm = styled.form`
  display: flex;
  .title {
    color: #141f2d;
    font-family: "Kumbh Sans";
    font-size: 36px;
    font-weight: bold;
    margin-left: 16px;
  }
  .containerPhoto {
    display: flex;
    align-items: center;
    margin-top: 10%;
    margin-bottom: 8%;
    margin-left: 16px;
    .photo {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      background-color: #05ce86;
    }
    .uploadPhoto {
      color: #000000;
      font-family: "Kumbh Sans";
      font-size: 14px;
      margin-left: 22px;
    }
  }
  .subTitle {
    margin-left: 16px;
    color: #3fc78e;
    font-family: "Kumbh Sans";
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .saveChanges {
    margin-left: 16px;
  }
`;

function Profile({
  getCustomerProfile,
  customerProfile,
  saveCustomerProfile,
  updateProfileForm,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    getCustomerProfile();
  }, [getCustomerProfile]);

  function formatPhoneNumber(phone) {
    if (phone) {
      phone = phone.replace(/[^\d]/g, "");

      //reformat and return phone number
      return phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/, "($1) $2 $3");
    }

    return phone;
  }

  const handleSumbitProfileForm = e => {
    e.preventDefault();
    const { name, email, userOLBAddress, userOLBPhone } =
      !_.isEmpty(customerProfile) && customerProfile;
    //if (name && address && email && mobile && homePhone && city && state ) {
    saveCustomerProfile({
      name,
      email,
      userOLBAddress,
      userOLBPhone,
    });
    //}
    //else {
    // handle form validation
    //}
    setIsOpenModal(true);
  };

  const renderSuccessModal = () => {
    return (
      <SuccessModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        headingText="User profile updated succesfully!"
        successIcon={<Check />}
        aria={{
          labelledby: "heading",
          describedby: "full_description",
        }}
      />
    );
  };

  const { name, email, userOLBAddress, userOLBPhone } =
    customerProfile && customerProfile;

  return (
    <DashboardScreens>
      {renderSuccessModal()}
      <ProfileForm>
        <Desktop>
          <div>
            <PageTitle>User Profile</PageTitle>
            <div className="containerPhoto">
              {/* <div className="photo">
                <UserWhite stylesInput={{ opacity: 1 }} />
              </div> */}
              <img
                src="https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png"
                width="32"
                height="32"
                style={{ borderRadius: "50%", height: "60px", width: "60px" }}
                alt="ImageProfile"
              />
              <span className="uploadPhoto">Upload Photo</span>
            </div>
            <h2 className="subTitle">PERSONAL INFO</h2>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={name || ""}
                type="text"
                name="name"
                id="profileName"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Name"}
                normal
              />

              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={email || ""}
                type="text"
                name="email"
                id="profilEmail"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Email"}
                normal
              />
            </Box>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBPhone ? formatPhoneNumber(userOLBPhone.phone) : ""}
                type="text"
                name="phone"
                id="profileMobile"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Mobile Number"}
                normal
              />
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBPhone ? formatPhoneNumber(userOLBPhone.homePhone) : ""}
                type="text"
                name="homePhone"
                id="profilehomePhone"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Home Phone Number"}
                normal
              />
            </Box>
            <h2 className="subTitle">ADDRESS</h2>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.address : ""}
                type="text"
                name="address"
                id="profileAddress"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Address"}
                normal
              />
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.address2 : ""}
                type="text"
                name="address2"
                id="profileAddress2"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Address 2"}
                normal
              />
            </Box>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.city : ""}
                type="text"
                name="city"
                id="profilecity"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"City"}
                normal
              />
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.state : ""}
                type="text"
                name="state"
                id="profilestate"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"State"}
                normal
              />
            </Box>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.zip : ""}
                type="text"
                name="zip"
                id="profilezip"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Zip"}
                normal
              />
            </Box>
            <br />
            <Button
              primary
              onClick={e => handleSumbitProfileForm(e)}
              className="saveChanges"
            >
              Save
            </Button>
          </div>
        </Desktop>
        <Mobile>
          <div>
            <h1 className="title">User Profile</h1>
            <div className="containerPhoto">
              {/* <div className="photo">
                <UserWhite stylesInput={{ opacity: 1 }} />
              </div> */}
              <img
                src="https://png.pngitem.com/pimgs/s/522-5220445_anonymous-profile-grey-person-sticker-glitch-empty-profile.png"
                width="32"
                height="32"
                style={{ borderRadius: "50%", height: "60px", width: "60px" }}
                alt="ImageProfile"
              />
              <span className="uploadPhoto">Upload Photo</span>
            </div>
            <h2 className="subTitle">PERSONAL INFO</h2>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={name || ""}
                type="text"
                name="name"
                id="profileName"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Name"}
                normal
              />

              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={email || ""}
                type="text"
                name="email"
                id="profilEmail"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Email"}
                normal
              />
            </Box>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBPhone ? formatPhoneNumber(userOLBPhone.phone) : ""}
                type="text"
                name="phone"
                id="profileMobile"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Mobile Number"}
                normal
              />
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBPhone ? formatPhoneNumber(userOLBPhone.homePhone) : ""}
                type="text"
                name="homePhone"
                id="profilehomePhone"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Home Phone Number"}
                normal
              />
            </Box>
            <h2 className="subTitle">ADDRESS</h2>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.address : ""}
                type="text"
                name="address"
                id="profileAddress"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Address"}
                normal
              />
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.address2 : ""}
                type="text"
                name="address2"
                id="profileAddress2"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Address 2"}
                normal
              />
            </Box>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.city : ""}
                type="text"
                name="city"
                id="profilecity"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"City"}
                normal
              />
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.state : ""}
                type="text"
                name="state"
                id="profilestate"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"State"}
                normal
              />
            </Box>
            <Box>
              <TextField
                onChange={e => updateProfileForm(e.target.name, e.target.value)}
                value={userOLBAddress ? userOLBAddress.zip : ""}
                type="text"
                name="zip"
                id="profilezip"
                // error={state.formError || state.amountError}
                // helperText={state.formError || state.amountError}
                label={"Zip"}
                normal
              />
            </Box>
            <br />
            <Button primary onClick={e => handleSumbitProfileForm(e)}>
              Save
            </Button>
          </div>
        </Mobile>
      </ProfileForm>
    </DashboardScreens>
  );
}

Profile.prototype = {
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  getCustomerProfile: PropTypes.func,
  saveCustomerProfile: PropTypes.func,
  updateProfileForm: PropTypes.func,
  customerProfile: PropTypes.object,
  successModal: PropTypes.bool,
  closeSuccessModal: PropTypes.func,
  classes: PropTypes.object,
};

const mapStateToProps = state => ({
  isFetching: state.account.isFetching,
  customerProfile: state.account.customerProfile,
  successModal: state.user.successModal,
  error: state.account.error,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getCustomerProfile,
    updateAccountDescription,
    saveCustomerProfile,
    updateProfileForm,
    closeSuccessModal,
  })(Profile),
);
