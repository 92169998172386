import * as types from "./constants";
import { push } from "react-router-redux";
import { handleHttpError } from "../../../utils/helper";
import { post } from "../../../utils/server";

export const getCustomerGoals = (hidden = null) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.GET_CUSTOMER_GOALS_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post("/customer/getgoals", { idOLBUser, hidden });
    if (data.success) {
      dispatch({ type: types.GET_CUSTOMER_GOALS_SUCCESS, payload: data.data });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_GOALS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.GET_CUSTOMER_GOALS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.GET_CUSTOMER_GOALS_FAILURE,
        payload: "error trying to get customer's goals",
      });
    }
  }
};

export const removeCustomerGoals = goalId => async (dispatch, getState) => {
  try {
    dispatch({ type: types.REMOVE_CUSTOMER_GOALS_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post("/customer/removegoal", { idOLBUser, goalId });
    if (data.success) {
      dispatch({ type: types.REMOVE_CUSTOMER_GOALS_SUCCESS, payload: data.data });
      dispatch(showSuccessModal(true, "Goal Removed Succesfully!"));
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_GOALS_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.REMOVE_CUSTOMER_GOALS_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.REMOVE_CUSTOMER_GOALS_FAILURE,
        payload: "error trying to remove customer's goals",
      });
    }
  }
};

export const createCustomerGoal = goal => async (dispatch, getState) => {
  try {
    dispatch({ type: types.CREATE_CUSTOMER_GOAL_REQUEST });
    const {
      auth: { userId: idOLBUser },
    } = getState();
    const { data } = await post("/customer/creategoal", { idOLBUser, ...goal });
    if (data.success) {
      dispatch({ type: types.CREATE_CUSTOMER_GOAL_SUCCESS, payload: data.data });
      dispatch(showCreateGoalModal(false));
      dispatch(showSuccessModal(true, "Goal Created Succesfully!"));
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_GOAL_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.CREATE_CUSTOMER_GOAL_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.CREATE_CUSTOMER_GOAL_FAILURE,
        payload: "error trying to create customer's goal",
      });
    }
  }
};

export const updateCustomerGoal = (goalId, goal) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.UPDATE_CUSTOMER_GOAL_REQUEST });
    const { data } = await post("/customer/updategoal", { goalId, ...goal });
    if (data.success) {
      dispatch({ type: types.UPDATE_CUSTOMER_GOAL_SUCCESS, payload: data.data });
      dispatch(showUpdateGoalModal(false));
      dispatch(showSuccessModal(true, "Goal Updated Succesfully!"));
    } else {
      dispatch({
        type: types.UPDATE_CUSTOMER_GOAL_FAILURE,
        payload: data.message,
      });
    }
  } catch (err) {
    if (handleHttpError(err, { dispatch, push })) {
      return;
    }
    if (err?.response?.data?.message) {
      dispatch({
        type: types.UPDATE_CUSTOMER_GOAL_FAILURE,
        payload: err.response.data.message || "",
      });
    } else {
      dispatch({
        type: types.UPDATE_CUSTOMER_GOAL_FAILURE,
        payload: "error trying to update customer's goal",
      });
    }
  }
};
export const showCreateGoalModal = show => dispatch =>
  dispatch({ type: types.SHOW_CREATE_GOAL_MODAL, payload: show });
export const showUpdateGoalModal = show => dispatch =>
  dispatch({ type: types.SHOW_UPDATE_GOAL_MODAL, payload: show });
export const showSuccessModal = (show, msg) => dispatch =>
  dispatch({ type: types.SHOW_CREATE_GOAL_SUCCESS_MODAL, payload: { show, msg } });
export const updateGoalValue = (id, name, value) => dispatch =>
  dispatch({ type: types.UPDATE_GOAL_VALUE, payload: { id, name, value } });
export const initGoalValues = () => dispatch =>
  dispatch({ type: types.INIT_GOAL_VALUES });
export const showErrorAmountModal = show => dispatch =>
  dispatch({ type: types.SHOW_ERROR_AMOUNT_MODAL, payload: show });
export const dispatchValidateAmount = (message = "") => dispatch =>
  dispatch({ type: types.DISPATCH_VALIDATE_AMOUNT, payload: message });
