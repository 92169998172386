import React from "react";
import PropTypes from "prop-types";
import { createMuiTheme } from "@material-ui/core/styles";
import Cookie from "js-cookie";
import AuthFirstStep from "../../../components/commons/AuthFirstStep";

class FormFirstStep extends React.Component {
  static defaultProps = {
    onSubmit: () => undefined,
  };

  static propTypes = {
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    onChangeUsername: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    onChangeRememberDevice: PropTypes.func.isRequired,
    validateFirstStep: PropTypes.func.isRequired,
    validateSecondStep: PropTypes.func.isRequired,
    rememberDevice: PropTypes.bool.isRequired,
    onError: PropTypes.func,
    classes: PropTypes.object,
    stepError: PropTypes.string,
    isSucessUserName: PropTypes.bool,
    isFetching: PropTypes.bool,
    config: PropTypes.object,
  };

  state = {
    error: "",
  };

  checkBoxTheme = createMuiTheme({
    palette: {
      secondary: {
        main: "#3fc78e",
      },
    },
  });

  handleOnSubmit = () => {
    const { validateSecondStep, username = "", password = "" } = this.props;

    if (!!username && !!password) {
      validateSecondStep(password);
    } else {
      this.setState({
        error: "please fill the form",
      });
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.handleOnSubmit();
    }
  };

  setRememberDevice = () => {
    const { rememberDevice = false } = this.props;

    const doDelete = () => {
      Cookie.remove("REMEMBER_DEVICE");
      localStorage.removeItem("rememberDevice");
    };

    const doSet = () => {
      Cookie.set("REMEMBER_DEVICE", true);
      localStorage.setItem("rememberDevice", JSON.stringify(true));
    };

    if (!rememberDevice) doSet();
    else doDelete();
  };

  handleChangeRememberDevice = ({ target }) => {
    try {
      const { rememberDevice, onChangeRememberDevice } = this.props;

      onChangeRememberDevice(!rememberDevice);

      this.setRememberDevice();
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      stepError,
      isFetching,
      classes,
      phrase,
      validateFirstStep,
      rememberDevice,
      isSucessUserName,
      onChangePassword,
      onChangeUsername,
      password,
      username,
    } = this.props;

    const { error } = this.state;

    const hasError = stepError || error || !password || !username;

    return (
      <AuthFirstStep
        disableLoginButton={!isSucessUserName || isFetching || hasError}
        isPrimaryLoginButton={!isFetching && isSucessUserName && !hasError}
        onChangePassword={({ target }) => {
          const password = target.value;

          onChangePassword(password);
        }}
        password={password}
        email={username}
        isFetching={isFetching}
        onChangeEmail={({ target }) => {
          const username = target.value;

          onChangeUsername(username);
        }}
        onChangeRememberDevice={this.handleChangeRememberDevice}
        onClickLoginButton={this.handleOnSubmit}
        rememberDevice={rememberDevice}
        title="Sign In"
        error={hasError}
        classes={classes}
        onBlurEmail={() => username !== "" && validateFirstStep(username)}
        phrase={phrase}
        onKeyPressEmail={this.handleKeyPress}
        onKeyPressPassword={this.handleKeyPress}
      />
    );
  }
}

export default FormFirstStep;
