import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { push } from "react-router-redux";
import styled from "styled-components";
import TextField from "../../../components/commons/TextField";
import { Button, Loader, Box, Space } from "../../../components/commons";
import { Desktop, Mobile } from "../../../components/commons/Responsive";
import { insertPayee } from "./actions";
import { selectDefaultPayees, selectSharesOptions } from "./selectors";
import { LoanControls } from "../Loans/LoanApp";
import states from "states-us";
import { MenuItem } from "@material-ui/core";
import Select from "./../../../components/commons/SelectInput";

import styles from "./styles.module.css";

const PayeeDescription = styled.div`
  margin-left: 15px;
  margin-top: 1rem;
  width: 100%;
  span {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
  }
  p {
    font-size: 15px;
    color: #a9a7af;
    margin: 0;

    @media (max-width: 767px) {
      max-width: 90%;
    }
  }
`;

// import me
const PayeeForm = styled.form`
  @media (max-width: 767px) {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: 768px) {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: auto;
      min-width: auto;
    }
  }
`;

const ModalControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  background-color: #f4f6f8;
`;

class NewPayee extends Component {
  static propTypes = {
    insertPayee: PropTypes.func,
    isFetching: PropTypes.bool,
    defaultPayees: PropTypes.object,
    shares: PropTypes.array,
    // push: PropTypes.func,
  };
  state = {
    name: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    nickname: "",
    accountNumber: "",
    payFromId: "",
    selectedGlobalPayeeValue: "",
    nameAccount: "",
    lastnameAccount: "",
    abaRouting: "",
  };
  onSubmit() {
    const {
      name,
      phone,
      address1,
      address2,
      city,
      state,
      zipCode,
      nickname,
      accountNumber,
      // payFromId,
    } = this.state;
    if (
      name !== "" &&
      phone !== "" &&
      address1 !== "" &&
      city !== "" &&
      state !== "" &&
      zipCode !== "" &&
      nickname !== "" &&
      accountNumber !== ""
      // payFromId !== ''
    ) {
      const newPayee = {
        name,
        phone,
        address1,
        address2,
        city,
        state,
        zipCode,
        nickname,
        accountNumber,
        // defaultPayFromId: payFromId,
      };
      this.setState({ error: null });
      this.props.insertPayee(newPayee);
    } else {
      this.setState({ error: "please fill all the required fields" });
    }
  }
  handleSelectGlobalPayee = e => {
    const { filteredPayees: defaultPayees } = this.props.defaultPayees;
    const selectedGlobalPayeeValue = e.target.value;
    const selectedGlobalPayee =
      defaultPayees &&
      _.find(defaultPayees, payee => payee.uniqueId === selectedGlobalPayeeValue);
    if (!_.isEmpty(selectedGlobalPayee)) {
      const {
        address1,
        address2,
        city,
        name,
        phone,
        state,
        zipCode,
      } = selectedGlobalPayee;
      this.setState({ address1, address2, city, name, phone, state, zipCode });
    }
    this.setState({ selectedGlobalPayeeValue });
  };
  handleChange = e => this.setState({ [e.target.name]: e.target.value });
  handleClearState = () => {
    this.setState({
      name: "",
      phone: "",
      address1: "",
      address2: "",
      payFromId: "",
      city: "",
      state: "",
      zipCode: "",
      nickname: "",
      accountNumber: "",
      selectedGlobalPayeeValue: "",
    });
  };
  render() {
    return (
      <>
        <Loader isLoading={this.props.isFetching} />
        <Box>
          <Box className={styles.desktopFx} />
        </Box>
        <Mobile>
          <Box column>
            <PayeeForm>
              <TextField
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="name"
                value={this.state.name}
                id="name"
                type="text"
                label={"Name"}
              />

              <TextField
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="nickname"
                value={this.state.nickname}
                id="nickname"
                type="text"
                label={"Nickname"}
              />

              <TextField
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="address1"
                value={this.state.address1}
                id="address1"
                type="text"
                label={"Address"}
              />

              <TextField
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="address2"
                value={this.state.address2}
                id="address2"
                type="text"
                label={"Address 2"}
              />

              <TextField
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="phone"
                value={this.state.phone}
                id="phone"
                type="text"
                label={"Phone Number"}
              />

              <TextField
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="city"
                value={this.state.city}
                id="city"
                type="text"
                label={"City"}
              />

              <Select
                id="state"
                name="state"
                aria-label="state"
                label={"State"}
                value={this.state.state}
                onChange={e => {
                  this.setState({ state: e.target.value });
                }}
                style={{ minWidth: "100px" }}
              >
                {(states || []).map((state, idx) => (
                  <MenuItem key={idx} value={state.abbreviation}>
                    {state.abbreviation}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                midSize
                readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
                onChange={this.handleChange}
                name="zipCode"
                value={this.state.zipCode}
                id="zip"
                type="text"
                label={"Zip Code"}
              />

              <PayeeDescription>
                <span>Account</span>
                <p>Enter Account number exactly how it appears on your bill.</p>
              </PayeeDescription>

              <TextField
                onChange={this.handleChange}
                name="accountNumber"
                value={this.state.accountNumber}
                id="account"
                type="text"
                label={"Account Number"}
              />

              <PayeeDescription>
                <p>Enter you name exactly how it appears on your bill.</p>
              </PayeeDescription>
            </PayeeForm>
            <br />
            {this.state.error && <p style={{ color: "#FF6670" }}>{this.state.error}</p>}
            <ModalControls>
              <Button onClick={() => this.props.closeModal()}>Cancel</Button>
              <Space />
              <Button primary onClick={() => this.onSubmit()}>
                Send
              </Button>
            </ModalControls>
          </Box>
        </Mobile>
        <Desktop>
          <PayeeForm>
            <TextField
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="name"
              value={this.state.name}
              id="name"
              type="text"
              label={"Name"}
            />

            <TextField
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="nickname"
              value={this.state.nickname}
              id="nickname"
              type="text"
              label={"Nickname"}
            />

            <TextField
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="address1"
              value={this.state.address1}
              id="address1"
              type="text"
              label={"Address"}
            />

            <TextField
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="address2"
              value={this.state.address2}
              id="address2"
              type="text"
              label={"Address 2"}
            />

            <TextField
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="city"
              value={this.state.city}
              id="city"
              type="text"
              label={"City"}
            />

            <Select
              id="state"
              name="state"
              aria-label="state"
              label={"State"}
              value={this.state.state}
              onChange={e => {
                this.setState({ state: e.target.value });
              }}
              style={{ minWidth: "100px" }}
            >
              {(states || []).map((state, idx) => (
                <MenuItem key={idx} value={state.abbreviation}>
                  {state.abbreviation}
                </MenuItem>
              ))}
            </Select>

            <TextField
              midSize
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="zipCode"
              value={this.state.zipCode}
              id="zip"
              type="text"
              label={"Zip Code"}
            />

            <TextField
              readOnly={this.state.selectedGlobalPayeeValue !== "" && true}
              onChange={this.handleChange}
              name="phone"
              value={this.state.phone}
              id="phone"
              type="text"
              label={"Phone Number"}
            />

            <PayeeDescription>
              <span>Account</span>
              <p>Enter Account number exactly how it appears on your bill.</p>
            </PayeeDescription>

            <TextField
              onChange={this.handleChange}
              name="accountNumber"
              value={this.state.accountNumber}
              id="account"
              type="text"
              label={"Account Number"}
            />

            <TextField
              onChange={this.handleChange}
              name="abaRouting"
              value={this.state.abaRouting}
              id="routing"
              type="text"
              label={"ABA Routing Number"}
            />

            <PayeeDescription>
              <p>Enter you name exactly how it appears on your bill.</p>
            </PayeeDescription>

            <TextField
              onChange={this.handleChange}
              name="nameAccount"
              value={this.state.nameAccount}
              id="nameAccount"
              type="text"
              label={"Name"}
            />

            <TextField
              onChange={this.handleChange}
              name="lastnameAccount"
              value={this.state.lastnameAccount}
              id="lastnameAccount"
              type="text"
              label={"Last Name"}
            />
          </PayeeForm>
          <br />
          {this.state.error && <p style={{ color: "#FF6670" }}>{this.state.error}</p>}
          <LoanControls>
            <Button onClick={() => this.props.closeModal()}>Cancel</Button>
            <Space />
            <Button primary onClick={() => this.onSubmit()}>
              Send
            </Button>
          </LoanControls>
        </Desktop>
      </>
    );
  }
}
const mapStateToProps = state => ({
  isFetching: state.epayment.isFetching,
  shares: selectSharesOptions(state),
  defaultPayees: selectDefaultPayees(state),
});
export default connect(mapStateToProps, { insertPayee, push })(NewPayee);
