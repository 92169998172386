import { FinancialInstitutionSecuredFormsCustomerStatus, SortFormsType } from "./enums";

export const sortFormsList = (forms, type) => {
  if (type === SortFormsType.UNREAD_FIRST) {
    forms.sort((a, b) => {
      if (
        a.status !== FinancialInstitutionSecuredFormsCustomerStatus.UNREAD &&
        b.status === FinancialInstitutionSecuredFormsCustomerStatus.UNREAD
      ) {
        return 1;
      }

      if (
        a.status !== FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD &&
        b.status === FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD
      ) {
        return 1;
      }

      if (
        a.status === FinancialInstitutionSecuredFormsCustomerStatus.UNREAD &&
        b.status !== FinancialInstitutionSecuredFormsCustomerStatus.UNREAD
      ) {
        return -1;
      }

      if (
        a.status === FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD &&
        b.status !== FinancialInstitutionSecuredFormsCustomerStatus.ARCHIVED_UNREAD
      ) {
        return -1;
      }

      return 0;
    });
  }

  if (type === SortFormsType.A_TO_Z) {
    forms.sort((a, b) => {
      var friendlyNameA = a.FinancialInstitutionSecuredForm.SecuredForm.friendlyName.toUpperCase();
      var friendlyNameB = b.FinancialInstitutionSecuredForm.SecuredForm.friendlyName.toUpperCase();

      if (friendlyNameA < friendlyNameB) {
        return -1;
      }
      if (friendlyNameA > friendlyNameB) {
        return 1;
      }

      return 0;
    });
  }
  return forms;
};
