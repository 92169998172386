import React from "react";

import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";

import { Popup, PopupRow, Button, Separator } from "../../../components/commons";
import enums from "../../../utils/enums";

const PoupMessage = ({ popup, handleClickOutside, messages, push, isAdmin }) => {
  return (
    <Popup
      id="messagesPopup"
      role="listbox"
      aria-labelledby="messagesPopup"
      aria-label="messages"
      aria-expanded={popup.messages.isOpen}
      aria-hidden={popup.messages.ariaHidden}
      isOpen={popup.messages.isOpen}
      onClose={handleClickOutside}
      sm
    >
      {messages.messagesToShow &&
        _.map(
          messages.messagesToShow,
          ({ id, viewed, imageUrl, message, postedDate, messageType, important }) => (
            <div key={id + postedDate} style={{ background: !viewed && "#FCFCFC" }}>
              {Object.keys(enums.MessageTypes)[messageType] === "INFO" ? (
                <PopupRow
                  onClick={() =>
                    push(isAdmin ? "/admin/panel/messages" : "/dashboard/messages")
                  }
                >
                  <span className="date">
                    {moment(postedDate).utc().format("MM/DD/YYYY")}
                  </span>
                  <span>{message}</span>
                  <span className="badge">
                    {Object.keys(enums.MessageTypes)[messageType]}
                  </span>
                  {important && <span>IMPORTANT</span>}
                </PopupRow>
              ) : (
                Object.keys(enums.MessageTypes)[messageType] === "BANNER" && (
                  <PopupRow>
                    <img
                      style={{
                        width: "100%",
                        height: 80,
                        borderRadius: 3,
                      }}
                      src={imageUrl}
                      alt="banner-img"
                    />
                  </PopupRow>
                )
              )}
              <Separator />
            </div>
          ),
        )}
      <div className="notificationsFooter">
        <Button
          onClick={() => push(isAdmin ? "/admin/panel/messages" : "/dashboard/messages")}
        >
          View All
        </Button>
      </div>
    </Popup>
  );
};

PoupMessage.prototype = {
  popup: PropTypes.object,
  handleClickOutside: PropTypes.func,
  messages: PropTypes.object,
  push: PropTypes.func,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { push })(PoupMessage);
