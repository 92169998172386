/**
 *
 * Login Container
 *
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import {
  CantLogIn,
  FirstStepAuth,
  ForgotPassword,
  SecondStepAuth,
  VerificationCodePage,
} from "./steps";
import {
  ChangeYourPassword,
  EnterLoginCode,
  EnterTemporaryPassword,
  ForgotYourPassword,
} from "./recoverPassword";
import * as actions from "./actions";

import styles from "./Login.module.css";
import { AuthBox, AuthContainer, Grid } from "../../components/commons/LoginStyles";

class Login extends React.Component {
  static defaultProps = {
    currentStep: "1",
    config: {
      fiName: "",
      fiLogo: "",
      fiFooterLogo: "",
      fiDisclaimer: "",
      fiPaymentDisclosure: "",
      fiContactEmail: "",
      fiContactPhone: "",
      fiTextBankingNumber: "",
    },
  };
  static propTypes = {
    currentStep: PropTypes.string,
    setToken: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    validateSecondStep: PropTypes.func.isRequired,
    validateThirdStep: PropTypes.func.isRequired,
    handleTroubleshooting: PropTypes.func,
    handleAuthNavigation: PropTypes.func,
    requestSMSCode: PropTypes.func,
    validateSMSCode: PropTypes.func,
    firstStepRecoverPassword: PropTypes.func,
    secondStepRecoverPassword: PropTypes.func,
    thirdStepRecoverPassword: PropTypes.func,
    phrase: PropTypes.string,
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool,
    mfa: PropTypes.string,
    config: PropTypes.object,
  };

  state = {
    username: "",
    password: "",
    rememberDevice: false,
  };

  componentDidMount() {
    const rememberDevice = !!localStorage.getItem("rememberDevice");

    this.setState({ ...this.state, rememberDevice });
  }

  validateFirstStep = username => {
    this.props.validateFirstStep(username);
  };

  validateSecondStep = () => {
    const { username, password } = this.state;
    const { history } = this.props;

    this.props.validateSecondStep(
      password,
      username,
      async ({ error, data, success = false, handlers = {} }) => {
        if (!!success && !!data && !!data.token && !!data.iat && !error) {
          this.props.setToken({
            iat: data.iat,
            token: data.token,
          });

          if (!!handlers && !!handlers.replace) {
            handlers.replace("/dashboard/accounts");
          }
        }
      },
    );
  };

  validateAdminThirdStep = (mfaAnswer = "", code = "") => {
    this.props.validateAdminThirdStep(mfaAnswer, code);
  };

  validateCode = code => {
    this.props.validateSMSCode(code);
  };

  handleTroubleshooting = type => {
    this.props.handleTroubleshooting(type);
  };

  handleAuthNavigation = step => {
    this.props.handleAuthNavigation(step);
  };

  handleFirstStepRecoverPassword = (ssn, dob) => {
    this.props.firstStepRecoverPassword(ssn, dob);
  };

  handleSecondStepRecoverPassword = tempPassword => {
    this.props.secondStepRecoverPassword(tempPassword);
  };
  handleFinalStepRecoverPassword = (newPassword, verifyPassword) => {
    this.props.thirdStepRecoverPassword(newPassword, verifyPassword);
  };

  renderBanner() {
    return (
      <header role="banner" aria-label="primary">
        <MediaQuery query="(max-width: 479px)">
          <img className={styles.banner} src="banner01-sm.jpg" alt="banner" />
        </MediaQuery>

        <MediaQuery minWidth={480} maxWidth={991}>
          <img className={styles.banner} src="banner01-md.jpg" alt="banner" />
        </MediaQuery>

        <MediaQuery query="(min-width: 992px)">
          <img className={styles.banner} src="banner01-lg.jpg" alt="banner" />
        </MediaQuery>
      </header>
    );
  }

  renderSteps() {
    const {
      mfa,
      errorMessage,
      phrase,
      config,
      isFetching,
      isSucessUserName,
      currentStep,
      requestSMSCode,
      doSendConfirmationCode,
    } = this.props;

    const { username, password, rememberDevice } = this.state;

    const firstStep = (
      <FirstStepAuth
        rememberDevice={rememberDevice}
        validateFirstStep={this.validateFirstStep}
        validateSecondStep={this.validateSecondStep}
        phrase={phrase}
        isSucessUserName={isSucessUserName}
        onError={this.handleTroubleshooting}
        isFetching={isFetching}
        stepError={errorMessage}
        config={config}
        username={username}
        password={password}
        onChangePassword={password => this.setState({ ...this.state, password })}
        onChangeUsername={username => this.setState({ ...this.state, username })}
        onChangeRememberDevice={rememberDevice =>
          this.setState({ ...this.state, rememberDevice })
        }
      />
    );

    switch (currentStep) {
      case "1":
        return firstStep;

      case "2":
        return (
          <SecondStepAuth
            onSubmit={this.validateSecondStep}
            onError={this.handleTroubleshooting}
            phrase={phrase}
            isFetching={isFetching}
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            config={config}
          />
        );
      case "3":
        return (
          <VerificationCodePage
            doSendConfirmationCode={async () => await doSendConfirmationCode(password)}
            onSubmit={(code = "") =>
              this.props.validateThirdStep(undefined, code, username)
            }
            onError={this.handleTroubleshooting}
            phrase={phrase}
            isFetching={isFetching}
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            config={config}
          />
        );

      // return (
      //   <ThirdStepAuth
      //     onSubmit={this.validateThirdStep}
      //     mfa={mfa}
      //     isFetching={isFetching}
      //     stepError={errorMessage}
      //     handleChangeStep={this.handleAuthNavigation}
      //     config={config}
      //   />
      // );
      case "cant_login":
        return <CantLogIn handleChangeStep={this.handleAuthNavigation} config={config} />;
      case "forgot_password":
        return (
          <ForgotPassword
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            onSubmit={requestSMSCode}
            isFetching={isFetching}
            config={config}
          />
        );
      case "via_sms_code":
        return (
          <EnterLoginCode
            onSubmit={this.validateCode}
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            isFetching={isFetching}
            config={config}
          />
        );
      case "via_email":
        return (
          <ForgotYourPassword
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            isFetching={isFetching}
            onSubmit={this.handleFirstStepRecoverPassword}
            config={config}
          />
        );
      case "temporary_password":
        return (
          <EnterTemporaryPassword
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            isFetching={isFetching}
            onSubmit={this.handleSecondStepRecoverPassword}
            config={config}
          />
        );
      case "reset_password":
        return (
          <ChangeYourPassword
            handleChangeStep={this.handleAuthNavigation}
            stepError={errorMessage}
            isFetching={isFetching}
            onSubmit={this.handleFinalStepRecoverPassword}
            config={config}
          />
        );
      default:
        return firstStep;
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return <div>{this.props.errorMessage}</div>;
    }

    return null;
  }

  renderFooter() {
    return (
      <footer role="contentinfo" className={styles.footer}>
        <MediaQuery query="(min-width: 992px)">
          <div style={{ flex: 1 }} />
        </MediaQuery>

        <p className={styles.footerText}>
          {this.props.config && this.props.config.fiDisclaimer}
        </p>

        <div className={styles.footerIcons}>
          <img className={styles.icon} src="logo-ncua.png" alt="logo-ncua" />

          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

          {this.props.config && this.props.config.fiFooterLogo !== "" && (
            <img
              className={styles.icon}
              src={this.props.config.fiFooterLogo}
              style={{ borderRadius: "60px" }}
              alt="logo-house-lender"
            />
          )}
        </div>
      </footer>
    );
  }

  render() {
    return (
      <AuthContainer>
        <div className="logo">
          <img
            alt="Logo"
            src={
              this.props.config && this.props.config.auth && this.props.config.auth.fiLogo
            }
            style={{ height: "37px" }}
          />
        </div>

        <Grid>
          <div className="logoMobil">
            <img
              alt="Logo"
              src={
                this.props.config &&
                this.props.config.auth &&
                this.props.config.auth.fiLogo
              }
              style={{ height: "37px" }}
            />
          </div>

          <AuthBox>{this.renderSteps()}</AuthBox>
        </Grid>
      </AuthContainer>
    );
  }
}

const mapStateToProps = ({ auth, config }) => ({
  errorMessage: auth.error,
  phrase: auth.phrase,
  currentStep: auth.currentStep,
  isSucessUserName: auth.isSucessUserName,
  isFetching: auth.isFetching,
  mfa: auth.mfa,
  config: config.config,
});

export default connect(mapStateToProps, actions)(Login);
