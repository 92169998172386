import styled from "styled-components";
export default styled.button`
  font-family: "Kumbh Sans", sans-serif;
  background: ${({ primary, danger, info, disabled }) =>
    primary
      ? "#05CE86"
      : danger
      ? "#cb3737"
      : info
      ? "#66CBFF"
      : disabled
      ? "#ebeff3"
      : "transparent"};
  color: ${({ primary, danger, info, disabled }) =>
    primary || danger || info ? "white" : disabled || "#141F2D"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-weight: 500;
  display: flex;
  line-height: 1.5;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: ${({ modalButton }) => (modalButton ? "0.88rem" : "1rem")};
  padding: 0.5rem 2rem;
  border: ${({ outline }) => (outline ? "1px solid #05CE86" : "0px")};
  border-radius: 10px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  & > img {
    margin-right: 5px;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${({ primary, danger, info, disabled }) =>
      primary
        ? "#02AA6E"
        : danger
        ? "#E94E58"
        : info
        ? "#407fa0"
        : disabled
        ? "#ebeff3"
        : "#44444"};
    color: ${({ primary, danger, info, disabled }) =>
      primary || danger || info ? "white" : disabled && "#141F2D"};
    border-color: #02aa6e;
    transition: all 0.5s ease;
  }

  @media (max-width: 769px) {
    width: 100%;
    max-width: 100%;
  }

  @media (min-width: 992px) {
    padding: ${({ md }) => (md ? "0.5rem 1.5rem" : "0.5rem 2rem")};
    font-size: ${({ md }) => md && "0.9rem"};
  }

  @media (min-width: 320px) and (max-width: 768px) {
    text-transform: uppercase;
    font-weight: bold;
  }
`;
