import React from "react";

import _ from "lodash";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ReactSVG } from "react-svg";
import { Box, NavLinkWithHover } from "../../commons";
import { ModalMobile } from "../../commons/Modals";

import { getMenuLinks, setActiveIconColor } from "../../../utils/sideNav";

const NavContainer = styled.nav`
  .container-item {
    align-items: center;
    height: 40px;
    display: flex;
  }

  .icon-nav {
    width: 24px;
    height: 24px;
  }

  .displayName {
    margin-left: 4%;
  }

  a {
    font-size: 0.9rem;
    font-weight: 300;
    color: black;
    line-height: normal;
  }

  .external-icon {
    stroke: #000;
    width: 12px;
    height: 12px;
    margin-left: 10px;
  }
`;

const SideNavMobile = ({ menuConfig, isMobileMenuOpen, onLogout }) => {
  const menu = getMenuLinks(menuConfig);

  return (
    <ModalMobile isOpen={isMobileMenuOpen}>
      <NavContainer>
        {menu &&
          _.map(menu, ({ iconSrc, displayName, path, subMenu, externalLink }, key) => (
            <Box key={key} role="menuitem" className="container-item">
              <Box>
                {iconSrc === "roles" ? (
                  <ReactSVG
                    src={`../../../../icons/roles.svg`}
                    evalScripts="always"
                    beforeInjection={svg => {
                      svg.setAttribute(
                        "style",
                        `color:${setActiveIconColor(
                          window.location.pathname,
                          path,
                          subMenu,
                          true,
                        )}; width: 25px;height: 25px;`,
                      );
                    }}
                  />
                ) : (
                  <FeatherIcon
                    icon={iconSrc}
                    className="icon-nav"
                    style={{
                      stroke: setActiveIconColor(
                        window.location.pathname,
                        path,
                        subMenu,
                        true,
                      ),
                    }}
                  />
                )}
              </Box>
              <Box fx={1} className="displayName" auto>
                {externalLink ? (
                  <a href={path} target="_blank">
                    {displayName}
                  </a>
                ) : (
                  <NavLinkWithHover
                    aria-label="link to place"
                    role="link"
                    activeStyle={{ color: "#05CE86" }}
                    to={path}
                  >
                    {displayName}
                  </NavLinkWithHover>
                )}

                {externalLink && (
                  <FeatherIcon icon="external-link" className="external-icon" />
                )}
              </Box>
            </Box>
          ))}
        <Box key="logout" role="menuitem" className="container-item">
          <Box>
            <FeatherIcon
              icon="log-out"
              className="icon-nav"
              style={{
                stroke: "red",
              }}
            />
          </Box>
          <Box fx={1} className="displayName" auto>
            <a style={{ color: "red" }} onClick={() => onLogout()}>
              Logout
            </a>
          </Box>
        </Box>
      </NavContainer>
    </ModalMobile>
  );
};

SideNavMobile.prototype = {
  menuConfig: PropTypes.object,
  isMobileMenuOpen: PropTypes.bool,
  onLogout: PropTypes.func,
};

export default SideNavMobile;
