import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 1rem;
  border-color: #66cbff;
  color: #66cbff;
  border-width: 2px;
  border-style: solid;
  font-size: 0.9rem;
  width: 200px;
  max-width: 200px;
  border-radius: 10px;
  white-space: nowrap;
  max-height: 30px;
  height: 30px;
  margin: 0 8px 8px 8px;

  & > p,
  small,
  h3,
  h4,
  h5 {
    margin: 0;
    font-size: 1rem;
    color: #66cbff;
  }
`;
