import React from "react";

import FeatherIcon from "feather-icons-react";
import MediaQuery from "react-responsive";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";

import {
  A4SubNav,
  A4SubNavMenu,
  A4SubNavMenuItem,
  A4SubNavMenuMobil,
  A4SubNavMenuItemMobil,
} from "../../../components/commons/GridNav";
import { NavLinkWithHover } from "../../../components/commons";
import {
  setActiveItemStyleSubMenu,
  setActiveIconColorSubmenu,
} from "../../../utils/subMenu";

const A4SubNavMenuCustom = styled(A4SubNavMenu)`
  a {
    span {
      display: flex;
      margin-left: 20px;
    }
  }
`;

const A4SubNavMenuMobilCustom = styled(A4SubNavMenuMobil)`
  margin-bottom: 5px;

  a {
    span {
      font-size: 14px;
      margin-left: 8;
      line-height: normal;
    }
  }
`;

const NavLinkWithHoverCustom = styled(NavLinkWithHover)`
  color: #4e5d71;

  span {
    font-family: Kumbh Sans;
    opacity: 0.8;
    font-size: 14px;
    vertical-align: middle;
  }
`;

const FeatherIconCustom = styled(FeatherIcon)`
  width: 20px;
  height: 20px;
`;

const DisplayName = styled.span`
  color: #141f2d;
  font-family: Kumbh Sans;
  font-size: 36px;
  font-weight: bold;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 28px;

  &.displayName {
    font-size: 26px;
    width: 70%;
  }
`;

const Desktop = ({ location, title, subMenu, push }) => {
  return (
    <A4SubNavMenuCustom id="collapsableNav" role="menu" aria-label="expanded navigation">
      <DisplayName className="displayName">{title}</DisplayName>

      {subMenu &&
        _.map(subMenu, ({ path, displayName, iconName }, key) => {
          const styleItemSubMenu = setActiveItemStyleSubMenu(location.pathname, path);
          const colorIconSubMenu = setActiveIconColorSubmenu(location.pathname, path);

          return (
            <A4SubNavMenuItem
              key={`subMenuItem-${displayName}`}
              aria-label={`menu item ${displayName}`}
              role="menuitem"
              style={{ ...styleItemSubMenu }}
              onClick={() => push(path)}
            >
              <FeatherIconCustom icon={iconName} style={{ stroke: colorIconSubMenu }} />
              <NavLinkWithHoverCustom
                aria-label={`link ${displayName}`}
                role="link"
                activeStyle={{ color: "#141F2D" }}
                to={path}
              >
                <span>{displayName}</span>
              </NavLinkWithHoverCustom>
            </A4SubNavMenuItem>
          );
        })}
    </A4SubNavMenuCustom>
  );
};

const Mobile = ({ location, title, subMenu, push }) => {
  return (
    <>
      <DisplayName>{title}</DisplayName>
      <A4SubNavMenuMobilCustom
        id="collapsableNav"
        role="menu"
        aria-label="expanded navigation"
      >
        {subMenu &&
          _.map(subMenu, ({ path, displayName, iconName }, key) => {
            const styleItemSubMenu = setActiveItemStyleSubMenu(
              location.pathname,
              path,
              true,
            );
            const colorIconSubMenu = setActiveIconColorSubmenu(location.pathname, path);

            return (
              <A4SubNavMenuItemMobil
                key={`subMenuItem-${displayName}`}
                aria-label={`menu item ${displayName}`}
                role="menuitem"
                style={{ ...styleItemSubMenu }}
                onClick={() => push(path)}
              >
                <FeatherIconCustom icon={iconName} style={{ stroke: colorIconSubMenu }} />
                <NavLinkWithHoverCustom
                  aria-label={`link ${displayName}`}
                  role="link"
                  activeStyle={{ color: "#141F2D" }}
                  to={path}
                >
                  <span>{displayName}</span>
                </NavLinkWithHoverCustom>
              </A4SubNavMenuItemMobil>
            );
          })}
      </A4SubNavMenuMobilCustom>
    </>
  );
};

const DashboardSubNav = props => {
  return (
    <>
      <MediaQuery query="(max-width: 767px)">
        <Mobile {...props} />
      </MediaQuery>
      <MediaQuery query="(min-width: 768px)">
        <A4SubNav>
          <Desktop {...props} />
        </A4SubNav>
      </MediaQuery>
    </>
  );
};

DashboardSubNav.propTypes = {
  location: PropTypes.object,
  subMenu: PropTypes.array,
  push: PropTypes.func,
  title: PropTypes.string,
};

Desktop.propTypes = {
  location: PropTypes.object,
  subMenu: PropTypes.array,
  push: PropTypes.func,
  title: PropTypes.string,
};

Mobile.propTypes = {
  location: PropTypes.object,
  subMenu: PropTypes.array,
  push: PropTypes.func,
  title: PropTypes.string,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { push })(DashboardSubNav);
