/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const SET_FI_REQUEST = "set_fi_request";
export const SET_FI_SUCCESS = "set_fi_success";
export const SET_FI_FAILURE = "set_fi_failure";

export const GET_FI_CONFIG_REQUEST = "get_fi_config_request";
export const GET_FI_CONFIG_SUCCESS = "get_fi_config_success";
export const GET_FI_CONFIG_FAILURE = "get_fi_config_failure";
