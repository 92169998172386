import styled from "styled-components";

export const FormThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;

  @media (max-width: 767px) {
    width: 100%;
  }

  .descriptionPage {
    color: #18243c;
    font-family: "Kumbh Sans";
    font-size: 17px;
    margin-top: 10px;
  }
  .titleQuestion {
    color: #c1cace;
    font-family: "Kumbh Sans";
    font-size: 17px;
    margin-top: 10%;
  }
  .question {
    color: #1b2846;
    font-family: "Kumbh Sans";
    font-size: 17px;
  }
  .separation {
    margin-top: 10%;
  }
  .containerChangeQuestion {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .changeQuestion {
      color: #c1cace;
      font-family: "Kumbh Sans";
      font-size: 14px;
      font-weight: bold;
    }
  }
  .btnLogin {
    font-family: "Kumbh Sans";
    font-size: 15px;
    margin-top: 110%;
  }
`;

export const AuthContainer = styled.div`
  background-color: white;
  height: 100%;
  min-height: 100%;

  * {
    box-sizing: border-box;
    max-width: 100%;
  }

  .logo {
    display: none;
    justify-content: flex-start;
    padding: 20px;

    @media (min-width: 767px) {
      display: flex;
    }
  }

  .logoMobil {
    display: none;
    justify-content: flex-start;
    margin: 0 0 65px 0;

    @media (max-width: 767px) {
      display: flex;
    }
  }
`;

export const AuthBox = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  @media (min-width: 992px) {
    padding: 1.5rem 0 0 0;
  }

  .back {
    margin: 0 0 75px 0;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
  height: auto;
  margin: 0 auto;

  @media (min-width: 767px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 0px 0px 5px 5px;
  }
`;

export const FormFirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 110px);
  justify-content: center;

  @media (max-width: 375px) {
    padding: 0 10px;
  }

  @media (max-width: 365px) {
    padding: 0 20px;
  }

  .space {
    flex: 1;
  }

  .separation {
    height: 20px;
  }

  .title {
    color: #26305d;
    font-family: "Kumbh Sans";
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 45px;
  }

  .containerForgotPassword {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2px;
    margin-top: 15px;

    @media (max-width: 349px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .MuiGrid-grid-xs-2 {
      flex-basis: inherit;
      flex-grow: inherit;
      min-width: inherit;
    }

    .rememberDevice {
      width: 100%;
      font-family: "Kumbh Sans";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #1f2427;
    }

    .rememberDeviceCheckbox {
      margin: 0 10px 0 0;

      .PrivateSwitchBase-input-26,
      .MuiSvgIcon-root {
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }

      .PrivateSwitchBase-input-26 {
        width: 30px;
        height: 30px;
      }
    }

    .forgotPassword {
      color: #a5a7a9;
      font-family: "Kumbh Sans";
      font-size: 15px;
      font-weight: bold;
      margin: 7px 0 0 10px;
    }
  }

  .containerPhrase {
    display: flex;
    flex-direction: column;
  }

  .descriptionPhrase {
    color: #1b2846;
    font-family: "Kumbh Sans";
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 5px;
    line-height: 1.2;
    margin-top: 3%;
  }

  .titlePhrase {
    color: #c1cace;
    font-family: "Kumbh Sans";
    font-size: 17px;
  }

  .phrase {
    color: #c1cace;
    font-family: "Kumbh Sans";
    font-size: 17px;
  }

  .phraseActive {
    color: #2e356a;
    font-family: "Kumbh Sans";
    font-size: 17px;
  }

  .createAccount {
    color: #152134;
    font-family: "Kumbh Sans";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 5%;
  }

  .btnLogin {
    margin: 25px 0;

    &:hover {
    }
  }
`;

export const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 350px;
  height: 100%;
  min-height: 100%;

  .back {
    color: #152134;
    font-family: "Kumbh Sans";
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    padding-bottom: 20px;
  }

  .title {
    color: #26305d;
    font-family: "Kumbh Sans";
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 75px;
  }

  .descriptionText {
    color: #18243c;
    font-family: "Kumbh Sans";
    font-size: 15px;
    margin: 5px 0 30px 0;
    line-height: 1.2;
  }
  .btnReset {
    font-family: "Kumbh Sans";
    font-size: 15px;
    margin-top: 35px;
  }
`;

export const Space = styled.div`
  flex: 1;
`;
