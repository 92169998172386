import styled from "styled-components";

export const HeaderColumn = styled.div`
  display: flex;
  flex: ${({ fx }) => fx || "0"};
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
