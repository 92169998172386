import _ from "lodash";
import * as types from "./constants";

const initialState = {
  isFetching: false,
  challengeQuestions: null,
  error: null,
  updatedUser: null,
  changePasswordModal: false,
  securityQuestionsModal: false,
  successModal: false,
  phraseModal: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CHALLENGE_QUESTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.GET_CHALLENGE_QUESTIONS_SUCCESS:
      const challengeQuestions = action.payload;
      let i = 1;
      if (challengeQuestions.length > 0) {
        i = i + challengeQuestions[challengeQuestions.length - 1].id;
      }
      while (challengeQuestions.length < 3) {
        challengeQuestions.push({ id: null, secret: "", answer: "", index: i });
        i++;
      }
      return {
        ...state,
        isFetching: false,
        challengeQuestions,
      };
    case types.GET_CHALLENGE_QUESTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.SAVE_CHALLENGE_QUESTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.SAVE_CHALLENGE_QUESTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        // challengeQuestions: action.payload
      };
    case types.SAVE_CHALLENGE_QUESTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.CHANGE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.CHANGE_USER_PHRASE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.CHANGE_USER_PHRASE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case types.CHANGE_USER_PHRASE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case types.CHALLENGE_QUESTIONS_UPDATE_VALUE:
      return {
        ...state,
        challengeQuestions:
          state.challengeQuestions &&
          _.map(state.challengeQuestions, obj => {
            if (obj.id !== null && obj.id === action.payload.id) {
              return {
                ...obj,
                [action.payload.name]: action.payload.value,
              };
            } else if (obj.index === action.payload.id) {
              console.log(action.payload.name);
              console.log(obj);
              return {
                ...obj,
                [action.payload.name]: action.payload.value,
              };
            }

            return obj;
          }),
      };
    case "OPEN_CHANGE_PASSWORD_MODAL":
      return {
        ...state,
        changePasswordModal: action.payload,
      };
    case "CLOSE_CHANGE_PASSWORD_MODAL":
      return { ...state, changePasswordModal: action.payload, error: null };
    case "OPEN_SUCCESS_MODAL":
      return { ...state, successModal: action.payload };
    case "CLOSE_SUCCESS_MODAL":
      return { ...state, successModal: action.payload };
    case "OPEN_PHRASE_MODAL":
      return { ...state, phraseModal: action.payload };
    case "CLOSE_PHRASE_MODAL":
      return { ...state, phraseModal: action.payload };
    case "OPEN_SECURITY_QUESTIONS_MODAL":
      return { ...state, securityQuestionsModal: action.payload };
    case "CLOSE_SECURITY_QUESTIONS_MODAL":
      return { ...state, securityQuestionsModal: action.payload };
    default:
      return state;
  }
}

export default userReducer;
