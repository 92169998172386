import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// container for badge prop
const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  p {
    display: inline;
  }
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    background: #e6444e;
    text-align: center;
    padding: 8px;
    width: 8px;
    color: white;
    font-size: 8px;
    font-weight: 500;
    border-radius: 50%;
  }
`;

const Badge = styled.i`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
`;

export default class Icon extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.hasFocus !== this.props.hasFocus && this.props.hasFocus === true) {
      this.input.focus();
    }
  }
  handleOnClick = event => {
    event.preventDefault();
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
  render() {
    const {
      hasPopup,
      src,
      badge,
      onClick,
      styles,
      stylesInput,
      ariaLabel,
      textDecorationThickness,
    } = this.props;
    const renderBadge = badge && badge > 0 && (
      <p aria-hidden="true">
        <span
          style={{
            display: "flex",
            width: 10,
            height: 10,
            fontSize: 10,
            fontWeight: "400",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Badge>{badge}</Badge>
        </span>
      </p>
    );
    return (
      <div style={styles}>
        {badge ? (
          <ImageContainer>
            <input
              ref={el => (this.input = el)}
              aria-haspopup={hasPopup}
              type="image"
              style={{
                width: 30,
                height: 30,
                cursor: "pointer",
                opacity: 0.7,
                textDecorationThickness,
                ...stylesInput,
              }}
              src={src}
              onClick={onClick}
              alt={ariaLabel || "icon"}
            />
            {renderBadge}
          </ImageContainer>
        ) : (
          <input
            ref={el => (this.input = el)}
            aria-haspopup={hasPopup}
            onClick={this.handleOnClick}
            type="image"
            src={src}
            alt={ariaLabel || "icon"}
            style={{
              opacity: 0.7,
              width: 23,
              height: 23,
              cursor: "pointer",
              outline: "transparent",
              textDecoration: "none",
              display: "flex",
              ...stylesInput,
            }}
          />
        )}
      </div>
    );
  }
}

Icon.propTypes = {
  hasFocus: PropTypes.bool,
  ariaLabel: PropTypes.string,
  hasPopup: PropTypes.bool,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  src: PropTypes.string.isRequired,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// icons
const Inbox = props => <Icon src={"../../../../icons/mail.svg"} {...props} />;
const Close = props => <Icon src={"../../../../icons/x.svg"} {...props} />;
const Email = props => <Icon src={"../../../../icons/mail.svg"} {...props} />;
const Percent = props => <Icon src={"../../../../icons/percent.svg"} {...props} />;
const Message = props => <Icon src={"../../../../icons/message.svg"} {...props} />;
const Unlock = props => <Icon src={"../../../../icons/unlock.svg"} {...props} />;
const Help = props => <Icon src={"../../../../icons/help.svg"} {...props} />;
const Link = props => <Icon src={"../../../../icons/external-link.svg"} {...props} />;
const Eye = props => <Icon src={"../../../../icons/eye.svg"} {...props} />;
const AlertCircle = props => (
  <Icon src={"../../../../icons/alert-circle.svg"} {...props} />
);
const PieChart = props => <Icon src={"../../../../icons/target.svg"} {...props} />;
const EyeOff = props => <Icon src={"../../../../icons/eye-off.svg"} {...props} />;
const Check = props => <Icon src={"../../../../icons/check.svg"} {...props} />;
const Cancel = props => <Icon src={"../../../../icons/x-black.svg"} {...props} />;
const Book = props => <Icon src={"../../../../icons/book.svg"} {...props} />;
const List = props => <Icon src={"../../../../icons/list.svg"} {...props} />;
const Dollar = props => <Icon src={"../../../../icons/dollar-sign.svg"} {...props} />;
const Upload = props => <Icon src={"../../../../icons/upload.svg"} {...props} />;
const Shield = props => <Icon src={"../../../../icons/shield.svg"} {...props} />;
const Star = props => <Icon src={"../../../../icons/star.svg"} {...props} />;
const Zap = props => <Icon src={"../../../../icons/zap.svg"} {...props} />;
const Burger = props => <Icon src={"../../../../icons/menu.svg"} {...props} />;
const Plus = props => <Icon src={"../../../../icons/plus.svg"} {...props} />;
const MoreVertical = props => (
  <Icon src={"../../../../icons/more-vertical.svg"} {...props} />
);
const Minus = props => <Icon src={"../../../../icons/minus.svg"} {...props} />;
const Bell = props => <Icon src={"../../../../icons/bell.svg"} {...props} />;
const Search = props => <Icon src={"../../../../icons/search.svg"} {...props} />;
const SearchGray = props => <Icon src={"../../../../icons/search-gray.svg"} {...props} />;
const Download = props => <Icon src={"../../../../icons/download.svg"} {...props} />;
const ArrowDown = props => <Icon src={"../../../../icons/chevron-down.svg"} {...props} />;
const chevronUp = props => <Icon src={"../../../../icons/chevron-up.svg"} {...props} />;
const Filter = props => <Icon src={"../../../../icons/filter.svg"} {...props} />;
const ArrowDownCircle = props => (
  <Icon src={"../../../../icons/arrow-down-circle.svg"} {...props} />
);
const PlusCircleBlack = props => (
  <Icon src={"../../../../icons/plus-circle-black.svg"} {...props} />
);
const CheckRoundGreen = props => (
  <Icon src={"../../../../icons/check-round.svg"} {...props} />
);
const CheckRoundGray = props => (
  <Icon src={"../../../../icons/check-round-gray.svg"} {...props} />
);
const ChevronLeft = props => (
  <Icon src={"../../../../icons/chevron-left.svg"} {...props} />
);
const ChevronRight = props => (
  <Icon src={"../../../../icons/chevron-right.svg"} {...props} />
);
const ChevronRightBlack = props => (
  <Icon src={"../../../../icons/chevron-right-black.svg"} {...props} />
);
const Blossom = props => <Icon src={"../../../../icons/BlossomWhite.svg"} {...props} />;
const BlossomGray = props => (
  <Icon src={"../../../../icons/BlossomGray.svg"} {...props} />
);
const BlossomIcon = props => (
  <Icon src={"../../../../icons/BlossomIconWhite.svg"} {...props} />
);
const BlossomIconGray = props => (
  <Icon src={"../../../../icons/BlossomIconGray.svg"} {...props} />
);
const BlossomIconGreen = props => <Icon src={"../../../../icons/Green.svg"} {...props} />;
const BlossomGreen = props => <Icon src={"../../../../icons/Blossom.svg"} {...props} />;
const BreadcrumbSeparator = props => (
  <Icon src={"../../../../icons/chevron-right.svg"} {...props} />
);
const PlusCircle = props => <Icon src={"../../../../icons/plus-circle.svg"} {...props} />;
const User = props => <Icon src={"../../../../icons/icon-user.svg"} {...props} />;
const UserWhite = props => <Icon src={"../../../../icons/user-white.svg"} {...props} />;
const UserBlack = props => <Icon src={"../../../../icons/user.svg"} {...props} />;
const Logout = props => <Icon src={"../../../../icons/logout.svg"} {...props} />;
const ArrowBack = props => <Icon src={"../../../../icons/icon-back.svg"} {...props} />;
const ArrowLeft = props => <Icon src={"../../../../icons/arrow-left.svg"} {...props} />;
const ArrowUp = props => <Icon src={"../../../../icons/arrow-up.svg"} {...props} />;
const Phone = props => <Icon src={"../../../../icons/phone.svg"} {...props} />;
const ArrowRight = props => <Icon src={"../../../../icons/arrow-right.svg"} {...props} />;
const Edit = props => <Icon src={"../../../../icons/edit.svg"} {...props} />;
const EditPrimary = props => <Icon src={"../../../../icons/edit-green.svg"} {...props} />;
const EditForms = props => <Icon src={"../../../../icons/editForms.svg"} {...props} />;
const Grid = props => <Icon src={"../../../../icons/grid.svg"} {...props} />;
const Smartphone = props => <Icon src={"../../../../icons/smartphone.svg"} {...props} />;
const RedTrash = props => <Icon src={"../../../../icons/trash-red.svg"} {...props} />;
const BlackTrash = props => <Icon src={"../../../../icons/trash-black.svg"} {...props} />;
const InfoGreen = props => <Icon src={"../../../../icons/infoGreen.svg"} {...props} />;
const Refresh = props => <Icon src={"../../../../icons/refresh-ccw.svg"} {...props} />;
const Users = props => <Icon src={"../../../../icons/users.svg"} {...props} />;
const Activity = props => <Icon src={"../../../../icons/activity.svg"} {...props} />;
const File = props => <Icon src={"../../../../icons/file.svg"} {...props} />;
const LifeBuoy = props => <Icon src={"../../../../icons/life-buoy2.svg"} {...props} />;
const Calendar = props => (
  <Icon
    src={
      props.primary
        ? "../../../../icons/calendar-primary.svg"
        : "../../../../icons/calendar.svg"
    }
    {...props}
  />
);
Calendar.propTypes = { primary: PropTypes.bool };

export {
  Calendar,
  Filter,
  Smartphone,
  BreadcrumbSeparator,
  ArrowRight,
  ArrowLeft,
  Phone,
  Grid,
  ArrowBack,
  ArrowUp,
  User,
  Logout,
  Bell,
  Inbox,
  Burger,
  ArrowDown,
  PlusCircle,
  Edit,
  EditPrimary,
  EditForms,
  Book,
  Zap,
  Upload,
  Dollar,
  Cancel,
  Email,
  Eye,
  AlertCircle,
  EyeOff,
  Unlock,
  Help,
  Percent,
  Check,
  List,
  Star,
  Message,
  Plus,
  Download,
  PieChart,
  Minus,
  Shield,
  Link,
  Close,
  Search,
  ChevronLeft,
  ChevronRight,
  Blossom,
  BlossomIcon,
  BlossomGray,
  BlossomIconGray,
  ChevronRightBlack,
  MoreVertical,
  RedTrash,
  ArrowDownCircle,
  CheckRoundGreen,
  PlusCircleBlack,
  chevronUp,
  BlackTrash,
  SearchGray,
  UserBlack,
  BlossomIconGreen,
  BlossomGreen,
  InfoGreen,
  UserWhite,
  Refresh,
  CheckRoundGray,
  Users,
  Activity,
  File,
  LifeBuoy,
};
